import { memo, useCallback, useEffect, useState } from "react"
import Button from "../../../bootstrap/Button";
import SelectUser from "./selectUser";

const UserAdd = ({ usersInit, usersOptions, usersSelected, onChange, onRemove }) => {

  const [componentsData, setcomponentsData] = useState([{
    userSelected: '',
  }])

  useEffect(() => {
    if(usersInit.length === 0) {
      setcomponentsData([{ userSelected: '' }])
    } else {
      setcomponentsData(usersInit.map(i => ({ userSelected: i })))
    }
  },[usersInit])

  const onChangeComponentData = useCallback((newUser, lastUser, index) => {
    componentsData[index].userSelected = newUser;
    onChange(newUser, lastUser, index);
    setcomponentsData(componentsData.slice())
  },[componentsData, onChange])

  const removeComponent = useCallback((index) => {
    onRemove(index)
    if(componentsData.length === 1) {
      componentsData[index].userSelected = '';
      return;
    }
    componentsData.splice(index, 1);
    setcomponentsData(componentsData.slice())
  },[componentsData])

  const addComponent = useCallback(() => {
    componentsData.push({
      userSelected: ''
    })
    setcomponentsData(componentsData.slice())
  },[componentsData])

  return (
    <div className="col-md-12">
      {componentsData.map((item, index) => {
        return (
          <SelectUser
            index={index}
            options={usersOptions}
            selected={item.userSelected}
            usersSelected={usersSelected}
            onChange={onChangeComponentData}
            onRemove={removeComponent}
          />
        )
      })}
      <Button
        className="mt-4"
        icon='Add'
        color='success'
        type='button'
        onClick={addComponent}
      >
        Adicionar Usuário
      </Button>
    </div>
  );
}

export default memo(UserAdd);