// import React, { memo, useContext } from 'react';
// import YoutLogo from '../../../assets/img/yourlogo.png'
// import { DefaultContext } from '../../../contexts/default';
// import PreAlert from '../../../helpers/utils/preAlert';
// import './avatar.css';

// const LIMIT_SIZE = 100000;

// function Avatar({ id, value, onChange }) {
//     const photo = value ? value : YoutLogo;

//     const { onShowAlert } = useContext(DefaultContext)

//     const onLocalChange = e => {
//         // const [file] = Array.from(e.target.files)
//         // if(file.size > LIMIT_SIZE) {
//         //     console.error('Imagem muito grande');
//         //     return onShowAlert(PreAlert.error('Imagem muito grande. Limite de ' + (LIMIT_SIZE / 1000) + ' KB'))
//         // }
//         onChange(e);
//     }
    
//     return (
//         <>
//         <div className="personal-image">
//             <label className="label">
//                 <input type="file" id={id} accept="image/*,video/mp4" multiple onChange={onLocalChange} />
//                 <figure className="personal-figure">
//                     <img src={photo} className="personal-avatar rounded" alt="" />
//                     <video src={photo} className="personal-avatar rounded" muted loop autoPlay={true}/>
//                     <figcaption className="personal-figcaption">
//                         <img src="https://raw.githubusercontent.com/ThiagoLuizNunes/angular-boilerplate/master/src/assets/imgs/camera-white.png" />
//                     </figcaption>
//                 </figure>
//             </label>
//         </div>
//         </>
//     );
// }

// export default memo(Avatar)
import React, { memo } from 'react';
import YoutLogo from '../../../assets/img/yourlogo.png'
import './avatar.css';

const LIMIT_SIZE = 100000;

function AvatarVideo({ id, value, onChange }) {

    const onLocalChange = e => {
        // const [file] = Array.from(e.target.files)
        // if(file.size > LIMIT_SIZE) {
        //     console.error('Imagem muito grande');
        //     return onShowAlert(PreAlert.error('Imagem muito grande. Limite de ' + (LIMIT_SIZE / 1000) + ' KB'))
        // }
        onChange(e);
    }
    
    return (
        <>
        <div className="personal-image">
            <label className="label">
                <input type="file" id={id} accept="image/*,video/mp4" multiple onChange={onLocalChange} />
                <figure className="personal-figure" style={{width: 'auto', height:'auto', maxHeight: '100px'}}>
                    <div style={ {position: "relative"} } className="personal-avatar rounded">
                        <img src={value || YoutLogo}  style={{zIndex: '5', width: 'auto', height:'auto', maxHeight: '100px'}} alt=""/>

                        <video src={value} style={{zIndex: '4', width: "auto", height:"auto",  maxHeight: "100px", position: "relative", top: 0, left: 0}} muted loop autoPlay={true}/>
                    </div>
                    <figcaption className="personal-figcaption">
                        <img src="https://raw.githubusercontent.com/ThiagoLuizNunes/angular-boilerplate/master/src/assets/imgs/camera-white.png" />
                    </figcaption>
                </figure>
            </label>
        </div>
        </>
    );
}

export default memo(AvatarVideo)