export const COLORS_LIGHT = {
  DARK: '#FAD02A',
  PRIMARY: '#25CED1',
  SECONDARY: '#AEFFD8',
  SUCCESS: '#E0ACD5',
  INFO: '#B66FFF',
  WARNING: '#DE6B48',
  DANGER: '#DE4315',
  GREEN: '#90EE90',
  DARK_GREEN: '#5f885c',
  LIGHT_GREEN:'#d9d94d',
  BLUE: '#357bf1',
  PURPLE: '#a4839e',
  PINK:'#d45df9',
  ROSE_DARK:'#ab8587',
  ORANGE:'#d18f56',
  ORANGE_DARK: '#f2550e',
  GRAY:'#8c8988'

} as const

export const COLORS_DARK = {
  DARK: '#D1AE22',
  PRIMARY: '#20BABD',
  SECONDARY: '#8DD0B0',
  SUCCESS: '#BD90B4',
  INFO: '#9057CB',
  WARNING: '#C65F3F',
  DANGER: '#C03911',
  GREEN: '#8AE38A',
  DARK_GREEN: '#5f883c',
  LIGHT_GREEN:'#d9d96d',
  BLUE: '#357bf1',
  PURPLE: '#a886a1',
  PINK:'#d45df4',
  ROSE_DARK:'#ab8584',
  ORANGE:'#d18f56',
  ORANGE_DARK: '#f2540e',
  GRAY: '#aeadac'
} as const