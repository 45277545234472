import { useContext, useEffect, useMemo, useState } from "react";
import { DefaultContext } from "../../contexts/default";
import TaxDevolutionEventData from "../../database/wrappers/eventData/taxaDevolution";
import TaxDevolution from "../../database/entities/eventData/taxaDevolution.entity";


const useTotalTaxDevolution = () => {
  const { estabSelected, eventSelected,sectorSelected } = useContext(DefaultContext);
  const [taxDevolution, setTaxDevolution] = useState<TaxDevolution []>([]);


  useEffect(() => {
    let isMounted = true;
    const loadTaxDevolution = () => {
      if (estabSelected && eventSelected && sectorSelected) {
        new TaxDevolutionEventData(estabSelected,eventSelected)
          .getBySector(sectorSelected)
          .then((taxDevolution) => {
            if (isMounted) {
              setTaxDevolution(taxDevolution)
            }
          }
          );
      }
    };
    loadTaxDevolution();
    return () => {
      isMounted = false;
    }
  }, [estabSelected, eventSelected, sectorSelected]);

  const TaxDevolutionCalc = useMemo(() => {
    if (!taxDevolution) return {
      totalTaxDevolution: 0
    }; 

    const totalTaxDevolution = taxDevolution.reduce((prev, curr) => prev + curr.value, 0);

    return {
      totalTaxDevolution
    };
  }, [taxDevolution]);

  return { TaxDevolutionCalc };
};

export default useTotalTaxDevolution;
