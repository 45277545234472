export function filterRolePages(user, pages) {
  const pagesDictionary = {};
  Object.keys(pages).forEach(key => {
    if (pages[key].hide) return
    if (pages[key].roles && !pages[key].roles.includes(user.role)) {
      return;
    } else {
      pagesDictionary[key] = structuredClone(pages[key]);
    }

    if (pages[key].subMenu) {
      pagesDictionary[key].subMenu = {};
      Object.keys(pages[key].subMenu).forEach(subKey => {
        if (!(pages[key].subMenu[subKey].roles && !pages[key].subMenu[subKey].roles.includes(user.role)))
          pagesDictionary[key].subMenu[subKey] = pages[key].subMenu[subKey];
      })
    }
  })
  return pagesDictionary;
}