import React, { useState, useEffect, useContext, memo, useCallback } from 'react';
import { useFormik } from 'formik';
import Papa from 'papaparse'
import FormGroup from '../../bootstrap/forms/FormGroup';
import Input from '../../bootstrap/forms/Input';
import Modal, { ModalBody, ModalHeader, ModalTitle } from '../../bootstrap/Modal';
import PreAlert from '../../../helpers/utils/preAlert';
import { DefaultContext } from '../../../contexts/default';
import BtnLoad from '../../MyCustom/BtnLoad';
import BtnCancel from '../../MyCustom/BtnCancel';
import BtnConfirm from '../../MyCustom/BtnConfirm';
import EarlyConsummationDB from '../../../database/wrappers/earlyConsummation';

const validate = (values) => {
  const errors = {};

  if (!values.file) {
    errors.file = 'Este campo é necessário';
  } 

  return errors;
}

const ModalRegisterEarlyConsummation = ({ open, setIsOpen, setIsClose }) => {

  const { onShowAlert, establishment, event } = useContext(DefaultContext)

  const [isLoading, setIsLoading] = useState(false);
  const [originalFile, setoriginalFile] = useState(null);

  useEffect(() => {
    if (!open) return formik.resetForm();
  }, [open])

  const validateList = useCallback((rows) => {
    rows.forEach((row, index) => {
      if(row.email === null && row.cpf === null)
        throw new Error(`Linha ${index + 1}. Sem identificação, email ou cpf`)
      if(typeof row.value !== 'number' || isNaN(row.value))
        throw new Error(`Linha ${index + 1}. Campo valor não é um número válido`)
      if(typeof row.consummation !== 'number' || isNaN(row.consummation))
        throw new Error(`Linha ${index + 1}. Campo consumação não é um número válido`)
    })
  },[])

  function readCSV(csv, saveData) {
    const results = Papa.parse(csv, { header: true }) // object with { data, errors, meta }
    const rows = results.data.filter(row => row !== '') // array of objects
    const datas = rows.map(row => ({
      event_id_ingresse: row['ID Evento'] === undefined ? null : row['ID Evento'],
      event_ingresse: row['Evento'] === undefined ? null : row['Evento'],
      name: row['Nome Portador'] === undefined ? null : row['Nome Portador'],
      email: row['Email Portador'] === undefined ? null : row['Email Portador'],
      phone: row['Telefone'] === undefined ? null : Number(row['Telefone'].toString().replace(/\D/g, "")),
      cpf: row['CPF'] === undefined ? null : Number(row['CPF'].toString().replace(/\D/g, "")),
      ticket: row['Ingresso'] === undefined ? null : row['Ingresso'],
      value: row['Valor'] === undefined ? null : Math.round(Number(row['Valor'] * 100)),
      consummation: row['Consumacao'] === undefined ? null : Math.round(Number(row['Consumacao'] * 100)),
      used: false,
    }))

    try {
      validateList(datas)
    } catch (error) {
      onShowAlert(PreAlert.error(error.message))
      setIsLoading(false)
      return;
    }

    saveData(datas)
  }

  function sendFile(file, saveData) {
    const reader = new FileReader();
    reader.onload = function (e) {
      // Use reader.result
      readCSV(reader.result, saveData)
    }
    reader.readAsText(file);
  }

  const handleFiles = e => {
    const [file] = Array.from(e.target.files)
    setoriginalFile(file)
    formik.handleChange(e)
  }

  const formik = useFormik({
    initialValues: {
      file: '',
    },
    validate,
    onSubmit: values => {

      const onSuccess = () => {
        onShowAlert(PreAlert.success('Lista importada com sucesso'))
        setIsClose();
      }
      const onError = (error) => {
        console.error(error);
        onShowAlert(PreAlert.error('Falhou ao importar a lista'))
      }

      setIsLoading(true);
      try {
        sendFile(originalFile, datas => {
          new EarlyConsummationDB(establishment.id, event.id)
          .writeBatch(datas)
          .then(onSuccess)
          .catch(onError)
          .finally(() => setIsLoading(false))
        })
      } catch (error) {
        console.error(error);
        onError();
        setIsLoading(false)
      } 
    },
  })


  return (
    <Modal
      id={'modal-register-EarlyConsummation'}
      titleId={'Cadastro e Update de Entrada Antecipada'}
      isOpen={open}
      setIsOpen={setIsOpen}
      isStaticBackdrop={true}
      isScrollable={false}
      isCentered={true}
      size="lg" // 'sm' || 'lg' || 'xl' 
      isAnimation={true}
      onSubmit={formik.handleSubmit}
    >
      <ModalHeader setIsOpen={setIsOpen}>
        <ModalTitle id="register-EarlyConsummationSelected">Importando Consumação Antecipada</ModalTitle>
      </ModalHeader>
      <ModalBody>
        <form noValidate onSubmit={formik.handleSubmit}>
          {/* Inputs */}
          <div className="row g-4">
            {/* File */}
            <FormGroup id="file" label="Nome" className='col-md-8'>
              <Input
                type='file'
                accept={'.csv'}
                onChange={handleFiles}
                onBlur={formik.handleBlur}
                value={formik.values.file}
                isValid={formik.isValid}
                isTouched={formik.touched.file}
                invalidFeedback={formik.errors.file}
                validFeedback='Assim está bom!'
                placeholder='Entrada de Bilheteria X'
              />
            </FormGroup>
          </div>
          {/* Buttons */}
          <div className="row pt-4">
            <div className="col-md-4 offset-md-8">
              <div className='d-flex justify-content-evenly'>
                <BtnCancel setIsClose={setIsClose} />
                {isLoading ? (
                  <BtnLoad />
                ) : (
                  <BtnConfirm isDisable={!formik.isValid} />
                )}
              </div>
            </div>
          </div>
        </form>
      </ModalBody>
    </Modal>
  )
}

export default memo(ModalRegisterEarlyConsummation);