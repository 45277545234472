import { where } from "firebase/firestore";

import { memo, useContext, useEffect, useState, useMemo } from "react";
import { DefaultContext } from "../../../../../contexts/default";
import ClouserCashierEventData from "../../../../../database/wrappers/eventData/clouserCashier";
import { ROLE } from "../../../../../types/roles";
import RelatorioCaixas from "./relatorioCaixas";
import Totais from "./totais";
import { TABS } from "../tabs";
import ReportsBySector from "./reportsBySector";
import TotaisSector from "./totaisBySector";
import useTotalCashier from "../../../../../hooks/TotaisReports/useTotalCashier";

const TabCaixa = ({ hidden, SubTabs }) => {
  const {cashiers, CashiersBySector} = useTotalCashier();


  return (
    <div className='row'>
      <div hidden={hidden} className='col-xxl-12'>
        <div hidden={hidden || SubTabs !== TABS.CAIXA.subTabs.GERAL.id} >
          <Totais data={cashiers} />
          <RelatorioCaixas cashiers={cashiers} />
        </div>

        <div hidden={hidden || SubTabs !== TABS.CAIXA.subTabs.POR_SECTOR.id}>
          <TotaisSector data={CashiersBySector} />
          <ReportsBySector cashiers={CashiersBySector} />
        </div>

      </div>
    </div>


  );
}

export default memo(TabCaixa)