import {  useMemo } from "react";
import useTotalSales from "./useTotalSales";


const useTotalStock = () => {
  const { SalesCalc } = useTotalSales();
  const StockCalc = useMemo(() => {
    const stock: any[] = [];
    if (SalesCalc && SalesCalc.salesWithoutRefunds) {
      SalesCalc.salesWithoutRefunds.forEach((sale: any) => {
        const sectorId = sale.sectorId;
        sale.products.forEach((product: any) => {
          const productId = product.id;
          const productQtd = product.qtd;
          const productReversed = product.qtdReversed || 0;
          const productBySector = stock.find(item => item.id === productId && item.sectorId === sectorId);
          if (productBySector) {
            productBySector.qtdReversed += productReversed;
            productBySector.qtd += productQtd;
          } else {
            stock.push({
              sectorId: sectorId,
              qtd: productQtd,
              id: productId,
              price: product.price,
              qtdReversed: productReversed,
            });
          }
        });
      });
    }


    return stock;
  }, [SalesCalc]);

  return { StockCalc };
};

export default useTotalStock;
