import React, { useCallback, useMemo, useContext, useEffect, useState } from 'react';
import PageWrapper from '../../../../layout/PageWrapper/PageWrapper';
import SubHeader, { SubHeaderLeft } from '../../../../layout/SubHeader/SubHeader';
import Page from '../../../../layout/Page/Page';
import Breadcrumb from '../../../../components/bootstrap/Breadcrumb';
import { demoPages } from '../../../../menu';
import { DefaultContext } from '../../../../contexts/default';
import Card, { CardBody, CardHeader, CardLabel, CardTitle } from '../../../../components/bootstrap/Card';
import Button from '../../../../components/bootstrap/Button';
import TableCustom from '../../../../components/MyCustom/TableCustom';
import SelectEstablishment from '../../../../components/MyCustom/SelectEstablishment';
import SelectEvent from '../../../../components/MyCustom/SelectEvent';
import ModalRegisterStockProducts from '../../../../components/modals/StockProducts/ModalRegister'
import ProductDB from '../../../../database/wrappers/product';
import useDarkMode from '../../../../hooks/useDarkMode';
import StockProductDB from '../../../../database/wrappers/stockProduct';
import { orderBy } from 'firebase/firestore';
import SelectSector from '../../../../components/MyCustom/SelectSector';
import LogoUgetBlack from '../../../../assets/img/logo_uget_black.png'
import LogoUget from '../../../../assets/img/logo_uget.png'

const StockProducts = () => {
  const { darkModeStatus } = useDarkMode();

  const { estabSelected, eventSelected, sectorSelected, onShowAlert } = useContext(DefaultContext)
  const [products, setProducts] = useState(null)
  const [stockProductsDicionary, setstockProductsDicionary] = useState(null)
  const [productsRender, setProductsRender] = useState([])
  const [productSelected, setProductSelected] = useState(null);
  const [showModal, setshowModal] = useState(false);

  useEffect(() => {
    if (!estabSelected) return;
    const onSubscribe = new ProductDB(estabSelected).on(setProducts, orderBy('name', 'asc'));
    return onSubscribe
  }, [estabSelected])

  useEffect(() => {
    if (!estabSelected || !eventSelected || !sectorSelected) return;
    const onSubscribe = new StockProductDB(estabSelected, eventSelected, sectorSelected).on(datas => {
      const dicionary = {};
      datas.forEach(data => dicionary[data.id] = data)
      setstockProductsDicionary(dicionary)
    });
    return onSubscribe;
  }, [estabSelected, eventSelected, sectorSelected])

  useEffect(() => {
    if (products !== null && stockProductsDicionary !== null) {
      const productsRender = products.map(product => {
        const stock = stockProductsDicionary[product.id];
        return {
          ...product,
          ...stock,
          active: !stock ? true : stock.active,
          stockExist: !!stock,
        }
      })

      setProductsRender(productsRender);
    }
  }, [products, stockProductsDicionary])

  const handleOpenModalEdit = useCallback((item) => {
    setProductSelected(item)
    setshowModal(true)
  }, [])

  const handleChangeStatus = useCallback((id, item) => {
    const onSuccess = () => { };
    const onError = () => {
      onShowAlert({
        title: 'ERRO!',
        msm: 'Não foi possível atualizar o status do produto',
        icon: 'Error',
        color: 'danger'
      })
    };
    if (item.stockExist) {
      new StockProductDB(estabSelected, eventSelected, sectorSelected)
        .update(item.id, {
          active: !item.active
        })
        .then(onSuccess)
        .catch(onError)
    } else {
      new StockProductDB(estabSelected, eventSelected, sectorSelected)
        .define(item.id, {
          active: !item.active
        })
        .then(onSuccess)
        .catch(onError)
    }
  }, [estabSelected, eventSelected, sectorSelected])

  const columns = useMemo(() => ([
    {
      label: 'Imagem',
      field: 'image_url',
      format: row => (
        <img
          src={row.image_url || (darkModeStatus ? LogoUget : LogoUgetBlack)}
          alt="Imagem"
          width={54}
          height={row.image_url ? 54 : 10}
        />
      ),
      noExport: true
    },
    {
      label: 'Nome',
      field: 'name',
    },
    {
      label: 'Quantidade',
      field: '{row}',
      format: row => (
        <>
          {row.qtd > 5 ? (
            <Button
              color='success'
              icon='CheckCircle'
              className='text-nowrap text-black'>
              {row.qtd}
            </Button>
          ) : (
            <Button
              color={row.qtd > 0 ? 'warning' : 'danger'}
              icon={row.qtd > 0 ? 'Warning' : 'Error'}
              className='text-nowrap text-black'>
              {row.qtd || 0}
            </Button>
          )}

        </>
      ),
      formatExport: row => row.qtd || 0
    },
    {
      label: 'Ações',
      field: 'actions',
      format: row => (
        <div className='w-50 d-flex justify-content-between'>
          <Button
            color={darkModeStatus ? 'light' : 'dark'}
            icon="Edit"
            hoverShadow="lg"
            size="lg"
            text=''
            onClick={() => handleOpenModalEdit(row)}
          />
        </div>
      ),
      noExport: true,
    },
  ]), [darkModeStatus, handleChangeStatus, handleOpenModalEdit])


  return (
    <>
      <PageWrapper title={demoPages.sector.subMenu.stockProducts.text}>
        <SubHeader>
          <SubHeaderLeft>
            <Breadcrumb
              list={[
                { title: 'Setor', to: '/sector/stockProducts' },
                { title: 'Estoque Produtos', to: '/sector/stockProducts' },
              ]}
            />
          </SubHeaderLeft>
        </SubHeader>
        <Page container='fluid'>
          <Card>
            <CardHeader>
              <CardLabel icon='AllInbox' iconColor={darkModeStatus ? 'light' : 'dark'}>
                <CardTitle>Estoque de Produtos</CardTitle>
              </CardLabel>
            </CardHeader>
            <CardBody className='table-responsive'>
              <div className='row d-flex align-items-center mt-4 mx-1' >
                <SelectEstablishment />
                <SelectEvent />
                <SelectSector />
              </div>
              <TableCustom
                titlePdf='Estoque de Produtos'
                icon="AllInbox"
                columns={columns}
                rows={productsRender}
                fileName="Estoque-de-produtos"
              />
            </CardBody>
          </Card>
        </Page>
      </PageWrapper>

      <ModalRegisterStockProducts
        open={showModal}
        setIsOpen={setshowModal}
        setIsClose={() => setshowModal(false)}
        stockSelected={productSelected}
      />
    </>
  );
};

export default StockProducts;
