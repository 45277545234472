import React, { createContext, useContext, useEffect, useState } from "react";
import ResultsContextInterface from "../interface/results";
import moment from 'moment'
import dateFormat from 'dateformat';
import ClouserCashier from "../database/entities/eventData/clouserCashier.entity";
import NFC from "../database/entities/eventData/nfc.entity";
import SelectOption from "../interfaces/selectOption";
import ActivationsNFCEventData from "../database/wrappers/eventData/activationsNFC";
import { DefaultContext } from "./default";

export const ResultsContext = createContext<ResultsContextInterface>({} as any)

export default function ResultsProvider({ children }: any) {

  const [cashiers, setcashiers] = useState<ClouserCashier[]>([])
  const [cashierSelected, setcashierSelected] = useState<string>('')
  const [cashiersRecharge, setcashiersRecharge] = useState<SelectOption[]>([])
  const [cashiersConsume, setcashiersConsume] = useState<SelectOption[]>([])
  const [cashierRechargeSelected, setcashierRechargeSelected] = useState('')
  const [cashierConsumeSelected, setcashierConsumeSelected] = useState('')
  const [nfcs, setnfcs] = useState<NFC[]>([])
  const [nfcSelected, setnfcSelected] = useState<string>('')
  const [nfcDicionary, setnfcDicionary] = useState<any>({})
  const [cashierDicionary, setcashierDicionary] = useState<any>({})
  const [totemsRechargeDicionary, settotemsRechargeDicionary] = useState<any>({})
  const [sectorsDicionary, setsectorsDicionary] = useState<any>({})
  const [productsDicionary, setproductsDicionary] = useState<any>({})
  const [dataFilter, setdataFilter] = useState({
    store: '#',
    firstDate: dateFormat(moment().add(-1, 'month').toDate(), 'yyyy-mm-dd'),
    secondDate: dateFormat(new Date(), 'yyyy-mm-dd'),
  })
  const {estabSelected, eventSelected} = useContext(DefaultContext)
    
  useEffect(() => {
    if(!estabSelected || !eventSelected) return
      
    const fetchDicionary = async () => {
        const nfc = await new ActivationsNFCEventData(estabSelected,eventSelected).getAllDicionary();
        setnfcDicionary(nfc);
    }

    fetchDicionary();
}, [eventSelected, estabSelected])

  return(
    <ResultsContext.Provider value={{
      setdataFilter,
      dataFilter,
      cashiers, 
      setcashiers,
      cashiersRecharge,
      setcashiersRecharge,
      cashiersConsume,
      setcashiersConsume,

      cashierSelected, 
      setcashierSelected,
      cashierRechargeSelected,
      setcashierRechargeSelected,
      cashierConsumeSelected,
      setcashierConsumeSelected,
      
      nfcs,
      setnfcs,
      nfcSelected, 
      setnfcSelected,
      nfcDicionary, 
      setnfcDicionary,
      cashierDicionary, 
      setcashierDicionary,
      sectorsDicionary, 
      setsectorsDicionary,
      productsDicionary, 
      setproductsDicionary,
      totemsRechargeDicionary, 
      settotemsRechargeDicionary,
    }}>
      { children }
    </ResultsContext.Provider>
  );
}