
import React, { memo } from 'react';
import CardValue from '../../../components/MyCustom/Dashboard/CardValue';
import GraphicDash from '../../../components/MyCustom/Dashboard/GraphicDash';
import Card, { CardActions, CardBody, CardHeader, CardLabel, CardTitle } from '../../../components/bootstrap/Card';
import Money from '../../../helpers/utils/money/money';
import useTotalGeral from '../../../hooks/TotaisPdf/useTotalGeral';
import useDarkMode from '../../../hooks/useDarkMode';


const Faturamento = ({ }) => {
  const { TotalCalc } = useTotalGeral();
  const { darkModeStatus } = useDarkMode();


  return (
    <Card stretch>
      <CardHeader>
        <CardLabel icon='PieChart' iconColor={!darkModeStatus ? 'dark' : 'light'}>
          <CardTitle className='d-flex flex-row align-items-center '>
            <CardActions>
              FATURAMENTO
            </CardActions>
          </CardTitle>
        </CardLabel>
      </CardHeader>
      <CardBody className='row'>
        <div className='col-sm-12 col-xxl-6'>
          <GraphicDash
            labels={[
              'Crédito',
              'Débito',
              'PIX',
              'Vale Refeição',
              'Dinheiro',
            ]}
            series={[
              Money.centsToCoin(TotalCalc?.totalCredit || 0),
              Money.centsToCoin(TotalCalc?.totalDebit || 0),
              Money.centsToCoin(TotalCalc?.totalPix || 0),
              0,
              Money.centsToCoin(TotalCalc?.totalCash || 0),
            ]}
          />
        </div>

        <div className='col-xxl-6'>

          <div className='row' >
            <div className='col-md-12'>
              <CardValue
                color='dark'
                label='TOTAL'
                value={Money.centsToMaskMoney(TotalCalc.totalFaturamento)}
              />
            </div>
            <div className='col-md-6'>
              <CardValue
                color='primary'
                label='CRÉDITO'
                description='Valor faturado de crédito durante o evento.'
                value={Money.centsToMaskMoney(TotalCalc?.totalCredit || 0)}
              />
            </div>
            <div className='col-md-6'>
              <CardValue
                color='secondary'
                label='DÉBITO'
                description='Valor faturado de débito durante o evento.'
                value={Money.centsToMaskMoney(TotalCalc?.totalDebit || 0)}
              />
            </div>
            <div className='col-md-6'>
              <CardValue
                color='success'
                label='PIX'
                description='Valor faturado de pix durante o evento.'
                value={Money.centsToMaskMoney(TotalCalc?.totalPix || 0)}
              />
            </div>
            <div className='col-md-6' >
              <CardValue
                color='info'
                label='VALE REFEIÇÃO'
                description='Valor faturado de vale refeições durante o evento.'
                value={Money.centsToMaskMoney(TotalCalc?.totalMealTicket)}
              />
            </div>
            <div className='col-md-6'>
              <CardValue
                color='warning'
                label='DINHEIRO'
                description='Valor faturado de dinheiro durante o evento.'
                value={Money.centsToMaskMoney(TotalCalc?.totalCash || 0)}
              />
            </div>
          </div>

        </div>
      </CardBody>
    </Card >
  )
}

export default memo(Faturamento)