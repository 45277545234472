import {
	collection,
	CollectionReference,
	DocumentData,
	getDocs,
	getFirestore,
	onSnapshot,
	query,
	QueryConstraint,
	QueryDocumentSnapshot,
	Timestamp,
	Unsubscribe,
	where,
} from 'firebase/firestore';
import app from '../../config';
import EntrancePay from '../../entities/eventData/entrancesPay.entity';

class EntrancePayEventData {
	protected readonly colRefStr: string;
	protected readonly colRef: CollectionReference<DocumentData>;

	constructor(estabId: string, eventId: string) {
		this.colRefStr = `EstablishmentsData/${estabId}/EventsData/${eventId}/EntrancesPay`;
		this.colRef = collection(getFirestore(app), this.colRefStr);
	}

	async getAll(): Promise<EntrancePay[]> {
		const snapshot = await getDocs(query(this.colRef))
		return snapshot.docs.map(doc => ({
			id: doc.id,
			...doc.data(),
		})) as any
	}

	on(listener: (data: EntrancePay[]) => void, ...params: QueryConstraint[]): Unsubscribe {
		return onSnapshot(query(this.colRef, ...params), snapshot => {
			const datas: EntrancePay[] = [];
			snapshot.forEach(doc => datas.push({ id: doc.id, ...doc.data() as any } as EntrancePay))
			listener(datas)
		})
	}

	onPagination(listener: (data: EntrancePay[], docs: QueryDocumentSnapshot<DocumentData>[]) => void, ...params: QueryConstraint[]): Unsubscribe {
		return onSnapshot(query(this.colRef, ...params), snapshot => {
			const datas: EntrancePay[] = [];
			snapshot.forEach(doc => datas.push({ id: doc.id, ...doc.data() as any } as EntrancePay))
			listener(datas, snapshot.docs)
		})
	}

	async getAllInterval(startDate: Date, endDate: Date): Promise<EntrancePay[]> {
		const snapshot = await getDocs(query(this.colRef,
			where('created_at', '>=', Timestamp.fromDate(startDate)),
			where('created_at', '<=', Timestamp.fromDate(endDate))
		))
		return snapshot.docs.map(doc => ({
			id: doc.id,
			...doc.data(),
		})) as any
	}
	async getBySector(sectorId: string): Promise<EntrancePay[]> {
		const snapshot = await getDocs(query(this.colRef,
			where('sectorId', '==', sectorId)
			
		))
		return snapshot.docs.map(doc => ({
			id: doc.id,
			...doc.data(),
		})) as any
	}
}


export default EntrancePayEventData;
