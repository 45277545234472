import React, { memo, useContext, useMemo } from 'react';
import Card, { CardActions, CardBody, CardHeader, CardLabel, CardTitle } from '../../../components/bootstrap/Card';
import Money from '../../../helpers/utils/money/money';
import useDarkMode from '../../../hooks/useDarkMode';
import dateFormat from 'dateformat';
import Button from '../../../components/bootstrap/Button';
import TableCustom from '../../../components/MyCustom/TableCustom';
import { useNavigate } from 'react-router-dom';
import { DefaultContext } from '../../../contexts/default';

const Eventos = ({ eventsRender }) => {
    const { darkModeStatus } = useDarkMode();

    const { setestabSelected, seteventSelected } = useContext(DefaultContext)

    const navigate = useNavigate()

    const columns = useMemo(() => ([
        {
            label: 'Vis',
            field: 'x',
            format: row => (
                <Button
                    icon='RemoveRedEye'
                    color={!darkModeStatus ? 'dark' : 'light'}
                    isOutline
                    onClick={() => {
                        const params = {
                            estabId: row.estabId,
                            eventId: row.id,
                        }
                        setestabSelected(params.estabId)
                        seteventSelected(params.eventId)
                        navigate({
                            pathname: '/contEvent',
                            search: new URLSearchParams(params).toString()
                        })
                    }}
                />
            ),
            noExport: true,
        },
        {
            label: 'Data de Início',
            field: 'startDate',
            format: row => dateFormat(row.startDate, 'dd/mm/yy HH:MM'),
            formatExport: value => dateFormat(value, 'dd/mm/yy HH:MM'),
        },
        {
            label: 'Data de Termino',
            field: 'endDate',
            format: row => dateFormat(row.endDate, 'dd/mm/yy HH:MM'),
            formatExport: value => dateFormat(value, 'dd/mm/yy HH:MM'),
        },
        {
            label: 'Nome do Evento',
            field: 'name',
        },
        {
            label: 'Fechamento',
            field: 'closured',
            format: row => (
                <Button
                    icon={row.closured ? 'Check' : 'Error'}
                    color={row.closured ? 'success' : 'danger'}
                />
            ),
            formatExport: value => value ? 'Concluído' : 'Não Concluído',
        },
        {
            label: 'Vendedor Responsável',
            field: 'seller',
        },
        {
            label: 'Movimentado',
            field: 'totalBusy',
            format: row => Money.centsToMaskMoney(row.totalBusy),
            formatExport: value => Money.centsToCoinCsv(value),
        },
        {
            label: 'uGet (Nota Fiscal)',
            field: 'totalUget',
            format: row => Money.centsToMaskMoney(row.totalUget),
            formatExport: value => Money.centsToCoinCsv(value),
        },
        {
            label: 'Intermediado',
            field: 'totalIntermediated',
            format: row => Money.centsToMaskMoney(row.totalIntermediated),
            formatExport: value => Money.centsToCoinCsv(value),
        },
        {
            label: 'Repasse',
            field: 'fullTransfer',
            format: row => Money.centsToMaskMoney(row.fullTransfer),
            formatExport: value => Money.centsToCoinCsv(value),
        },
        {
            label: 'Liquido',
            field: 'totalLiquid',
            format: row => Money.centsToMaskMoney(row.totalLiquid),
            formatExport: value => Money.centsToCoinCsv(value),
        },
    ]), [navigate, darkModeStatus]);

    return (
        <Card>
            <CardHeader>
                <CardLabel icon='Festival' iconColor={!darkModeStatus ? 'dark' : 'light'}>
                    <CardTitle className='d-flex flex-row align-items-center'>
                        <CardActions>
                            Eventos
                        </CardActions>
                    </CardTitle>
                </CardLabel>
            </CardHeader>
            <CardBody className='table-responsive p-5'>
                <TableCustom
                    titlePdf='Eventos'
                    icon="Festival"
                    fontSize={8}
                    rows={eventsRender}
                    columns={columns}
                    keyExtractor={r => r.id}
                    fileName={'Eventos'}
                />
            </CardBody>
        </Card>
    );
};

export default memo(Eventos);