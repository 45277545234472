import { limit, QueryConstraint, Unsubscribe, where } from "firebase/firestore";
import Toten from "../entities/totems/toten.entity";
import FireTotenPipe from "../utils/pipe";
import EstablishmentDB from "./establishment";
import NumbersDB from "./numbers";

class TotenDB extends FireTotenPipe {

  public static readonly colName = 'Totems'

  constructor(private idEstab: string) {
    if(!idEstab)
      throw new Error('Informe o id do estabelecimento')
    super(`${EstablishmentDB.colName}/${idEstab}/${TotenDB.colName}`);
  }

  public async create(data: Toten): Promise<any> {
    const newNumber = (await new NumbersDB(this.idEstab).incrementTerminal()).toString();
    data.terminal = this.completeTerminalNumber(newNumber);
    return this._create(data);
  }
  public update(id: string, data: Toten): Promise<any> {
    return this._update(id, data);
  }
  public delete(id: string): Promise<any> {
    return this._delete(id);
  }
  public getAll(...params: QueryConstraint[]): Promise<Toten[]> {
    return this._getAll<Toten>(...params);
  }
  public get(id: string): Promise<Toten> {
    return this._get(id);
  }
  public on(listener: (data: Toten[]) => void, ...params: QueryConstraint[]): Unsubscribe {
    return this._on(listener, ...params);
  }

  private completeTerminalNumber(number: string) {
    while(number.length < 8)
      number = '0' + number;
    return number
  }

}

export default TotenDB;