import React, { useContext } from "react";
import Logo from "../../Logo";
import Icon from "../../icon/Icon";
import bg from '../../../assets/img/bg.png'
import { DefaultContext } from "../../../contexts/default";
import LogoUget from '../../../assets/img/logo_uget_pdf.png'


const backgroundStyle = {
    backgroundImage: `url(${bg})`,
    backgroundSize: 'cover',
    backgroundPosition: 'top',
    backgroundRepeat: 'noRepeat',
};
const HeaderSector = ({  }) => {

    const { event, establishment } = useContext(DefaultContext)
    return (
        <div className='py-2  px-4 mb-5' style={backgroundStyle}>
            <div className='d-flex justify-content-between '>
                <div>
                    <div className="d-flex align-content-center gap-2">
                        <Icon icon="StoreMallDirectory" color="warning" size="2x" />
                        <h1 className="fs-6 text-white  text-uppercase m-0 my-auto">{establishment.name}</h1>
                    </div>
                    <div className="d-flex align-content-center   gap-2 ">
                        <Icon icon="Storefront" color="warning"size="2x" />
                        <h1 className="fs-6 text-white   text-uppercase m-0 my-auto ">{event.name}</h1>
                    </div>

                    {/* {sector.name &&
                        <div className="d-flex align-content-center   gap-2">
                            <Icon icon="LibraryBooks" color="warning" />
                            <h1 className="fs-6 text-white fw-light text-uppercase m-0 ">{sector.name}</h1>
                        </div>

                    } */}
                </div>
                <img src={LogoUget} width={150} height={20.96} className="my-auto" />
            </div>

        </div>
    );
}

export default HeaderSector;