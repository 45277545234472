export const TRIBUTARY_SITUATION = {
  ISENTO: 0,
  TRIBUTO_18: 1,
  SUBS_TRIBUTARIO: 2,
} as const;

export function convertTrybuIntToPTBR(option: number) {
  if(option === TRIBUTARY_SITUATION.ISENTO) return 'Isento';
  if(option === TRIBUTARY_SITUATION.TRIBUTO_18) return 'Tributado 18%';
  if(option === TRIBUTARY_SITUATION.SUBS_TRIBUTARIO) return 'Substituição Tributária';
  return '';
}

export function convertTrybuImport(option: string) {
  if(option === 'i') return TRIBUTARY_SITUATION.ISENTO;
  if(option === 't') return TRIBUTARY_SITUATION.TRIBUTO_18;
  if(option === 's') return TRIBUTARY_SITUATION.SUBS_TRIBUTARIO;
  return TRIBUTARY_SITUATION.ISENTO;
}