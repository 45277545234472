import {
  collection,
  CollectionReference,
	DocumentData,
	getDocs,
	getFirestore,
	onSnapshot,
	query,
	QueryConstraint,
	Unsubscribe,
} from 'firebase/firestore';
import app from '../../config';
import ClouserTotem from '../../entities/eventData/clouserTotem.entity';

class ClouserTotemRechargeEventData {
	protected readonly colRefStr: string;
  protected readonly colRef: CollectionReference<DocumentData> ;

	constructor(estabId: string, eventId: string) {
		this.colRefStr = `EstablishmentsData/${estabId}/EventsData/${eventId}/ClosureTotemRecharge`;
    this.colRef = collection(getFirestore(app), this.colRefStr);
	}

	async getAll(): Promise<ClouserTotem[]> {
		const snapshot = await getDocs(query(this.colRef))
		
		const array: ClouserTotem[] = [];
		snapshot.forEach(item => array.push({
			id: item.id,
			...item.data()
		} as any))
		
		return array
	}

	on(listener: (data: ClouserTotem[]) => void, ...params: QueryConstraint[]): Unsubscribe {
		return onSnapshot(query(this.colRef, ...params), snapshot => {
      const datas: ClouserTotem[] = [];
      snapshot.forEach(doc => datas.push({ id: doc.id, ...doc.data() as any } as ClouserTotem))
      listener(datas)
    })
	}

	onDicionary(listener: (data: any) => void, ...params: QueryConstraint[]): Unsubscribe {
		return onSnapshot(query(this.colRef, ...params), snapshot => {
      const dicionary: any = {};
      snapshot.forEach(doc => dicionary[doc.id] = doc.data())
      listener(dicionary)
    })
	}

	onDatas(listener: (data: ClouserTotem[], dicionary: any) => void, ...params: QueryConstraint[]): Unsubscribe {
		return onSnapshot(query(this.colRef, ...params), snapshot => {
      const datas: ClouserTotem[] = [];
			const dicionary: any = {};
      snapshot.forEach(doc => {
				const data = { id: doc.id, ...doc.data() as any };
				datas.push(data)
				dicionary[doc.id] = data;
			})
      listener(datas, dicionary)
    })
	}

	onDatasNewChildren(listener: (data: ClouserTotem[], dicionary: any) => void, ...params: QueryConstraint[]): Unsubscribe {
    let size = 0;
		return onSnapshot(query(this.colRef, ...params), snapshot => {
      if(size === snapshot.size) return;
      size = snapshot.size
      const datas: ClouserTotem[] = [];
			const dicionary: any = {};
      snapshot.forEach(doc => {
				const data = { id: doc.id, ...doc.data() as any };
				datas.push(data)
				dicionary[doc.id] = data;
			})
      listener(datas, dicionary)
    })
	}

}

export default ClouserTotemRechargeEventData;
