import React, { memo } from "react";
import Button from "../../../bootstrap/Button";
import Dropdown, { DropdownItem, DropdownMenu, DropdownToggle } from "../../../bootstrap/Dropdown";
import Nav, { NavItem, NavLinkDropdown } from "../../../bootstrap/Nav";

function HeaderLeftMobile({ tabs, setActiveTab, setActiveSubTab }) {

  function renderTab(tab) {
		if(tab.subTabs) {
			return (
        <DropdownItem>
          <NavItem>
            <Dropdown>
              <DropdownToggle>
                <NavLinkDropdown>
                  {tab.label}
                </NavLinkDropdown>
              </DropdownToggle>
              <DropdownMenu>
                {Object.keys(tab.subTabs).map(key => (
                  <DropdownItem
                    onClick={() => {
                      setActiveSubTab(tab.subTabs[key].id);
                      setActiveTab(tab.id);
                    }}>
                      {tab.subTabs[key].label}
                  </DropdownItem>
                ))}
              </DropdownMenu>
            </Dropdown>
          </NavItem>
        </DropdownItem>
			);
		} else {
			return (
				<DropdownItem
          onClick={() => setActiveTab(tab.id)}
        >
          {tab.label}
        </DropdownItem>
			);
		}
  }

  return (
    <Nav className='d-flex justify-content-between w-100'>
      <NavItem>
        <Dropdown>
          <DropdownToggle>
            <Button className='btn btn-primary '>
              RELATÓRIOS
            </Button>
          </DropdownToggle>

          <DropdownMenu >
            {Object.keys(tabs).map(key => renderTab(tabs[key]))}
          </DropdownMenu>
        </Dropdown>
      </NavItem>
    </Nav>
  );
}

export default memo(HeaderLeftMobile);