import { useContext, useMemo, useState, useEffect } from "react";
import useTotalSales from "./useTotalSales";
import { DefaultContext } from "../../contexts/default";
import ProductDB from "../../database/wrappers/product";
import convertArrayToObject from "../../utils/convertArrayToObject";
import SectorDB from "../../database/wrappers/sector";
import ClouserCashierEventData from "../../database/wrappers/eventData/clouserCashier";
import EntranceDB from "../../database/wrappers/entrance";

const useDicionary = () => {
  const { estabSelected,eventSelected } = useContext(DefaultContext);
  const [productsDicionary, setproductsDicionary] = useState([]);
  const [sectorsDicionary, setsectorsDicionary] = useState([]);
  const [cashierDicionary, setcashierDicionary] = useState([]);
  const [entrancesDicionary, setentrancesDicionary] = useState([]);
  useEffect(() => {
    if (!estabSelected) return;
    const onSubscriber = new ProductDB(estabSelected).getAll()
      .then(datas => {
        setproductsDicionary(convertArrayToObject(datas, 'id'));
      })
      .catch(error => {
        console.error('Erro ao obter dados:', error);
      });
    return onSubscriber;
  }, [estabSelected]);

  useEffect(() => {
    if (!estabSelected) return;
    const onSubscriber = new SectorDB(estabSelected).getAll()
      .then(datas => {
        setsectorsDicionary(convertArrayToObject(datas, 'id'));
      })
      .catch(error => {
        console.error('Erro ao obter dados:', error);
      });
    return onSubscriber;
  }, [estabSelected]);

  useEffect(() => {
    if (!estabSelected && !eventSelected) return;
    const onSubscriber = new ClouserCashierEventData(estabSelected,eventSelected).getAll()
      .then(datas => {
        setcashierDicionary(convertArrayToObject(datas, 'id'));
      })
      .catch(error => {
        console.error('Erro ao obter dados:', error);
      });
    return onSubscriber;
  }, [estabSelected,eventSelected]);

  useEffect(() => {
    if (!estabSelected && !eventSelected) return;
    const onSubscriber = new EntranceDB(estabSelected,eventSelected).getAll()
      .then(datas => {
        setentrancesDicionary(convertArrayToObject(datas, 'id'));
      })
      .catch(error => {
        console.error('Erro ao obter dados:', error);
      });
    return onSubscriber;
  }, [estabSelected,eventSelected]);




  return { productsDicionary,sectorsDicionary,cashierDicionary,entrancesDicionary };
};

export default useDicionary;
