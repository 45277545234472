import { where } from "firebase/firestore";
import CustoOperacional from "../../database/entities/configUget/custoOperacional";
import { Faturamento, Movimentacao } from "../../database/entities/dashboard/geral";
import Event from "../../database/entities/event.entity";
import ClosureResourcesEvent from "../../database/entities/event/closureEvent";
import EventGeral from "../../database/entities/event/eventGeral.entity";
import ClouserCashierEventData from "../../database/wrappers/eventData/clouserCashier";
import ClouserTotemEventData from "../../database/wrappers/eventData/clouserTotem";
import ClouserTotemRechargeEventData from "../../database/wrappers/eventData/clouserTotemRecharge";
import { TYPE_OF_CHARGE } from "../../types/charge";
import { DEVICE } from "../../types/device";
import { ROLE } from "../../types/roles";
import { EquipamentValues } from "./equipamentTable";

export async function closureCashiersAndTotems(estabSelected: string, eventSelected: string): Promise<any[]> {
  const clousreCashiers = await new ClouserCashierEventData(estabSelected, eventSelected).getAll(where('cashierType', 'in', [ROLE.TICKET, ROLE.RECHARGE]));
  const clousreTotems = await new ClouserTotemEventData(estabSelected, eventSelected).getAll();
  clousreTotems.forEach((item: any) => item.device = DEVICE.TOTEN);
  const clousreTotemsRecharge = await new ClouserTotemRechargeEventData(estabSelected, eventSelected).getAll();
  clousreTotemsRecharge.forEach((item: any) => item.device = DEVICE.TOTEN_RECHARGE);
  const clouseres = [
    ...clousreCashiers,
    ...clousreTotems,
    ...clousreTotemsRecharge,
  ]
  clouseres.sort((a: any, b: any) => {
    const nameA = a.operator ? a.operator.toLowerCase() : a.name.toLowerCase()
    const nameB = b.operator ? b.operator.toLowerCase() : b.name.toLowerCase()
    if(nameA > nameB)
      return 1;
    return -1;
  })
  return clouseres;
}

export function calculateTotalFaturamento(faturamento: Faturamento) {
  const { cash, credit, debit, pix } = faturamento;
  return cash + credit + debit + pix;
}

export function calculateTotalMovimentSystem(faturamento: Faturamento, movimentacao: Movimentacao) {
  const { cash, credit, debit, pix, courtesy } = faturamento;
  const { recargaAntecipada } = movimentacao
  return cash + credit + debit + pix + courtesy + recargaAntecipada;
}

export function calculateTotalDebitCreditPix(faturamento: Faturamento) {
  const { credit, debit, pix } = faturamento;
  return credit + debit + pix;
}

export function calculateFee(faturamento: Faturamento, movimentacao: Movimentacao, eventGeral: EventGeral, closureResourcesEvent: ClosureResourcesEvent) {
  const courtesy = Math.round(faturamento.courtesy * eventGeral.rate.courtesy / 100)
  const cash = Math.round(faturamento.cash * eventGeral.rate.cash / 100);
  const debit = Math.round(faturamento.debit * eventGeral.rate.debit / 100);
  const credit = Math.round(faturamento.credit * eventGeral.rate.credit / 100);
  const pix = Math.round(faturamento.pix * eventGeral.rate.pix / 100);
  const advanced_recharge = Math.round(movimentacao.recargaAntecipada * eventGeral.rate.advance_recharge / 100);
  const advanced_credit = closureResourcesEvent.isAdvanceCredit ? calculateAdvancePurchase(faturamento, eventGeral) : 0

  return { courtesy, cash, debit, credit, pix, advanced_recharge, advanced_credit };
  
}



export function calculateTotalFee(faturamento: Faturamento, movimentacao: Movimentacao, eventGeral: EventGeral, closureResourcesEvent: ClosureResourcesEvent) {
  const {
    courtesy, cash, debit, credit, pix, advanced_recharge, advanced_credit
  } = calculateFee(faturamento, movimentacao, eventGeral, closureResourcesEvent);
  
  return courtesy + cash + debit + credit + pix + advanced_credit + advanced_recharge;
}

export function calculateAdvancePurchase(faturamento: Faturamento, eventGeral: EventGeral) {
  return faturamento.credit * eventGeral.rate.advance_credit / 100;
}

export function calculateTotalInBox(faturamento: Faturamento) {
  const courtesy = faturamento.courtesy;
  const cash = faturamento.cash;
  const debit = faturamento.debit;
  const credit = faturamento.credit;
  const pix = faturamento.pix;
  
  return courtesy + cash + debit + credit + pix;
}

export function calculateNonReturnedMaterial(eventGeral: EventGeral, closureResourcesEvent: ClosureResourcesEvent) {
  const qtdPos = eventGeral.resources.pos - closureResourcesEvent.returned_resources.pos;
  const qtdTotems = eventGeral.resources.totems - closureResourcesEvent.returned_resources.totems;
  const qtdTotems_recharges = eventGeral.resources.totems_recharges - closureResourcesEvent.returned_resources.totems_recharges;
  const qtdCards = eventGeral.resources.cards - closureResourcesEvent.returned_resources.cards;
  const qtdBracelets = eventGeral.resources.bracelets - closureResourcesEvent.returned_resources.bracelets;
  
  const pos = qtdPos * eventGeral.resources.value_loss_pos;
  const totems = qtdTotems * eventGeral.resources.value_loss_totems;
  const totems_recharges = qtdTotems_recharges * eventGeral.resources.value_loss_totems_recharges;
  const cards = qtdCards * eventGeral.resources.value_loss_cards;
  const bracelets = qtdBracelets * eventGeral.resources.value_loss_bracelets;

  return { pos, totems, totems_recharges, cards, bracelets };
}

// export function calculateTotalNonReturnedMaterial(eventGeral: EventGeral, closureResourcesEvent: ClosureResourcesEvent) {
//   const {
//     pos, totems, totems_recharges, cards, bracelets
//   } = calculateNonReturnedMaterial(eventGeral, closureResourcesEvent)

//   return pos + totems + totems_recharges + cards + bracelets;
// }

// export function calculateDevicesRent(eventGeral: EventGeral) {
//   const pos = eventGeral.resources.pos * eventGeral.resources.value_pos;
//   const totems = eventGeral.resources.totems * eventGeral.resources.value_totems;
//   const totems_recharges = eventGeral.resources.totems_recharges * eventGeral.resources.value_totems_recharges;

//   return { pos, totems, totems_recharges };
// }

// function calculateTotalDevicesRent(eventGeral: EventGeral) {
//   const {
//     pos, totems, totems_recharges
//   } = calculateDevicesRent(eventGeral)

//   return pos + totems + totems_recharges;
// }

export function calculateTotalAdditionalServices(closureResourcesEvent: ClosureResourcesEvent, custoOperacional: CustoOperacional, equipamentsValues: EquipamentValues, event: Event) {
  // const totalLossDevices = calculateTotalNonReturnedMaterial(eventGeral, closureResourcesEvent);
  // const rentDevices = calculateTotalDevicesRent(eventGeral);
  const totalTechnical = calculateTotalTechnichal(closureResourcesEvent, custoOperacional, event);

  return  equipamentsValues.total_loss +
          equipamentsValues.total_rent +
          totalTechnical +
          closureResourcesEvent.internet.wifi +
          // closureResourcesEvent.internet.system_wifi +
          closureResourcesEvent.internet.satellite;
}

// export function calculateTotalSpending(eventGeral: EventGeral, closureResourcesEvent: ClosureResourcesEvent) {
//   const totalLossDevices = calculateTotalNonReturnedMaterial(eventGeral, closureResourcesEvent);
//   const rentDevices = calculateTotalDevicesRent(eventGeral);

//   return  totalLossDevices +
//           rentDevices +
//           closureResourcesEvent.internet.wifi +
//           closureResourcesEvent.internet.system_wifi +
//           closureResourcesEvent.internet.satellite;
// }

export function calculateTechnichalAndLiders(closureResourcesEvent: ClosureResourcesEvent, custoOperacional: CustoOperacional) {
  const technical_daily = closureResourcesEvent.technical.daily * custoOperacional.daily.technical;
  const technical_extra_hour = closureResourcesEvent.technical.extra_hour * custoOperacional.hours.technical;
  const lider_daily = closureResourcesEvent.technical.daily * custoOperacional.daily.coordinator;
  const lider_extra_hour = closureResourcesEvent.technical.extra_hour * custoOperacional.hours.coordinator;

  const freight = closureResourcesEvent.technical.freight;
  const ticket = closureResourcesEvent.technical.ticket;
  const uber = closureResourcesEvent.technical.uber;
  const accommodation = closureResourcesEvent.technical.accommodation;
  const food = closureResourcesEvent.technical.food;
  
  return { technical_daily, technical_extra_hour, lider_daily, lider_extra_hour, freight, ticket, uber, accommodation, food };
}

export function calculateTotalTechnichal(closureResourcesEvent: ClosureResourcesEvent, custoOperacional: CustoOperacional, event: Event) {
  const { 
    technical_daily, technical_extra_hour, lider_daily, lider_extra_hour, freight, ticket, uber, accommodation, food
  } = calculateTechnichalAndLiders(closureResourcesEvent, custoOperacional);

  const value_dialy = technical_daily * event.technical.length + lider_daily * event.responsibles.length;
  const value_hours = technical_extra_hour * event.technical.length + lider_extra_hour * event.responsibles.length;

  return value_dialy + value_hours + freight + ticket + uber + accommodation + food;
}

export function calculateTotalUget(faturamento: Faturamento, movimentacao: Movimentacao, eventGeral: EventGeral, closureResourcesEvent: ClosureResourcesEvent, equipamentsValues: EquipamentValues, custoOperacional: CustoOperacional, event: Event) {
  const rentDevices = equipamentsValues.total_rent;
  const lossDevices = equipamentsValues.total_loss;
  const totalFee = calculateTotalFee(faturamento, movimentacao, eventGeral, closureResourcesEvent);
  const totalTechnical = calculateTotalTechnichal(closureResourcesEvent, custoOperacional, event);
  const totalExtraExpenses = closureResourcesEvent.internet.wifi + closureResourcesEvent.internet.satellite;
  
  let subTotalUget = 0;

  if(closureResourcesEvent.typeCharge === TYPE_OF_CHARGE.AUTOMATIC_CHARGE) {
    if(totalFee > rentDevices)
      subTotalUget = totalFee;
    else
      subTotalUget = rentDevices;
  } else if(closureResourcesEvent.typeCharge === TYPE_OF_CHARGE.PAYMENT_RATE_CHARGE) {
    subTotalUget = totalFee;
  } else if(closureResourcesEvent.typeCharge === TYPE_OF_CHARGE.RENT_CHARGE) {
    subTotalUget = rentDevices;
  } else if(closureResourcesEvent.typeCharge === TYPE_OF_CHARGE.TOTAL_CHARGE) {
    subTotalUget = totalFee + rentDevices;
  }

  const totalUget = subTotalUget + lossDevices + totalTechnical + totalExtraExpenses;
  return totalUget < 0 ? 0 : totalUget;
}

export function calculateIRRF(faturamento: Faturamento, movimentacao: Movimentacao, eventGeral: EventGeral, closureResourcesEvent: ClosureResourcesEvent, equipamentsValues: EquipamentValues, custoOperacional: CustoOperacional, event: Event) {
  const totalUget = calculateTotalUget(faturamento, movimentacao, eventGeral, closureResourcesEvent, equipamentsValues, custoOperacional, event);
  const irrf = Math.round(0.015 * totalUget);
  return irrf > 1000 ? irrf : 0;
}

export function calculateTotalUgetLiquid(faturamento: Faturamento, movimentacao: Movimentacao, eventGeral: EventGeral, closureResourcesEvent: ClosureResourcesEvent, equipamentsValues: EquipamentValues, custoOperacional: CustoOperacional, event: Event) {
  const totalUget = calculateTotalUget(faturamento, movimentacao, eventGeral, closureResourcesEvent, equipamentsValues, custoOperacional, event);
  const IRRF = calculateIRRF(faturamento, movimentacao, eventGeral, closureResourcesEvent, equipamentsValues, custoOperacional, event);
  return totalUget - IRRF;
}

export function calculateValueToTransfered(faturamento: Faturamento, movimentacao: Movimentacao, eventGeral: EventGeral, closureResourcesEvent: ClosureResourcesEvent, equipamentsValues: EquipamentValues, custoOperacional: CustoOperacional, event: Event) {
  const totalDebitCreditPix = calculateTotalDebitCreditPix(faturamento)
  const totalUget =  calculateTotalUget(faturamento, movimentacao, eventGeral, closureResourcesEvent, equipamentsValues, custoOperacional, event);
  const totalClient = totalDebitCreditPix - totalUget;
  return totalClient < 0 ? 0 : totalClient;
}