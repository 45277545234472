import { doc, getFirestore, QueryConstraint, Unsubscribe, writeBatch } from "firebase/firestore";
import app from "../config";
import Operators from "../entities/event/operators";
import FirestorePipe from "../utils/pipe";
import EstablishmentDB from "./establishment";
import EventDB from "./event";

class OperatorsDB extends FirestorePipe {

  public static readonly colName = 'Operators'

  constructor(idEstab: string, idEvent: string) {
    if (!idEstab || !idEvent)
      throw new Error('Informe o id do estabelecimento e evento')
    super(`${EstablishmentDB.colName}/${idEstab}/${EventDB.colName}/${idEvent}/${OperatorsDB.colName}`);
  }

  public writeBatch(datas: Operators[]): Promise<any> {
    const batch = writeBatch(getFirestore(app));
    datas.forEach((item) => {
      const newRef = doc(getFirestore(app), this.colRefStr, item.id);
      delete (item as any).id;
      batch.set(newRef, item as any);
    })
    return batch.commit();
  }
  public create(data: Operators): Promise<any> {
    return this._create(data);
  }
  public update(id: string, data: Operators): Promise<any> {
    return this._update(id, data);
  }
  public delete(id: string): Promise<any> {
    return this._delete(id);
  }
  public getAll(...params: QueryConstraint[]): Promise<Operators[]> {
    return this._getAll<Operators>(...params);
  }
  public get(id: string): Promise<Operators> {
    return this._get(id);
  }
  public on(listener: (data: Operators[]) => void, ...params: QueryConstraint[]): Unsubscribe {
    return this._on(listener, ...params);
  }

}

export default OperatorsDB;