import React, { memo, useEffect, useMemo } from 'react';
import Card, { CardActions, CardBody, CardHeader, CardLabel, CardTitle } from '../../../components/bootstrap/Card';
import TableSimples from '../../../components/MyCustom/TableSimples';
import Money from '../../../helpers/utils/money/money';
import useDarkMode from '../../../hooks/useDarkMode';
import TableCustom from '../../../components/MyCustom/TableCustom';

const OutrasDespesas = ({ closureEvent }) => {
  const { darkModeStatus } = useDarkMode();

  const rows = useMemo(() => {
    return [
      {
        name: 'Frete',
        qtd: 0,
        value: 0,
        total: closureEvent?.technical.freight || 0,
      },
      {
        name: 'Passagens',
        qtd: 0,
        value: 0,
        total: closureEvent?.technical.ticket || 0,
      },
      {
        name: 'Uber',
        qtd: 0,
        value: 0,
        total: closureEvent?.technical.uber || 0,
      },
      {
        name: 'Alimentação',
        qtd: 0,
        value: 0,
        total: closureEvent?.technical.food || 0,
      },
      {
        name: 'Estadia',
        qtd: 0,
        value: 0,
        total: closureEvent?.technical.accommodation || 0,
      },
      {
        name: 'Reimbolso',
        qtd: 0,
        value: 0,
        total: 0,
      },
    ]
  },[closureEvent])

  const columns = useMemo(() => ([
    {
      label: 'Nome',
      field: 'name',
    },
    // {
    //   label: 'Qtd Diaria',
    //   field: 'qtd',
    // },
    // {
    //   label: 'Valor Diaria',
    //   field: 'value',
    //   format: row => Money.centsToMaskMoney(row.value)
    // },
    {
      label: 'Total',
      field: 'total',
      format: row => Money.centsToMaskMoney(row.total),
      formatExport: value => Money.centsToCoin(value)
    },
  ]), [])

  return (
    <Card>
      <CardHeader>
        <CardLabel icon='MoneyOff' iconColor={!darkModeStatus ? 'dark' : 'light'}>
          <CardTitle className='d-flex flex-row align-items-center'>
            <CardActions>
              OUTRAS DESPESAS
            </CardActions>
          </CardTitle>
        </CardLabel>
      </CardHeader>
      <CardBody className='table-responsive p-5'>
        <TableCustom
          rows={rows}
          columns={columns}
          fileName={'Outras-despesas'}
        />
      </CardBody>
    </Card>
  );
}

export default memo(OutrasDespesas);