import { memo } from 'react';
import Button from '../../../bootstrap/Button';
import BtnLoad from '../../BtnLoad';

function BtnExport({ onClick, loading }) {
	return (
		<div className='d-flex flex-grow-1 flex-row-reverse'>
			{loading ?
        <BtnLoad />
      :
        <Button
          type='button'
          color='primary'
          icon='Download'
          hoverShadow='sm'
          shadow='sm'
          size='sm'
          onClick={() => onClick(true)}>
          Exportar
        </Button>
      }
		</div>
	);
}

export default memo(BtnExport);
