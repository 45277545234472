import React, { memo, useMemo } from 'react';
import TableCustom from '../../../components/MyCustom/TableCustom';
import Card, { CardActions, CardBody, CardHeader, CardLabel, CardTitle } from '../../../components/bootstrap/Card';
import Money from '../../../helpers/utils/money/money';
import useDarkMode from '../../../hooks/useDarkMode';

const CustosOperacionais = ({ responsibles, technicals }) => {
  const { darkModeStatus } = useDarkMode();

  const columns1 = useMemo(() => ([
    {
      label: 'LIDER',
      field: 'name',
    },
    {
      label: 'QUANTIDADE DIÁRIA (10H)',
      field: 'qtdDiaria',
    },
    {
      label: 'VALOR DIÁRIA',
      field: 'vlDiaria',
      format: row => Money.centsToMaskMoney(row.vlDiaria),
    },
    {
      label: 'HORA ADICIONAL',
      field: 'qtdHora',
    },
    {
      label: 'VALOR HORA ADC.',
      field: 'vldHora',
      format: row => Money.centsToMaskMoney(row.vldHora),
    },
    {
      label: 'VALOR A PAGAR',
      field: 'total',
      format: row => Money.centsToMaskMoney(row.total),
    },
  ]), [])

  const columns2 = useMemo(() => ([
    {
      label: 'TECNICOS',
      field: 'name',
    },
    {
      label: 'QUANTIDADE DIÁRIA (10H)',
      field: 'qtdDiaria',
    },
    {
      label: 'VALOR DIÁRIA',
      field: 'vlDiaria',
      format: row => Money.centsToMaskMoney(row.vlDiaria),
     
    },
    {
      label: 'HORA ADICIONAL',
      field: 'qtdHora',
    },
    {
      label: 'VALOR HORA ADC.',
      field: 'vldHora',
      format: row => Money.centsToCoinCsv(row.vldHora),
     
    },
    {
      label: 'VALOR A PAGAR',
      field: 'total',
      format: row => Money.centsToCoinCsv(row.total),
     
    },
  ]), [])

  return (
    <Card>
      <CardHeader>
        <CardLabel icon='SupervisorAccount' iconColor={!darkModeStatus ? 'dark' : 'light'}>
          <CardTitle className='d-flex flex-row align-items-center'>
            <CardActions>
              CUSTOS OPERACIONAL
            </CardActions>
          </CardTitle>
        </CardLabel>
      </CardHeader>
      <CardBody className='table-responsive p-5'>
        <TableCustom             
          rows={responsibles}
          columns={columns1}
          fileName={'Custo-operacional-lider'}
        /> 
        <TableCustom
          className='mt-4'    
          rows={technicals}
          columns={columns2}
          fileName={'Custo-operacional-tecnico'}
        />
      </CardBody>
    </Card>
  );
}

export default memo(CustosOperacionais);
