import React, { memo, useCallback, useState } from 'react';
import useFilterData from '../../../hooks/useFilterData';
import useSortableData from '../../../hooks/useSortableData';
import { exportCSVFile } from '../../../utils/exportFile';
import Button from '../../bootstrap/Button';
import { CardFooter, CardFooterLeft, CardFooterRight } from '../../bootstrap/Card';
import FormGroup from '../../bootstrap/forms/FormGroup';
import Input from '../../bootstrap/forms/Input';
import Pagination, { PaginationItem } from '../../bootstrap/Pagination';
import Icon from '../../icon/Icon';

const TableCustomExternalPagination = ({ columns, rows, keyExtractor, noShowHeader, title, pageIndex, onNextPage, onPrevPage, limit }) => {
	const [search, setsearch] = useState('');
	const dataFilter = useFilterData(rows, search)
	const { items, requestSort, getClassNamesFor } = useSortableData(dataFilter);

	const onExport = useCallback(() => {
		const dataExport = dataFilter.map((row) => {
			const data = {};
			columns.forEach((c) => {
				if (c.noExport) return;
				const field = c.field;
				let cell = c.formatExport
					? c.formatExport(field === '{row}' ? row : row[field])
					: row[field];
				cell = cell === undefined ? ' ' : cell;
				data[c.label] = cell;
			});
			return data;
		});
		exportCSVFile(dataExport, 'dados')
	}, [columns, dataFilter])

	return (
		<>
			<div className='d-flex' style={{ marginBottom: 20 }} >
				<h4>{title}</h4>
				{!noShowHeader &&
					<div className='d-flex flex-grow-1 flex-row-reverse'>
						<Button
							type='button'
							className='text-dark py-0 ml-20'
							color='warning'
							icon="Download"
							// rounded={1}
							hoverShadow="sm"
							shadow="sm"
							size="sm"
							onClick={onExport}
						>
							Exportar
						</Button>
						<FormGroup id="name" label="" className='col-md-3'>
							<Input
								onChange={e => setsearch(e.target.value)}
								value={search}
								placeholder='Buscar'
							/>
						</FormGroup>
					</div>}
			</div>
			<table className='table table-modern'>
				<thead>
					<tr>
						{columns.map((column) => (
							<th key={'tab-head-' + column.label} onClick={() => requestSort(column.field)}>
								{column.label.toUpperCase()}
								<Icon
									size='sm'
									className={getClassNamesFor(column.field)}
									icon='FilterList'
								/>
							</th>
						))}
					</tr>
				</thead>
				<tbody>
					{items.map((item, index) => (
						<tr key={keyExtractor ? keyExtractor(item) : item.id}>
							{columns.map((c, index2) => (
								<td key={`tab-cell-${item.id}-${index}-${index2}`}>
									{c.format ? c.format(item) : item[c.field]}
								</td>
							))}
						</tr>
					))}
				</tbody>
			</table>

			<CardFooter>
				<CardFooterRight>
					<Pagination>
						<PaginationItem
							isPrev
							isDisabled={pageIndex === 1}
							onClick={onPrevPage}
						/>
						<PaginationItem isActive>{pageIndex}</PaginationItem>
						<PaginationItem
							isNext
							isDisabled={limit !== rows.length}
							onClick={onNextPage}
						/>
					</Pagination>
				</CardFooterRight>
			</CardFooter>
		</>
	);
};

export default memo(TableCustomExternalPagination);
