import {
	collection,
	CollectionReference,
	DocumentData,
	getDocs,
	getFirestore,
	onSnapshot,
	query,
	QueryConstraint,
	QueryDocumentSnapshot,
	Timestamp,
	Unsubscribe,
	where,
} from 'firebase/firestore';
import app from '../../config';
import Sales from '../../entities/eventData/sales.entity';

class SectorProductSoldEventData {
	protected readonly colRefStr: string;
	protected readonly colRef: CollectionReference<DocumentData>;

	constructor(estabId: string, eventId: string, sectorId: string) {
		this.colRefStr = `EstablishmentsData/${estabId}/DashboardView/${eventId}/Sectors/${sectorId}/ProductsSolds`;
		this.colRef = collection(getFirestore(app), this.colRefStr);
		console.log(estabId,eventId,sectorId);
	}

	async getByNfc(nfc: string): Promise<Sales[]> {
		const snapshot = await getDocs(query(this.colRef, where('idCard', '==', nfc)))
		return snapshot.docs.map(doc => ({
			id: doc.id,
			...doc.data(),
		})) as any
	}

	async getByNfcReversed(nfc: string): Promise<Sales[]> {
		const snapshot = await getDocs(query(this.colRef, where('idCard', '==', nfc), where('reversed', '==', true)))
		return snapshot.docs.map(doc => ({
			id: doc.id,
			...doc.data(),
		})) as any
	}

	on(listener: (data: Sales[]) => void, ...params: QueryConstraint[]): Unsubscribe {
		return onSnapshot(query(this.colRef, ...params), snapshot => {
			const datas: Sales[] = [];
			snapshot.forEach(doc => datas.push({ id: doc.id, ...doc.data() as any } as Sales))
			listener(datas)
		})
	}

	onDictionary(listener: (dictionary: any) => void, ...params: QueryConstraint[]): Unsubscribe {
		return onSnapshot(query(this.colRef, ...params), snapshot => {
			const dictionary: any = [];
			snapshot.forEach(doc => dictionary[doc.id] = doc.data())
			listener(dictionary)
		})
	}

	onPagination(listener: (data: Sales[], docs: QueryDocumentSnapshot<DocumentData>[]) => void, ...params: QueryConstraint[]): Unsubscribe {
		return onSnapshot(query(this.colRef, ...params), snapshot => {
			const datas: Sales[] = [];
			snapshot.forEach(doc => datas.push({ id: doc.id, ...doc.data() as any } as Sales))
			listener(datas, snapshot.docs)
		})
	}

	async getAll(): Promise<Sales[]> {
		const snapshot = await getDocs(this.colRef);
		return snapshot.docs.map((doc) => {
			const data = doc.data() as Sales; 
			return {
				id: doc.id,
				...data,
			};
		});
	}

	async getAllInterval(startDate: Date, endDate: Date): Promise<Sales[]> {
		const snapshot = await getDocs(query(this.colRef,
			where('created_at', '>=', Timestamp.fromDate(startDate)),
			where('created_at', '<=', Timestamp.fromDate(endDate))
		))
		return snapshot.docs.map(doc => ({
			id: doc.id,
			...doc.data(),
		})) as any
	}


}

export default SectorProductSoldEventData;
