export function validateStep1(values) {
  const errors = {};
  if (!values.cnpj) {
    errors.cnpj = 'Este campo é necessário.';
  } else if (values.cnpj.length < 18) {
    errors.cnpj = 'O CNPJ precisa ter 14 dígitos.';
  }

  if (!values.name) {
    errors.name = 'Este campo é necessário.';
  }

  if (!values.municipalRegistration) {
    errors.municipalRegistration = 'Este campo é necessário.';
  }

  if (!values.stateRegistration) {
    errors.stateRegistration = 'Este campo é necessário.';
  }

  if (!values.fantasyName) {
    errors.fantasyName = 'Este campo é necessário.';
  } else if (values.fantasyName.length < 2) {
    errors.fantasyName = 'O nome precisa ter 2 caracteres ou mais.';
  }

  if (!values.phone) {
    errors.phone = 'Este campo é necessário.';
  } else if (values.phone.length <= 13) {
    errors.phone = 'O telefone precisa ter 10 dígitos.';

  }

  if (!values.email) {
    errors.email = 'Este campo é necessário.';
  } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)) {
    errors.email = 'Email inválido.';
  }

  if (!values.latitude) {
    errors.latitude = 'Este campo é necessário.';
  }
  else if (Number(values.latitude) < -90 || Number(values.latitude) > 90) {
    errors.latitude = 'Por favor insira uma latitude válida. Deve estar entre -90 e 90.';
  }

  if (!values.longitude) {
    errors.longitude = 'Este campo é necessário.';
  }
  if (!values.pagseguroCode) {
    errors.pagseguroCode = 'Este campo é necessário.';
  }
  if (!values.stoneCode) {
    errors.stoneCode = 'Este campo é necessário.';
  }
  if (!values.idLoja) {
    errors.idLoja = 'Este campo é necessário.';
  }
  else if (Number(values.longitude) < -180 || Number(values.longitude) > 180) {
    errors.longitude = 'Por favor insira uma longitude válida. Deve estar entre -180 e 180.';
  }

  if (!values.active) {
    errors.active = 'Você precisa escolher o status do estabelecimento.';
  }

  if (!values.reason) {
    errors.reason = 'Este campo é necessário.';
  } else if (values.reason.length < 6) {
    errors.reason = `A razão social precisa ter 6 caracteres ou mais. Você tem ${values.reason.length} caracteres.`;
  }

  return errors;
}

export function validateStep2(values) {
  const errors = {};
  if (!values.zip_code) {
    errors.zip_code = 'Este campo é necessário.';
  } else if (values.zip_code.length < 9) {
    errors.zip_code = 'O CEP precisa ter 9 dígitos.';
  }

  if (!values.state) {
    errors.state = 'Este campo é necessário.';
  }
  if (!values.city) {
    errors.city = 'Este campo é necessário.';
  }
  if (!values.neighborhood) {
    errors.neighborhood = 'Este campo é necessário.';
  }
  if (!values.street) {
    errors.street = 'Este campo é necessário.';
  }
  if (!values.number) {
    errors.number = 'Este campo é necessário.';
  }

  return errors;
}

export function validateStep3(values) {

  const errors = {};
  if (!values.personal_cpf) {
    errors.personal_cpf = 'Este campo é necessário.';
  }
  // else if (values.cnpj.length < 14) {
  //   errors.personal_cpf = 'O CPF precisa ter 11 dígitos.';
  // }
  if (!values.personal_name) {
    errors.personal_name = 'Este campo é necessário.';
  }
  if (!values.personal_rg) {
    errors.personal_rg = 'Este campo é necessário.';
  }
  if (!values.personal_street) {
    errors.personal_street = 'Este campo é necessário.';
  }
  if (!values.personal_number) {
    errors.personal_number = 'Este campo é necessário.';
  }
  if (!values.personal_neighborhood) {
    errors.personal_neighborhood = 'Este campo é necessário.';
  }
  if (!values.personal_city) {
    errors.personal_city = 'Este campo é necessário.';
  }
  if (!values.personal_state) {
    errors.personal_state = 'Este campo é necessário.';
  }
  if (!values.personal_zip_code) {
    errors.personal_zip_code = 'Este campo é necessário.';
  }
}


export function validateStep4(values) {
  const errors = {};

  if (!values.bank) {
    errors.bank = 'Este campo é necessário.';
  }
  if (!values.bankCode) {
    errors.bankCode = 'Este campo é necessário.';
  }
  if (!values.agency) {
    errors.agency = 'Este campo é necessário.';
  }
  if (!values.account) {
    errors.account = 'Este campo é necessário.';
  }
  if (!values.pix) {
    errors.pix = 'Este campo é necessário.';
  }
  if (!values.witnesse1) {
    errors.witnesse1 = 'Este campo é necessário.';
  }
  if (!values.witnesse2) {
    errors.witnesse2 = 'Este campo é necessário.';
  }
  if (!values.witnesse1Email) {
    errors.witnesse1Email = 'Este campo é necessário.';
  }
  if (!values.witnesse2Email) {
    errors.witnesse2Email = 'Este campo é necessário.';
  }

  return errors;
}