import React, { useState, useContext, useEffect, useCallback } from 'react';
import { useFormik } from 'formik';
import Button from '../../bootstrap/Button';
import FormGroup from '../../bootstrap/forms/FormGroup';
import Input from '../../bootstrap/forms/Input';
import Checks, { ChecksGroup } from '../../bootstrap/forms/Checks';
import InputGroup, { InputGroupText } from '../../bootstrap/forms/InputGroup';
import Label from '../../bootstrap/forms/Label';
import Modal, { ModalBody, ModalHeader, ModalTitle } from '../../bootstrap/Modal';
import Spinner from '../../bootstrap/Spinner';
import showNotification from '../../extras/showNotification';
// Database
import SubCategoryDB from '../../../database/wrappers/subCategory';
import Avatar from '../../MyCustom/Avatar';
import ListProducts from '../../MyCustom/ListProducts';
import ProductDB from '../../../database/wrappers/product';
import { orderBy, where } from 'firebase/firestore';
import { DefaultContext } from '../../../contexts/default';
import PreAlert from '../../../helpers/utils/preAlert';
import BtnCancel from '../../MyCustom/BtnCancel';
import BtnLoad from '../../MyCustom/BtnLoad';
import BtnConfirm from '../../MyCustom/BtnConfirm';


const validate = (values) => {
  const errors = {};

  if (!values.name) {
    errors.name = 'Este campo é necessário';
  }

  if (!values.active) {
    errors.active = 'Você precisa escolher o status da subcategoria';
  }

  return errors;
}

const ModalRegisterSubcategories = ({ open, setIsOpen, setIsClose, subCategorySelected }) => {

  const { establishment, onShowAlert } = useContext(DefaultContext)

  const [isLoading, setIsLoading] = useState(false);
  const [products, setproducts] = useState([]);

  useEffect(() => {
    if(!open) return formik.resetForm();
    if (subCategorySelected) {
      const { name, active, products, image_url } = subCategorySelected;

      formik.setValues({
        name,
        active: active ? 'ativo' : 'inativo',
        products,
        image: null,
        image_url
      });
    }
  }, [subCategorySelected, open])

  useEffect(() => {
    if(establishment) {
      function sort(a, b) {
        if(a.name > b.name) return 1;
        if(a.name < b.name) return -1;
        return 0;
      }
      async function getProducts() {
        const products = await new ProductDB(establishment.id).getAll(where('active', '==', true));
        products.sort(sort);
        setproducts(products);
      }

      getProducts();
    }
  },[establishment])

  const formik = useFormik({
    initialValues: {
      name: '',
      active: '',
      products: [],
      image: null,
      image_url: null
    },
    validate,
    onSubmit: async values => {
      const { name, active, products, image } = values;

      const data = { 
        name: name, 
        active: active === 'ativo', 
        products,
        image,
      }

      const onSuccess = () => {
        onShowAlert(PreAlert.success('Registro cadastrado com sucesso'))
        setIsClose();
      }
      const onSuccessUpdate = () => {
        onShowAlert(PreAlert.success('Registro atualizado com sucesso'))
        setIsClose();
      }
      const onError = () => {
        onShowAlert(PreAlert.error('Falhou ao cadastrar o registro'))
      }

      setIsLoading(true);
      if (subCategorySelected) {
        new SubCategoryDB(establishment.id)
          .update(subCategorySelected.id, data)
          .then(onSuccessUpdate)
          .catch(onError)
          .finally(() => setIsLoading(false))
      } else {
        new SubCategoryDB(establishment.id)
          .create(data)
          .then(onSuccess)
          .catch(onError)
          .finally(() => setIsLoading(false))
      }
    },
  })

  const handleImage = useCallback((e) => {
    const [file] = Array.from(e.target.files)
    formik.setValues({
      ...formik.values,
      image: file,
      image_url: URL.createObjectURL(file)
    })
  }, [formik.values])

  return (
    <Modal
      id={'modal-register-sub-category'}
      titleId={'Cadastro de SubCategoria'}
      isOpen={open}
      setIsOpen={setIsOpen}
      isStaticBackdrop={true}
      isScrollable={false}
      isCentered={true}
      size="lg" // 'sm' || 'lg' || 'xl' 
      isAnimation={true}
      onSubmit={formik.handleSubmit}
    >
      <ModalHeader setIsOpen={setIsOpen}>
        <ModalTitle id="register-sub-category">{subCategorySelected ? 'Atualização de SubCategoria' : 'Cadastro de SubCategoria'}</ModalTitle>
      </ModalHeader>
      <ModalBody>
        <form noValidate onSubmit={formik.handleSubmit}>
          <div className='row align-items-center justify-content-center'>
            <FormGroup
              id='image_url'
            >
              <Avatar
                id='image_url'
                value={formik.values.image_url}
                onChange={handleImage} />
            </FormGroup>
          </div>
          {/* Inputs */}
          <div className="row g-4">
            {/* Nome */}
            <FormGroup id="name" label="Nome" className='col-md-8'>
              <Input
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.name}
                isValid={formik.isValid}
                isTouched={formik.touched.name}
                invalidFeedback={formik.errors.name}
                validFeedback='Assim está bom!'
                placeholder='Eletrodomésticos, lâmpadas, etc...'
              />
            </FormGroup>
            {/* Status */}
            <FormGroup className='col-md-4'>
              <Label>Status</Label>
              <ChecksGroup
                isValid={formik.isValid}
                isTouched={formik.touched.active}
                invalidFeedback={formik.errors.active}
              >
                <Checks
                  type="radio"
                  id="active"
                  label="Ativo"
                  name="active"
                  value="ativo"
                  onChange={formik.handleChange}
                  checked={formik.values.active}
                  isInline
                />
                <Checks
                  type="radio"
                  id="active2"
                  label="Inativo"
                  name="active"
                  value="inativo"
                  onChange={formik.handleChange}
                  checked={formik.values.active}
                  isInline
                />
              </ChecksGroup>
            </FormGroup>
          </div>
          {/* List */}
          <ListProducts
            products={products}
            productsSelected={formik.values.products}
            onChange={formik.handleChange}
          />
          {/* Buttons */}
          <div className="row pt-4">
            <div className="col-md-4 offset-md-8">
              <div className='d-flex justify-content-evenly'>
                <BtnCancel setIsClose={setIsClose} />
                {isLoading ? (
                  <BtnLoad />
                ) : (
                  <BtnConfirm isDisable={!formik.isValid} />
                )}
              </div>
            </div>
          </div>
        </form>
      </ModalBody>
    </Modal>
  )
}

export default ModalRegisterSubcategories;