import React, { memo } from 'react';
import Money from '../../../../helpers/utils/money/money';
import Checks, { ChecksGroup } from '../../../bootstrap/forms/Checks';
import FormGroup from '../../../bootstrap/forms/FormGroup';
import Input from '../../../bootstrap/forms/Input';

function ComponentItem({complement, onChange, checked}) {
  return(
    <div className='row g-4 py-2'>
      <div className='col-md-1'>
        <div className='h-100 d-flex align-items-center justify-content-center'>
          <ChecksGroup>
            <Checks
              type='switch'
              id='complements'
              name='complements'
              value={complement.id}
              onChange={onChange}
              checked={checked}
              isInline
            />
          </ChecksGroup>
        </div>
      </div>
      <div className='col-md-1'>
        <img
          src={complement.image_url}
          alt='Imagem'
          width={40}
          height={40}
        />
      </div>
      <div className='col-md-7'>
        <FormGroup>
          <Input value={complement.name} disabled readOnly />
        </FormGroup>
      </div>
      <div className='col-md-2'>
        <FormGroup>
          <Input
            value={Money.centsToMaskMoney(complement.price)}
            disabled
            readOnly
          />
        </FormGroup>
      </div>
    </div>
  );
}

const ComponentElement = memo(ComponentItem);

function ListComponents({ complements, complementsSelected, onChange }) {
  if(!complementsSelected) return null;
	return (
		<div style={{ maxHeight: 300 }} className='overflow-y'>
			{complements.map((complement, index) => {
				return (
					<ComponentElement
            key={'ch_comp'+index}
            complement={complement}
            onChange={onChange}
            checked={complementsSelected.includes(complement.id)}
          />
				);
			})}
		</div>
	);
}

export default memo(ListComponents);
