import React, { memo, useMemo } from 'react'
import Card, { CardHeader, CardLabel, CardTitle, CardBody, CardActions } from '../../../components/bootstrap/Card';
import Chart from '../../../components/extras/Chart';
import useDarkMode from '../../../hooks/useDarkMode';
import Popovers from '../../../components/bootstrap/Popovers';
import Icon from '../../../components/icon/Icon';

const RankingBilheteria = ({ entrances, entranceDicionary }) => {

  const { darkModeStatus } = useDarkMode();

  const entrancesRender = useMemo(() => {
    return entrances.map(p => ({
      id: p.id,
      name: p.entranceId === null ? 'LANÇAMENTO MANUAL' : entranceDicionary[p.entranceId]?.name || '',
      qtd: p.qtd
    }))
  }, [entrances, entranceDicionary])

  return (
    <Card stretch>
      <CardHeader>
        <CardLabel icon='EmojiEvents' iconColor={!darkModeStatus ? 'dark' : 'light'}>
          <CardTitle className='d-flex flex-row align-items-center'>
            <CardActions>
              RANKING DE BILHETERIA
            </CardActions>
            <Popovers trigger={'hover'} desc={<b>Ranking de ingressos vendidos na bilheteria.</b>}><Icon className='mx-3' size='2x' icon='Info' /></Popovers>
          </CardTitle>
        </CardLabel>
      </CardHeader>
      <CardBody>
        <Chart
          height={350}
          options={{
            colors: ['#F21F00'],
            chart: {
              height: 350,
              type: 'bar',
              toolbar: {
                export: {
                  csv: {
                    filename: "Ranking-bilheteria",
                    headerCategory: 'BILHETERIA',
                  },
                  svg: {
                    filename: "Ranking-bilheteria",
                  },
                  png: {
                    filename: "Ranking-bilheteria",
                  }
                }
              },
            },
            dataLabels: {
              enabled: true
            },
            plotOptions: {
              bar: {
                horizontal: false,
              }
            },
            tooltip: {
              theme: 'dark',
              fixed: {
                enabled: false,
              },
            },
            xaxis: {
              categories: entrancesRender.map(item => item.name),
              convertedCatToNumeric: false,
            },
            x: {
              show: true,
            },
            y: {
              title: {
                // eslint-disable-next-line no-unused-vars
                formatter(seriesName) {
                  return '';
                },
              },
            },
          }}
          series={[
            {
              name: 'VENDIDO',
              data: entrancesRender.map(item => item.qtd),
            },
          ]}
          type="bar"
        />
      </CardBody>
    </Card>
  )
}

export default memo(RankingBilheteria);



