import React, { useState, useEffect, useContext, useCallback, useMemo } from 'react';
import PageWrapper from '../../../../layout/PageWrapper/PageWrapper';
import SubHeader, { SubHeaderLeft, SubHeaderRight } from '../../../../layout/SubHeader/SubHeader';
import Page from '../../../../layout/Page/Page';
import Breadcrumb from '../../../../components/bootstrap/Breadcrumb';
import { demoPages } from '../../../../menu';
import Card, { CardBody, CardHeader, CardLabel, CardTitle } from '../../../../components/bootstrap/Card';
import Button from '../../../../components/bootstrap/Button';
import ModalRegisterPrinters from '../../../../components/modals/Printers/ModalRegister';
import { DefaultContext } from '../../../../contexts/default';
import PrinterDB from '../../../../database/wrappers/printer';
import SelectEstablishment from '../../../../components/MyCustom/SelectEstablishment';
import TableCustom from '../../../../components/MyCustom/TableCustom';
import showNotification from '../../../../components/extras/showNotification';
import notificationBody from '../../../../helpers/utils/functions/notificationBody';
import { where } from 'firebase/firestore';
import SelectSector from '../../../../components/MyCustom/SelectSector';
import useDarkMode from '../../../../hooks/useDarkMode';
import PrinterKeysDB from '../../../../database/wrappers/printerKeys';
import convertArrayToObject from '../../../../utils/convertArrayToObject';
import ModalTestPrinters from '../../../../components/modals/Printers/ModalTestPrinter';
import ModalShowQrcodePrinter from '../../../../components/modals/Printers/ModalShowQrcode';

const Printers = () => {
    const { darkModeStatus } = useDarkMode();

    const { estabSelected, sectorSelected, sectors, onShowAlert, onShowQuestion } = useContext(DefaultContext);

    const [openModalRegister, setOpenModalRegister] = useState(false);
    const [openModalTest, setOpenModalTest] = useState(false);
    const [openModalQrcode, setOpenModalQrcode] = useState(false);
    const [printers, setPrinters] = useState([]);
    const [printersKeyDicionary, setPrintersKeyDicionary] = useState({});
    const [PrintersSelected, setPrintersSelected] = useState(null);

    useEffect(() => {
        if (!estabSelected) return;
        const onSubscribe1 = new PrinterDB(estabSelected).on(setPrinters);
        const onSubscribe2 = new PrinterKeysDB(estabSelected).on(datas =>
            setPrintersKeyDicionary(convertArrayToObject(datas, 'printerId'))
        , where('estabId', '==', estabSelected));
        return () => {
            onSubscribe1();
            onSubscribe2();
        };
    }, [estabSelected])

    const showQrcode = useCallback((row) => {
        setPrintersSelected(row)
        setOpenModalQrcode(true)
    }, [])

    const handleOpenModalRegister = useCallback(() => {
        setPrintersSelected(null)
        setOpenModalRegister(true);
    }, [])

    const handleCloseModalRegister = useCallback(() => {
        setOpenModalRegister(false);
    }, [])

    const handleOpenModalEdit = useCallback((item) => {
        setPrintersSelected(item);
        setOpenModalRegister(true)
    }, [])

    const handleOpenModalTest = useCallback((item) => {
        setPrintersSelected(item);
        setOpenModalTest(true)
    }, [])

    const handleOpenModalDelete = useCallback((id) => {
        onShowQuestion({
            title: 'Exclusão de Impressora',
            message: 'Você está excluindo uma impressora. Está ação é irreversível. Deseja continuar?',
            onConfirm: () => {
                new PrinterDB(estabSelected, sectorSelected)
                    .delete(id)
                    .then(res => showNotification('', notificationBody('Registro deletado com sucesso'), 'success'))
                    .catch(err => showNotification('', notificationBody('Falhou ao deletar o registro'), 'danger'))
            }
        })
    }, [estabSelected, sectorSelected])

    const handleChangeStatus = useCallback((id, active) => {
        new PrinterDB(estabSelected, sectorSelected)
            .update(id, {
                active: !active
            })
            .catch(error => onShowAlert({
                title: 'ERRO!',
                msm: 'Não foi possível atualizar o status do produto',
                icon: 'Error',
                color: 'danger'
            }))
    }, [estabSelected, sectorSelected])

    const sectorsDicionary = useMemo(() => 
        convertArrayToObject(sectors, 'id')
    ,[sectors])

    const rowsRender = useMemo(() => 
        printers.map(row => ({
            ...row,
            key: printersKeyDicionary[row.id]?.id,
            qtdProducts: row.products.length,
            sectorsNames: row.sectors?.map(sectorId => sectorsDicionary[sectorId]?.name).sort().join('; ')
        }))
    ,[printers, printersKeyDicionary, sectorsDicionary])

    const columns = useMemo(() => ([
        {
          label: 'QRcode',
          field: 'id',
          format: row => (
            <div>
              <Button
                className='btn-tablecustom-row'
                color={darkModeStatus ? 'light' : 'dark'}
                icon="QrCodeScanner"
                shadow="sm"
                hoverShadow="sm"
                size="sm"
                onClick={() => showQrcode(row)}
              />
            </div>
          ),
          noExport: true
        },
        {
            label: 'Nome',
            field: 'name',
           
        },
        {
            label: 'Setores',
            field: 'sectorsNames',
        },
        {
          label: 'Chave',
          field: 'key',
        },
        {
            label: 'QTD Produtos',
            field: 'qtdProducts',
        },
        {
            label: 'Status',
            field: 'active',
            format: row => (
                <Button
                    isLink
                    color={row.active ? 'success' : 'danger'}
                    icon='Circle'
                    // onClick={() => handleChangeStatus(row.id, row.active)}
                    className='text-nowrap'>
                    {/* {row.active ? 'Ativo' : 'Inativo'} */}
                </Button>
            )
        },
          
        {
            label: 'Ações',
            field: 'actions',
            format: row => (
                <div className='row'>
                    <Button
                        color={darkModeStatus ? 'light' : 'dark'}
                        icon="edit"
                        shadow="sm"
                        hoverShadow="sm"
                        size="sm"
                        onClick={() => handleOpenModalEdit(row)}
                    />

                    <Button
                        className='ms-4'
                        color={darkModeStatus ? 'light' : 'dark'}
                        icon="Print"
                        shadow="sm"
                        hoverShadow="sm"
                        size="sm"
                        onClick={() => handleOpenModalTest(row)}
                    />

                    <Button
                        className='ms-4'
                        color="danger"
                        icon="Trash"
                        shadow="sm"
                        hoverShadow="sm"
                        size="sm"
                        onClick={() => handleOpenModalDelete(row.id)}
                        
                    />
                    
                </div>
                
            ),
            noExport: true
        },
    ]), [darkModeStatus, handleChangeStatus, handleOpenModalEdit, handleOpenModalDelete])

    return (
        <>
            <PageWrapper title={demoPages.sector.subMenu.printers.text}>
                <SubHeader>
                    <SubHeaderLeft>
                        <Breadcrumb
                            list={[
                                { title: 'Setor', to: '/sector/printers' },
                                {
                                    title: 'Impressoras',
                                    to: '/sector/printers',
                                },
                            ]}
                        />
                    </SubHeaderLeft>
                    <SubHeaderRight>
                        <Button
                            color='primary'
                            icon='plus'
                            shadow="sm"
                            hoverShadow="sm"
                            onClick={handleOpenModalRegister}
                        >
                            Cadastrar
                        </Button>
                    </SubHeaderRight>
                </SubHeader>
                <Page container="flui">
                    <Card>
                        <CardHeader>
                            <CardLabel icon='Printer' iconColor={darkModeStatus ? 'light' : 'dark'}>
                                <CardTitle>Impressoras</CardTitle>
                            </CardLabel>
                        </CardHeader>
                        <CardBody className='table-responsive'>
                            <div className='row d-flex align-items-center mt-4 mx-1' >
                                <SelectEstablishment />
                                {/* <SelectSector /> */}
                            </div>

                            <TableCustom 
                                columns={columns} 
                                rows={rowsRender} 
                                 fileName="Impressoras"
                            />
                        </CardBody>
                    </Card>
                </Page>
            </PageWrapper>

            <ModalRegisterPrinters
                open={openModalRegister}
                setIsOpen={setOpenModalRegister}
                setIsClose={handleCloseModalRegister}
                printersSelected={PrintersSelected}
            />

            <ModalTestPrinters
                open={openModalTest}
                setIsOpen={setOpenModalTest}
                printersSelected={PrintersSelected}
            />

            <ModalShowQrcodePrinter
                open={openModalQrcode}
                setIsOpen={setOpenModalQrcode}
                printersSelected={PrintersSelected}
            />
        </>
    );
};

export default Printers;
