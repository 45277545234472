import { orderBy } from 'firebase/firestore';
import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import SelectEstablishment from '../../../../components/MyCustom/SelectEstablishment';
import SelectEvent from '../../../../components/MyCustom/SelectEvent';
import TableCustom from '../../../../components/MyCustom/TableCustom';
import Breadcrumb from '../../../../components/bootstrap/Breadcrumb';
import Button from '../../../../components/bootstrap/Button';
import Card, { CardBody, CardHeader, CardLabel, CardTitle } from '../../../../components/bootstrap/Card';
import Input from '../../../../components/bootstrap/forms/Input';
import showNotification from '../../../../components/extras/showNotification';
import { DefaultContext } from '../../../../contexts/default';
import PromotersLinkDB from '../../../../database/wrappers/promotersLink';
import notificationBody from '../../../../helpers/utils/functions/notificationBody';
import Money from '../../../../helpers/utils/money/money';
import useDarkMode from '../../../../hooks/useDarkMode';
import Page from '../../../../layout/Page/Page';
import PageWrapper from '../../../../layout/PageWrapper/PageWrapper';
import SubHeader, { SubHeaderLeft, SubHeaderRight } from '../../../../layout/SubHeader/SubHeader';
import { demoPages } from '../../../../menu';
import ModalRegisterPromoterLink from '../../../../components/modals/PromotersLink/ModalRegister'

const PromotersLink = () => {
  const { darkModeStatus } = useDarkMode();

  const { estabSelected, eventSelected, onShowQuestion } = useContext(DefaultContext)

  const [openModalRegister, setOpenModalRegister] = useState(false);
  const [promoters, setpromoters] = useState([])
  const [promoterSelected, setpromoterSelected] = useState(null)

  useEffect(() => {
    if (!estabSelected || !eventSelected) return;
    const onSubscriber = new PromotersLinkDB(estabSelected, eventSelected).on(setpromoters, orderBy('name', 'desc'))
    return onSubscriber;
  }, [estabSelected, eventSelected])

  const handleCloseModal = useCallback(() => {
    setOpenModalRegister(false)
  }, [])

  const handleRegister = useCallback(() => {
    setpromoterSelected(null);
    setOpenModalRegister(true)
  }, [])

  const handleEntranceEdit = useCallback((item) => {
    setpromoterSelected(item);
    setOpenModalRegister(true)
  }, [])

  const handleChangeItem = useCallback((id, key, value) => {
    new PromotersLinkDB(estabSelected, eventSelected)
      .update(id, {
        [key]: value
      })
      .then(() => showNotification('', notificationBody('Registro alterado com sucesso'), 'success'))
      .catch(() => showNotification('', notificationBody('Falhou ao atualizar o registro'), 'danger'))
  }, [estabSelected, eventSelected])

  const handleOpenModalDelete = useCallback((id) => {
    onShowQuestion({
      title: 'Exclusão de Entrada de Bilheteria',
      message: 'Você está excluindo uma entrada de Bilheteria. Está ação é irreversível. Deseja continuar?',
      onConfirm: () => {
        new PromotersLinkDB(estabSelected, eventSelected)
          .delete(id)
          .then(res => showNotification('', notificationBody('Registro deletado com sucesso'), 'success'))
          .catch(err => showNotification('', notificationBody('Falhou ao deletar o registro'), 'danger'))
      }
    })
  }, [estabSelected, eventSelected])


  const createCustomLink = (promoter) => {
    if (estabSelected && eventSelected) {
      const url = `${window.location.host}/preRegister/?estab=${estabSelected}&promo=${promoter.id}&event=${eventSelected}`;


      navigator.clipboard.writeText(url)
        .then(() => {
          showNotification(`LINK COPIADO PARA O PROMOTER ${promoter.name}`, null, 'default', {
            container: 'bottom-center',
          })
        })
        .catch(err => {
          console.error('Erro ao copiar a URL para a área de transferência:', err);
        });
    }
  };


  const columns = useMemo(() => ([
    {
      label: 'Nome ',
      field: 'name',
    },
    {
      label: 'Cpf',
      field: 'cpf'
    },
    {
      label: 'Link',
      field: 'link',
      format: row => (
        <div className='w-75 d-flex justify-content-between'>
          <Button
            color={darkModeStatus ? 'light' : 'dark'}
            icon="InsertLink"
            shadow="sm"
            hoverShadow="sm"
            size="sm"
            onClick={() => createCustomLink(row)}
          />

        </div>
      ),
      noExport: true
    },

    {
      label: 'Ações',
      field: 'actions',
      format: row => (
        <div className='w-75 d-flex justify-content-between'>
          <Button
            color={darkModeStatus ? 'light' : 'dark'}
            icon="edit"
            shadow="sm"
            hoverShadow="sm"
            size="sm"
            onClick={() => handleEntranceEdit(row)}
          />
        </div>
      ),
      noExport: true
    },
  ]), [darkModeStatus, handleOpenModalDelete, handleChangeItem, createCustomLink])

  return (
    <>
      <PageWrapper title={demoPages.event.subMenu.promoterLink.text}>
        <SubHeader>
          <SubHeaderLeft>
            <Breadcrumb
              list={[
                { title: 'Evento', to: demoPages.event.subMenu.promoterLink.path },
                { title: 'promoterLink', to: demoPages.event.subMenu.promoterLink.path },
              ]}
            />
          </SubHeaderLeft>
          <SubHeaderRight>
            <Button
              color='primary'
              icon='plus'
              shadow="sm"
              hoverShadow="sm"
              onClick={handleRegister}
            >
              Cadastrar
            </Button>
          </SubHeaderRight>
        </SubHeader>

        <Page container="fluid">
          <Card>
            <CardHeader>
              <CardLabel icon='Share' iconColor={darkModeStatus ? 'light' : 'dark'}>
                <CardTitle>Link Promoters</CardTitle>
              </CardLabel>
            </CardHeader>
            <CardBody className='table-responsive'>
              <div className='row'>
                <SelectEstablishment />
                <SelectEvent />
              </div>

              <TableCustom
                rows={promoters}
                columns={columns}
                keyExtractor={row => row.id}
                fileName='Link-promotores'
              />
            </CardBody>
          </Card>
        </Page>

        <ModalRegisterPromoterLink
          open={openModalRegister}
          setIsOpen={setOpenModalRegister}
          setIsClose={handleCloseModal}
          promoterSelected={promoterSelected}
        />
      </PageWrapper>
    </>
  );
};

export default PromotersLink;
