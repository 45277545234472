import React, { memo, useMemo } from 'react';
import Card, { CardActions, CardBody, CardHeader, CardLabel, CardTitle } from '../../../components/bootstrap/Card';
import Money from '../../../helpers/utils/money/money';
import useDarkMode from '../../../hooks/useDarkMode';
import TableCustom from '../../../components/MyCustom/TableCustom';

const Resumo = ({ resume }) => {
  const { darkModeStatus } = useDarkMode();

  const rows = [
    {
      name: 'TAXA uGet ;-)',
      tax:resume?.tax_uget  || 0
    },
    {
      name: 'OUTRAS RECEITAS',
      tax:resume?.other_recipes  || 0
    },
    {
      name: 'CUSTO OPERACIONAL',
      tax:resume?.operational_cost  || 0
    },
    {
      name: 'OUTRAS DESPESAS',
      tax:resume?.other_expenses || 0
    },
    {
      name: 'PERDAS E DANOS',
      tax:resume?.other_recipes || 0
    },
    {
      name: 'COMISSÃO DO VENDEDOR',
      tax:resume?.sellers_commission || 0
    },
    {
      name: 'TOTAL uGet (NOTA FISCAL)',
      tax:resume?.total_uget_gross || 0
    },
    {
      name: 'TOTAL INTERMEDIADO (Crédito, Debito e Pix)',
      tax:resume?.total_intermediated  || 0
    },
    {
      name: 'SERVIÇOS PRESTADOS PELA PLATAFORMA UGET PDX',
      tax:resume?.services_provided || 0
    },
    {
      name: 'RETENÇÃO DE IRRF (1,5%)',
      tax:resume?.tax_irrf || 0
    },
    {
      name: 'TOTAL DO REPASSE',
      tax:resume?.full_transfer || 0
    },
    {
      name: 'TOTAL DE REPASSE + RETENÇÃO DE IRRF',
      tax:resume?.full_transfer || 0
    },
   
    {
      name: 'TOTAL LIQUIDO (referente a taxa uGet)',
      tax:resume?.total_liquid  || 0
    },
    {
      name: <span className='table-active fw-bold' >SALDO FINAL uGet</span>,
      tax:resume?.final_balance_uget || 0
    },
       
  ]

  const columns = useMemo(() => ([
    {
      label: '',
      field: 'name',
    },
    {
      label: '',
      field: 'tax',
      format: row => Money.centsToMaskMoney(row.tax)
    },
   
  ]))


  return (
    <Card>
      <CardHeader>
        <CardLabel icon='Payments' iconColor={!darkModeStatus ? 'dark' : 'light'}>
          <CardTitle className='d-flex flex-row align-items-center'>
            <CardActions>
              RESUMO (SEM SOMA SO VISUALIZAÇÃO)
            </CardActions>
          </CardTitle>
        </CardLabel>
      </CardHeader>
      <CardBody className='table-responsive p-5'>
      <TableCustom  rows={rows} columns={columns} noShowPagination fileName={'Resumo-sem-soma-so-visualizacao'} />
        {/* <table className='table table-borderless table-hover'>
          <tbody>
            <tr>
              <td>TAXA uGet ;-)</td>
              <td>{Money.centsToMaskMoney(resume?.tax_uget || 0)}</td>
            </tr>
            <tr>
              <td>OUTRAS RECEITAS</td>
              <td>{Money.centsToMaskMoney(resume?.other_recipes || 0)}</td>
            </tr>
            <tr>
              <td>CUSTO OPERACIONAL</td>
              <td>{Money.centsToMaskMoney(resume?.operational_cost || 0)}</td>
            </tr>
            <tr>
              <td>OUTRAS DESPESAS</td>
              <td>{Money.centsToMaskMoney(resume?.other_expenses || 0)}</td>
            </tr>
            <tr>
              <td>PERDAS E DANOS</td>
              <td>{Money.centsToMaskMoney(resume?.losses_and_damages || 0)}</td>
            </tr>
            <tr>
              <td>COMISSÃO DO VENDEDOR</td>
              <td>{Money.centsToMaskMoney(resume?.sellers_commission || 0)}</td>
            </tr>
            <tr >
              <td>TOTAL uGet (NOTA FISCAL)</td>
              <td>{Money.centsToMaskMoney(resume?.total_uget_gross || 0)}</td>
            </tr>
            <tr >
              <td>TOTAL INTERMEDIADO (Crédito, Debito e Pix)</td>
              <td>{Money.centsToMaskMoney(resume?.total_intermediated || 0)}</td>
            </tr>
            <tr >
              <td>SERVIÇOS PRESTADOS PELA PLATAFORMA UGET PDX</td>
              <td>{Money.centsToMaskMoney(resume?.services_provided || 0)}</td>
            </tr>
            <tr >
              <td>RETENÇÃO DE IRRF (1,5%)</td>
              <td>{Money.centsToMaskMoney(resume?.tax_irrf || 0)}</td>
            </tr>
            <tr >
              <td>TOTAL DO REPASSE</td>
              <td>{Money.centsToMaskMoney(resume?.full_transfer || 0)}</td>
            </tr>
            <tr >
              <td>TOTAL DE REPASSE + RETENÇÃO DE IRRF</td>
              <td>{Money.centsToMaskMoney(resume?.full_transfer || 0 + resume?.tax_irrf || 0)}</td>
            </tr>
            <tr >
              <td>TOTAL LIQUIDO (referente a taxa uGet)</td>
              <td>{Money.centsToMaskMoney(resume?.total_liquid || 0)}</td>
            </tr>
            <tr className='table-active fw-bold'>
              <td>SALDO FINAL uGet</td>
              <td>{Money.centsToMaskMoney(resume?.final_balance_uget || 0)}</td>
            </tr>
          </tbody>
        </table> */}
      </CardBody>
    </Card>
  );
}

export default memo(Resumo);