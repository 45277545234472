import axios from 'axios';
import ContractDB from '../database/wrappers/contract';
import EstablishmentContract from '../components/modals/EstablishmentContract/contractEstablishment';
import EventContract from '../components/modals/EventContract/contractEvent';
import { renderToStaticMarkup } from 'react-dom/server';
import html2pdf from 'html2pdf.js';


export default async function d4signOpen(estabelecimento, evento, osData) {

  console.log('estabelecimento: ', estabelecimento);
  console.log('evento: ', evento);

  if (estabelecimento, evento) {
    const folderOptions = {
      method: 'POST',
      url: `/api/v1/folders/${process.env.REACT_APP_SAFE_EVENTS}/create`,
      params: {
        tokenAPI: process.env.REACT_APP_TOKEN_API,
        cryptKey: process.env.REACT_APP_CRYPT_KEY
      },
      headers: { accept: 'application/json', 'content-type': 'application/json' },
      data: { folder_name: evento.name + ' - ' + evento.crypt_key }
    };

    const folderResponse = await axios.request(folderOptions)
      .then(async function (responseFolder) {
        console.log('folderResponse.data: ', responseFolder.data);
        try {
          const contractComponent = <EventContract estabelecimento={estabelecimento} evento={evento} osData={osData} />;
          const contractHTML = renderToStaticMarkup(contractComponent);

          // converte o HTML em PDF com margem em cada lado
          const pdfContent = await html2pdf()
            .from(contractHTML)
            .set({
              margin: [5, 10, 10, 10],
            })
            .outputPdf('blob');

          // converte o conteúdo PDF em base64
          const base64Content = await new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsDataURL(pdfContent);
            reader.onloadend = () => {
              const base64Content = reader.result.split(',')[1];
              console.log('base64Content: ', base64Content);
              resolve(base64Content);
            };
            reader.onerror = reject;
          });

          const uploadOptions = {
            method: 'POST',
            url: `/api/v1/documents/${process.env.REACT_APP_SAFE_EVENTS}/uploadbinary`,
            params: {
              tokenAPI: process.env.REACT_APP_TOKEN_API,
              cryptKey: process.env.REACT_APP_CRYPT_KEY
            },
            headers: { accept: 'application/json', 'content-type': 'application/json' },
            data: {
              mime_type: 'application/pdf',
              name: 'contrato ' + evento.name,
              uuid_folder: responseFolder.data.uuid,
              base64_binary_file: base64Content
            }
          };
          try {
            const uploadResponse = await axios.request(uploadOptions)
              .then(function (responseUpload) {
                console.log('responseUpload.data: ', responseUpload.data);
                const signatoriesOptions = {
                  method: 'POST',
                  url: `/api/v1/documents/${responseUpload.data.uuid}/createlist`,
                  params: {
                    tokenAPI: process.env.REACT_APP_TOKEN_API,
                    cryptKey: process.env.REACT_APP_CRYPT_KEY
                  },
                  headers: { accept: 'application/json', 'content-type': 'application/json' },
                  data: {
                    signers: [
                      {
                        //contrato uget
                        email: 'aluanbh@gmail.com',
                        act: '1',
                        foreign: '0',
                        certificadoicpbr: '0',
                        assinatura_presencial: '0'
                      },
                      {
                        email: estabelecimento.email,
                        act: '1',
                        foreign: '0',
                        assinatura_presencial: '0',
                        certificadoicpbr: '0'
                      },
                      {
                        email: evento.witnesses.ugetWitnessEmail,
                        act: '5',
                        foreign: '0',
                        certificadoicpbr: '0',
                        assinatura_presencial: '0'
                      },
                      {
                        email: evento.witnesses.eventWitnessEmail,
                        act: '5',
                        foreign: '0',
                        certificadoicpbr: '0',
                        assinatura_presencial: '0'
                      }
                    ]
                  }
                };
                try {
                  const signatoriesResponse = axios.request(signatoriesOptions)
                    .then(function (responseSignatories) {
                      console.log('responseSignatories.data: ', responseSignatories.data);
                      const Sendoptions = {
                        method: 'POST',
                        url: `/api/v1/documents/${responseUpload.data.uuid}/sendtosigner`,
                        params: {
                          tokenAPI: process.env.REACT_APP_TOKEN_API,
                          cryptKey: process.env.REACT_APP_CRYPT_KEY
                        },
                        headers: { accept: 'application/json', 'content-type': 'application/json' },
                        data: { skip_email: '0', workflow: '0' }
                      };
                      try {
                        const SendResponse = axios.request(Sendoptions)
                          .then(async function (responseSend) {
                            console.log('responseSend.data: ', responseSend.data);
                            const data = {
                              folder: responseFolder.data.uuid,
                              contract_ref: responseUpload.data.uuid,
                              signatories: responseSignatories.data,
                              signed: false,
                              type: 'OpenOS',
                              event: evento.id,
                              eventName: evento.name,
                              contractNumber: evento.codeNumber,
                              date: new Date(),
                            };
                            // console.log('data: ', data);

                            try {
                              const contracts = await new ContractDB(estabelecimento.id)
                                .getAll()
                                .catch((error) => {
                                  console.log('Erro ao buscar contratos: ', error);
                                  throw error;
                                });

                              const contractToUpdate = contracts[0];
                              contractToUpdate.eventos = contractToUpdate.eventos || {};
                              contractToUpdate.eventos[evento.id] = data;

                              const updatedContract = await new ContractDB(estabelecimento.id)
                                .update(contractToUpdate.id, contractToUpdate)
                                .catch((error) => {
                                  console.log('Erro ao atualizar contrato: ', error);
                                  throw error;
                                });

                              return updatedContract;
                            } catch (error) {
                              console.log('Erro ao criar instância de ContractDB: ', error);
                              throw error;
                            }
                            return responseSend.data;
                          }).catch(function (error) {
                            console.error('NÃO ENVIOU', error);
                            return false;
                          });
                      }
                      catch (error) {
                        console.log('não enviou os emails: ', error);
                      }
                    }).catch(function (error) {
                      console.error('NÃO CADASTROU SIGNATÁRIOS', error);
                    });
                }
                catch (error) {
                  console.log('não cadastrou signatários: ', error);
                }
                return responseUpload.data;
              }).catch(function (error) {
                console.error('NÃO FEZ UPLOAD', error);
                return false;
              })
          }
          catch (error) {
            console.error('NÃO FEZ UPLOAD', error);
          }
        } catch (error) {
          console.log('error: ', error);
          return false;
        }
      }).catch(function (error) {
        console.error('NÃO CRIOU A PASTA', error);
      });
  }


}


