import React, { memo, useCallback, useContext, useEffect, useMemo, useState } from "react";
import Card from "../../../../../components/bootstrap/Card";
import Money from "../../../../../helpers/utils/money/money";
import dateFormat from "dateformat";
import { ResultsContext } from "../../../../../contexts/results";
import { DefaultContext } from "../../../../../contexts/default";
import TableCustomExternalPagination from "../../../../../components/MyCustom/TableCustomExternalPagination";
import EntrancePayEventData from "../../../../../database/wrappers/eventData/entrancePay";
import { limit, orderBy, startAfter } from "firebase/firestore";
import BtnExport from "../../../../../components/MyCustom/Dashboard/BtnExport";
import { exportEntrancesPay } from "../../../../../utils/export";
import PreAlert from "../../../../../helpers/utils/preAlert";
import { cpfFormatMask } from "../../../../../utils/format/cpfFormat";
import useDarkMode from "../../../../../hooks/useDarkMode";
import ExportSelectType from "../../../../../components/MyCustom/ExportSelectType";
import { PAYMENT_TYPE_PTBR } from "../../../../../types/payment";
import Totais from "./totais";
import Icon from "../../../../../components/icon/Icon";
import { CardHeader, CardLabel, CardTitle, CardBody } from "../../../../../components/bootstrap/Card";
import TableCustom from "../../../../../components/MyCustom/TableCustom";
import useTotalEntrance from "../../../../../hooks/TotaisReports/useTotalEntrance";
import useDicionary from "../../../../../hooks/TotaisReports/useDicionarys.js";
import SelectSector from "../../../../../components/MyCustom/SelectSector";


const ReportsBySector = ({ hidden }) => {
    const { EntrancesSector } = useTotalEntrance();
    const { cashierDicionary, entrancesDicionary, sectorsDicionary } = useDicionary();
    const { darkModeStatus } = useDarkMode();
    const rowsRender = useMemo(() =>
        EntrancesSector.entrancesSector.map(row => ({
            ...row,
            operator: cashierDicionary[row.cashierId]?.operator || '',
            operatorCpf: cpfFormatMask(cashierDicionary[row.cashierId]?.cpf || 0),
            terminal: cashierDicionary[row.cashierId]?.terminal || '',
            sector: sectorsDicionary[row.sectorId]?.name || '',
            entrada: row.entranceId ? entrancesDicionary[row.entranceId]?.name || ''
                : row.entrances?.length === 0 ? 'Entrada Avulsa'
                    : row.entrances?.map(en => `${en.qtd}x ${entrancesDicionary[en.id]?.name || ''}`).join(', ') || '',
            pagamento: PAYMENT_TYPE_PTBR[row.payment_type],
        }))
        , [EntrancesSector.entrancesSector, entrancesDicionary, cashierDicionary, sectorsDicionary])


    const columns = useMemo(() => ([
        {
            label: 'Data / Hora',
            field: 'created_at',
            format: row => dateFormat(row.created_at.toDate(), 'dd/mm/yyyy HH:MM:ss'),
            formatExport: value => dateFormat(value.toDate(), 'dd/mm/yyyy HH:MM'),
        },
        {
            label: 'Estorno',
            field: 'reversed',
            format: row => row.reversed ? <Icon size="2x" icon='Circle' color='danger' /> : <Icon size="2x" icon='Circle' color='success' />,
            formatExport: value => value ? 'SIM' : 'NÃO',
        },
        {
            label: 'Operador',
            field: 'operator',
        },
        {
            label: 'CPF',
            field: 'operatorCpf',
        },
        {
            label: 'Terminal',
            field: 'terminal',
        },
        {
            label: 'Entrada',
            field: 'entrada',
        },
        {
            label: 'Setor',
            field: 'sector',
        },
        {
            label: 'Pagamento',
            field: 'pagamento',
        },
        {
            label: 'Bandeira',
            field: 'cardBrand',
        },
        {
            label: 'VL Unitário',
            field: 'value',
            format: row => Money.centsToMaskMoney(row.value),
            formatExport: value => Money.centsToCoin(value),
        },
        {
            label: 'VL Ingresso',
            field: '{row}',
            format: row => Money.centsToMaskMoney(row.value - row.consummation),
            formatExport: row => Money.centsToCoin(row.value - row.consummation),
        },
        {
            label: 'Consumação',
            field: 'consummation',
            format: row => Money.centsToMaskMoney(row.consummation),
            formatExport: value => Money.centsToCoin(value),
        },
        {
            label: 'Total',
            field: 'value',
            format: row => Money.centsToMaskMoney(row.value),
            formatExport: value => Money.centsToCoin(value),
        },
    ]), [])

    return (
        <div hidden={hidden}>
            <Card stretch>
                <CardHeader>
                    <CardLabel icon='BackupTable' iconColor={!darkModeStatus ? 'dark' : 'light'}>
                        <CardTitle>MOVIMENTAÇÃO POR SETOR</CardTitle>
                    </CardLabel>
                </CardHeader>
                <CardBody className='table-responsive'>
                    <SelectSector />
                    <TableCustom
                        rows={rowsRender}
                        columns={columns}
                        keyExtractor={(row) => row.id}
                    />
                </CardBody>
            </Card>

        </div>
    );
}

export default memo(ReportsBySector);