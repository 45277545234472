import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react'
import Modal, { ModalBody, ModalHeader, ModalTitle } from '../../bootstrap/Modal';
import { DefaultContext } from '../../../contexts/default';
import { Timestamp } from 'firebase/firestore'
import FormGroup from '../../bootstrap/forms/FormGroup';
import Input from '../../bootstrap/forms/Input';
import { useFormik } from 'formik';
import PreAlert from '../../../helpers/utils/preAlert';
import SelectEvent from '../../MyCustom/SelectEvent';
import EventDB from '../../../database/wrappers/event';
import randomString from '../../../utils/random/randomString';
import Checks from '../../bootstrap/forms/Checks';
import Wizard, { WizardItem } from '../../Wizard';
import UserAdd from '../../MyCustom/Events/UserAdd';
import Select from '../../bootstrap/forms/Select';

const ModalCloneEvent = ({ open, setIsOpen, setIsClose, usersCoor, usersSeller }) => {

  const { estabSelected, eventSelected, event, onShowAlert } = useContext(DefaultContext)

  const [isLoading, setIsLoading] = useState(false)

  useEffect(() => {
    if (!open) return formik.resetForm();
  },[open])

  const validate = useCallback((values) => {
    const errors = {};

    if (!values.name) {
      errors.name = 'Informe o nome do evento'
    }
    if (!values.date) {
      errors.date = 'Informe a data'
    }
    if (!values.endDate) {
      errors.endDate = 'Informe a data'
    }

    return errors;
  }, [])

  const formik = useFormik({
    initialValues: {
      name: '',
      isConfirmed: false,
      date: '',
      endDate: '',
      responsibles: [],
      technical: [],
      sellerId: '',
    },
    validate,
    onSubmit: values => {
      const {
        name,
        date,
        endDate,
        isConfirmed,

        responsibles,
        technical,
        sellerId,
      } = values;

      const data = {
        closed: false,
        name,
        date: Timestamp.fromDate(new Date(date)),
        endDate: Timestamp.fromDate(new Date(endDate)),
        isConfirmed,
        responsibles,
        technical,
        sellerId,
        crypt_key: randomString(10),
      }

      if(!eventSelected)
        return onShowAlert(PreAlert.error('Selecione o evento a ser clonado'))

      const onSuccess = () => {
        onShowAlert(PreAlert.success('Evento clonado com sucesso'))
        setIsClose();
      }
      const onError = (error) => {
        console.error(error);
        onShowAlert(PreAlert.error('Falhou ao cadastrar o registro'))
      }

      setIsLoading(true);
      new EventDB(estabSelected)
        .clone(eventSelected, data)
        .then(onSuccess)
        .catch(onError)
        .finally(() => setIsLoading(false))
    }
  });

  const checkStepIsValid = (step) => {
    if (step === 'step1') {
      if (formik.values.name !== '' && formik.values.date !== '' && formik.values.endDate !== '') {
        return false;
      } else {
        return true;
      }
    }
    if (step === 'step2') {
      return formik.values.responsibles.length === 0
    }
    if (step === 'step3') {
      return formik.values.technical.length === 0
    }
  }

  const [usersResponsiblesInit, usersTechnicalInit] = useMemo(() => 
    [
      event?.responsibles || [],
      event?.technical || [],
    ]
  ,[event])

  useEffect(() => {
    formik.setFieldValue('responsibles', usersResponsiblesInit)
    formik.setFieldValue('technical', usersTechnicalInit)
  }, [usersResponsiblesInit, usersTechnicalInit])

  const onChangeUsersCoor = useCallback((newUser, lastUser, index) => {
    formik.values.responsibles[index] = newUser
    formik.setFieldValue('responsibles', formik.values.responsibles.slice())
  },[formik.values.responsibles])

  const onRemoveUsersCoor = useCallback((index) => {
    formik.values.responsibles.splice(index, 1);
    formik.setFieldValue('responsibles', formik.values.responsibles.slice())
  },[formik.values.responsibles])

  const onChangeUsersTechnical = useCallback((newUser, lastUser, index) => {
    formik.values.technical[index] = newUser
    formik.setFieldValue('technical', formik.values.technical.slice())
  },[formik.values.technical])

  const onRemoveUsersTechnical = useCallback((index) => {
    formik.values.technical.splice(index, 1);
    formik.setFieldValue('technical', formik.values.technical.slice())
  },[formik.values.technical])

  const optionsUsersCoor = useMemo(() =>
    usersCoor.map(u => ({ value: u.id, label: u.name }))
  ,[usersCoor])

  const optionsUsersSeller = useMemo(() =>
    usersSeller.map(u => ({ value: u.id, label: u.name }))
  ,[usersSeller])

  return (
    <Modal
      id={'modal-clone-events'}
      titleId={'Fechamento de Evento'}
      isOpen={open}
      setIsOpen={setIsOpen}
      isStaticBackdrop
      isScrollable
      isCentered
      isAnimation
      size="lg"
    >
      <ModalHeader setIsOpen={setIsOpen}>
        <ModalTitle id="clone-events">Clonar Evento</ModalTitle>
      </ModalHeader>
      <ModalBody>
        <Wizard
          isHeader
          color='info'
          isLoading={isLoading}
          onSubmit={formik.handleSubmit}
          className='row'>
          <WizardItem id='step1' title='Rótulo do Evento' required={checkStepIsValid('step1')} >
            <div className='row p-2'>
              <FormGroup className='col-md-6'>
                <SelectEvent class='col-md-12' />
              </FormGroup>

              <FormGroup id="isConfirmed" label="Confirmação" className='col-md-3'>
                <Checks
                  type='switch'
                  label={formik.values.isConfirmed ? 'Confirmado' : 'Não Confirmado'}
                  name='isConfirmed'
                  onChange={formik.handleChange}
                  checked={formik.values.isConfirmed}
                />
              </FormGroup>
            </div>
            <div className='row p-2'>
              <FormGroup id="name" label="Nome do Evento" className='col-md-6'>
                <Input
                  ariaDescribedby='inputGroupPrepend'
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.name}
                  isValid={formik.isValid}
                  isTouched={formik.touched.name}
                  invalidFeedback={formik.errors.name}
                  validFeedback='Assim está bom!'
                  placeholder="Ex: Show do Pink Floyd"
                />
              </FormGroup>
              <FormGroup id="sellerId" label="Vendedor" className='col-md-6'>
                <Select
                  value={formik.values.sellerId}
                  list={optionsUsersSeller}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  invalidFeedback={formik.errors.sellerId}
                  isValid={formik.isValid}
                  isTouched={formik.touched.sellerId}
                  placeholder="Selecione o vendedor"
                />
              </FormGroup>
            </div>
            <div className='row p-2'>
              <FormGroup id="date" label="Data Inicial" className='col-md-6'>
                <Input
                  ariaDescribedby='inputGroupPrepend'
                  type='datetime-local'
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.date}
                  isValid={formik.isValid}
                  isTouched={formik.touched.date}
                  invalidFeedback={formik.errors.date}
                  validFeedback='Assim está bom!'
                  placeholder="Ex: 22/05/2023"
                />
              </FormGroup>
              <FormGroup id="endDate" label="Data de Término" className='col-md-6'>
                <Input
                  ariaDescribedby='inputGroupPrepend'
                  type='datetime-local'
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.endDate}
                  isValid={formik.isValid}
                  isTouched={formik.touched.endDate}
                  invalidFeedback={formik.errors.endDate}
                  validFeedback='Assim está bom!'
                  placeholder="Ex: 22/05/2023"
                />
              </FormGroup>
            </div>
          </WizardItem>

          <WizardItem id='step2' title='Lider(s) Responsável(s)' required={checkStepIsValid('step2')} >
            <div className='col-md-12'>
              <UserAdd
                usersInit={usersResponsiblesInit}
                usersOptions={optionsUsersCoor}
                usersSelected={formik.values.responsibles}
                onChange={onChangeUsersCoor}
                onRemove={onRemoveUsersCoor}
              />
            </div>
          </WizardItem>

          <WizardItem id='step3' title='Técnicos' required={checkStepIsValid('step3')} >
            <div className='col-md-12'>
              <UserAdd
                usersInit={usersTechnicalInit}
                usersOptions={optionsUsersCoor}
                usersSelected={formik.values.technical}
                onChange={onChangeUsersTechnical}
                onRemove={onRemoveUsersTechnical}
              />
            </div>
          </WizardItem>
        </Wizard>
      </ModalBody>
      {/* <ModalFooter>
        <BtnCancel setIsClose={setIsClose} />
        {isLoading ?
          <BtnLoad /> 
          :
          <BtnConfirm />
        }
      </ModalFooter> */}
    </Modal>
  )
}

export default ModalCloneEvent
