import React, { useState, useContext, useEffect } from 'react';
import { useFormik } from 'formik';
import FormGroup from '../../bootstrap/forms/FormGroup';
import Input from '../../bootstrap/forms/Input';
import Checks from '../../bootstrap/forms/Checks';
import Modal, { ModalBody, ModalHeader, ModalTitle } from '../../bootstrap/Modal';
// Database
import PrinterDB from '../../../database/wrappers/printer';
import ListProducts from '../../MyCustom/ListProducts';
import ProductDB from '../../../database/wrappers/product';
import { where } from 'firebase/firestore';
import { DefaultContext } from '../../../contexts/default';
import PreAlert from '../../../helpers/utils/preAlert';
import BtnCancel from '../../MyCustom/BtnCancel';
import BtnLoad from '../../MyCustom/BtnLoad';
import BtnConfirm from '../../MyCustom/BtnConfirm';
import Nav, { NavItem } from '../../bootstrap/Nav';
import ListSectors from '../Coordinators/ListSectors';


const validate = (values) => {
  const errors = {};

  if (!values.name) {
    errors.name = 'Este campo é necessário';
  }

  return errors;
}

const ModalRegisterPrinters = ({ open, setIsOpen, setIsClose, printersSelected }) => {

  const { establishment, sectors, onShowAlert } = useContext(DefaultContext)

  const [isLoading, setIsLoading] = useState(false);
  const [abaSelected, setabaSelected] = useState(0);
  const [products, setproducts] = useState([]);

  useEffect(() => {
    if (!open) return formik.resetForm();
    if (printersSelected) {
      const { name, active, products, sectors=[] } = printersSelected;

      formik.setValues({
        name,
        active,
        products,
        sectors
      });
    }
  }, [printersSelected, open])

  useEffect(() => {
    if (establishment) {
      function sort(a, b) {
        if (a.name > b.name) return 1;
        if (a.name < b.name) return -1;
        return 0;
      }
      async function getProducts() {
        const products = await new ProductDB(establishment.id).getAll(where('active', '==', true));
        products.sort(sort)
        setproducts(products);
      }

      getProducts();
    }
  }, [establishment])

  const formik = useFormik({
    initialValues: {
      name: '',
      active: '',
      products: [],
      sectors: [],
    },
    validate,
    onSubmit: values => {
      const { name, active, products, sectors } = values;

      if(products.length === 0)
        return onShowAlert(PreAlert.success('Selecione ao menos um produto'))
      if(sectors.length === 0)
        return onShowAlert(PreAlert.success('Selecione ao menos um setor'))

      const data = {
        name,
        active,
        products,
        sectors
      }

      const onSuccess = () => {
        onShowAlert(PreAlert.success('Registro cadastrado com sucesso'))
        setIsClose();
      }
      const onSuccessUpdate = () => {
        onShowAlert(PreAlert.success('Registro atualizado com sucesso'))
        setIsClose();
      }
      const onError = (error) => {
        console.error(error);
        onShowAlert(PreAlert.error('Falhou ao cadastrar o registro'))
      }

      setIsLoading(true);
      if (printersSelected) {
        new PrinterDB(establishment.id)
          .update(printersSelected.id, data)
          .then(onSuccessUpdate)
          .catch(onError)
          .finally(() => setIsLoading(false))
      } else {
        new PrinterDB(establishment.id)
          .create(data)
          .then(onSuccess)
          .catch(onError)
          .finally(() => setIsLoading(false))
      }
    }
  })

  return (
    <Modal
      id={'modal-register-printers'}
      titleId={'Cadastro de Impressoras'}
      isOpen={open}
      setIsOpen={setIsOpen}
      isStaticBackdrop={true}
      isScrollable={false}
      isCentered={true}
      size="lg" // 'sm' || 'lg' || 'xl' 
      isAnimation={true}
      onSubmit={formik.handleSubmit}
    >
      <ModalHeader setIsOpen={setIsOpen}>
        <ModalTitle id="register-printer">{printersSelected ? 'Atualização de Impressoras' : 'Cadastro de Impressoras'}</ModalTitle>
      </ModalHeader>
      <ModalBody>
        <form noValidate onSubmit={formik.handleSubmit}>
          {/* Inputs */}
          <div className="row g-4">
            <div className="col-md-12">
              {/* Nome */}
              <FormGroup id="name" label="Nome" className='col-md-6'>
                <Input
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.name}
                  isValid={formik.isValid}
                  isTouched={formik.touched.name}
                  invalidFeedback={formik.errors.name}
                  validFeedback='Assim está bom!'
                  placeholder='Nome da impressora'
                />
              </FormGroup>
            </div>
            {/* Status */}
            <FormGroup
              id='active'
              label='Status'
              className='col-md-2'
            >
              <Checks
                type="switch"
                id="active"
                label={formik.values.active ? 'Ativo' : 'Inativo'}
                name="active"
                onChange={formik.handleChange}
                checked={formik.values.active}
              />
            </FormGroup>
          </div>

          {/* Abas */}
          <Nav
            style={{ marginTop: 20 }}
            tag='ul' // 'ul' || 'nav'
            design='tabs' // 'tabs' || 'pills'
            isFill
          >
            <NavItem 
              isActive={abaSelected === 0}
              onClick={() => setabaSelected(0)}>
              <a>
                Setores
              </a>
            </NavItem>
            
            <NavItem 
              isActive={abaSelected === 1}
              onClick={() => setabaSelected(1)}>
              <a>
                Produtos
              </a>
            </NavItem>
          </Nav>

          <ListSectors
            hidden={abaSelected !== 0}
            sectors={sectors}
            sectorSelected={formik.values.sectors}
            onChange={formik.handleChange}
          />

          <ListProducts
            hidden={abaSelected !== 1}
            products={products}
            productsSelected={formik.values.products}
            onChange={formik.handleChange}
          />

          {/* Buttons */}
          <div className="row pt-4">
            <div className="col-md-4 offset-md-8">
              <div className='d-flex justify-content-evenly'>
                <BtnCancel setIsClose={setIsClose} />
                {isLoading ? (
                  <BtnLoad />
                ) : (
                  <BtnConfirm isDisable={!formik.isValid} />
                )}
              </div>
            </div>
          </div>
        </form>
      </ModalBody>
    </Modal>
  )
}

export default ModalRegisterPrinters;