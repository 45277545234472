import React, { useCallback, useContext, useEffect, useState, useMemo } from 'react';
import PageWrapper from '../../../../layout/PageWrapper/PageWrapper';
import SubHeader, { SubHeaderLeft, SubHeaderRight } from '../../../../layout/SubHeader/SubHeader';
import Page from '../../../../layout/Page/Page';
import Breadcrumb from '../../../../components/bootstrap/Breadcrumb';
import { demoPages } from '../../../../menu';
import Card, { CardBody, CardHeader, CardLabel, CardTitle } from '../../../../components/bootstrap/Card';
import ModalRegisterUsers from '../../../../components/modals/Users/ModalRegister';
import Button from '../../../../components/bootstrap/Button';
import { DefaultContext } from '../../../../contexts/default';
import SelectEstablishment from '../../../../components/MyCustom/SelectEstablishment';
import TableCustom from '../../../../components/MyCustom/TableCustom';
import api from '../../../../services/api';
import { ACCESS_LEVEL, BASIC_ROLE, ROLE, ROLE_PTBR } from '../../../../types/roles';
import useDarkMode from '../../../../hooks/useDarkMode';
import UserDB from '../../../../database/wrappers/user';
import convertArrayToObject from '../../../../utils/convertArrayToObject';
import Spinner from '../../../../components/bootstrap/Spinner';
import showNotification from '../../../../components/extras/showNotification';
import notificationBody from '../../../../helpers/utils/functions/notificationBody';
import { where } from 'firebase/firestore';

const Users = () => {
  const { darkModeStatus } = useDarkMode();

  const { accessLevel, establishment, onShowAlert, onShowQuestion } = useContext(DefaultContext);

  const [openModalRegister, setOpenModalRegister] = useState(false);
  const [usersInEstab, setUsersInEstab] = useState([])
  const [users, setUsers] = useState([])
  const [usersCoor, setUsersCoor] = useState([])
  const [usersUget, setUsersUget] = useState([])
  const [usersDataDicionary, setusersDataDicionary] = useState([])
  const [userSelected, setUserSelected] = useState(null)
  const [loading, setloading] = useState(false)

  useEffect(() => {
    
    getUsers();
    return new UserDB().on(datas =>
      setusersDataDicionary(convertArrayToObject(datas, 'id')),
      where('role', 'in', [BASIC_ROLE.SUPER_ADMIN, BASIC_ROLE.ADMIN, BASIC_ROLE.COORDINATOR, BASIC_ROLE.MANAGER])
    )
  }, [])

  useEffect(() => {
    if (establishment) {
      const usersFilter = users.filter(user => user.customClaims?.estabs.includes(establishment.id));
      setUsersInEstab(usersFilter)
    }
    
  }, [establishment, users])

  useEffect(()=> {
    console.log('usersInEstab', usersInEstab)
  } ,[usersInEstab])

  const getUsers = useCallback(() => {
    setloading(true)
    api.get('routes/users')
      .then(res => {
        const users = [];
        const usersUget = [];
        const usersCoor = [];
        res.data.forEach(user => {
          if (user.customClaims.role === BASIC_ROLE.ADMIN || user.customClaims.role === BASIC_ROLE.SUPER_ADMIN)
            usersUget.push(user)
          else if (user.customClaims.role === BASIC_ROLE.COORDINATOR)
            usersCoor.push(user)
          else if (user.customClaims.role === BASIC_ROLE.MANAGER)
            users.push(user)
        });
        setUsers(users)
        setUsersUget(usersUget)
        setUsersCoor(usersCoor)
      })
      .catch(error => onShowAlert({
        title: 'ERRO!',
        msm: 'Não foi possível obter os dados dos usuários',
        icon: 'Error',
        color: 'danger'
      }))
      .finally(() => setloading(false))
  }, [])

  const handleOpenModalRegister = useCallback(() => {
    setUserSelected(null)
    setOpenModalRegister(true);
  }, [])

  const handleCloseModalRegister = useCallback(() => {
    setOpenModalRegister(false);
  }, [])

  const handleOpenModalEdit = useCallback((item) => {
    setUserSelected({
      ...item,
      ...usersDataDicionary[item.uid]
    });
    setOpenModalRegister(true)
  }, [usersDataDicionary])

  const handleOpenModalDelete = useCallback((row) => {
    onShowQuestion({
      title: 'Deletar usuário',
      message: 'Você está excluindo um usuário. Está ação é irreversível. Deseja continuar?',
      onConfirm: () => {
        api.delete('routes/users/' + row.uid)
          .then(res => {
            getUsers();
            showNotification('', notificationBody('Registro deletado com sucesso'), 'success')
          })
          .catch(err => showNotification('', notificationBody('Falhou ao deletar o registro'), 'danger'))
      }
    })
  }, [])

  const columns = useMemo(() => ([
    {
      label: 'Nome',
      field: 'displayName',
    },
    {
      label: 'Email',
      field: 'email',
    },
    {
      label: 'Função',
      field: '{row}',
      format: row => ROLE_PTBR[row.customClaims?.role],
      formatExport: row => ROLE_PTBR[row.customClaims?.role],
    },
    {
      label: 'Status',
      field: 'disabled',
      format: row => (
        <Button
          isLink
          color={row.disabled ? 'danger' : 'success'}
          icon='Circle'
          className='text-nowrap'>
          {row.disabled ? 'Inativo' : 'Ativo'}
        </Button>
      ),
      formatExport: value => value ? 'Inativo' : 'Ativo' 
    },
    {
      label: 'Ações',
      field: 'actions',
      format: row => (
        <div className='w-50 d-flex justify-content-between'>
          <Button
            color={darkModeStatus ? 'light' : 'dark'}
            icon="edit"
            shadow="sm"
            hoverShadow="sm"
            size="sm"
            onClick={() => handleOpenModalEdit(row)}
          />
          <Button
            color="danger"
            icon="Trash"
            shadow="sm"
            hoverShadow="sm"
            size="sm"
            onClick={() => handleOpenModalDelete(row)}
          />
        </div>
      ),
      noExport: true
    },
  ]), [darkModeStatus, handleOpenModalEdit, handleOpenModalDelete])

  return (
    <>
      <PageWrapper title={demoPages.pageLayout.subMenu.users.text}>
        <SubHeader>
          <SubHeaderLeft>
            <Breadcrumb
              list={[
                { title: 'Cadastros', to: '/registers/users' },
                {
                  title: 'Usuários',
                  to: '/registers/users',
                },
              ]}
            />
          </SubHeaderLeft>
          <SubHeaderRight>
            <Button
              color='primary'
              icon='plus'
              shadow="sm"
              hoverShadow="sm"
              onClick={handleOpenModalRegister}
            >
              Cadastrar
            </Button>
          </SubHeaderRight>
        </SubHeader>
        <Page container='fluid'>
          <SelectEstablishment />

          {loading ?
            <div className='d-flex flex-grow-1 align-items-center justify-content-center'>
              <Spinner
                size={80}
                color={darkModeStatus ? 'light' : 'dark'}
              />
            </div>
            :
            <>
              <Card>
                <CardHeader>
                  <CardLabel icon='AccountBox' iconColor={darkModeStatus ? 'light' : 'dark'}>
                    <CardTitle>Usuários no Estabelecimento</CardTitle>
                  </CardLabel>
                </CardHeader>
                <CardBody className='table-responsive'>
                  <TableCustom
                    titlePdf='Usuários no Estabelecimento'
                    icon="AccountBox"
                    rows={usersInEstab}
                    columns={columns}
                    keyExtractor={item => item.uid}
                    fileName="Usuarios-estabelecimento"
                  />
                </CardBody>
              </Card>

              <Card>
                <CardHeader>
                  <CardLabel icon='AccountBox' iconColor={darkModeStatus ? 'light' : 'dark'}>
                    <CardTitle>Usuários Coordenadores</CardTitle>
                  </CardLabel>
                </CardHeader>
                <CardBody className='table-responsive'>
                  <TableCustom
                    titlePdf='Usuários Coordenadores'
                    icon="AccountBox"
                    rows={usersCoor}
                    columns={columns}
                    keyExtractor={item => item.uid}
                    fileName="Usuarios-cordenadores"
                  />
                </CardBody>
              </Card>

              {accessLevel >= ACCESS_LEVEL.ADMIN &&
                <Card>
                  <CardHeader>
                    <CardLabel icon='AccountBox' iconColor={darkModeStatus ? 'light' : 'dark'}>
                      <CardTitle>Usuários uGet</CardTitle>
                    </CardLabel>
                  </CardHeader>
                  <CardBody className='table-responsive'>
                    <TableCustom
                      titlePdf='Usuários uGet'
                      icon="AccountBox"
                      rows={usersUget}
                      columns={columns}
                      keyExtractor={item => item.uid}
                      fileName="Usuarios-uget"
                    />
                  </CardBody>
                </Card>}
            </>}
        </Page>
      </PageWrapper>

      <ModalRegisterUsers
        open={openModalRegister}
        setIsOpen={setOpenModalRegister}
        setIsClose={handleCloseModalRegister}
        editData={userSelected}
        loadUsers={getUsers}
      />
    </>
  );
};

export default Users;
