import React, { useMemo } from 'react'
import Card, { CardHeader, CardLabel, CardTitle, CardBody } from '../../../../../components/bootstrap/Card';
import CardValue from '../../../../../components/MyCustom/Dashboard/CardValue';
import useDarkMode from '../../../../../hooks/useDarkMode';
import Money from '../../../../../helpers/utils/money/money';
import SelectSector from '../../../../../components/MyCustom/SelectSector';

const TotaisSector = ({ data }) => {
  const { darkModeStatus } = useDarkMode()
  const { credit, debit, pix, courtesy, cash, total } = useMemo(() => {
    let credit = 0;
    let debit = 0;
    let pix = 0;
    let courtesy = 0;
    let cash = 0;
    let total = 0;

    data.forEach(item => {
      credit += item.credit
      debit += item.debit
      pix += item.pix
      courtesy += item.courtesy
      cash += item.cash
      total += item.credit + item.debit + item.pix + item.courtesy + item.cash
    })

    return { credit, debit, pix, courtesy, cash, total }
  }, [data])



  return (
    <Card stretch>
      <CardHeader>
        <CardLabel icon='PointOfSale' iconColor={!darkModeStatus ? 'dark' : 'light'}>
          <CardTitle>TOTAIS POR SETOR</CardTitle>
        </CardLabel>
      </CardHeader>
      <CardBody>

        <div className='row align-items-center' >
          <div className='col-md'>
            <CardValue
              color='primary'
              label='CRÉDITO'
              value={Money.centsToMaskMoney(credit)}
            />
          </div>
          <div className='col-md'>
            <CardValue
              color='secondary'
              label='DÉBITO'
              value={Money.centsToMaskMoney(debit)}


            />
          </div>
          <div className='col-md' >
            <CardValue
              color='success'
              label='PIX'
              value={Money.centsToMaskMoney(pix)}


            />
          </div>
          <div className='col-md'>
            <CardValue
              color='danger'
              label='CORTESIA'
              value={Money.centsToMaskMoney(courtesy)}

            />
          </div>
          <div className='col-md'>
            <CardValue
              color='dark'
              label='DINHEIRO'
              value={Money.centsToMaskMoney(cash)}

            />
          </div>

          <div className='col-md'>
            <CardValue
              color='info'
              label='TOTAL'
              value={Money.centsToMaskMoney(total)}

            />
          </div>
        </div>
      </CardBody>
    </Card>
  )
}

export default TotaisSector