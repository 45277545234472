import React, { memo, useMemo } from 'react';
import Card, { CardActions, CardBody, CardHeader, CardLabel, CardTitle } from '../../../components/bootstrap/Card';
import TableSimples from '../../../components/MyCustom/TableSimples';
import Money from '../../../helpers/utils/money/money';
import useDarkMode from '../../../hooks/useDarkMode';
import TableCustom from '../../../components/MyCustom/TableCustom';

const OutrasReceitas = ({ equipaments }) => {
  const { darkModeStatus } = useDarkMode();

  const columns = useMemo(() => ([
    {
      label: 'Nome',
      field: 'equipment',
    },
    {
      label: 'Qtd Env',
      field: 'qtdSent',
    },
    {
      label: 'Qtd Rec',
      field: 'qtdReceived',
    },
    {
      label: 'Qtd Perdido',
      field: 'qtd_loss',
    },
    {
      label: 'Valor',
      field: 'value',
      format: row => Money.centsToMaskMoney(row.value),
      formatExport: value => Money.centsToCoin(value),
    },
    {
      label: 'Total Alug',
      field: 'total_rent',
      format: row => Money.centsToMaskMoney(row.total_rent),
      formatExport: value => Money.centsToCoin(value),
    },
  ]), [])

  const equipamentsRender = useMemo(() => {
    return equipaments?.filter(e => 
      e.qtdSent > 0 ||
      e.qtdReceived > 0 ||
      e.qtd_loss > 0
    ) || [];
  },[equipaments])

  return (
    <Card>
      <CardHeader>
        <CardLabel icon='AttachMoney' iconColor={!darkModeStatus ? 'dark' : 'light'}>
          <CardTitle className='d-flex flex-row align-items-center'>
            <CardActions>
              OUTRAS RECEITAS
            </CardActions>
          </CardTitle>
        </CardLabel>
      </CardHeader>
      <CardBody className='table-responsive p-5'>
        <TableCustom
          rows={equipamentsRender}
          columns={columns}
          fileName={'Outras-receitas'}
        />
      </CardBody>
    </Card>
  );
}

export default memo(OutrasReceitas);