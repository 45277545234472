export const PAYMENT_TYPE = {
  CARD_DEBIT: 0,
  CARD_CREDIT: 1,
  CASH: 2,
  CASHLESS: 3,
  COURTESY: 4,
  DIVIDED: 5,
  PIX: 6,
  MEAL_TICKET: 7,
}

export const PAYMENT_TYPE_PTBR = {
  [PAYMENT_TYPE.CARD_DEBIT]: 'Débito',
  [PAYMENT_TYPE.CARD_CREDIT]: 'Crédito',
  [PAYMENT_TYPE.CASH]: 'Dinheiro',
  [PAYMENT_TYPE.CASHLESS]: 'Consumo',
  [PAYMENT_TYPE.COURTESY]: 'Cortesia',
  [PAYMENT_TYPE.DIVIDED]: 'Dividido',
  [PAYMENT_TYPE.PIX]: 'Pix',
  [PAYMENT_TYPE.MEAL_TICKET]: 'Vale refeição',
}

export const ACTIVATION_TYPE = {
  COMMON: 0,
  INGRESSE: 1,
  ENTRANCE_PAY: 2,
  LINKED_NEW_CARD: 3,
  EARLY_ENTRY: 4,
} as const


export const EAT_OR_TAKE = {
  UNINFORMED: 0,
  EAT_HERE: 1,
  TO_TAKE: 2
} as const