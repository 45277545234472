import EntrancePay from "../../database/entities/eventData/entrancesPay.entity";
import { exportCSVFile, exportJSONFile } from "../exportFile";
import dateFormat from "dateformat";
import Money from "../../helpers/utils/money/money";
import { PAYMENT_TYPE_PTBR } from "../../types/payment";
import { cpfFormatMask } from "../format/cpfFormat";
import Recharge from "../../database/entities/eventData/recharge.entity";
import Sales from "../../database/entities/eventData/sales.entity";

export function exportEntrancesPay(entrances: EntrancePay[], cashierDicionary: any, entranceDicionary: any, typeFile: 'csv' | 'json') {
  if (typeFile === 'csv') {
    const datas = entrances.map(row => ({
      DATA: dateFormat(row.created_at.toDate(), 'dd/mm/yyyy HH:MM:ss'),
      OPERADOR: cashierDicionary[row.cashierId]?.operator || '',
      CPF: cpfFormatMask(cashierDicionary[row.cashierId]?.cpf || 0),
      TERMINAL: cashierDicionary[row.cashierId]?.terminal || '',
      ENTRADA: row.entranceId ? entranceDicionary[row.entranceId]?.name || ''
        : row.entrances?.length === 0 ? 'Entrada Avulsa'
          : row.entrances?.map(en => `${en.qtd}x ${entranceDicionary[en.id]?.name || ''}`).join(', ') || '',
      PAGAMENTO: PAYMENT_TYPE_PTBR[row.payment_type],
      BANDEIRA: row.cardBrand || '',
      CV: row.codVenda || '',
      'ID TRANS': row.transaction_code || '',
      ESTORNO: row.reversed ? 'SIM' : 'NÃO',  
      'VALOR UNIT': Money.centsToCoinCsv(row.value),
      'VALOR INGRESSO': Money.centsToCoinCsv(row.value - row.consummation),
      CONSUMACAO: Money.centsToCoinCsv(row.consummation),
      TOTAL: Money.centsToCoinCsv(row.value),
    }))
    exportCSVFile(datas, 'Bilheteria-movimentacao')
  } else
    exportJSONFile(entrances, 'Bilheteria-movimentacao')
}

export function exportRecharges(recharges: Recharge[], cashierDicionary: any, nfcDicionary: any, typeFile: 'csv' | 'json') {
  if (typeFile === 'csv') {
    const datas = recharges.map(row => ({
      DATA: dateFormat(row.created_at.toDate(), 'dd/mm/yyyy HH:MM:ss'),
      'ID NFC': row.idCard,
      TERMINAL: cashierDicionary[row.cashierId]?.terminal || '',
      OPERADOR: cashierDicionary[row.cashierId]?.operator || '',
      CPF: cpfFormatMask(cashierDicionary[row.cashierId]?.cpf || 0),
      'NOME CLIENTE': row.idCard ? nfcDicionary[row.idCard]?.name || '' : '',
      'CPF CLIENTE': row.idCard ? nfcDicionary[row.idCard]?.cpf || '' : '',
      PAGAMENTO: PAYMENT_TYPE_PTBR[row.payment_type],
      BANDEIRA: row.cardBrand || '',
      CV: row.codVenda || '',
      'ID TRANS': row.transaction_code || '',  
      ESTORNO: row.reversed ? 'SIM' : 'NÃO',
      VALOR: Money.centsToCoinCsv(row.value),
    }))
    exportCSVFile(datas, 'Recarga-movimentacao')
  } else
    exportJSONFile(recharges, 'Recarga-movimentacao')
}

export function exportSales(sales: Sales[], cashierDicionary: any, nfcDicionary: any,   typeFile: 'csv' | 'json', sectorsDicionary: any, localProductDictionary:any) {
  if (typeFile === 'csv') {
    const datas = sales.map(row => ({
      DATA: dateFormat(row.created_at.toDate(), 'dd/mm/yyyy HH:MM:ss'),
      'ID NFC': row.idCard,
      OPERADOR: cashierDicionary[row.cashierId]?.operator || '',
      CPF: cpfFormatMask(cashierDicionary[row.cashierId]?.cpf || 0),
      TERMINAL: cashierDicionary[row.cashierId]?.terminal || '', 
      SETOR:sectorsDicionary[row.sectorId]?.name || '',
      PAGAMENTO: PAYMENT_TYPE_PTBR[row.payment_type],
      BANDEIRA: row.cardBrand || '',
      CV: row.codVenda || '',
      'ID TRANS': row.transaction_code || '',                                               
      'QNT.PRODUTOS': row.products.reduce((prev, curr) => prev + curr.qtd - curr.qtdReversed, 0),
      PRODUTO: row.products.map((product)=>{
       return `${product.qtd - product.qtdReversed} ${localProductDictionary[product.id]?.name}`
      } ),
      // 'nome cliente': row.idCard ? nfcDicionary[row.idCard]?.name || '' : '',
      // 'cpf cliente': row.idCard ? nfcDicionary[row.idCard]?.cpf || '' : '',
      ESTORNO: row.reversed ? 'SIM' : 'NÃO',
      TOTAL: Money.centsToCoinCsv(row.value),
    }))
    exportCSVFile(datas, 'Consumo-movimentacao')
  } else
    exportJSONFile(sales, 'Consumo-movimentacao')
}