import {
  collection,
  CollectionReference,
	DocumentData,
	getDocs,
	getFirestore,
	onSnapshot,
	query,
	QueryConstraint,
	QueryDocumentSnapshot,
	Timestamp,
	Unsubscribe,
	where,
} from 'firebase/firestore';
import app from '../../config';
import Recharge from '../../entities/eventData/recharge.entity';

class RechargeEventData {
	protected readonly colRefStr: string;
  protected readonly colRef: CollectionReference<DocumentData> ;

	constructor(estabId: string, eventId: string) {
		this.colRefStr = `EstablishmentsData/${estabId}/EventsData/${eventId}/Recharge`;
    this.colRef = collection(getFirestore(app), this.colRefStr);
	}

	async getAll(): Promise<Recharge[]> {
		const snapshot = await getDocs(query(this.colRef))
		return snapshot.docs.map(doc => ({
			id: doc.id,
			...doc.data(),
		})) as any
	}

	async getByNfc(nfc: string): Promise<Recharge[]> {
		const snapshot = await getDocs(query(this.colRef, where('idCard', '==', nfc)))
		return snapshot.docs.map(doc => ({
			id: doc.id,
			...doc.data(),
		})) as any
	}

	async getByNfcReversed(nfc: string): Promise<Recharge[]> {
		const snapshot = await getDocs(query(this.colRef, where('idCard', '==', nfc), where('reversed', '==', true)))
		return snapshot.docs.map(doc => ({
			id: doc.id,
			...doc.data(),
		})) as any
	}

	on(listener: (data: Recharge[]) => void, ...params: QueryConstraint[]): Unsubscribe {
		return onSnapshot(query(this.colRef, ...params), snapshot => {
      const datas: Recharge[] = [];
      snapshot.forEach(doc => datas.push({ id: doc.id, ...doc.data() as any } as Recharge))
      listener(datas)
    })
	}

	onPagination(listener: (data: Recharge[], docs: QueryDocumentSnapshot<DocumentData>[]) => void, ...params: QueryConstraint[]): Unsubscribe {
		return onSnapshot(query(this.colRef, ...params), snapshot => {
      const datas: Recharge[] = [];
      snapshot.forEach(doc => datas.push({ id: doc.id, ...doc.data() as any } as Recharge))
      listener(datas, snapshot.docs)
    })
	}

	async getAllInterval(startDate: Date, endDate: Date): Promise<Recharge[]> {
		const snapshot = await getDocs(query(this.colRef, 
			where('created_at', '>=', Timestamp.fromDate(startDate)),
			where('created_at', '<=', Timestamp.fromDate(endDate))
		))
		return snapshot.docs.map(doc => ({
			id: doc.id,
			...doc.data(),
		})) as any
	}
	async getBySector(sectorId: string): Promise<Recharge[]> {
		const snapshot = await getDocs(query(this.colRef,
			where('sectorId', '==', sectorId)
			
		))
		return snapshot.docs.map(doc => ({
			id: doc.id,
			...doc.data(),
		})) as any
	}

}

export default RechargeEventData;

