import React, { useCallback, useMemo, useContext, useEffect, useState } from 'react';
import PageWrapper from '../../../../layout/PageWrapper/PageWrapper';
import SubHeader, { SubHeaderLeft } from '../../../../layout/SubHeader/SubHeader';
import Page from '../../../../layout/Page/Page';
import Breadcrumb from '../../../../components/bootstrap/Breadcrumb';
import { demoPages } from '../../../../menu';
import { DefaultContext } from '../../../../contexts/default';
import Card, { CardBody, CardHeader, CardLabel, CardTitle } from '../../../../components/bootstrap/Card';
import Button from '../../../../components/bootstrap/Button';
import TableCustom from '../../../../components/MyCustom/TableCustom';
import SelectEstablishment from '../../../../components/MyCustom/SelectEstablishment';
import SelectEvent from '../../../../components/MyCustom/SelectEvent';
import ModalRegisterSectorProducts from '../../../../components/modals/SectorProducts/ModalRegister'
import ProductDB from '../../../../database/wrappers/product';
import useDarkMode from '../../../../hooks/useDarkMode';
import SectorProductDB from '../../../../database/wrappers/sectorProduct';
import Money from '../../../../helpers/utils/money/money';
import { orderBy } from 'firebase/firestore';
import SelectSector from '../../../../components/MyCustom/SelectSector';
import LogoUgetBlack from '../../../../assets/img/logo_uget_black.png'
import LogoUget from '../../../../assets/img/logo_uget.png'

const SectorProducts = () => {
  const { darkModeStatus } = useDarkMode();

  const { estabSelected, sectorSelected, onShowAlert } = useContext(DefaultContext)
  const [products, setProducts] = useState(null)
  const [sectorProductsDicionary, setsectorProductsDicionary] = useState(null)
  const [productsRender, setProductsRender] = useState([])
  const [productSelected, setProductSelected] = useState(null);
  const [showModal, setshowModal] = useState(false);

  useEffect(() => {
    if (!estabSelected) return;
    const onSubscribe = new ProductDB(estabSelected).on(setProducts, orderBy('name', 'asc'));
    return onSubscribe
  }, [estabSelected])

  useEffect(() => {
    if (!estabSelected || !sectorSelected) return;
    const onSubscribe = new SectorProductDB(estabSelected, sectorSelected).on(datas => {
      const dicionary = {};
      datas.forEach(data => dicionary[data.id] = data)
      setsectorProductsDicionary(dicionary)
    });
    return onSubscribe;
  }, [estabSelected, sectorSelected])

  useEffect(() => {
    if (products !== null && sectorProductsDicionary !== null) {
      const productsRender = products.map(product => {
        const sectorProd = sectorProductsDicionary[product.id];
        return {
          ...product,
          ...sectorProd,
          defaultPrice: product.price,
          active: !sectorProd ? true : sectorProd.active,
          sectorProdExist: !!sectorProd,
        }
      })

      setProductsRender(productsRender);
    }
  }, [products, sectorProductsDicionary])

  const handleOpenModalEdit = useCallback((item) => {
    setProductSelected(item)
    setshowModal(true)
  }, [])

  const handleChangeStatus = useCallback((id, item) => {
    const onSuccess = () => { };
    const onError = () => {
      onShowAlert({
        title: 'ERRO!',
        msm: 'Não foi possível atualizar o status do produto',
        icon: 'Error',
        color: 'danger'
      })
    };
    if (item.sectorProdExist) {
      new SectorProductDB(estabSelected, sectorSelected)
        .update(item.id, {
          active: !item.active
        })
        .then(onSuccess)
        .catch(onError)
    } else {
      new SectorProductDB(estabSelected, sectorSelected)
        .define(item.id, {
          active: !item.active
        })
        .then(onSuccess)
        .catch(onError)
    }
  }, [estabSelected, sectorSelected])

  const columns = useMemo(() => ([
    {
      label: 'Imagem',
      field: 'image_url',
      format: row => (
        <img
          src={row.image_url || (darkModeStatus ? LogoUget : LogoUgetBlack)}
          alt="Imagem"
          width={54}
          height={row.image_url ? 54 : 10}
        />
      ),
      noExport: true,
    },
    {
      label: 'Nome',
      field: 'name',
    },
    {
      label: 'Preço Padrão',
      field: 'defaultPrice',
      format: row => Money.centsToMaskMoney(row.defaultPrice),
      formatExport: value => Money.centsToCoin(value),
    },
    {
      label: 'Preço no Setor',
      field: 'price',
      format: row => Money.centsToMaskMoney(row.price),
      formatExport: value => Money.centsToCoin(value),
    },
    {
      label: 'Preço Aplicado',
      field: 'sectorProdExist',
      format: row => (
        <Button
          isLink
          color={!row.sectorProdExist ? 'success' : 'warning'}
          icon={!row.sectorProdExist ? 'check' : 'WarningAmber'}
          className='text-nowrap'>
          {!row.sectorProdExist ? 'Padrão' : 'Setor'}
        </Button>
      ),
      formatExport: value => !value ? 'Padrão' : 'Setor',
    },
    {
      label: 'Status',
      field: 'active',
      format: row => (
        <>
          <Button
            isLink
            color={row.active ? 'success' : 'danger'}
            icon='Circle'
            onClick={() => handleChangeStatus(row.id, row)}
            className='text-nowrap'>
            {row.active ? 'Ativo' : 'Inativo'}
          </Button>
        </>
      )
    },
    {
      label: 'Ações',
      field: 'actions',
      format: row => (
        <div className='w-50 d-flex justify-content-between'>
          <Button
            color={darkModeStatus ? 'light' : 'dark'}
            icon="edit"
            hoverShadow="lg"
            size="lg"
            text=''
            onClick={() => handleOpenModalEdit(row)}
          />
        </div>
      ),
      noExport: true
    },
  ]), [darkModeStatus, handleChangeStatus, handleOpenModalEdit])

  return (
    <>
      <PageWrapper title={demoPages.sector.subMenu.product.text}>
        <SubHeader>
          <SubHeaderLeft>
            <Breadcrumb
              list={[
                { title: 'Setor', to: '/sector' },
                { title: 'Produtos no Setor', to: '/sector/products' },
              ]}
            />
          </SubHeaderLeft>
        </SubHeader>
        <Page container='fluid'>
          <Card>
            <CardHeader>
              <CardLabel icon='TagsFill' iconColor={darkModeStatus ? 'light' : 'dark'}>
                <CardTitle>Produtos</CardTitle>
              </CardLabel>
            </CardHeader>
            <CardBody className='table-responsive'>
              <div className='row d-flex align-items-center mt-4 mx-1' >
                <SelectEstablishment />
                <SelectSector />
              </div>

              <TableCustom titlePdf='Produtos'
                icon="TagsFill"
                 columns={columns} 
                 rows={productsRender} 
                 fileName="Produtos"
                 />
            </CardBody>
          </Card>
        </Page>
      </PageWrapper>

      <ModalRegisterSectorProducts
        open={showModal}
        setIsOpen={setshowModal}
        setIsClose={() => setshowModal(false)}
        productSelected={productSelected}
      />
    </>
  );
};

export default SectorProducts;
