import { memo } from "react"
import HeaderLeftMobile from './Mobile'
import HeaderLeftDesktop from './Desktop'

const HeaderLeftReports = ({ isMobile, tabs, activeTab, themeStatus, setActiveTab, setActiveSubTab }) => {
  return (
    <>
    {isMobile ?
      /////////////////////// versão mobile ///////////////////////
      <HeaderLeftMobile
        tabs={tabs}
        setActiveTab={setActiveTab}
        setActiveSubTab={setActiveSubTab}
      />
      :
      /////////////////////// versão desktop ///////////////////////
      <HeaderLeftDesktop
        tabs={tabs}
        setActiveTab={setActiveTab}
        setActiveSubTab={setActiveSubTab}
        activeTab={activeTab}
        themeStatus={themeStatus}
      />
    }
    </>
  );
}

export default memo(HeaderLeftReports)