import { QueryConstraint, Unsubscribe } from "firebase/firestore";
import Sector from "../entities/sector.entity";
import FirestorePipe from "../utils/pipe";
import EstablishmentDB from "./establishment";

class SectorDB extends FirestorePipe {

  public static readonly colName = 'Sectors'

  constructor(idEstab: string) {
    if (!idEstab)
      throw new Error('Informe o id do estabelecimento')
    super(`${EstablishmentDB.colName}/${idEstab}/${SectorDB.colName}`);
  }

  public create(data: Sector): Promise<any> {
    return this._create(data);
  }
  public update(id: string, data: Sector): Promise<any> {
    return this._update(id, data);
  }
  public delete(id: string): Promise<any> {
    return this._delete(id);
  }
  public getAll(...params: QueryConstraint[]): Promise<Sector[]> {
    return this._getAll<Sector>(...params);
  }
  public get(id: string): Promise<Sector> {
    return this._get(id);
  }
  public on(listener: (data: Sector[]) => void, ...params: QueryConstraint[]): Unsubscribe {
    return this._on(listener, ...params);
  }
  public onDicionary(listener: (data: any) => void, ...params: QueryConstraint[]): Unsubscribe {
    return this._onDicionary(listener, ...params);
  }

  private async saveFile(data: any, label: string) {
    if (data[label]) {
      const resultUpload = await this.uploadFile(new Date().getTime().toString(), data[label]);
      data[label + '_ref'] = resultUpload.ref.fullPath;
      data[label + '_url'] = resultUpload.url;
    }
    delete data[label]
  }

}

export default SectorDB;