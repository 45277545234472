import { doc, getFirestore, QueryConstraint, serverTimestamp, Unsubscribe, writeBatch } from "firebase/firestore";
import randomString from "../../utils/random/randomString";
import app from "../config";
import Event from "../entities/event.entity";
import Setting from "../entities/setting.entity";
import FirestorePipe from "../utils/pipe";
import CoordinatorEventDB from "./coordinatorEvent";
import EntranceDB from "./entrance";
import EstablishmentDB from "./establishment";
import OperatorsDB from "./operatorsEvent";
import SettingDB from "./settings";

class EventDB extends FirestorePipe {

  public static readonly colName = 'Events'

  constructor(private idEstab: string) {
    if (!idEstab)
      throw new Error('Informe o id do estabelecimento')
    super(`${EstablishmentDB.colName}/${idEstab}/${EventDB.colName}`);
  }

  public async create(data: Event, settings?: Setting): Promise<any> {
    try {
      await this.saveFile(data, 'logo');
      await this.saveFile(data, 'banner');
      await this.saveFile(data, 'banner_email');

      const eventId = await this._create(data);
      if (settings)
        await new SettingDB(this.idEstab, eventId).define(settings);
      return eventId;
    } catch (error: any) {
      if (data.banner_ref)
        await this.deleteFile(data.banner_ref)
      if (data.logo_ref)
        await this.deleteFile(data.logo_ref)
      if (data.banner_email_ref)
        await this.deleteFile(data.banner_email_ref)
      throw error.message
    }
  }

  public async update(id: string, data: Event, settings?: Setting): Promise<any> {
    try {
      await this.saveFile(data, 'logo');
      await this.saveFile(data, 'banner');
      await this.saveFile(data, 'banner_email');

      const eventId = await this._update(id, data);
      if (settings)
        await new SettingDB(this.idEstab, id).define(settings);
      return eventId
    } catch (error: any) {
      if (data.banner_ref)
        await this.deleteFile(data.banner_ref)
      if (data.logo_ref)
        await this.deleteFile(data.logo_ref)
      throw error.message

    }
  }
  public delete(id: string): Promise<any> {
    return this._delete(id);
  }
  public getAll(...params: QueryConstraint[]): Promise<Event[]> {
    return this._getAll<Event>(...params);
  }
  public get(id: string): Promise<Event> {
    return this._get(id);
  }
  public on(listener: (data: Event[]) => void, ...params: QueryConstraint[]): Unsubscribe {
    return this._on(listener, ...params);
  }

  private async saveFile(data: any, label: string) {
    if (data[label]) {
      console.log('UPLOAD IMAGE');
      const resultUpload = await this.uploadFile(new Date().getTime().toString(), data[label]);
      data[label + '_ref'] = resultUpload.ref.fullPath;
      data[label + '_url'] = resultUpload.url;
    }
    delete data[label]
  }

  public async clone(id: string, data: any) {
    const eventToCloned = await this._get(id);
    if (!eventToCloned)
      throw new Error('Evento não encontrado');

    const newEvent = {
      ...eventToCloned as any,
      ...data as any,
    } as any

    delete newEvent.id;
    delete newEvent.codeNumber;

    const settings = await new SettingDB(this.idEstab, id).get();

    const idNewEvent = await this.create(newEvent, settings);

    const batch = writeBatch(getFirestore(app));

    const entrances = await new EntranceDB(this.idEstab, id).getAll();
    entrances.forEach(item => {
      const newRef = doc(getFirestore(app), `${EstablishmentDB.colName}/${this.idEstab}/${EventDB.colName}/${idNewEvent}/${EntranceDB.colName}`, randomString(20));
      delete (item as any).id;
      batch.set(newRef, {
        ...item,
        created_at: serverTimestamp()
      });
    })

    const operators = await new OperatorsDB(this.idEstab, id).getAll();
    operators.forEach(item => {
      const newRef = doc(getFirestore(app), `${EstablishmentDB.colName}/${this.idEstab}/${EventDB.colName}/${idNewEvent}/${OperatorsDB.colName}`, item.id);
      delete (item as any).id;
      batch.set(newRef, {
        ...item,
        created_at: serverTimestamp()
      });
    })

    const coordinators = await new CoordinatorEventDB(this.idEstab, id).getAll();
    coordinators.forEach(item => {
      const newRef = doc(getFirestore(app), `${EstablishmentDB.colName}/${this.idEstab}/${EventDB.colName}/${idNewEvent}/${CoordinatorEventDB.colName}`, item.id);
      delete (item as any).id;
      batch.set(newRef, {
        ...item,
        created_at: serverTimestamp()
      });
    })

    return batch.commit()
  }

}

export default EventDB;