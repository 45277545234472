import React, { memo } from "react";
import Button from "../../../components/bootstrap/Button";
import Dropdown, { DropdownItem, DropdownMenu, DropdownToggle } from "../../../components/bootstrap/Dropdown";
import FilterByDate from "../../../components/MyCustom/FilterByDate";
import Money from "../../../helpers/utils/money/money";
import useDarkMode from "../../../hooks/useDarkMode";
import SubHeader, { SubHeaderLeft, SubHeaderRight, SubheaderSeparator } from "../../../layout/SubHeader/SubHeader";

const AdmContabilHeader = ({ TABS, menuSelected, setMenuSelected, onSearch, balance }) => {
  const { themeStatus, darkModeStatus } = useDarkMode();
  return (
    <>
      <SubHeader className={'mb-4'}>
        <SubHeaderLeft>
          <h1 className='h5 mb-0'>Saldo atual:</h1>
          <span className='h3 mb-0'>{Money.centsToMaskMoney(balance)}</span>
        </SubHeaderLeft>
      </SubHeader>
      <SubHeader >
        <SubHeaderLeft>
          <span className='h4 mb-0 fw-bold'>ADM CONTÁBIL</span>
          <SubheaderSeparator />
          {TABS.map((tab) => (
            <React.Fragment key={tab.key}>
              {tab.subTabs ? (
                <Dropdown
                  className=''
                  isOpen={true}
                  key={tab.key}
                >
                  <DropdownToggle>
                    <Button
                      className={`m-0 ${menuSelected === tab.key
                        ? 'font-weight-bold text-dark'
                        : ''
                        }`}
                      color={
                        menuSelected === tab.key
                          ? 'warning'
                          : themeStatus
                      }>
                      {tab.label}
                    </Button>
                  </DropdownToggle>
                  <DropdownMenu>
                    {tab.subTabs.map((subTab) => (
                      <DropdownItem
                        key={subTab.key}
                        onClick={() => {
                          if (tab.key === 'geral') {
                            setMenuSelected(tab.key);
                          }
                        }}>
                        {subTab.label}
                      </DropdownItem>
                    ))}
                  </DropdownMenu>
                </Dropdown>
              ) : (
                <Button
                  key={tab.key}
                  onClick={() => {
                    if (tab.key === 'geral') {
                      setMenuSelected(tab.key);
                    }
                  }}
                  className=''
                  color={menuSelected === tab.key ? 'warning' : 'light'}
                  style={{
                    color: menuSelected === tab.key && darkModeStatus === 'light' ? 'black' : '',
                  }}>
                  {tab.label}
                </Button>
              )}
            </React.Fragment>
          ))}
        </SubHeaderLeft>
        <SubHeaderRight>
          <FilterByDate onSearch={onSearch} />
        </SubHeaderRight>
      </SubHeader>
    </>
  );
}

export default memo(AdmContabilHeader);