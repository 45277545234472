
import React, { useMemo } from "react";
import Money from "../../../helpers/utils/money/money";
import TableReport from "../TableReport";


const LossDamage = ({ equipaments, equipamentsValues }) => {

  const bobines = equipaments?.find((item) => item.key === 'bobines')


  const equipamentsFilter = useMemo(() => {
    if (!equipaments) return [];
    const filter = equipaments.filter((e) =>
      e.key !== 'bobines' && (
        e.key === 'pos' ||
        e.key === 'pos_backup' ||
        e.key === 'totems' ||
        e.key === 'totems_recharges' ||
        e.key === 'bracelets' ||
        e.key === 'cable_lan' ||
        e.key === 'tripod_wifi' ||
        e.key === 'processor_wifi' ||
        e.key === 'router_wifi' ||
        e.key === 'transport_box' ||
        e.key === 'pos_power' ||
        e.key === 'tablet_power' ||
        e.key === 'tablet' ||
        e.key === 'pouch' ||
        e.key === 'ruler' ||
        e.key === 'extension' ||
        e.key === 'totems_pedestal' ||
        e.key === 'totems_cover' ||
        e.key === 'totems_cuffs' ||
        e.key === 'totems_cables' || 
        e.key === 'kit_cordao'
      )

    ).filter(e =>
      e.total_loss > 0 ||
      e.equipamentsValues > 0 ||
      e.qtd_loss > 0
    )

    filter.push({
      equipment: <span className="fw-bold">Total Referente a Perdas e Danos</span>,
      qtd_loss: filter.reduce((acc, curr) => acc + (curr?.qtd_loss || 0), 0),
      value_loss: filter.reduce((acc, curr) => acc + (curr?.value_loss || 0), 0),
      total_loss: filter.reduce((acc, curr) => acc + (curr?.total_loss || 0), 0),
    });
    return filter;
  }, [equipaments]);


  const columns = useMemo(() => ([
    {
      label: 'ITENS',
      field: 'equipment',
    },
    {
      label: 'QUANTIDADE',
      field: 'qtd_loss',

    },
    {
      label: 'VALOR UNITÁRIO',
      field: 'value_loss',
      format: row => Money.centsToMaskMoney(row.value_loss)
    },
    {
      label: 'VALOR TOTAL',
      field: 'total_loss',
      format: row => Money.centsToMaskMoney(row.total_loss),
    },
  ]), []);

  const equipamentsRender = useMemo(() => {
    return equipamentsFilter?.filter(e =>
      e.total_loss > 0 ||
      e.equipamentsValues > 0 ||
      e.qtd_loss > 0
    ) || [];
  }, [equipamentsFilter]);

  const hasValuesToCharge = equipamentsRender.some(e => e.value > 0 || e.total_loss > 0 || bobines?.qtd_loss);
  

  return (
    hasValuesToCharge && (
    <div >
      <div className="row pagebreak ">
      <p>Em razão da não devolução integral dos equipamentos, ou devolução com danos, fica o CLIENTE obrigado a ressarcir a uGet o valor referente aos mesmos: </p>
        <h4 className='text-center p-2 fw-bold'>PERDAS E DANOS</h4>
        <h6 className='text-center mb-5  '> (Exceto bobinas)</h6>
      </div>
     
        <TableReport
          rows={equipamentsRender}
          columns={columns}
          keyExtractor={row => row.id}
        />
     
    </div>)
  );
}

export default LossDamage;
