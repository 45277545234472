import React, { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import SelectEstablishment from '../../../../components/MyCustom/SelectEstablishment';
import SelectEvent from '../../../../components/MyCustom/SelectEvent';
import TableCustom from '../../../../components/MyCustom/TableCustom';
import Breadcrumb from '../../../../components/bootstrap/Breadcrumb';
import Button from '../../../../components/bootstrap/Button';
import Card, { CardBody, CardHeader, CardLabel, CardTitle } from '../../../../components/bootstrap/Card';
import showNotification from '../../../../components/extras/showNotification';
import ModalRegisterCourtesyList from '../../../../components/modals/CourtesyList/modalRegister';
import ModalRegisterUniqueCourtesy from '../../../../components/modals/RegisterCourtesy/index';
import { DefaultContext } from '../../../../contexts/default';
import CustomersGlobalDB from '../../../../database/databaseGlobal/wrappers/customers';
import ListCourtesyDB from '../../../../database/wrappers/listCourtesy';
import PromotersLinkDB from '../../../../database/wrappers/promotersLink';
import notificationBody from '../../../../helpers/utils/functions/notificationBody';
import masks from '../../../../helpers/utils/masks';
import useDarkMode from '../../../../hooks/useDarkMode';
import Page from '../../../../layout/Page/Page';
import PageWrapper from '../../../../layout/PageWrapper/PageWrapper';
import SubHeader, { SubHeaderLeft, SubHeaderRight } from '../../../../layout/SubHeader/SubHeader';
import { demoPages } from '../../../../menu';
import { SEX_LABEL } from '../../../../types/sex';
import convertArrayToObject from '../../../../utils/convertArrayToObject';
import { cpfFormatMask } from '../../../../utils/format/cpfFormat';
const CourtesyListPage = () => {
  const { darkModeStatus } = useDarkMode();
  const { estabSelected, eventSelected, onShowQuestion, establishment, event, user } = useContext(DefaultContext)
  const [openModalRegister, setOpenModalRegister] = useState(false);
  const [openModalRegisterUnique, setOpenModalRegisterUnique] = useState(false);
  const [renderedRows, setRenderedRows] = useState([]);
  const [listCourtesy, setlistCourtesy] = useState([])
  const [promotersDicionary, setpromotersDicionary] = useState([]);
  useEffect(() => {
    if (!estabSelected || !eventSelected) return;
    const onSubscriber = new PromotersLinkDB(estabSelected, eventSelected).getAll()
      .then(datas => {
        setpromotersDicionary(convertArrayToObject(datas, 'id'));
      })
      .catch(error => {
        console.error('Erro ao obter dados:', error);
      });
    return onSubscriber;
  }, [estabSelected, eventSelected]);



  const refLinkCsv = useRef(null)

  useEffect(() => {
    if (!estabSelected || !eventSelected) return;
    const onSubscriber = new ListCourtesyDB(estabSelected, eventSelected).on(setlistCourtesy)
    return onSubscriber;
  }, [estabSelected, eventSelected])

  const onDownloadCsvFile = useCallback(() => {
    refLinkCsv.current.click();
  }, [refLinkCsv])

  const handleCloseModal = useCallback(() => {
    setOpenModalRegister(false)
  }, [])

  const handleRegister = useCallback(() => {
    setOpenModalRegister(true)
  }, [])

  const handleRegisterUnique = useCallback(() => {
    setOpenModalRegisterUnique(true)
  }, [])

  const handleCloseModalUnique = useCallback(() => {
    setOpenModalRegisterUnique(false)
  }, [])

  const handleChangeItem = useCallback((id, key, value) => {
    new ListCourtesyDB(estabSelected, eventSelected)
      .update(id, {
        [key]: value
      })
      .then(() => showNotification('', notificationBody('Registro alterado com sucesso'), 'success'))
      .catch(() => showNotification('', notificationBody('Falhou ao atualizar o registro'), 'danger'))
  }, [estabSelected, eventSelected])

  const handleOpenModalDelete = useCallback((id) => {
    onShowQuestion({
      title: 'Exclusão de Entrada Antecipada',
      message: 'Você está excluindo uma entrada antecipada. Está ação é irreversível. Deseja continuar?',
      onConfirm: () => {
        new ListCourtesyDB(estabSelected, eventSelected)
          .delete(id)
          .then(res => showNotification('', notificationBody('Registro deletado com sucesso'), 'success'))
          .catch(err => showNotification('', notificationBody('Falhou ao deletar o registro'), 'danger'))
      }
    })
  }, [estabSelected, eventSelected])

  useEffect(() => {
    const fetchData = async () => {
      const rows = await Promise.all(
        listCourtesy.map(async row => {
          const cpfClient = row.cpf.toString()
          const client = await new CustomersGlobalDB().getByCPF(cpfClient);
          const promoterName = promotersDicionary && row?.promoterId ? promotersDicionary[row?.promoterId]?.name : 'Indefinido';
          return {
            ...row,
            cpf: cpfFormatMask(row.cpf),
            name: client ? client.name : row.name,
            email: client ? client.email : '--',
            phone: client ? client.phone : row.phone.toString(),
            sex: client ? SEX_LABEL[client.sex] : '--',
            birthDate: client ? client.birthDate : '--',
            promoterName,
          };
        })
      );
      setRenderedRows(rows);
    };

    fetchData();
  }, [listCourtesy, promotersDicionary]);


  const columns = useMemo(() => ([
    {
      label: 'Solicitação',
      field: 'approved',
      format: row => (
        <Button
          isLink
          color={row.approved ? 'success' : 'danger'}
          icon={row.approved ? 'Check' : 'Lock'}
          className='text-nowrap'
          onClick={() => handleChangeItem(row.id, 'approved', !row.approved)}
        >
          {row.approved ? 'Liberado' : 'Bloqueado'}
        </Button>
      ),
      formatExport: value => value?'Liberado' : 'Bloqueado'
    },
    {
      label: 'Nome',
      field: 'name',
    },
    {
      label: 'E-mail',
      field: 'email',
    },
    {
      label: 'CPF',
      field: 'cpf',
    },
    {
      label: 'Telefone',
      field: 'phone',
      format: row => row.phone ? masks.phone(row.phone) : '--'
    },
    {
      label: 'Data Nasc',
      field: 'birthDate',
    },
    {
      label: 'Promoter',
      field: 'promoterName'
    },
    {
      label: 'Utilizado',
      field: 'used',
      format: row => (
        <Button
          isLink
          color={row.used ? 'danger' : 'success'}
          icon='Circle'
          className='text-nowrap'
          onClick={() => handleChangeItem(row.id, 'used', !row.used)}
        >
          {row.used ? 'Usado' : 'Livre'}
        </Button>
      ),
      formatExport: value => !value
    },
    {
      label: 'Ações',
      field: 'actions',
      format: row => (
        <div className='w-75 d-flex justify-content-between'>
          <Button
            color="danger"
            icon="Trash"
            shadow="sm"
            hoverShadow="sm"
            size="sm"
            onClick={() => handleOpenModalDelete(row.id)}
          />
        </div>
      ),
      noExport: true
    },
  ]), [handleOpenModalDelete, handleChangeItem])



  return (
    <>
      <PageWrapper title={demoPages.event.subMenu.courtesyList.text}>
        <SubHeader>
          <SubHeaderLeft>
            <Breadcrumb
              list={[
                { title: 'Evento', to: '/event/' },
                { title: 'Lista de Cortesia', to: '/event/courtesyList' },
              ]}
            />
          </SubHeaderLeft>
          <SubHeaderRight>
            {/* <a hidden ref={refLinkCsv} href='https://firebasestorage.googleapis.com/v0/b/e-pdx-b9d3b.appspot.com/o/ExtraFiles%2Flista-vip.csv?alt=media&token=a1e3774d-e4e6-4fa2-8de0-4448e0b80a8a' />
            <Button
              type='file'
              className='text-dark'
              color='success'
              icon='download'
              shadow="sm"
              hoverShadow="sm"
              onClick={onDownloadCsvFile}
            >
              Download CSV
            </Button>
            <Button
              color='primary'
              icon='plus'
              shadow="sm"
              hoverShadow="sm"
              onClick={handleRegister}
            >
              Cadastrar
            </Button> */}

            <Button
              className='text-dark'
              color='warning'
              icon='plus'
              shadow="sm"
              hoverShadow="sm"
              onClick={handleRegisterUnique}
            >
              Cadastro único
            </Button>
          </SubHeaderRight>
        </SubHeader>

        <Page container="fluid">
          <Card>
            <CardHeader>
              <CardLabel icon='ConfirmationNumber' iconColor={darkModeStatus ? 'light' : 'dark'}>
                <CardTitle>Lista vip</CardTitle>
              </CardLabel>
            </CardHeader>
            <CardBody className='table-responsive'>
              <div className='row align-items-center  '>
                <SelectEstablishment />
                <SelectEvent />
              </div>
              <TableCustom
                titlePdf='Lista de Cortesia'
                icon="ConfirmationNumber"
                fontSize={8}
                rows={renderedRows}
                columns={columns}
                keyExtractor={row => row.id}
                 fileName="Lista-vip"
              />
            </CardBody>
          </Card>
        </Page>
      </PageWrapper>

      <ModalRegisterCourtesyList
        open={openModalRegister}
        setIsOpen={setOpenModalRegister}
        setIsClose={handleCloseModal}
      />

      <ModalRegisterUniqueCourtesy
        open={openModalRegisterUnique}
        setIsOpen={setOpenModalRegisterUnique}
        setIsClose={handleCloseModalUnique}
      />
    </>
  );
};

export default CourtesyListPage;
