import { useCallback } from "react";
import masks from "../../../helpers/utils/masks";
import useDarkMode from "../../../hooks/useDarkMode";
import Checks, { ChecksGroup } from "../../bootstrap/forms/Checks";
import FormGroup from "../../bootstrap/forms/FormGroup";
import Input from "../../bootstrap/forms/Input";
import Label from "../../bootstrap/forms/Label";
import Card, { CardBody, CardHeader, CardLabel, CardTitle, CardSubTitle } from "../../bootstrap/Card";
import Avatar from "../Avatar";
import Alelo from '../../../assets/img/brands/alelo.svg'
import AmericanExpress from '../../../assets/img/brands/american-express.svg'
import Banrisul from '../../../assets/img/brands/banrisul.svg'
import Cabal from '../../../assets/img/brands/cabal.svg'
import DinersClub from '../../../assets/img/brands/diners-club.svg'
import Elo from '../../../assets/img/brands/elo.svg'
import HiperCard from '../../../assets/img/brands/hipercard.svg'
import Maestro from '../../../assets/img/brands/maestro.svg'
import MasterCard from '../../../assets/img/brands/masterCard.svg'
import Sodexo from '../../../assets/img/brands/sodexo.svg'
import ValeRF from '../../../assets/img/brands/vale-refeicao.svg'
import Visa from '../../../assets/img/brands/visa.svg'
import AvatarVideo from "../AvatarVideo";


const SettingsEvent = ({ isModal, formik }) => {

	const { darkModeStatus } = useDarkMode();

	const handleImageHome = useCallback((e) => {
		const [file] = Array.from(e.target.files)
		formik.setValues({
			...formik.values,
			imageHome: file,
			imageHome_url: URL.createObjectURL(file)
		})
	}, [formik.values])

	const handleImageBanner = useCallback((e) => {
		const [file] = Array.from(e.target.files)
		formik.setValues({
			...formik.values,
			imageBanner: file,
			imageBanner_url: URL.createObjectURL(file)
		})
	}, [formik.values])

	console.log('imageHome_url', formik.values.imageHome_url);

	return (
		<div className='col-md-12 d-grid gap-4'>
			<div className='row p-2'>
				<div className='col-md-6'>
					<Label>Habilitar Tipo Pagamento</Label>
					<ChecksGroup>
						<Checks
							type='switch'
							id='paymentDebit'
							label='Débito'
							name='paymentDebit'
							onChange={formik.handleChange}
							checked={formik.values.paymentDebit}
						/>
						<Checks
							type='switch'
							id='paymentCredit'
							label='Crédito'
							name='paymentCredit'
							onChange={formik.handleChange}
							checked={formik.values.paymentCredit}
						/>
						<Checks
							type='switch'
							id='paymentPix'
							label='PIX'
							name='paymentPix'
							onChange={formik.handleChange}
							checked={formik.values.paymentPix}
						/>
						<Checks
							type='switch'
							id='paymentCash'
							label='Dinheiro'
							name='paymentCash'
							onChange={formik.handleChange}
							checked={formik.values.paymentCash}
						/>
						<Checks
							type='switch'
							id='paymentCourtesy'
							label='Cortesia'
							name='paymentCourtesy'
							onChange={formik.handleChange}
							checked={formik.values.paymentCourtesy}
						/>
					</ChecksGroup>

					<FormGroup id="minValuePix" label="Pix valor mínimo" className='col-md-6'>
						<Input
		
						ariaDescribedby='inputGroupPrepend'
						onChange={formik.handleChange}
						onBlur={formik.handleBlur}
						value={masks.money(formik.values.minValuePix)}
						isValid={formik.isValid}
						isTouched={formik.touched.minValuePix}
						invalidFeedback={formik.errors.minValuePix}
						validFeedback='Assim está bom!'
						placeholder="Ex: 10.00"
						/>
					</FormGroup>
				</div>
				<div className='col-md-6'>
					<div className='col mb-2'>
						<Label>Gerar Número do Pedido</Label>
						<Checks
							label={formik.values.requestNumber ? 'Ativo' : 'Inativo'}
							id='requestNumber'
							name='requestNumber'
							onChange={formik.handleChange}
							checked={formik.values.requestNumber}
							type='switch'
						/>
					</div>
					<div className='col mb-2'>
						<Label>Utilizar impressoras</Label>
						<Checks
							label={formik.values.usePrinter ? 'Ativo' : 'Inativo'}
							id='usePrinter'
							name='usePrinter'
							onChange={formik.handleChange}
							checked={formik.values.usePrinter}
							type='switch'
						/>
					</div>
					<div className='col mb-2'>
						<Label>Habilitar QR Code no Ticket</Label>
						<Checks
							label={formik.values.useQrcodeInTicket ? 'Ativo' : 'Inativo'}
							id='useQrcodeInTicket'
							name='useQrcodeInTicket'
							onChange={formik.handleChange}
							checked={formik.values.useQrcodeInTicket}
							type='switch'
						/>
					</div>
					<div className='col mb-2'>
						<Label>Observação no Pedido</Label>
						<Checks
							label={formik.values.observationInRequest ? 'Ativo' : 'Inativo'}
							id='observationInRequest'
							name='observationInRequest'
							onChange={formik.handleChange}
							checked={formik.values.observationInRequest}
							type='switch'
						/>
					</div>
					<div className='col mb-2'>
						<Label>Desativar produtos em estoque zerado</Label>
						<Checks
							label={formik.values.disableProductsInZeroStock ? 'Ativo' : 'Inativo'}
							id='disableProductsInZeroStock'
							name='disableProductsInZeroStock'
							onChange={formik.handleChange}
							checked={formik.values.disableProductsInZeroStock}
							type='switch'
						/>
					</div>

					<div className='col mb-2'>
						<Label>Imprimir Ticket no Cashless</Label>
						<Checks
							label={formik.values.printConsumptionAsTicket ? 'Ativo' : 'Inativo'}
							id='printConsumptionAsTicket'
							name='printConsumptionAsTicket'
							onChange={formik.handleChange}
							checked={formik.values.printConsumptionAsTicket}
							type='switch'
						/>
					</div>
				</div>
			</div>

			<div className='row p-2'>
				<div className='col'>
					<Label>Ticket Único</Label>
					<Checks
						label={formik.values.ticketUnique ? 'Ativo' : 'Inativo'}
						id='ticketUnique'
						name='ticketUnique'
						onChange={formik.handleChange}
						checked={formik.values.ticketUnique}
						type='switch'
					/>
				</div>

				<div className='col'>
					<Label>Exibir Levar ou Comer </Label>
					<Checks
						label={formik.values.eatTake ? 'Ativo' : 'Inativo'}
						id='eatTake'
						name='eatTake'
						onChange={formik.handleChange}
						checked={formik.values.eatTake}
						type='switch'
					/>
				</div>
			</div>

			<div className='row p-2'>
				<div className='col'>
					<Label>Emitir Nota Fiscal </Label>
					<Checks
						label={formik.values.fiscal ? 'Ativo' : 'Inativo'}
						id='fiscal'
						name='fiscal'
						onChange={formik.handleChange}
						checked={formik.values.fiscal}
						type='switch'
					/>
				</div>
				<div className='col'>
					<Label>Solicitar nome no pedido</Label>
					<Checks
						label={formik.values.nameRequest ? 'Ativo' : 'Inativo'}
						id='nameRequest'
						name='nameRequest'
						onChange={formik.handleChange}
						checked={formik.values.nameRequest}
						type='switch'
					/>
				</div>
			</div>

			<div className='row p-2'>
				<div className='col'>
					<Label>Taxa de Serviço</Label>
					<Checks
						label={formik.values.taxService ? 'Ativo' : 'Inativo'}
						id='taxService'
						name='taxService'
						onChange={formik.handleChange}
						checked={formik.values.taxService}
						type='switch'
					/>
				</div>
				<div className='col'>
					<FormGroup
						id='taxServiceValue'
						label='Taxa de Serviço (%)'
						className='col-md-6'>
						<Input
							type='number'
							ariaDescribedby='inputGroupPrepend'
							onChange={formik.handleChange}
							onBlur={formik.handleBlur}
							value={formik.values.taxServiceValue}
							isValid={formik.isValid}
							isTouched={formik.touched.taxServiceValue}
							invalidFeedback={formik.errors.taxServiceValue}
							validFeedback='Assim está bom!'
							placeholder='Ex: 10%'
						/>
					</FormGroup>
				</div>
			</div>

			<div className='row p-2'>
				<div className='col-md-6'>
					<FormGroup
						id='cashlessValue'
						label='Valor da taxa de ativação'
						className='col-md-6'>
						<Input
							ariaDescribedby='inputGroupPrepend'
							onChange={formik.handleChange}
							onBlur={formik.handleBlur}
							value={masks.money(formik.values.cashlessValue)}
							isValid={formik.isValid}
							isTouched={formik.touched.cashlessValue}
							invalidFeedback={formik.errors.cashlessValue}
							validFeedback='Assim está bom!'
							placeholder='Ex: R$ 10,00'
						/>
					</FormGroup>
					<FormGroup
						id='tokenGwp'
						label='Token GWP'
						className='col-md-6 mt-2'>
						<Input
							ariaDescribedby='inputGroupPrepend'
							onChange={formik.handleChange}
							onBlur={formik.handleBlur}
							value={formik.values.tokenGwp || ''}
							isValid={formik.isValid}
							isTouched={formik.touched.tokenGwp}
							invalidFeedback={formik.errors.tokenGwp}
							validFeedback='Assim está bom!'
							placeholder='(Opcional) '
						/>
					</FormGroup>
				</div>
				<div className='col-md-6'>
					<Label>Permitir Transferir Saldo</Label>
					<Checks
						label={formik.values.transferBalance ? 'Ativo' : 'Inativo'}
						id='transferBalance'
						name='transferBalance'
						onChange={formik.handleChange}
						checked={formik.values.transferBalance}
						type='switch'
					/>
					<Label>Bilheteria</Label>
					<Checks
						label={formik.values.entrance ? 'Ativo' : 'Inativo'}
						id='entrance'
						name='entrance'
						onChange={formik.handleChange}
						checked={formik.values.entrance}
						type='switch'
					/>
					<Label>Consumação Antecipada</Label>
					<Checks
						label={formik.values.ingresse ? 'Ativo' : 'Inativo'}
						id='ingresse'
						name='ingresse'
						onChange={formik.handleChange}
						checked={formik.values.ingresse}
						type='switch'
					/>

					<Label>Lista de cortesia</Label>
					<Checks
						label={formik.values.listCourtesy ? 'Ativo' : 'Inativo'}
						id='listCourtesy'
						name='listCourtesy'
						onChange={formik.handleChange}
						checked={formik.values.listCourtesy}
						type='switch'
					/>

					<Label>Venda Avulsa</Label>
					<Checks
						label={formik.values.singleSale ? 'Ativo' : 'Inativo'}
						id='singleSale'
						name='singleSale'
						onChange={formik.handleChange}
						checked={formik.values.singleSale}
						type='switch'
					/>
				</div>
			</div>

			{isModal && (
				<div className="row p-2">
					<Card>
						<CardHeader>
							<CardLabel icon='AppSettingsAlt' iconColor={darkModeStatus ? 'light' : 'dark'}>
								<CardTitle>Configurações dos Totems Ticket</CardTitle>
								<CardSubTitle>Preencha apenas para os casos de utilização dos totems ticket</CardSubTitle>
							</CardLabel>
						</CardHeader>
						<CardBody>
							<div className='row p-2'>
								<div className='col-md-6'>
									<div className='d-flex flex-row justify-content-center'>
										<label>Image Home</label>
									</div>
									<AvatarVideo
										id='imageHome_url'
										value={formik.values.imageHome_url}
										onChange={handleImageHome} />
								</div>
								<div className='col-md-6'>
									<div className='d-flex flex-row justify-content-center'>
										<label>Image Banner</label>
									</div>
									<AvatarVideo
										id='imageBanner_url'
										value={formik.values.imageBanner_url}
										onChange={handleImageBanner} />
								</div>
							</div>
							<div className="row mt-4  ">
								<div className="col-md-6" >
									<CardHeader className="p-0 py-4">
										<CardLabel icon='ColorLens' iconColor='#000000'>
											<CardTitle>Cores e tipografia</CardTitle>
										</CardLabel>
									</CardHeader>
									<div className='row d-flex pt-4'>
										<div className='row col-md-12 '>
											<FormGroup id="backgroundColor" label="Cor Background" className='col-md-3' >
												<Input
													type='color'
													ariaDescribedby='inputGroupPrepend'
													onChange={formik.handleChange}
													onBlur={formik.handleBlur}
													value={formik.values.backgroundColor}
													isValid={formik.isValid}
													isTouched={formik.touched.backgroundColor}
													invalidFeedback={formik.errors.backgroundColor}
													validFeedback='Assim está bom!'
													placeholder="Ex: #000000"
												/>
											</FormGroup>
											<FormGroup id="fontColor" label="Cor da Fonte" className='col-md-3' >
												<Input
													type='color'
													ariaDescribedby='inputGroupPrepend'
													onChange={formik.handleChange}
													onBlur={formik.handleBlur}
													value={formik.values.fontColor}
													isValid={formik.isValid}
													isTouched={formik.touched.fontColor}
													invalidFeedback={formik.errors.fontColor}
													validFeedback='Assim está bom!'
													placeholder="Ex: #000000"
												/>
											</FormGroup>
											<FormGroup id="touchColor" label="Cor do toque" className='col-md-3'>
												<Input
													type='color'
													ariaDescribedby='inputGroupPrepend'
													onChange={formik.handleChange}
													onBlur={formik.handleBlur}
													value={formik.values.touchColor}
													isValid={formik.isValid}
													isTouched={formik.touched.touchColor}
													invalidFeedback={formik.errors.touchColor}
													validFeedback='Assim está bom!'
													placeholder="Ex: #000000"
												/>
											</FormGroup>
										</div>
										<FormGroup label="Tipo de fonte" className='p-3 '>
											<ChecksGroup
												isValid={formik.isValid}
												isTouched={formik.touched.fontType}
												invalidFeedback={formik.errors.fontType}
												className='d-flex justify-content-lg-start '
											>
												<Checks
													type="radio"
													id="fontType"
													label="Maúscula"
													name="fontType"
													value="UPPERCASE"
													onChange={formik.handleChange}
													checked={formik.values.fontType}
													isInline
												/>
												<Checks
													type="radio"
													id="fontType2"
													label="Minúscula"
													name="fontType"
													value="LOWERCASE"
													onChange={formik.handleChange}
													checked={formik.values.fontType}
													isInline
												/>
												<Checks
													type="radio"
													id="fontType3"
													label="Normal"
													name="fontType"
													value="NORMAL"
													onChange={formik.handleChange}
													checked={formik.values.fontType}
													isInline
												/>
											</ChecksGroup>
										</FormGroup>
									</div>
								</div>
								<div className="col-md-6">
									<CardHeader>
										<CardLabel icon='CreditCard' iconColor='#000000'>
											<CardTitle>Pagamento</CardTitle>
										</CardLabel>
									</CardHeader>
									<div className='col-md-12 d-flex g-4 justify-content-lg-between p-4'>
										<div className='row g-2'>
											<div className='d-flex col-md-4 align-items-center justify-content-start  gap-3'>
												<Checks
													type="switch"
													id="brandVisa"
													name="brandVisa"
													onChange={formik.handleChange}
													checked={formik.values.brandVisa}
												/>
												<img src={Visa} height={12} />
											</div>
											<div className='d-flex col-md-4 align-items-center justify-content-start gap-3'>
												<Checks
													type="switch"
													id="brandMasterCard"
													name="brandMasterCard"
													onChange={formik.handleChange}
													checked={formik.values.brandMasterCard}
												/>
												<img src={MasterCard} height={25} />
											</div>
											<div className='d-flex col-md-4 align-items-center justify-content-start gap-3'>
												<Checks
													type="switch"
													id="brandMaestro"
													name="brandMaestro"
													onChange={formik.handleChange}
													checked={formik.values.brandMaestro}
												/>
												<img src={Maestro} height={25} />
											</div>
											<div className='d-flex col-md-4 align-items-center justify-content-start gap-3'>
												<Checks
													type="switch"
													id="brandElo"
													name="brandElo"
													onChange={formik.handleChange}
													checked={formik.values.brandElo}
												/>
												<img src={Elo} height={15} />
											</div>
											<div className='d-flex col-md-4 align-items-center justify-content-start gap-3'>
												<Checks
													type="switch"
													id="brandHiperCard"
													name="brandHiperCard"
													onChange={formik.handleChange}
													checked={formik.values.brandHiperCard}
												/>
												<img src={HiperCard} height={15} />
											</div>
											<div className='d-flex col-md-4 align-items-center justify-content-start gap-3'>
												<Checks
													type="switch"
													id="brandAlelo"
													name="brandAlelo"
													onChange={formik.handleChange}
													checked={formik.values.brandAlelo}
												/>
												<img src={Alelo} height={25} />
											</div>
											<div className='d-flex col-md-4 align-items-center justify-content-start gap-3'>
												<Checks
													type="switch"
													id="brandSodexo"
													name="brandSodexo"
													onChange={formik.handleChange}
													checked={formik.values.brandSodexo}
												/>
												<img src={Sodexo} height={15} />
											</div>
											<div className='d-flex col-md-4 align-items-center justify-content-start gap-3'>
												<Checks
													type="switch"
													id="brandCabal"
													name="brandCabal"
													onChange={formik.handleChange}
													checked={formik.values.brandCabal}
												/>
												<img src={Cabal} height={15} />
											</div>
											<div className='d-flex col-md-4 align-items-center justify-content-start gap-3'>
												<Checks
													type="switch"
													id="brandDinersClub"
													name="brandDinersClub"
													onChange={formik.handleChange}
													checked={formik.values.brandDinersClub}
												/>
												<img src={DinersClub} height={20} />
											</div>
											<div className='d-flex col-md-4 align-items-center justify-content-start gap-3'>
												<Checks
													type="switch"
													id="brandVr"
													name="brandVr"
													onChange={formik.handleChange}
													checked={formik.values.brandVr}
												/>
												<img src={ValeRF} height={35} />
											</div>
											<div className='d-flex col-md-4 align-items-center justify-content-start gap-3'>
												<Checks
													type="switch"
													id="brandBanrisul"
													name="brandBanrisul"
													onChange={formik.handleChange}
													checked={formik.values.brandBanrisul}
												/>
												<img src={Banrisul} height={12} />
											</div>
											<div className='d-flex col-md-4 align-items-center justify-content-start gap-3'>
												<Checks
													type="switch"
													id="brandAmericanExpress"
													name="brandAmericanExpress"
													onChange={formik.handleChange}
													checked={formik.values.brandAmericanExpress}
												/>
												<img src={AmericanExpress} height={50} />
											</div>
										</div>
									</div>
								</div>
							</div>
						</CardBody>
					</Card>
				</div>

			)}
		</div>
	);
};

export default SettingsEvent;
