import { useFormik } from 'formik';
import React, { useCallback, useContext, useEffect, useState } from 'react';
import Card, { CardBody, CardHeader, CardLabel, CardTitle } from '../../../../components/bootstrap/Card';
import BtnConfirm from '../../../../components/MyCustom/BtnConfirm';
import BtnLoad from '../../../../components/MyCustom/BtnLoad';
import SelectEstablishment from '../../../../components/MyCustom/SelectEstablishment';
import PreAlert from '../../../../helpers/utils/preAlert';
import useDarkMode from '../../../../hooks/useDarkMode';
import Page from '../../../../layout/Page/Page';
import PageWrapper from '../../../../layout/PageWrapper/PageWrapper';
import { demoPages } from '../../../../menu';

import { DefaultContext } from '../../../../contexts/default';
import Label from '../../../../components/bootstrap/forms/Label';
import Checks from '../../../../components/bootstrap/forms/Checks';
import EstablishmentSettingDB from '../../../../database/wrappers/establishmentSettings';
import masks from '../../../../helpers/utils/masks';
import FormGroup from '../../../../components/bootstrap/forms/FormGroup';
import Input from '../../../../components/bootstrap/forms/Input';

const Settings = () => {
  const { darkModeStatus } = useDarkMode();

  const [setting, setsetting] = useState(null)
  const [loading, setloading] = useState(false)

  const { estabSelected, onShowAlert } = useContext(DefaultContext);

  useEffect(() => {
    if(!estabSelected) return;
    const onSubscriber = new EstablishmentSettingDB(estabSelected).on(setsetting)
    return () => {
      onSubscriber();
      formik.resetForm();
    }
  },[estabSelected])

  useEffect(() => {
    if(setting) {
      formik.setValues({
        allowOperatorRequestRegistration: setting.allowOperatorRequestRegistration,
        cnpj: setting.cnpj || '',
      })
    } else {
      formik.resetForm();
    }
  },[setting])

  const validate = useCallback((values) => {
    const errors = {};
    
    if (!values.cnpj) {
      errors.cnpj = 'Este campo é necessário.';
    } else if (values.cnpj.length < 18) {
      errors.cnpj = 'O CNPJ precisa ter 14 dígitos.';
    }
  
    return errors;
  },[])

  const formik = useFormik({
    initialValues: {
      allowOperatorRequestRegistration: false,
      cnpj: '',
    },
    validate,
    onSubmit: values => {
      const {
        allowOperatorRequestRegistration,
        cnpj,
      } = values

      const data = {
        allowOperatorRequestRegistration: allowOperatorRequestRegistration || false,
        cnpj,
      }

      if(!estabSelected)
        return onShowAlert(PreAlert.error('Selecione um estabelecimento para definir as configurações'))

      const onSuccessUpdate = () => {
        onShowAlert(PreAlert.success('Registro atualizado com sucesso'))
      }
      const onError = (error) => {
        console.error(error);
        onShowAlert(PreAlert.error('Falhou ao cadastrar o registro'))
      }

      setloading(true);
      new EstablishmentSettingDB(estabSelected)
        .define(data)
        .then(onSuccessUpdate)
        .catch(onError)
        .finally(() => setloading(false))
    }
  });

  return (
    <PageWrapper title={demoPages.establishment.subMenu.settings.text}>
      <Page>
        <form onSubmit={formik.handleSubmit}>
        <div className='row d-flex align-items-center mt-4 mx-1' >
          <SelectEstablishment />
          
          <div className='d-flex flex-row w-100 justify-content-end'>
            {loading ? (
              <BtnLoad />
            ) : (
              <BtnConfirm />
            )}
          </div>
        </div>
        <div className="row">
          <Card>
            <CardHeader>
              <CardLabel icon='Settings' iconColor={darkModeStatus ? 'light' : 'dark'}>
                <CardTitle>Configurações do Estabelecimento</CardTitle>
              </CardLabel>
            </CardHeader>
            <CardBody>
              <div className='row col-md-12'>
                <div className='col mb-6'>
                  <Label>Permitir cadastro de operadores avulso</Label>
                  <Checks
                    label={formik.values.allowOperatorRequestRegistration ? 'Permitir' : 'Não Permitir'}
                    id='allowOperatorRequestRegistration'
                    name='allowOperatorRequestRegistration'
                    onChange={formik.handleChange}
                    checked={formik.values.allowOperatorRequestRegistration}
                    type='switch'
                  />
                </div>
                <div className='col mb-6'>
                  <FormGroup
                    id='cnpj'
                    label='CNPJ para Transações'>
                    <Input
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={masks.cnpj(formik.values.cnpj)}
                      isValid={formik.isValid}
                      isTouched={formik.touched.cnpj}
                      invalidFeedback={formik.errors.cnpj}
                      validFeedback='Assim está bom!'
                      placeholder='34237209000195'
                    />
                  </FormGroup>
                </div>
              </div>
            </CardBody>
          </Card>
        </div>
        </form>
      </Page>
    </PageWrapper >
  );
};

export default Settings;