import React, { memo, useMemo } from 'react';
import Card, { CardActions, CardBody, CardHeader, CardLabel, CardTitle } from '../../../components/bootstrap/Card';
import Money from '../../../helpers/utils/money/money';
import useDarkMode from '../../../hooks/useDarkMode';
import TableCustom from '../../../components/MyCustom/TableCustom';

const TaxaAdquirencia = ({ banners_card }) => {
  const { darkModeStatus } = useDarkMode();

  const [totalBruto, totalTaxasValor] = useMemo(() => !banners_card ? [0,0] : [
    Object.keys(banners_card).filter(key => key.includes('gross')).reduce((prev, key) => prev + banners_card[key],0),
    Object.keys(banners_card).filter(key => !key.includes('tax') && !key.includes('gross')).reduce((prev, key) => prev + banners_card[key],0),
  ],[banners_card])

  const rows = [
    {
      name: 'Visa',
      amountTotal: banners_card?.gross_visa,
      tax: banners_card?.tax_visa,
      amount: banners_card?.visa
    },

    {
      name: 'Visa Electron',
      amountTotal: banners_card?.gross_visa_electron,
      tax: banners_card?.tax_visa_electron,
      amount: banners_card?.visa_electron
    },
    {
      name: 'MasterCard',
      amountTotal: banners_card?.gross_master,
      tax: banners_card?.tax_master,
      amount: banners_card?.master
    },
    {
      name: 'Maestro',
      amountTotal: banners_card?.gross_maestro,
      tax: banners_card?.tax_maestro,
      amount: banners_card?.maestro
    },
    {
      name: 'Elo',
      amountTotal: banners_card?.gross_elo,
      tax: banners_card?.tax_elo,
      amount: banners_card?.elo
    },
    {
      name: 'Dinners',
      amountTotal: banners_card?.gross_dinners,
      tax: banners_card?.tax_dinners,
      amount: banners_card?.dinners
    },
    {
      name: 'Amex',
      amountTotal: banners_card?.gross_amex,
      tax: banners_card?.tax_amex,
      amount: banners_card?.amex
    },
    {
      name: 'PIX',
      amountTotal: banners_card?.gross_pix,
      tax: banners_card?.tax_pix,
      amount: banners_card?.pix
    },
    {
      name: <span style={{fontSize: 'larger', fontWeight: 'bold'}}>TOTAL</span>,
      amountTotal: totalBruto,
      tax: 0,
      amount: totalTaxasValor
    },

  ]
  const columns = useMemo(() => ([
    {
      label: 'BANDEIRA',
      field: 'name',
      formatExport: value => (value.name)
     
    },
    {
      label: 'BRUTO',
      field: 'amountTotal',
      format: row => Money.centsToMaskMoney(row.amountTotal),
      formatExport: value => Money.centsToMaskMoney(value)
    },
    {
      label: 'TAXA',
      field: 'tax',
      format: row => `${row?.tax?.toFixed(2)}%`,
      
    },
    {
      label: 'VALOR',
      field: 'amount',
      format: row => Money.centsToMaskMoney(row.amount),
      formatExport: value => Money.centsToMaskMoney(value)
    },
  ]))



  return (
    <Card>
      <CardHeader>
        <CardLabel icon='CreditCard' iconColor={!darkModeStatus ? 'dark' : 'light'}>
          <CardTitle className='d-flex flex-row align-items-center'>
            <CardActions>
              TAXAS DE ADQUIRÊNCIA
            </CardActions>
          </CardTitle>
        </CardLabel>
      </CardHeader>
      <CardBody className='table-responsive p-5'>
      <TableCustom  rows={rows} columns={columns} noShowPagination fileName={'Taxas-de-adquirência'}/>
        {/* <table className='table table-borderless table-hover'>
          <thead className='border-bottom'>
            <tr>
              <th>BANDEIRA</th>
              <th>BRUTO</th>
              <th>TAXA</th>
              <th>VALOR</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>Visa</td>
              <td>{Money.centsToMaskMoney(banners_card?.gross_visa || 0)}</td>
              <td>{banners_card?.tax_visa.toFixed(2) || 0}%</td>
              <td>{Money.centsToMaskMoney(banners_card?.visa || 0)}</td>
            </tr>
            <tr>
              <td>Visa Electron</td>
              <td>{Money.centsToMaskMoney(banners_card?.gross_visa_electron || 0)}</td>
              <td>{banners_card?.tax_visa_electron.toFixed(2) || 0}%</td>
              <td>{Money.centsToMaskMoney(banners_card?.visa_electron || 0)}</td>
            </tr>
            <tr>
              <td>MasterCard</td>
              <td>{Money.centsToMaskMoney(banners_card?.gross_master || 0)}</td>
              <td>{banners_card?.tax_master.toFixed(2) || 0}%</td>
              <td>{Money.centsToMaskMoney(banners_card?.master || 0)}</td>
            </tr>
            <tr>
              <td>Maestro</td>
              <td>{Money.centsToMaskMoney(banners_card?.gross_maestro || 0)}</td>
              <td>{banners_card?.tax_maestro.toFixed(2) || 0}%</td>
              <td>{Money.centsToMaskMoney(banners_card?.maestro || 0)}</td>
            </tr>
            <tr>
              <td>Elo</td>
              <td>{Money.centsToMaskMoney(banners_card?.gross_elo || 0)}</td>
              <td>{banners_card?.tax_elo.toFixed(2) || 0}%</td>
              <td>{Money.centsToMaskMoney(banners_card?.elo || 0)}</td>
            </tr>
            <tr>
              <td>Dinners</td>
              <td>{Money.centsToMaskMoney(banners_card?.gross_dinners || 0)}</td>
              <td>{banners_card?.tax_dinners.toFixed(2) || 0}%</td>
              <td>{Money.centsToMaskMoney(banners_card?.dinners || 0)}</td>
            </tr>
            <tr>
              <td>Amex</td>
              <td>{Money.centsToMaskMoney(banners_card?.gross_amex || 0)}</td>
              <td>{banners_card?.tax_amex.toFixed(2) || 0}%</td>
              <td>{Money.centsToMaskMoney(banners_card?.amex || 0)}</td>
            </tr>
            <tr>
              <td>PIX</td>
              <td>{Money.centsToMaskMoney(banners_card?.gross_pix || 0)}</td>
              <td>{banners_card?.tax_pix.toFixed(2) || 0}%</td>
              <td>{Money.centsToMaskMoney(banners_card?.pix || 0)}</td>
            </tr>
            <tr className='table-active fw-bold'>
              <td>TOTAL</td>
              <td>{Money.centsToMaskMoney(totalBruto)}</td>
              <td></td>
              <td>{Money.centsToMaskMoney(totalTaxasValor)}</td>
            </tr>
          </tbody>
        </table> */}
      </CardBody>
    </Card>
  );
}

export default memo(TaxaAdquirencia);