import React, { useRef } from 'react';
import Modal, { ModalBody, ModalFooter, ModalHeader, ModalTitle } from '../../bootstrap/Modal';
import { useReactToPrint } from 'react-to-print';
import EventContract from './contractEvent';
import BtnCancel from '../../MyCustom/BtnCancel';
import Button from '../../bootstrap/Button';


const ModalEventoContrato = ({ open, setIsOpen, setIsClose, estabelecimento, evento, osData }) => {

    const contractRef = useRef();

    const handlePrint = useReactToPrint({
        content: () => contractRef.current,
        removeAfterPrint: true,
        documentTitle: `ORDEM DE SERVIÇO n°000${evento?.codeNumber || 0} – REFERENTE A CONTRATO n° ${estabelecimento?.codeNumber || 0}`,
        // onAfterPrint: () => setIsOpen(false),
    });

    return (
        <Modal
            id={'modal-contrato-evento'}
            titleId={'contrato evento'}
            isOpen={open}
            setIsOpen={setIsOpen}
            isStaticBackdrop={true}
            isScrollable={false}
            isCentered={true}
            size="lg" // 'sm' || 'lg' || 'xl' 
            isAnimation={true}
        // onSubmit={formik.handleSubmit}
        >
            <ModalHeader className="m-0 p-0" setIsOpen={setIsOpen}>
                <ModalTitle></ModalTitle>
            </ModalHeader>
            <ModalBody className='overflow-auto' style={{ height: 800 }}>
                <div ref={contractRef}>
                    <EventContract estabelecimento={estabelecimento} evento={evento} osData={osData} />
                </div>
            </ModalBody>
            <ModalFooter>
                <div className='row col-md-12 justify-content-around m-3'>
                    <BtnCancel
                        className='col-md-3'
                        setIsClose={setIsClose}
                    />
                    <Button onClick={handlePrint} className='col-md-3' type='button' color='primary' icon="PictureAsPdf" rounded={1} hoverShadow="sm" shadow="sm" size="sm">
                        Imprimir ou Baixar
                    </Button>
                </div>
            </ModalFooter>
        </Modal>
    )
}

export default ModalEventoContrato;
