import { useMemo } from "react";
import useTotalRecharge from "./useTotalRecharge";
import useTotalSales from "./useTotalSales";
import useTotalEntrance from "./useTotalEntrance";
import useTotalTaxDevolution from "./useTotalTaxDevolution";
import useTotalBalanceReversal from "./useTotalBalanceReversal";
import useTotalActivationNfc from "./useTotalActivationsNFC";
import useTotalCashier from "./useTotalCashier";

const useTotalGeral = () => {
  const { RechargesCalc } = useTotalRecharge();
  const { SalesCalc } = useTotalSales();
  const {EntrancesCalc} = useTotalEntrance();
  const {TaxDevolutionCalc} = useTotalTaxDevolution();
  const {BalanceReversalCalc} = useTotalBalanceReversal();
  const {ActivationNfclCalc} = useTotalActivationNfc()
  const {CashiersBalanceReversal} = useTotalCashier();

  const TotalCalc = useMemo(() => {
    if (!RechargesCalc || !SalesCalc ) return {
      totalFaturamento: 0,
      totalMovimentacao: 0,
      totalCredit: 0,
      totalDebit: 0,
      totalPix: 0,
      totalMealTicket: 0,
      totalCash: 0,
      totalCourtesy: 0,
      totalConsummation: 0,
    };

  
    let totalCredit = (RechargesCalc.credit || 0) + (SalesCalc.credit || 0) + (EntrancesCalc.credit || 0);
    let totalDebit = (RechargesCalc.debit || 0) +( SalesCalc.debit  || 0)+ (EntrancesCalc.debit || 0);
    let totalPix = (RechargesCalc.pix || 0) + (SalesCalc.pix || 0) + (EntrancesCalc.pix || 0);
    let totalMealTicket = (RechargesCalc.mealTicket || 0) + (SalesCalc.mealTicket || 0) + (EntrancesCalc.mealTicket || 0);
    let totalCash = ((RechargesCalc.cash || 0)   + (SalesCalc.cash || 0) + (EntrancesCalc.cash || 0)) - (TaxDevolutionCalc.totalTaxDevolution + BalanceReversalCalc.totalBalanceReversal) ;
    let totalCourtesy = (RechargesCalc.courtesy || 0) + (SalesCalc.courtesy || 0) + (EntrancesCalc.courtesy || 0);



    let totalFaturamentoTicket = (SalesCalc.credit || 0) + (SalesCalc.debit || 0) + (SalesCalc.pix || 0) + (SalesCalc.mealTicket || 0) + (SalesCalc.cash || 0) + (SalesCalc.totalCourtesy || 0)
    let totalIngresso = EntrancesCalc.totalIngresso;
    let totalRecharge = (RechargesCalc.totalRecharge || 0)  - (RechargesCalc.courtesy || 0);
    let totalEarlyRecharge = ActivationNfclCalc.totalEarlyRecharge || 0;
    let totalConsummation = EntrancesCalc.totalConsumacao;
    let totalFaturamento = totalCredit + totalDebit + totalPix + totalMealTicket + totalCash;
    let totalMovimentacao =  totalFaturamentoTicket +  totalCourtesy + totalConsummation + totalIngresso + totalRecharge + totalEarlyRecharge - CashiersBalanceReversal;

  
    

    return {
      totalFaturamentoTicket,
      totalFaturamento,
      totalMovimentacao,
      totalCredit,
      totalDebit,
      totalPix,
      totalMealTicket,
      totalCash,
      totalCourtesy,
      totalConsummation,
      totalIngresso,
      totalRecharge,
      totalEarlyRecharge
    };
  }, [RechargesCalc,SalesCalc,EntrancesCalc,TaxDevolutionCalc,BalanceReversalCalc,ActivationNfclCalc,CashiersBalanceReversal]);
  return { TotalCalc };
};

export default useTotalGeral;
