import React, { memo } from 'react'
import { TABS } from '../tabs';

import Estoque from '../stock/stock';
import ReportsBySector from './reportsBySector';

const TabEstoque = ({ hidden ,SubTabs}) => {

  return (
    <div hidden={hidden} className='col-xxl'>
    
      <Estoque hidden={hidden || SubTabs !== TABS.ESTOQUE.subTabs.GERAL.id}/>

      <ReportsBySector hidden={hidden || SubTabs !== TABS.ESTOQUE.subTabs.POR_SECTOR.id}/>
      
    </div>
  )
}

export default memo(TabEstoque);
