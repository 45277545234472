import { QueryConstraint, Unsubscribe } from "firebase/firestore";
import DefectProduct from "../entities/stock/defects";
import FirestorePipe from "../utils/pipe";
import EstablishmentDB from "./establishment";

class DefectDB extends FirestorePipe {

  public static readonly colName = 'Defects'

  constructor(idEstab: string) {
    if (!idEstab)
      throw new Error('Informe o id do estabelecimento')
    super(`${EstablishmentDB.colName}/${idEstab}/${DefectDB.colName}`);
  }

  public async create(data: DefectProduct): Promise<any> {
    return this._create(data);
  }
  public async update(id: string, data: any): Promise<any> {
    return this._update(id, data);
  }
  public delete(id: string): Promise<any> {
    return this._delete(id);
  }
  public getAll(...params: QueryConstraint[]): Promise<DefectProduct[]> {
    return this._getAll<DefectProduct>(...params);
  }
  public get(id: string): Promise<DefectProduct> {
    return this._get(id);
  }
  public on(listener: (data: DefectProduct[]) => void, ...params: QueryConstraint[]): Unsubscribe {
    return this._on(listener, ...params);
  }

}

export default DefectDB;