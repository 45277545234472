import { useCallback, useMemo, useContext, useState, useEffect } from "react";
import Money from "../../../../../helpers/utils/money/money";
import Card, { CardBody, CardHeader, CardLabel, CardSubTitle, CardTitle } from "../../../../../components/bootstrap/Card";
import TableCustom from "../../../../../components/MyCustom/TableCustom";
import { cpfFormatMask } from "../../../../../utils/format/cpfFormat";
import useDarkMode from "../../../../../hooks/useDarkMode";
import { ResultsContext } from "../../../../../contexts/results";
import dateFormat from "dateformat";
import { PAYMENT_TYPE_PTBR } from "../../../../../types/payment";
import Icon from "../../../../../components/icon/Icon";
import useDicionary from "../../../../../hooks/TotaisReports/useDicionarys.js";
import SelectSector from "../../../../../components/MyCustom/SelectSector";
import { DefaultContext } from "../../../../../contexts/default";



const RechargeMovement = ({ recharge }) => {
  const { darkModeStatus } = useDarkMode();
  const { cashierDicionary,sectorsDicionary } = useDicionary();
  const { totemsRechargeDicionary } = useContext(ResultsContext)
  const calculateTotal = useCallback((moviment) => {
    const { cash, credit, debit, pix, courtesy, balanceReversal } = moviment;
    return cash + credit + debit + pix + courtesy + balanceReversal;
  }, [])

  const calculateBalanceToPay = useCallback((moviment) => {
    const { cash, cash_return, bleed } = moviment;
    return cash + cash_return - bleed;
  }, [])

  const rowsRender = useMemo(() =>
    recharge?.map(row => {
      const amountToPay = calculateBalanceToPay(row)
      return {
        ...row,
        cpfLabel: cpfFormatMask(row.cpf),
        total: calculateTotal(row),
        amountToPay,
        sector: sectorsDicionary[row.sectorId]?.name || '',
        final: row.closed ? row.paid - amountToPay
          : - amountToPay
      }
    }) || []
    , [recharge])
    



  const columns = useMemo(() => ([
    {
      label: 'Data / Hora',
      field: 'created_at',
      format: row => dateFormat(row.created_at.toDate(), 'dd/mm/yyyy HH:MM'),
      formatExport: value => dateFormat(value.toDate(), 'dd/mm/yyyy HH:MM'),
    },
    {
      label: 'Estorno',
      field: 'reversed',
      format: row => row.reversed ? <Icon size="2x" icon='Circle' color='danger' /> : <Icon size="2x" icon='Circle' color='success' />,
      formatExport: value => value ? 'SIM' : 'NÃO',
    },
    {
      label: 'ID NFC',
      field: 'idCard',
    },
    {
      label: 'Terminal',
      field: '{row}',
      format: row => row.cashierId ?
        cashierDicionary[row.cashierId]?.terminal || '' :
        totemsRechargeDicionary[row.totemId]?.terminal || '',
      formatExport: row => row.cashierId ?
        cashierDicionary[row.cashierId]?.terminal || '' :
        totemsRechargeDicionary[row.totemId]?.terminal || '',
    },
    {
      label: 'Nome do Operador',
      field: '{row}',
      format: row => row.cashierId ?
        cashierDicionary[row.cashierId]?.operator || '' :
        totemsRechargeDicionary[row.totemId]?.name || '',
      formatExport: row => row.cashierId ?
        cashierDicionary[row.cashierId]?.operator || '' :
        totemsRechargeDicionary[row.totemId]?.name || '',
    },
    {
      label: 'Setor',
      field: 'sector',
    },
    {
      label: 'Tipo de Pagamento',
      field: 'payment_type',
      format: row => PAYMENT_TYPE_PTBR[row.payment_type],
      formatExport: value => PAYMENT_TYPE_PTBR[value],
    },
    {
      label: 'Bandeira',
      field: 'cardBrand',
    },
    {
      label: 'Valor da movimentacao',
      field: 'value',
      format: row => Money.centsToMaskMoney(row.value),
      formatExport: value => Money.centsToCoin(value),
    }
  ]), [cashierDicionary, totemsRechargeDicionary])

  return (
    <Card>
      <CardHeader>
        <CardLabel icon='BackupTable' iconColor={!darkModeStatus ? 'dark' : 'light'}>
          <CardTitle>RECARGA POR MOVIMENTAÇÃO</CardTitle>
        </CardLabel>
      </CardHeader>
      <div className="col-12 ">
        <CardBody className='table-responsive p-5 align-items-center'>
          <TableCustom
            rows={rowsRender}
            columns={columns}
            keyExtractor={row => row.id}
          />
        </CardBody>
      </div>
    </Card>
  );
}

export default RechargeMovement