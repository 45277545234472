import { QueryConstraint, Unsubscribe } from "firebase/firestore";
import EventNumbers from "../entities/event/eventNumber.entity";
import FirestorePipe from "../utils/pipe";

class EventNumberDB extends FirestorePipe {

  public static readonly colName = 'EventNumbers'

  constructor() {
    super(`${EventNumberDB.colName}`);
  }

  public create(): Promise<any> {
    throw 'Method not exists'
  }
  public update(id: string, data: EventNumbers): Promise<any> {
    throw 'Method not exists'
  }
  public delete(id: string): Promise<any> {
    throw 'Method not exists'
  }
  public getAll(...params: QueryConstraint[]): Promise<EventNumbers[]> {
    return this._getAll<EventNumbers>(...params);
  }
  public get(id: string): Promise<EventNumbers> {
    return this._get(id);
  }
  public on(listener: (data: EventNumbers[]) => void, ...params: QueryConstraint[]): Unsubscribe {
    return this._on(listener, ...params);
  }

}

export default EventNumberDB;