import React, { useState, useEffect, useContext, useCallback, memo, useMemo } from 'react';
import { useFormik } from 'formik';
import Modal, { ModalBody, ModalHeader, ModalTitle } from '../../bootstrap/Modal';
import PreAlert from '../../../helpers/utils/preAlert';
import { DefaultContext } from '../../../contexts/default';
import BtnLoad from '../../MyCustom/BtnLoad';
import ListOperators from './ListOperators';
import { ROLE } from '../../../types/roles';
import OperatorsDB from '../../../database/wrappers/operatorsEvent';
import BtnCancel from '../../MyCustom/BtnCancel';
import BtnConfirm from '../../MyCustom/BtnConfirm';
import SectorDB from '../../../database/wrappers/sector';
import { where } from 'firebase/firestore';
import useFilterData from '../../../hooks/useFilterData';
import useSortableData from '../../../hooks/useSortableData';
import FormGroup from '../../bootstrap/forms/FormGroup';
import Input from '../../bootstrap/forms/Input';
import { cpfFormatMask } from '../../../utils/format/cpfFormat';

const validate = (values) => {
  const errors = {};

  return errors;
}

const ModalRegisterOperators = ({ open, setIsOpen, setIsClose, operatorselected, usersOperators }) => {

  const { onShowAlert, establishment, event } = useContext(DefaultContext)

  const [isLoading, setIsLoading] = useState(false);
  const [sectors, setsectors] = useState([]);
  const [search, setsearch] = useState('');

  const usersOperatorsRender = useMemo(() =>
    usersOperators.map(operator => ({
      ...operator,
      cpfFormat: cpfFormatMask(operator.cpf),
    }))
  ,[usersOperators])

  //usar o hook useFilterData para filtrar os operadores por nome 
  const dataFilter = useFilterData(usersOperatorsRender, search)
  const { items } = useSortableData(dataFilter);

  useEffect(() => {
    if (!open) return formik.resetForm();
    if (operatorselected) {
      const { operators, roles } = operatorselected

      formik.setValues({
        operators,
        rolesSelected: roles
      })
    }
  }, [open, operatorselected])

  useEffect(() => {
    if(!establishment) return;
    function sort(a, b) {
      if (a.name > b.name) return 1;
      if (a.name < b.name) return -1;
      return 0;
    }
    return new SectorDB(establishment.id).on(datas => {
      datas.sort(sort)
      setsectors(datas)
    }, where('active', '==', true))
  }, [establishment])

  const formik = useFormik({
    initialValues: {
      operators: [],
      rolesSelected: {},
      sectorSelected: {},
    },
    validate,
    onSubmit: values => {
      const { operators, rolesSelected, sectorSelected } = values;

      const datas = operators.map(item => ({
        id: item,
        role: rolesSelected[item] ? rolesSelected[item] : ROLE.TICKET,
        sectorId: sectorSelected[item],
        active: true
      }))

      const findError = datas.find(item => !item.sectorId)
      if(findError)
        return onShowAlert(PreAlert.warning('Operador sem setor informado'))

      const onSuccess = () => {
        onShowAlert(PreAlert.success('Registro cadastrado com sucesso'))
        setIsClose();
      }
      const onError = () => {
        onShowAlert(PreAlert.error('Falhou ao cadastrar o registro'))
      }

      setIsLoading(true)
      new OperatorsDB(establishment.id, event.id)
        .writeBatch(datas)
        .then(onSuccess)
        .catch(onError)
        .finally(() => setIsLoading(false))
    },
  })

  const onChangeRoles = useCallback((id, role) => {
    formik.values.rolesSelected[id] = role
    formik.setValues({
      ...formik.values,
      rolesSelected: formik.values.rolesSelected
    })
  }, [formik.values])

  const onChangeSector = useCallback((id, sector) => {
    formik.values.sectorSelected[id] = sector
    formik.setValues({
      ...formik.values,
      sectorSelected: formik.values.sectorSelected
    })
  }, [formik.values])

  return (
    <Modal
      id={'modal-register-operators'}
      titleId={'Cadastro de operadores'}
      isOpen={open}
      setIsOpen={setIsOpen}
      isStaticBackdrop={true}
      isScrollable={false}
      isCentered={true}
      size="lg" // 'sm' || 'lg' || 'xl' 
      isAnimation={true}
      onSubmit={formik.handleSubmit}
    >
      <ModalHeader setIsOpen={setIsOpen}>
        <ModalTitle id="register-operators">Operadores</ModalTitle>
      </ModalHeader>
      <ModalBody>
        <form noValidate onSubmit={formik.handleSubmit}>
          <div className='d-flex flex-row align-items-center justify-content-between mb-2'>
            <p className='m-0'>Operadores que irão atuar no evento e suas funções</p>
            <FormGroup id="name" label="" className='col-md-5'>
              <Input
                onChange={e => setsearch(e.target.value)}
                value={search}
                placeholder='Buscar'
              />
            </FormGroup>
          </div>

          {/* Inputs */}
          <div className="row g-4">
            <ListOperators
              onChange={formik.handleChange}
              onChangeRoles={onChangeRoles}
              sectors={sectors}
              operators={search ? items : usersOperatorsRender}
              operatorsSelected={formik.values.operators}
              sectorSelected={formik.values.sectorSelected}
              rolesSelected={formik.values.rolesSelected}
              onChangeSector={onChangeSector}
            />
          </div>

          {/* Buttons */}
          <div className="row pt-4">
            <div className="col-md-4 offset-md-8">
              <div className='d-flex justify-content-evenly'>
                <BtnCancel setIsClose={setIsClose} />
                {isLoading ? (
                  <BtnLoad />
                ) : (
                  <BtnConfirm isDisable={!formik.isValid} />
                )}
              </div>
            </div>
          </div>
        </form>
      </ModalBody>
    </Modal>
  )
}

export default memo(ModalRegisterOperators);