import React, { useContext, useEffect, useMemo, useState } from 'react';
import Modal, { ModalBody, ModalHeader, ModalTitle } from '../../bootstrap/Modal';
// Database
import { DefaultContext } from '../../../contexts/default';
import SelectEvent from '../../MyCustom/SelectEvent';
import Button from '../../bootstrap/Button';
import BtnLoad from '../../MyCustom/BtnLoad';
import ProductDB from '../../../database/wrappers/product';
import { where, serverTimestamp } from 'firebase/firestore';
import { EAT_OR_TAKE } from '../../../types/payment';
import PreAlert from '../../../helpers/utils/preAlert';
import SettingDB from '../../../database/wrappers/settings';
import TableCustom from '../../MyCustom/TableCustom';
import useDarkMode from '../../../hooks/useDarkMode';
import LogoUgetBlack from '../../../assets/img/logo_uget_black.png'
import LogoUget from '../../../assets/img/logo_uget.png'
import PrintableDataDB from '../../../database/wrappers/eventData/printableData';

const ModalTestPrinters = ({ open, setIsOpen, printersSelected }) => {

  const { darkModeStatus } = useDarkMode();

  const { estabSelected, eventSelected, onShowAlert } = useContext(DefaultContext)

  const [settings, setsettings] = useState(null)
  const [products, setproducts] = useState([])
  const [loading, setloading] = useState(false)

  useEffect(() => {
    if (estabSelected) {
      function sort(a, b) {
        if (a.name > b.name) return 1;
        if (a.name < b.name) return -1;
        return 0;
      }
      async function getProducts() {
        const products = await new ProductDB(estabSelected).getAll(where('active', '==', true));
        products.sort(sort)
        setproducts(products);
      }

      getProducts();
    }
  }, [estabSelected])

  useEffect(() => {
    if(eventSelected) {
      new SettingDB(estabSelected, eventSelected)
        .get()
        .then(setsettings)
        .catch((error) => {
          console.error(error);
          setsettings(null)
        })
    } else {
      setsettings(null)
    }
  }, [eventSelected])

  const productsInPrinter = useMemo(() => {
    if(!printersSelected) return [];
    const productsPrinter = []
    printersSelected.products.forEach(pId => {
      const product = products.find(product => pId === product.id)
      if(product) {
        productsPrinter.push(product)
      }
    })
    return productsPrinter
  }, [printersSelected, products])

  const onTestPrinter = async () => {
    if(!estabSelected || !eventSelected) return;
    setloading(true)
    try {
      if(productsInPrinter.length === 0) {
        return onShowAlert(PreAlert.error('Sem produtos'))
      }

      const dataPrint = {
        created_at: serverTimestamp(),
        products: productsInPrinter.map(product => ({
          id: product.id,
          name: product.name,
          qtd: 1,
        })),
        isPrinted: false,
        test: true,
        dataSale: {
          sectorName: 'TESTE',
          userName: 'TESTE',
          earOrTake: EAT_OR_TAKE.UNINFORMED,
        },
      }

      await new PrintableDataDB(estabSelected, eventSelected, printersSelected.id).create(dataPrint)
      onShowAlert(PreAlert.success('Impressão enviada com sucesso'))
    } catch (error) {
      console.error(error);
      onShowAlert(PreAlert.error('Falhou ao enviar a impressão'))
    } finally {
      setloading(false)
    }
  }

  const columns = useMemo(() => ([
    {
      label: 'Imagem',
      field: 'image_url',
      format: row => (
        <img 
          src={row.image_url || (darkModeStatus ? LogoUget : LogoUgetBlack)} 
          alt="Imagem" 
          width={row.image_url ? 20 : 54}
          height={row.image_url ? 20 : 10} 
        />
      )
    },
    {
      label: 'Nome',
      field: 'name',
    },
  ]), [])

  const error = (settings && !settings.usePrinter) ? 'Impressoras desativadas neste evento' : null

  return (
    <Modal
      id={'modal-test-printers'}
      titleId={'Testar Impressora'}
      isOpen={open}
      setIsOpen={setIsOpen}
      isStaticBackdrop={true}
      isScrollable={false}
      isCentered={true}
      size="lg" // 'sm' || 'lg' || 'xl' 
      isAnimation={true}
    >
      <ModalHeader setIsOpen={setIsOpen}>
        <ModalTitle id="test-printer">Testar Impressora</ModalTitle>
      </ModalHeader>
      <ModalBody>
        <div className='row'>
          <SelectEvent 
            class='col-md-6' 
            invalidFeedback={error}
            validFeedback='Evento OK!'
            isValid={false}
            isTouched={true}
          />

          <div className='col-md-4 d-flex align-items-center'>
            {loading ?
            <BtnLoad />
            :
            <Button
              color="primary"
              icon="Print"
              shadow="sm"
              hoverShadow="sm"
              size="sm"
              onClick={onTestPrinter}
              isDisable={error}
            >
              Imprimir
            </Button>}
          </div>
        </div>
        <TableCustom
          rows={productsInPrinter}
          columns={columns}
          keyExtractor={row => row.id}
        />
      </ModalBody>
    </Modal>
  )
}

export default ModalTestPrinters;