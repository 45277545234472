import React, { useState, useEffect, useContext, memo, useMemo } from 'react';
import { useFormik } from 'formik';
import Modal, { ModalBody, ModalHeader, ModalTitle } from '../../bootstrap/Modal';
import PreAlert from '../../../helpers/utils/preAlert';
import { DefaultContext } from '../../../contexts/default';
import BtnLoad from '../../MyCustom/BtnLoad';
import ListSectors from './ListSectors';
import BtnCancel from '../../MyCustom/BtnCancel';
import BtnConfirm from '../../MyCustom/BtnConfirm';
import SectorDB from '../../../database/wrappers/sector';
import { where } from 'firebase/firestore';
import FormGroup from '../../bootstrap/forms/FormGroup';
import CoordinatorEventDB from '../../../database/wrappers/coordinatorEvent';
import Select from '../../bootstrap/forms/Select';
import Input from '../../bootstrap/forms/Input';
import Checks from '../../bootstrap/forms/Checks';

const validate = (values) => {
  const errors = {};

  if(!values.userId) {
    errors.userId = 'Informe o coordenador'
  }

  if(!values.cardNumber) {
    errors.cardNumber = 'Informe o número do cartão NFC'
  }

  if(values.sectors.length === 0) {
    errors.sectors = 'Informe o coordenador'
  }

  return errors;
}

const ModalRegisterCoordinator = ({ open, setIsOpen, setIsClose, userSelected, usersCoor }) => {

  const { onShowAlert, establishment, event } = useContext(DefaultContext)
  const [isLoading, setIsLoading] = useState(false);
  const [sectors, setsectors] = useState([]);
  
  useEffect(() => {
    if (!open) return formik.resetForm();
    if (userSelected) {
      const { id, cardNumber, sectors, active } = userSelected

      formik.setValues({
        userId: id,
        cardNumber,
        sectors,
        active,
      })
    }
  }, [open, userSelected])

  useEffect(() => {
    function sort(a, b) {
      if (a.name > b.name) return 1;
      if (a.name < b.name) return -1;
      return 0;
    }
    return new SectorDB(establishment.id).on(datas => {
      datas.sort(sort)
      setsectors(datas)
    }, where('active', '==', true))
  }, [establishment])

  const formik = useFormik({
    initialValues: {
      userId: '',
      cardNumber: '',
      sectors: [],
      active: true
    },
    validate,
    onSubmit: values => {
      const { userId, cardNumber, active, sectors } = values;

      const data = {
        cardNumber: cardNumber.toUpperCase(),
        sectors,
        active,
      }

      const onSuccess = () => {
        onShowAlert(PreAlert.success('Registro cadastrado com sucesso'))
        setIsClose();
      }
      const onError = () => {
        onShowAlert(PreAlert.error('Falhou ao cadastrar o registro'))
      }

      setIsLoading(true)
      new CoordinatorEventDB(establishment.id, event.id)
        .define(userId, data)
        .then(onSuccess)
        .catch(onError)
        .finally(() => setIsLoading(false))
    },
  })

  const options = useMemo(() => usersCoor.map(item => ({ value: item.id, text: item.name })), [usersCoor])

  return (
    <Modal
      id={'modal-register-coordinators'}
      titleId={'Cadastro de operadores'}
      isOpen={open}
      setIsOpen={setIsOpen}
      isStaticBackdrop={true}
      isScrollable={false}
      isCentered={true}
      size="lg" // 'sm' || 'lg' || 'xl' 
      isAnimation={true}
      onSubmit={formik.handleSubmit}
    >
      <ModalHeader setIsOpen={setIsOpen}>
        <ModalTitle id="register-coordinators">Coordenador no Evento</ModalTitle>
      </ModalHeader>
      <ModalBody>
        <form className='row' noValidate onSubmit={formik.handleSubmit}>
          <FormGroup id="userId" label="Coordenador" className='col-md-6'>
            <Select
              placeholder='Selecione o Coordenador'
              value={formik.values.userId}
              list={options}
              onChange={formik.handleChange}
              isValid={formik.isValid}
              isTouched={formik.touched.userId}
              invalidFeedback={formik.errors.userId}
              validFeedback='Assim está bom!'
              disabled={userSelected}
            />
          </FormGroup>

          <FormGroup id="cardNumber" label="Número do Cartão" className='col-md-4'>
            <Input
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.cardNumber}
              isValid={formik.isValid}
              isTouched={formik.touched.cardNumber}
              invalidFeedback={formik.errors.cardNumber}
              validFeedback='Assim está bom!'
              placeholder="Ex: AABBDDCC"
            />
          </FormGroup>

          {/* Status */}
          <FormGroup
            id='active'
            label='Status'
            className='col-md-2'
          >
            <Checks
              type="switch"
              id="active"
              label={formik.values.active ? 'Ativo' : 'Inativo'}
              name="active"
              onChange={formik.handleChange}
              checked={formik.values.active}
            />
          </FormGroup>

          <ListSectors
            hidden={false}
            sectors={sectors}
            sectorSelected={formik.values.sectors}
            onChange={formik.handleChange}
          />

          {/* Buttons */}
          <div className="row pt-4">
            <div className="col-md-4 offset-md-8">
              <div className='d-flex justify-content-evenly'>
                <BtnCancel setIsClose={setIsClose} />
                {isLoading ? (
                  <BtnLoad />
                ) : (
                  <BtnConfirm isDisable={!formik.isValid} />
                )}
              </div>
            </div>
          </div>
        </form>
      </ModalBody>
    </Modal>
  )
}

export default memo(ModalRegisterCoordinator);