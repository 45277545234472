import { useFormik } from 'formik';
import React, { useMemo, memo, useCallback, useContext, useEffect, useState } from 'react';
import { DefaultContext } from '../../../contexts/default';
import CustomersGlobalDB from '../../../database/databaseGlobal/wrappers/customers';
import validaCpf from '../../../helpers/utils/functions/validateCpf';
import masks, { unmask } from '../../../helpers/utils/masks';
import PreAlert from '../../../helpers/utils/preAlert';
import { cpfInputMask } from '../../../utils/format/cpfFormat';
import BtnCancel from '../../MyCustom/BtnCancel';
import BtnConfirm from '../../MyCustom/BtnConfirm';
import BtnLoad from '../../MyCustom/BtnLoad';
import Modal, { ModalBody, ModalHeader, ModalTitle } from '../../bootstrap/Modal';
import Spinner from '../../bootstrap/Spinner';
import FormGroup from '../../bootstrap/forms/FormGroup';
import Input from '../../bootstrap/forms/Input';
import ListCourtesyDB from '../../../database/wrappers/listCourtesy';
import { Options } from '../../bootstrap/Option';
import Select from '../../bootstrap/forms/Select'
import EntranceDB from '../../../database/wrappers/entrance';
import { orderBy, where } from 'firebase/firestore';
import PromotersLinkDB from '../../../database/wrappers/promotersLink';
const ModalRegisterUniqueCourtesy = ({ open, setIsOpen, setIsClose }) => {

  const { onShowAlert, event, establishment, estabSelected, eventSelected } = useContext(DefaultContext)
  const [noSearch, setnoSearch] = useState(true);
  const [loadingGetClient, setloadingGetClient] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [promoters, setpromoters] = useState([]);


  useEffect(() => {
    if (!estabSelected || !eventSelected) return;
    const onSubscriber = new PromotersLinkDB(estabSelected, eventSelected).on(setpromoters, orderBy('name', 'desc'))
    return onSubscriber;
  }, [estabSelected, eventSelected])


  const options = useMemo(() => [
    { value: '', text: 'Selecione um promoter' },
    ...promoters.map(item => ({ value: item.id, text: item.name }))
  ], [promoters]);

  useEffect(() => {
    if (!open) {
      setnoSearch(true)
      formik.resetForm();
    }
  }, [open])

  const validate = useCallback((values) => {
    const errors = {};

    if (!values.phone) {
      errors.phone = 'Este campo é necessário';
    }

    if (!values.name) {
      errors.name = 'Este campo é necessário';
    } else if (values.name.length < 3) {
      errors.name = 'O nome precisa ter 3 caracteres ou mais';
    }

    if (!values.cpf) {
      errors.cpf = 'Este campo é necessário';
    } else if (values.cpf.length < 14) {
      errors.cpf = 'Informe o cpf completo';
    } else if (!validaCpf(values.cpf.replace(/\D/g, ""))) {
      errors.cpf = 'CPF inválido';
    }


    return errors;
  }, [])

  const formik = useFormik({
    initialValues: {
      name: '',
      cpf: '',
      phone: '',
      promoterId: '',
      value: '',
      consummation: '',
    },
    validate,
    onSubmit: async values => {
      setIsLoading(true);
      const { name, cpf, phone, promoterId } = values;

      const data = {
        name,
        cpf: cpf.replace(/\D/g, ""),
        phone: phone.replace(/\D/g, ""),
        used: false,
        event_ingresse: event.name,
        value: 0,
        consummation: 0,
        approved: true,
        promoterId,
        link: true,
      }



      const onSuccess = () => {
        onShowAlert(PreAlert.success('Cortesia cadastrada com sucesso'))
        setIsClose();
      }
      const onError = (error) => {
        onShowAlert(PreAlert.error('Falhou ao cadastrar a cortesia'))
      }

      try {
        const user = await new ListCourtesyDB(establishment.id, event.id).getByCPF(cpf.replace(/\D/g, ""));
        if (user) {
          onError();
          console.log(user);
          setIsLoading(false);
          return;
        }
        await new ListCourtesyDB(establishment.id, event.id)
          .create(data)
          .then(onSuccess)
          .catch((e) => {
            console.log(e);
            onError();
          })
          .finally(() => setIsLoading(false));
      } catch (error) {
        console.error(error);
        onError();
        setIsLoading(false);
      }

    },
  })

  useEffect(() => {
    const unmaskCpf = formik.values.cpf.replace(/\D/g, "")
    const fetchData = async () => {
      if (formik.values.cpf.length === 14) {
        setloadingGetClient(true);
        const client = await new CustomersGlobalDB().getByCPF(unmaskCpf).finally(() => {
          setnoSearch(false)
          setloadingGetClient(false);
        });

        if (client) {
          formik.setFieldValue('name', client.name)
          formik.setFieldValue('phone', masks.phone(String(client.phone)))
        } else {
          formik.setFieldValue('name', '')
          formik.setFieldValue('phone', '')
        }
      }

    };
    fetchData();
  }, [formik.values.cpf]);


  const onChangeCpf = useCallback((e) => {
    formik.setValues({
      ...formik.values,
      cpf: cpfInputMask(e.target.value)
    })
  }, [formik.values])

  return (
    <Modal
      id={'modal-register-unique-operator'}
      titleId={'Cadastro de Operador'}
      isOpen={open}
      setIsOpen={setIsOpen}
      isStaticBackdrop={true}
      isScrollable={false}
      isCentered={true}
      size="lg" // 'sm' || 'lg' || 'xl' 
      isAnimation={true}
      onSubmit={formik.handleSubmit}
    >
      <ModalHeader setIsOpen={setIsOpen}>
        <ModalTitle id="register-user">Cadastro de usuário VIP por evento</ModalTitle>
      </ModalHeader>
      <ModalBody>
        <form noValidate onSubmit={formik.handleSubmit}>
          {/* Inputs */}
          <div className="row g-4">
            <div className="row col-md-12">
              {/* cpf */}
              <FormGroup id='cpf' label='CPF' className='col-md-4'>
                <Input
                  onChange={onChangeCpf}
                  onBlur={formik.handleBlur}
                  value={formik.values.cpf}
                  isValid={formik.isValid}
                  isTouched={formik.touched.cpf}
                  invalidFeedback={formik.errors.cpf}
                  disabled={loadingGetClient}
                  // validFeedback='Assim está bom!'
                  placeholder='CPF do Usuário'
                />
              </FormGroup>

              {/* Nome */}
              {!noSearch &&
                <>
                  <FormGroup id="name" label="Nome" className='col-md-4'>
                    <Input
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.name}
                      isValid={formik.isValid}
                      isTouched={formik.touched.name}
                      invalidFeedback={formik.errors.name}
                      validFeedback='Assim está bom!'
                      placeholder='Ex: João da Silva'
                    />
                  </FormGroup>

                  <FormGroup id='phone' label='Telefone' className='col-md-4'>
                    <Input
                      type="phone"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={masks.phone(formik.values.phone)}
                      isValid={formik.isValid}
                      isTouched={formik.touched.phone}
                      invalidFeedback={formik.errors.phone}
                      validFeedback='Assim está bom!'
                      placeholder='Telefone do Usuário'
                    />
                  </FormGroup>
                </>


              }

              {loadingGetClient &&
                <div className='d-flex align-items-end col-md-8 p-2'>
                  <Spinner className='ms-1' />
                </div>}
            </div>

            {!noSearch &&
              <>
                <FormGroup id='promoterId' label='Promoter' className='col-md-4'>
                  <Select
                    placeholder='Selecione um promoter'
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange}
                    isValid={formik.isValid}
                    isTouched={formik.touched.promoterId}
                    value={formik.values.promoterId}
                    invalidFeedback={formik.errors.promoterId}

                  >
                    <Options list={options}
                    />
                  </Select>
                </FormGroup>


              </>
            }
          </div>

          {/* Buttons */}
          <div className="row pt-4">
            <div className="col-md-4 offset-md-8">
              <div className='d-flex justify-content-evenly'>
                <BtnCancel setIsClose={setIsClose} />
                {isLoading ? (
                  <BtnLoad />
                ) : (
                  <BtnConfirm isDisable={!formik.isValid} />
                )}
              </div>
            </div>
          </div>
        </form>
      </ModalBody>
    </Modal>
  )
}

export default memo(ModalRegisterUniqueCourtesy);