import { useCallback, useContext, useMemo, useState, useEffect } from "react";
import Money from "../../../../../helpers/utils/money/money";
import Card, { CardBody, CardHeader, CardLabel, CardSubTitle, CardTitle } from "../../../../../components/bootstrap/Card";
import TableCustom from "../../../../../components/MyCustom/TableCustom";
import { cpfFormatMask } from "../../../../../utils/format/cpfFormat";
import useDarkMode from "../../../../../hooks/useDarkMode";
import dateFormat from "dateformat";
import Icon from "../../../../../components/icon/Icon";
import useDicionary from "../../../../../hooks/TotaisReports/useDicionarys.js";
import { PAYMENT_TYPE, PAYMENT_TYPE_PTBR } from "../../../../../types/payment";
import SelectSector from "../../../../../components/MyCustom/SelectSector";
import { DefaultContext } from "../../../../../contexts/default";


const  MovementReports = ({ moviments }) => {
  const { darkModeStatus } = useDarkMode();
  const { cashierDicionary, sectorsDicionary } = useDicionary();
  const {sectorSelected} = useContext(DefaultContext);
  const calculateTotal = useCallback((moviment) => {
    const { cash, credit, debit, pix, courtesy, balanceReversal } = moviment;
    return cash + credit + debit + pix + courtesy + balanceReversal;
  }, [])

  const calculateBalanceToPay = useCallback((moviment) => {
    const { cash, cash_return, bleed } = moviment;
    return cash + cash_return - bleed;
  }, [])

  const rowsRender = useMemo(() =>
    moviments?.map(row => {
      const amountToPay = calculateBalanceToPay(row)
      const qtdProducts = row.products.reduce((acc, product) => {
        return acc + product.qtd;
      }, 0);
      return {
        ...row,
        operator: cashierDicionary[row.cashierId]?.operator || '',
        cpf: cpfFormatMask(cashierDicionary[row.cashierId]?.cpf || 0),
        terminal: cashierDicionary[row.cashierId]?.terminal || '',
        sector: sectorsDicionary[row.sectorId]?.name || '',
        total: calculateTotal(row),
        qtdProducts,
        amountToPay,
        final: row.closed ? row.paid - amountToPay
          : - amountToPay
      }
    }) || []
    , [moviments, cashierDicionary, sectorsDicionary])


  const columns = useMemo(() => ([
    {
      label: 'DataHora',
      field: 'created_at',
      format: row => dateFormat(row.created_at.toDate(), 'dd/mm/yyyy HH:MM'),
      formatExport: value => dateFormat(value.toDate(), 'dd/mm/yyyy HH:MM'),
    },
    {
      label: 'Estorno',
      field: 'reversed',
      format: row => row.reversed ? <Icon size="2x" icon='Circle' color='danger' /> : <Icon size="2x" icon='Circle' color='success' />,
      formatExport: value => value ? 'SIM' : 'NÃO',
    },
    {
      label: 'Operador',
      field: 'operator',
    },
    {
      label: 'CPF',
      field: 'cpf',
    },
    {
      label: 'Terminal',
      field: 'terminal',

    },
    {
      label: 'Setor',
      field: 'sector',
    },
    {
      label: 'Qnt.Produtos',
      field: 'qtdProducts',
    },
    {
      label: 'Tipo de pagamento',
      field: 'payment_type',
      format: row => PAYMENT_TYPE_PTBR[row.payment_type]
    },
    {
      label: 'Valor',
      field: 'value',
      format: row => Money.centsToMaskMoney(row.value),
      formatExport: value => Money.centsToCoin(value),
    },
    {
      label: 'Valor pago',
      field: '{row}',
      format: row => {
        if (row.reversed) {
          return Money.centsToMaskMoney(0)
        }
        let totalSplitPayment = 0;
        let totalReversed = 0;
        if (row.payment_type === PAYMENT_TYPE.DIVIDED) {
          row.splitPayment.forEach(item => {
            totalSplitPayment += item.value;
            if (item.reversed) totalReversed += item.value;
          })
          return Money.centsToMaskMoney(totalSplitPayment - totalReversed);
        } else {
          return Money.centsToMaskMoney(row.value)
        }

      },
      formatExport: row => {
        let totalSplitPayment = 0;
        let totalReversed = 0;
        if (row.payment_type === PAYMENT_TYPE.DIVIDED) {
          row.splitPayment.forEach(item => {
            totalSplitPayment += item.value;
            if (item.reversed) totalReversed += item.value;
          })
          return Money.centsToCoin(totalSplitPayment - totalReversed);
        } else {
          return Money.centsToCoin(row.value);
        }
      }
    },

  ]), [])

  return (
    <Card >
      <CardHeader>
        <CardLabel icon='BackupTable' iconColor={!darkModeStatus ? 'dark' : 'light'}>
          <CardTitle>CONSUMO</CardTitle>
        </CardLabel>
      </CardHeader>
      <div className="col-12 ">
        <CardBody className='table-responsive p-5 align-items-center'>
          <TableCustom
            rows={rowsRender}
            columns={columns}
            keyExtractor={row => row.id}
          />
        </CardBody>
      </div>
    </Card>
  );
}

export default MovementReports