import { QueryConstraint, Unsubscribe } from "firebase/firestore";
import ClosureResourcesEvent from "../entities/event/closureEvent";
import FirestorePipe from "../utils/pipe";
import EstablishmentDB from "./establishment";

class ClosureResourcesEventDB extends FirestorePipe {

  public static readonly colName = 'ClosureResourcesEvent'

  constructor(idEstab: string) {
    if (!idEstab)
      throw new Error('Informe o id do estabelecimento')
    super(`${EstablishmentDB.colName}/${idEstab}/${ClosureResourcesEventDB.colName}`);
  }

  public define(id: string, data: ClosureResourcesEvent): Promise<any> {
    return this._define(id, data)
  }
  public create(data: ClosureResourcesEvent): Promise<any> {
    return this._create(data);
  }
  public update(id: string, data: ClosureResourcesEvent): Promise<any> {
    return this._update(id, data);
  }
  public delete(id: string): Promise<any> {
    return this._delete(id);
  }
  public getAll(...params: QueryConstraint[]): Promise<ClosureResourcesEvent[]> {
    return this._getAll<ClosureResourcesEvent>(...params);
  }
  public get(id: string): Promise<ClosureResourcesEvent> {
    return this._get(id);
  }
  public on(listener: (data: ClosureResourcesEvent[]) => void, ...params: QueryConstraint[]): Unsubscribe {
    return this._on(listener, ...params);
  }

}

export default ClosureResourcesEventDB;