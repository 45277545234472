import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import PageWrapper from '../../../../layout/PageWrapper/PageWrapper';
import SubHeader, { SubHeaderLeft, SubHeaderRight } from '../../../../layout/SubHeader/SubHeader';
import Page from '../../../../layout/Page/Page';
import Breadcrumb from '../../../../components/bootstrap/Breadcrumb';
import Button from '../../../../components/bootstrap/Button';
import Card, { CardBody, CardHeader, CardLabel, CardTitle } from '../../../../components/bootstrap/Card';
import { demoPages } from '../../../../menu';
import ModalRegisterEstablishments from '../../../../components/modals/Establishments/ModalRegister';
import TableCustom from '../../../../components/MyCustom/TableCustom';
// Database
import EstablishmentDB from '../../../../database/wrappers/establishment';
import { DefaultContext } from '../../../../contexts/default';
import { orderBy } from 'firebase/firestore';
import useDarkMode from '../../../../hooks/useDarkMode';
import axios from 'axios';

const Establishment = () => {
  const { darkModeStatus } = useDarkMode();

  const [openModalRegister, setOpenModalRegister] = useState(false);
  const [establishments, setEstablishments] = useState([])
  const [establishmentSelected, setEstablishmentSelected] = useState({})

  const [responseArray, setResponseArray] = useState([]);

  useEffect(() => {
    const onSubscribe = new EstablishmentDB().on(setEstablishments, orderBy('name', 'asc'))
    return onSubscribe;
  }, [])

  const handleOpenModalRegister = useCallback(() => {
    setEstablishmentSelected(null)
    setOpenModalRegister(true);
  }, [])

  const handleCloseModalRegister = useCallback(() => {
    setOpenModalRegister(false);
  }, [])

  const handleOpenModalEdit = useCallback((item) => {
    setEstablishmentSelected(item);
    setOpenModalRegister(true)
  }, [])

  const handleOpenModalDelete = useCallback((id) => {

  }, [])




  const columns = useMemo(() => ([
    {
      label: 'Imagem',
      field: 'image_url',
      format: row => (
        <img src={row.image_url} alt="Imagem" width={54} height={54} />
      ),
      noExport: true,
    },
    {
      label: 'Nome',
      field: 'name',
    },
    {
      label: 'Cod.',
      field: 'codeNumber',
    },
    {
      label: 'CNPJ',
      field: 'cnpj',
    },
    {
      label: 'Telefone',
      field: 'phone',
    },
    {
      label: 'E-mail',
      field: 'email',
    },
    {
      label: 'Código do Estab.',
      field: 'code_auth',
    },
    {
      label: 'Status',
      field: 'active',
      format: row => (
        <Button
          isLink
          color={row.active ? 'success' : 'danger'}
          icon='Circle'
          className='text-nowrap'>
          {row.active ? 'Ativo' : 'Inativo'}
        </Button>
      )
    },
    {
      label: 'Ações',
      field: 'actions',
      format: row => (
        <div className='w-75 d-flex justify-content-between'>
          <Button
            color={darkModeStatus ? 'light' : 'dark'}
            icon="edit"
            shadow="sm"
            hoverShadow="sm"
            size="sm"
            onClick={() => handleOpenModalEdit(row)}
          />
          {/* <Button
            color="danger"
            icon="Trash"
            shadow="sm"
            hoverShadow="sm"
            size="sm"
            onClick={() => handleOpenModalDelete(row.id)}
          /> */}
        </div>
      ),
      noExport: true
    },
  ]), [darkModeStatus, handleOpenModalEdit, handleOpenModalDelete])

  return (
    <>
      <PageWrapper title={demoPages.pageLayout.subMenu.establishment.text}>
        <SubHeader>
          <SubHeaderLeft>
            <Breadcrumb
              list={[
                { title: 'Cadastros', to: '/registers/establishment' },
                {
                  title: 'Estabelecimentos',
                  to: '/registers/establishment',
                },
              ]}
            />
          </SubHeaderLeft>
          <SubHeaderRight>
            <Button
              color='primary'
              icon='plus'
              shadow="sm"
              hoverShadow="sm"
              onClick={handleOpenModalRegister}>
              Cadastrar
            </Button>
          </SubHeaderRight>
        </SubHeader>
        <Page container='fluid'>
          <Card>
            <CardHeader>
              <CardLabel icon='House' iconColor={darkModeStatus ? 'light' : 'dark'}>
                <CardTitle>Estabelecimentos</CardTitle>
              </CardLabel>
            </CardHeader>
            <CardBody className='table-responsive'>
              <div>
                {responseArray.map((item, index) => (
                  <div key={index}>{item.name}</div>
                ))}
              </div>
              <TableCustom
                titlePdf='Estabelecimentos'
                icon="Store"
                fontSize={8}
                columns={columns}
                rows={establishments}
                fileName="Estabelecimentos"
              />
            </CardBody>
          </Card>

        </Page>
      </PageWrapper>

      <ModalRegisterEstablishments
        open={openModalRegister}
        setIsOpen={setOpenModalRegister}
        setIsClose={handleCloseModalRegister}
        establishment={establishmentSelected}
      />
    </>
  );
};

export default Establishment;
