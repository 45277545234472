import { QueryConstraint, Unsubscribe } from "firebase/firestore";
import PrinterKeys from "../entities/printer/printerKey.entity";
import FireTotenPipe from "../utils/pipe";

class PrinterKeysDB extends FireTotenPipe {

  public static readonly colName = 'PrinterKeys'

  constructor() {
    super(`${PrinterKeysDB.colName}`);
  }

  public create(data: PrinterKeys): Promise<any> {
    throw new Error('Method not implemented')
  }
  public update(id: string, data: PrinterKeys): Promise<any> {
    throw new Error('Method not implemented')
  }
  public delete(id: string): Promise<any> {
    throw new Error('Method not implemented')
  }
  public getAll(...params: QueryConstraint[]): Promise<PrinterKeys[]> {
    return this._getAll<PrinterKeys>(...params);
  }
  public get(id: string): Promise<PrinterKeys> {
    return this._get(id);
  }
  public on(listener: (data: PrinterKeys[]) => void, ...params: QueryConstraint[]): Unsubscribe {
    return this._on(listener, ...params);
  }
  public onDicionary(listener: (data: any) => void, ...params: QueryConstraint[]): Unsubscribe {
    return this._onDicionary(listener, ...params);
  }

}

export default PrinterKeysDB;