import React, { useMemo } from "react";
import Money from "../../../helpers/utils/money/money";

const FirstPage = ({ eventSelected, establishment, eventGeral, closureResourcesEvent, equipaments }) => {

  const rowsMateriais = useMemo(() => {
    if(!equipaments) return []
    return equipaments.filter(row => 
      ['pos', 'totems', 'totems_recharges', 'cards',].includes(row.key)
    );
  },[equipaments])

  if(!establishment || !eventGeral || !closureResourcesEvent) return null;

  return (
    <div className='d-flex flex-column col-xxl-12 justify-content-around mt-4'>
      <p style={{ textAlign: 'justify' }}>Objeto do presente termo é o instrumento de vistoria dos equipamentos &
        máquinas cedidas em comodato, e o de acordo na prestação de valores arrecadado
        e serviços prestados referente ao contrato de número: <strong><mark>{establishment?.codeNumber}</mark></strong>, 
        referente a licença de software, prestação de serviços de intermediação e comodado de
        ordem de serviço de número: <strong><mark>{eventSelected?.codeNumber}</mark></strong>, celebrado entre a uGet e o
        CLIENTE <strong><mark>{establishment?.name}</mark></strong> , inscrito no CNPJ/CPF sob nº:
        <strong><mark>{establishment?.cnpj}</mark></strong>,
        localizado na <strong><mark>{establishment?.address?.street}, {establishment?.address?.number} - {establishment?.address?.neighborhood}</mark></strong>,
        na cidade de <strong><mark>{establishment?.address?.city}</mark></strong>, no estado de <strong><mark>{establishment?.address?.state}</mark></strong>,
        CEP <strong><mark>{establishment?.address?.zip_code}</mark></strong>
      </p>

      <p style={{ textAlign: 'justify' }}>
        Qualquer discordância ou questionamentos acerca do presente termo deverá ser comunicada ao Comodante,
        por escrito, dentro do prazo de  <strong><mark>03 (três)</mark></strong> dias a contar da data da assinatura deste.
      </p>

      <p style={{ textAlign: 'justify' }}>
        Vistoriados os TERMINAIS SMART POS entregues em comodato a quantidade de: <strong><mark>{eventGeral?.resources.pos}</mark></strong>.
        Quantidade recebida de: <strong><mark>{closureResourcesEvent?.returned_resources.pos}</mark></strong> em perfeito estado de conservação e funcionamento, com todos
        os seus pertences, utensílios e acessórios em perfeito estado.
      </p>
      <p style={{ textAlign: 'justify' }}>
        Vistoriados os TOTENS TICKET entregues em comodato a quantidade de: <strong><mark>{eventGeral?.resources.totems}</mark></strong>
        Quantidade recebida de: <strong><mark>{closureResourcesEvent?.returned_resources.totems}</mark></strong> em perfeito estado de conservação e funcionamento, com todos
        os seus pertences, utensílios e acessórios em perfeito estado.
      </p>
      <p style={{ textAlign: 'justify' }}>
        Vistoriados os TOTENS RECARGA entregues em comodato a quantidade de: <strong><mark>{eventGeral?.resources.totems_recharges}</mark></strong>.
        Quantidade recebida de: <strong><mark>{closureResourcesEvent?.returned_resources.totems_recharges}</mark></strong> em perfeito estado de conservação e funcionamento, com todos
        os seus pertences, utensílios e acessórios em perfeito estado.
      </p>
      <p style={{ textAlign: 'justify' }}>
        Vistoriados os CARTÕES DE CONSUMO entregues em comodato a quantidade de: <strong><mark>{eventGeral?.resources.cards}</mark></strong>.
        Quantidade recebida de: <strong><mark>{closureResourcesEvent?.returned_resources.cards}</mark></strong> em perfeito estado de conservação e funcionamento, com todos
        os seus pertences, utensílios e acessórios em perfeito estado.
      </p>
      <p style={{ textAlign: 'justify' }}>Em razão da não devolução integral dos equipamentos, fica o CLIENTE obrigado ao pagamento dos seguintes valores:</p>
      <div className='d-flex flex-row my-5 '>
        <div className='d-flex flex-column col-4'>
          {rowsMateriais.map(row => (
            <p>{row.equipment}</p>
          ))}
        </div>
        <div className='d-flex flex-column col-4'>
          {rowsMateriais.map(row => (
            <p>{Money.centsToMaskMoney(row.total)}</p>
          ))}
        </div>
      </div>
    </div>
  );
}

export default FirstPage;