import {
	collection,
	CollectionReference,
	DocumentData,
	getDocs,
	getFirestore,
	onSnapshot,
	query,
	QueryConstraint,
	Timestamp,
	Unsubscribe,
	where,
} from 'firebase/firestore';
import app from '../../config';
import ClouserCashier from '../../entities/eventData/clouserCashier.entity';
import { ROLE } from '../../../types/roles';



class ClouserCashierEventData {
	protected readonly colRefStr: string;
	protected readonly colRef: CollectionReference<DocumentData>;

	constructor(estabId: string, eventId: string) {
		this.colRefStr = `EstablishmentsData/${estabId}/EventsData/${eventId}/ClosureCashier`;
		this.colRef = collection(getFirestore(app), this.colRefStr);
	}

	async getAll(...params: QueryConstraint[]): Promise<ClouserCashier[]> {
		const snapshot = await getDocs(query(this.colRef, ...params))

		const array: ClouserCashier[] = [];
		snapshot.forEach(item => array.push({
			id: item.id,
			...item.data()
		} as any))

		return array
	}

	on(listener: (data: ClouserCashier[]) => void, ...params: QueryConstraint[]): Unsubscribe {
		return onSnapshot(query(this.colRef, ...params), snapshot => {
			const datas: ClouserCashier[] = [];
			snapshot.forEach(doc => datas.push({ id: doc.id, ...doc.data() as any } as ClouserCashier))
			listener(datas)
		})
	}

	onDicionary(listener: (data: any) => void, ...params: QueryConstraint[]): Unsubscribe {
		return onSnapshot(query(this.colRef, ...params), snapshot => {
			const dicionary: any = {};
			snapshot.forEach(doc => dicionary[doc.id] = doc.data())
			listener(dicionary)
		})
	}

	onDatas(listener: (data: ClouserCashier[], dicionary: any) => void, ...params: QueryConstraint[]): Unsubscribe {
		return onSnapshot(query(this.colRef, ...params), snapshot => {
			const datas: ClouserCashier[] = [];
			const dicionary: any = {};
			snapshot.forEach(doc => {
				const data = { id: doc.id, ...doc.data() as any };
				datas.push(data)
				dicionary[doc.id] = data;
			})
			listener(datas, dicionary)
		})
	}

	onDatasNewChildren(listener: (data: ClouserCashier[], dicionary: any) => void, ...params: QueryConstraint[]): Unsubscribe {
		let size = 0;
		return onSnapshot(query(this.colRef, ...params), snapshot => {
			if (size === snapshot.size) return;
			size = snapshot.size
			const datas: ClouserCashier[] = [];
			const dicionary: any = {};
			snapshot.forEach(doc => {
				const data = { id: doc.id, ...doc.data() as any };
				datas.push(data)
				dicionary[doc.id] = data;
			})
			listener(datas, dicionary)
		})
	}

	async getAllInterval(startDate: Date, endDate: Date): Promise<ClouserCashier[]> {
		const snapshot = await getDocs(query(this.colRef,
			where('opening_at', '>=', Timestamp.fromDate(startDate)),
			where('opening_at', '<=', Timestamp.fromDate(endDate)),
			where('cashierType', 'in', [ROLE.TICKET, ROLE.RECHARGE]),
		));
		return snapshot.docs.map(doc => ({
			id: doc.id,
			...doc.data(),
		})) as any;
	}
async getBySector(sectorId: string): Promise<ClouserCashier[]> {
		const snapshot = await getDocs(query(this.colRef,
			where('sectorId', '==', sectorId)
			
		))
		return snapshot.docs.map(doc => ({
			id: doc.id,
			...doc.data(),
		})) as any
	}
}

export default ClouserCashierEventData;
