import { QueryConstraint, Unsubscribe } from "firebase/firestore";
import Contract from "../entities/contract.entity";
import FirestorePipe from "../utils/pipe";
import EstablishmentDB from "./establishment";

class ContractDB extends FirestorePipe {

  public static readonly colName = 'Contracts'

  constructor(idEstab: string) {
    if (!idEstab)
      throw new Error('Informe o id do estabelecimento')
    super(`${EstablishmentDB.colName}/${idEstab}/${ContractDB.colName}`);
  }

  public async create(data: Contract): Promise<any> {
    try {
      return await this._create(data);
    } catch (error: any) {
      throw error.message
    }
  }
  public async update(id: string, data: Contract): Promise<any> {
    return this._update(id, data);
  }
  public delete(id: string): Promise<any> {
    return this._delete(id);
  }
  public getAll(...params: QueryConstraint[]): Promise<Contract[]> {
    return this._getAll<Contract>(...params);
  }
  public get(id: string): Promise<Contract> {
    return this._get(id);
  }
  public on(listener: (data: Contract[]) => void, ...params: QueryConstraint[]): Unsubscribe {
    return this._on(listener, ...params);
  }

}

export default ContractDB;