import { useFormik } from "formik";
import { memo, useCallback, useContext, useEffect, useMemo, useState } from "react";
import Card, { CardBody, CardFooter, CardHeader, CardLabel, CardTitle } from "../../../../components/bootstrap/Card";
import FormGroup from "../../../../components/bootstrap/forms/FormGroup";
import Input from "../../../../components/bootstrap/forms/Input";
import Select from "../../../../components/bootstrap/forms/Select";
import BtnCancel from "../../../../components/MyCustom/BtnCancel";
import BtnConfirm from "../../../../components/MyCustom/BtnConfirm";
import BtnLoad from "../../../../components/MyCustom/BtnLoad";
import { DefaultContext } from "../../../../contexts/default";
import ConfigUgetDB from "../../../../database/wrappers/configUget";
import PreAlert from "../../../../helpers/utils/preAlert";
import { ADQUIRENTE, ADQUIRENTE_LABEL } from "../../../../types/adquirante";
import { inputOnlyNumbers } from "../../../../utils/input";

const TaxaAdm = ({ darkModeStatus }) => {

  const { onShowAlert } = useContext(DefaultContext);

  const [loading, setloading] = useState(false)
  const [loadingGetData, setloadingGetData] = useState(false)
  const [adquirente, setadquirente] = useState(ADQUIRENTE.PAGSEGURO)

  const validate = useCallback((values) => {
    const errors = {};

    if(!values.d1_visa) {
      errors.d1_visa = 'Campo obrigatório'
    }
    if(!values.d1_visa_electron) {
      errors.d1_visa_electron = 'Campo obrigatório'
    }
    if(!values.d1_master) {
      errors.d1_master = 'Campo obrigatório'
    }
    if(!values.d1_maestro) {
      errors.d1_maestro = 'Campo obrigatório'
    }
    if(!values.d1_elo) {
      errors.d1_elo = 'Campo obrigatório'
    }
    if(!values.d1_dinners) {
      errors.d1_dinners = 'Campo obrigatório'
    }
    if(!values.d1_amex) {
      errors.d1_amex = 'Campo obrigatório'
    }
    if(!values.d1_pix) {
      errors.d1_pix = 'Campo obrigatório'
    }
    if(!values.d30_visa) {
      errors.d30_visa = 'Campo obrigatório'
    }
    if(!values.d30_visa_electron) {
      errors.d30_visa_electron = 'Campo obrigatório'
    }
    if(!values.d30_master) {
      errors.d30_master = 'Campo obrigatório'
    }
    if(!values.d30_maestro) {
      errors.d30_maestro = 'Campo obrigatório'
    }
    if(!values.d30_elo) {
      errors.d30_elo = 'Campo obrigatório'
    }
    if(!values.d30_dinners) {
      errors.d30_dinners = 'Campo obrigatório'
    }
    if(!values.d30_amex) {
      errors.d30_amex = 'Campo obrigatório'
    }
    if(!values.d30_pix) {
      errors.d30_pix = 'Campo obrigatório'
    }

    return errors;
  }, [])

  const formik = useFormik({
    initialValues: {
      d1_visa: '',
      d1_visa_electron: '',
      d1_master: '',
      d1_maestro: '',
      d1_elo: '',
      d1_dinners: '',
      d1_amex: '',
      d1_pix: '',
      d30_visa: '',
      d30_visa_electron: '',
      d30_master: '',
      d30_maestro: '',
      d30_elo: '',
      d30_dinners: '',
      d30_amex: '',
      d30_pix: '',
    },
    validate,
    onSubmit: values => {
      const {
        d1_visa,
        d1_visa_electron,
        d1_master,
        d1_maestro,
        d1_elo,
        d1_dinners,
        d1_amex,
        d1_pix,
        d30_visa,
        d30_visa_electron,
        d30_master,
        d30_maestro,
        d30_elo,
        d30_dinners,
        d30_amex,
        d30_pix,
      } = values;

      const data = {
        d1: {
          visa: Number(d1_visa),
          visa_electron: Number(d1_visa_electron),
          master: Number(d1_master),
          maestro: Number(d1_maestro),
          elo: Number(d1_elo),
          dinners: Number(d1_dinners),
          amex: Number(d1_amex),
          pix: Number(d1_pix),
        },
        d30: {
          visa: Number(d30_visa),
          visa_electron: Number(d30_visa_electron),
          master: Number(d30_master),
          maestro: Number(d30_maestro),
          elo: Number(d30_elo),
          dinners: Number(d30_dinners),
          amex: Number(d30_amex),
          pix: Number(d30_pix),
        },
      }

      const onSuccessUpdate = () => {
        onShowAlert(PreAlert.success('Registro atualizado com sucesso'))
      }
      const onError = (error) => {
        console.error(error);
        onShowAlert(PreAlert.error('Falhou ao cadastrar o registro'))
      }

      setloading(true);
      new ConfigUgetDB()
        .define('taxaAdm_' + adquirente, data)
        .then(onSuccessUpdate)
        .catch(onError)
        .finally(() => setloading(false))
    }
  });

  const loadData = useCallback((adquirente) => {
    setloadingGetData(true)
    new ConfigUgetDB()
      .get('taxaAdm_' + adquirente)
      .then(data => {
        if(!data) 
          return formik.resetForm();
        formik.setValues({
          d1_visa: String(data.d1.visa),
          d1_visa_electron: String(data.d1.visa_electron),
          d1_master: String(data.d1.master),
          d1_maestro: String(data.d1.maestro),
          d1_elo: String(data.d1.elo),
          d1_dinners: String(data.d1.dinners),
          d1_amex: String(data.d1.amex),
          d1_pix: String(data.d1.pix),
          d30_visa: String(data.d30.visa),
          d30_visa_electron: String(data.d30.visa_electron),
          d30_master: String(data.d30.master),
          d30_maestro: String(data.d30.maestro),
          d30_elo: String(data.d30.elo),
          d30_dinners: String(data.d30.dinners),
          d30_amex: String(data.d30.amex),
          d30_pix: String(data.d30.pix),
        })
      })
      .catch(error => {
        console.error(error)
        formik.resetForm();
      })
      .finally(() => setloadingGetData(false))
  },[])

  useEffect(() => loadData(adquirente), [adquirente])

  const adquirenateOptions = useMemo(() => Object.keys(ADQUIRENTE).map(key => ({
    value: ADQUIRENTE[key], label: ADQUIRENTE_LABEL[ADQUIRENTE[key]]
  })),[])

  const onChangeMoney = useCallback((e) => inputOnlyNumbers(formik, e), [])

  return (
    <Card>
      <CardHeader>
        <CardLabel icon='AttachMoney' iconColor={darkModeStatus ? 'light' : 'dark'}>
          <CardTitle>Taxa ADM</CardTitle>
        </CardLabel>
      </CardHeader>
      <form onSubmit={formik.handleSubmit}>
      <CardBody>
        <div className="row ms-2">
          <div className="col-md-12">
            <FormGroup label="Adquirente" className='col-md-3 mb-4'>
              <Select
                value={adquirente}
                list={adquirenateOptions}
                onChange={e => setadquirente(e.target.value)}
              />
            </FormGroup>
          </div>
          <div className="col-md-6">
            <h4>TAXA D+1</h4>
            <FormGroup className='row' label="Visa" labelClassName='col-md-2' isColForLabel>
              <div className="col-md-3">
              <Input
                id="d1_visa"
                onChange={onChangeMoney}
                onBlur={formik.handleBlur}
                value={formik.values.d1_visa}
                isValid={formik.isValid}
                isTouched={formik.touched.d1_visa}
                invalidFeedback={formik.errors.d1_visa}
                validFeedback='Assim está bom!'
                placeholder='0.00'
              />
              </div>
            </FormGroup>
            <FormGroup className='row' label="Visa Electron" labelClassName='col-md-2' isColForLabel>
              <div className="col-md-3">
              <Input
                id="d1_visa_electron"
                onChange={onChangeMoney}
                onBlur={formik.handleBlur}
                value={formik.values.d1_visa_electron}
                isValid={formik.isValid}
                isTouched={formik.touched.d1_visa_electron}
                invalidFeedback={formik.errors.d1_visa_electron}
                validFeedback='Assim está bom!'
                placeholder='0.00'
              />
              </div>
            </FormGroup>
            <FormGroup className='row mt-2' label="Master" labelClassName='col-md-2' isColForLabel>
              <div className="col-md-3">
              <Input
                id="d1_master"
                onChange={onChangeMoney}
                onBlur={formik.handleBlur}
                value={formik.values.d1_master}
                isValid={formik.isValid}
                isTouched={formik.touched.d1_master}
                invalidFeedback={formik.errors.d1_master}
                validFeedback='Assim está bom!'
                placeholder='0.00'
              />
              </div>
            </FormGroup>
            <FormGroup className='row mt-2' label="Maestro" labelClassName='col-md-2' isColForLabel>
              <div className="col-md-3">
              <Input
                id="d1_maestro"
                onChange={onChangeMoney}
                onBlur={formik.handleBlur}
                value={formik.values.d1_maestro}
                isValid={formik.isValid}
                isTouched={formik.touched.d1_maestro}
                invalidFeedback={formik.errors.d1_maestro}
                validFeedback='Assim está bom!'
                placeholder='0.00'
              />
              </div>
            </FormGroup>
            <FormGroup className='row mt-2' label="Elo" labelClassName='col-md-2' isColForLabel>
              <div className="col-md-3">
              <Input
                id="d1_elo"
                onChange={onChangeMoney}
                onBlur={formik.handleBlur}
                value={formik.values.d1_elo}
                isValid={formik.isValid}
                isTouched={formik.touched.d1_elo}
                invalidFeedback={formik.errors.d1_elo}
                validFeedback='Assim está bom!'
                placeholder='0.00'
              />
              </div>
            </FormGroup>
            <FormGroup className='row mt-2' label="Dinners" labelClassName='col-md-2' isColForLabel>
              <div className="col-md-3">
              <Input
                id="d1_dinners"
                onChange={onChangeMoney}
                onBlur={formik.handleBlur}
                value={formik.values.d1_dinners}
                isValid={formik.isValid}
                isTouched={formik.touched.d1_dinners}
                invalidFeedback={formik.errors.d1_dinners}
                validFeedback='Assim está bom!'
                placeholder='0.00'
              />
              </div>
            </FormGroup>
            <FormGroup className='row mt-2' label="Amex" labelClassName='col-md-2' isColForLabel>
              <div className="col-md-3">
              <Input
                id="d1_amex"
                onChange={onChangeMoney}
                onBlur={formik.handleBlur}
                value={formik.values.d1_amex}
                isValid={formik.isValid}
                isTouched={formik.touched.d1_amex}
                invalidFeedback={formik.errors.d1_amex}
                validFeedback='Assim está bom!'
                placeholder='0.00'
              />
              </div>
            </FormGroup>
            <FormGroup className='row mt-2' label="PIX" labelClassName='col-md-2' isColForLabel>
              <div className="col-md-3">
              <Input
                id="d1_pix"
                onChange={onChangeMoney}
                onBlur={formik.handleBlur}
                value={formik.values.d1_pix}
                isValid={formik.isValid}
                isTouched={formik.touched.d1_pix}
                invalidFeedback={formik.errors.d1_pix}
                validFeedback='Assim está bom!'
                placeholder='0.00'
              />
              </div>
            </FormGroup>
          </div>

          <div className="col-md-6">
            <h4>TAXA D+30</h4>
            <FormGroup className='row' label="Visa" labelClassName='col-md-2' isColForLabel>
              <div className="col-md-3">
              <Input
                id="d30_visa"
                onChange={onChangeMoney}
                onBlur={formik.handleBlur}
                value={formik.values.d30_visa}
                isValid={formik.isValid}
                isTouched={formik.touched.d30_visa}
                invalidFeedback={formik.errors.d30_visa}
                validFeedback='Assim está bom!'
                placeholder='0.00'
              />
              </div>
            </FormGroup>
            <FormGroup className='row' label="Visa Electron" labelClassName='col-md-2' isColForLabel>
              <div className="col-md-3">
              <Input
                id="d30_visa_electron"
                onChange={onChangeMoney}
                onBlur={formik.handleBlur}
                value={formik.values.d30_visa_electron}
                isValid={formik.isValid}
                isTouched={formik.touched.d30_visa_electron}
                invalidFeedback={formik.errors.d30_visa_electron}
                validFeedback='Assim está bom!'
                placeholder='0.00'
              />
              </div>
            </FormGroup>
            <FormGroup className='row mt-2' label="Master" labelClassName='col-md-2' isColForLabel>
              <div className="col-md-3">
              <Input
                id="d30_master"
                onChange={onChangeMoney}
                onBlur={formik.handleBlur}
                value={formik.values.d30_master}
                isValid={formik.isValid}
                isTouched={formik.touched.d30_master}
                invalidFeedback={formik.errors.d30_master}
                validFeedback='Assim está bom!'
                placeholder='0.00'
              />
              </div>
            </FormGroup>
            <FormGroup className='row mt-2' label="Maestro" labelClassName='col-md-2' isColForLabel>
              <div className="col-md-3">
              <Input
                id="d30_maestro"
                onChange={onChangeMoney}
                onBlur={formik.handleBlur}
                value={formik.values.d30_maestro}
                isValid={formik.isValid}
                isTouched={formik.touched.d30_maestro}
                invalidFeedback={formik.errors.d30_maestro}
                validFeedback='Assim está bom!'
                placeholder='0.00'
              />
              </div>
            </FormGroup>
            <FormGroup className='row mt-2' label="Elo" labelClassName='col-md-2' isColForLabel>
              <div className="col-md-3">
              <Input
                id="d30_elo"
                onChange={onChangeMoney}
                onBlur={formik.handleBlur}
                value={formik.values.d30_elo}
                isValid={formik.isValid}
                isTouched={formik.touched.d30_elo}
                invalidFeedback={formik.errors.d30_elo}
                validFeedback='Assim está bom!'
                placeholder='0.00'
              />
              </div>
            </FormGroup>
            <FormGroup className='row mt-2' label="Dinners" labelClassName='col-md-2' isColForLabel>
              <div className="col-md-3">
              <Input
                id="d30_dinners"
                onChange={onChangeMoney}
                onBlur={formik.handleBlur}
                value={formik.values.d30_dinners}
                isValid={formik.isValid}
                isTouched={formik.touched.d30_dinners}
                invalidFeedback={formik.errors.d30_dinners}
                validFeedback='Assim está bom!'
                placeholder='0.00'
              />
              </div>
            </FormGroup>
            <FormGroup className='row mt-2' label="Amex" labelClassName='col-md-2' isColForLabel>
              <div className="col-md-3">
              <Input
                id="d30_amex"
                onChange={onChangeMoney}
                onBlur={formik.handleBlur}
                value={formik.values.d30_amex}
                isValid={formik.isValid}
                isTouched={formik.touched.d30_amex}
                invalidFeedback={formik.errors.d30_amex}
                validFeedback='Assim está bom!'
                placeholder='0.00'
              />
              </div>
            </FormGroup>
            <FormGroup className='row mt-2' label="PIX" labelClassName='col-md-2' isColForLabel>
              <div className="col-md-3">
              <Input
                id="d30_pix"
                onChange={onChangeMoney}
                onBlur={formik.handleBlur}
                value={formik.values.d30_pix}
                isValid={formik.isValid}
                isTouched={formik.touched.d30_pix}
                invalidFeedback={formik.errors.d30_pix}
                validFeedback='Assim está bom!'
                placeholder='0.00'
              />
              </div>
            </FormGroup>
          </div>
        </div>
      </CardBody>
      <CardFooter>
        <div>
          {!loadingGetData && <BtnCancel setIsClose={() => loadData(adquirente)} className='mx-4' />}
          {loading ? (
            <BtnLoad className='mx-4' />
          ) : (
            <BtnConfirm className='mx-4' isDisable={!formik.isValid} />
          )}
        </div>
      </CardFooter>
      </form>
    </Card>
  );
}

export default memo(TaxaAdm)