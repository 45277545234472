import React, { useContext, useEffect, useState } from 'react';
import Modal, { ModalBody, ModalHeader, ModalTitle } from '../../bootstrap/Modal';
import { DefaultContext } from '../../../contexts/default';
import SelectEvent from '../../MyCustom/SelectEvent';
import { QrReader } from 'react-qr-reader';
import api from '../../../services/api';
import PreAlert from '../../../helpers/utils/preAlert';
import Spinner from '../../bootstrap/Spinner';
import Button from '../../bootstrap/Button';

const ModalAuthTotem = ({ open, setIsOpen, totemsSelected, totemType }) => {

  const { estabSelected, eventSelected, onShowAlert } = useContext(DefaultContext)

  const [page, setpage] = useState(0)
  const [loading, setloading] = useState(false)

  useEffect(() => {
    if(!open) {
      setpage(0)
    }
  },[open])

  const onResult = (result, error) => {
    if (!!error) {
      return console.error(error);
    }

    if (!!result) {
      let json;
      try {
        json = JSON.parse(result.text)
        if(
          !json.publicId ||
          !json.keyUser
        )
          throw new Error('Qrcode inválido')
      } catch (error) {
        return onShowAlert(PreAlert.error('Qrcode inválido'))
      }

      if (json.totemType !== totemType) {
        return onShowAlert(PreAlert.error('QRcode não pertence a este tipo de dispositivo'))
      }

      const data = {
        publicId: json.publicId,
        totemId: totemsSelected.id,
        keyUser: json.keyUser,

        estabId: estabSelected,
        eventId: eventSelected,
      }

      const onSuccess = () => {
        onShowAlert(PreAlert.success('QR Code autenticado com sucesso'))
        setIsOpen(false);
      }
      const onError = (error) => {
        console.error(error);
        onShowAlert(PreAlert.error(error?.response?.data?.message || 'Falhou ao enviar os dados do qr code'))
      }

      setloading(true)
      api
        .put('routes/totemQrcodeLogin', data)
        .then(onSuccess)
        .catch(onError)
        .finally(() => setloading(false))
    }
  }

  return (
    <Modal
      id={'modal-auth-totenS'}
      titleId={'Logar Totem'}
      isOpen={open}
      setIsOpen={setIsOpen}
      isStaticBackdrop={true}
      isScrollable={false}
      isCentered={true}
      size="lg" // 'sm' || 'lg' || 'xl' 
      isAnimation={true}
    >
      <ModalHeader setIsOpen={setIsOpen}>
        <ModalTitle id="register-totems">Logar Totem</ModalTitle>
      </ModalHeader>
      <ModalBody>
        <h4 className='text-center'>{totemsSelected?.name}</h4>

        {loading ? (
          <div className='col-md-12 d-flex justify-content-center align-items-center flex-column'>
            <Spinner />
            <h5 className='mt-2'>Enviando</h5>
            <p>Por favor, aguarde</p>
          </div>
        ) : page === 0 ? (
          <div className='row col-md-12'>
            <SelectEvent/>
            <div className='col-md-4 d-flex align-items-center'>
              <Button
                className='mt-3'
                type='buttom'
                color='primary'
                onClick={() => setpage(1)}
              >
                Continuar
              </Button>
            </div>
          </div>
        ) : (
          <QrReader
            onResult={onResult}
            constraints={{
              facingMode: {
                exact: 'environment'
              }
            }}
            style={{ width: '100%' }}
          />
        )}
      </ModalBody>
    </Modal>
  )
}

export default ModalAuthTotem;