import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import PageWrapper from '../../../../layout/PageWrapper/PageWrapper';
import SubHeader, { SubHeaderLeft, SubHeaderRight } from '../../../../layout/SubHeader/SubHeader';
import Page from '../../../../layout/Page/Page';
import Button from '../../../../components/bootstrap/Button';
import { demoPages } from '../../../../menu';
import ModalRegisterEntrance from '../../../../components/modals/TicketBoxEntrances/ModalRegister';
import { DefaultContext } from '../../../../contexts/default';
import EntranceDB from '../../../../database/wrappers/entrance';
import { orderBy } from 'firebase/firestore';
import Card, { CardBody, CardHeader, CardLabel, CardTitle } from '../../../../components/bootstrap/Card';
import Money from '../../../../helpers/utils/money/money';
import { SEX_LABEL } from '../../../../types/sex';
import TableCustom from '../../../../components/MyCustom/TableCustom';
import showNotification from '../../../../components/extras/showNotification';
import notificationBody from '../../../../helpers/utils/functions/notificationBody';
import Input from '../../../../components/bootstrap/forms/Input';
import Breadcrumb from '../../../../components/bootstrap/Breadcrumb';
import useDarkMode from '../../../../hooks/useDarkMode';
import SelectEstablishment from '../../../../components/MyCustom/SelectEstablishment';
import SelectEvent from '../../../../components/MyCustom/SelectEvent';

const TicketBox = () => {
  const { darkModeStatus } = useDarkMode();

  const { estabSelected, eventSelected, onShowQuestion } = useContext(DefaultContext)

  const [openModalRegister, setOpenModalRegister] = useState(false);
  const [entrances, setentrances] = useState([])
  const [entranceSelected, setentranceSelected] = useState(null)

  useEffect(() => {
    if (!estabSelected || !eventSelected) return;
    const onSubscriber = new EntranceDB(estabSelected, eventSelected).on(setentrances, orderBy('name', 'desc'))
    return onSubscriber;
  }, [estabSelected, eventSelected])

  const handleCloseModal = useCallback(() => {
    setOpenModalRegister(false)
  }, [])

  const handleRegister = useCallback(() => {
    setentranceSelected(null);
    setOpenModalRegister(true)
  }, [])

  const handleEntranceEdit = useCallback((item) => {
    setentranceSelected(item);
    setOpenModalRegister(true)
  }, [])

  const handleChangeItem = useCallback((id, key, value) => {
    new EntranceDB(estabSelected, eventSelected)
      .update(id, {
        [key]: value
      })
      .then(() => showNotification('', notificationBody('Registro alterado com sucesso'), 'success'))
      .catch(() => showNotification('', notificationBody('Falhou ao atualizar o registro'), 'danger'))
  }, [estabSelected, eventSelected])

  const handleOpenModalDelete = useCallback((id) => {
    onShowQuestion({
      title: 'Exclusão de Entrada de Bilheteria',
      message: 'Você está excluindo uma entrada de Bilheteria. Está ação é irreversível. Deseja continuar?',
      onConfirm: () => {
        new EntranceDB(estabSelected, eventSelected)
          .delete(id)
          .then(res => showNotification('', notificationBody('Registro deletado com sucesso'), 'success'))
          .catch(err => showNotification('', notificationBody('Falhou ao deletar o registro'), 'danger'))
      }
    })
  }, [estabSelected, eventSelected])

  console.log('entrances', entrances)

  const rowsRender = useMemo(() =>
    entrances.map(row => ({
      ...row,
      sexLabel: SEX_LABEL[row.sex],
      vlIng: row.value - row.consummation,
    }))
    , [entrances])



  const columns = useMemo(() => ([
    {
      label: 'Entrada',
      field: 'name',
    },
    {
      label: 'Cor',
      field: 'color',
      format: row => (
        <Input
          type='color'
          disabled
          value={row.color}
        />
      ),
      noExport: true,
    },
    {
      label: 'Sexo',
      field: 'sexLabel',
    },
    {
      label: 'Valor Unitário',
      field: 'value',
      format: row => Money.centsToMaskMoney(row.value),
      formatExport: value => Money.centsToCoin(value),
    },
    {
      label: 'Valor do Ingresso',
      field: 'vlIng',
      format: row => Money.centsToMaskMoney(row.vlIng),
      formatExport: value => Money.centsToCoin(value),
    },
    {
      label: 'Consumação',
      field: 'consummation',
      format: row => Money.centsToMaskMoney(row.consummation),
      formatExport: value => Money.centsToCoin(value),
    },
    {
      label: 'Status',
      field: 'active',
      format: row => (
        <Button
          isLink
          color={row.active ? 'success' : 'danger'}
          icon='Circle'
          className='text-nowrap'
          onClick={() => handleChangeItem(row.id, 'active', !row.active)}
        >
          {row.active ? 'Ativo' : 'Inativo'}
        </Button>
      )
    },
    {
      label: 'Exclusivo LINK',
      field: 'link',
      format: row => (
        <Button
          isLink
          color={row.link ? 'success' : 'danger'}
          icon='Circle'
          className='text-nowrap'
          onClick={() => handleChangeItem(row.id, 'link', !row.link)}
        >
          {row.link ? 'Ativo' : 'Inativo'}
        </Button>
        
      ),
      formatExport: value => value ? 'Ativo' : 'Inativo'
           
    },
    {
      label: 'Ações',
      field: 'actions',
      format: row => (
        <div className='w-75 d-flex justify-content-between'>
          <Button
            color={darkModeStatus ? 'light' : 'dark'}
            icon="edit"
            shadow="sm"
            hoverShadow="sm"
            size="sm"
            onClick={() => handleEntranceEdit(row)}
          />
        </div>
      ),
      noExport: true
    },
  ]), [darkModeStatus, handleOpenModalDelete, handleChangeItem])


  return (
    <>
      <PageWrapper title={demoPages.event.subMenu.entrance.text}>
        <SubHeader>
          <SubHeaderLeft>
            <Breadcrumb
              list={[
                { title: 'Evento', to: '/event/bilheteria' },
                { title: 'Bilheteria', to: '/event/bilheteria' },
              ]}
            />
          </SubHeaderLeft>
          <SubHeaderRight>
            <Button
              color='primary'
              icon='plus'
              shadow="sm"
              hoverShadow="sm"
              onClick={handleRegister}
            >
              Cadastrar
            </Button>
          </SubHeaderRight>
        </SubHeader>

        <Page container="fluid">
          <Card>
            <CardHeader>
              <CardLabel icon='ConfirmationNumber' iconColor={darkModeStatus ? 'light' : 'dark'}>
                <CardTitle>Bilheteria</CardTitle>
              </CardLabel>
            </CardHeader>
            <CardBody className='table-responsive'>
              <div className='row'>
                <SelectEstablishment />
                <SelectEvent />
              </div>

              <TableCustom
                rows={rowsRender}
                columns={columns}
                keyExtractor={row => row.id}
                fileName='Bilheteria'
              />
            </CardBody>
          </Card>
        </Page>

        <ModalRegisterEntrance
          open={openModalRegister}
          setIsOpen={setOpenModalRegister}
          setIsClose={handleCloseModal}
          entranceSelected={entranceSelected}
        />
      </PageWrapper>
    </>
  );
};

export default TicketBox;
