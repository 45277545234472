import { useMemo, memo } from "react";
import useDarkMode from "../../../../../hooks/useDarkMode";
import Card, { CardActions, CardBody, CardHeader, CardLabel, CardTitle } from "../../../../../components/bootstrap/Card";
import Popovers from "../../../../../components/bootstrap/Popovers";
import CardValue from "../../../../../components/MyCustom/Dashboard/CardValue";
import Money from "../../../../../helpers/utils/money/money";
import Icon from "../../../../../components/icon/Icon";
import { TABS } from "../tabs";


const Faturamento = ({ faturamento }) => {
    const { darkModeStatus } = useDarkMode();
    const {totalFaturamento, totalCredit,totalDebit,totalPix, totalMealTicket,totalCash} = faturamento;

    console.log(faturamento);
    return (
        <>
            <Card >
                <CardHeader>
                    <CardLabel icon='PieChart' iconColor={!darkModeStatus ? 'dark' : 'light'}>
                        <CardTitle className='d-flex flex-row align-items-center'>
                            <CardActions>
                                FATURAMENTO
                            </CardActions>
                            <Popovers desc={<b>Valor faturado no evento, representado por: Crédito, Débito, Pix, Vale Refeição e Dinheiro</b>}><Icon className='mx-3' size='2x' icon='Info' /></Popovers>
                        </CardTitle>
                    </CardLabel>
                </CardHeader>
                <CardBody >

                    <div className='row' >
                        <div className='col-md'>
                            <CardValue
                                color='dark'
                                label='TOTAL'
                                value={Money.centsToMaskMoney(totalFaturamento)}
                            />
                        </div>
                        <div className='col-md'>
                            <CardValue
                                color='primary'
                                label='CRÉDITO'
                                description='Valor faturado de crédito durante o evento.'
                                value={Money.centsToMaskMoney(totalCredit)}
                            />
                        </div>
                        <div className='col-md'>
                            <CardValue
                                color='secondary'
                                label='DÉBITO'
                                description='Valor faturado de débito durante o evento.'
                                value={Money.centsToMaskMoney(totalDebit)}
                            />
                        </div>
                        <div className='col-md'>
                            <CardValue
                                color='success'
                                label='PIX'
                                description='Valor faturado de pix durante o evento.'
                                value={Money.centsToMaskMoney(totalPix)}
                            />
                        </div>
                        <div className='col-md'>
                            <CardValue
                                color='info'
                                label='VALE REFEIÇÃO'
                                description='Valor faturado de vale refeições durante o evento.'
                                value={Money.centsToMaskMoney(totalMealTicket)}
                            />
                        </div>
                        <div className='col-md'>
                            <CardValue
                                color='warning'
                                label='DINHEIRO'
                                description='Valor faturado de dinheiro durante o evento.'
                                value={Money.centsToMaskMoney(totalCash)}
                            />
                        </div>
                    </div>

                </CardBody>
            </Card>
        </>
    )
}

export default memo(Faturamento);
