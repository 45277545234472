import React, { useState, useEffect, useContext, useMemo } from 'react';
import Page from '../../../../layout/Page/Page';
import useDarkMode from '../../../../hooks/useDarkMode';
import { DefaultContext } from '../../../../contexts/default';
import SubHeader, { SubHeaderLeft, SubHeaderRight } from '../../../../layout/SubHeader/SubHeader';
import PageWrapper from '../../../../layout/PageWrapper/PageWrapper';
import { tabsFilterByEventTypes } from './tabs';
import TotemReports from './totemReports';
import TabCaixa from './cashReports';
import { TABS } from './tabs';
import HeaderLeftReports from '../../../../components/MyCustom/Relatorios'
import TabConsumo from './movementConsumption';
import TabRecarga from './rechargeMovement';
import FilterReports from '../../../../components/MyCustom/Relatorios/FilterReports';
import TabSales from './totaisVendas/'
import TabBilheteria from './entranceReports';
import TabEstoque from './stock'




const FilterByDate = () => {
    const { themeStatus } = useDarkMode()

    const { event, isMobile } = useContext(DefaultContext)

    const tabs = useMemo(() => tabsFilterByEventTypes(event), [event])

    const [activeTab, setActiveTab] = useState(TABS.VENDA.id);
    const [activeSubTab, setActiveSubTab] = useState('');
    

    return (
        <PageWrapper title='uGet PDX'>
            <SubHeader >
                <SubHeaderLeft className='d-flex flex-row flex-wrap align-items-center justify-content-sm-start justify-content-center'>
                    <HeaderLeftReports
                        isMobile={isMobile}
                        tabs={tabs}
                        activeTab={activeTab}
                        themeStatus={themeStatus}
                        setActiveTab={setActiveTab}
                        setActiveSubTab={setActiveSubTab}
                    />
                </SubHeaderLeft>
                <SubHeaderRight>
                    <FilterReports />
                </SubHeaderRight>
            </SubHeader>


            <Page container="fluid">
                <TabSales hidden={activeTab !== TABS.VENDA.id} />
                <TabCaixa hidden={activeTab !== TABS.CAIXA.id} SubTabs={activeSubTab}  />
                <TotemReports hidden={activeTab !== TABS.TOTEM.id} />
                <TabConsumo hidden={activeTab !== TABS.CONSUMO.id} SubTabs={activeSubTab}/>
                <TabBilheteria hidden={activeTab !== TABS.ENTRADA.id} SubTabs={activeSubTab}/>
                <TabRecarga hidden={activeTab !== TABS.RECARGA.id} SubTabs={activeSubTab}/>
                <TabEstoque hidden={activeTab !== TABS.ESTOQUE.id} SubTabs={activeSubTab}/>


            </Page>
        </PageWrapper >
    );
};

export default FilterByDate;
