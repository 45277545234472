import React, { useCallback, useContext, useEffect, useState, useMemo } from 'react';
import PageWrapper from '../../../../layout/PageWrapper/PageWrapper';
import SubHeader, { SubHeaderLeft, SubHeaderRight } from '../../../../layout/SubHeader/SubHeader';
import Page from '../../../../layout/Page/Page';
import Breadcrumb from '../../../../components/bootstrap/Breadcrumb';
import Button from '../../../../components/bootstrap/Button';
import { demoPages } from '../../../../menu';
import Card, { CardBody, CardHeader, CardLabel, CardTitle } from '../../../../components/bootstrap/Card';
import SelectEstablishment from '../../../../components/MyCustom/SelectEstablishment';
import { DefaultContext } from '../../../../contexts/default';
import TableCustom from '../../../../components/MyCustom/TableCustom';
import showNotification from '../../../../components/extras/showNotification';
import notificationBody from '../../../../helpers/utils/functions/notificationBody';
import { orderBy, where } from 'firebase/firestore';
import useDarkMode from '../../../../hooks/useDarkMode';
import TotenDB from '../../../../database/wrappers/toten';
import TotemKeysDB from '../../../../database/wrappers/totemKeys';
import convertArrayToObject from '../../../../utils/convertArrayToObject';
import ModalRegisterTotem from '../../../../components/modals/Totem/ModalRegister';
import SectorDB from '../../../../database/wrappers/sector';
import ModalAuthTotem from '../../../../components/modals/Totem/ModalAuthTotem';
import { QRCODE_TOTEM_TYPE } from '../../../../types/qrcodeTotem';

const Totem = () => {
  const { darkModeStatus } = useDarkMode();

  const { estabSelected, onShowQuestion } = useContext(DefaultContext)

  const [sectorDicionary, setsectorDicionary] = useState({})
  const [openModalRegister, setOpenModalRegister] = useState(false);
  const [openModalAuth, setopenModalAuth] = useState(false);
  const [totems, settotems] = useState([]);
  const [totemsDicionary, settotemsDicionary] = useState({});
  const [totemsSelected, settotemsSelected] = useState(null);

  useEffect(() => {
    if (!estabSelected) return;
    const onSubscribe = new TotenDB(estabSelected).on(settotems, orderBy('name', 'asc'))
    const onSubscribe2 = new TotemKeysDB().on(datas =>
      settotemsDicionary(convertArrayToObject(datas, 'totemId')), where('estabId', '==', estabSelected)
    )
    const onSubscribe3 = new SectorDB(estabSelected).on(datas => 
      setsectorDicionary(convertArrayToObject(datas, 'id'))
    )
    return () => {
      onSubscribe();
      onSubscribe2();
      onSubscribe3();
    };
  }, [estabSelected]);

  const handleOpenModalAuth = useCallback((totem) => {
    settotemsSelected(totem)
    setopenModalAuth(true)
  }, [])

  const handleOpenModalRegister = useCallback(() => {
    settotemsSelected(null);
    setOpenModalRegister(true);
  }, [])

  const handleCloseModalRegister = useCallback(() => {
    setOpenModalRegister(false);
  }, [])

  const handleOpenModalEdit = useCallback((item) => {
    settotemsSelected(item);
    setOpenModalRegister(true)
  }, [])

  const handleChangeItem = useCallback((id, key, value) => {
    new TotenDB(estabSelected)
      .update(id, {
        [key]: value
      })
      .then(() => showNotification('', notificationBody('Registro alterado com sucesso'), 'success'))
      .catch(() => showNotification('', notificationBody('Falhou ao atualizar o registro'), 'danger'))
  }, [estabSelected])

  const handleOpenModalDelete = useCallback((id) => {
    onShowQuestion({
      title: 'Exclusão de Totem',
      message: 'Você está excluindo um Totem. Esta ação é irreversível. Deseja continuar?',
      onConfirm: () => {
        new TotenDB(estabSelected)
          .delete(id)
          .then(() => showNotification('', notificationBody('Registro excluído com sucesso'), 'success'))
          .catch(() => showNotification('', notificationBody('Falha ao excluir o registro'), 'danger'))
      }
    })
  }, [estabSelected])

  const columns = useMemo(() => ([
    {
      label: 'QR CODE',
      field: 'qrcode',
      noExport: true,
      format: row => (
        <Button
          color={darkModeStatus ? 'light' : 'dark'}
          icon='QrCode'
          shadow="sm"
          hoverShadow="sm"
          size="sm"
          onClick={() => handleOpenModalAuth(row)}
        />
      )
    },
    {
      label: 'Nome',
      field: 'name',
    },
    {
      label: 'Chave',
      field: '{row}',
      format: row => totemsDicionary[row.id]?.id,
      formatExport: row => totemsDicionary[row.id]?.id,
    },
    {
      label: 'PDV',
      field: 'terminal',
    },
    {
      label: 'Setor',
      field: '{row}',
      format: row => sectorDicionary[row.sectorId]?.name,
      formatExport: row => sectorDicionary[row.sectorId]?.name,
    },
    {
      label: 'Status',
      field: 'active',
      format: row => (
        <Button
          isLink
          color={row.active ? 'success' : 'danger'}
          icon='Circle'
          className='text-nowrap'
          onClick={() => handleChangeItem(row.id, 'active', !row.active)}
        >
          {row.active ? 'Ativo' : 'Inativo'}
        </Button>
      )
    },
    {
      label: 'Ações',
      field: 'actions',
      format: row => (
        <div className='w-75 d-flex justify-content-between'>
          <Button
            color={darkModeStatus ? 'light' : 'dark'}
            icon="edit"
            shadow="sm"
            hoverShadow="sm"
            size="sm"
            onClick={() => handleOpenModalEdit(row)}
          />
          <Button
            color="danger"
            icon="Trash"
            shadow="sm"
            hoverShadow="sm"
            size="sm"
            onClick={() => handleOpenModalDelete(row.id)}
          />
        </div>
      ),
      noExport: true
    },
  ]), [totemsDicionary, sectorDicionary, darkModeStatus, handleOpenModalDelete, handleChangeItem])

  return (
    <>
      <PageWrapper title={demoPages.establishment.subMenu.totems.text}>
        <SubHeader>
          <SubHeaderLeft>
            <Breadcrumb
              list={[
                { title: 'Estabelecimento', to: '/estab/totems' },
                { title: 'Totem', to: '/estab/totems' },
              ]}
            />
          </SubHeaderLeft>
          <SubHeaderRight>
            {/* <Button
              className='text-dark'
              color='warning'
              icon='QrCode'
              shadow="sm"
              hoverShadow="sm"
              onClick={handleOpenModalAuth}>
              Logar Totem
            </Button> */}
            <Button
              color='primary'
              icon='plus'
              shadow="sm"
              hoverShadow="sm"
              onClick={handleOpenModalRegister}>
              Cadastrar
            </Button>
          </SubHeaderRight>
        </SubHeader>
        <Page container='fluid'>
          <Card>
            <CardHeader>
              <CardLabel icon='SettingsCell' iconColor={darkModeStatus ? 'light' : 'dark'}>
                <CardTitle>Totem</CardTitle>
              </CardLabel>
            </CardHeader>
            <CardBody className='table-responsive'>
              <SelectEstablishment />

              <TableCustom
                columns={columns}
                rows={totems}
                fileName="Totem"
              />
            </CardBody>
          </Card>
        </Page>
      </PageWrapper>

      <ModalRegisterTotem
        open={openModalRegister}
        setIsOpen={setOpenModalRegister}
        setIsClose={handleCloseModalRegister}
        totemsSelected={totemsSelected}
      />

      <ModalAuthTotem
        open={openModalAuth}
        setIsOpen={setopenModalAuth}
        totemsSelected={totemsSelected}
        totemType={QRCODE_TOTEM_TYPE.TOTEM_TICKET}
      />
    </>
  );
};

export default Totem;
