import React, { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import PageWrapper from '../../../../layout/PageWrapper/PageWrapper';
import SubHeader, { SubHeaderLeft, SubHeaderRight } from '../../../../layout/SubHeader/SubHeader';
import Page from '../../../../layout/Page/Page';
import Button from '../../../../components/bootstrap/Button';
import { demoPages } from '../../../../menu';
import { DefaultContext } from '../../../../contexts/default';
import Card, { CardBody, CardHeader, CardLabel, CardTitle } from '../../../../components/bootstrap/Card';
import SelectEstablishment from '../../../../components/MyCustom/SelectEstablishment';
import Money from '../../../../helpers/utils/money/money';
import ModalRegisterEarlyEntry from '../../../../components/modals/EarlyConsummation/modalRegister'
import TableCustom from '../../../../components/MyCustom/TableCustom';
import showNotification from '../../../../components/extras/showNotification';
import notificationBody from '../../../../helpers/utils/functions/notificationBody';
import Breadcrumb from '../../../../components/bootstrap/Breadcrumb';
import EarlyConsummationDB from '../../../../database/wrappers/earlyConsummation';
import SelectEvent from '../../../../components/MyCustom/SelectEvent';
import { orderBy } from 'firebase/firestore';
import useDarkMode from '../../../../hooks/useDarkMode';
import { cpfFormatMask } from '../../../../utils/format/cpfFormat';
import { Link } from 'react-router-dom';

const EarlyConsummationPage = () => {
  const { darkModeStatus } = useDarkMode();

  const { estabSelected, eventSelected, onShowQuestion } = useContext(DefaultContext)

  const [openModalRegister, setOpenModalRegister] = useState(false);
  const [earlyConsummation, setearlyConsummation] = useState([])

  const refLinkCsv = useRef(null)

  useEffect(() => {
    if (!estabSelected || !eventSelected) return;
    const onSubscriber = new EarlyConsummationDB(estabSelected, eventSelected).on(setearlyConsummation, orderBy('name', 'asc'))
    return onSubscriber;
  }, [estabSelected, eventSelected])

  const onDownloadCsvFile = useCallback(() => {
    refLinkCsv.current.click();
  }, [refLinkCsv])

  const handleCloseModal = useCallback(() => {
    setOpenModalRegister(false)
  }, [])

  const handleRegister = useCallback(() => {
    setOpenModalRegister(true)
  }, [])

  const handleChangeItem = useCallback((id, key, value) => {
    new EarlyConsummationDB(estabSelected, eventSelected)
      .update(id, {
        [key]: value
      })
      .then(() => showNotification('', notificationBody('Registro alterado com sucesso'), 'success'))
      .catch(() => showNotification('', notificationBody('Falhou ao atualizar o registro'), 'danger'))
  }, [estabSelected, eventSelected])

  const handleOpenModalDelete = useCallback((id) => {
    onShowQuestion({
      title: 'Exclusão de Entrada Antecipada',
      message: 'Você está excluindo uma entrada antecipada. Está ação é irreversível. Deseja continuar?',
      onConfirm: () => {
        new EarlyConsummationDB(estabSelected, eventSelected)
          .delete(id)
          .then(res => showNotification('', notificationBody('Registro deletado com sucesso'), 'success'))
          .catch(err => showNotification('', notificationBody('Falhou ao deletar o registro'), 'danger'))
      }
    })
  }, [estabSelected, eventSelected])

  const rowsRender = useMemo(() => earlyConsummation.map(row => ({
    ...row,
    cpf: cpfFormatMask(row.cpf)
  })), [earlyConsummation])

  const columns = useMemo(() => ([
    {
      label: 'Nome',
      field: 'name',
    },
    {
      label: 'Ingresso',
      field: 'ticket',
    },
    {
      label: 'Email',
      field: 'email',
    },
    {
      label: 'CPF',
      field: 'cpf',
      // format: row => masks.cpf(row.cpf.toString()),
      // formatExport: value => masks.cpf(value.toString())
    },
    {
      label: 'Telefone',
      field: 'phone',
       formatExport: value => value || ''
    },
    {
      label: 'Valor',
      field: 'value',
      format: row => Money.centsToMaskMoney(row.value),
      formatExport: value => Money.centsToMaskMoney(value)
    },
    {
      label: 'Consumação',
      field: 'consummation',
      format: row => Money.centsToMaskMoney(row.consummation),
      formatExport: value => Money.centsToMaskMoney(value)
    },
    {
      label: 'Disponivel',
      field: 'used',
      format: row => (
        <Button
          isLink
          color={row.used ? 'danger' : 'success'}
          icon='Circle'
          className='text-nowrap'
          onClick={() => handleChangeItem(row.id, 'used', !row.used)}
        >
          {row.used ? 'Usado' : 'Livre'}
        </Button>
      ),
      formatExport: value => !value
    },
    {
      label: 'Ações',
      field: 'actions',
      format: row => (
        <div className='w-75 d-flex justify-content-between'>
          <Button
            color="danger"
            icon="Trash"
            shadow="sm"
            hoverShadow="sm"
            size="sm"
            onClick={() => handleOpenModalDelete(row.id)}
          />
        </div>
      ),
      noExport: true
    },
  ]), [handleOpenModalDelete, handleChangeItem])



  return (
    <>
      <PageWrapper title={demoPages.event.subMenu.earlyConsummation.text}>
        <SubHeader>
          <SubHeaderLeft>
            <Breadcrumb
              list={[
                { title: 'Evento', to: '/event/' },
                { title: 'Consumação Antecipada', to: '/event/earlyConsummation' },
              ]}
            />
          </SubHeaderLeft>
          <SubHeaderRight>
            <a hidden ref={refLinkCsv} href='https://firebasestorage.googleapis.com/v0/b/e-pdx-b9d3b.appspot.com/o/ExtraFiles%2Fconsumacao-antecipado.csv?alt=media&token=345afc51-e357-48e5-a7f2-6e5ba8f2fa81' />
            <Button
              type='file'
              className='text-dark'
              color='success'
              icon='download'
              shadow="sm"
              hoverShadow="sm"
              onClick={onDownloadCsvFile}
            >
              Download CSV
            </Button>
            <Button
              color='primary'
              icon='plus'
              shadow="sm"
              hoverShadow="sm"
              onClick={handleRegister}
            >
              Cadastrar
            </Button>
          </SubHeaderRight>
        </SubHeader>

        <Page container="fluid">
          <Card>
            <CardHeader>
              <CardLabel icon='ConfirmationNumber' iconColor={darkModeStatus ? 'light' : 'dark'}>
                <CardTitle>Consumação Antecipada</CardTitle>
              </CardLabel>
            </CardHeader>
            <CardBody className='table-responsive'>
              <div className='row'>
                <SelectEstablishment />
                <SelectEvent />
              </div>

              <TableCustom
                titlePdf='Consumação Antecipada'
                icon="ConfirmationNumber"
                fontSize={8}
                rows={rowsRender}
                columns={columns}
                keyExtractor={row => row.id}
                fileName='Consumação-antecipada'
              />
            </CardBody>
          </Card>
        </Page>
      </PageWrapper>

      <ModalRegisterEarlyEntry
        open={openModalRegister}
        setIsOpen={setOpenModalRegister}
        setIsClose={handleCloseModal}
      />
    </>
  );
};

export default EarlyConsummationPage;
