import React, { memo, useMemo } from 'react';
import Checks, { ChecksGroup } from '../../../bootstrap/forms/Checks';
import FormGroup from '../../../bootstrap/forms/FormGroup';
import Input from '../../../bootstrap/forms/Input';

function EstablishmentItem({item, onChange, checked}) {
  return(
    <div className='row g-4 py-2'>
      <div className='col-md-1 d-flex align-items-center justify-content-center'>
        <ChecksGroup>
          <Checks
            type='switch'
            id='estabs'
            name='estabs'
            value={item.id}
            onChange={onChange}
            checked={checked}
            isInline
          />
        </ChecksGroup>
      </div>
      <div className='col-md-11'>
        <FormGroup>
          <Input value={item.name} disabled readOnly />
        </FormGroup>
      </div>
    </div>
  );
}

const EstablishmentElement = memo(EstablishmentItem);

function ListEstablishments({ hidden, establishment, establishmentSelected, onChange }) {
  const Header = useMemo(() => (
    <div className='row'>
      <div className='col-md-1'>
        <p>#</p>
      </div>
      <div className='col-md-11'>
        <p>Nome</p>
      </div>
    </div>
  ), [])

  if(!establishmentSelected) return null;
	return (
    <div 
      hidden={hidden}
    >
      { Header }

      <div 
        style={{ maxHeight: 400 }} 
        className='overflow-y'
      >
        {establishment.map((item, index) => {
          return (
            <EstablishmentElement
              key={'ch_comp'+index}
              item={item}
              onChange={onChange}
              checked={establishmentSelected.includes(item.id)}
            />
          );
        })}
      </div>
    </div>
	);
}

export default memo(ListEstablishments);
