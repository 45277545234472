import React, { useEffect } from "react";
import HeaderContrato from "./header";
import BodyContract from "./bodyContract";
import LastPageContract from "./lastPageContract";
import UserDB from "../../../database/wrappers/user";

const EventContract = ({ estabelecimento, evento, osData }) => {

    return (
        <>
        <HeaderContrato event={evento} establishment={estabelecimento} />
        <BodyContract event={evento} establishment={estabelecimento} osData={osData} />
        <LastPageContract event={evento} establishment={estabelecimento} />
        </>
    );
}

export default EventContract;
