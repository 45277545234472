export const BASIC_ROLE = {
  SUPER_ADMIN: 'superAdmin',
  ADMIN: 'admin',
  MANAGER: 'manager',
  COORDINATOR: 'coordinator',
  OPERATOR: 'operator',
  SELLER: 'seller',
  STOCKIST: 'stockist',
  CONTRACT_MANAGER: 'contractManager',
  FINANCIAL_MANAGER: 'financialManager',
} as const

export const ROLE = {
  TICKET: 'ticket',
  RECHARGE: 'recharge',
  CONSUMPTION: 'consumption',
  BURNER: 'burner',
  HAWKER: 'hawker',
  WALKER: 'walker',
} as const

export const ACCESS_LEVEL = {
  SUPER_ADMIN: 4,
  ADMIN: 3,
  MANAGER: 2,
  COORDINATOR: 1,
  OPERATOR: 0,
  SELLER: 0,
} as const

export const ROLE_PTBR = {
  [BASIC_ROLE.SUPER_ADMIN]: 'Super Admin',
  [BASIC_ROLE.ADMIN]: 'Administrador',
  [BASIC_ROLE.MANAGER]: 'Gerente',
  [BASIC_ROLE.COORDINATOR]: 'Coordenador',
  [BASIC_ROLE.OPERATOR]: 'Operador',
  [BASIC_ROLE.SELLER]: 'vendedor',
  [ROLE.TICKET]: 'Ticket',
  [ROLE.RECHARGE]: 'Recarga',
  [ROLE.CONSUMPTION]: 'Consumo',
  [ROLE.BURNER]: 'Queimador',
  [ROLE.HAWKER]: 'Caixa de Ambulante',
  [ROLE.WALKER]: 'Ambulante'
}

export const ROLE_ACCESS_LEVEL = {
  [BASIC_ROLE.SUPER_ADMIN]: 4,
  [BASIC_ROLE.ADMIN]: 3,
  [BASIC_ROLE.MANAGER]: 2,
  [BASIC_ROLE.COORDINATOR]: 1,
  [BASIC_ROLE.OPERATOR]: 0,
  [BASIC_ROLE.SELLER]: 0,
  [BASIC_ROLE.STOCKIST]: 0,
  [BASIC_ROLE.CONTRACT_MANAGER]: 0,
  [BASIC_ROLE.FINANCIAL_MANAGER]: 0,
}
