import React, { memo } from 'react';

const TableSimples = ({ className, columns, rows, keyExtractor, footer }) => {
	return (
		<table className={`table table-modern ${className || ''}`}>
			<thead>
				<tr>
					{columns.map((column) => (
						<th key={'tab-head-' + column.label}>
							{column.label.toUpperCase()}
						</th>
					))}
				</tr>
			</thead>
			<tbody>
				{rows.map((item, index) => (
					<tr key={keyExtractor ? keyExtractor(item) : item.id}>
						{columns.map((c, index2) => (
							<td key={`tab-cell-${item.id}-${index}-${index2}`}>
								{c.format ? c.format(item) : item[c.field]}
							</td>
						))}
					</tr>
				))}
				{ footer }
			</tbody>
		</table>
	);
};

export default memo(TableSimples);
