
import React, { memo, useContext } from 'react';
import YoutLogo from '../../../assets/img/yourlogo.png';
import { DefaultContext } from '../../../contexts/default';
import './avatar.css';

const LIMIT_SIZE = 100000;

function Avatar({ id, value, onChange }) {
    const photo = value ? value : YoutLogo;

    const { onShowAlert } = useContext(DefaultContext)

    const onLocalChange = e => {
        // const [file] = Array.from(e.target.files)
        // if(file.size > LIMIT_SIZE) {
        //     console.error('Imagem muito grande');
        //     return onShowAlert(PreAlert.error('Imagem muito grande. Limite de ' + (LIMIT_SIZE / 1000) + ' KB'))
        // }
        onChange(e);
    }
   
    
    return (
        <>
        <div className="personal-image">
            <label className="label">
                <input type="file" id={id} accept={"image/*"} multiple onChange={onLocalChange} />
                <figure className="personal-figure">
                    <div style={ {position: "relative"} } className="personal-avatar rounded">
                        <img src={photo}  style={{zIndex: '5', width: 'auto', height:'auto', maxHeight: '100px'}} alt=""/>
                    </div>
                    <figcaption className="personal-figcaption">
                        <img src="https://raw.githubusercontent.com/ThiagoLuizNunes/angular-boilerplate/master/src/assets/imgs/camera-white.png" alt='imagem da camera' />
                    </figcaption>
                </figure>
            </label>
        </div>
        </>
    );
}

export default memo(Avatar)