import React, { memo, useMemo } from 'react';
import Card, { CardActions, CardBody, CardHeader, CardLabel, CardTitle } from '../../../components/bootstrap/Card';
import Money from '../../../helpers/utils/money/money';
import useDarkMode from '../../../hooks/useDarkMode';
import TableCustom from '../../../components/MyCustom/TableCustom';

const ComissaoVendedor = ({ sellers }) => {
  const { darkModeStatus } = useDarkMode();

  const columns = useMemo(() => ([
    {
      label: 'Nome',
      field: 'name',
    },
    {
      label: 'Qtd. Eventos',
      field: 'qtdEvent',
    },
    {
      label: 'Total Faturado',
      field: 'totalInvoiced',
      format: row => Money.centsToMaskMoney(row.totalInvoiced),
      formatExport: value => Money.centsToCoinCsv(value),
    },
    {
      label: 'Comissão sobre taxas',
      field: 'tax_commission',
      format: row => Money.centsToMaskMoney(row.tax_commission),
      formatExport: value => Money.centsToCoinCsv(value),
    },
    {
      label: 'Comissão sobre outras receitas',
      field: 'other_recipes_commission',
      format: row => Money.centsToMaskMoney(row.other_recipes_commission),
      formatExport: value => Money.centsToCoinCsv(value),
    },
  ]), [])

  const sellersRender = useMemo(() => {
    const dictionary = {};
    sellers.forEach(s => {
      if (dictionary[s.id]) {
        dictionary[s.id].tax_commission += s.tax_commission;
        dictionary[s.id].other_recipes_commission += s.other_recipes_commission;
        dictionary[s.id].qtdEvent++;
        dictionary[s.id].totalInvoiced += s.tax_commission + s.other_recipes_commission;
      } else {
        dictionary[s.id] = s;
        dictionary[s.id].qtdEvent = 1;
        dictionary[s.id].totalInvoiced = s.tax_commission + s.other_recipes_commission;
      }
    })
    return Object.keys(dictionary).map(key => dictionary[key]);
  }, [sellers])

  return (
    <Card>
      <CardHeader>
        <CardLabel icon='Person' iconColor={!darkModeStatus ? 'dark' : 'light'}>
          <CardTitle className='d-flex flex-row align-items-center'>
            <CardActions>
              COMISSÃO DE VENDEDOR
            </CardActions>
          </CardTitle>
        </CardLabel>
      </CardHeader>
      <CardBody className='table-responsive p-5'>
        <TableCustom
          titlePdf=' COMISSÃO DE VENDEDOR'
          icon="Person"
          rows={sellersRender}
          columns={columns}
          fileName={'Comissão-de-vendedor'}
        />
      </CardBody>
    </Card>
  );
}

export default memo(ComissaoVendedor);

