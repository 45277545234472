import React, { useMemo } from "react";
import Money from "../../../helpers/utils/money/money";


const FirstPage = ({ eventSelected, establishment, eventGeral, closureResourcesEvent, equipaments }) => {

  const rowsMateriais = useMemo(() => {
    if(!equipaments) return []
    return equipaments.filter(row => 
      ['pos_Smart', 'totems', 'totems_recharges',].includes(row.key)
    );
  },[equipaments])

  if(!establishment || !eventGeral || !closureResourcesEvent) return null;

  return (
    <div className='d-flex flex-column col-xxl-12 justify-content-around mt-4" '>
      <p style={{ textAlign: 'justify' }}>Objeto do presente termo é o instrumento de vistoria dos equipamentos, e/ou 
        máquinas cedidas em comodato, e de acordo na prestação de contas dos valores intermediados e dos serviços prestados
         referente ao contrato de número: <strong><mark>{establishment?.codeNumber}</mark></strong>, 
        ordem de serviço de número: <strong><mark>{eventSelected?.codeNumber}</mark></strong>, celebrado entre a uGet e o
        CLIENTE <strong><mark>{establishment?.name}</mark></strong> , inscrito no CNPJ/CPF sob nº:
        <strong><mark>{establishment?.cnpj}</mark></strong>,
        localizado na <strong><mark>{establishment?.address?.street}, {establishment?.address?.number} - {establishment?.address?.neighborhood}</mark></strong>,
        na cidade de <strong><mark>{establishment?.address?.city}</mark></strong>, no estado de <strong><mark>{establishment?.address?.state}</mark></strong>,
        CEP <strong><mark>{establishment?.address?.zip_code}.</mark></strong>
      </p>

      <p style={{ textAlign: 'justify' }}>
        Qualquer discordância ou questionamentos acerca do presente termo deverá ser comunicada ao Comodante,
        por escrito, dentro do prazo de  <strong><mark> 03 (três)</mark></strong> dias.
      </p>

      <p style={{ textAlign: 'justify ' }}>
      <strong>VISTORIA DOS EQUIPAMENTOS</strong>  
      </p>
      <p style={{ textAlign: 'justify' }}>
      A tabela abaixo, enumera a quantidade de equipamentos entregues ao CLIENTE em perfeito estado no modelo comodato, a quantidade devolvida em perfeito estado de conservação e 
        funcionamento, com todos os seus pertences, utensílios e acessórios, assim como a quantidade devolvida com danos e a quantidade não devolvida.
      </p>

    
    </div>
  );
}

export default FirstPage;