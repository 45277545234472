import React, { memo, useEffect, useMemo, useState, useContext } from 'react'
import Card, { CardHeader, CardLabel, CardTitle, CardBody, CardActions } from '../../../components/bootstrap/Card';
import CardValue from '../../../components/MyCustom/Dashboard/CardValue';
import useDarkMode from '../../../hooks/useDarkMode';
import Money from '../../../helpers/utils/money/money';
import Popovers from '../../../components/bootstrap/Popovers';
import Icon from '../../../components/icon/Icon';
import { DefaultContext } from '../../../contexts/default';


const QtdEtorno = ({ recarga, products }) => {
  const { event } = useContext(DefaultContext)
  const [isTicket, setIsTicket] = useState(false)

  useEffect(() => {
    if (event) {
      let isTicket = event?.types.ticket;
      setIsTicket(isTicket)
    }
  }, [event])

  const { darkModeStatus } = useDarkMode();

  const qtdProductsChargeback = useMemo(() => 
    products.reduce((prev, curr) => prev + curr.qtdReversed, 0)
  , [products])

  const valueRefundProducts = useMemo(() => 
    products.reduce((prev, curr) => prev + (curr.qtdReversed * curr.price), 0)
  , [products])

  return (
    <Card stretch>
      <CardHeader>
        <CardLabel icon='TableRows' iconColor={!darkModeStatus ? 'dark' : 'light'}>
          <CardTitle className='d-flex flex-row align-items-center'>
            <CardActions>
             ESTORNO DE PRODUTOS
            </CardActions>
            <Popovers trigger={'hover'} desc={<b>Quantidade de estorno.</b>}><Icon className='mx-3' size='2x' icon='Info' /></Popovers>
          </CardTitle>
        </CardLabel>
      </CardHeader>
      <CardBody className='d-flex flex-column'>
        <div className='col align-items-center' >
          {isTicket == false && <>
            <div className='col-md-12' >
              <CardValue
                color='orange_dark'
                description='Quantidade de produtos estornados.'
                label='QTD. ESTORNO DE PRODUTOS'
                value={recarga?.qtdChargebackRecharges || '0'}
              />
            </div>
            <div className='col-md-12' >
              <CardValue
                color='warning'
                description='Valor de produtos estornados.'
                label='VALOR ESTORNADO  DE PRODUTOS'
                value={Money.centsToMaskMoney(recarga?.estornoRecarga || '0')}
              />
            </div>
          </>}
          {/* <div className='col-md-12' >
            <CardValue
              color='orange_dark'
              description='Quantidade de produtos estornados.'
              label='ESTORNO DE PRODUTOS'
              value={qtdProductsChargeback}
            /></div>
          <div className='col-md-12' >
            <CardValue
              color='warning'
              description='Valor de produtos estornados.'
              label='VALOR ESTORNO DE PRODUTOS'
              value={Money.centsToMaskMoney(valueRefundProducts || '0')}
            />
          </div> */}
        </div>
      </CardBody>
    </Card>
  )
}

export default memo(QtdEtorno)
