import React, { memo, useMemo } from 'react';
import Card, { CardActions, CardBody, CardHeader, CardLabel, CardTitle } from '../../../components/bootstrap/Card';
import Money from '../../../helpers/utils/money/money';
import useDarkMode from '../../../hooks/useDarkMode';
import TableCustom from '../../../components/MyCustom/TableCustom';

const ComissaoVendedor = ({ seller }) => {
  const { darkModeStatus } = useDarkMode();

  const rows = useMemo(()=> {
    return[
    {
      name: 'Comissão sobre Taxa',
      tax:seller?.commissionFee || 0
    },
    {
      name: 'Comissão sobre Outras Receitas',
      tax:seller?.commissionMaterial || 0
    },
    {
      name: 'Comissão sobre Taxa',
      tax:seller?.commissionFee || 0
    },
    {
      name: 'Total a Receber',
      tax:seller?.total_receivable || 0
    },
  ]
  },[seller])
   


const columns = useMemo(() => ([
  {
    label: 'Nome',
    field: 'name',
  },
  {
    label: 'Tax',
    field: 'tax',
    format: row => Money.centsToCoinCsv(row.tax)
  },
 
]))

  return (
    <Card>
      <CardHeader>
        <CardLabel icon='Person' iconColor={!darkModeStatus ? 'dark' : 'light'}>
          <CardTitle className='d-flex flex-row align-items-center'>
            <CardActions>
              COMISSÃO VENDEDOR ({seller?.name.toUpperCase() || ''})
            </CardActions>
          </CardTitle>
        </CardLabel>
      </CardHeader>
      <CardBody className='table-responsive p-5'>
      <TableCustom  rows={rows} columns={columns} noShowPagination fileName={'Comissão-vendedor'} />
        {/* <table className='table table-borderless table-hover'>
          <tbody>
            <tr>
              <td>Comissão sobre Taxa</td>
              <td>{Money.centsToMaskMoney(seller?.commissionFee || 0)}</td>
            </tr>
            <tr>
              <td>Comissão sobre Outras Receitas</td>
              <td>{Money.centsToMaskMoney(seller?.commissionMaterial || 0)}</td>
            </tr>
            <tr className='table-active fw-bold'>
              <td>Total a Receber</td>
              <td>{Money.centsToMaskMoney(seller?.total_receivable || 0)}</td>
            </tr>
          </tbody>
        </table> */}
      </CardBody>
    </Card>
  );
}

export default memo(ComissaoVendedor);