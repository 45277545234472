import React, { useCallback, useContext, useEffect, useState } from 'react';
import UserDB from "../../../database/wrappers/user";
import ConfigUgetDB from '../../../database/wrappers/configUget';
import { DefaultContext } from '../../../contexts/default';
import api from '../../../services/api';
import { BASIC_ROLE } from '../../../types/roles';
import Money from '../../../helpers/utils/money/money';
// const numero = require('numero-por-extenso');

const BodyContract = ({ event, osData }) => {
  // const [reponsableAccess, setReponsableAccess] = useState({})
  
    const {establishment} = useContext(DefaultContext );
    const [materialCost, setMaterialCost] = useState({});
    const [users, setUsers] = useState({})
    const [custoOperacional, setCustoOperacional] = useState(null)

    const getUsers = useCallback(() => {
        api.get('routes/users')
          .then(res => {
            const users = [];
            res.data.forEach(user => {
              
              if (user.customClaims.role === BASIC_ROLE.MANAGER)
                users.push(user)
            });
            users.forEach((userss) => {
              
                const user = userss.customClaims.estabs.find((key) => key === establishment.id)
                if(user){       
                    setUsers(userss)
                }        
            })          
          })
          
      },[])

    useEffect(() => {
        getUsers()
         new ConfigUgetDB().get('custoMaterial')
            .then(data => {
                setMaterialCost(data);
            })
            .catch(error => {
                console.log(error);
            })  
            
        new ConfigUgetDB()
            .get("custoOperacional")
            .then((data)=>{
                setCustoOperacional(data)
            })
    },[])

    const styleParagraph = '  text-justify; my-2 '
    const styleTitle = 'my-3 fw-bold'
    const styleBold = 'fw-bold'

    function formatarMoeda(valor) {
        valor = valor / 100;
        return valor.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' });
    }
   
    return (
        
            <div className='p-2'>
                <h5 className={styleTitle}>
                    1. DADOS DA EMPRESA ORGANIZADORA
                </h5>

                <p className={styleParagraph}>
                    <ul>
                        <li>
                            Nome Fantasia: {establishment?.fantasy_name}

                        </li>
                        <li>
                            Razão Social: {establishment.reason}
                        </li>
                        <li>
                            CNPJ da Empresa: {establishment?.cnpj}
                        </li>
                        <li>
                            Inscrição Estadual: {establishment?.stateRegistration}
                        </li>
                        <li>
                            Inscrição Municipal: {establishment?.municipalRegistration}
                        </li>
                        <li>
                            Endereço da Empresa:  {establishment?.address?.street}, {establishment?.address?.number}, {establishment?.address?.neighborhood}
                        </li>
                        <li>
                            Cep: {establishment?.address?.zip_code}
                        </li>

                        <li>
                            Cidade / Estado: {establishment?.address?.city}, {establishment?.address?.state}
                        </li>
                    </ul>
                </p>

                <h5 className={styleTitle}>
                    2. DADOS DO REPRESENTANTE LEGAL DA EMPRESA ORGANIZADORA
                </h5>
                <p className={styleParagraph}>
                    <ul>
                        <li>
                            Representante Legal da Empresa: {establishment?.representative?.personal_name}
                        </li>
                        <li>
                            CPF do Representante Legal: {establishment?.representative?.personal_cpf}
                        </li>
                        <li>
                            Cédula de identidade: {establishment?.representative?.personal_rg}
                        </li>
                        <li>
                            Endereço: {establishment?.representative?.personal_street}, {establishment?.representative?.personal_number} {establishment?.representative?.personal_complement}
                        </li>
                        <li>
                            Cep: {establishment?.representative?.personal_zip_code}
                        </li>
                        <li>
                            Cidade / Estado: {establishment?.representative?.personal_city}/{establishment?.representative?.personal_state}
                        </li>
                    </ul>
                </p>


                <h5 className={styleTitle}>
                    3. DADOS BANCÁRIOS DA EMPRESA / ORGANIZADORA
                </h5>

                <p className={styleParagraph}>
                    <ul>
                        <li>
                            Banco: {establishment?.bankAccount?.bank}
                        </li>

                        <li>
                            Código do Banco: {establishment?.bankAccount?.bankCode}
                        </li>

                        <li>
                            Agência: {establishment?.bankAccount?.agency}
                        </li>

                        <li>
                            Conta: {establishment?.bankAccount?.account}
                        </li>

                        <li>
                            PIX: {establishment?.bankAccount?.pix}
                        </li>

                    </ul>
                </p>

                <h5 className={styleTitle}>
                    4. DADOS DO RESPONSÁVEL DURANTE O EVENTO
                </h5>

                <p className={styleParagraph}>
                    <ul>
                        <li>
                            Nome Completo do Responsável: {establishment?.representative?.personal_name}
                        </li>
                        <li>
                            CPF do Responsável:  {establishment?.representative?.personal_cpf}
                        </li>
                        <li>
                            E-mail do Responsável: {establishment?.email}
                        </li>
                        <li>
                            Celular do Responsável: {establishment?.phone}
                        </li>
                    </ul>
                </p>

                <h5 className={styleTitle}>
                    5. DADOS DO EVENTO
                </h5>

                <p className={styleParagraph}>
                    <ul>
                        <li>
                            Nome do Evento:  {event?.name}
                        </li>
                        <li>
                            Local do evento:  {event?.place?.placeName}
                        </li>
                        <li>
                            Endereço do evento: {event?.place?.street} {event?.place?.number}-{event?.place?.complement}
                        </li>
                        <li>
                            Cep:{event?.place?.zip_code}.
                        </li>
                        <li>
                            Cidade / Estado: {event?.place?.city}/{event?.place?.state}
                        </li>
                        <li>
                            Data e Hora do Início: {new Date(event?.date.seconds * 1000).toLocaleString('pt-BR', { timeZone: 'America/Sao_Paulo' })}
                        </li>

                        <li>
                            Data e Hora do Término:  {new Date(event?.endDate.seconds * 1000).toLocaleString('pt-BR', { timeZone: 'America/Sao_Paulo' })}
                        </li>
                    </ul>
                </p>

                <h5 className={styleTitle } >
                    <h4 className='pagebreak'></h4>
                    6. ACESSOS SISTEMA – DASBOARD uGet ;-)
                </h5>
                <p className={styleParagraph}>
                    <ul>
                        <li>
                            Nome:  {users && users?.displayName}
                        </li>
                        {/* <li>
                            Celular: {reponsableAccess && reponsableAccess?.phone}
                        </li> */}
                        <li>
                            E-mail: {users && users?.email}
                        </li>
                        <li>
                            Tipo de Acesso: {users && users?.customClaims?.role}
                        </li>
                    </ul>
                </p>

                <h5 className={styleTitle}>
                    7. DESCRITIVOS TÉCNICO
                </h5>
                <p className={styleParagraph}>
                    <ul>
                        {osData?.resources?.pos > 0 &&
                            <>
                                <li>
                                    Quantidade de dispositivos TERMINAIS SMART POS: < span className='fw-bold'>{osData?.resources?.pos}</span>
                                </li>
                                <li>
                                    Valor por dispositivo SMART POS: < span className='fw-bold'>{formatarMoeda(osData?.resources?.value_pos || 0)}</span>
                                </li>
                                <li>
                                    Valor por dispositivo SMART POS não devolvido ou danificado: <span className='fw-bold'>{formatarMoeda(materialCost.pos || 0)}</span>
                                </li>
                            </>
                        }
                         {osData?.resources?.pos_power > 0 &&
                            <>
                                <li>
                                    Quantidade de dispositivos  CARREGADOR da POS: < span className='fw-bold'>{osData?.resources?.pos_power}</span>
                                </li>
                                <li>
                                    Valor por dispositivo CARREGADOR DA POS: < span className='fw-bold'>{formatarMoeda(osData?.resources?.resources?.value_pos_power || 0)}</span>
                                </li>
                                <li>
                                    Valor por dispositivo CARREGADOR DA POS não devolvido ou danificado: <span className='fw-bold'>{formatarMoeda(materialCost.pos_power || 0)}</span>
                                </li>
                            </>
                        }
                        {osData?.resources?.totems > 0 &&
                            <>
                                <li>
                                    Quantidade de TOTENS DE AUTOATENDIMENTO TICKET: < span className='fw-bold'>{osData?.resources?.totems} </span>
                                </li>
                                <li>
                                    Valor por dispositivo TOTENS DE AUTOATENDIMENTO TICKET: < span className='fw-bold'>{formatarMoeda(osData?.resources?.value_totems || 0)}</span>
                                </li>
                                <li>
                                    Valor por dispositivo TOTENS DE AUTOATENDIMENTO TICKET não devolvido ou danificado: < span className='fw-bold'>{formatarMoeda(materialCost.totems || 0)}</span>
                                </li>
                            </>
                        }
                        {osData?.resources?.totems_recharges > 0 &&
                            <>
                                <li>
                                    Quantidade de TOTENS DE AUTOATENDIMENTO RECARGA: < span className='fw-bold'>{osData?.resources?.totems_recharges}</span>
                                </li>
                                <li>
                                    Valor por dispositivo TOTENS DE AUTOATENDIMENTO RECARGA: < span className='fw-bold'>{formatarMoeda(osData?.resources?.value_totems_recharges || 0)}</span>
                                </li>
                                <li>
                                    Valor por dispositivo TOTENS DE AUTOATENDIMENTO RECARGA não devolvido ou danificado: < span className='fw-bold'>{formatarMoeda(materialCost.totems_recharges || 0)}</span>
                                </li>
                            </>
                        }
                        {osData?.resources?.cards > 0 &&
                            <>
                                <li>
                                    Quantidade de CARTÕES DE CONSUMO comodato: < span className='fw-bold'>{osData?.resources?.cards}</span>
                                </li>
                                <li>
                                    Valor de CARTÕES DE CONSUMO comodato: < span className='fw-bold'>{formatarMoeda(osData?.resources?.value_cards || 0)}</span>
                                </li>
                                <li>
                                    Valor unitário CARTÃO DE CONSUMO não devolvido ou danificado: < span className='fw-bold'>{formatarMoeda(materialCost.cards || 0)}</span>
                                </li>
                            </>
                        }
                         {osData?.resources?.cards_adm > 0 &&
                            <>
                                <li>
                                    Quantidade de CARTÕES DE ADMINISTRADORES : < span className='fw-bold'>{osData?.resources?.cards_adm}</span>
                                </li>
                                <li>
                                    Valor de CARTÕES DE ADMINISTRADORES: < span className='fw-bold'>{formatarMoeda(osData?.resources?.value_cards_adm || 0)}</span>
                                </li>
                                <li>
                                    Valor unitário CARTÃO DE ADMINISTRADOR não devolvido ou danificado: < span className='fw-bold'>{formatarMoeda(materialCost.cards_adm || 0)}</span>
                                </li>
                            </>
                        }
                        {osData?.resources?.bracelets > 0 &&
                            <>
                                <li>
                                    Quantidade de Pulseiras: < span className='fw-bold'>{osData?.resources?.bracelets}</span>
                                </li>
                                <li>
                                    Valor de Pulseiras: < span className='fw-bold'> {formatarMoeda(osData?.resources?.value_bracelets || 0)}</span>
                                </li>
                                <li>
                                    Valor unitário Pulseiras não devolvido ou danificado: < span className='fw-bold'>{formatarMoeda(materialCost?.bracelets || 0)}</span>
                                </li>
                            </>
                        }
                        {osData?.resources?.totems_cables > 0 &&
                            <>
                                <li>
                                    Quantidade de Cabo de Totems: < span className='fw-bold'> {osData?.resources?.totems_cables}</span>
                                </li>
                                <li>
                                    Valor de Cabo de Totems: < span className='fw-bold'>{formatarMoeda(osData?.resources?.value_totems_cables || 0)}</span>
                                </li>
                                <li>
                                    Valor unitário Cabo de Totems não devolvido ou danificado: < span className='fw-bold'>{formatarMoeda(materialCost?.totems_cables || 0)}</span>
                                    totems_cables
                                </li>
                            </>
                        }
                        {osData?.resources?.totems_cuffs > 0 &&
                            <>
                                <li>
                                    Quantidade de Algemas de TOTENS: < span className='fw-bold'>{osData?.resources?.totems_cuffs}</span>
                                </li>
                                <li>
                                    Valor por Algemas de TOTENS: < span className='fw-bold'>{formatarMoeda(osData?.resources?.value_totems_cuffs)}</span>
                                </li>
                                <li>
                                    Valor por Algemas de TOTENS não devolvido ou danificado: < span className='fw-bold'>{formatarMoeda(materialCost?.totems_cuffs)}</span>
                                </li>
                            </>
                        }
                        {osData?.resources?.totems_pedestal > 0 &&
                            <>
                                <li>
                                    Quantidade de Pedestal de TOTENS: < span className='fw-bold'>{osData?.resources?.totems_pedestal}</span>
                                </li>
                                <li>
                                    Valor por Pedestal de TOTENS: < span className='fw-bold'>{formatarMoeda(osData?.resources?.value_totems_pedestal)}</span>
                                </li>
                                <li>
                                    Valor por Pedestal de TOTENS não devolvido ou danificado: < span className='fw-bold'>{formatarMoeda(materialCost?.totems_pedestal)}</span>
                                </li>
                            </>
                        }
                        {osData?.resources?.totems_cover > 0 &&
                            <>
                                <li>
                                    Quantidade de carcaça de TOTENS: < span className='fw-bold'>{osData?.resources?.totems_cover}</span>
                                </li>
                                <li>
                                    Valor por carcaça de TOTENS: < span className='fw-bold'>{formatarMoeda(osData?.resources?.value_totems_cover)}</span>
                                </li>
                                <li>
                                    Valor por carcaça de TOTENS não devolvido ou danificado: < span className='fw-bold'>{formatarMoeda(materialCost?.totems_cover)}</span>
                                </li>
                            </>
                        }
                        {osData?.resources?.coordinators > 0 &&
                            <>
                                <li>
                                    Quantidade de Coordenadores por diária: < span className='fw-bold'>{osData?.resources?.coordinators}</span>
                                </li>
                                <li>
                                    Valor unitário por diária de Coordenadores pelo período 10 horas: < span className='fw-bold'>{formatarMoeda(custoOperacional?.daily.coordinator || "0,00")}</span>
                                </li>
                                <li>
                                    Valor unitário por hora adicional a partir da 10 hora Coordenadores: < span className='fw-bold'>{formatarMoeda(custoOperacional?.hours.coordinator || "0,00")}</span>
                                </li>
                            </>
                        }

                        {osData?.resources?.technical > 0 &&
                            <>
                                <li>
                                    Quantidade de TÉCNCICOS por diária: < span className='fw-bold'>{osData?.resources?.technical}</span>
                                </li>
                                <li>
                                    Valor unitário por TÉCNCICOS pelo período 10 horas: < span className='fw-bold'>{formatarMoeda(custoOperacional?.daily.technical || '0,00')}</span>
                                </li>
                                <li>
                                    Valor unitário por TÉCNICOS por hora adicional: < span className='fw-bold'>{formatarMoeda(custoOperacional?.hours.technical || "0,00")}</span>
                                </li>
                            </>
                        }
                        {osData?.resources?.bobines > 0 &&
                            <>
                                <li>
                                    Quantidade de Bobinas: < span className='fw-bold'>{osData?.resources?.bobines}</span>
                                </li>
                                <li>
                                    Valor de Bobinas: < span className='fw-bold'>{formatarMoeda(osData?.resources?.value_bobines)}</span>
                                </li>
                                <li>
                                    Valor de Bobinas não devolvido ou danificado: < span className='fw-bold'>{formatarMoeda(materialCost?.bobines || 0)}</span>
                                </li>
                            </>
                        }
                        {osData?.resources?.extension > 0 &&
                            <>
                                <li>
                                    Quantidade de Extensão: < span className='fw-bold'>{osData?.resources?.extension}</span>
                                </li>
                                <li>
                                    Valor por Extensão: < span className='fw-bold'>{formatarMoeda(osData?.resources?.value_extension)}</span>
                                </li>
                                <li>
                                    Valor por Extensão não devolvido ou danificado: < span className='fw-bold'>{formatarMoeda(materialCost?.extension)}</span>
                                </li>
                            </>
                        }
                        {osData?.resources?.ruler > 0 &&
                            <>
                                <li>
                                    Quantidade de Régua: <span className='fw-bold'>{osData?.resources?.ruler}</span>
                                </li>
                                <li>
                                    Valor por Régua: <span className='fw-bold'>{formatarMoeda(osData?.resources?.value_ruler)}</span>
                                </li>
                                <li>
                                    Valor por Régua não devolvido ou danificado: <span className='fw-bold'>{formatarMoeda(materialCost?.ruler || 0)}</span>
                                </li>
                            </>
                        }

                        { osData?.resources?.kit_cordao > 0 &&
                            <>
                                <li>
                                    Quantidade Kit Cordão: <span className='fw-bold'>{osData?.resources?.kit_cordao}</span>
                                </li>
                                <li>
                                    Valor kit Cordão: <span className='fw-bold'>{formatarMoeda(osData?.resources?.value_kit_cordao)}</span>
                                </li>
                                <li>
                                    Valor por kit Cordão não devolvido ou danificado: <span className='fw-bold'>{formatarMoeda(materialCost?.kit_cordao || 0)}</span>
                                </li>
                            </>

                        }
                        
                        {osData?.resources?.pouch > 0 &&
                            <>
                                <li>
                                    Quantidade de Malote: <span className='fw-bold'>{osData?.resources?.pouch}</span>
                                </li>
                                <li>
                                    Valor por Malote: <span className='fw-bold'>{formatarMoeda(osData?.resources?.value_pouch)}</span>
                                </li>
                                <li>
                                    Valor por Malote não devolvido ou danificado: < span className='fw-bold'>{formatarMoeda(materialCost?.pouch || 0)}</span>
                                </li>
                            </>
                        }
                        {osData?.resources?.tablet > 0 &&
                            <>
                                <li>
                                    Quantidade de Tablet: < span className='fw-bold'>{osData?.resources?.tablet}</span>
                                </li>
                                <li>
                                    Valor por Tablet: < span className='fw-bold'>{formatarMoeda(osData?.resources?.value_tablet)}</span>
                                </li>
                                <li>
                                    Valor por Tablet não devolvido ou danificado: < span className='fw-bold'>{formatarMoeda(materialCost?.tablet || 0)}</span>
                                </li>
                            </>
                        }
                        {osData?.resources?.tablet_power > 0 &&
                            <>
                                <li>
                                    Quantidade de Carregador Tablet: < span className='fw-bold'>{osData?.resources?.tablet_power}</span>
                                </li>
                                <li>
                                    Valor por Carregador Tablet: < span className='fw-bold'>{formatarMoeda(osData?.resources?.value_tablet_power)}</span>
                                </li>
                                <li>
                                    Valor por Carregador Tablet não devolvido ou danificado: < span className='fw-bold'>{formatarMoeda(materialCost?.tablet_power || 0)}</span>
                                </li>
                            </>
                        }
                        {osData?.resources?.transport_box > 0 &&
                            <>
                                <li>
                                    Quantidade de Caixa para Transporte: < span className='fw-bold'>{osData?.resources?.transport_box}</span>
                                </li>
                                <li>
                                    Valor por Caixa para Transporte: < span className='fw-bold'>{formatarMoeda(osData?.resources?.value_transport_box)}</span>
                                </li>
                                <li>
                                    Valor por Caixa para Transporte não devolvido ou danificado: < span className='fw-bold'>{formatarMoeda(materialCost?.transport_box)}</span>
                                </li>
                            </>
                        }
                        {osData?.resources?.router_wifi > 0 &&
                            <>
                                <li>
                                    Quantidade de Roteador Wi-Fi: < span className='fw-bold'>{osData?.resources?.router_wifi}</span>
                                </li>
                                <li>
                                    Valor por Roteador Wi-Fi: < span className='fw-bold'>{formatarMoeda(osData?.resources?.value_router_wifi)}</span>
                                </li>
                                <li>
                                    Valor por Roteador Wi-Fi não devolvido ou danificado: < span className='fw-bold'>{formatarMoeda(materialCost?.router_wifi)}</span>
                                </li>
                            </>
                        }
                        {osData?.resources?.processor_wifi > 0 &&
                            <>
                                <li>
                                    Quantidade de Switch: < span className='fw-bold'>{osData?.resources?.processor_wifi}</span>
                                </li>
                                <li>
                                    Valor por Switch: < span className='fw-bold'>{formatarMoeda(osData?.resources?.value_processor_wifi)}</span>
                                </li>
                                <li>
                                    Valor por Switch não devolvido ou danificado: < span className='fw-bold'>{formatarMoeda(materialCost?.processor_wifi)}</span>
                                </li>
                            </>
                        }
                        {osData?.resources?.tripod_wifi > 0 &&
                            <>
                                <li>
                                    Quantidade de Tripé Wifi: < span className='fw-bold'>{osData?.resources?.tripod_wifi}</span>
                                </li>
                                <li>
                                    Valor por Tripé Wifi: < span className='fw-bold'>{formatarMoeda(osData?.resources?.value_tripod_wifi)}</span>
                                </li>
                                <li>
                                    Valor por Tripé Wifi não devolvido ou danificado: < span className='fw-bold'>{formatarMoeda(materialCost?.tripod_wifi || 0)}</span>
                                </li>
                            </>
                        }
                        {osData?.resources?.cable_lan > 0 &&
                            <>
                                <li>
                                    Quantidade de Cabo LAN: < span className='fw-bold'>{osData?.resources?.cable_lan}</span>
                                </li>
                                <li>
                                    Valor por Cabo LAN: < span className='fw-bold'>{formatarMoeda(osData?.resources?.value_cable_lan)}</span>
                                </li>
                                <li>
                                    Valor por Cabo LAN não devolvido ou danificado: < span className='fw-bold'>{formatarMoeda(materialCost?.cable_lan)}</span>
                                </li>
                            </>
                        }
                    </ul>
                </p>

                <h5 className={styleTitle}>
                    8. CONDIÇÕES COMERCIAIS
                </h5>

                <p className={styleParagraph}>
                    8.1. <span className='fw-bold'> REMUNERAÇÃO UGET SOBRE O LICENCIAMENTO DE SOFTWARE E INTERMEDIAÇÃO</span>
                </p>
                <p className={styleParagraph}>
                    8.1.1.  A remuneração da UGET sobre o LICENCIAMENTO DE SOFTWARE E INTERMEDIAÇÃO tem o valor
                    mínimo garantido calculado sobre a quantidade de licenças dos TERMINAIS POS SMART descritas
                    na clausula 7, totalizando o valor de <span className='fw-bold'>   {formatarMoeda((osData?.resources?.value_pos || 0) * osData?.resources?.pos)} </span> por diária de evento.
                </p>
                <p className={styleParagraph}>
                    8.1.2.  . Caso a somatória dos valores oriundos dos percentuais cobrados das transações arrecadadas no evento
                    nas funções descritas nas clausulas <span className='fw-bold'>8.1.3. - 8.1.4. - 8.1.5 - 8.1.6. - 8.1.7. - 8.1.8. - 8.1.9.</span>, supere o valor mínimo
                    estabelecido na clausula <span className='fw-bold'>8.1.1.</span> prevalece o maior valor como remuneração da UGET sobre o
                    LICENCIAMENTO DE SOFWARE E INTERMEDIAÇÃO.
                </p>
                <p className={styleParagraph}>
                    8.1.3. . A remuneração da UGET referente ao LICENCIAMENTO DE SOFTWARE E INTERMEDIAÇÃO é de <span className='fw-bold'>
                        {osData?.rate?.cash}% </span>  sobre o valor total arrecadado na função DINHEIRO no evento.
                </p>
                <p className={styleParagraph}>
                    8.1.4. A remuneração da UGET referente ao LICENCIAMENTO DE SOFTWARE E INTERMEDIAÇÃO é de <span className='fw-bold'>
                        {osData?.rate?.debit}% </span> sobre o valor total arrecadado na função DÉBITO no evento.
                </p>
                <p className={styleParagraph}>
                    8.1.5.  . A remuneração da UGET referente ao LICENCIAMENTO DE SOFTWARE E INTERMEDIAÇÃO é de <span className='fw-bold'>
                        {osData?.rate?.pix}% </span> sobre o valor total arrecadado na função PIX no evento.
                </p>
                <p className={styleParagraph}>
                    8.1.6.  A remuneração da UGET referente ao LICENCIAMENTO DE SOFTWARE E INTERMEDIAÇÃO é de <span className='fw-bold'>
                        {osData?.rate?.credit}%</span> sobre o valor total arrecadado na função CRÉDITO (a vista) no evento.
                </p>
                <p className={styleParagraph}>
                    8.1.7. A remuneração da UGET referente ao LICENCIAMENTO DE SOFTWARE E INTERMEDIAÇÃO é de <span className='fw-bold'>
                        {osData?.rate?.courtesy}% </span> sobre o valor total arrecadado na função CORTESIA no evento.
                </p>
                <p className={styleParagraph}>
                    8.1.8. A remuneração da UGET referente ao LICENCIAMENTO DE SOFTWARE E INTERMEDIAÇÃO é de <span className='fw-bold'>
                        {osData?.rate?.advance_recharge}% </span> sobre o valor total arrecadado na função RECARGA ANTECIPADA no evento.
                </p>
                <p className={styleParagraph}>
                    8.1.9.  A remuneração da UGET referente ao LICENCIAMENTO DE SOFTWARE E INTERMEDIAÇÃO é de <span className='fw-bold'>
                        {osData?.rate?.advance_credit}% </span> sobre o valor total arrecadado na função CRÉDITO ANTECIPADA no evento.
                </p>

                <h5 className={styleTitle} >
                <h4 className='pagebreak'></h4>
                    8.2.SERVIÇOS ADICIONAIS CONTRADADOS
                </h5>
                
                <p className={styleParagraph}>
                    8.2.1. Valores referente aos serviços técnicos de suporte local durante o evento realizado por {(osData?.resources.coordinators)} supervisor e {( osData?.resources.technical)}  
                {' '} técnico, total durante o período de 10 horas de evento< span className='fw-bold'> {Money.centsToMaskMoney(((custoOperacional?.daily.coordinator * osData?.resources.coordinators) + (custoOperacional?.daily.technical * osData?.resources.technical)))} .
                    </span>
                </p>
                <p className={styleParagraph}>
                    8.2.2.  A diária de alimentação dos supervisores e técnicos é de responsabilidade do CLIENTE.
                </p>
                <p className={styleParagraph}>
                    8.2.3.  O transporte e estadia no caso de eventos fora da cidade de São Paulo são de responsabilidade do
                    CLIENTE.
                </p>

                <h5 className={styleTitle}>
                    9. PRAZO DE PAGAMENTO DO REPASSE
                </h5>

                <p className={styleParagraph}> 9.1.
                    Prazo para pagamento dos valores arrecadados por meio de < span className='fw-bold'>Débito e Pix </span> através da plataforma de
                    intermediação de recebíveis do sistema uGet PDX se dará (D+2) 2 dias úteis após o término do evento,
                    mediante a compensação e aprovação realizada pela adquirente. O prazo de pagamento dos valores
                    arrecadados por meio de < span className='fw-bold'>Crédito</span> através da plataforma de intermediação de recebíveis do
                    sistema uGet PDX se dará (D+31) <span className='fw-bold'>31</span> dias úteis após o término do evento,
                    mediante a  compensação e aprovação realizada pela adquirente, ambas por meio transferência para a
                    conta de Domicílio Bancário do CLIENTE (“TED”).
                    <span className='fw-bold'>3.1.</span>
                </p>

                <p className={styleParagraph}>
                    9.2.  Caso o CLIENTE opte pela taxa de antecipação cláusula 10.1. o prazo de pagamento dos
                    valores arrecadados por meio de <span className='fw-bold'>Crédito </span> através da plataforma de intermediação
                    de recebíveis, sistema uGet PDX se dará <span className='fw-bold'>3</span>  dias úteis após a data do término do evento, por meio
                    de transferência para a conta de Domicílio Bancário do Cliente (“TED”) OU (“PIX”) .
                </p>

                <p className={styleParagraph}>
                    9.3. Domicílio bancário do contratante para o deposito do repasse disposta na clausula 3.<span className='fw-bold'> DADOS
                        BANCARIOS DA EMPRESA / ORGANIZADORA</span>

                    <ul>
                        <li>
                            Banco: {establishment?.bankAccount?.bank}
                        </li>

                        <li>
                            Código do Banco: {establishment?.bankAccount?.bankCode}
                        </li>

                        <li>
                            Agência: {establishment?.bankAccount?.agency}
                        </li>

                        <li>
                            Conta:  {establishment?.bankAccount?.account}
                        </li>

                        <li>
                            PIX:  {establishment?.bankAccount?.pix}
                        </li>
                    </ul>
                </p>

                <h5 className={styleTitle}>
                    10. OUTRAS TAXAS E CONDIÇÕES
                </h5>

                <p className={styleParagraph}>
                    10.1. . Para a antecipação do recebimento integral dos valores arrecadados na função CRÉDITO (a vista) em
                    (D+3)    <span className='fw-bold'>3</span> dias úteis após a data do término do evento será cobrado o percentual de <span className='fw-bold'>
                        {osData?.rate?.advance_credit}% </span>
                    sobre o valor total arrecadado na função CRÉDITO.
                </p>


                <h5 className={styleTitle}>
                    11. DISPOSIÇÕES GERAIS
                </h5>

                <p className={styleParagraph}>
                    11.1. Qualquer alteração na quantidade de equipamentos impactará diretamente nas taxas cobradas pela
                    utilização do sistema, a fim de se manter o equilíbrio econômico do presente instrumento.
                </p>

                <p className={styleParagraph}>
                    11.2. As Partes reconhecem a forma de contratação por meios eletrônicos e
                    digitais como válida e eficaz, mesmo que feita com assinatura eletrônica ou certificação
                    fora dos padrões ICP-Brasil nos termos do artigo 10 da MP 2.200/2001.4.1.3.
                    O CLIENTE declara que leu e aceita os termos e condições constantes do Contrato de
                    Licença de Uso de Software e Prestação de Serviços, bem como confirmar a veracidade
                    dos dados preenchidos acima.
                </p>





            </div>
        
    );

}

export default BodyContract;