import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import ModalRegisterCoordinator from '../../../../components/modals/Coordinators/modalRegister'
import PageWrapper from '../../../../layout/PageWrapper/PageWrapper';
import SubHeader, { SubHeaderLeft, SubHeaderRight } from '../../../../layout/SubHeader/SubHeader';
import Page from '../../../../layout/Page/Page';
import Button from '../../../../components/bootstrap/Button';
import { demoPages } from '../../../../menu';
import { DefaultContext } from '../../../../contexts/default';
import Card, { CardBody, CardHeader, CardLabel, CardTitle } from '../../../../components/bootstrap/Card';
import SelectEstablishment from '../../../../components/MyCustom/SelectEstablishment';
import TableCustom from '../../../../components/MyCustom/TableCustom';
import Breadcrumb from '../../../../components/bootstrap/Breadcrumb';
import SelectEvent from '../../../../components/MyCustom/SelectEvent';
import { BASIC_ROLE } from '../../../../types/roles';
import showNotification from '../../../../components/extras/showNotification';
import notificationBody from '../../../../helpers/utils/functions/notificationBody';
import SectorDB from '../../../../database/wrappers/sector';
import convertArrayToObject from '../../../../utils/convertArrayToObject';
import useDarkMode from '../../../../hooks/useDarkMode';
import { orderBy, where } from 'firebase/firestore';
import { cpfFormatMask } from '../../../../utils/format/cpfFormat';
import UserDB from '../../../../database/wrappers/user';
import CoordinatorEventDB from '../../../../database/wrappers/coordinatorEvent';

const Coordinators = () => {
  const { darkModeStatus } = useDarkMode();

  const { estabSelected, eventSelected, onShowAlert, onShowQuestion } = useContext(DefaultContext)

  const [openModalRegister, setOpenModalRegister] = useState(false);
  const [sectorDicionary, setsectorDicionary] = useState({})
  const [userSelected, setuserSelected] = useState(null);
  const [usersCoor, setusersCoor] = useState([])
  const [usersCoorDicionary, setusersCoorDicionary] = useState({})
  const [usersCoorInEvent, setusersCoorInEvent] = useState([])

  useEffect(() => {
    return new UserDB().on(datas => {
      datas.sort((a, b) => {
        if(a.name.toLowerCase() > b.name.toLowerCase()) 
          return 1;
        return -1;
      })
      setusersCoor(datas)
      setusersCoorDicionary(convertArrayToObject(datas, 'id'))
    }, where('role', 'in', [BASIC_ROLE.COORDINATOR, BASIC_ROLE.ADMIN]))
  }, [])

  useEffect(() => {
    if (!estabSelected) return;
    const onSubscriber = new SectorDB(estabSelected).on(datas => setsectorDicionary(convertArrayToObject(datas, 'id')))
    return onSubscriber;
  }, [estabSelected])

  useEffect(() => {
    if (!estabSelected || !eventSelected) return;
    const onSubscriber = new CoordinatorEventDB(estabSelected, eventSelected).on(setusersCoorInEvent)
    return () => {
      onSubscriber();
    }
  }, [estabSelected, eventSelected])

  const formatSectors = useCallback((sectors) => {
    return sectors.map(sectorId => sectorDicionary[sectorId]?.name).sort().join(' - ')
  }, [sectorDicionary])

  const handleCloseModal = useCallback(() => {
    setOpenModalRegister(false)
  }, [])

  const handleRegister = useCallback(() => {
    setuserSelected(null);
    setOpenModalRegister(true)
  }, [])

  const handleOpenModalEdit = useCallback((item) => {
    setuserSelected(item);
    setOpenModalRegister(true)
  }, [])

  const handleOpenModalDelete = useCallback((id) => {
    onShowQuestion({
      title: 'Exclusão de Coordenador neste Evento',
      message: 'Você está excluindo um coordenador deste evento. Está ação é irreversível. Deseja continuar?',
      onConfirm: () => {
        new CoordinatorEventDB(estabSelected, eventSelected)
          .delete(id)
          .then(res => showNotification('', notificationBody('Registro deletado com sucesso'), 'success'))
          .catch(err => showNotification('', notificationBody('Falhou ao deletar o registro'), 'danger'))
      }
    })
  }, [estabSelected, eventSelected])

  const handleChangeStatus = useCallback((id, active) => {
    new CoordinatorEventDB(estabSelected, eventSelected)
      .update(id, {
        active: !active
      })
      .catch(error => onShowAlert({
        title: 'ERRO!',
        msm: 'Não foi possível atualizar o status',
        icon: 'Error',
        color: 'danger'
      }))
  }, [estabSelected, eventSelected])



  const columns = useMemo(() => ([
    {
      label: 'Nome',
      field: "{row}",
      format: row => usersCoorDicionary[row.id]?.name,
      formatExport: row => usersCoorDicionary[row.id]?.name,
    },
    {
      label: 'Cpf',
      field: "{row}",
      format: row => cpfFormatMask(usersCoorDicionary[row.id]?.cpf.toString() || ''),
      formatExport: row => cpfFormatMask(usersCoorDicionary[row.id]?.cpf.toString() || ''),
    },
    {
      label: 'N Cartão',
      field: 'cardNumber',
      
      
    },
    {
      label: 'Setores',
      field: '{row}',
      format: row => formatSectors(row.sectors),
      formatExport: row => formatSectors(row.sectors)
    },
    {
      label: 'Status',
      field: 'active',
      format: row => (
        <Button
          isLink
          color={row.active ? 'success' : 'danger'}
          icon='Circle'
          onClick={() => handleChangeStatus(row.id, row.active)}
          className='text-nowrap'>
          {row.active ? 'Ativo' : 'Inativo'}
        </Button>
      )
    },
    {
      label: 'Ações',
      field: 'actions',
      format: row => (
        <div className='w-50 d-flex justify-content-between'>
          <Button
            className='btn-tablecustom-row'
            color={darkModeStatus ? 'light' : 'dark'}
            icon="edit"
            shadow="sm"
            hoverShadow="sm"
            size="sm"
            onClick={() => handleOpenModalEdit(row)}
          />
          <Button
            className='btn-tablecustom-row'
            color="danger"
            icon="Trash"
            shadow="sm"
            hoverShadow="sm"
            size="sm"
            onClick={() => handleOpenModalDelete(row.id)}
          />
        </div>
      ),
      noExport: true
    },
  ]), [darkModeStatus, usersCoorDicionary, sectorDicionary, handleChangeStatus])


  return (
    <>
      <PageWrapper title={demoPages.event.subMenu.coordinators.text}>
        <SubHeader>
          <SubHeaderLeft>
            <Breadcrumb
              list={[
                { title: 'Evento', to: '/event' },
                { title: 'Coordenadores', to: '/event/coordinators' },
              ]}
            />
          </SubHeaderLeft>
          <SubHeaderRight>
            <Button
              color='primary'
              icon='plus'
              shadow="sm"
              hoverShadow="sm"
              onClick={handleRegister}
            >
              Cadastrar
            </Button>
          </SubHeaderRight>
        </SubHeader>

        <Page container="fluid">
          <Card>
            <CardHeader>
              <CardLabel icon='AccountCircle' iconColor={darkModeStatus ? 'light' : 'dark'}>
                <CardTitle>Coordenadores de Setor por Evento</CardTitle>
              </CardLabel>
            </CardHeader>
            <CardBody className='table-responsive'>
              <div className='row'>
                <SelectEstablishment />
                <SelectEvent />
              </div>

              <TableCustom
                rows={usersCoorInEvent}
                columns={columns}
                keyExtractor={row => row.id}
                fileName='Coordenadores-de-setor-por-evento'
              />
            </CardBody>
          </Card>
        </Page>

        <ModalRegisterCoordinator
          open={openModalRegister}
          setIsOpen={setOpenModalRegister}
          setIsClose={handleCloseModal}
          userSelected={userSelected}
          usersCoor={usersCoor}
        />
      </PageWrapper>
    </>
  );
};

export default Coordinators;

