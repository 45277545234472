import { QueryConstraint, Unsubscribe } from "firebase/firestore";
import Establishment from "../entities/establishment.entity";
import FirestorePipe from "../utils/pipe";

class EstablishmentDB extends FirestorePipe {

  public static readonly colName = 'Establishments'

  constructor() {
    super(EstablishmentDB.colName);
  }

  public async create(data: Establishment): Promise<any> {
    try {
      await this.saveFile(data);
      return await this._create(data);
    } catch (error: any) {
      if (data.image_ref) {
        await this.deleteFile(data.image_ref)
      }
      throw error.message
    }
  }
  public async update(id: string, data: Establishment): Promise<any> {
    try {
      await this.saveFile(data);
      return await this._update(id, data);
    } catch (error: any) {
      if (data.image_ref) {
        await this.deleteFile(data.image_ref)
      }
      throw error.message
    }
  }
  public delete(id: string): Promise<any> {
    return this._delete(id);
  }
  public getAll(...params: QueryConstraint[]): Promise<Establishment[]> {
    return this._getAll<Establishment>(...params);
  }
  public get(id: string): Promise<Establishment> {
    return this._get(id);
  }
  public on(listener: (data: Establishment[]) => void, ...params: QueryConstraint[]): Unsubscribe {
    return this._on(listener, ...params);
  } 

  public async getById(id: string): Promise<Establishment> {
    try {
      return await this._get(id);
    } catch (error: any) {
      throw error.message;
    }
  }

  private async saveFile(data: Establishment) {
    if (data.image) {
      const resultUpload = await this.uploadFile(new Date().getTime().toString(), data.image);
      data.image_ref = resultUpload.ref.fullPath;
      data.image_url = resultUpload.url;
    }
    delete data.image
  }

  // private async saveContract(data: Establishment) {
  //   if (data.contracts.contract) {
  //     const resultUpload = await this.uploadFile(new Date().getTime().toString(), data.contracts.contract);
  //     data.contracts.contract_ref = resultUpload.ref.fullPath;
  //     data.contracts.contract_url = resultUpload.url;
  //   }
  //   delete data.contracts.contract
  // }

}

export default EstablishmentDB;