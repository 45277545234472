import React, { useContext, useEffect, useMemo, useState } from 'react';
import PageWrapper from '../../layout/PageWrapper/PageWrapper';
import SubHeader, {
  SubHeaderLeft,
  SubHeaderRight,
} from '../../layout/SubHeader/SubHeader';
import Page from '../../layout/Page/Page';
import { DefaultContext } from '../../contexts/default';
import FiltersDash from '../../components/MyCustom/Dashboard/FiltersDash';
import Movimentacao from './movimentacao';
import TaxasAdquirencia from './taxasAdquirencia';
import CustosOperacionais from './custosOperacionais';
import OutrasReceitas from './outrasReceitas';
import OutrasDespesas from './outrasDespesas';
import PerdasDanos from './perdasDanos';
import ComissaoVendedor from './comissaoVendedor';
import Resumo from './resumo';
import api from '../../services/api';
import PreAlert from '../../helpers/utils/preAlert';
import LoadingPage from '../../components/MyCustom/LoadingPage';

const ContEvent = () => {

  const { estabSelected, eventSelected, onShowAlert } = useContext(DefaultContext)

  const [result, setresult] = useState(null)
  const [loading, setloading] = useState(false)

  useEffect(() => {
    if (estabSelected && eventSelected) {
      const params = {
        estabId: estabSelected,
        eventId: eventSelected,
      }
      setloading(true)
      api.get('routes/admContabil?' + new URLSearchParams(params).toString())
        .then(res => setresult(res.data))
        .catch(error => onShowAlert(PreAlert.error(error.response?.data?.message || 'Ocorreu um erro imprevisto!')))
        .finally(() => setloading(false))
    }
  }, [estabSelected, eventSelected])

  return (
    <PageWrapper title='ADM'>
      <SubHeader >
        <SubHeaderLeft />
        <SubHeaderRight>
          <FiltersDash />
        </SubHeaderRight>
      </SubHeader>

      {loading ?
        <LoadingPage />
      :
        <Page container="fluid">
          <Movimentacao 
            courtesy={result?.faturamento.courtesy || 0}
            recargaAntecipada={result?.faturamento.recargaAntecipada || 0}
          />
          <TaxasAdquirencia banners_card={result?.banners_card} />
          <OutrasReceitas equipaments={result?.equipaments || []} />
          <CustosOperacionais 
            responsibles={result?.responsibles || []}
            technicals={result?.technical || []}
          />
          <OutrasDespesas closureEvent={result?.closureEvent} />
          <PerdasDanos equipments={result?.equipaments || []} />
          <ComissaoVendedor seller={result?.seller}/>
          <Resumo resume={result?.resume} />
        </Page>
      }
    </PageWrapper >
  );
};

export default ContEvent ;
