import { useFormik } from "formik";
import { memo, useCallback, useContext, useEffect, useState } from "react";
import Card, { CardBody, CardFooter, CardHeader, CardLabel, CardTitle } from "../../../../components/bootstrap/Card";
import FormGroup from "../../../../components/bootstrap/forms/FormGroup";
import Input from "../../../../components/bootstrap/forms/Input";
import BtnCancel from "../../../../components/MyCustom/BtnCancel";
import BtnConfirm from "../../../../components/MyCustom/BtnConfirm";
import BtnLoad from "../../../../components/MyCustom/BtnLoad";
import { DefaultContext } from "../../../../contexts/default";
import ConfigUgetDB from "../../../../database/wrappers/configUget";
import { unmask } from "../../../../helpers/utils/masks";
import Money from "../../../../helpers/utils/money/money";
import PreAlert from "../../../../helpers/utils/preAlert";
import { inputMoneyMask } from "../../../../utils/input";

const CustoMaterial = ({ darkModeStatus }) => {

  const { onShowAlert } = useContext(DefaultContext);

  const [loading, setloading] = useState(false)
  const [loadingGetData, setloadingGetData] = useState(false)

  const validate = useCallback((values) => {
    const errors = {};
    
    if(!values.cards) {
      errors.cards = 'Valor inválido';
    }
    if(!values.cards_adm) {
      errors.cards_adm = 'Valor inválido';
    }
    if(!values.pos) {
      errors.pos = 'Valor inválido';
    }
    if(!values.pos_backup) {
      errors.pos_backup = 'Valor inválido';
    }
    if(!values.totems) {
      errors.totems = 'Valor inválido';
    }
    if(!values.totems_recharges) {
      errors.totems_recharges = 'Valor inválido';
    }
    if(!values.bracelets) {
      errors.bracelets = 'Valor inválido';
    }
    if(!values.totems_cables) {
      errors.totems_cables = 'Valor inválido';
    }
    if(!values.totems_cuffs) {
      errors.totems_cuffs = 'Valor inválido';
    }
    if(!values.totems_pedestal) {
      errors.totems_pedestal = 'Valor inválido';
    }
    if(!values.totems_cover) {
      errors.totems_cover = 'Valor inválido';
    }
    if(!values.extension) {
      errors.extension = 'Valor inválido';
    }
    if(!values.bobines) {
      errors.bobines = 'Valor inválido';
    }
    if(!values.kit_cordao) {
      errors.kit_cordao = 'Valor inválido';
    }
    if(!values.ruler) {
      errors.ruler = 'Valor inválido';
    }
    if(!values.pouch) {
      errors.pouch = 'Valor inválido';
    }
    if(!values.tablet) {
      errors.tablet = 'Valor inválido';
    }
    if(!values.tablet_power) {
      errors.tablet_power = 'Valor inválido';
    }
    if(!values.pos_power) {
      errors.pos_power = 'Valor inválido';
    }
    if(!values.transport_box) {
      errors.transport_box = 'Valor inválido';
    }
    if(!values.router_wifi) {
      errors.router_wifi = 'Valor inválido';
    }
    if(!values.processor_wifi) {
      errors.processor_wifi = 'Valor inválido';
    }
    if(!values.tripod_wifi) {
      errors.tripod_wifi = 'Valor inválido';
    }
    if(!values.cable_lan) {
      errors.cable_lan = 'Valor inválido';
    }

    return errors;
  }, [])

  const formik = useFormik({
    initialValues: {
      cards: '',
      cards_adm:'',
      pos: '',
      pos_backup: '',
      totems: '',
      totems_recharges: '',
      bracelets: '',
      totems_cables: '',
      totems_cuffs: '',
      totems_pedestal: '',
      totems_cover: '',
      extension: '',
      bobines: '',
      kit_cordao: '',
      ruler: '',
      pouch: '',
      tablet: '',
      tablet_power: '',
      pos_power: '',
      transport_box: '',
      router_wifi: '',
      processor_wifi: '',
      tripod_wifi: '',
      cable_lan: '',
    },
    validate,
    onSubmit: values => {
      const {
        cards,
        cards_adm,
        pos,
        pos_backup,
        totems,
        totems_recharges,
        bracelets,
        totems_cables,
        totems_cuffs,
        totems_pedestal,
        totems_cover,
        extension,
        bobines,
        kit_cordao,
        ruler,
        pouch,
        tablet,
        tablet_power,
        pos_power,
        transport_box,
        router_wifi,
        processor_wifi,
        tripod_wifi,
        cable_lan,
      } = values;

      const data = {
        cards: Number(unmask(cards)),
        cards_adm: Number(unmask(cards_adm)),
        pos: Number(unmask(pos)),
        pos_backup: Number(unmask(pos_backup)),
        totems: Number(unmask(totems)),
        totems_recharges: Number(unmask(totems_recharges)),
        bracelets: Number(unmask(bracelets)),
        totems_cables: Number(unmask(totems_cables)),
        totems_cuffs: Number(unmask(totems_cuffs)),
        totems_pedestal: Number(unmask(totems_pedestal)),
        totems_cover: Number(unmask(totems_cover)),
        extension: Number(unmask(extension)),
        bobines: Number(unmask(bobines)),
        kit_cordao: Number(unmask(kit_cordao)),
        ruler: Number(unmask(ruler)),
        pouch: Number(unmask(pouch)),
        tablet: Number(unmask(tablet)),
        tablet_power: Number(unmask(tablet_power)),
        pos_power: Number(unmask(pos_power)),
        transport_box: Number(unmask(transport_box)),
        router_wifi: Number(unmask(router_wifi)),
        processor_wifi: Number(unmask(processor_wifi)),
        tripod_wifi: Number(unmask(tripod_wifi)),
        cable_lan: Number(unmask(cable_lan)),
      }

      const onSuccessUpdate = () => {
        onShowAlert(PreAlert.success('Registro atualizado com sucesso'))
      }
      const onError = (error) => {
        console.error(error);
        onShowAlert(PreAlert.error('Falhou ao cadastrar o registro'))
      }

      setloading(true);
      new ConfigUgetDB()
        .define('custoMaterial', data)
        .then(onSuccessUpdate)
        .catch(onError)
        .finally(() => setloading(false))
    }
  });

  const loadData = useCallback(() => {
    setloadingGetData(true)
    new ConfigUgetDB()
      .get('custoMaterial')
      .then(data => {
        if (!data)
          return formik.resetForm();
        formik.setValues({
          cards: Money.centsToCoin(data.cards).toFixed(2),
          cards_adm: Money.centsToCoin(data.cards_adm).toFixed(2),
          pos: Money.centsToCoin(data.pos).toFixed(2),
          pos_backup: Money.centsToCoin(data.pos_backup).toFixed(2),
          totems: Money.centsToCoin(data.totems).toFixed(2),
          totems_recharges: Money.centsToCoin(data.totems_recharges).toFixed(2),
          bracelets: Money.centsToCoin(data.bracelets).toFixed(2),
          totems_cables: Money.centsToCoin(data.totems_cables).toFixed(2),
          totems_cuffs: Money.centsToCoin(data.totems_cuffs).toFixed(2),
          totems_pedestal: Money.centsToCoin(data.totems_pedestal).toFixed(2),
          totems_cover: Money.centsToCoin(data.totems_cover).toFixed(2),
          extension: Money.centsToCoin(data.extension).toFixed(2),
          bobines: Money.centsToCoin(data.bobines).toFixed(2),
          kit_cordao: Money.centsToCoin(data.kit_cordao).toFixed(2),
          ruler: Money.centsToCoin(data.ruler).toFixed(2),
          pouch: Money.centsToCoin(data.pouch).toFixed(2),
          tablet: Money.centsToCoin(data.tablet).toFixed(2),
          tablet_power: Money.centsToCoin(data.tablet_power).toFixed(2),
          pos_power: Money.centsToCoin(data.pos_power).toFixed(2),
          transport_box: Money.centsToCoin(data.transport_box).toFixed(2),
          router_wifi: Money.centsToCoin(data.router_wifi).toFixed(2),
          processor_wifi: Money.centsToCoin(data.processor_wifi).toFixed(2),
          tripod_wifi: Money.centsToCoin(data.tripod_wifi).toFixed(2),
          cable_lan: Money.centsToCoin(data.cable_lan).toFixed(2),
        })
      })
      .catch(error => {
        console.error(error)
        formik.resetForm();
      })
      .finally(() => setloadingGetData(false))
  }, [])

  useEffect(loadData, [])

  const onChangeNumbers = useCallback((e) => inputMoneyMask(formik, e), [])

  return (
    <Card>
      <CardHeader>
        <CardLabel icon='Build' iconColor={darkModeStatus ? 'light' : 'dark'}>
          <CardTitle>Custo Material</CardTitle>
        </CardLabel>
      </CardHeader>
      <form onSubmit={formik.handleSubmit}>
        <CardBody>
          <div className="row ms-2">
            <div className="col-md-6">
              <FormGroup className='row' label="Cartões" labelClassName='col-md-3' isColForLabel>
                <div className="col-md-3">
                  <Input
                    id="cards"
                    onChange={onChangeNumbers}
                    onBlur={formik.handleBlur}
                    value={formik.values.cards}
                    isValid={formik.isValid}
                    isTouched={formik.touched.cards}
                    invalidFeedback={formik.errors.cards}
                    validFeedback='Assim está bom!'
                    placeholder='0.00'
                  />
                </div>
              </FormGroup>
              <FormGroup className='row' label="Cartões Administradores" labelClassName='col-md-3' isColForLabel>
                <div className="col-md-3">
                  <Input
                    id="cards_adm"
                    onChange={onChangeNumbers}
                    onBlur={formik.handleBlur}
                    value={formik.values.cards_adm}
                    isValid={formik.isValid}
                    isTouched={formik.touched.cards_adm}
                    invalidFeedback={formik.errors.cards_adm}
                    validFeedback='Assim está bom!'
                    placeholder='0.00'
                  />
                </div>
              </FormGroup>
              <FormGroup className='row mt-2' label="POS" labelClassName='col-md-3' isColForLabel>
                <div className="col-md-3">
                  <Input
                    id="pos"
                    onChange={onChangeNumbers}
                    onBlur={formik.handleBlur}
                    value={formik.values.pos}
                    isValid={formik.isValid}
                    isTouched={formik.touched.pos}
                    invalidFeedback={formik.errors.pos}
                    validFeedback='Assim está bom!'
                    placeholder='0.00'
                  />
                </div>
              </FormGroup>
              <FormGroup className='row mt-2' label="POS Reserva" labelClassName='col-md-3' isColForLabel>
                <div className="col-md-3">
                  <Input
                    id="pos_backup"
                    onChange={onChangeNumbers}
                    onBlur={formik.handleBlur}
                    value={formik.values.pos_backup}
                    isValid={formik.isValid}
                    isTouched={formik.touched.pos_backup}
                    invalidFeedback={formik.errors.pos_backup}
                    validFeedback='Assim está bom!'
                    placeholder='0.00'
                  />
                </div>
              </FormGroup>
              <FormGroup className='row mt-2' label="Totem Ticket" labelClassName='col-md-3' isColForLabel>
                <div className="col-md-3">
                  <Input
                    id="totems"
                    onChange={onChangeNumbers}
                    onBlur={formik.handleBlur}
                    value={formik.values.totems}
                    isValid={formik.isValid}
                    isTouched={formik.touched.totems}
                    invalidFeedback={formik.errors.totems}
                    validFeedback='Assim está bom!'
                    placeholder='0.00'
                  />
                </div>
              </FormGroup>
              <FormGroup className='row mt-2' label="Totem Recarga" labelClassName='col-md-3' isColForLabel>
                <div className="col-md-3">
                  <Input
                    id="totems_recharges"
                    onChange={onChangeNumbers}
                    onBlur={formik.handleBlur}
                    value={formik.values.totems_recharges}
                    isValid={formik.isValid}
                    isTouched={formik.touched.totems_recharges}
                    invalidFeedback={formik.errors.totems_recharges}
                    validFeedback='Assim está bom!'
                    placeholder='0.00'
                  />
                </div>
              </FormGroup>
              <FormGroup className='row mt-2' label="Pulseira" labelClassName='col-md-3' isColForLabel>
                <div className="col-md-3">
                  <Input
                    id="bracelets"
                    onChange={onChangeNumbers}
                    onBlur={formik.handleBlur}
                    value={formik.values.bracelets}
                    isValid={formik.isValid}
                    isTouched={formik.touched.bracelets}
                    invalidFeedback={formik.errors.bracelets}
                    validFeedback='Assim está bom!'
                    placeholder='0.00'
                  />
                </div>
              </FormGroup>
              <FormGroup className='row mt-2' label="Cabo de Totem" labelClassName='col-md-3' isColForLabel>
                <div className="col-md-3">
                  <Input
                    id="totems_cables"
                    onChange={onChangeNumbers}
                    onBlur={formik.handleBlur}
                    value={formik.values.totems_cables}
                    isValid={formik.isValid}
                    isTouched={formik.touched.totems_cables}
                    invalidFeedback={formik.errors.totems_cables}
                    validFeedback='Assim está bom!'
                    placeholder='0.00'
                  />
                </div>
              </FormGroup>
              <FormGroup className='row mt-2' label="Algemas de Totem" labelClassName='col-md-3' isColForLabel>
                <div className="col-md-3">
                  <Input
                    id="totems_cuffs"
                    onChange={onChangeNumbers}
                    onBlur={formik.handleBlur}
                    value={formik.values.totems_cuffs}
                    isValid={formik.isValid}
                    isTouched={formik.touched.totems_cuffs}
                    invalidFeedback={formik.errors.totems_cuffs}
                    validFeedback='Assim está bom!'
                    placeholder='0.00'
                  />
                </div>
              </FormGroup>
              <FormGroup className='row mt-2' label="Pedestal Totem" labelClassName='col-md-3' isColForLabel>
                <div className="col-md-3">
                  <Input
                    id="totems_pedestal"
                    onChange={onChangeNumbers}
                    onBlur={formik.handleBlur}
                    value={formik.values.totems_pedestal}
                    isValid={formik.isValid}
                    isTouched={formik.touched.totems_pedestal}
                    invalidFeedback={formik.errors.totems_pedestal}
                    validFeedback='Assim está bom!'
                    placeholder='0.00'
                  />
                </div>
              </FormGroup>
              <FormGroup className='row mt-2' label="Carcaça Totem" labelClassName='col-md-3' isColForLabel>
                <div className="col-md-3">
                  <Input
                    id="totems_cover"
                    onChange={onChangeNumbers}
                    onBlur={formik.handleBlur}
                    value={formik.values.totems_cover}
                    isValid={formik.isValid}
                    isTouched={formik.touched.totems_cover}
                    invalidFeedback={formik.errors.totems_cover}
                    validFeedback='Assim está bom!'
                    placeholder='0.00'
                  />
                </div>
              </FormGroup>
              <FormGroup className='row mt-2' label="Extensão" labelClassName='col-md-3' isColForLabel>
                <div className="col-md-3">
                  <Input
                    id="extension"
                    onChange={onChangeNumbers}
                    onBlur={formik.handleBlur}
                    value={formik.values.extension}
                    isValid={formik.isValid}
                    isTouched={formik.touched.extension}
                    invalidFeedback={formik.errors.extension}
                    validFeedback='Assim está bom!'
                    placeholder='0.00'
                  />
                </div>
              </FormGroup>
            </div>

            <div className="col-md-6">
              <FormGroup className='row' label="Bobinas" labelClassName='col-md-3' isColForLabel>
                <div className="col-md-3">
                  <Input
                    id="bobines"
                    onChange={onChangeNumbers}
                    onBlur={formik.handleBlur}
                    value={formik.values.bobines}
                    isValid={formik.isValid}
                    isTouched={formik.touched.bobines}
                    invalidFeedback={formik.errors.bobines}
                    validFeedback='Assim está bom!'
                    placeholder='0.00'
                  />
                </div>
              </FormGroup>
              <FormGroup className='row' label="Régua" labelClassName='col-md-3' isColForLabel>
                <div className="col-md-3">
                  <Input
                    id="ruler"
                    onChange={onChangeNumbers}
                    onBlur={formik.handleBlur}
                    value={formik.values.ruler}
                    isValid={formik.isValid}
                    isTouched={formik.touched.ruler}
                    invalidFeedback={formik.errors.ruler}
                    validFeedback='Assim está bom!'
                    placeholder='0.00'
                  />
                </div>
              </FormGroup>
              <FormGroup className='row' label="Kit Cordões" labelClassName='col-md-3' isColForLabel>
                <div className="col-md-3">
                  <Input
                    id="kit_cordao"
                    onChange={onChangeNumbers}
                    onBlur={formik.handleBlur}
                    value={formik.values.kit_cordao}
                    isValid={formik.isValid}
                    isTouched={formik.touched.kit_cordao}
                    invalidFeedback={formik.errors.kit_cordao}
                    validFeedback='Assim está bom!'
                    placeholder='0.00'
                  />
                </div>
              </FormGroup>
              <FormGroup className='row mt-2' label="Malote" labelClassName='col-md-3' isColForLabel>
                <div className="col-md-3">
                  <Input
                    id="pouch"
                    onChange={onChangeNumbers}
                    onBlur={formik.handleBlur}
                    value={formik.values.pouch}
                    isValid={formik.isValid}
                    isTouched={formik.touched.pouch}
                    invalidFeedback={formik.errors.pouch}
                    validFeedback='Assim está bom!'
                    placeholder='0.00'
                  />
                </div>
              </FormGroup>
              <FormGroup className='row mt-2' label="Tablet" labelClassName='col-md-3' isColForLabel>
                <div className="col-md-3">
                  <Input
                    id="tablet"
                    onChange={onChangeNumbers}
                    onBlur={formik.handleBlur}
                    value={formik.values.tablet}
                    isValid={formik.isValid}
                    isTouched={formik.touched.tablet}
                    invalidFeedback={formik.errors.tablet}
                    validFeedback='Assim está bom!'
                    placeholder='0.00'
                  />
                </div>
              </FormGroup>
              <FormGroup className='row mt-2' label="Carregador Tablet" labelClassName='col-md-3' isColForLabel>
                <div className="col-md-3">
                  <Input
                    id="tablet_power"
                    onChange={onChangeNumbers}
                    onBlur={formik.handleBlur}
                    value={formik.values.tablet_power}
                    isValid={formik.isValid}
                    isTouched={formik.touched.tablet_power}
                    invalidFeedback={formik.errors.tablet_power}
                    validFeedback='Assim está bom!'
                    placeholder='0.00'
                  />
                </div>
              </FormGroup>
              <FormGroup className='row mt-2' label="Carregador POS" labelClassName='col-md-3' isColForLabel>
                <div className="col-md-3">
                  <Input
                    id="pos_power"
                    onChange={onChangeNumbers}
                    onBlur={formik.handleBlur}
                    value={formik.values.pos_power}
                    isValid={formik.isValid}
                    isTouched={formik.touched.pos_power}
                    invalidFeedback={formik.errors.pos_power}
                    validFeedback='Assim está bom!'
                    placeholder='0.00'
                  />
                </div>
              </FormGroup>
              <FormGroup className='row mt-2' label="Caixa Transporte" labelClassName='col-md-3' isColForLabel>
                <div className="col-md-3">
                  <Input
                    id="transport_box"
                    onChange={onChangeNumbers}
                    onBlur={formik.handleBlur}
                    value={formik.values.transport_box}
                    isValid={formik.isValid}
                    isTouched={formik.touched.transport_box}
                    invalidFeedback={formik.errors.transport_box}
                    validFeedback='Assim está bom!'
                    placeholder='0.00'
                  />
                </div>
              </FormGroup>
              <FormGroup className='row mt-2' label="Roteador Wifi" labelClassName='col-md-3' isColForLabel>
                <div className="col-md-3">
                  <Input
                    id="router_wifi"
                    onChange={onChangeNumbers}
                    onBlur={formik.handleBlur}
                    value={formik.values.router_wifi}
                    isValid={formik.isValid}
                    isTouched={formik.touched.router_wifi}
                    invalidFeedback={formik.errors.router_wifi}
                    validFeedback='Assim está bom!'
                    placeholder='0.00'
                  />
                </div>
              </FormGroup>
              <FormGroup className='row mt-2' label="Switch" labelClassName='col-md-3' isColForLabel>
                <div className="col-md-3">
                  <Input
                    id="processor_wifi"
                    onChange={onChangeNumbers}
                    onBlur={formik.handleBlur}
                    value={formik.values.processor_wifi}
                    isValid={formik.isValid}
                    isTouched={formik.touched.processor_wifi}
                    invalidFeedback={formik.errors.processor_wifi}
                    validFeedback='Assim está bom!'
                    placeholder='0.00'
                  />
                </div>
              </FormGroup>
              <FormGroup className='row mt-2' label="Tripé Wifi" labelClassName='col-md-3' isColForLabel>
                <div className="col-md-3">
                  <Input
                    id="tripod_wifi"
                    onChange={onChangeNumbers}
                    onBlur={formik.handleBlur}
                    value={formik.values.tripod_wifi}
                    isValid={formik.isValid}
                    isTouched={formik.touched.tripod_wifi}
                    invalidFeedback={formik.errors.tripod_wifi}
                    validFeedback='Assim está bom!'
                    placeholder='0.00'
                  />
                </div>
              </FormGroup>
              <FormGroup className='row mt-2' label="Cabo Lan" labelClassName='col-md-3' isColForLabel>
                <div className="col-md-3">
                  <Input
                    id="cable_lan"
                    onChange={onChangeNumbers}
                    onBlur={formik.handleBlur}
                    value={formik.values.cable_lan}
                    isValid={formik.isValid}
                    isTouched={formik.touched.cable_lan}
                    invalidFeedback={formik.errors.cable_lan}
                    validFeedback='Assim está bom!'
                    placeholder='0.00'
                  />
                </div>
              </FormGroup>
            </div>
          </div>
        </CardBody>
        <CardFooter>
          <div>
            {!loadingGetData && <BtnCancel setIsClose={loadData} className='mx-4' />}
            {loading ? (
              <BtnLoad className='mx-4' />
            ) : (
              <BtnConfirm className='mx-4' isDisable={!formik.isValid} />
            )}
          </div>
        </CardFooter>
      </form>
    </Card>
  );
}

export default memo(CustoMaterial)