
import React, { useContext, useEffect, useState, useMemo } from "react";
import { DefaultContext } from "../../../../../contexts/default";
import ClouserTotemEventData from "../../../../../database/wrappers/eventData/clouserTotem";
import ClouserTotemRechargeEventData from "../../../../../database/wrappers/eventData/clouserTotemRecharge";
import RelatorioTotems from "./RelatorioTotems";
import Totais from "./totais";
import RelatorioTotemsRecharge from "./RelatorioTotemsRecharge";

const TotemReports = ({ hidden }) => {
	const { estabSelected, eventSelected, dataFilter } = useContext(DefaultContext)
	const [totems, settotems] = useState([])
	const [totemsRecharge, settotemsRecharge] = useState([])
	const firstDate = new Date(dataFilter.firstDate);
	const secondDate = new Date(dataFilter.secondDate);

	useEffect(() => {
		const loadTotem = () => {
				if (!hidden && estabSelected && eventSelected) {
					new ClouserTotemEventData(estabSelected, eventSelected)
						.getAll()
						.then(totem => 
							settotems(totem)
						);
					new ClouserTotemRechargeEventData(estabSelected, eventSelected)
						.getAll()
						.then(totem => 
							settotemsRecharge(totem)
						);
				}
		};

		loadTotem();
	}, [hidden, estabSelected, eventSelected]);

	const memorizedFilteredtotem = useMemo(() => {
		return totems.filter(totem => totem.opening_at.toDate() > firstDate && totem.opening_at.toDate() < secondDate);
	}, [totems, firstDate, secondDate]);

	const memorizedFilteredtotemRecharge = useMemo(() => {
		return totemsRecharge.filter(totem => totem.opening_at.toDate() > firstDate && totem.opening_at.toDate() < secondDate);
	}, [totemsRecharge, firstDate, secondDate]);

	return (

		<div className='row' hidden={hidden} >
			<div>
				<Totais
					totems={memorizedFilteredtotem}
					totemsRecharge={memorizedFilteredtotemRecharge}
				/>
			</div>
			<RelatorioTotems totems={memorizedFilteredtotem} />
			<RelatorioTotemsRecharge totemsRecharge={memorizedFilteredtotemRecharge} />
		</div>



	);
}

export default TotemReports