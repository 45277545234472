import React, { useState, useEffect, useContext, useMemo } from 'react';
import { useFormik } from 'formik';
import FormGroup from '../../bootstrap/forms/FormGroup';
import Input from '../../bootstrap/forms/Input';
import Checks from '../../bootstrap/forms/Checks';
import Modal, { ModalBody, ModalHeader, ModalTitle } from '../../bootstrap/Modal';
import PreAlert from '../../../helpers/utils/preAlert';
import { DefaultContext } from '../../../contexts/default';
import BtnCancel from '../../MyCustom/BtnCancel';
import BtnLoad from '../../MyCustom/BtnLoad';
import BtnConfirm from '../../MyCustom/BtnConfirm';
import TotenDB from '../../../database/wrappers/toten';
import Select from '../../bootstrap/forms/Select';

const validate = (values) => {
  const errors = {};

  if (!values.name) {
    errors.name = 'Este campo é necessário.';
  }

  if (!values.sectorId) {
    errors.sectorId = 'Este campo é necessário.';
  }

  return errors;

}

const ModalRegisterTotem = ({ open, setIsOpen, setIsClose, totemsSelected }) => {

  const { estabSelected, sectors, onShowAlert } = useContext(DefaultContext)

  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (!open) return formik.resetForm();
    if (totemsSelected) {
      const { name, sectorId, active } = totemsSelected;

      formik.setValues({
        name,
        sectorId,
        active,
      });
    }
  }, [totemsSelected, open])

  const formik = useFormik({
    initialValues: {
      name: '',
      sectorId: '',
      active: '',
    },
    validate,
    onSubmit: values => {
      const { name, sectorId, active } = values;

      const data = {
        name,
        sectorId,
        active,
      }

      const onSuccess = () => {
        onShowAlert(PreAlert.success('Registro cadastrado com sucesso'))
        setIsClose();
      }
      const onSuccessUpdate = () => {
        onShowAlert(PreAlert.success('Registro atualizado com sucesso'))
        setIsClose();
      }
      const onError = () => {
        onShowAlert(PreAlert.error('Falhou ao cadastrar o registro'))
      }

      setIsLoading(true);
      if (totemsSelected) {
        new TotenDB(estabSelected)
          .update(totemsSelected.id, data)
          .then(onSuccessUpdate)
          .catch(onError)
          .finally(() => setIsLoading(false))
      } else {
        new TotenDB(estabSelected)
          .create(data)
          .then(onSuccess)
          .catch(onError)
          .finally(() => setIsLoading(false))
      }
    },
  })

  const options = useMemo(() => sectors.map(item => ({ value: item.id, text: item.name })), [sectors])

  return (
    <Modal
      id={'modal-register-totenSales'}
      titleId={'Cadastro e Atualização de Totem'}
      isOpen={open}
      setIsOpen={setIsOpen}
      isStaticBackdrop={true}
      isScrollable={false}
      isCentered={true}
      size="lg" // 'sm' || 'lg' || 'xl' 
      isAnimation={true}
      onSubmit={formik.handleSubmit}
    >
      <ModalHeader setIsOpen={setIsOpen}>
        <ModalTitle id="register-totems">{totemsSelected ? 'Atualização de Totem' : 'Cadastro de Totem'}</ModalTitle>
      </ModalHeader>
      <ModalBody>
        <form noValidate onSubmit={formik.handleSubmit}>

          {/* Inputs */}
          <div className="row g-4">

            {/* Nome */}
            <FormGroup id="name" label="Nome" className='col-md-6'>
              <Input
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.name}
                isValid={formik.isValid}
                isTouched={formik.touched.name}
                invalidFeedback={formik.errors.name}
                validFeedback='Assim está bom!'
                placeholder='Totem'
              />
            </FormGroup>
            <FormGroup id="sectorId" label="Setor" className='col-md-4'>
              <Select
                placeholder='Selecione o setor'
                value={formik.values.sectorId}
                list={options}
                onChange={formik.handleChange}
                isValid={formik.isValid}
                isTouched={formik.touched.sectorId}
                invalidFeedback={formik.errors.sectorId}
                validFeedback='Assim está bom!'
              />
            </FormGroup>

            {/* Status */}
            <FormGroup
              id='active'
              label='Status'
              className='col-md-2'
            >
              <Checks
                type="switch"
                id="active"
                label={formik.values.active ? 'Ativo' : 'Inativo'}
                name="active"
                onChange={formik.handleChange}
                checked={formik.values.active}
              />
            </FormGroup>
          </div>
          {/* Buttons */}
          <div className="row pt-4">
            <div className="col-md-4 offset-md-8">
              <div className='d-flex justify-content-evenly'>
                <BtnCancel setIsClose={setIsClose} />
                {isLoading ? (
                  <BtnLoad />
                ) : (
                  <BtnConfirm isDisable={!formik.isValid} />
                )}
              </div>
            </div>
          </div>
        </form>
      </ModalBody>
    </Modal>
  )
}

export default ModalRegisterTotem;