import React, { useState, useContext, useEffect, useCallback } from 'react';
import { useFormik } from 'formik';
import Button from '../../bootstrap/Button';
import FormGroup from '../../bootstrap/forms/FormGroup';
import Input from '../../bootstrap/forms/Input';
import Checks, { ChecksGroup } from '../../bootstrap/forms/Checks';
import Label from '../../bootstrap/forms/Label';
import Modal, { ModalBody, ModalHeader, ModalTitle } from '../../bootstrap/Modal';
import Spinner from '../../bootstrap/Spinner';
import showNotification from '../../extras/showNotification';
import Textarea from '../../bootstrap/forms/Textarea';
// Database
import CategoryDB from '../../../database/wrappers/category';
import SubCategoryDB from '../../../database/wrappers/subCategory';
import Nav, { NavItem } from '../../bootstrap/Nav';
import ListSubCategories from './ListSubCategories';
import ProductDB from '../../../database/wrappers/product';
import ListProducts from '../../MyCustom/ListProducts';
import { orderBy, where } from 'firebase/firestore';
import Avatar from '../../MyCustom/Avatar';
import { unmask } from '../../../helpers/utils/masks';
import { DefaultContext } from '../../../contexts/default';
import PreAlert from '../../../helpers/utils/preAlert';
import BtnCancel from '../../MyCustom/BtnCancel';
import BtnLoad from '../../MyCustom/BtnLoad';
import BtnConfirm from '../../MyCustom/BtnConfirm';

const validate = (values) => {
  const errors = {};

  if (!values.name) {
    errors.name = 'Este campo é necessário';
  } else if (values.name.length < 4) {
    errors.name = 'O nome precisa ter 3 caracteres ou mais';
  }

  if (isNaN(Number(values.order)) || Number(values.order) < 0 || Number(values.order) > 99) {
    errors.order = "Informe um número entre 0 e 99";
  }

  if (!values.active) {
    errors.active = 'Você precisa escolher o status da categoria';
  }

  return errors;

}

const ModalRegisterCategories = ({ open, setIsOpen, setIsClose, categorySelected }) => {

  const { establishment, onShowAlert } = useContext(DefaultContext)

  const [isLoading, setIsLoading] = useState(false);
  const [abaSelected, setabaSelected] = useState(0);
  const [products, setproducts] = useState([]);
  const [subCategoriesResponse, setSubCategoriesResponse] = useState([])
  const [showCategoryItems, setshowCategoryItems] = useState(false)

  useEffect(() => {
    if(!open) return formik.resetForm();
    if (categorySelected) {
      const { name, active, order, description, subCategories, products, image_url } = categorySelected;

      formik.setValues({
        name: name,
        active: active ? 'ativo' : 'inativo',
        order: order,
        description: description,

        subCategories,
        products,

        image: null,
        image_url,
      });
    }
  }, [categorySelected, open])

  // Pega as subcategorias
  useEffect(async () => {
    if (establishment) {
      function sort(a, b) {
        if(a.name > b.name) return 1;
        if(a.name < b.name) return -1;
        return 0;
      }
      async function getSubCategories() {
        const response = await new SubCategoryDB(establishment.id).getAll(where('active', '==', true))
        response.sort(sort);
        setSubCategoriesResponse(response)
      }
      async function getProducts() {
        const products = await new ProductDB(establishment.id).getAll(where('active', '==', true));
        products.sort(sort);
        setproducts(products);
      }

      getSubCategories();
      getProducts();
    }
  }, [establishment])

  const formik = useFormik({
    initialValues: {
      name: '',
      active: '',
      order: 0,
      description: '',
      products: [],

      subCategories: [],
      products: [],

      image: null,
      image_url: null,
    },
    validate,
    onSubmit: async values => {
      const { name, active, order, description, subCategories, image, products } = values;
  
      const data = {
        name: name,
        active: active === 'ativo',
        order: Number(order),
        description,
        subCategories,
        products,
        image,
      }

      const onSuccess = () => {
        onShowAlert(PreAlert.success('Registro cadastrado com sucesso'))
        setIsClose();
      }
      const onSuccessUpdate = () => {
        onShowAlert(PreAlert.success('Registro atualizado com sucesso'))
        setIsClose();
      }
      const onError = () => {
        onShowAlert(PreAlert.error('Falhou ao cadastrar o registro'))
      }
  
      setIsLoading(true);
      if (categorySelected) {
        await new CategoryDB(establishment.id)
          .update(categorySelected.id, data)
          .then(onSuccessUpdate)
          .catch(onError)
          .finally(() => setIsLoading(false))
      } else {
        await new CategoryDB(establishment.id)
          .create(data)
          .then(onSuccess)
          .catch(onError)
          .finally(() => setIsLoading(false))
      }
    }
  })


  const handleImage = useCallback((e) => {
    const [file] = Array.from(e.target.files)
    formik.setValues({
      ...formik.values,
      image: file,
      image_url: URL.createObjectURL(file)
    })
  }, [formik.values])

  const handleChangeOrder = useCallback(e => {
    const order = e.target.value.replace(/\D/g, "");
    formik.setValues({
      ...formik.values,
      order
    })
  }, [formik.values])

  const onChangeShowCategory = useCallback((show) => setshowCategoryItems(show), [])

  return (
    <Modal
      id={'modal-register-category'}
      titleId={'Cadastro de Categoria'}
      isOpen={open}
      setIsOpen={setIsOpen}
      isStaticBackdrop={true}
      isScrollable={false}
      isCentered={true}
      size="lg" // 'sm' || 'lg' || 'xl' 
      isAnimation={true}
      onSubmit={formik.handleSubmit}
    >
      <ModalHeader setIsOpen={setIsOpen}>
        <ModalTitle id="register-category">{categorySelected ? 'Atualização de Categoria' : 'Cadastro de Categoria'}</ModalTitle>
      </ModalHeader>
      <ModalBody style={showCategoryItems ? { opacity: 0.1, backgroundColor: 'black' } : {}}>
        <form noValidate onSubmit={formik.handleSubmit}>
          {/* Imagem do Categoria */}
          <div className='row align-items-center justify-content-center'>
            <FormGroup
              id='image_url'
              ariaLabel='Logo'
            >
              <Avatar
                id='image_url'
                value={formik.values.image_url}
                onChange={handleImage} />
            </FormGroup>
          </div>
          {/* Inputs */}
          <div className="row g-4">
            {/* Nome */}
            <FormGroup id="name" label="Nome" className='col-md-8'>
              <Input
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.name || ''}
                isValid={formik.isValid}
                isTouched={formik.touched.name}
                invalidFeedback={formik.errors.name}
                validFeedback='Assim está bom!'
                placeholder='Eletrodomésticos, lâmpadas, etc...'
              />
            </FormGroup>

            {/* Ordem */}
            <FormGroup id="order" label="Ordem" className='col-md-4'>
              <Input
                onChange={handleChangeOrder}
                onBlur={formik.handleBlur}
                value={formik.values.order || ''}
                isValid={formik.isValid}
                isTouched={formik.touched.order}
                invalidFeedback={formik.errors.order}
                validFeedback='Assim está bom!'
                placeholder='1, 2, 3, 4, etc...'
              />
            </FormGroup>

            {/* Descrição */}
            <FormGroup id="description" label="Descrição" className='col-md-8'>
              <Textarea
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.description || ''}
                isValid={formik.isValid}
                isTouched={formik.touched.description}
                invalidFeedback={formik.errors.description}
                validFeedback='Assim está bom!'
                placeholder='
                Lorem ipsum dolor sit amet consectetur adipisicing elit. Consectetur illum hic omnis ipsam fugiat incidunt'
              />
            </FormGroup>

            {/* Status */}
            <FormGroup className='col-md-4'>
              <Label>Status</Label>
              <ChecksGroup
                isValid={formik.isValid}
                isTouched={formik.touched.active}
                invalidFeedback={formik.errors.active}
              >
                <Checks
                  type="radio"
                  id="active"
                  label="Ativo"
                  name="active"
                  value="ativo"
                  onChange={formik.handleChange}
                  checked={formik.values.active}
                  isInline
                />
                <Checks
                  type="radio"
                  id="active2"
                  label="Inativo"
                  name="active"
                  value="inativo"
                  onChange={formik.handleChange}
                  checked={formik.values.active}
                  isInline
                />
              </ChecksGroup>
            </FormGroup>
          </div>
          
          {/* Abas */}
          <Nav
            style={{ marginTop: 20 }}
            tag='ul' // 'ul' || 'nav'
            design='tabs' // 'tabs' || 'pills'
            isFill
          >
            <NavItem 
              isActive={abaSelected === 0}
              onClick={() => setabaSelected(0)}>
              <a>
                Produtos
              </a>
            </NavItem>
            
            <NavItem 
              isActive={abaSelected === 1}
              onClick={() => setabaSelected(1)}>
              <a>
                Sub-categorias
              </a>
            </NavItem>
          </Nav>

          {/* Lists */}
          <ListProducts
            hidden={abaSelected !== 0}
            products={products}
            productsSelected={formik.values.products}
            onChange={formik.handleChange}
          />
        
          <ListSubCategories
            hidden={abaSelected !== 1}
            products={products}
            categories={subCategoriesResponse}
            categoriesSelected={formik.values.subCategories}
            onChange={formik.handleChange}
            onChangeShowCategory={onChangeShowCategory}
          />

          {/* Buttons */}
          <div className="row pt-4">
            <div className="col-md-4 offset-md-8">
              <div className='d-flex justify-content-evenly'>
                <BtnCancel setIsClose={setIsClose} />
                {isLoading ? (
                  <BtnLoad />
                ) : (
                  <BtnConfirm isDisable={!formik.isValid} />
                )}
              </div>
            </div>
          </div>
        </form>
      </ModalBody>
    </Modal>
  )
}

export default ModalRegisterCategories;