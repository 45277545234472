import {
  collection,
  CollectionReference,
	DocumentData,
	getFirestore,
	onSnapshot,
	query,
	QueryConstraint,
	Unsubscribe,
} from 'firebase/firestore';
import app from '../../config';

class NfcHistory {
	protected readonly colRefStr: string;
  protected readonly colRef: CollectionReference<DocumentData> ;

	constructor(estabId: string, eventId: string, nfcId: string) {
		this.colRefStr = `EstablishmentsData/${estabId}/EventsData/${eventId}/NFCs/${nfcId}/History`;
    this.colRef = collection(getFirestore(app), this.colRefStr);
	}

	on(listener: (data: History[]) => void, ...params: QueryConstraint[]): Unsubscribe {
		return onSnapshot(query(this.colRef, ...params), snapshot => {
      const datas: History[] = [];
      snapshot.forEach(doc => datas.push({ id: doc.id, ...doc.data() as any } as History))
      listener(datas)
    })
	}

}

export default NfcHistory;