import {
  collection,
  CollectionReference,
	DocumentData,
	getDocs,
	getFirestore,
	onSnapshot,
	query,
	QueryConstraint,
	QueryDocumentSnapshot,
	Timestamp,
	Unsubscribe,
	where,
} from 'firebase/firestore';
import app from '../../config';
import Sales from '../../entities/eventData/sales.entity';

class SalesEventData {
	protected readonly colRefStr: string;
  protected readonly colRef: CollectionReference<DocumentData> ;

	constructor(estabId: string, eventId: string) {
		this.colRefStr = `EstablishmentsData/${estabId}/EventsData/${eventId}/Sales`;
    this.colRef = collection(getFirestore(app), this.colRefStr);
	}

	async getAll(): Promise<Sales[]> {
		const snapshot = await getDocs(query(this.colRef))
		return snapshot.docs.map(doc => ({
			id: doc.id,
			...doc.data(),
		})) as any
	}

	async getByNfc(nfc: string): Promise<Sales[]> {
		const snapshot = await getDocs(query(this.colRef, where('idCard', '==', nfc)))
		const snapshotSplit = await getDocs(query(this.colRef, where('nfcsPaid', 'array-contains', nfc)))

		const array = snapshot.docs.map((doc: DocumentData): Sales => ({
			id: doc.id,
			...doc.data(),
		}))
		const arraySplit: any[] = [];
		snapshotSplit.docs.forEach(doc => {
			const data = doc.data() as Sales;
			data.splitPayment.forEach(split => {
				if(split.idCard === nfc)
					arraySplit.push({
						...data,
						...split,
						split: true,
						created_at: null
					})
			})
		})

		return array.concat(arraySplit)
	}

	async getByNfcReversed(nfc: string): Promise<Sales[]> {
		const snapshot = await getDocs(query(this.colRef, where('idCard', '==', nfc), where('reversed', '==', true)))
		return snapshot.docs.map(doc => ({
			id: doc.id,
			...doc.data(),
		})) as any
	}

	on(listener: (data: Sales[]) => void, ...params: QueryConstraint[]): Unsubscribe {
		return onSnapshot(query(this.colRef, ...params), snapshot => {
      const datas: Sales[] = [];
      snapshot.forEach(doc => datas.push({ id: doc.id, ...doc.data() as any } as Sales))
      listener(datas)
    })
	}

	onPagination(listener: (data: Sales[], docs: QueryDocumentSnapshot<DocumentData>[]) => void, ...params: QueryConstraint[]): Unsubscribe {
		return onSnapshot(query(this.colRef, ...params), snapshot => {
      const datas: Sales[] = [];
      snapshot.forEach(doc => datas.push({ id: doc.id, ...doc.data() as any } as Sales))
      listener(datas, snapshot.docs)
    })
	}

	async getAllInterval(startDate: Date, endDate: Date): Promise<Sales[]> {
		const snapshot = await getDocs(query(this.colRef, 
			where('created_at', '>=', Timestamp.fromDate(startDate)),
			where('created_at', '<=', Timestamp.fromDate(endDate))
		))
		return snapshot.docs.map(doc => ({
			id: doc.id,
			...doc.data(),
		})) as any
	}
	async getBySector(sectorId: string): Promise<Sales[]> {
		const snapshot = await getDocs(query(this.colRef,
			where('sectorId', '==', sectorId)
			
		))
		return snapshot.docs.map(doc => ({
			id: doc.id,
			...doc.data(),
		})) as any
	}
}

export default SalesEventData;
