const EVENT_TYPE = {
  CASHLESS: 1,
  TICKET: 2,
  HAWKER: 3,
  TOTEM: 4,
}

const FIELD_EVENT_TYPE = {
  [EVENT_TYPE.CASHLESS]: 'cashless',
  [EVENT_TYPE.TICKET]: 'ticket',
  [EVENT_TYPE.HAWKER]: 'hawker',
  [EVENT_TYPE.TOTEM]: 'totem',
}

export const TABS = {

  VENDA: {
    id: 'VENDA',
    label: 'Venda',

  },
  CAIXA: {
    id: 'CAIXA',
    label: 'Caixa',
    subTabs: {
      GERAL: { id: 'GERAL', label: 'Geral' },
      POR_SECTOR: { id: 'POR_SECTOR', label: 'Por Setor' },
    },
  },
  TOTEM: {
    id: 'TOTEM',
    label: 'Totem',
    eventTypes: [EVENT_TYPE.TOTEM]
  },
  CONSUMO: {
    id: 'CONSUMO',
    label: 'Consumo',
    subTabs: {
      GERAL: { id: 'GERAL', label: 'Geral' },
      POR_SECTOR: { id: 'POR_SECTOR', label: 'Por Setor' },
    },

  },
  ENTRADA: {
    id: 'ENTRADA',
    label: 'Entrada',
    subTabs: {
      GERAL: { id: 'GERAL', label: 'Geral' },
      POR_SECTOR: { id: 'POR_SECTOR', label: 'Por Setor' },
    },
  },
  RECARGA: {
    id: 'RECARGA',
    label: 'Recarga',
    subTabs: {
      GERAL: { id: 'GERAL', label: 'Geral' },
      POR_SECTOR: { id: 'POR_SECTOR', label: 'Por Setor' },
    },

  },
  ESTOQUE: {
    id: 'ESTOQUE',
    label: 'Estoque',
    subTabs: {
      GERAL: { id: 'GERAL', label: 'Geral' },
      POR_SECTOR: { id: 'POR_SECTOR', label: 'Por Setor' },
    },
  },


};

export function tabsFilterByEventTypes(event) {
  if (!event) return TABS;
  const tabs = {};
  Object.keys(TABS).forEach(key => {
    if (TABS[key].eventTypes) {
      if (TABS[key].eventTypes.find(eventType => event.types[FIELD_EVENT_TYPE[eventType]])) {
        tabs[key] = TABS[key]
      }
    } else {
      tabs[key] = TABS[key]
    }
  })
  return tabs;
}