import { QueryConstraint, Unsubscribe } from "firebase/firestore";
import FirestorePipe from "../utils/pipe";

class ConfigUgetDB extends FirestorePipe {

  public static readonly colName = 'ConfigUget'

  constructor() {
    super(`${ConfigUgetDB.colName}`);
  }

  public create(data: any): Promise<any> {
    throw new Error('Metodo não implementado')
  }
  public define(id: string, data: any): Promise<any> {
    return this._define(id, data);
  }
  public update(id: string, data: any): Promise<any> {
    return this._update(id, data);
  }
  public delete(id: string): Promise<any> {
    return this._delete(id);
  }
  public getAll(...params: QueryConstraint[]): Promise<any[]> {
    return this._getAll<any>(...params);
  }
  public get(id: string): Promise<any> {
    return this._get(id);
  }
  public on(listener: (data: any[]) => void, ...params: QueryConstraint[]): Unsubscribe {
    return this._on(listener, ...params);
  }
  public onDicionary(listener: (data: any) => void, ...params: QueryConstraint[]): Unsubscribe {
    return this._onDicionary(listener, ...params);
  }

}

export default ConfigUgetDB;