import { deleteObject, FirebaseStorage, getDownloadURL, getStorage, ref, StorageReference, uploadBytes } from "firebase/storage";
import app from "../config";

class StoragePipe {

  protected readonly colRefStr: string;
  protected readonly storage: FirebaseStorage;

  constructor(colRefStr: string) {
    this.colRefStr = colRefStr;
    this.storage = getStorage(app);
  }

  protected async uploadFile(name: string, data: Blob): Promise<{ ref: StorageReference, url: string }> {
    const storageRef = ref(this.storage, `${this.colRefStr}/${name}`);
    const snapshot = await uploadBytes(storageRef, data);
    const url = await getDownloadURL(snapshot.ref);
    return { url, ref: snapshot.ref };
  }

  protected async deleteFile(reference: string) {
    const desertRef = ref(this.storage, reference);
    return await deleteObject(desertRef)
  }

}

export default StoragePipe;