import { memo, useMemo } from "react"
import Button from "../../../bootstrap/Button";
import Select from "../../../bootstrap/forms/Select";

const SelectUser = ({ index, options, selected, usersSelected, onChange, onRemove }) => {
  const optionsFilter = useMemo(() => 
    options.filter(o => o.value === selected || !usersSelected.includes(o.value))
  ,[options, usersSelected])
  
  return (
    <div className="row col-md-12 mt-2">
      <div className="col-md-10">
        <Select
          value={selected}
          list={optionsFilter}
          placeholder='Selecione'
          onChange={e => onChange(e.target.value, selected, index)}
        />
      </div>
      <Button
        className="col-md-2 ms-2"
        icon='Remove'
        color='danger'
        type='button'
        onClick={() => onRemove(index)}
      />
    </div>
  );
}

export default memo(SelectUser)