import React, { useState, useEffect, useContext, useCallback } from 'react';
import { useFormik } from 'formik';
import FormGroup from '../../bootstrap/forms/FormGroup';
import Input from '../../bootstrap/forms/Input';
import Textarea from '../../bootstrap/forms/Textarea';
import InputGroup, { InputGroupText } from '../../bootstrap/forms/InputGroup';
import Label from '../../bootstrap/forms/Label';
import Checks, { ChecksGroup } from '../../bootstrap/forms/Checks';
import Modal, { ModalBody, ModalHeader, ModalTitle } from '../../bootstrap/Modal';
import showNotification from '../../extras/showNotification';
import EstablishmentDB from '../../../database/wrappers/establishment';
import { GeoPoint } from 'firebase/firestore';
import masks from '../../../helpers/utils/masks';
import PreAlert from '../../../helpers/utils/preAlert';
import { DefaultContext } from '../../../contexts/default';
import Avatar from '../../MyCustom/Avatar';
import randomString from '../../../helpers/utils/functions/randomString';
import Wizard, { WizardItem } from '../../Wizard';
import { validateStep1, validateStep2, validateStep3, validateStep4 } from './utils';
import apiViaCep from '../../../services/viaCep';
import d4sign from '../../../services/d4signEstablishment';
import { inputOnlyNumbers, inputOnlyNumbersNoStitch } from '../../../utils/input';



const validate = (values) => {
  const errorsStep1 = validateStep1(values)
  const errorsStep2 = validateStep2(values)
  const errorsStep3 = validateStep3(values)
  const errorsStep4 = validateStep4(values)
  return {
    ...errorsStep1,
    ...errorsStep2,
    ...errorsStep3,
    ...errorsStep4
  }
}

const ModalRegisterEstablishments = ({ open, setIsOpen, setIsClose, establishment }) => {

  const { onShowAlert } = useContext(DefaultContext)

  const [isLoading, setIsLoading] = useState(false);
  // console.log(establishment)

  useEffect(() => {
    if (!open) return formik.resetForm();
    if (establishment) {
      const { cnpj, name, fantasy_name, phone, stateRegistration, municipalRegistration, email, reason, active, geopoint, pagseguroCode, stoneCode, idLoja, address, bankAccount, representative, image_url, witnesses, signed } = establishment;

      formik.setValues({
        cnpj: cnpj,
        name: name,
        stateRegistration: stateRegistration,
        municipalRegistration: municipalRegistration,
        fantasyName: fantasy_name,
        phone: phone,
        email: email,
        latitude: geopoint ? geopoint._lat : '',
        longitude: geopoint ? geopoint._long : '',
        reason: reason,
        pagseguroCode,
        stoneCode,
        idLoja,
        active: active ? 'ativo' : 'inativo',
        image_url,

        zip_code: address?.zip_code || '',
        city: address?.city || '',
        state: address?.state || '',
        neighborhood: address?.neighborhood || '',
        street: address?.street || '',
        number: address?.number || '',
        complement: address?.complement || '',

        personal_name: representative?.personal_name || '',
        personal_cpf: representative?.personal_cpf || '',
        personal_rg: representative?.personal_rg || '',
        personal_street: representative?.personal_street || '',
        personal_number: representative?.personal_number || '',
        personal_complement: representative?.personal_complement || '',
        personal_neighborhood: representative?.personal_neighborhood || '',
        personal_city: representative?.personal_city || '',
        personal_state: representative?.personal_state || '',
        personal_zip_code: representative?.personal_zip_code || '',

        bank: bankAccount?.bank || '',
        bankCode: bankAccount?.bankCode || '',
        account: bankAccount?.account || '',
        agency: bankAccount?.agency || '',
        pix: bankAccount?.pix || '',

        witnesse1: witnesses?.witnesse1 || '',
        witnesse2: witnesses?.witnesse2 || '',
        witnesse1Email: witnesses?.witnesse1Email || '',
        witnesse2Email: witnesses?.witnesse2Email || '',

        signed: signed || false,

      });
    }
  }, [establishment, open])

  const formik = useFormik({
    initialValues: {
      cnpj: '',
      name: '',
      stateRegistration: '',
      municipalRegistration: '',
      fantasyName: '',
      phone: '',
      email: '',
      latitude: '',
      longitude: '',
      reason: '',
      pagseguroCode: '',
      stoneCode: '',
      idLoja: '',
      active: '',
      image: null,
      image_url: null,

      zip_code: '',
      city: '',
      state: '',
      neighborhood: '',
      street: '',
      number: '',
      complement: '',

      personal_name: '',
      personal_cpf: '',
      personal_rg: '',
      personal_street: '',
      personal_number: '',
      personal_complement: '',
      personal_neighborhood: '',
      personal_city: '',
      personal_state: '',
      personal_zip_code: '',

      bank: '',
      bankCode: '',
      agency: '',
      account: '',
      pix: '',

      witnesse1: '',
      witnesse2: '',
      witnesse1Email: '',
      witnesse2Email: '',

      signed: false,

      codeNumber: '',
    },
    validate,
    onSubmit: async values => {

      const {
        name, fantasyName, stateRegistration, municipalRegistration, phone, email, cnpj, reason, active, latitude, longitude, pagseguroCode, stoneCode, idLoja, image,
        zip_code, city, state, neighborhood, street, number, complement, personal_name, personal_cpf, personal_rg, personal_street,
        personal_number, personal_complement, personal_neighborhood, personal_city, personal_state, personal_zip_code,
        bank, bankCode, account, agency, pix, witnesse1, witnesse2, witnesse1Email, witnesse2Email, signed, codeNumber
      } = values;

      const data = {
        name: name,
        fantasy_name: fantasyName,
        stateRegistration,
        municipalRegistration,
        phone: phone,
        email: email,
        cnpj: cnpj,
        reason: reason,
        pagseguroCode,
        stoneCode,
        idLoja,
        active: active === 'ativo',
        geopoint: new GeoPoint(latitude, longitude),
        image,
        code_auth: establishment ? establishment.code_auth : randomString(8),
        signed: signed,


        address: {
          zip_code,
          city,
          state,
          neighborhood,
          street,
          number,
          complement,
        },

        representative: {
          personal_name: personal_name,
          personal_cpf: personal_cpf,
          personal_rg: personal_rg,
          personal_street: personal_street,
          personal_number: personal_number,
          personal_complement: personal_complement,
          personal_neighborhood: personal_neighborhood,
          personal_city: personal_city,
          personal_state: personal_state,
          personal_zip_code: personal_zip_code,
        },

        bankAccount: {
          bank,
          bankCode,
          account,
          agency,
          pix,
        },

        witnesses: {
          witnesse1,
          witnesse2,
          witnesse1Email,
          witnesse2Email
        }
      }

      if(codeNumber) {
        data.codeNumber = Number(codeNumber)
      }

      const onSuccess = () => {
        onShowAlert(PreAlert.success('Registro cadastrado com sucesso'))
        setIsClose();
      }
      const onSuccessUpdate = () => {
        onShowAlert(PreAlert.success('Registro atualizado com sucesso'))
        setIsClose();
      }
      const onError = (error) => {
        console.error(error);
        onShowAlert(PreAlert.error('Falhou ao cadastrar o registro'))
      }


      if (establishment && establishment.id) {
        setIsLoading(true); // Define isLoading como true antes de iniciar a atualização
        new EstablishmentDB()
          .update(establishment.id, data)
          .then(onSuccessUpdate)
          .catch(onError)
          .finally(() => setIsLoading(false)); // Define isLoading como false quando a atualização terminar
      } else {
        setIsLoading(true); // Define isLoading como true antes de iniciar a criação
        new EstablishmentDB()
          .create(data)
          .then((response) => {
            const newEstablishmentId = response;
            try {
              new EstablishmentDB()
                .get(newEstablishmentId)
                .then((response) => {
                  setIsLoading(true); // Define isLoading como true antes de iniciar a chamada de d4sign
                  d4sign(response)
                    .then(() => {
                      // console.log('Estabelecimento', response);
                      setTimeout(() => {
                        setIsLoading(false); // Define isLoading como false quando a chamada de d4sign terminar
                        onSuccess();
                      }, 5000);
                    })
                    .catch((error) => {
                      console.log('Erro ao chamar d4sign', error);
                      setIsLoading(false); // Define isLoading como false em caso de erro na chamada de d4sign
                    });
                })
                .catch(onError);
            } catch (error) {
              console.log('Não achou o estabelecimento', error);
            }
          })
          .catch(onError)
          .finally(() => setIsLoading(false)); // Define isLoading como false quando a criação terminar
      }
    },
  })

  useEffect(() => {
    if (formik.values.zip_code?.length === 9 && formik.values.zip_code !== '') {
      const handleCallbackCep = async (cep) => {
        await apiViaCep(`${cep}/json`)
          .then(res => {
            if (res.data.erro) {
              showNotification('', 'CEP não encontrado', 'danger');
              formik.setFieldValue('neighborhood', '');
              formik.setFieldValue('street', '');
              formik.setFieldValue('zip_code', '');
              formik.setFieldValue('state', '');
              formik.setFieldValue('city', '');
              formik.setFieldValue('municipal_code', '');
              formik.setFieldValue('state_code', '');
            } else {
              showNotification('', 'CEP localizado', 'success');
              if (res.data.bairro) formik.setFieldValue('neighborhood', res.data.bairro);
              if (res.data.logradouro) formik.setFieldValue('street', res.data.logradouro);
              formik.setFieldValue('zip_code', res.data.cep);
              formik.setFieldValue('state', res.data.uf);
              formik.setFieldValue('city', res.data.localidade);
              formik.setFieldValue('municipal_code', res.data.siafi);
              formik.setFieldValue('state_code', res.data.ibge.substring(0, 2));
            }
          }).catch(err => {
            showNotification('', 'CEP não encontrado', 'danger');
          }).finally(res => {
            //setIsLoading(false);
          });
      }

      let cep = formik.values.zip_code.replace('-', '');
      handleCallbackCep(cep);
    }
  }, [formik.values.zip_code]);

  useEffect(() => {
    if (formik.values.personal_zip_code?.length === 9 && formik.values.personal_zip_code !== '') {
      const handleCallbackCep = async (cep) => {
        await apiViaCep(`${cep}/json`)
          .then(res => {
            if (res.data.erro) {
              showNotification('', 'CEP não encontrado', 'danger');
              formik.setFieldValue('personal_neighborhood', '');
              formik.setFieldValue('personal_street', '');
              formik.setFieldValue('personal_zip_code', '');
              formik.setFieldValue('personal_state', '');
              formik.setFieldValue('personal_city', '');
            } else {
              showNotification('', 'CEP localizado', 'success');
              if (res.data.bairro) formik.setFieldValue('personal_neighborhood', res.data.bairro);
              if (res.data.logradouro) formik.setFieldValue('personal_street', res.data.logradouro);
              formik.setFieldValue('personal_zip_code', res.data.cep);
              formik.setFieldValue('personal_state', res.data.uf);
              formik.setFieldValue('personal_city', res.data.localidade);
            }
          }).catch(err => {
            showNotification('', 'CEP não encontrado', 'danger');
          }).finally(res => {
            //setIsLoading(false);
          });
      }

      let cep = formik.values.personal_zip_code.replace('-', '');
      handleCallbackCep(cep);
    }
  }, [formik.values.personal_zip_code]);

  const checkStepIsValid = (step) => {
    if (step === 'step1') {
      const errors = validateStep1(formik.values)
      return Object.keys(errors).length > 0
    }
    if (step === 'step2') {
      const errors = validateStep2(formik.values)
      return Object.keys(errors).length > 0
    }
    if (step === 'step3') {
      if (formik.values.personal_name !== '' && formik.values.personal_cpf !== '' && formik.values.personal_rg !== '') {
        return false;
      }
      return true;
    }
    if (step === 'step4') {
      const errors = validateStep4(formik.values)
      return Object.keys(errors).length > 0
    }
  }

  const handleImage = useCallback((e) => {
    const [file] = Array.from(e.target.files)
    formik.setValues({
      ...formik.values,
      image: file,
      image_url: URL.createObjectURL(file)
    })
  }, [formik.values])

  const onChangeCodeNumber = useCallback((e) => inputOnlyNumbersNoStitch(formik, e), [formik.values])

  return (
    <Modal
      id={'modal-register-establishments'}
      titleId={'Cadastro e Update de Estabelecimentos'}
      isOpen={open}
      setIsOpen={setIsOpen}
      isStaticBackdrop={true}
      isScrollable={false}
      isCentered={true}
      size="lg" // 'sm' || 'lg' || 'xl' 
      isAnimation={true}
      onSubmit={formik.handleSubmit}
    >
      <ModalHeader setIsOpen={setIsOpen}>
        <ModalTitle id="register-establishment">{establishment ? 'Atualização de Estabelecimento' : 'Cadastro de Estabelecimento'}</ModalTitle>
      </ModalHeader>
      <ModalBody>
        <Wizard
          isHeader
          color='info'
          onSubmit={formik.handleSubmit}
          className='shadow-3d-info'
          isLoading={isLoading}
        >
          <WizardItem id='step1' title='Informações Principais' required={checkStepIsValid('step1')}>
            {/* Imagem do Estabelecimento */}
            <div className='row align-items-center justify-content-center'>
              <FormGroup
                id='image_url'
                ariaLabel='Logo'
              >
                <Avatar
                  id='image_url'
                  value={formik.values.image_url}
                  onChange={handleImage} />
              </FormGroup>
            </div>
            {/* Inputs */}
            <div className="row g-4">
              {/* CNPJ */}
              <FormGroup
                id='cnpj'
                label='CNPJ'
                className='col-md-4'
              >
                <Input
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={masks.cnpj(formik.values.cnpj)}
                  isValid={formik.isValid}
                  isTouched={formik.touched.cnpj}
                  invalidFeedback={formik.errors.cnpj}
                  validFeedback='Assim está bom!'
                  placeholder='34237209000195'
                />
              </FormGroup>
              {/* Nome */}
              <FormGroup id="name" label="Nome" className='col-md-8'>
                <Input
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.name}
                  isValid={formik.isValid}
                  isTouched={formik.touched.name}
                  invalidFeedback={formik.errors.name}
                  validFeedback='Assim está bom!'
                  placeholder='João da Silva'
                />
              </FormGroup>
              {/* Inscrição Estadual */}
              <FormGroup id='stateRegistration' label='Inscrição Estadual' className='col-md-6'>
                <Input
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.stateRegistration}
                  isValid={formik.isValid}
                  isTouched={formik.touched.stateRegistration}
                  invalidFeedback={formik.errors.stateRegistration}
                  validFeedback='Assim está bom!'
                  placeholder='23564654615'
                />
              </FormGroup>
              <FormGroup
                id='municipalRegistration'
                label='Inscrição Municipal'
                className='col-md-6'
              >
                <Input
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.municipalRegistration}
                  isValid={formik.isValid}
                  isTouched={formik.touched.municipalRegistration}
                  invalidFeedback={formik.errors.municipalRegistration}
                  validFeedback='Assim está bom!'
                  placeholder='23564654615'
                />
              </FormGroup>
              {/* Nome Fantasia */}
              <FormGroup
                id='fantasyName'
                label='Nome Fantasia'
                className='col-md-4'
              >
                <Input
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.fantasyName}
                  isValid={formik.isValid}
                  isTouched={formik.touched.fantasyName}
                  invalidFeedback={formik.errors.fantasyName}
                  validFeedback='Assim está bom!'
                  placeholder='Loja do João Ltda'
                />
              </FormGroup>
              {/* Telefone */}
              <FormGroup
                id='phone'
                label='Telefone'
                className='col-md-4'
              >
                <Input
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={masks.phone(formik.values.phone)}
                  isValid={formik.isValid}
                  isTouched={formik.touched.phone}
                  invalidFeedback={formik.errors.phone}
                  validFeedback='Assim está bom!'
                  placeholder='33999998888'
                />
              </FormGroup>
              {/* Email */}
              <FormGroup className='col-md-4'>
                <Label htmlFor="email">Email</Label>
                <InputGroup>
                  <InputGroupText id="inputGroupPrepend">
                    @
                  </InputGroupText>
                  <Input
                    id="email"
                    ariaDescribedby='inputGroupPrepend'
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.email}
                    isValid={formik.isValid}
                    isTouched={formik.touched.email}
                    invalidFeedback={formik.errors.email}
                    validFeedback='Assim está bom!'
                    placeholder="joaodasilva@email.com"
                  />
                </InputGroup>
              </FormGroup>
              {/* Cod. Pagseguro */}
              <FormGroup
                id='pagseguroCode'
                label='Cod. Pagseguro'
                className='col-md-4'
              >
                <Input
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.pagseguroCode}
                  isValid={formik.isValid}
                  isTouched={formik.touched.pagseguroCode}
                  invalidFeedback={formik.errors.pagseguroCode}
                  validFeedback='Assim está bom!'
                  placeholder='00000000'
                />
              </FormGroup>
              {/* Cod. Stone */}
              <FormGroup
                id='stoneCode'
                label='Cod. Stone'
                className='col-md-4'
              >
                <Input
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.stoneCode}
                  isValid={formik.isValid}
                  isTouched={formik.touched.stoneCode}
                  invalidFeedback={formik.errors.stoneCode}
                  validFeedback='Assim está bom!'
                  placeholder='00000000'
                />
              </FormGroup>
              {/* ID Loja */}
              <FormGroup
                id='idLoja'
                label='ID Loja'
                className='col-md-4'
              >
                <Input
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.idLoja}
                  isValid={formik.isValid}
                  isTouched={formik.touched.idLoja}
                  invalidFeedback={formik.errors.idLoja}
                  validFeedback='Assim está bom!'
                  placeholder='00000000'
                />
              </FormGroup>
              {/* Razão Social */}
              <FormGroup id="reason" label="Razão Social" className='col-md-6'>
                <Textarea
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.reason}
                  isValid={formik.isValid}
                  isTouched={formik.touched.reason}
                  invalidFeedback={formik.errors.reason}
                  validFeedback='Assim está bom!'
                  placeholder='
                Digite sua razão social aqui'
                  rows={5}
                />
              </FormGroup>
              <div className='col-md-6'>
                <div className='row g-4'>
                  <FormGroup
                    id='codeNumber'
                    label='Cod. Contrato'
                    className='col-md-6'
                  >
                    <Input
                      onChange={onChangeCodeNumber}
                      onBlur={formik.handleBlur}
                      value={formik.values.codeNumber}
                      isValid={formik.isValid}
                      isTouched={formik.touched.codeNumber}
                      invalidFeedback={formik.errors.codeNumber}
                      validFeedback='Assim está bom!'
                      placeholder='(Opcional)'
                    />
                  </FormGroup>
                </div>
                <div className='row g-4'>
                  {/* Latitude */}
                  <FormGroup
                    id='latitude'
                    label='Latitude'
                    className='col-md-6'
                  >
                    <Input
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.latitude}
                      isValid={formik.isValid}
                      isTouched={formik.touched.latitude}
                      invalidFeedback={formik.errors.latitude}
                      validFeedback='Assim está bom!'
                      placeholder='23564654615'
                    />
                  </FormGroup>
                  {/* Longitude */}
                  <FormGroup
                    id='longitude'
                    label='Longitude'
                    className='col-md-6'
                  >
                    <Input
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.longitude}
                      isValid={formik.isValid}
                      isTouched={formik.touched.longitude}
                      invalidFeedback={formik.errors.longitude}
                      validFeedback='Assim está bom!'
                      placeholder='23564654615'
                    />
                  </FormGroup>
                  {/* Status */}
                  <FormGroup className='col-md-4'>
                    <Label>Status</Label>
                    <ChecksGroup
                      className='d-flex flex-row gap-3'
                      isValid={formik.isValid}
                      isTouched={formik.touched.active}
                      invalidFeedback={formik.errors.active}
                    >
                      <Checks
                        type="radio"
                        id="active"
                        label="Ativo"
                        name="active"
                        value="ativo"
                        onChange={formik.handleChange}
                        checked={formik.values.active}
                        isInline
                      />
                      <Checks
                        type="radio"
                        id="active2"
                        label="Inativo"
                        name="active"
                        value="inativo"
                        onChange={formik.handleChange}
                        checked={formik.values.active}
                        isInline
                      />
                    </ChecksGroup>
                  </FormGroup>
                </div>
              </div>
            </div>
          </WizardItem>

          <WizardItem id='step2' title='Endereço' required={checkStepIsValid('step2')}>
            {/* CNPJ */}
            <div className="row g-4">
              <FormGroup
                id='zip_code'
                label='CEP'
                className='col-md-4'
              >
                <Input
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={masks.cep(formik.values.zip_code)}
                  isValid={formik.isValid}
                  isTouched={formik.touched.zip_code}
                  invalidFeedback={formik.errors.zip_code}
                  validFeedback='Assim está bom!'
                  placeholder='39100-000'
                />
              </FormGroup>

              {(formik.values.state && !formik.errors.zip_code) && (
                <FormGroup
                  id='state'
                  label='Estado'
                  className='col-md-2'
                >
                  <Input
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.state}
                    isTouched={formik.touched.state}
                    invalidFeedback={formik.errors.state}
                    disabled
                  />
                </FormGroup>
              )}

              {(formik.values.city && !formik.errors.zip_code) && (
                <FormGroup
                  id='city'
                  label='Cidade'
                  className='col-md-6'
                >
                  <Input
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.city}
                    isTouched={formik.touched.city}
                    invalidFeedback={formik.errors.city}
                    disabled
                  />
                </FormGroup>
              )}

              {(formik.values.city && !formik.errors.zip_code) && (
                <FormGroup
                  id='neighborhood'
                  label='Bairro'
                  className='col-md-3'
                >
                  <Input
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.neighborhood}
                    isTouched={formik.touched.neighborhood}
                    invalidFeedback={formik.errors.neighborhood}
                    placeholder='Ex: Centro'
                  />
                </FormGroup>
              )}

              {(formik.values.city && !formik.errors.zip_code) && (
                <FormGroup
                  id='street'
                  label='Logradouro'
                  className='col-md-5'
                >
                  <Input
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.street}
                    isTouched={formik.touched.street}
                    invalidFeedback={formik.errors.street}
                    placeholder='Ex: Rua Principal'
                  />
                </FormGroup>
              )}

              {(formik.values.city && !formik.errors.zip_code) && (
                <FormGroup
                  id='number'
                  label='Número'
                  className='col-md-2'
                >
                  <Input
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.number}
                    isTouched={formik.touched.number}
                    invalidFeedback={formik.errors.number}
                    placeholder='0'
                  />
                </FormGroup>
              )}

              {(formik.values.city && !formik.errors.zip_code) && (
                <FormGroup
                  id='complement'
                  label='Complemento'
                  className='col-md-6'
                >
                  <Input
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.complement}
                    isTouched={formik.touched.complement}
                    invalidFeedback={formik.errors.complement}
                    placeholder='Ex: Casa'
                  />
                </FormGroup>
              )}
            </div>
          </WizardItem>

          <WizardItem id='step3' title='Dados do Representante' required={checkStepIsValid('step3')}>
            <div className="row g-4">
              {/* Nome */}
              <FormGroup id="personal_name" label="Nome" className='col-md-6'>
                <Input
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.personal_name}
                  isValid={formik.isValid}
                  isTouched={formik.touched.personal_name}
                  invalidFeedback={formik.errors.personal_name}
                  validFeedback='Assim está bom!'
                  placeholder='João da Silva'
                />
              </FormGroup>
              {/* CPF */}
              <FormGroup
                id='personal_cpf'
                label='CPF'
                className='col-md-3'
              >
                <Input
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={masks.cpf(formik.values.personal_cpf)}
                  isValid={formik.isValid}
                  isTouched={formik.touched.personal_cpf}
                  invalidFeedback={formik.errors.personal_cpf}
                  validFeedback='Assim está bom!'
                  placeholder='102.906.166-11'
                />
              </FormGroup>
              {/* RG */}
              <FormGroup id="personal_rg" label="RG" className='col-md-3'>
                <Input
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.personal_rg}
                  isValid={formik.isValid}
                  isTouched={formik.touched.personal_rg}
                  invalidFeedback={formik.errors.personal_rg}
                  validFeedback='Assim está bom!'
                  placeholder='MG-15.843.778'
                />
              </FormGroup>
              <FormGroup
                id='personal_zip_code'
                label='CEP'
                className='col-md-2'
              >
                <Input
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={masks.cep(formik.values.personal_zip_code)}
                  isValid={formik.isValid}
                  isTouched={formik.touched.personal_zip_code}
                  invalidFeedback={formik.errors.personal_zip_code}
                  validFeedback='Assim está bom!'
                  placeholder='39100-000'
                />
              </FormGroup>
              {/* Rua */}
              <FormGroup id="personal_street" label="Rua" className='col-md-6'>
                <Input
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.personal_street}
                  isValid={formik.isValid}
                  isTouched={formik.touched.personal_street}
                  invalidFeedback={formik.errors.personal_street}
                  validFeedback='Assim está bom!'
                  placeholder='Rua Pessoal'
                />
              </FormGroup>
              {/* number personal */}
              <FormGroup id="personal_number" label="Número" className='col-md-2'>
                <Input
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.personal_number}
                  isValid={formik.isValid}
                  isTouched={formik.touched.personal_number}
                  invalidFeedback={formik.errors.personal_number}
                  validFeedback='Assim está bom!'
                  placeholder='123'
                />
              </FormGroup>
              {/* complemento personal */}
              <FormGroup id="personal_complement" label="Complemento" className='col-md-2'>
                <Input
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  isValid={formik.isValid}
                  isTouched={formik.touched.personal_complement}
                  invalidFeedback={formik.errors.personal_complement}
                  validFeedback='Assim está bom!'
                  placeholder='CASA B'
                />
              </FormGroup>
              {/* bairro personal */}
              <FormGroup id="personal_neighborhood" label="Bairro" className='col-md-4'>
                <Input
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.personal_neighborhood}
                  isValid={formik.isValid}
                  isTouched={formik.touched.personal_neighborhood}
                  invalidFeedback={formik.errors.personal_neighborhood}
                  validFeedback='Assim está bom!'
                  placeholder='Centro'
                />
              </FormGroup>
              {/* cidade personal */}
              <FormGroup id="personal_city" label="Cidade" className='col-md-4'>
                <Input
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.personal_city}
                  isValid={formik.isValid}
                  isTouched={formik.touched.personal_city}
                  invalidFeedback={formik.errors.personal_city}
                  validFeedback='Assim está bom!'
                  placeholder='Pirapora'
                />
              </FormGroup>
              {/* estado personal */}
              <FormGroup id="personal_state" label="Estado" className='col-md-4'>
                <Input
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.personal_state}
                  isValid={formik.isValid}
                  isTouched={formik.touched.personal_state}
                  invalidFeedback={formik.errors.personal_state}
                  validFeedback='Assim está bom!'
                  placeholder='Minas Gerais'
                />
              </FormGroup>

            </div>
          </WizardItem>

          <WizardItem id='step4' title='Dados Bancários' required={checkStepIsValid('step4')}>
            <div className="row g-4">
              {/* Banco */}
              <FormGroup id="bank" label="Banco" className='col-md-4'>
                <Input
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.bank}
                  isValid={formik.isValid}
                  isTouched={formik.touched.bank}
                  invalidFeedback={formik.errors.bank}
                  validFeedback='Assim está bom!'
                  placeholder='Digite o nome do banco'
                />
              </FormGroup>
              {/* codigo do banco */}
              <FormGroup id="bankCode" label="Código do Banco" className='col-md-4'>
                <Input
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.bankCode}
                  isValid={formik.isValid}
                  isTouched={formik.touched.bankCode}
                  invalidFeedback={formik.errors.bankCode}
                  validFeedback='Assim está bom!'
                  placeholder='Digite o código do banco'
                />
              </FormGroup>
              {/* Agência */}
              <FormGroup id="agency" label="Agência" className='col-md-4'>
                <Input
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.agency}
                  isValid={formik.isValid}
                  isTouched={formik.touched.agency}
                  invalidFeedback={formik.errors.agency}
                  validFeedback='Assim está bom!'
                  placeholder='Digite a agência'
                />
              </FormGroup>
              {/* Conta */}
              <FormGroup id="account" label="Conta Corrente" className='col-md-4'>
                <Input
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.account}
                  isValid={formik.isValid}
                  isTouched={formik.touched.account}
                  invalidFeedback={formik.errors.account}
                  validFeedback='Assim está bom!'
                  placeholder='Digite a conta corrente'
                />
              </FormGroup>
              {/* pix */}
              <FormGroup id="pix" label="Pix" className='col-md-4'>
                <Input
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.pix}
                  isValid={formik.isValid}
                  isTouched={formik.touched.pix}
                  invalidFeedback={formik.errors.pix}
                  validFeedback='Assim está bom!'
                  placeholder='Digite o pix da conta'
                />
              </FormGroup>
              <div className='col-md-12'>
                <div className="row">
                  <FormGroup label="Testemunhas da Uget" className='col-md-12' />
                  <FormGroup id="witnesse1" className='col-md-6'>
                    <Input
                      ariaDescribedby='inputGroupPrepend'
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.witnesse1}
                      isValid={formik.isValid}
                      isTouched={formik.touched.witnesse1}
                      invalidFeedback={formik.errors.witnesse1}
                      validFeedback='Assim está bom!'
                      placeholder="Nome da Testemunha Uget"
                    />
                  </FormGroup>
                  <FormGroup id="witnesse1Email" className='col-md-6'>
                    <Input
                      ariaDescribedby='inputGroupPrepend'
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.witnesse1Email}
                      isValid={formik.isValid}
                      isTouched={formik.touched.witnesse1Email}
                      invalidFeedback={formik.errors.witnesse1Email}
                      validFeedback='Assim está bom!'
                      placeholder="Email da Testemunha Uget"
                    />
                  </FormGroup>
                  <FormGroup label="Testemunhas do Evento" className='col-md-12 mt-4' />
                  <FormGroup id="witnesse2" className='col-md-6 '>
                    <Input
                      ariaDescribedby='inputGroupPrepend'
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.witnesse2}
                      isValid={formik.isValid}
                      isTouched={formik.touched.witnesse2}
                      invalidFeedback={formik.errors.witnesse2}
                      validFeedback='Assim está bom!'
                      placeholder="Nome da Testemunha do evento"
                    />
                  </FormGroup>
                  <FormGroup id="witnesse2Email" className='col-md-6 '>
                    <Input
                      ariaDescribedby='inputGroupPrepend'
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.witnesse2Email}
                      isValid={formik.isValid}
                      isTouched={formik.touched.witnesse2Email}
                      invalidFeedback={formik.errors.witnesse2Email}
                      validFeedback='Assim está bom!'
                      placeholder="Email da Testemunha do evento"
                    />
                  </FormGroup>
                </div>
              </div>
            </div>
          </WizardItem>
        </Wizard>
      </ModalBody>
    </Modal>
  )
}

export default ModalRegisterEstablishments;