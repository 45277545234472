import { useFormik } from 'formik';
import React, { memo, useCallback, useContext, useEffect, useState } from 'react';
import { DefaultContext } from '../../../contexts/default';
import PromotersLinkDB from '../../../database/wrappers/promotersLink';
import masks from '../../../helpers/utils/masks';
import PreAlert from '../../../helpers/utils/preAlert';
import BtnCancel from '../../MyCustom/BtnCancel';
import BtnConfirm from '../../MyCustom/BtnConfirm';
import BtnLoad from '../../MyCustom/BtnLoad';
import Modal, { ModalBody, ModalHeader, ModalTitle } from '../../bootstrap/Modal';
import Checks from '../../bootstrap/forms/Checks';
import FormGroup from '../../bootstrap/forms/FormGroup';
import Input from '../../bootstrap/forms/Input';

const validate = (values) => {
  const errors = {};


  if (!values.name) {
    errors.name = 'Este campo é necessário.';
  }

  return errors;
}

const ModalRegisterPromoterLink = ({ open, setIsOpen, setIsClose, promoterSelected }) => {

  const { onShowAlert, establishment, event } = useContext(DefaultContext)

  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (!open) return formik.resetForm();
    if (promoterSelected) {
      const { name, cpf,active } = promoterSelected;
      formik.setValues({
        name,
        active: active,
        cpf,
      });
    }
  }, [promoterSelected, open])

  const formik = useFormik({
    initialValues: {
      name: '',
      cpf: '',
      active: true,
    },
    validate,
    onSubmit: values => {
      const { name, cpf } = values;

      const data = {
        name,
        cpf,
        active: true,
      }

      const onSuccess = () => {
        onShowAlert(PreAlert.success('Registro cadastrado com sucesso'))
        setIsClose();
      }
      const onSuccessUpdate = () => {
        onShowAlert(PreAlert.success('Registro atualizado com sucesso'))
        setIsClose();
      }
      const onError = () => {
        onShowAlert(PreAlert.error('Falhou ao cadastrar o registro'))
      }

      setIsLoading(true);
      if (promoterSelected) {
        new PromotersLinkDB(establishment.id, event.id)
          .update(promoterSelected.id, data)
          .then(onSuccessUpdate)
          .catch(onError)
          .finally(() => setIsLoading(false))
      } else {
        new PromotersLinkDB(establishment.id, event.id)
          .create(data)
          .then(onSuccess)
          .catch(onError)
          .finally(() => setIsLoading(false))
      }
    },
  })

  const onChangeCpf = useCallback((e) => {
    formik.setFieldValue('cpf', masks.cpf(e.target.value))
  }, [])

  return (
    <Modal
      id={'modal-register-promoterLink'}
      titleId={'Cadastro e Update de Entrada de Bilheteria'}
      isOpen={open}
      setIsOpen={setIsOpen}
      isStaticBackdrop={true}
      isScrollable={false}
      isCentered={true}
      size="lg" // 'sm' || 'lg' || 'xl' 
      isAnimation={true}
      onSubmit={formik.handleSubmit}
    >
      <ModalHeader setIsOpen={setIsOpen}>
        <ModalTitle id="register-promoterSelected">{promoterSelected ? 'Atualização de Link Promoter' : 'Cadastro de Link'}</ModalTitle>
      </ModalHeader>
      <ModalBody>
        <form noValidate onSubmit={formik.handleSubmit}>

          {/* Inputs */}
          <div className="row g-4">

            {/* Nome */}
            <FormGroup id="name" label="Nome" className='col-md-7'>
              <Input
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.name}
                isValid={formik.isValid}
                isTouched={formik.touched.name}
                invalidFeedback={formik.errors.name}
                validFeedback='Assim está bom!'
                placeholder='Nome do Promoter'
              />
            </FormGroup>


            <FormGroup id='cpf' label='CPF' className='col-md-3'>
              <Input
                onChange={onChangeCpf}
                onBlur={formik.handleBlur}
                value={formik.values.cpf}
                isValid={formik.isValid}
                isTouched={formik.touched.cpf}
                invalidFeedback={formik.errors.cpf}
                validFeedback='Assim está bom!'
                placeholder='000.000.000-00'
              />
            </FormGroup>
            {/* Status */}
            
          </div>
          {/* Buttons */}
          <div className="row pt-4">
            <div className="col-md-4 offset-md-8">
              <div className='d-flex justify-content-evenly'>
                <BtnCancel setIsClose={setIsClose} />
                {isLoading ? (
                  <BtnLoad />
                ) : (
                  <BtnConfirm isDisable={!formik.isValid} />
                )}
              </div>
            </div>
          </div>
        </form>
      </ModalBody>
    </Modal>
  )
}

export default memo(ModalRegisterPromoterLink);