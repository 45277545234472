import { memo, useContext, useMemo } from "react";
import Select from "../../bootstrap/forms/Select";
import { DefaultContext } from "../../../contexts/default";
import FormGroup from "../../bootstrap/forms/FormGroup";

const SelectSector = (props) => {

  const { sectors, sectorSelected, setsectorSelected } = useContext(DefaultContext);

  const options = useMemo(() => sectors.map(item => ({ value: item.id, text: item.name })), [sectors])

  return (
    <FormGroup id="sector" label="Setor" className={`${props.class ? props.class : 'col-md-4'} mb-3`}>
      <Select
        value={sectorSelected}
        list={options}
        onChange={e => setsectorSelected(e.target.value)}
        disabled={props.disabled}
      />
    </FormGroup>
  );
}

export default memo(SelectSector);