import React from "react";
import HeaderContrato from "./header";
import BodyContract from "./bodyContract";
import LastPageContract from "./lastPageContract";

const EstablishmentContract = ({ estabelecimento }) => {
    return (
        <div>
            <HeaderContrato establishment={estabelecimento} />
            <BodyContract establishment={estabelecimento} />
            <LastPageContract establishment={estabelecimento} />
        </div>
    );
}

export default EstablishmentContract;
