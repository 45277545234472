import React, { useMemo } from "react";
import Money from "../../../helpers/utils/money/money";
import TableReport from "../TableReport";

const TransferTable = ({ establishment, resume }) => {

  // ========= TABELA DE DADOS BANCARIOS  =========
  const columnsBankData = useMemo(() => ([
    {
      label: 'BANCO',
      field: 'bank'
    },
    {
      label: 'CODIGO DO BANCO',
      field: 'bankCode',
    },
    {
      label: 'AGÊNCIA',
      field: 'agency',
    },
    {
      label: 'CONTA CORRENTE',
      field: 'account',
    },
    {
      label: 'PIX',
      field: 'pix',
    }
  ]), [])

  const rowsBankData = useMemo(() =>
  ([
    {
      bank: establishment?.bankAccount?.bank.toUpperCase(),
      bankCode: establishment?.bankAccount?.bankCode,
      agency: establishment?.bankAccount?.agency,
      account: establishment?.bankAccount?.account,
      pix: establishment?.bankAccount?.pix,
    },
  ]), [establishment])

  if(!resume) return null;

  return (
    <>
      <div className=''>
        <h4 className='text-center p-4 fw-bold'>FECHAMENTO UGET</h4>
        <table className='table table-striped table-fs-litle'>
          <tbody>
            <tr>
              <td>VALOR TOTAL INTERMEDIADO ATRAVÉS SISTEMA EM DÉBITO + CRÉDITO + PIX</td>
              <td className="text-tb-align-right">{Money.centsToMaskMoney(resume.total_intermediated)}</td>
            </tr>
            <tr>
              <td>VALOR REFERENTE AO SERVIÇO PRESTADO PELA PLATAFORMA UGET ;-)</td>
              <td className="text-tb-align-right">{Money.centsToMaskMoney(resume.total_uget_gross)}</td>
            </tr>
            <tr>
              <td>RETENÇÃO IRRF (1,5%)<br/>(ATENÇÃO: ESSE VALOR DEVERÁ SER INFORMADO NA DCTFWEB/REINF DA {establishment?.reason} E RECOLHIDO PARA A RECEITA FEDERAL  / **RETENÇÃO DISPENSADA CASO VALOR ESTEJA  ABAIXO DO MÍNIMO**.
)</td>
              <td className="text-tb-align-right">{Money.centsToMaskMoney(resume.tax_irrf)}</td>
            </tr>
            <tr>
              <td>VALOR LÍQUIDO DISPONÍVEL PARA A UGET ;-)</td>
              <td className="text-tb-align-right">{Money.centsToMaskMoney(resume.total_liquid)}</td>
            </tr>
            <tr className="fw-bold">
              <td>VALOR TOTAL DO REPASSE</td>
              <td className="text-tb-align-right">{Money.centsToMaskMoney(resume.full_transfer)}</td>
            </tr>
            <tr className="fw-bold">
              <td>VALOR TOTAL DO REPASSE + RETENÇÃO IRRF</td>
              <td className="text-tb-align-right">{Money.centsToMaskMoney(resume.full_transfer + resume.tax_irrf)}</td>
            </tr>
          </tbody>
        </table>
      </div>
      <div className='d-flex flex-column col-xxl-12 justify-content-around p-4'>
        <h6> * O Repasse será realizado na conta corrente indicada pelo CLIENTE.</h6>
        <TableReport
          rows={rowsBankData}
          columns={columnsBankData}
          keyExtractor={(_, index) => index.toString()}
        />
      </div>
    </>
  );
}

export default TransferTable