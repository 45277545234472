import React, { useState, useEffect, useContext, useCallback, useMemo } from 'react';
import PageWrapper from '../../../../layout/PageWrapper/PageWrapper';
import SubHeader, { SubHeaderLeft, SubHeaderRight } from '../../../../layout/SubHeader/SubHeader';
import Page from '../../../../layout/Page/Page';
import Breadcrumb from '../../../../components/bootstrap/Breadcrumb';
import { demoPages } from '../../../../menu';
import Card, { CardBody, CardHeader, CardLabel, CardTitle } from '../../../../components/bootstrap/Card';
import Button from '../../../../components/bootstrap/Button';
import ModalRegisterSubCategories from '../../../../components/modals/SubCategories/ModalRegister';
import { DefaultContext } from '../../../../contexts/default';
import SubCategoryDB from '../../../../database/wrappers/subCategory';
import SelectEstablishment from '../../../../components/MyCustom/SelectEstablishment';
import TableCustom from '../../../../components/MyCustom/TableCustom';
import showNotification from '../../../../components/extras/showNotification';
import notificationBody from '../../../../helpers/utils/functions/notificationBody';
import { orderBy } from 'firebase/firestore';
import { ACCESS_LEVEL } from '../../../../types/roles';
import useDarkMode from '../../../../hooks/useDarkMode';

const SubCategories = () => {
  const { darkModeStatus } = useDarkMode();

  const { accessLevel, estabSelected, onShowAlert, onShowQuestion } = useContext(DefaultContext);

  const [openModalRegister, setOpenModalRegister] = useState(false);
  const [subCategories, setSubCategories] = useState([]);
  const [subCategorySelected, setSubCategorySelected] = useState({});

  useEffect(() => {
    if (!estabSelected) return;
    const onSubscribe = new SubCategoryDB(estabSelected).on(setSubCategories, orderBy('name', 'asc'));
    return onSubscribe;
  }, [estabSelected])

  const handleOpenModalRegister = useCallback(() => {
    setSubCategorySelected(null)
    setOpenModalRegister(true);
  }, [])

  const handleCloseModalRegister = useCallback(() => {
    setOpenModalRegister(false);
  }, [])

  const handleOpenModalEdit = useCallback((item) => {
    setSubCategorySelected(item);
    setOpenModalRegister(true)
  }, [])

  const handleOpenModalDelete = useCallback((id) => {
    onShowQuestion({
      title: 'Exclusão de Sub-categoria',
      message: 'Você está excluindo uma sub-categoria. Está ação é irreversível. Deseja continuar?',
      onConfirm: () => {
        new SubCategoryDB(estabSelected)
          .delete(id)
          .then(res => showNotification('', notificationBody('Registro deletado com sucesso'), 'success'))
          .catch(err => showNotification('', notificationBody('Falhou ao deletar o registro'), 'danger'))
      }
    })
  }, [estabSelected])

  const handleChangeStatus = useCallback((id, active) => {
    new SubCategoryDB(estabSelected)
      .update(id, {
        active: !active
      })
      .catch(error => onShowAlert({
        title: 'ERRO!',
        msm: 'Não foi possível atualizar o status do produto',
        icon: 'Error',
        color: 'danger'
      }))
  }, [estabSelected])

  const columns = useMemo(() => ([
    {
      label: 'Imagem',
      field: 'image_url',
      format: row => (
        <img src={row.image_url} alt="Imagem" width={54} height={54} />
      ),
      noExport: true,
    },
    {
      label: 'Nome',
      field: 'name',
    },
    {
      label: 'QTD Produtos',
      field: 'products',
      format: row => row.products.length,
      formatExport: data => data.length,
    },
    {
      label: 'Status',
      field: 'active',
      format: row => (
        <Button
          isLink
          color={row.active ? 'success' : 'danger'}
          icon='Circle'
          onClick={() => handleChangeStatus(row.id, row.active)}
          className='text-nowrap'>
          {row.active ? 'Ativo' : 'Inativo'}
        </Button>
      )
    },
    {
      label: 'Ações',
      field: 'actions',
      format: row => (
        <div className='w-25 d-flex justify-content-between'>
          <Button
            color={darkModeStatus ? 'light' : 'dark'}
            icon="edit"
            shadow="sm"
            hoverShadow="sm"
            size="sm"
            onClick={() => handleOpenModalEdit(row)}
          />

          <Button
            color="danger"
            icon="Trash"
            shadow="sm"
            hoverShadow="sm"
            size="sm"
            onClick={() => handleOpenModalDelete(row.id)}
          />
        </div>
      ),
      noExport: true
    },
  ]), [darkModeStatus, handleChangeStatus, handleOpenModalEdit, handleOpenModalDelete])

  return (
    <>
      <PageWrapper title={demoPages.pdvMenu.subMenu.subCategories.text}>
        <SubHeader>
          <SubHeaderLeft>
            <Breadcrumb
              list={[
                { title: 'Cadastros', to: '/pdv/subCategories' },
                {
                  title: 'Sub-Categorias',
                  to: '/pdv/subCategories',
                },
              ]}
            />
          </SubHeaderLeft>
          <SubHeaderRight>
            {accessLevel >= ACCESS_LEVEL.MANAGER &&
              <Button
                color='primary'
                icon='plus'
                shadow="sm"
                hoverShadow="sm"
                onClick={handleOpenModalRegister}
              >
                Cadastrar
              </Button>}
          </SubHeaderRight>
        </SubHeader>
        <Page container="flui">
          <Card>
            <CardHeader>
              <CardLabel icon='Subtract' iconColor={darkModeStatus ? 'light' : 'dark'}>
                <CardTitle>Sub-Categorias</CardTitle>
              </CardLabel>
            </CardHeader>
            <CardBody className='table-responsive'>
              <SelectEstablishment />
              <TableCustom 
                titlePdf='Sub-Categorias'
                icon='Subtract'
                columns={columns} 
                rows={subCategories} 
                 fileName="Sub-categorias"
              />

            </CardBody>
          </Card>
        </Page>
      </PageWrapper>

      <ModalRegisterSubCategories
        open={openModalRegister}
        setIsOpen={setOpenModalRegister}
        setIsClose={handleCloseModalRegister}
        subCategorySelected={subCategorySelected}
      />
    </>
  );
};

export default SubCategories;
