import { useContext, useEffect, useMemo, useState } from "react";
import { DefaultContext } from "../../contexts/default";
import RechargeEventData from "../../database/wrappers/eventData/recharge";
import { PAYMENT_TYPE } from "../../types/payment";
import TaxDevolutionEventData from "../../database/wrappers/eventData/taxaDevolution";
import TaxDevolution from "../../database/entities/eventData/taxaDevolution.entity";

const useTotalTaxDevolution = () => {
  const { estabSelected, eventSelected, dataFilter } = useContext(DefaultContext);
  const [taxDevolution, setTaxDevolution] = useState<TaxDevolution[]>([]);
  const firstDate = new Date(dataFilter.firstDate);
  const secondDate = new Date(dataFilter.secondDate);

  useEffect(() => {
    let isMounted = true;
    const loadTaxDevolution = () => {
      if (estabSelected && eventSelected) {
        new TaxDevolutionEventData(estabSelected, eventSelected)
          .getAllInterval(firstDate, secondDate)
          .then(taxDevolution => {
            if (isMounted) {
              setTaxDevolution(taxDevolution)
            }
          }
          );
      }
    };
    loadTaxDevolution();
    return () => {
      isMounted = false;
    }
  }, [estabSelected, eventSelected,dataFilter]);

  const TaxDevolutionCalc = useMemo(() => {
    if (!taxDevolution) return {
      totalTaxDevolution: 0
    };

    const totalTaxDevolution = taxDevolution.reduce((prev, curr) => prev + curr.value, 0);

    return {
      totalTaxDevolution
    };
  }, [taxDevolution]);

  return { TaxDevolutionCalc };
};

export default useTotalTaxDevolution;
