import React from "react";
import Logo from "../../Logo";


const HeaderContrato = ({ establishment, event }) => {
    return (
        <div className='row px-2 m-5'>
            <div className='col text-center'>
                <Logo width={200} black />
                <h6 className='my-3 fw-bold'>ORDEM DE SERVIÇO n°000{event?.codeNumber || 0} – REFERENTE A CONTRATO n° {establishment?.codeNumber || 0}</h6>
                <a>Anexo ordem de serviço referente ao contrato n° 000{establishment?.codeNumber || 0}, com as informações relevantes a organização do evento,
                    descritivo técnico, condições comerciais, prazo de pagamento, outras taxas e negociações e disposições gerais. </a>
            </div>

        </div>
    );
}

export default HeaderContrato;