import React, { useMemo } from "react";
import Money from "../../../helpers/utils/money/money";

const AdditionalServices = ({ typeOfChargeApplied, equipaments, closureResourcesEvent,custoOperacional, responsibles, technical, resume }) => {

  const [responsible_total_vlDiaria, technical_total_vlDiaria, responsible_total_vldHora, technical_total_vldHora] = useMemo(() => {
    if(!responsibles || !technical) return [0,0];
    return [
      responsibles.reduce((prev, curr) => prev + curr.total_daily, 0),
      technical.reduce((prev, curr) => prev + curr.total_daily, 0),
      responsibles.reduce((prev, curr) => prev + curr.total_hours, 0),
      technical.reduce((prev, curr) => prev + curr.total_hours, 0),
    ]
  },[responsibles, technical])

  const qtdSentTotems = useMemo(() => {
    if(!equipaments) return 0;
    const totems = equipaments.find(r => r.key === 'totems')
    const totems_recharges = equipaments.find(r => r.key === 'totems_recharges')
    return (totems?.qtdSent || 0) + (totems_recharges?.qtdSent || 0);
  },[equipaments])

  if (!responsibles || !technical || !equipaments) return null;

  // const rentDevicesNoTotems = equipamentsValues.total_rent - totalRentTotems;

  const qtdDiaria = closureResourcesEvent.technical.daily;

  const technicalCount = technical.length;
  const liderCount = responsibles.length;

  console.log(resume.services_provided, 'AQUI')
  return (
    <div>
      <h4 className='text-center p-4 fw-bold'>SERVIÇOS ADICIONAIS</h4>
      <table className='table table-striped table-fs-litle'>
        <tbody>
          <tr >
            <td>LIDERES uGet ;-) {qtdDiaria} DIÁRIA DE 10 HORAS CADA</td>
            <td>QUANTIDADE LIDERES: {liderCount}</td>
            <td>VALOR POR DIÁRIA: {Money.centsToMaskMoney(custoOperacional.daily.coordinator)}</td>
            <td className="text-tb-align-right">{Money.centsToMaskMoney(responsible_total_vlDiaria)}</td>
          </tr>
          <tr>
            <td>TÉCNICOS uGet ;-) {qtdDiaria} DIÁRIA DE 10 HORAS CADA</td>
            <td>QUANTIDADE TÉCNICOS: {technicalCount}</td>
            <td>VALOR POR DIÁRIA: {Money.centsToMaskMoney(custoOperacional.daily.technical)}</td>
            <td className="text-tb-align-right">{Money.centsToMaskMoney(technical_total_vlDiaria)}</td>
          </tr>
          <tr>
            <td>HORA ADICIONAL LIDERES uGet ;-)</td>
            <td>QUANTIDADE HORAS: {closureResourcesEvent.technical.extra_hour}</td>
            <td>VALOR HORA EXTRA: {Money.centsToMaskMoney(custoOperacional.hours.coordinator)}</td>
            <td className="text-tb-align-right">{Money.centsToMaskMoney(responsible_total_vldHora)}</td>
          </tr>
          <tr>
            <td>HORA ADICIONAL TÉCNICOS uGet ;-)</td>
            <td>QUANTIDADE HORAS: {closureResourcesEvent.technical.extra_hour}</td>
            <td>VALOR HORA EXTRA: {Money.centsToMaskMoney(custoOperacional.hours.technical)}</td>
            <td className="text-tb-align-right">{Money.centsToMaskMoney(technical_total_vldHora)}</td>
          </tr>
          <tr>
            <td colSpan='3'>FRETE</td>
            <td className="text-tb-align-right">{Money.centsToMaskMoney(closureResourcesEvent.technical.freight)}</td>
          </tr>
          <tr>
            <td colSpan='3'>PASSAGENS</td>
            <td className="text-tb-align-right">{Money.centsToMaskMoney(closureResourcesEvent.technical.ticket)}</td>
          </tr>
          <tr>
            <td colSpan='3'>UBER</td>
            <td className="text-tb-align-right">{Money.centsToMaskMoney(closureResourcesEvent.technical.uber)}</td>
          </tr>
          <tr>
            <td colSpan='3'>ALIMENTAÇÃO</td>
            <td className="text-tb-align-right">{Money.centsToMaskMoney(closureResourcesEvent.technical.food)}</td>
          </tr>
          <tr>
            <td colSpan='3'>HOSPEDAGEM TÉCNICOS</td>
            <td className="text-tb-align-right">{Money.centsToMaskMoney(closureResourcesEvent.technical.accommodation)}</td>
          </tr>
          
          <tr>
            <td colSpan='3'>INTERNET SATÉLITE</td>
            <td className="text-tb-align-right">{Money.centsToMaskMoney(closureResourcesEvent.internet.satellite)}</td>
          </tr>
          <tr>
            <td colSpan='3'>INTERNET WIFI</td>
            <td className="text-tb-align-right">{Money.centsToMaskMoney(closureResourcesEvent.internet.wifi)}</td>
          </tr>
          <tr className="fw-bold">
            <td colSpan='3'>TOTAL SERVIÇOS ADICIONAIS uGet ;-) </td>
            <td className="text-tb-align-right">{Money.centsToMaskMoney(resume.services_provided)}</td>
          </tr>
        </tbody>
      </table>
    </div>
  );
}

export default AdditionalServices;