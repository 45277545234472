import React, { memo, useMemo } from 'react';
import Card, { CardActions, CardBody, CardHeader, CardLabel, CardTitle } from '../../../components/bootstrap/Card';
import CardValue from '../../../components/MyCustom/Dashboard/CardValue';
import GraphicDash from '../../../components/MyCustom/Dashboard/GraphicDash';
import Money from '../../../helpers/utils/money/money';
import useDarkMode from '../../../hooks/useDarkMode';

const Movimentacao = ({ courtesy, recargaAntecipada }) => {
  const { darkModeStatus } = useDarkMode();

  return (
    <Card>
      <CardHeader>
        <CardLabel icon='PieChart' iconColor={!darkModeStatus ? 'dark' : 'light'}>
          <CardTitle className='d-flex flex-row align-items-center'>
            <CardActions>
              MOVIMENTAÇÃO
            </CardActions>
          </CardTitle>
        </CardLabel>
      </CardHeader>
      <CardBody className='row col-md-12'>
        <div className='col-md-6'>
          <GraphicDash
            height={window.innerWidth < 550 ? 125 : 190}
            labels={[
              'Cortesia',
              'Recarga Antecipada',
            ]}
            series={[
              Money.centsToCoin(courtesy),
              Money.centsToCoin(recargaAntecipada),
            ]}
          />
        </div>
        <div className='row col-md-6' style={{ height: 100 }}>
          <div className='col-md-12'>
            <CardValue
              color='dark'
              label='TOTAL'
              value={Money.centsToMaskMoney(courtesy + recargaAntecipada)}
            />
          </div>
          <div className='col-md-6'>
            <CardValue
              color='primary'
              label='Cortesia'
              value={Money.centsToMaskMoney(courtesy)}
            />
          </div>
          <div className='col-md-6'>
            <CardValue
              color='secondary'
              label='Recarga Antecipada'
              value={Money.centsToMaskMoney(recargaAntecipada)}
            />
          </div>
        </div>
      </CardBody>
    </Card>
  );
}

export default memo(Movimentacao);