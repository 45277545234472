import React, { useContext, useEffect, useMemo, useState } from 'react'
import Card, { CardHeader, CardLabel, CardTitle, CardBody } from '../../../../../components/bootstrap/Card';
import CardValue from '../../../../../components/MyCustom/Dashboard/CardValue';
import useDarkMode from '../../../../../hooks/useDarkMode';
import Money from '../../../../../helpers/utils/money/money';
import useTotalEntrance from '../../../../../hooks/TotaisReports/useTotalEntrance';


const TotaisBySector = () => {
  const { darkModeStatus } = useDarkMode()
  const {EntrancesSector} = useTotalEntrance();

  return (
    <Card>
      <CardHeader>
        <CardLabel icon='PointOfSale' iconColor={!darkModeStatus ? 'dark' : 'light'}>
          <CardTitle>TOTAIS POR SETOR</CardTitle>
        </CardLabel>
      </CardHeader>
      <CardBody>
        <div className='row align-items-center' >
          <div className='col-md'>
            <CardValue
              color='dark'
              label='TOTAL DE INGRESSOS'
              value={Money.centsToMaskMoney(EntrancesSector.totalIngresso)}
            />
          </div>
          <div className='col-md'>
            <CardValue
              color='primary'
              label='TOTAL DE CONSUMAÇÃO'
              value={Money.centsToMaskMoney(EntrancesSector.totalConsumacao)}
            />
          </div>
          <div className='col-md' >
            <CardValue
              color='secondary'
              label='TOTAL GERAL'
              value={Money.centsToMaskMoney(EntrancesSector.totalConsumacao + EntrancesSector.totalIngresso)}
            />
          </div>

        </div>
      </CardBody>
    </Card>
  )
}

export default TotaisBySector
