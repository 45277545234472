import React, { useCallback, useContext, useEffect, useState, useMemo } from 'react';
import PageWrapper from '../../../../layout/PageWrapper/PageWrapper';
import SubHeader, { SubHeaderLeft, SubHeaderRight } from '../../../../layout/SubHeader/SubHeader';
import Page from '../../../../layout/Page/Page';
import Breadcrumb from '../../../../components/bootstrap/Breadcrumb';
import { demoPages } from '../../../../menu';
import Card, { CardBody, CardHeader, CardLabel, CardTitle } from '../../../../components/bootstrap/Card';
import Button from '../../../../components/bootstrap/Button';
import { DefaultContext } from '../../../../contexts/default';
import TableCustom from '../../../../components/MyCustom/TableCustom';
import useDarkMode from '../../../../hooks/useDarkMode';
import UserOperatorsDB from '../../../../database/wrappers/usersOperators';
import { orderBy } from 'firebase/firestore';
import masks from '../../../../helpers/utils/masks';
import ModalRegisterUsersOperators from '../../../../components/modals/UsersOperators/ModalRegister';
import { cpfFormatMask } from '../../../../utils/format/cpfFormat';
import showNotification from '../../../../components/extras/showNotification';
import notificationBody from '../../../../helpers/utils/functions/notificationBody';

const UsersOperators = () => {
  const { darkModeStatus } = useDarkMode();

  const { onShowAlert, onShowQuestion } = useContext(DefaultContext);

  const [openModalRegister, setOpenModalRegister] = useState(false);
  const [usersOperators, setUsersOperators] = useState([])
  const [userSelected, setUserSelected] = useState(null)

  useEffect(() => {
    return new UserOperatorsDB().on(setUsersOperators, orderBy('name', 'asc'))
  }, [])

  const handleOpenModalRegister = useCallback(() => {
    setUserSelected(null)
    setOpenModalRegister(true);
  }, [])

  const handleCloseModalRegister = useCallback(() => {
    setOpenModalRegister(false);
  }, [])

  const handleOpenModalEdit = useCallback((item) => {
    setUserSelected(item);
    setOpenModalRegister(true)
  }, [])

  const handleChangeStatus = useCallback((id, active) => {
    new UserOperatorsDB()
      .update(id, {
        active: !active
      })
      .catch(error => onShowAlert({
        title: 'ERRO!',
        msm: 'Não foi possível atualizar o status',
        icon: 'Error',
        color: 'danger'
      }))
  }, [])

  const handleOpenModalDelete = useCallback((id) => {
    onShowQuestion({
      title: 'Exclusão de Produto',
      message: 'Você está excluindo um produto. Está ação é irreversível. Deseja continuar?',
      onConfirm: () => {
        new UserOperatorsDB()
          .delete(id)
          .then(res => showNotification('', notificationBody('Registro deletado com sucesso'), 'success'))
          .catch(err => showNotification('', notificationBody('Falhou ao deletar o registro'), 'danger'))
      }
    })
  }, [])

  const rowsRender = useMemo(() =>
    usersOperators.map(row => ({
      ...row,
      cpfLabel: cpfFormatMask(row.cpf),
      phoneLabel: row.phone ? masks.phone(row.phone.toString()) : '',
    }))
    , [usersOperators])

  const columns = useMemo(() => ([
    {
      label: 'Nome',
      field: 'name',
    },
    {
      label: 'Cpf',
      field: 'cpfLabel',
    },
    {
      label: 'Telefone',
      field: 'phoneLabel',
    },
    {
      label: 'Status',
      field: 'active',
      format: row => (
        <Button
          isLink
          color={row.active ? 'success' : 'danger'}
          icon='Circle'
          onClick={() => handleChangeStatus(row.id, row.active)}
          className='text-nowrap'>
          {row.active ? 'Ativo' : 'Inativo'}
        </Button>
      )
    },
    {
      label: 'Ações',
      field: 'actions',
      format: row => (
        <div className='w-50 d-flex justify-content-between'>
          <Button
            color={darkModeStatus ? 'light' : 'dark'}
            icon="edit"
            shadow="sm"
            hoverShadow="sm"
            size="sm"
            onClick={() => handleOpenModalEdit(row)}
          />
          <Button
            color="danger"
            icon="Trash"
            shadow="sm"
            hoverShadow="sm"
            size="sm"
            onClick={() => handleOpenModalDelete(row.id)}
          />
        </div>
      ),
      noExport: true
    },
  ]), [darkModeStatus, handleOpenModalEdit, handleOpenModalDelete])
  

  return (
    <>
      <PageWrapper title={demoPages.pageLayout.subMenu.usersOperators.text}>
        <SubHeader>
          <SubHeaderLeft>
            <Breadcrumb
              list={[
                { title: 'Cadastros', to: '/registers/operators' },
                {
                  title: 'Operadores',
                  to: '/registers/operators',
                },
              ]}
            />
          </SubHeaderLeft>
          <SubHeaderRight>
            <Button
              color='primary'
              icon='plus'
              shadow="sm"
              hoverShadow="sm"
              onClick={handleOpenModalRegister}
            >
              Cadastrar
            </Button>
          </SubHeaderRight>
        </SubHeader>
        <Page container='fluid'>
          <Card>
            <CardHeader>
              <CardLabel icon='SupervisorAccount' iconColor={darkModeStatus ? 'light' : 'dark'}>
                <CardTitle>Operadores</CardTitle>
              </CardLabel>
            </CardHeader>
            <CardBody className='table-responsive'>
              <TableCustom
                titlePdf='Operadores'
                icon="SupervisorAccount"
                rows={rowsRender}
                columns={columns}
                keyExtractor={item => item.id}
                fileName="Operadores"
              />
            </CardBody>
          </Card>
        </Page>
      </PageWrapper>

      <ModalRegisterUsersOperators
        open={openModalRegister}
        setIsOpen={setOpenModalRegister}
        setIsClose={handleCloseModalRegister}
        editData={userSelected}
      />
    </>
  );
};

export default UsersOperators;
