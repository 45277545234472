import React, { memo } from 'react';
import './styles.css'

const TableReport = ({ columns, rows, keyExtractor, title, rowFooter, fontSize, fontSizeFooter, }) => {

	return (
		<div>
			{title != null && <h4 className={[`text-center p-4 fw-bold`]} >{title}</h4>}

			<table className='table table-striped'>
				<thead >
					<tr>
						{columns.map((column) => (
							<th key={'tab-head-' + column.label} style={{ fontSize: fontSize || 12 }}>
								{column.label.toUpperCase()}
							</th>
						))}
					</tr>
				</thead>
				<tbody>
					{rows.map((item, index) => (
						<tr key={keyExtractor ? keyExtractor(item, index) : item.id}
							style={item.bg && { backgroundColor: item.bg }}>
							{columns.map((c, index2) => (
								<td key={`tab-cell-${item.id}-${index}-${index2}`} style={{ fontSize: fontSize || 12, width: c.width }} className={c.className}>
									{c.format ? c.format(item) : item[c.field]}
								</td>
							))}
						</tr>
					))}
					{!!rowFooter && (
						<tr>
							{columns.map((c, index2) => (
								<td key={`row-footer-${index2}`} className={'fw-bold table-not-break-line-white-space ' + c.className} style={{ fontSize: fontSize || 12 }}>
									{rowFooter[c.field]}
								</td>
							))}
						</tr>
					)}
				</tbody>
			</table>
		</div>
	);
};

export default memo(TableReport);
