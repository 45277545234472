import { useFormik } from 'formik';
import React, { useCallback, useContext, useEffect, useState } from 'react';
import Card, { CardBody, CardHeader, CardLabel, CardTitle } from '../../../../components/bootstrap/Card';
import BtnConfirm from '../../../../components/MyCustom/BtnConfirm';
import BtnLoad from '../../../../components/MyCustom/BtnLoad';
import SelectEstablishment from '../../../../components/MyCustom/SelectEstablishment';
import SelectEvent from '../../../../components/MyCustom/SelectEvent';
import SettingsEvent from '../../../../components/MyCustom/SettingsEvent';
import { loadSettingsData, settingInitValues, settingMountSaveData, settingValidateForm } from '../../../../components/MyCustom/SettingsEvent/settingsUtils';
import SettingDB from '../../../../database/wrappers/settings';
import PreAlert from '../../../../helpers/utils/preAlert';
import useDarkMode from '../../../../hooks/useDarkMode';
import Page from '../../../../layout/Page/Page';
import PageWrapper from '../../../../layout/PageWrapper/PageWrapper';
import { demoPages } from '../../../../menu';

import { DefaultContext } from '../../../../contexts/default';

const Settings = () => {
  const { darkModeStatus } = useDarkMode();

  const [setting, setsetting] = useState(null)
  const [loading, setloading] = useState(false)

  const { estabSelected, eventSelected, onShowAlert } = useContext(DefaultContext);

  useEffect(() => {
    if(!estabSelected || !eventSelected) return;
    const onSubscriber = new SettingDB(estabSelected, eventSelected).on(setsetting)
    return () => {
      onSubscriber();
      formik.resetForm();
    }
  },[estabSelected, eventSelected])

  useEffect(() => {
    if(setting) {
      formik.setValues(loadSettingsData(setting))
    } else {
      formik.resetForm();
    }
  },[setting])

  const validate = useCallback((values) => {
    const errors = {};
  
    settingValidateForm(values, errors)
  
    return errors;
  },[])

  const formik = useFormik({
    initialValues: settingInitValues,
    validate,
    onSubmit: values => {

      const data = settingMountSaveData(values)

      if(!estabSelected || !eventSelected)
        return onShowAlert(PreAlert.error('Selecione um evento para definir as configurações'))

      const onSuccessUpdate = () => {
        onShowAlert(PreAlert.success('Registro atualizado com sucesso'))
      }
      const onError = (error) => {
        console.error(error);
        onShowAlert(PreAlert.error('Falhou ao cadastrar o registro'))
      }

      setloading(true);
      new SettingDB(estabSelected, eventSelected)
        .define(data)
        .then(onSuccessUpdate)
        .catch(onError)
        .finally(() => setloading(false))
    }
  });

  return (
    <PageWrapper title={demoPages.event.subMenu.settings.text}>
      <Page>
        <form onSubmit={formik.handleSubmit}>
        <div className='row d-flex align-items-center mt-4 mx-1' >
          <SelectEstablishment />
          <SelectEvent />
          
          <div className='d-flex flex-row w-100 justify-content-end'>
            {loading ? (
              <BtnLoad />
            ) : (
              <BtnConfirm />
            )}
          </div>
        </div>
        <div className="row">
          <Card>
            <CardHeader>
              <CardLabel icon='Settings' iconColor={darkModeStatus ? 'light' : 'dark'}>
                <CardTitle>Configurações do Evento</CardTitle>
              </CardLabel>
            </CardHeader>
            <CardBody>
            <div className='row align-items-center justify-content-center'>
              <SettingsEvent isModal={true} formik={formik} />
            </div>
            </CardBody>
          </Card>
        </div>
        </form>
      </Page>
    </PageWrapper >
  );
};

export default Settings;