import React, { useState, useContext, useEffect, memo } from 'react';
import { useFormik } from 'formik';
import FormGroup from '../../bootstrap/forms/FormGroup';
import Input from '../../bootstrap/forms/Input';
import Modal, { ModalBody, ModalHeader, ModalTitle } from '../../bootstrap/Modal';
import { serverTimestamp, increment } from 'firebase/firestore'

import { DefaultContext } from '../../../contexts/default';
import PreAlert from '../../../helpers/utils/preAlert';
import StockProductDB from '../../../database/wrappers/stockProduct';
import BtnCancel from '../../MyCustom/BtnCancel';
import BtnLoad from '../../MyCustom/BtnLoad';
import BtnConfirm from '../../MyCustom/BtnConfirm';

const validate = (values) => {
  const errors = {};

  if (!values.qtd || isNaN(parseInt(values.qtd))) {
    errors.qtd = 'Informe um número válido';
  }

  return errors;
}

const ModalRegisterStockProducts = ({ open, setIsOpen, setIsClose, stockSelected }) => {

  const { establishment, event, sector, onShowAlert, user } = useContext(DefaultContext)

  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if(!open) return formik.resetForm();
  }, [stockSelected, open])

  const formik = useFormik({
    initialValues: {
      active: '',
      otp: '',
    },
    validate,
    onSubmit: values => {
      const { qtd } = values;

      const data = {
        qtd: !stockSelected ? qtd : increment(qtd),
        authorEdit: user.uid,
        updated_at: serverTimestamp(),
      }

      const onSuccess = () => {
        onShowAlert(PreAlert.success('Registro cadastrado com sucesso'))
        setIsClose();
      }
      const onSuccessUpdate = () => {
        onShowAlert(PreAlert.success('Registro atualizado com sucesso'))
        setIsClose();
      }
      const onError = (error) => {
        console.error(error);
        onShowAlert(PreAlert.error('Falhou ao cadastrar o registro'))
      }

      setIsLoading(true)
      if (stockSelected.stockExist) {
        console.log('Atualiza');
        new StockProductDB(establishment.id, event.id, sector.id)
          .update(stockSelected.id, data)
          .then(onSuccessUpdate)
          .catch(onError)
          .finally(() => setIsLoading(false))
      } else {
        console.log('Define');
        new StockProductDB(establishment.id, event.id, sector.id)
          .define(stockSelected.id, data)
          .then(onSuccess)
          .catch(onError)
          .finally(() => setIsLoading(false))
      }
      
    },
  })

  return (
    <Modal
      id={'modal-register-totem'}
      titleId={'Cadastro de Totem'}
      isOpen={open}
      setIsOpen={setIsOpen}
      isStaticBackdrop={true}
      isScrollable={false}
      isCentered={true}
      size="lg" // 'sm' || 'lg' || 'xl' 
      isAnimation={true}
      onSubmit={formik.handleSubmit}
    >
      <ModalHeader setIsOpen={setIsOpen}>
        <ModalTitle id="register-totem">{stockSelected ? 'Atualização Estoque de Produto' : 'Cadastro Estoque de Produto'}</ModalTitle>
      </ModalHeader>
      <ModalBody>
        <form noValidate onSubmit={formik.handleSubmit}>
          <div className='d-flex flex-column align-items-center col-md-12'>
            <img src={stockSelected?.image_url} height={100} width={100} />
            <h3 style={{ marginTop: 20, marginBottom: 20 }}>{stockSelected?.name}</h3>
          </div>
          {/* Inputs */}
          <div className="row g-4">
            {/* key */}
            <FormGroup
              id='qtd' label='Quantidade' className='col-md-4'
            >
              <Input
                type="number"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.qtd}
                isValid={formik.isValid}
                isTouched={formik.touched.qtd}
                invalidFeedback={formik.errors.qtd}
                validFeedback='Assim está bom!'
                placeholder='Insira a Key'
              />
            </FormGroup>
          </div>



          {/* Buttons */}
          <div className="row pt-4">
            <div className="col-md-4 offset-md-8">
              <div className='d-flex justify-content-evenly'>
                <BtnCancel setIsClose={setIsClose} />
                {isLoading ? (
                  <BtnLoad />
                ) : (
                  <BtnConfirm isDisable={!formik.isValid} />
                )}
              </div>
            </div>
          </div>
        </form>
      </ModalBody>
    </Modal>
  )
}

export default memo(ModalRegisterStockProducts);