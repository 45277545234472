import React, { memo, useState } from "react";
import Button from "../../bootstrap/Button";
import Dropdown, { DropdownMenu, DropdownToggle } from "../../bootstrap/Dropdown";
import FormGroup from "../../bootstrap/forms/FormGroup";
import Input from "../../bootstrap/forms/Input";
import Label from "../../bootstrap/forms/Label";
import dateFormat from "dateformat";

function FilterByDate({ onSearch }) {
  const [openFilter, setopenFilter] = useState(false)
  const [startDate, setstartDate] = useState(dateFormat(new Date(), 'yyyy-mm-dd 00:00:00'))
  const [endDate, setendDate] = useState(dateFormat(new Date(), 'yyyy-mm-dd 23:59:59'))

  const onClickSearch = () => {
    onSearch({ startDate, endDate })
    setopenFilter(false)
  }

  return (
    <Dropdown isOpen={openFilter} setIsOpen={setopenFilter}>
      <DropdownToggle hasIcon={false}>
        <Button
          icon='Filter' color='warning'
          //isLight 
          className='text-dark'
          data-tour='filter'
        >
          Filtros
        </Button>
      </DropdownToggle>
      <DropdownMenu
        isAlignmentEnd
        size='lg'
        isCloseAfterLeave={false}
        data-tour='filter-menu'>
        <div className='container py-2'>
          <div className='col-12'>
            <FormGroup>
              <Label htmlFor='unity'>Data inicial</Label>
              <Input
                id='startDate'
                type='date'
                ariaLabel='Second Date'
                placeholder='01/2022'
                onChange={e => setstartDate(e.target.value)}
                value={startDate}
              />
            </FormGroup>
          </div>
          <div className='col-12 mt-2'>
            <FormGroup>
              <Label htmlFor='unity'>Data final</Label>
              <Input
                id='endDate'
                type='date'
                ariaLabel='Second Date'
                placeholder='01/2022'
                onChange={e => setendDate(e.target.value)}
                value={endDate}
              />
            </FormGroup>
          </div>
          <div className='col-12 mt-4 d-flex flex-row-reverse'>
            <Button style={{ color: 'black' }} color='warning' type='buttom' onClick={onClickSearch}>
              Buscar
            </Button>
          </div>
        </div>
      </DropdownMenu>
    </Dropdown>
  );
}

export default memo(FilterByDate);