import React, { useCallback, useContext, useEffect, useState, useMemo } from 'react';
import PageWrapper from '../../../../layout/PageWrapper/PageWrapper';
import SubHeader, { SubHeaderLeft, SubHeaderRight } from '../../../../layout/SubHeader/SubHeader';
import Page from '../../../../layout/Page/Page';
import Breadcrumb from '../../../../components/bootstrap/Breadcrumb';
import Button from '../../../../components/bootstrap/Button';
import ModalRegisterSectors from '../../../../components/modals/Sectors/ModalRegister';
import { demoPages } from '../../../../menu';
import Card, { CardBody, CardHeader, CardLabel, CardTitle } from '../../../../components/bootstrap/Card';
import SelectEstablishment from '../../../../components/MyCustom/SelectEstablishment';
import { DefaultContext } from '../../../../contexts/default';
import SectorDB from '../../../../database/wrappers/sector';
import TableCustom from '../../../../components/MyCustom/TableCustom';
import showNotification from '../../../../components/extras/showNotification';
import notificationBody from '../../../../helpers/utils/functions/notificationBody';
import { orderBy } from 'firebase/firestore';
import Input from '../../../../components/bootstrap/forms/Input';
import MenuDB from '../../../../database/wrappers/menu';
import convertArrayToObject from '../../../../utils/convertArrayToObject'
import useDarkMode from '../../../../hooks/useDarkMode';

const Sectors = () => {
  const { darkModeStatus } = useDarkMode();

  const { estabSelected, onShowQuestion } = useContext(DefaultContext)

  const [openModalRegister, setOpenModalRegister] = useState(false);
  const [menuDicionary, setmenuDicionary] = useState({});
  const [sector, setSector] = useState([]);
  const [sectorSelected, setSectorSelected] = useState(null);

  useEffect(() => {
    if (!estabSelected) return;
    const onSubscribe = new SectorDB(estabSelected).on(setSector, orderBy('name', 'asc'))
    const onSubscribe2 = new MenuDB(estabSelected).on(
      datas => setmenuDicionary(convertArrayToObject(datas, 'id'))
      , orderBy('name', 'asc'))
    return () => {
      onSubscribe();
      onSubscribe2();
    };
  }, [estabSelected]);

  const handleOpenModalRegister = useCallback(() => {
    setSectorSelected(null);
    setOpenModalRegister(true);
  }, [])

  const handleCloseModalRegister = useCallback(() => {
    setOpenModalRegister(false);
  }, [])

  const handleOpenModalEdit = useCallback((item) => {
    setSectorSelected(item);
    setOpenModalRegister(true)
  }, [])

  const handleChangeItem = useCallback((id, key, value) => {
    new SectorDB(estabSelected)
      .update(id, {
        [key]: value
      })
      .then(() => showNotification('', notificationBody('Registro alterado com sucesso'), 'success'))
      .catch(() => showNotification('', notificationBody('Falhou ao atualizar o registro'), 'danger'))
  }, [estabSelected])

  const handleOpenModalDelete = useCallback((id) => {
    onShowQuestion({
      title: 'Exclusão de Setor',
      message: 'Você está excluindo um Setor. Está ação é irreversível. Deseja continuar?',
      onConfirm: () => {
        new SectorDB(estabSelected)
          .delete(id)
          .then(() => showNotification('', notificationBody('Registro deletado com sucesso'), 'success'))
          .catch(() => showNotification('', notificationBody('Falhou ao deletar o registro'), 'danger'))
      }
    })
  }, [estabSelected])

  const columns = useMemo(() => ([
    {
      label: 'Nome',
      field: 'name',
    },
    {
      label: 'Cor',
      field: 'color',
      format: row => (
        <Input
          type='color'
          disabled
          value={row.color}
        />
      ),
      noExport:"true,"
    },
    {
      label: 'Cardápio',
      field: 'menuId',
      format: row => menuDicionary[row.menuId]?.name,
      formatExport: value => menuDicionary[value]?.name,
    },
    {
      label: 'Status',
      field: 'active',
      format: row => (
        <Button
          isLink
          color={row.active ? 'success' : 'danger'}
          icon='Circle'
          className='text-nowrap'
          onClick={() => handleChangeItem(row.id, 'active', !row.active)}
        >
          {row.active ? 'Ativo' : 'Inativo'}
        </Button>
      )
    },
    {
      label: 'Ações',
      field: 'actions',
      format: row => (
        <div className='w-75 d-flex justify-content-between'>
          <Button
            color={darkModeStatus ? 'light' : 'dark'}
            icon="edit"
            shadow="sm"
            hoverShadow="sm"
            size="sm"
            onClick={() => handleOpenModalEdit(row)}
          />
          <Button
            color="danger"
            icon="Trash"
            shadow="sm"
            hoverShadow="sm"
            size="sm"
            onClick={() => handleOpenModalDelete(row.id)}
          />
        </div>
      ),
      noExport: true
    },
  ]), [darkModeStatus, handleOpenModalDelete, handleChangeItem, menuDicionary])

  return (
    <>
      <PageWrapper title={demoPages.establishment.subMenu.sectors.text}>
        <SubHeader>
          <SubHeaderLeft>
            <Breadcrumb
              list={[
                { title: 'Estabelecimento', to: '/estab/sectors' },
                { title: 'Setores', to: '/estab/sectors' },
              ]}
            />
          </SubHeaderLeft>
          <SubHeaderRight>
            <Button
              color='primary'
              icon='plus'
              shadow="sm"
              hoverShadow="sm"
              onClick={handleOpenModalRegister}>
              Cadastrar
            </Button>
          </SubHeaderRight>
        </SubHeader>
        <Page container='fluid'>
          <Card>
            <CardHeader>
              <CardLabel icon='Store' iconColor={darkModeStatus ? 'light' : 'dark'}>
                <CardTitle>Setores</CardTitle>
              </CardLabel>
            </CardHeader>
            <CardBody className='table-responsive'>
              <SelectEstablishment />

              <TableCustom
                columns={columns}
                rows={sector}
                fileName="Setores"
              />
            </CardBody>
          </Card>
        </Page>
      </PageWrapper>

      <ModalRegisterSectors
        open={openModalRegister}
        setIsOpen={setOpenModalRegister}
        setIsClose={handleCloseModalRegister}
        sectorSelected={sectorSelected}
      />
    </>
  );
};

export default Sectors;
