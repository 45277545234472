import React, { memo, useMemo } from 'react';
import Modal, { ModalBody, ModalHeader, ModalTitle } from '../../bootstrap/Modal';
import Button from '../../bootstrap/Button';
import Timeline, { TimelineItem } from '../../../components/extras/Timeline';
import Icon from '../../../components/icon/Icon';
import dateFormat from 'dateformat';
import { NFC_HISTORY_COLOR, OPERATOR_HISTORY_COLOR } from '../../../types/history';

const ModalLogs = ({ open, setIsOpen, setIsClose, data, title, type }) => {

  const COLOR = useMemo(() => type === 'cashier' ? OPERATOR_HISTORY_COLOR : NFC_HISTORY_COLOR, [type])

  return (
    <Modal
      id={'modal-logs-histories'}
      titleId={title}
      isOpen={open}
      setIsOpen={setIsOpen}
      isStaticBackdrop={true}
      isScrollable={true}
      isCentered={true}
      size="lg" // 'sm' || 'lg' || 'xl' 
      isAnimation={true}
    >
      <ModalHeader setIsOpen={setIsOpen}>
        <ModalTitle ><Icon icon="NotificationsActive" color='warning' size="2x" className="m-2" />{title}</ModalTitle>
      </ModalHeader>
      <ModalBody className="p-4">

        <Timeline>
          {data.map((item, index) => (
            <TimelineItem
              key={'logx'+index}
              label={dateFormat(item.created_at.toDate(), 'HH:MM:ss')}
              color={COLOR[item.action]}
            >
              {item.infor}
            </TimelineItem>
          ))}
        </Timeline>

        <div className='d-flex flex-row-reverse'>
          <Button
            color="danger"
            icon="cancel"
            rounded={1}
            onClick={setIsClose}
            shadow="sm"
            hoverShadow="sm"
            size="sm"
          >
            Fechar
          </Button>
        </div>
      </ModalBody>
    </Modal>
  )
}

export default memo(ModalLogs);