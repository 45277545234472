import React from 'react';
import classNames from 'classnames';
import PageWrapper from '../../../layout/PageWrapper/PageWrapper';
import Page from '../../../layout/Page/Page';
import Card, { CardBody } from '../../../components/bootstrap/Card';
import Spinner from '../../../components/bootstrap/Spinner';
import Logo from '../../../components/Logo';

const Start = () => {
  return (
    <PageWrapper
      title={'Login'}
      className={classNames({ 'bg-warning': true })}>
      <Page className='p-0'>
        <div className='row h-100 align-items-center justify-content-center'>
          <div className='col-xl-4 col-lg-6 col-md-8 shadow-3d-container'>
            <Card style={{ backgroundColor: '#212529' }}  className='shadow-3d-dark' data-tour='login-page'>
              <CardBody>
                <div style={{ padding: 60 }}>
                  <Logo width={'100%'} />
                  <div className='d-flex flex-column justify-content-center align-items-center' style={{ marginTop: 40 }}>
                    {/* <div class="spinner-border text-warning" role="status"></div> */}
                    <Spinner
                      tag='div'
                      color='warning'
                      isGrow
                      size={40}
                    />
                  </div>
                </div>
              </CardBody>
            </Card>
          </div>
        </div>
      </Page>
    </PageWrapper>
  );
};

export default Start;
