import { useContext, useEffect, useMemo, useState } from "react";
import { DefaultContext } from "../../contexts/default";
import EntrancePayEventData from "../../database/wrappers/eventData/entrancePay";
import { PAYMENT_TYPE } from "../../types/payment";
import EntrancePay from "../../database/entities/eventData/entrancesPay.entity";

const useTotalEntrance = () => {
  const { estabSelected, eventSelected, dataFilter, sectorSelected } = useContext(DefaultContext);
  const [entrances, setEntrances] = useState<EntrancePay[]>([]);
  const firstDate = new Date(dataFilter.firstDate);
  const secondDate = new Date(dataFilter.secondDate);
  useEffect(() => {
    let isMounted = true;
    const loadEntrance = () => {
      if (estabSelected && eventSelected) {
        new EntrancePayEventData(estabSelected, eventSelected)
          .getAllInterval(firstDate, secondDate)
          .then(entrance => {
            if (isMounted) {
              setEntrances(entrance)
            }
          }
          );
      }
    };
    loadEntrance();

    return () => {
      isMounted = false;
    }
  }, [estabSelected, eventSelected,dataFilter]);

  const EntrancesCalc = useMemo(() => {
    let totalCash = 0;
    let totalDebit = 0;
    let totalCredit = 0;
    let totalPix = 0;
    let totalMealTicket = 0;
    let totalCourtesy = 0;
    let totalIngresso = 0;
    let totalConsumacao = 0

    if (!entrances) return {
      totalCash: 0,
      totalDebit: 0,
      totalCredit: 0,
      totalPix: 0,
      totalMealTicket: 0,
      totalCourtesy: 0,
      totalIngresso: 0,
      totalConsumacao: 0,

    };

    const sumTypeValue = (paymentType: number, value: number) => {
      if (paymentType === PAYMENT_TYPE.CASH)
        totalCash += value
      else if (paymentType === PAYMENT_TYPE.CARD_CREDIT)
        totalCredit += value
      else if (paymentType === PAYMENT_TYPE.CARD_DEBIT)
        totalDebit += value
      else if (paymentType === PAYMENT_TYPE.PIX)
        totalPix += value
      else if (paymentType === PAYMENT_TYPE.MEAL_TICKET)
        totalMealTicket += value
      else if (paymentType === PAYMENT_TYPE.COURTESY)
        totalCourtesy += value
    }
    const entrancesWithoutRefunds = entrances.filter(entrance => !entrance?.reversed);
    entrancesWithoutRefunds.forEach(entrance => {
      if (entrance.payment_type === PAYMENT_TYPE.DIVIDED) {
        entrance.splitPayment.forEach(item => {
          if (item.reversed) {
            return;
          } else {
            totalConsumacao += entrance.consummation;
            totalIngresso += entrance.value - entrance.consummation;
            sumTypeValue(item.payment_type, item.value);
          }
        });
      } else {
        totalConsumacao += entrance.consummation;
        totalIngresso += entrance.value - entrance.consummation;
        sumTypeValue(entrance.payment_type, entrance.value);
      }
    });
    let totalFaturamento = totalCash + totalDebit + totalCredit + totalPix + totalMealTicket + totalCourtesy;
    return {
      cash: totalCash,
      debit: totalDebit,
      credit: totalCredit,
      pix: totalPix,
      mealTicket: totalMealTicket,
      courtesy: totalCourtesy,
      totalConsumacao,
      totalIngresso,
      totalFaturamento,
      entrances,
      entrancesWithoutRefunds
    };
  }, [entrances]);

  const EntrancesSector = useMemo(() => {
    let totalIngresso = 0;
    let totalConsumacao = 0
    if (!entrances) return {
      totalIngresso: 0,
      totalConsumacao: 0,

    };


    const entrancesSector = entrances.filter((entrance) => entrance.sectorId === sectorSelected)
    const entrancesSectorWithoutRefunds = entrancesSector.filter(entrance => !entrance?.reversed);
    entrancesSectorWithoutRefunds.forEach(entrance => {
      if (entrance.payment_type === PAYMENT_TYPE.DIVIDED) {
        entrance.splitPayment.forEach(item => {
          if (item.reversed) {
            return;
          } else {
            totalConsumacao += entrance.consummation;
            totalIngresso += entrance.value - entrance.consummation;
          }
        });
      } else {
        totalConsumacao += entrance.consummation;
        totalIngresso += entrance.value - entrance.consummation;
      }
    });


    return {
      totalConsumacao,
      totalIngresso,
      entrancesSector

    }
  }, [entrances, sectorSelected])

  return { EntrancesCalc, EntrancesSector };
};

export default useTotalEntrance;
