import React, { useCallback, useContext, useEffect, useState, useMemo } from 'react';
import PageWrapper from '../../../../layout/PageWrapper/PageWrapper';
import SubHeader, { SubHeaderLeft, SubHeaderRight } from '../../../../layout/SubHeader/SubHeader';
import Page from '../../../../layout/Page/Page';
import Breadcrumb from '../../../../components/bootstrap/Breadcrumb';
import Button from '../../../../components/bootstrap/Button';
import { demoPages } from '../../../../menu';
import ModalRegisterSeller from '../../../../components/modals/Seller/modalRegister'
import Card, { CardBody, CardHeader, CardLabel, CardTitle } from '../../../../components/bootstrap/Card';
import { DefaultContext } from '../../../../contexts/default';
import TableCustom from '../../../../components/MyCustom/TableCustom';
import showNotification from '../../../../components/extras/showNotification';
import notificationBody from '../../../../helpers/utils/functions/notificationBody';
import useDarkMode from '../../../../hooks/useDarkMode';
import UserDB from '../../../../database/wrappers/user';
import { where } from 'firebase/firestore';
import { BASIC_ROLE } from '../../../../types/roles';
import { cpfFormatMask } from '../../../../utils/format/cpfFormat';
import api from '../../../../services/api';

const Sellers = () => {
  const { darkModeStatus } = useDarkMode();

  const { onShowQuestion } = useContext(DefaultContext)

  const [openModalRegister, setOpenModalRegister] = useState(false);
  const [userSelected, setuserSelected] = useState(null);
  const [users, setusers] = useState([]);

  useEffect(() => {
    const onSubscribe = new UserDB().on(setusers, where('role', '==', BASIC_ROLE.SELLER))
    return onSubscribe;
  }, []);

  const handleOpenModalRegister = useCallback(() => {
    setuserSelected(null);
    setOpenModalRegister(true);
  }, [])

  const handleCloseModalRegister = useCallback(() => {
    setOpenModalRegister(false);
  }, [])

  const handleOpenModalEdit = useCallback((item) => {
    setuserSelected(item);
    setOpenModalRegister(true)
  }, [])

  const handleOpenModalDelete = useCallback((id) => {
    onShowQuestion({
      title: 'Exclusão de usuário',
      message: 'Você está excluindo uma usuário. Está ação é irreversível. Deseja continuar?',
      onConfirm: () => {
        api.delete('routes/users/' + id)
          .then(res => showNotification('', notificationBody('Registro deletado com sucesso'), 'success'))
          .catch(err => showNotification('', notificationBody('Falhou ao deletar o registro'), 'danger'))
      }
    })
  }, [])

  const rowsRender = useMemo(() => users.map(row => ({
    ...row,
    cpfLabel: cpfFormatMask(String(row.cpf))
  })))

  const columns = useMemo(() => ([
    {
      label: 'Nome',
      field: 'name',
    },
    {
      label: 'CPF/CNPJ',
      field: 'cpfLabel',
    },
    // {
    //   label: 'QTD Cliente',
    //   field: 'qtdCliente',
    // },
    {
      label: 'Comissão Taxa',
      field: 'commissionFee',
    },
    {
      label: 'Comissão Material',
      field: 'commissionMaterial',
    },
    {
      label: 'Status',
      field: 'disabled',
      format: row => (
        <Button
          isLink
          color={row.disabled ? 'danger' : 'success'}
          icon='Circle'
          className='text-nowrap'>
          {row.disabled ? 'Inativo' : 'Ativo'}
        </Button>
      ),
      formatExport: value => !value.disabled
    },
    {
      label: 'Ações',
      field: 'actions',
      format: row => (
        <div className='w-75 d-flex justify-content-between'>
          <Button
            color="light"
            icon="edit"
            shadow="sm"
            hoverShadow="sm"
            size="sm"
            onClick={() => handleOpenModalEdit(row)}
          />
          <Button
            color="danger"
            icon="Trash"
            shadow="sm"
            hoverShadow="sm"
            size="sm"
            onClick={() => handleOpenModalDelete(row.id)}
          />
        </div>
      ),
      noExport: true
    },
  ]), [handleOpenModalEdit, handleOpenModalDelete])
 

  return (
    <>
      <PageWrapper title={demoPages.pageLayout.subMenu.sellers.text}>
        <SubHeader>
          <SubHeaderLeft>
            <Breadcrumb
              list={[
                { title: 'Cadastros', to: '/registers/sellers' },
                {
                  title: 'Vendedores',
                  to: '/registers/sellers',
                },
              ]}
            />
          </SubHeaderLeft>
          <SubHeaderRight>
            <Button
              color='primary'
              icon='plus'
              shadow="sm"
              hoverShadow="sm"
              onClick={handleOpenModalRegister}>
              Cadastrar
            </Button>
          </SubHeaderRight>
        </SubHeader>
        <Page container='fluid'>
          <Card>
            <CardHeader>
              <CardLabel icon='PointOfSale' iconColor={darkModeStatus ? 'light' : 'dark'}>
                <CardTitle>Vendedores</CardTitle>
              </CardLabel>
            </CardHeader>
            <CardBody className='table-responsive'>
              <TableCustom
                titlePdf='Vendedores'
                icon="PointOfSale"
                columns={columns}
                rows={rowsRender}
                fileName="Vendedores"
              />
            </CardBody>
          </Card>
        </Page>
      </PageWrapper>

      <ModalRegisterSeller
        open={openModalRegister}
        setIsOpen={setOpenModalRegister}
        setIsClose={handleCloseModalRegister}
        editData={userSelected}
      />
    </>
  );
};

export default Sellers;
