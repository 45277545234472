import React, { useState, useContext, memo, useRef, useEffect, useMemo } from 'react';
import dateFormat from "dateformat";
import Footer from "../../Footer";
import { MONTH_PTBR } from "../../../types/dates";


const LastPageContract = ({ establishment }) => {


  const dataAtual = new Date();

  return (
    <>
      <div className='d-flex flex-column col-xxl-12 justify-content-around mt-4'>
        <h6>Por ser expressão da verdade, e estarem de comum acordo com o presente termo firmam o presente.</h6>
        <h6 className='mt-2'>São Paulo, {dateFormat(dataAtual, 'dd')} de {MONTH_PTBR[dataAtual.getMonth()]} de {dateFormat(dataAtual, 'yyyy')}</h6>
      </div>
      <div className='divFooter pagebreak'>
        <div className='d-flex text-center flex-column col-xxl-12 justify-content-around mt-report  my-5 py-5'>
          <h6>UGET PDX TECNOLOIGA E SOLUÇÕES LTDA.</h6>
        </div>
        <div className='d-flex text-center flex-column col-xxl-12 justify-content-around mt-report my-5 py-5'>
          <h6>CLIENTE - {establishment?.name}</h6>
        </div>
        <div id='ugetWitnesses' className='row justify-content-between mt-report  my-5'>
          <div className='col-6 p-0  text-center'>
            <h6>TESTEMUNHA UGET</h6>
            <h6>{establishment?.witnesses.witnesse1}</h6>
            <h6>E-MAIL: {establishment?.witnesses.witnesse1Email}</h6>
          </div>
          <div className='col-6 p-0 text-center'>
            <h6>TESTEMUNHA ESTABELECIMENTO</h6>
            <h6>{establishment?.witnesses.witnesse2}</h6>
            <h6>E-MAIL: {establishment?.witnesses.witnesse2Email}</h6>
          </div>
        </div>

        <Footer />
      </div>
    </>
  );
}

export default LastPageContract;