import { useContext, useEffect, useMemo, useState } from "react";
import { DefaultContext } from "../../contexts/default";
import { PAYMENT_TYPE } from "../../types/payment";
import SalesEventData from "../../database/wrappers/eventData/sales";
import Sales from "../../database/entities/eventData/sales.entity";

const useTotalSales = () => {
  const { estabSelected, eventSelected, dataFilter } = useContext(DefaultContext);
  const [sales, setsales] = useState<Sales[]>([]);
  const firstDate = new Date(dataFilter.firstDate);
  const secondDate = new Date(dataFilter.secondDate);
  useEffect(() => {
    let isMounted = true;
    const loadSales = () => {
      if (estabSelected && eventSelected) {
        new SalesEventData(estabSelected, eventSelected)
          .getAllInterval(firstDate, secondDate)
          .then((sale) => {
            if (isMounted) {
              setsales(sale)
            }
          }
          );

      }
    };
    loadSales();
  }, [estabSelected, eventSelected,dataFilter]);



  const SalesCalc = useMemo(() => {
    let totalCredit = 0;
    let totalDebit = 0;
    let totalPix = 0;
    let totalMealTicket = 0;
    let totalCash = 0;
    let totalCourtesy = 0;
    if (!sales) return {
      totalCash: 0,
      totalDebit: 0,
      totalCredit: 0,
      totalPix: 0,
      totalMealTicket: 0,
      totalCourtesy: 0,
    };
    const sumTypeValue = (paymentType: number, value: number) => {
      if (paymentType === PAYMENT_TYPE.CASH)
        totalCash += value
      else if (paymentType === PAYMENT_TYPE.CARD_CREDIT)
        totalCredit += value
      else if (paymentType === PAYMENT_TYPE.CARD_DEBIT)
        totalDebit += value
      else if (paymentType === PAYMENT_TYPE.PIX)
        totalPix += value
      else if (paymentType === PAYMENT_TYPE.MEAL_TICKET)
        totalMealTicket += value
      else if (paymentType === PAYMENT_TYPE.COURTESY)
        totalCourtesy += value
    }
    const salesWithoutRefunds = sales.filter(sale => !sale?.reversed);
    const salesWithRefunds = sales.filter(sale => sale?.reversed);
    const TotalSalesWithRefunds = salesWithRefunds.reduce((prev, curr) => prev + curr.value, 0);

    let TotalSales = sales.reduce((prev, curr) => prev + curr.value, 0);
    let totalProducts = 0;
    let totalSplitPaymentReversed = 0;
    salesWithoutRefunds.forEach(sale => {
      let totalProductsReversed = 0;
      sale.products.forEach(product => {
        totalProducts += product.qtd;
        if (sale.payment_type === PAYMENT_TYPE.CASH) {
          if (product.qtdReversed > 0) {
            totalProductsReversed += product.price * product.qtdReversed;
          }
        }
      });
      if (sale.payment_type === PAYMENT_TYPE.CASH) {
        sumTypeValue(sale.payment_type, sale.value - totalProductsReversed);
      } else if (sale.payment_type === PAYMENT_TYPE.DIVIDED) {
        sale.splitPayment.forEach(item => {
          if (item.reversed) {
            totalSplitPaymentReversed += item.value;
          } else {
            sumTypeValue(item.payment_type, item.value);
          }
        });
      } else {
        sumTypeValue(sale.payment_type, sale.value);
      }
    });


    const totalFaturamento = totalCash + totalDebit + totalCredit + totalPix + totalMealTicket;

    return {
      cash: totalCash,
      debit: totalDebit,
      credit: totalCredit,
      pix: totalPix,
      mealTicket: totalMealTicket,
      courtesy: totalCourtesy,
      totalFaturamento,
      salesWithoutRefunds,
      TotalSalesWithRefunds: TotalSalesWithRefunds + totalSplitPaymentReversed,
      sales,
      totalProducts,
      TotalSales: TotalSales - TotalSalesWithRefunds - totalSplitPaymentReversed,

    };
  }, [sales]);

  return { SalesCalc };
};

export default useTotalSales;
