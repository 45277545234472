import React, { memo, useMemo } from 'react';
import Card, { CardActions, CardBody, CardHeader, CardLabel, CardTitle } from '../../../components/bootstrap/Card';
import CardValue from '../../../components/MyCustom/Dashboard/CardValue';
import useDarkMode from '../../../hooks/useDarkMode';


const Totais = ({ events_result }) => {
    const { darkModeStatus } = useDarkMode();

    const [n_completeClosure, n_not_completeClosure] = useMemo(() => {
        const n_completeClosure = events_result.reduce((prev, curr) => prev + curr.completeClosure,0)
        return [
            n_completeClosure,
            events_result.length - n_completeClosure,
        ]
    },[events_result])

    return (
        <Card>
            <CardHeader>
                <CardLabel icon='PointOfSale' iconColor={!darkModeStatus ? 'dark' : 'light'}>
                    <CardTitle className='d-flex flex-row align-items-center'>
                        <CardActions>
                            Totais
                        </CardActions>
                    </CardTitle>
                </CardLabel>
            </CardHeader>
            <CardBody className='row col-md-12 '>
                <div className='row justify-content-center'>
                    <div className='col-md-3 '>
                        <CardValue
                            color='dark'
                            label='Eventos'
                            value={events_result.length}
                        />
                    </div>
                    <div className='col-md-3 '>
                        <CardValue
                            color='dark'
                            label='Fechamentos Concluídos'
                            value={n_completeClosure}
                        />
                    </div>
                    <div className='col-md-3 '>
                        <CardValue
                            color='dark'
                            label='Fechamentos Não Concluídos'
                            value={n_not_completeClosure}
                        />
                    </div>
                </div>
            </CardBody>
        </Card>
    );
}

export default memo(Totais);