import { Firestore, QueryConstraint, Unsubscribe, collection, getDocs, getFirestore, limit, query, where } from "firebase/firestore";
import FirestorePipe from "../utils/pipe";
import EstablishmentDB from "./establishment";
import EventDB from "./event";
import ListCourtesy from "../entities/event/listCourtesy.entity";
import NFC from "../entities/eventData/nfc.entity";


class ListCourtesyDB extends FirestorePipe {

  public static readonly colName = 'ListCourtesy'

  constructor(idEstab: string, idEvent: string) {
    if (!idEstab || !idEvent)
      throw new Error('Informe o id do estabelecimento e evento')
    super(`${EstablishmentDB.colName}/${idEstab}/${EventDB.colName}/${idEvent}/${ListCourtesyDB.colName}`);
  }

  async getByCPF(cpf: string): Promise<ListCourtesy | null> {
    const snapshot = await getDocs(query(this.colRef, where('cpf', '==', cpf), limit(1)))
    if (snapshot.empty)
      return null;

    const doc = snapshot.docs[0];
    return {
      id: doc.id,
      ...doc.data(),
    } as any;
  }

  async getByPhone(phone: string): Promise<ListCourtesy | null> {
    const snapshot = await getDocs(query(this.colRef, where('phone', '==', phone), limit(1)))
    if (snapshot.empty)
      return null;

    const doc = snapshot.docs[0];
    return {
      id: doc.id,
      ...doc.data(),
    } as any;
  }

  public writeBatch(datas: ListCourtesy[]): Promise<void> {
    return this._writeBatch<ListCourtesy>(datas)
  }
  public create(data: ListCourtesy): Promise<any> {
    return this._create(data);
  }
  public update(id: string, data: ListCourtesy): Promise<any> {
    return this._update(id, data);
  }
  public delete(id: string): Promise<any> {
    return this._delete(id);
  }
  public getAll(...params: QueryConstraint[]): Promise<ListCourtesy[]> {
    return this._getAll<ListCourtesy>(...params);
  }
  public get(id: string): Promise<ListCourtesy> {
    return this._get(id);
  }
  public on(listener: (data: ListCourtesy[]) => void, ...params: QueryConstraint[]): Unsubscribe {
    return this._on(listener, ...params);
  }

}

export default ListCourtesyDB;