import { addDoc, collection, CollectionReference, DocumentData, getFirestore } from "firebase/firestore";
import app from "../../config";
import PrintableData from "../../entities/eventData/printable.entity";

class PrintableDataDB {

  public static readonly collection = 'PrintableData';
  public readonly colRefStr: string;
  private readonly colRef: CollectionReference<DocumentData>;

  constructor(estabId: string, eventId: string, printerId: string) {
    this.colRefStr = `EstablishmentsData/${estabId}/EventsData/${eventId}/Printer/${printerId}/${PrintableDataDB.collection}`;
    this.colRef = collection(getFirestore(app), this.colRefStr);
  }

  create(data: PrintableData) {
    return addDoc(this.colRef, data as any);
  }
}

export default PrintableDataDB;