import React, { useRef, useContext, useLayoutEffect, useState, useEffect, useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import Header, { HeaderLeft, HeaderRight } from '../../../layout/Header/Header';
import SubHeader, { SubHeaderLeft, SubHeaderRight, SubheaderSeparator, } from '../../../layout/SubHeader/SubHeader';
import Popovers from '../../../components/bootstrap/Popovers';
import Button from '../../../components/bootstrap/Button';
import useDarkMode from '../../../hooks/useDarkMode';
import LANG, { getLangWithKey } from '../../../lang';
import ModalRegisterNotification from '../../../components/modals/Notifications/ModalRegister';
import ThemeContext from '../../../contexts/themeContext';
import showNotification from '../../../components/extras/showNotification';
import Icon from '../../../components/icon/Icon';
import { DefaultContext } from '../../../contexts/default';
import OffCanvas, {
	OffCanvasBody,
	OffCanvasHeader,
	OffCanvasTitle,
} from '../../../components/bootstrap/OffCanvas';
import Alert from '../../../components/bootstrap/Alert';
import Spinner from '../../../components/bootstrap/Spinner';
import EstablishmentContract from '../../../components/modals/EstablishmentContract/contractEstablishment';
import { renderToStaticMarkup } from 'react-dom/server';
import html2pdf from 'html2pdf.js';
import ModalEventoContrato from '../../../components/modals/EventContract/modalEvent';
import ContractDB from '../../../database/wrappers/contract';
import { orderBy } from 'firebase/firestore';
import { set } from 'firebase/database';
import { demoPages, othersMenu } from '../../../menu';

const DashboardHeader = () => {
	const path = window.location.pathname
	const { darkModeStatus, setDarkModeStatus } = useDarkMode();
	const { establishment, eventSelected, event, dataFilter, sector } = useContext(DefaultContext)
	const [contracts, setContracts] = useState(false)
	const [listaContratos, setListaContratos] = useState([])
	const [openModalEvent, setOpenModalEvent] = useState(false)
	// console.log('establishment', establishment)
	// console.log('event', event)

	const dateLabel = useMemo(() => {
		const firstDate = new Date(dataFilter.firstDate);
		const secondDate = new Date(dataFilter.secondDate);
		return `${firstDate.toLocaleDateString()} ${firstDate.toLocaleTimeString()} - ${secondDate.toLocaleDateString()} ${secondDate.toLocaleTimeString()}`
	}, [dataFilter.firstDate, dataFilter.secondDate])

	const styledBtn = {
		color: darkModeStatus ? 'dark' : 'light',
		hoverShadow: 'default',
		isLight: !darkModeStatus,
		size: 'lg',
	};

	useEffect(() => {
		if (establishment?.contracts?.signed == false || event?.contracts?.openSigned == false || event?.contracts?.closeSigned == false) {
			setContracts(false)
		} else {
			setContracts(true)
		}
	}, [establishment, event])

	const { fullScreenStatus, setFullScreenStatus } = useContext(ThemeContext);

	const [offcanvasStatus, setOffcanvasStatus] = useState(false);

	const { i18n } = useTranslation();

	const [openModalRegister, setOpenModalRegister] = useState(false);
	const [stores, setStores] = useState([]);
	const [storeSelected, setStoreSelected] = useState(null);


	const canvasRef = useRef(null);


	const changeLanguage = (lng) => {
		i18n.changeLanguage(lng).then(() =>
			showNotification(
				<span className='d-flex align-items-center'>
					<Icon icon={getLangWithKey(lng)?.icon} size='lg' className='me-1' />
					<span>{`Language changed to ${getLangWithKey(lng)?.text}`}</span>
				</span>,
				'You updated the language of the site. (Only "Aside" was prepared as an example.)',
			),
		);
	};

	/**
	 * Language attribute
	 */
	useLayoutEffect(() => {
		document.documentElement.setAttribute('lang', i18n.language);
	});

	const handleOPenModalRegister = (() => {
		setOpenModalRegister(true)
	})

	const handleCloseModalRegister = useCallback(() => {
		setOpenModalRegister(false);
	}, [])

	const handleOpenModalEvent = (() => {
		setOpenModalEvent(true)
	})

	const handleCloseModalEvent = (() => {
		setOpenModalEvent(false)
	})

	// useEffect(() => {
	// 	if (!estabSelected) return;
	// 	const onSubscribe1 = new ContractDB(estabSelected).on(setListaContratos, orderBy('created_at', 'desc'))
	// 	return () => {
	// 		// console.log('lista', listaContratos)
	// 		onSubscribe1();
	// 	}
	// }, [estabSelected])

	// console.log('lista', listaContratos)


	return (
		<Header>
			<HeaderLeft className='d-flex align-items-center'>
				<Popovers
					title='Estabelecimento selecionado'
					desc={establishment ? establishment.name : ''}>
					{establishment ? establishment.name : ''}
				</Popovers>
				<SubheaderSeparator />
				<Popovers
					title='Evento Seleccionado'
					desc={event ? event.name : ''}>
					{event ? event.name : ''}
				</Popovers>
				<SubheaderSeparator />
				{'/' + demoPages.reports.subMenu.filtroPorData.path === path &&
					<>
						<Popovers
							title='Data Seleccionada'
							desc={dateLabel ? dateLabel : ''}>
							{dateLabel ? dateLabel : ''}
						</Popovers>
					</>
				}
				{/* <Button onClick={getContract()} {...styledBtn}>Contrato</Button> */}
				{/* <Button onClick={handleOpenModalEvent} {...styledBtn}>open modal</Button> */}
			</HeaderLeft>
			<HeaderRight>
				<div className='row g-3 align-items-center'>
					<div className='col-auto'>
						{
							contracts == false &&
							(

								<Popovers
									title='Contratos não assinados'
									desc={
										<>
											{establishment?.contracts?.signed == false && (
												<Alert
													color='warning'
													style={{ cursor: 'pointer' }}
													className='mb-2'
													isLight
													onClick={() => { console.log('contrato do estabelecimento') }}
												>
													Contrato do estabelecimento {establishment.name} não assinado, clique aqui para assinar o contrato
												</Alert>
											)}
											{event?.contracts?.openSigned == false && (
												<Alert
													color='warning'
													style={{ cursor: 'pointer' }}
													className='mb-2'
													isLight
													onClick={() => { console.log('contrato de abertura') }}
												>
													Contrato de abertura do evento {event.name} não assinado, clique aqui para assinar o contrato
												</Alert>
											)}
											{event?.contracts?.closeSigned == false && (
												<Alert
													color='warning'
													style={{ cursor: 'pointer' }}
													className='mb-2'
													isLight
													onClick={() => { console.log('contrato de fechamento') }}
												>
													Contrato de fechamento do evento {event.name} não assinado, clique aqui para assinar o contrato
												</Alert>
											)}
										</>
									}>

									<Button
										{...styledBtn}
										color='warning'
										className='btn-only-icon'
										aria-label='Contratos não assinados'
										data-tour='contratos-nao-assinados'>
										<Spinner isSmall inButton='onlyIcon' isGrow />
									</Button>

								</Popovers>
							)}
						{/* 
						<code className='ps-3'>DashboardHeader.js</code> */}
					</div>
					{/* Dark Mode */}
					<div className='col-auto'>
						<Popovers trigger='hover' desc='Dark / Light mode'>
							<Button
								// eslint-disable-next-line react/jsx-props-no-spreading
								{...styledBtn}
								icon={darkModeStatus ? 'DarkMode' : 'LightMode'}
								onClick={() => setDarkModeStatus(!darkModeStatus)}
								aria-label='Toggle fullscreen'
								data-tour='dark-mode'
							/>
						</Popovers>
					</div>
					{/*	Full Screen */}
					<div className='col-auto'>
						<Popovers trigger='hover' desc='Fullscreen'>
							<Button
								// eslint-disable-next-line react/jsx-props-no-spreading
								{...styledBtn}
								icon={fullScreenStatus ? 'FullscreenExit' : 'Fullscreen'}
								onClick={() => setFullScreenStatus(!fullScreenStatus)}
								aria-label='Toggle dark mode'
							/>
						</Popovers>
					</div>
					{/*	Notifications */}
					<div className='col-auto'>
						<Button
							// eslint-disable-next-line react/jsx-props-no-spreading
							{...styledBtn}
							icon='Notifications'
							onClick={() => setOffcanvasStatus(true)}
							aria-label='Notifications'
						/>
					</div>
				</div>
				<OffCanvas
					id='notificationCanvas'
					titleId='offcanvasExampleLabel'
					placement='end'
					isOpen={offcanvasStatus}
					setOpen={setOffcanvasStatus}>
					<OffCanvasHeader setOpen={setOffcanvasStatus}>
						<OffCanvasTitle id='offcanvasExampleLabel'>Notificações</OffCanvasTitle>
					</OffCanvasHeader>
					<OffCanvasBody>
						{/* <Alert icon='ViewInAr' isLight color='info' className='flex-nowrap'>
							Produto Cadastrado com sucesso.
						</Alert>
						<Alert icon='ThumbUp' isLight color='warning' className='flex-nowrap'>
							Usuário ativado com sucesso.
						</Alert>
						<Alert icon='Inventory2' isLight color='danger' className='flex-nowrap'>
							Estoque atualizado com sucesso.
						</Alert>
						<Alert icon='BakeryDining' isLight color='success' className='flex-nowrap'>
							Dashboard carregada com sucesso.
						</Alert>
						<Alert icon='Escalator' isLight color='primary' className='flex-nowrap'>
							Abertura de Caixa Efetuada.
						</Alert> */}
					</OffCanvasBody>
				</OffCanvas>
			</HeaderRight>

			<ModalRegisterNotification
				open={openModalRegister}
				setIsOpen={setOpenModalRegister}
				setIsClose={handleCloseModalRegister}
				storeSelected={storeSelected}
			/>
			<ModalEventoContrato
				open={openModalEvent}
				setIsOpen={setOpenModalEvent}
				setIsClose={handleCloseModalEvent}
				evento={event}
				estabelecimento={establishment}
			/>
		</Header>

	);
};

export default DashboardHeader;
