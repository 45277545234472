import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import PageWrapper from '../../../../layout/PageWrapper/PageWrapper';
import SubHeader, { SubHeaderLeft, SubHeaderRight } from '../../../../layout/SubHeader/SubHeader';
import Page from '../../../../layout/Page/Page';
import Button from '../../../../components/bootstrap/Button';
import { demoPages } from '../../../../menu';
import { DefaultContext } from '../../../../contexts/default';
import Card, { CardBody, CardHeader, CardLabel, CardTitle } from '../../../../components/bootstrap/Card';
import SelectEstablishment from '../../../../components/MyCustom/SelectEstablishment';
import TableCustom from '../../../../components/MyCustom/TableCustom';
import Breadcrumb from '../../../../components/bootstrap/Breadcrumb';
import SelectEvent from '../../../../components/MyCustom/SelectEvent';
import useDarkMode from '../../../../hooks/useDarkMode';
import ModalLogs from '../../../../components/modals/LogsModal/ModalLogs';
import OperatorsHistory from '../../../../database/wrappers/eventData/operatorsHistory';
import ClouserCashierEventData from '../../../../database/wrappers/eventData/clouserCashier';
import dateFormat from 'dateformat';
import { orderBy } from 'firebase/firestore';

const OperatorsReports = () => {
  const { darkModeStatus } = useDarkMode();
  const { estabSelected, eventSelected } = useContext(DefaultContext);
  const [openModalLogs, setOpenModalLogs] = useState(false);
  const [cashierSelected, setcashierSelected] = useState('');
  const [title, settitle] = useState('');
  const [rows, setrows] = useState([]);
  const [histories, sethistories] = useState([]);

  useEffect(() => {
    if(estabSelected && eventSelected) {
      return new ClouserCashierEventData(estabSelected, eventSelected).on(setrows, orderBy('operator', 'asc'))
    }
  }, [estabSelected, eventSelected])

  useEffect(() => {
    if(estabSelected && eventSelected && cashierSelected) {
      const onSubscriber = new OperatorsHistory(estabSelected, eventSelected, cashierSelected).on(sethistories, orderBy('created_at', 'asc'))
      return () => {
        onSubscriber();
        sethistories([]);
      };
    }
  }, [estabSelected, eventSelected, cashierSelected])

  const handleOpenModalLogs = useCallback((row) => {
    setcashierSelected(row.id)
    settitle(row.operator + ' - ' + row.terminal)
    setOpenModalLogs(true);
  }, []);

  const handleCloseModalLogs = useCallback(() => {
    setOpenModalLogs(false);
    setcashierSelected('')
  }, []);

  const columns = useMemo(() => ([
    {
      label: 'Abertura',
      field: 'opening_at',
      format: row => dateFormat(row.opening_at.toDate(), 'dd/mm HH:MM'),
      formatExport: value =>  dateFormat(value.toDate(), 'dd/mm HH:MM')
    },
    {
      label: 'Operador',
      field: 'operator',
    },
    {
      label: 'Cpf',
      field: 'cpf',
    },
    {
      label: 'Terminal',
      field: 'terminal',
    },
    // {
    //   label: 'Setor',
    //   field: 'sector',
    // },
    {
      label: 'Ações',
      field: 'actions',
      format: (row) => (
        <Button
          color="primary"
          size="sm"
          icon='RemoveRedEye'
          onClick={() => handleOpenModalLogs(row)}
        />
      ),
      noExport: true
    },
  ]), [darkModeStatus])

  return (
    <>
      <PageWrapper title={demoPages.reports.subMenu.operatorsReports.text}>
        <SubHeader>
          <SubHeaderLeft>
            <Breadcrumb
              list={[
                { title: 'Relatórios', to: '/reports' },
                { title: 'Relatório Operadores', to: '/reports/operatorsReports' },
              ]}
            />
          </SubHeaderLeft>
        </SubHeader>

        <Page container="fluid">
          <Card>
            <CardHeader>
              <CardLabel icon='SupervisorAccount' iconColor='light'>
                <CardTitle>Relatório Operadores</CardTitle>
              </CardLabel>
            </CardHeader>
            <CardBody className='table-responsive'>
              <div className='row'>
                <SelectEstablishment />
                <SelectEvent />
              </div>

              <TableCustom
                columns={columns}
                rows={rows}
                keyExtractor={item => item.id}
                fileName='Relatório-operadores'
              />
            </CardBody>
          </Card>
        </Page>
      </PageWrapper>


      <ModalLogs
        open={openModalLogs}
        setIsClose={handleCloseModalLogs}
        onClose={handleCloseModalLogs}
        title={title}
        type='cashier'
        data={histories}
      />
    </>
  );
};

export default OperatorsReports;
