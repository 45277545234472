import React, { memo, useMemo } from 'react';
import Card, { CardActions, CardBody, CardHeader, CardLabel, CardTitle } from '../../../components/bootstrap/Card';
import TableSimples from '../../../components/MyCustom/TableSimples';
import Money from '../../../helpers/utils/money/money';
import useDarkMode from '../../../hooks/useDarkMode';
import TableCustom from '../../../components/MyCustom/TableCustom';

const PerdasDanos = ({ equipments }) => {
  const { darkModeStatus } = useDarkMode();

  const columns = useMemo(() => ([
    {
      label: 'Nome',
      field: 'equipment',
    },
    {
      label: 'Qtd Env.',
      field: 'qtdSent',
    },
    // {
    //   label: 'Valor',
    //   field: 'value',
    // },
    {
      label: 'Qtd Faltante',
      field: 'qtd_loss',
    },
    {
      label: 'Valor de Perda',
      field: 'value_loss',
      format: row => Money.centsToMaskMoney(row.value_loss),
      formatExport: value => Money.centsToCoinCsv(value),
    },
    {
      label: 'A Pagar',
      field: 'total_loss',
      format: row => Money.centsToMaskMoney(row.total_loss),
      formatExport: value => Money.centsToCoinCsv(value),
    },
  ]), [])

  const equipamentsLoss = useMemo(() => 
    equipments.filter(e => e.qtd_loss > 0)
  ,[equipments])

  return (
    <Card>
      <CardHeader>
        <CardLabel icon='ReportProblem' iconColor={!darkModeStatus ? 'dark' : 'light'}>
          <CardTitle className='d-flex flex-row align-items-center'>
            <CardActions>
              PERDAS E DANOS
            </CardActions>
          </CardTitle>
        </CardLabel>
      </CardHeader>
      <CardBody className='table-responsive p-5'>
        <TableCustom
          rows={equipamentsLoss}
          columns={columns}
          fileName={'Perdas-e-danos'}
        />
      </CardBody>
    </Card>
  );
}

export default memo(PerdasDanos);