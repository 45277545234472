import { useCallback, useContext, useEffect, useMemo, useState } from "react";
import DefectDB from "../../../database/wrappers/defect";
import { DefaultContext } from "../../../contexts/default";
import useDarkMode from "../../../hooks/useDarkMode";
import ProductDB from "../../../database/wrappers/product";
import Button from "../../../components/bootstrap/Button";
import PageWrapper from "../../../layout/PageWrapper/PageWrapper";
import SubHeader, { SubHeaderLeft, SubHeaderRight } from "../../../layout/SubHeader/SubHeader";
import Breadcrumb from "../../../components/bootstrap/Breadcrumb";
import Page from "../../../layout/Page/Page";
import Card, { CardBody, CardHeader, CardLabel, CardTitle } from "../../../components/bootstrap/Card";
import SelectEstablishment from "../../../components/MyCustom/SelectEstablishment";
import SelectSector from "../../../components/MyCustom/SelectSector";
import TableCustom from "../../../components/MyCustom/TableCustom";
import { ACCESS_LEVEL } from "../../../types/roles";
import { demoPages } from "../../../menu";
import ModalRegisterDefects from "../../../components/modals/Defects";
import SelectEvent from "../../../components/MyCustom/SelectEvent";
;


const Defect = () => {

  const { darkModeStatus } = useDarkMode();
  const [openModalRegister, setOpenModalRegister] = useState(false);
  const { establishment, store, onShowAlert, accessLevel } = useContext(DefaultContext)
  const [defects, setDefects] = useState(null)
  const [defectsRender, setDefectsRender] = useState([])
  const [storeProductsDicionary, setstoreProductsDicionary] = useState(null)
  const [defectSelected, setDefectSelected] = useState({});

  useEffect(() => {
    if (!establishment) return;
    const onSubscribe = new DefectDB(establishment.id).on(datas => {
      setDefects(datas)
    })
    return onSubscribe
  }, [establishment, store])

  const row = [
    {
      id: 'testeastasdsd212',
      name: 'Teste',
      description: 'Teste',
      qtd: 10,
      unit: 'kg',
      type: 'Sobra',
      storeId: 'apMheC5iSkaImLEhG3FT',
      productId: '74ghth6WMVIT3Ag54JpD'
    }
  ]
  useEffect(() => {
    if (!establishment || !store) return;
    const onSubscribe = new ProductDB(establishment.id).on(datas => {
      const dicionary = {};
      datas.forEach(data => dicionary[data.id] = data)
      setstoreProductsDicionary(dicionary)
    });
    return onSubscribe;
  }, [establishment, store])

  useEffect(() => {
    if (storeProductsDicionary !== null && defects !== null) {
      const defectsRender = defects
        .filter(defect => defect.storeId === store.id)
        .map(defect => {
          const storeProd = storeProductsDicionary[defect.productId];
          storeProd.defect = defect;
          return {
            ...storeProd,
          };
        });

      setDefectsRender(defectsRender);
    }
  }, [defects, storeProductsDicionary]);


  const handleOpenModalRegister = useCallback(() => {
    setDefectSelected(null)
    setOpenModalRegister(true);
  }, [])

  const handleOpenModalEdit = useCallback((item) => {
    setDefectSelected(item)
    setOpenModalRegister(true);
  }, [])

  const handleCloseModalRegister = useCallback(() => {
    setOpenModalRegister(false);
  }, [])

  const columns = useMemo(() => ([
    {
      label: 'Imagem',
      field: 'image_url',
      format: row => (
        <img src={row.image_url} alt="Imagem" width={54} height={54} />
      )
    },
    {
      label: 'Nome',
      field: 'name',
    },
    {
      label: 'Unidade',
      field: 'unit',
    },
    {
      label: 'Quantidade',
      field: 'qtd',
      format: row => (
        row.defect.qtd
      )
    },
    {
      label: 'Tipo',
      field: 'type',
      format: row => (
        row?.defect?.type
      )
    },
    {
      label: 'Ações',
      field: 'actions',
      format: row => (
        <div className='w-50 d-flex justify-content-between'>
          <Button
            color={darkModeStatus ? 'light' : 'dark'}
            icon="edit"
            hoverShadow="md"
            size="sm"
            text=''
            onClick={() => handleOpenModalEdit(row)}
          />
        </div>
      ),
      noExport: true
    },
  ]), [handleOpenModalEdit])

  return (
    <>
      <PageWrapper title={demoPages.defect.text}>
        <SubHeader>
          <SubHeaderLeft>
            <Breadcrumb
              list={[
                { title: 'Avariados', to: '/defect' },
                {
                  title: 'Avaria ou Sobra',
                  to: '/store/defect'
                },
              ]}

            />
          </SubHeaderLeft>
          <SubHeaderRight>
            {accessLevel >= ACCESS_LEVEL.MANAGER &&
              <Button
                color='primary'
                icon='plus'
                shadow="sm"
                hoverShadow="sm"
                onClick={handleOpenModalRegister}
              >
                Cadastrar
              </Button>}
          </SubHeaderRight>
        </SubHeader>
        <Page container='fluid'>
          <Card>
            <CardHeader>
              <CardLabel  icon='ReportProblem' iconColor={darkModeStatus ? 'light' : 'dark'}>
                <CardTitle>Avariados</CardTitle>
              </CardLabel>
            </CardHeader>
            <CardBody className='table-responsive p-5'>
              <div className='row d-flex align-items-center mt-4 mx-1' >
                <SelectEstablishment />
                <SelectEvent />
              </div>
              {defectsRender && defectsRender.length > 0 && (

                <TableCustom columns={columns} rows={defectsRender.length > 0 ? defectsRender : []} />
              )}
            </CardBody>
          </Card>
        </Page>
      </PageWrapper>
      <ModalRegisterDefects
        open={openModalRegister}
        setIsOpen={setOpenModalRegister}
        setIsClose={handleCloseModalRegister}
        defectSelected={defectSelected}
      />

    </>


  );
};

export default Defect;
