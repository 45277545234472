import React from "react";
import dateFormat from "dateformat";
import Footer from "../../Footer";
import { MONTH_PTBR } from "../../../types/dates";


const LastPage = ({ establishment, eventSelected }) => {


  const dataAtual = new Date();

  return (
    <>
      <div className='col-xxl-12 justify-content-around '>
        <h6>Por ser expressão da verdade, e estarem de comum acordo com o presente termo firmam o presente.</h6>
        <h6 className='mt-2 fw-bold'>São Paulo, {dateFormat(dataAtual, 'dd')} de {MONTH_PTBR[dataAtual.getMonth()]} de {dateFormat(dataAtual, 'yyyy')}</h6>
      </div>
      <div className='divFooter'>
        <div className='d-flex text-center flex-column col-xxl-12 justify-content-around mt-report  my-4 py-4'>
          <h6>UGET PDX TECNOLOIGA E SOLUÇÕES LTDA.</h6>
        </div>
        <div className='d-flex text-center flex-column col-xxl-12 justify-content-around mt-report my-4 py-4'>
          <h6>CLIENTE - {establishment?.name}</h6>
        </div>
        <div id='ugetWitnesses' className='row justify-content-between mt-report  my-4'>
          <div className='col-6 p-0  text-center'>
            <h6>TESTEMUNHA UGET</h6>
            <h6>{eventSelected?.witnesses.ugetWitnessName}</h6>
            <h6>CPF: {eventSelected?.witnesses.ugetWitnessCpf}</h6>
          </div>
          <div className='col-6 p-0 text-center'>
            <h6>TESTEMUNHA EVENTO</h6>
            <h6>{eventSelected?.witnesses.eventWitnessName}</h6>
            <h6>CPF: {eventSelected?.witnesses.eventWitnessCpf}</h6>
          </div>
        </div>
        <Footer />
      </div>
    </>
  );
}

export default LastPage;