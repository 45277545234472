import {
  collection,
  CollectionReference,
	DocumentData,
	getDocs,
	getFirestore,
	onSnapshot,
	query,
	QueryConstraint,
	Timestamp,
	Unsubscribe,
	where,
} from 'firebase/firestore';
import app from '../../config';
import TaxDevolution from '../../entities/eventData/taxaDevolution.entity';

class TaxDevolutionEventData {
	protected readonly colRefStr: string;
  protected readonly colRef: CollectionReference<DocumentData> ;

	constructor(estabId: string, eventId: string) {
		this.colRefStr = `EstablishmentsData/${estabId}/EventsData/${eventId}/TaxDevolution`;
    this.colRef = collection(getFirestore(app), this.colRefStr);
	}

	async getByNfc(nfc: string): Promise<TaxDevolution[]> {
		const snapshot = await getDocs(query(this.colRef, where('idCard', '==', nfc)))
		return snapshot.docs.map(doc => ({
			id: doc.id,
			...doc.data(),
		})) as any
	}

	on(listener: (data: TaxDevolution[]) => void, ...params: QueryConstraint[]): Unsubscribe {
		return onSnapshot(query(this.colRef, ...params), snapshot => {
      const datas: TaxDevolution[] = [];
      snapshot.forEach(doc => datas.push({ id: doc.id, ...doc.data() as any } as TaxDevolution))
      listener(datas)
    })
	}

	async getAllInterval(startDate: Date, endDate: Date): Promise<TaxDevolution[]> {
		const snapshot = await getDocs(query(this.colRef,
			where('created_at', '>=', Timestamp.fromDate(startDate)),
			where('created_at', '<=', Timestamp.fromDate(endDate))
		))
		return snapshot.docs.map(doc => ({
			id: doc.id,
			...doc.data(),
		})) as any
	}

	async getBySector(sectorId: string): Promise<TaxDevolution[]> {
		const snapshot = await getDocs(query(this.colRef,
			where('sectorId', '==', sectorId)
		))
		return snapshot.docs.map(doc => ({
			id: doc.id,
			...doc.data(),
		})) as any
	}

}

export default TaxDevolutionEventData;
