import { Firestore, QueryConstraint, Unsubscribe, collection, getDocs, getFirestore, limit, query, where } from "firebase/firestore";
import Customer from "../entities/customer";
import FirestoreGlobalPipe from "../pipe";
import { appGlobal } from "../firebaseConfig";
class CustomersGlobalDB extends FirestoreGlobalPipe {

  public static readonly colName = 'customers'

  constructor() {
    super(CustomersGlobalDB.colName);
  }

  public async create(data: Customer): Promise<any> {
    try {
      await this.saveFile(data, 'photo');
      await this._create(data);
    } catch (error: any) {
      if (data.photo_ref)
        await this.deleteFile(data.photo_ref)
      throw error.message
    }
  }

  public async update(id: string, data: any): Promise<any> {
    try{
      await this.saveFile(data, 'photo');
      await this._update(id, data);
    } catch(error: any) {
      if(data.photo_ref)
        await this.delete(data.photo_ref)
      throw error.message

    }
  }
  public delete(id: string): Promise<any> {
    return this._delete(id);
  }
  public getAll(...params: QueryConstraint[]): Promise<Customer[]> {
    return this._getAll<Customer>(...params);
  }
  public get(id: string): Promise<Customer> {
    return this._get(id);
  }
  public on(listener: (data: Customer[]) => void, ...params: QueryConstraint[]): Unsubscribe {
    return this._on(listener, ...params);
  }

  async getByPhone(phone: string): Promise<Customer | null> {
    const snapshot = await getDocs(query(this.colRef, where('phone', '==', phone), limit(1)))
    if (snapshot.empty)
      return null;

    const doc = snapshot.docs[0];
    return {
      id: doc.id,
      ...doc.data(),
    } as any;
  }

  async getByCPF(cpf: string): Promise<Customer | null> {
    const snapshot = await getDocs(query(this.colRef, where('cpf', '==', cpf), limit(1)))
    if (snapshot.empty)
      return null;

    const doc = snapshot.docs[0];
    return {
      id: doc.id,
      ...doc.data(),
    } as any;
  }

  private async saveFile(data: any, label: string) {
    if (data[label]) {
      console.log('UPLOAD IMAGE');
      const resultUpload = await this.uploadFile(new Date().getTime().toString(), data[label]);
      data[label + '_ref'] = resultUpload.ref.fullPath;
      data[label + '_url'] = resultUpload.url;
    }
    delete data[label]
  }

}

export default CustomersGlobalDB;