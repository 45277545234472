import React, { useCallback, useMemo, useState, useContext, useEffect } from "react";
import Card, { CardBody, CardHeader, CardLabel, CardTitle } from "../../../../../components/bootstrap/Card";
import TableCustom from "../../../../../components/MyCustom/TableCustom";
import Money from "../../../../../helpers/utils/money/money";
import Button from "../../../../../components/bootstrap/Button";
import dateFormat from "dateformat";
import useDarkMode from "../../../../../hooks/useDarkMode";
import { cpfFormatMask } from "../../../../../utils/format/cpfFormat";
import { DefaultContext } from "../../../../../contexts/default";
import SectorDB from "../../../../../database/wrappers/sector";
import convertArrayToObject from "../../../../../utils/convertArrayToObject";
import useDicionary from "../../../../../hooks/TotaisReports/useDicionarys.js";
import SelectSector from "../../../../../components/MyCustom/SelectSector";


const RelatorioCaixas = ({ cashiers }) => {
  const { darkModeStatus } = useDarkMode();
  const { sectorSelected } = useContext(DefaultContext)
  const { sectorsDicionary } = useDicionary();
  const [row, setrows] = useState([]);
  const calculateTotal = useCallback((cashier) => {
    const { cash, credit, debit, pix, courtesy, balanceReversal } = cashier;
    return cash + credit + debit + pix + courtesy + balanceReversal;
  }, [])

  const calculateBalanceToPay = useCallback((cashier) => {
    const { cash, cash_return, bleed } = cashier;
    return cash + cash_return - bleed;
  }, [])

  const rowsRender = useMemo(() =>
    cashiers.map(row => {
      const amountToPay = calculateBalanceToPay(row)
      return {
        ...row,
        cpfLabel: cpfFormatMask(row.cpf),
        total: calculateTotal(row),
        amountToPay,
        final: row.closed ? row.paid - amountToPay
          : - amountToPay
      }
    })
    , [cashiers])


  const columns = useMemo(() => ([
    {
      label: 'Abertura',
      field: 'opening_at',
      format: row => dateFormat(row.opening_at.toDate(), 'dd/mm/yyyy HH:MM'),
      formatExport: value => dateFormat(value.toDate(), 'dd/mm/yyyy HH:MM'),
    },
    {
      label: 'Status',
      field: 'closed',
      format: row => (
        <Button
          isLink
          color={row.closed ? 'danger' : 'success'}
          icon={row.closed ? 'Lock' : 'Check'}
          className='text-nowrap'>
          {/* {row.closed ? 'Fechado' : 'Aberto'} */}
        </Button>
      )
    },
    {
      label: 'Operador',
      field: 'operator',
      format: row => row.operator.substring(0, 15),
    },
    {
      label: 'Setor',
      field: 'sectorId',
      format: row => sectorsDicionary ? sectorsDicionary[row.sectorId]?.name : ''
    },
    {
      label: 'CPF',
      field: 'cpfLabel',
      hidden: true,
    },
    {
      label: 'Terminal',
      field: 'terminal',
      hidden: true,
    },
    {
      label: 'Troco',
      field: 'cash_return',
      format: row => Money.centsToMaskMoney(row.cash_return),
      formatExport: value => Money.centsToCoin(value),
    },
    {
      label: 'Sangria',
      field: 'bleed',
      format: row => Money.centsToMaskMoney(row.bleed),
      formatExport: value => Money.centsToCoin(value),
    },
    {
      label: 'Dinheiro',
      field: 'cash',
      format: row => Money.centsToMaskMoney(row.cash),
      formatExport: value => Money.centsToCoin(value),
    },
    {
      label: 'Debito',
      field: 'debit',
      format: row => Money.centsToMaskMoney(row.debit),
      formatExport: value => Money.centsToCoin(value),
    },
    {
      label: 'Credito',
      field: 'credit',
      format: row => Money.centsToMaskMoney(row.credit),
      formatExport: value => Money.centsToCoin(value),
    },
    {
      label: 'Pix',
      field: 'pix',
      format: row => Money.centsToMaskMoney(row.pix),
      formatExport: value => Money.centsToCoin(value),
    },
    {
      label: 'Cortesia',
      field: 'courtesy',
      format: row => Money.centsToMaskMoney(row.courtesy),
      formatExport: value => Money.centsToCoin(value),
    },
    {
      label: 'Saldo Estor.',
      field: 'balanceReversal',
      format: row => Money.centsToMaskMoney(row.balanceReversal),
      formatExport: value => Money.centsToCoin(value),
    },

    // {
    //   label: 'Rec. Antecipada',
    //   field: 'advanceRecharge',
    //   format: row => Money.centsToMaskMoney(row.advanceRecharge),
    //   formatExport: value => Money.centsToCoin(value),
    // },
    {
      label: 'Pagar',
      field: 'amountToPay',
      format: row => Money.centsToMaskMoney(row.amountToPay),
      formatExport: value => Money.centsToCoin(value),
    },
    {
      label: 'Pago',
      field: 'paid',
      format: row => Money.centsToMaskMoney(row.paid || 0),
      formatExport: value => Money.centsToCoin(value || 0),
      hidden: true,
    },
    {
      label: 'Final',
      field: 'final',
      format: row => <span className={row.final < 0 ? "text-danger" : "text-success"}>{Money.centsToMaskMoney(row.final)}</span>,
      formatExport: value => Money.centsToCoin(value),
    },
  ]), [sectorsDicionary])

  return (
    <Card >
      <CardHeader>
        <CardLabel icon='PointOfSale' iconColor={!darkModeStatus ? 'dark' : 'light'}>
          <CardTitle>CAIXAS</CardTitle>
        </CardLabel>
      </CardHeader>
      <div className="col-12 ">
        <CardBody className='table-responsive p-5 align-items-center'>
          <TableCustom
            rows={rowsRender}
            columns={columns}
            keyExtractor={row => row.id}
          />
        </CardBody>
      </div>


    </Card >
  );
}

export default RelatorioCaixas;