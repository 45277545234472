import React from "react";
import Logo from "../../Logo";


const HeaderContrato = ({ establishment }) => {
    return (
        <div className='row'>
            <div className='col p-0 px-4  text-center'>
                <Logo width={200} black />

                <h6 className='my-3 fw-bold'>CONTRATO DE LICENÇA DE SOFTWARE,</h6>
                <h6 className='fw-bold'>PRESTAÇÃO DE SERVIÇOS E COMODATO DE MÁQUINAS. </h6>
                <h6 className='my-3 fw-bold'>CONTRATO Nº {establishment?.code_auth}</h6>
            </div>

        </div>
    );
}

export default HeaderContrato;