import React, { useState, useCallback, useMemo, useContext, useEffect } from 'react';
import Card, { CardBody, CardHeader, CardLabel, CardTitle } from '../../components/bootstrap/Card';
import useDarkMode from '../../hooks/useDarkMode';
import TableSimples from '../../components/MyCustom/TableSimples';
import dateFormat from 'dateformat';
import Popovers from '../../components/bootstrap/Popovers';
import Button, { ButtonGroup } from '../../components/bootstrap/Button';



const EventsContracts = ({ hidden }) => {
    const { themeStatus, darkModeStatus, setDarkModeStatus } = useDarkMode();

    const rows = useMemo(() => ([
        {
            id: 1,
            date: '01/01/2021',
            contract: '123456',
            event: 'Evento 1',
            status: 'Não Assinado',
        },
        {
            id: 2,
            date: '01/01/2021',
            contract: '123456',
            event: 'Evento 2',
            status: 'Não Assinado',
        },
        {
            id: 3,
            date: '01/01/2021',
            contract: '123456',
            event: 'Evento 3',
            status: 'Não Assinado',
        }
    ]), [])

    const columns = useMemo(() => ([
        {
            label: 'DATA',
            field: 'date',
            format: row => typeof row.date === 'string' ? `${row.date.substring(8, 10)}/${row.date.substring(5, 7)}/${row.date.substring(0, 4)}`
                : dateFormat(row.date.toDate(), 'dd/mm/yyyy'),
            formatExport: value => typeof value === 'string' ? `${value.substring(8, 10)}/${value.substring(5, 7)}/${value.substring(0, 4)}`
                : dateFormat(value.toDate(), 'dd/mm/yyyy'),
        },
        {
            label: 'CONTRATO',
            field: 'contract',
        },
        {
            label: 'EVENTO',
            field: 'event',
        },
        {
            label: 'STATUS',
            field: 'status',
        },
        {
            label: 'AÇÕES',
            field: 'actions',
            format: row => (
                <div className='w-50 d-flex justify-content-around'>
                    <Popovers trigger='hover' desc='Assinar'>
                        <Button
                            color={darkModeStatus ? 'light' : 'dark'}
                            icon="EditNote"
                            shadow="sm"
                            hoverShadow="sm"
                            size="sm"
                            onClick={() => { console.log('teste') }}
                        />
                    </Popovers>
                    <Popovers trigger='hover' desc='Download'>
                        <Button
                            color={darkModeStatus ? 'light' : 'dark'}
                            icon="Download"
                            shadow="sm"
                            hoverShadow="sm"
                            size="sm"
                            onClick={() => { console.log('teste') }}
                        />
                    </Popovers>
                </div>
            ),

        }
    ]), [])


    return (
        <Card hidden={hidden}>
            <CardBody>
                <TableSimples
                    rows={rows}
                    columns={columns}
                    keyExtractor={row => row.id}
                />
            </CardBody>
        </Card>
    )
}

export default EventsContracts;