import React, { useContext, memo, useRef, useState } from 'react';
import Modal, { ModalBody, ModalHeader, ModalTitle } from '../../bootstrap/Modal';
import { QRCodeSVG } from 'qrcode.react';
import { DefaultContext } from '../../../contexts/default';
import dateFormat from 'dateformat';
import Button from '../../bootstrap/Button';
import { useReactToPrint } from 'react-to-print';


const ModalQrcode = ({ open, setIsOpen, qrcodeData, eventselect }) => {
  const { establishment, event } = useContext(DefaultContext)
  const componentRef = useRef(null);
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    documentTitle: 'Código QR - ' + eventselect?.name,
    // onAfterPrint: () => setIsOpen(false),
  });
  return (
    <Modal
      id={'modal-qrcode-operators'}
      titleId={'Login com Qrcode'}
      isOpen={open}
      setIsOpen={setIsOpen}
      isStaticBackdrop={true}
      isScrollable={false}
      isCentered={true}
      size="lg" // 'sm' || 'lg' || 'xl' 
      isAnimation={true}
    >
      <ModalHeader setIsOpen={setIsOpen}>
        <ModalTitle id="qrcode-operators">Login com Qrcode</ModalTitle>
      </ModalHeader>
      <ModalBody >
        <div className='mt-4 d-flex flex-column flex-row align-items-center justify-content-between' ref={componentRef}>
          <h5><b style={{ marginRight: 3 }}>Estabelecimento: </b>{establishment?.name}</h5>
          <h5><b style={{ marginRight: 3 }}>Evento: </b>{eventselect?.name}</h5>
          <h5><b style={{ marginRight: 3 }}>Data do Evento: </b>{dateFormat(eventselect?.date.toDate() || new Date(), 'dd/mm/yyyy')}</h5>
          <div style={{ padding: 10, backgroundColor: 'white' }}>
            <QRCodeSVG
              value={
                qrcodeData &&
                `https://${window.location.host}/cadastro-operador/?eventNumber=${qrcodeData?.id}&key=${qrcodeData?.key}`
                ||
                'Sem dados'}
              size={150}
            />
          </div>
        </div>
        <div className='d-flex flex-row col-md-12 align-content-center justify-content-center my-4'>
          <Button style={{ display: 'block' }} onClick={handlePrint} className='col-md-3' type='button' color='primary' icon="PictureAsPdf" rounded={1} hoverShadow="sm" shadow="sm" size="sm">
            Imprimir ou Baixar
          </Button>
        </div>
      </ModalBody>
    </Modal>
  )
}

export default memo(ModalQrcode);