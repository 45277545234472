import React, { useState, useContext, useEffect, useCallback, memo } from 'react';
import { useFormik } from 'formik';
import FormGroup from '../../bootstrap/forms/FormGroup';
import Input from '../../bootstrap/forms/Input';
import Checks from '../../bootstrap/forms/Checks';
import Modal, { ModalBody, ModalHeader, ModalTitle } from '../../bootstrap/Modal';
import masks from '../../../helpers/utils/masks';
import InputGroup, { InputGroupText } from '../../bootstrap/forms/InputGroup';
import { BASIC_ROLE } from '../../../types/roles';
import { DefaultContext } from '../../../contexts/default';

import api from '../../../services/api';
import PreAlert from '../../../helpers/utils/preAlert';
import BtnLoad from '../../MyCustom/BtnLoad';
import BtnCancel from '../../MyCustom/BtnCancel';
import BtnConfirm from '../../MyCustom/BtnConfirm';
import { inputOnlyNumbers } from '../../../utils/input';
import { cpfFormatMask } from '../../../utils/format/cpfFormat';

const ModalRegisterStockist = ({ open, setIsOpen, setIsClose, editData }) => {

  const { onShowAlert } = useContext(DefaultContext)

  const [isLoading, setIsLoading] = useState(false);

  const validate = useCallback((values) => {
    const errors = {};
  
    if (!values.name) {
      errors.name = 'Este campo é necessário';
    } else if (values.name.length < 3) {
      errors.name = 'O nome precisa ter 3 caracteres ou mais';
    }

    if (!values.email) {
      errors.email = 'Este campo é necessário.';
    } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)) {
      errors.email = 'Email inválido.';
    }
  
    if (!values.cpf) {
      errors.cpf = 'Este campo é necessário';
    } else if (values.cpf.length < 14) {
      errors.cpf = 'Informe o cpf completo';
    }

    if (!values.cpf) {
      errors.cpf = 'Este campo é necessário';
    }
    if (!values.phone) {
      errors.phone = 'Este campo é necessário';
    }
  
    if (!values.active) {
      errors.active = 'Você precisa escolher o status da categoria';
    }
  
    if(!editData) {
      if (!values.password || values.password.length < 6) {
        errors.password = 'A senha precisa ter 6 caracteres ou mais';
      }
    }
  
    return errors;
  },[editData])

  const formik = useFormik({
    initialValues: {
      name: '',
      cpf: '',
      phone: '',
      email: '',
      // cardNumber: '',
      active: '',
      password: '',
    },
    validate,
    onSubmit: async values => {
      const { 
        name,
        cpf,
        phone,
        email,
        active,
        password,
      } = values;

      const data = {
        name,
        email,
        cpf: Number(cpf?.replace(/\D/g, "") || 0),
        phone: Number(phone?.replace(/\D/g, "") || 0),
        // cardNumber,
        role: BASIC_ROLE.STOCKIST,
        password,
        disabled: !active,
      }

      const onSuccess = () => {
        onShowAlert(PreAlert.success('Registro cadastrado com sucesso'))
        setIsClose();
      }
      const onSuccessUpdate = () => {
        onShowAlert(PreAlert.success('Registro atualizado com sucesso'))
        setIsClose();
      }
      const onError = () => {
        onShowAlert(PreAlert.error('Falhou ao cadastrar o registro'))
      }

      setIsLoading(true);
      if (editData) {
        data.uid = editData.id
        api.put('routes/users', data)
          .then(onSuccessUpdate)
          .catch(onError)
          .finally(() => setIsLoading(false))
      } else {
        api.post('routes/users', data)
          .then(onSuccess)
          .catch(onError)
          .finally(() => setIsLoading(false))
      }
    },
  })

  useEffect(() => {
    if (!open) return formik.resetForm();
    if (editData) {
      const {
        disabled,
        name,
        cpf,
        phone,
        email,
      } = editData;

      formik.setValues({
        name,
        active: !disabled,
        email,
        phone: masks.phone(String(phone)),
        cpf: cpfFormatMask(String(cpf)),
      });
    }
  }, [editData, open])

  const onChangeCpf = useCallback((e) => {
    formik.setFieldValue('cpf', masks.cpf(e.target.value))
  }, [])

  const onChangePhone = useCallback((e) => {
    formik.setFieldValue('phone', masks.phone(e.target.value))
  }, [])

  return (
    <Modal
      id={'modal-register-category'}
      titleId={'Cadastro de Categoria'}
      isOpen={open}
      setIsOpen={setIsOpen}
      isStaticBackdrop={true}
      isScrollable={false}
      isCentered={true}
      size="lg" // 'sm' || 'lg' || 'xl' 
      isAnimation={true}
      onSubmit={formik.handleSubmit}
    >
      <ModalHeader setIsOpen={setIsOpen}>
        <ModalTitle id="register-user">{editData ? 'Atualização de Vendedor' : 'Cadastro de Vendedor'}</ModalTitle>
      </ModalHeader>
      <ModalBody>
        <form noValidate onSubmit={formik.handleSubmit}>
          <div className="row g-4">
            <FormGroup id="name" label="Nome" className='col-md-8'>
              <Input
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.name}
                isValid={formik.isValid}
                isTouched={formik.touched.name}
                invalidFeedback={formik.errors.name}
                validFeedback='Assim está bom!'
                placeholder='Ex: João da Silva'
              />
            </FormGroup>

            <FormGroup id="email" label="Email" className='col-md-4'>
              <InputGroup>
                <InputGroupText id="inputGroupPrepend">
                  @
                </InputGroupText>
                <Input
                  id="email"
                  ariaDescribedby='inputGroupPrepend'
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.email}
                  isValid={formik.isValid}
                  isTouched={formik.touched.email}
                  invalidFeedback={formik.errors.email}
                  validFeedback='Assim está bom!'
                  placeholder="joaodasilva@email.com"
                  disabled={editData !== null}
                />
              </InputGroup>
            </FormGroup>

            <FormGroup id='cpf' label='CPF' className='col-md-3'>
              <Input
                onChange={onChangeCpf}
                onBlur={formik.handleBlur}
                value={formik.values.cpf}
                isValid={formik.isValid}
                isTouched={formik.touched.cpf}
                invalidFeedback={formik.errors.cpf}
                validFeedback='Assim está bom!'
                placeholder='000.000.000-00'
                disabled={editData !== null}
              />
            </FormGroup>

            <FormGroup id='phone' label='Telefone' className='col-md-3'>
              <Input
                onChange={onChangePhone}
                onBlur={formik.handleBlur}
                value={formik.values.phone}
                isValid={formik.isValid}
                isTouched={formik.touched.phone}
                invalidFeedback={formik.errors.phone}
                validFeedback='Assim está bom!'
                placeholder='(00) 00000-0000'
              />
            </FormGroup>

            <FormGroup id="password" label="Senha" className='col-md-4'>
              <Input
                type='password'
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.password}
                isValid={formik.isValid}
                isTouched={formik.touched.password}
                invalidFeedback={formik.errors.password}
                validFeedback='Assim está bom!'
                placeholder='Ex: 01234'
                disabled={editData !== null}
              />
            </FormGroup>

            {/* Status */}
            <FormGroup id='active' label='Status' className='col-md-3'>
              <Checks
                type="switch"
                id="active"
                label={formik.values.active ? 'Ativo' : 'Inativo'}
                name="active"
                onChange={formik.handleChange}
                checked={formik.values.active}
              />
            </FormGroup>
          </div>

          {/* Buttons */}
          <div className="row pt-4">
            <div className="col-md-4 offset-md-8">
              <div className='d-flex justify-content-evenly'>
                <BtnCancel setIsClose={setIsClose} />
                {isLoading ? (
                  <BtnLoad />
                ) : (
                  <BtnConfirm isDisable={!formik.isValid} />
                )}
              </div>
            </div>
          </div>
        </form>
      </ModalBody>
    </Modal>
  )
}

export default memo(ModalRegisterStockist);