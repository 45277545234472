import React, { memo } from "react";
import Button from "../../bootstrap/Button";

const EventsType = ({ types }) => {
  const {
    ticket,
    hawker,
    command,
    cashless,
    totem,
  } = types;
  return (
    <div className="row">
      {ticket &&
        <Button
          isLink
          color={'success'}
          icon='Circle'
          className='col-md-4'>
          Ticket
        </Button>
      }
      {hawker &&
        <Button
          isLink
          color={'info'}
          icon='Circle'
          className='col-md-4'>
          Hawker
        </Button>
      }
      {command &&
        <Button
          isLink
          color={'danger'}
          icon='Circle'
          className='col-md-4'>
          Comanda
        </Button>
      }
      {cashless &&
        <Button
          isLink
          color={'primary'}
          icon='Circle'
          className='col-md-4'>
          Cashless
        </Button>
      }
      {totem &&
        <Button
          isLink
          color={'warning'}
          icon='Circle'
          className='col-md-4'>
          Totem
        </Button>
      }
    </div>
  );
}

export default memo(EventsType);