import { doc, setDoc, getDoc, getFirestore } from "firebase/firestore";
import EstablishmentDB from "./establishment";
import app from "../config";

class NumbersDB {

  public static readonly colName = 'Numbers';
  protected readonly colRefStr: string;

  constructor(idEstab: string) {
    this.colRefStr = `${EstablishmentDB.colName}/${idEstab}/${NumbersDB.colName}`
  }

  public async incrementTerminal(): Promise<number> {
    const docRef = doc(getFirestore(app), this.colRefStr, 'terminal')
    const docSnap = await getDoc(docRef)
    const newNumber = docSnap.exists() ?
                      docSnap.data().number + 1 : 1;
    await setDoc(docRef, { number: newNumber })
    return newNumber;
  }

}

export default NumbersDB;