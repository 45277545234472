import React, { useState, useEffect, useContext, useCallback, memo, useMemo } from 'react';
import { useFormik } from 'formik';
import Button from '../../bootstrap/Button';
import Modal, { ModalBody, ModalHeader, ModalTitle } from '../../bootstrap/Modal';
import PreAlert from '../../../helpers/utils/preAlert';
import { DefaultContext } from '../../../contexts/default';
import BtnLoad from '../../MyCustom/BtnLoad';
import ListOperators from './ListOperators';
import { BASIC_ROLE, ROLE, ROLE_PTBR } from '../../../types/roles';
import OperatorsDB from '../../../database/wrappers/operatorsEvent';
import SectorDB from '../../../database/wrappers/sector';
import BtnCancel from '../../MyCustom/BtnCancel';
import BtnConfirm from '../../MyCustom/BtnConfirm';
import FormGroup from '../../bootstrap/forms/FormGroup';
import Input from '../../bootstrap/forms/Input';
import Select from '../../bootstrap/forms/Select';
import { Options } from '../../bootstrap/Option';
import convertArrayToObject from '../../../utils/convertArrayToObject';

const validate = (values) => {
  const errors = {};

  return errors;
}

const ModalEditOperators = ({ open, setIsOpen, setIsClose, operatorSelected, usersOperatorsDicionary }) => {

  const { onShowAlert, establishment, event } = useContext(DefaultContext)
  const [sectorDicionary, setsectorDicionary] = useState({})
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (!establishment) return;
    const onSubscriber = new SectorDB(establishment.id).on(datas => setsectorDicionary(convertArrayToObject(datas, 'id')))
    return onSubscriber;
  }, [establishment])

  useEffect(() => {
    if (!open) return formik.resetForm();
    if (operatorSelected) {
      const { role, sectorId } = operatorSelected
      formik.setValues({ role, sectorId })
    }
  }, [open, operatorSelected])

  const formik = useFormik({
    initialValues: {
      role: ROLE.TICKET,
      sectorId: ''
    },
    validate,
    onSubmit: values => {
      const { role, sectorId } = values;

      const data = {
        role,
        sectorId
      }

      const onSuccess = () => {
        onShowAlert(PreAlert.success('Registro atualizado com sucesso'))
        setIsClose();
      }
      const onError = () => {
        onShowAlert(PreAlert.error('Falhou ao cadastrar o registro'))
      }

      setIsLoading(true)
      new OperatorsDB(establishment.id, event.id)
        .update(operatorSelected.id, data)
        .then(onSuccess)
        .catch(onError)
        .finally(() => setIsLoading(false))
    },
  })

  const rolesOptions = useMemo(() => Object.keys(ROLE).map(key => ({ value: ROLE[key], text: ROLE_PTBR[ROLE[key]] })), [])
  const sectorsOptions = useMemo(() => {
    const options = Object.keys(sectorDicionary).map(key => ({ value: sectorDicionary[key].id, text: sectorDicionary[key].name }))
    options.sort((a, b) => {
      if(a.text > b.text) return 1;
      return -1;
    })
    return options
  }, [sectorDicionary])

  return (
    <Modal
      id={'modal-edit-operator'}
      titleId={'Cadastro e Update de Entrada Antecipada'}
      isOpen={open}
      setIsOpen={setIsOpen}
      isStaticBackdrop={true}
      isScrollable={false}
      isCentered={true}
      size="lg" // 'sm' || 'lg' || 'xl' 
      isAnimation={true}
      onSubmit={formik.handleSubmit}
    >
      <ModalHeader setIsOpen={setIsOpen}>
        <ModalTitle id="edit-operator">Editar Operador</ModalTitle>
      </ModalHeader>
      <ModalBody>
        <form noValidate onSubmit={formik.handleSubmit}>
          <div className="row g-4">
            <div className='col-md-6'>
              <FormGroup>
                <Input value={usersOperatorsDicionary[operatorSelected?.id]?.name} disabled readOnly />
              </FormGroup>
            </div>
            <div className='col-md-3'>
              <FormGroup id='role'>
                <Select
                  id="role"
                  onChange={formik.handleChange}
                  value={formik.values.role}
                >
                  <Options list={rolesOptions} />
                </Select>
              </FormGroup>
            </div>
            <div className='col-md-3'>
              <FormGroup id='sectorId'>
                <Select
                  id="sectorId"
                  onChange={formik.handleChange}
                  value={formik.values.sectorId}
                >
                  <Options list={sectorsOptions} />
                </Select>
              </FormGroup>
            </div>
          </div>
          {/* Buttons */}
          <div className="row pt-4">
            <div className="col-md-4 offset-md-8">
              <div className='d-flex justify-content-evenly'>
                <BtnCancel setIsClose={setIsClose} />
                {isLoading ? (
                  <BtnLoad />
                ) : (
                  <BtnConfirm isDisable={!formik.isValid} />
                )}
              </div>
            </div>
          </div>
        </form>
      </ModalBody>
    </Modal>
  )
}

export default memo(ModalEditOperators);