import {
	collection,
	CollectionReference,
	doc,
	DocumentData,
	getDoc,
	getDocs,
	getFirestore,
	limit,
	onSnapshot,
	query,
	QueryConstraint,
	Unsubscribe,
	where,
} from 'firebase/firestore';
import app from '../../config';
import NFC from '../../entities/eventData/nfc.entity';

class NFCEventData {
	protected readonly colRefStr: string;
	protected readonly colRef: CollectionReference<DocumentData>;

	constructor(estabId: string, eventId: string) {
		this.colRefStr = `EstablishmentsData/${estabId}/EventsData/${eventId}/NFCs`;
		this.colRef = collection(getFirestore(app), this.colRefStr);
	}

	async get(id: string): Promise<NFC | null> {
		const dbDoc = doc(getFirestore(app), this.colRefStr, id)
		const snapshot = await getDoc(dbDoc)
		if (!snapshot.exists())
			return null;
		return {
			id: snapshot.id,
			...snapshot.data()
		} as any
	}

	async getByCpf(cpf: string): Promise<NFC | null> {
		const snapshot = await getDocs(query(this.colRef, where('cpf', '==', cpf), limit(1)))
		if (snapshot.empty)
			return null;

		const doc = snapshot.docs[0];
		return {
			id: doc.id,
			...doc.data(),
		} as any;
	}

	on(listener: (data: NFC[]) => void, ...params: QueryConstraint[]): Unsubscribe {
		return onSnapshot(query(this.colRef, ...params), snapshot => {
			const datas: NFC[] = [];
			snapshot.forEach(doc => datas.push({ id: doc.id, ...doc.data() as any } as NFC))
			listener(datas)
		})
	}

	onDicionary(listener: (data: any) => void, ...params: QueryConstraint[]): Unsubscribe {
		return onSnapshot(query(this.colRef, ...params), snapshot => {
			const dicionary: any = {};
			snapshot.forEach(doc => dicionary[doc.id] = doc.data())
			listener(dicionary)
		})
	}

	onDatas(listener: (data: NFC[], dicionary: any) => void, ...params: QueryConstraint[]): Unsubscribe {
		return onSnapshot(query(this.colRef, ...params), snapshot => {
			const datas: NFC[] = [];
			const dicionary: any = {};
			snapshot.forEach(doc => {
				const data = { id: doc.id, ...doc.data() as any };
				datas.push(data)
				dicionary[doc.id] = data;
			})
			listener(datas, dicionary)
		})
	}

}

export default NFCEventData;
