import React from 'react';
import PageWrapper from '../../../layout/PageWrapper/PageWrapper';
import Page from '../../../layout/Page/Page';
import { demoPages } from '../../../menu';
import useDarkMode from '../../../hooks/useDarkMode';
import TaxaAdm from './taxaAdm';
import CustomOperacional from './custoOperacional';
import SubHeader, { SubHeaderLeft } from '../../../layout/SubHeader/SubHeader';
import Breadcrumb from '../../../components/bootstrap/Breadcrumb';
import CustoMaterial from './custoMaterial';

const ConfigUget = () => {
  const { darkModeStatus } = useDarkMode();

  return (
    <PageWrapper title={demoPages.configUget.text}>
      <SubHeader>
        <SubHeaderLeft>
          <Breadcrumb
            list={[
              { title: 'Config uGet', to: '/configUget' },
            ]}
          />
        </SubHeaderLeft>
      </SubHeader>
      <Page container='fluid'>
        <TaxaAdm darkModeStatus={darkModeStatus} />
        <CustomOperacional darkModeStatus={darkModeStatus} />
        <CustoMaterial darkModeStatus={darkModeStatus} />
      </Page>
    </PageWrapper >
  );
};

export default ConfigUget;
