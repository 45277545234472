import React, { useMemo } from 'react'
import Card, { CardHeader, CardLabel, CardTitle, CardBody } from '../../../../../components/bootstrap/Card';
import CardValue from '../../../../../components/MyCustom/Dashboard/CardValue';
import useDarkMode from '../../../../../hooks/useDarkMode';
import Money from '../../../../../helpers/utils/money/money';

const Totais = ({ totems, totemsRecharge }) => {
  const { darkModeStatus } = useDarkMode()

  const { totems_credit, totems_debit, totems_mealTicket, totems_pix } = useMemo(() => {
    let credit = 0;
    let debit = 0;
    let mealTicket = 0;
    let pix = 0;
    totems.forEach(totem => {
      credit += totem.credit;
      debit += totem.debit;
      mealTicket += totem.mealTicket;
      pix += totem.pix;
    })
    return {
      totems_credit: credit,
      totems_debit: debit,
      totems_mealTicket: mealTicket,
      totems_pix: pix
    };
  }, [totems])

  const { recharge_credit, recharge_debit, recharge_mealTicket, recharge_pix } = useMemo(() => {
    let credit = 0;
    let debit = 0;
    let mealTicket = 0;
    let pix = 0;
    totemsRecharge.forEach(totem => {
      credit += totem.credit;
      debit += totem.debit;
      mealTicket += totem.mealTicket;
      pix += totem.pix;
    })
    return {
      recharge_credit: credit,
      recharge_debit: debit,
      recharge_mealTicket: mealTicket,
      recharge_pix: pix
    };
  }, [totemsRecharge])

  return (
    <Card stretch>
      <CardHeader>
        <CardLabel icon='PointOfSale' iconColor={!darkModeStatus ? 'dark' : 'light'}>
          <CardTitle>TOTAIS</CardTitle>
        </CardLabel>
      </CardHeader>
      <CardBody>
        <div className='row align-items-center' >
          <div className='col-md'>
            <CardValue
              color='primary'
              label='CRÉDITO'
              value={Money.centsToMaskMoney(totems_credit + recharge_credit)}
            />
          </div>
          <div className='col-md'>
            <CardValue
              color='secondary'
              label='DÉBITO'
              value={Money.centsToMaskMoney(totems_debit + recharge_debit)}
            />
          </div>
          <div className='col-md' >
            <CardValue
              color='success'
              label='PIX'
              value={Money.centsToMaskMoney(totems_pix + recharge_pix)}
            />
          </div>
          <div className='col-md'>
            <CardValue
              color='danger'
              label='VALE REFEIÇÃO'
              value={Money.centsToMaskMoney(totems_mealTicket + recharge_mealTicket)}
            />
          </div>
          <div className='col-md'>
            <CardValue
              color='dark'
              label='TOTAL'
              value={Money.centsToMaskMoney(
                totems_credit + totems_debit + totems_mealTicket + totems_pix +
                recharge_credit + recharge_debit + recharge_mealTicket + recharge_pix
              )}
            />
          </div>
        </div>
      </CardBody>
    </Card>
  )
}

export default Totais