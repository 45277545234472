import { doc, DocumentData, DocumentReference, getDoc, getFirestore, onSnapshot, serverTimestamp, setDoc, Unsubscribe, updateDoc, WriteBatch } from "firebase/firestore";
import app from "../config";
import Setting from "../entities/setting.entity";
import StoragePipe from "../utils/storagePipe";
import EstablishmentDB from "./establishment";

class SettingDB extends StoragePipe {

  public static readonly colName = 'EventSettings'
  public readonly docRef: DocumentReference<DocumentData>;

  constructor(idEstab: string, idEvent: string) {
    if(!idEstab || !idEvent)
      throw new Error('Informe o id do estabelecimento')
    const colRefStr = `${EstablishmentDB.colName}/${idEstab}/${SettingDB.colName}`;
    super(colRefStr);
    this.docRef = doc(getFirestore(app), colRefStr, idEvent);
  }

  public async define(data: Setting): Promise<any> {
    await this.saveFile(data, 'Home');
    await this.saveFile(data, 'Banner');
    try {
      const snapshot = await getDoc(this.docRef);
      if(snapshot.exists())
        return await updateDoc(this.docRef, {
          ...data,
          updated_at: serverTimestamp()
        });
      else
        return await setDoc(this.docRef, data);
    } catch (error: any) {
      if(data.imageHome_ref) 
        await this.deleteFile(data.imageHome_ref)
      if(data.imageBanner_ref) 
        await this.deleteFile(data.imageBanner_ref)
      // throw error.message
    }
  }
  public on(listener: (data: Setting) => void): Unsubscribe {
    return onSnapshot(this.docRef, snapshot => {
      listener(snapshot.data() as Setting)
    })
  }
  public async get(): Promise<Setting | undefined> {
    const snapshot = await getDoc(this.docRef);
    return snapshot.data() as Setting;
  }
  public batchSet(batch: WriteBatch, data: any) {
    batch.set(this.docRef, data)
  }

  private async saveFile(data: any, label: string) {
    if(data['image'+label]) {
      console.log('UPLOAD IMAGE');
      const resultUpload = await this.uploadFile(new Date().getTime().toString(), data['image'+label]);
      data['image'+label+'_ref'] = resultUpload.ref.fullPath;
      data['image'+label+'_url'] = resultUpload.url;
    }
    delete data['image'+label]
  }

}

export default SettingDB;