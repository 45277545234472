import React, { useState, useEffect, useContext } from 'react';
import { useFormik } from 'formik';
import Button from '../../bootstrap/Button';
import FormGroup from '../../bootstrap/forms/FormGroup';
import Input from '../../bootstrap/forms/Input';
import Checks, { ChecksGroup } from '../../bootstrap/forms/Checks';
import Label from '../../bootstrap/forms/Label';
import Modal, { ModalBody, ModalHeader, ModalTitle } from '../../bootstrap/Modal';
import Spinner from '../../bootstrap/Spinner';
import showNotification from '../../extras/showNotification';
import Textarea from '../../bootstrap/forms/Textarea';
import Select from '../../bootstrap/forms/Select';
// Database
import ComplementDB from '../../../database/wrappers/complement';
import CategoryComplementDB from '../../../database/wrappers/categoryComplement';
import Money from '../../../helpers/utils/money/money';
import ListComponents from './ListComponents';
import { orderBy, where } from 'firebase/firestore';
import { DefaultContext } from '../../../contexts/default';
import PreAlert from '../../../helpers/utils/preAlert';
import { SELECT_TYPE } from '../../../types/categoryType';
import BtnCancel from '../../MyCustom/BtnCancel';
import BtnLoad from '../../MyCustom/BtnLoad';
import BtnConfirm from '../../MyCustom/BtnConfirm';


import ProductDB from '../../../database/wrappers/product';
import { convertTrybuIntToPTBR, TRIBUTARY_SITUATION } from '../../../types/fiscal';
import ListProductsSuggestion from '../../MyCustom/ListProductsSuggestion';
import SuggestionDB from '../../../database/wrappers/suggestion';



const validate = (values) => {
  const errors = {};

  if (!values.name) {
    errors.name = 'Este campo é necessário';
  }

  if (!values.type_select) {
    errors.type_select = 'Este campo é necessário';
  }

  if (!values.type_required) {
    errors.type_required = 'Este campo é necessário';
  }

  if (!values.max_item) {
    errors.max_item = 'Este campo é necessário';
  } else if (isNaN(Number(values.max_item)) || Number(values.max_item) < 0) {
    errors.max_item = "O valor precisa ser um número válido.";
  }


  if (!values.min_item) {
    errors.min_item = 'Este campo é necessário';
  } else if (isNaN(Number(values.min_item)) || Number(values.min_item) < 0) {
    errors.min_item = "O valor precisa ser um número válido.";
  }

  if (!values.active) {
    errors.active = 'Você precisa escolher o status do complemento';
  }

  return errors;

}

const ModalRegisterCategoryComplement = ({ open, setIsOpen, setIsClose, categoryComplementSelected }) => {

  const { establishment, onShowAlert } = useContext(DefaultContext)

  const [isLoading, setIsLoading] = useState(false);
  const [complementsResponse, setComplementsResponse] = useState([]);

  useEffect(() => {
    if (!open) return formik.resetForm();
    if (categoryComplementSelected) {
      const { name, active, description, type_select, type_required, max_item, min_item, complements } = categoryComplementSelected;

      formik.setValues({
        name,
        active: active ? 'ativo' : 'inativo',
        description,
        type_select,
        type_required,
        min_item,
        max_item,
        complements,
      });
    }
  }, [categoryComplementSelected, open])

  // Pega os complementos
  useEffect(async () => {
    if (establishment) {
      function sort(a, b) {
        if (a.name > b.name) return 1;
        if (a.name < b.name) return -1;
        return 0;
      }
      const response = await new ProductDB(establishment.id).getAll(where('active', '==', true))
      response.sort(sort);
      setComplementsResponse(response)
    }
  }, [establishment])

  const formik = useFormik({
    initialValues: {
      name: '',
      description: '',
      type_select: '',
      type_required: '',
      min_item: '',
      max_item: '',
      active: '',
      complements: []
    },
    validate,
    onSubmit: values => {
      const { name, description, type_select, type_required, max_item, min_item, active, complements } = values;

      const data = {
        name,
        active: active === 'ativo',
        description,
        type_select: Number(type_select),
        type_required: Number(type_required),
        min_item: Number(min_item),
        max_item: Number(max_item),
        complements,
      }

      const onSuccess = () => {
        onShowAlert(PreAlert.success('Registro cadastrado com sucesso'))
        setIsClose();
      }
      const onSuccessUpdate = () => {
        onShowAlert(PreAlert.success('Registro atualizado com sucesso'))
        setIsClose();
      }
      const onError = () => {
        onShowAlert(PreAlert.error('Falhou ao cadastrar o registro'))
      }

      setIsLoading(true);
      if (categoryComplementSelected) {
        new CategoryComplementDB(establishment.id)
          .update(categoryComplementSelected.id, data)
          .then(onSuccessUpdate)
          .catch(onError)
          .finally(() => setIsLoading(false))
      } else {
        new CategoryComplementDB(establishment.id)
          .create(data)
          .then(onSuccess)
          .catch(onError)
          .finally(() => setIsLoading(false))
      }
    },
  })

  return (
    <Modal
      id={'modal-register-category-complement'}
      titleId={'Cadastro de Categoria de Complemento'}
      isOpen={open}
      setIsOpen={setIsOpen}
      isStaticBackdrop={true}
      isScrollable={complementsResponse.length >= 5 ? true : false}
      isCentered={true}
      size="lg" // 'sm' || 'lg' || 'xl' 
      isAnimation={true}
      onSubmit={formik.handleSubmit}
    >
      <ModalHeader setIsOpen={setIsOpen}>
        <ModalTitle id="register-category-complement">{categoryComplementSelected ? 'Atualização de Cadastro de Categoria' : 'Cadastro de Categoria de Complemento'}</ModalTitle>
      </ModalHeader>
      <ModalBody>
        <form noValidate onSubmit={formik.handleSubmit}>
          {/* Inputs */}
          <div className="row g-4">
            {/* Nome */}
            <FormGroup id="name" label="Nome da Categoria" className='col-md-12'>
              <Input
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.name}
                isValid={formik.isValid}
                isTouched={formik.touched.name}
                invalidFeedback={formik.errors.name}
                validFeedback='Assim está bom!'
                placeholder='Eletrodomésticos, lâmpadas, etc...'
              />
            </FormGroup>

            {/* Tipo */}
            <FormGroup id="type_select" label="Seleção por item" className='col-md-4'>
              <Select
                placeholder='Selecione o tipo'
                list={[{ value: SELECT_TYPE.UNIQUE, text: 'Seleção Única' }, { value: SELECT_TYPE.MULTI, text: 'Seleção Múltipla' }]}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.type_select}
                isValid={formik.isValid}
                isTouched={formik.touched.type_select}
                invalidFeedback={formik.errors.type_select}
              />
            </FormGroup>

            {/* Tipo */}
            <FormGroup id="type_required" label="Selecione a obrigatoriedade" className='col-md-4'>
              <Select
                placeholder='Selecione a obrigatoriedade'
                list={[{ value: 0, text: 'Obrigatório' }, { value: 1, text: 'Opcional' }]}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.type_required}
                isValid={formik.isValid}
                isTouched={formik.touched.type_required}
                invalidFeedback={formik.errors.type_required}
              />
            </FormGroup>

            {/* Mínimo de itens */}
            <FormGroup id="min_item" label="Mínimo de itens" className='col-md-4'>
              <Input
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.min_item}
                isValid={formik.isValid}
                isTouched={formik.touched.min_item}
                invalidFeedback={formik.errors.min_item}
                validFeedback='Insira um número inteiro'
                placeholder='Ex.: 10'
              />
            </FormGroup>

            {/* Mínimo de itens */}
            <FormGroup id="max_item" label="Máximo de itens" className='col-md-4'>
              <Input
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.max_item}
                isValid={formik.isValid}
                isTouched={formik.touched.max_item}
                invalidFeedback={formik.errors.max_item}
                validFeedback='Insira um número inteiro'
                placeholder='Ex.: 10'
              />
            </FormGroup>

            {/* Descrição */}
            <FormGroup id="description" label="Descrição" className='col-md-8'>
              <Textarea
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.description}
                isValid={formik.isValid}
                isTouched={formik.touched.description}
                invalidFeedback={formik.errors.description}
                validFeedback='Assim está bom!'
                placeholder='
                Lorem ipsum dolor sit amet consectetur adipisicing elit. Consectetur illum hic omnis ipsam fugiat incidunt'
              />
            </FormGroup>

            {/* Status */}
            <FormGroup className='col-md-4'>
              <Label>Status</Label>
              <ChecksGroup
                isValid={formik.isValid}
                isTouched={formik.touched.active}
                invalidFeedback={formik.errors.active}
              >
                <Checks
                  type="radio"
                  id="active"
                  label="Ativo"
                  name="active"
                  value="ativo"
                  onChange={formik.handleChange}
                  checked={formik.values.active}
                  isInline
                />
                <Checks
                  type="radio"
                  id="active2"
                  label="Inativo"
                  name="active"
                  value="inativo"
                  onChange={formik.handleChange}
                  checked={formik.values.active}
                  isInline
                />
              </ChecksGroup>
            </FormGroup>
          </div>

          {complementsResponse.length > 0 && (
            <div className='row g-4 mt-1'>
              <h5>Complementos</h5>
            </div>
          )}

          <ListComponents
            complements={complementsResponse}
            complementsSelected={formik.values.complements}
            onChange={formik.handleChange}
          />

          {/* Buttons */}
          <div className="row pt-4">
            <div className="col-md-4 offset-md-8">
              <div className='d-flex justify-content-evenly'>
                <BtnCancel setIsClose={setIsClose} />
                {isLoading ? (
                  <BtnLoad />
                ) : (
                  <BtnConfirm isDisable={!formik.isValid} />
                )}
              </div>
            </div>
          </div>
        </form>
      </ModalBody>
    </Modal>
  )
}

export default ModalRegisterCategoryComplement;