export const SEX = {
  MASCULINO: 0,
  FEMININO: 1,
  INDEFINIDO: 2,
}

export const SEX_LABEL = {
  [SEX.MASCULINO]: 'Masculino',
  [SEX.FEMININO]: 'Feminino',
  [SEX.INDEFINIDO]: 'Indefinido',
}