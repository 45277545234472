import React, { useState, useEffect, useContext, memo } from 'react';
import { useFormik } from 'formik';
import FormGroup from '../../bootstrap/forms/FormGroup';
import Input from '../../bootstrap/forms/Input';
import Select from '../../bootstrap/forms/Select';
import Checks from '../../bootstrap/forms/Checks';
import Modal, { ModalBody, ModalHeader, ModalTitle } from '../../bootstrap/Modal';
import masks, { unmask } from '../../../helpers/utils/masks';
import PreAlert from '../../../helpers/utils/preAlert';
import { DefaultContext } from '../../../contexts/default';
import { Options } from '../../bootstrap/Option';
import { SEX, SEX_LABEL } from '../../../types/sex';
import EntranceDB from '../../../database/wrappers/entrance';
import BtnLoad from '../../MyCustom/BtnLoad';
import BtnCancel from '../../MyCustom/BtnCancel';
import BtnConfirm from '../../MyCustom/BtnConfirm';

const validate = (values) => {
  const errors = {};

  const consummation = unmask(values.consummation);
  if (!values.consummation) {
    errors.consummation = 'Este campo é necessário';
  } else if (isNaN(Number(consummation)) || Number(consummation) < 0) {
    errors.consummation = 'O preço precisa ser um número válido e positivo';
  }

  const value = unmask(values.value);
  if (!values.value) {
    errors.value = 'Este campo é necessário';
  } else if (isNaN(Number(value)) || Number(value) < 0) {
    errors.value = 'O preço precisa ser um número válido e positivo';
  }

  if (!values.name) {
    errors.name = 'Este campo é necessário.';
  }

  if (values.sex === '') {
    errors.sex = 'Este campo é necessário';
  }

  return errors;
}

const ModalRegisterEntrance = ({ open, setIsOpen, setIsClose, entranceSelected }) => {

  const { onShowAlert, establishment, event } = useContext(DefaultContext)

  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (!open) return formik.resetForm();
    if (entranceSelected) {
      const { name, sex, color, value, consummation, active, link } = entranceSelected;

      formik.setValues({
        name,
        sex,
        color,
        value: value.toString(),
        consummation: consummation.toString(),
        active: active,
        link: link ? link : false,
      });
    }
  }, [entranceSelected, open])

  const formik = useFormik({
    initialValues: {
      name: '',
      sex: '',
      color: '',
      value: '',
      consummation: '',
      active: true,
      link: false,
    },
    validate,
    onSubmit: values => {
      const { name, sex, color, value, consummation, active,link } = values;
      const value_unmasked = unmask(value)
      const consummation_unmasked = unmask(consummation)
      const data = {
        name,
        sex,
        color,
        value: Number(value_unmasked),
        consummation: Number(consummation_unmasked),
        active,
        link
      }

      const onSuccess = () => {
        onShowAlert(PreAlert.success('Registro cadastrado com sucesso'))
        setIsClose();
      }
      const onSuccessUpdate = () => {
        onShowAlert(PreAlert.success('Registro atualizado com sucesso'))
        setIsClose();
      }
      const onError = () => {
        onShowAlert(PreAlert.error('Falhou ao cadastrar o registro'))
      }

      setIsLoading(true);
      if (entranceSelected) {
        new EntranceDB(establishment.id, event.id)
          .update(entranceSelected.id, data)
          .then(onSuccessUpdate)
          .catch(onError)
          .finally(() => setIsLoading(false))
      } else {
        new EntranceDB(establishment.id, event.id)
          .create(data)
          .then(onSuccess)
          .catch(onError)
          .finally(() => setIsLoading(false))
      }
    },
  })


  return (
    <Modal
      id={'modal-register-ticketBoxEntrance'}
      titleId={'Cadastro e Update de Entrada de Bilheteria'}
      isOpen={open}
      setIsOpen={setIsOpen}
      isStaticBackdrop={true}
      isScrollable={false}
      isCentered={true}
      size="lg" // 'sm' || 'lg' || 'xl' 
      isAnimation={true}
      onSubmit={formik.handleSubmit}
    >
      <ModalHeader setIsOpen={setIsOpen}>
        <ModalTitle id="register-entranceSelected">{entranceSelected ? 'Atualização de Bilheteria Entradas' : 'Cadastro de Bilheteria Entradas'}</ModalTitle>
      </ModalHeader>
      <ModalBody>
        <form noValidate onSubmit={formik.handleSubmit}>

          {/* Inputs */}
          <div className="row g-4">

            {/* Nome */}
            <FormGroup id="name" label="Nome" className='col-md-7'>
              <Input
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.name}
                isValid={formik.isValid}
                isTouched={formik.touched.name}
                invalidFeedback={formik.errors.name}
                validFeedback='Assim está bom!'
                placeholder='Entrada de Bilheteria X'
              />
            </FormGroup>
            {/* Sexo */}
            <FormGroup id='sex' label='Sexo' className='col-md-4'>
              <Select
                placeholder='Selecione o sexo'
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                isValid={formik.isValid}
                isTouched={formik.touched.sex}
                value={formik.values.sex}
                invalidFeedback={formik.errors.sex}
              >
                <Options list={[
                  { value: '', text: 'Selecione o sexo' },
                  { value: SEX.MASCULINO, text: SEX_LABEL[SEX.MASCULINO] },
                  { value: SEX.FEMININO, text: SEX_LABEL[SEX.FEMININO] },
                  { value: SEX.INDEFINIDO, text: SEX_LABEL[SEX.INDEFINIDO] },
                ]}
                />
              </Select>
            </FormGroup>
            {/* COR */}
            <FormGroup id='color' label='Cor' className='col-md-1'>
              <Input
                type='color'
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.color}
                // isValid={formik.isValid}
                // isTouched={formik.touched.color}
                invalidFeedback={formik.errors.color}
                validFeedback='Assim está bom!'
                placeholder='#515151'
              />
            </FormGroup>
            {/* Valor */}
            <FormGroup id="value" label="Valor" className='col-md-4'>
              <Input
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={masks.money(formik.values.value)}
                isValid={formik.isValid}
                isTouched={formik.touched.value}
                invalidFeedback={formik.errors.value}
                validFeedback='Assim está bom!'
                placeholder='R$ 50,00'
              />
            </FormGroup>
            {/* Consumação */}
            <FormGroup id="consummation" label="Consumação" className='col-md-4'>
              <Input
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={masks.money(formik.values.consummation)}
                isValid={formik.isValid}
                isTouched={formik.touched.consummation}
                invalidFeedback={formik.errors.consummation}
                validFeedback='Assim está bom!'
                placeholder='R$ 50,00'
              />
            </FormGroup>
            {/* Status */}
            <FormGroup
              id='active'
              label='Status'
              className='col-md-2'
            >
              <Checks
                type="switch"
                id="active"
                label={formik.values.active ? 'Ativo' : 'Inativo'}
                name="active"
                onChange={formik.handleChange}
                checked={formik.values.active}
              />
            </FormGroup>


            <FormGroup
              id='link'
              label='Exclusivo Link'
              className='col-md-2'
            >
              <Checks
                type="switch"
                id="link"
                label={formik.values.link ? 'Ativo' : 'Inativo'}
                name="link"
                onChange={formik.handleChange}
                checked={formik.values.link}
              />
            </FormGroup>
          </div>
          {/* Buttons */}
          <div className="row pt-4">
            <div className="col-md-4 offset-md-8">
              <div className='d-flex justify-content-evenly'>
                <BtnCancel setIsClose={setIsClose} />
                {isLoading ? (
                  <BtnLoad />
                ) : (
                  <BtnConfirm isDisable={!formik.isValid} />
                )}
              </div>
            </div>
          </div>
        </form>
      </ModalBody>
    </Modal>
  )
}

export default memo(ModalRegisterEntrance);