import React, { memo, useContext, useMemo, useState, useEffect } from "react";
import { orderBy } from "firebase/firestore";
import { DefaultContext } from "../../../../contexts/default";
import { ACCESS_LEVEL } from "../../../../types/roles";
import Button from "../../../bootstrap/Button";
import Dropdown, { DropdownMenu, DropdownToggle } from "../../../bootstrap/Dropdown";
import FormGroup from "../../../bootstrap/forms/FormGroup";
import Label from "../../../bootstrap/forms/Label";
import Select from "../../../bootstrap/forms/Select";
import dateFormat from "dateformat";
import { useFormik } from 'formik';
import Input from "../../../bootstrap/forms/Input";
import useDarkMode from "../../../../hooks/useDarkMode";
import EventDB from "../../../../database/wrappers/event";
import SectorDB from "../../../../database/wrappers/sector";


function FiltersReports({ }) {
  const { themeStatus } = useDarkMode()
  const { accessLevel, establishments, estabSelected, setestabSelected, eventSelected, seteventSelected, setdataFilter } = useContext(DefaultContext)
  const [eventsInFilter, seteventsInFilter] = useState([]);
  const [sectors, setsectors] = useState([]);
  const [filterMenu, setFilterMenu] = useState(false);

  const formik = useFormik({
    initialValues: {
      estab: '',
      event:  '',
      sector: '',
      firstDate: dateFormat(new Date(), 'yyyy-mm-dd 00:00:00'),
      secondDate: dateFormat(new Date(), 'yyyy-mm-dd 23:59:59'),
    },
    // eslint-disable-next-line no-unused-vars
    onSubmit: (values) => {
      setFilterMenu(false);
      setestabSelected(values.estab);
      seteventSelected(values.event);
      // setsectorSelected(values.sector);
      console.log(values);
      setdataFilter({
        firstDate: values.firstDate,
        secondDate: values.secondDate
      })

    },
  });


  console.log(estabSelected, eventSelected);

  // useEffect(() => {
  //   if (estabSelected && eventSelected) {
  //     formik.setFieldValue('estab', estabSelected)
  //     formik.setFieldValue('event', eventSelected)
  //     formik.handleSubmit();
  //   }
  // }, [estabSelected, eventSelected]);

  const optionsEstablishments = useMemo(() => establishments.map(item => ({ value: item.id, text: item.name })), [establishments])

  const optionsEvents = useMemo(() => {
    const options = eventsInFilter.map(item => ({ value: item.id, text: item.name }))
    return options;
  }, [eventsInFilter])

  const optionsSectors = useMemo(() => {
    const options = sectors.map(item => ({ value: item.id, text: item.name }))
    options.unshift({
      value: '#', text: 'Todos'
    })
    return options;
  }, [sectors])


  useEffect(() => {
    if (formik.values.estab) {
      const loadEvents = () => {
        new EventDB(formik.values.estab)
          .getAll(orderBy('created_at', 'desc'))
          .then(datas => {
            seteventsInFilter(datas)
          })
          .catch(error => console.error(error))
      }
      const loadSectors = () => {
        new SectorDB(formik.values.estab)
          .getAll(orderBy('name', 'asc'))
          .then(datas => {
            setsectors(datas)
          })
          .catch(error => console.error(error))
      }

      loadSectors();
      loadEvents();
    }
  }, [formik.values.estab])

  return (
    <Dropdown isOpen={filterMenu} setIsOpen={setFilterMenu}>
      <DropdownToggle hasIcon={false}>
        <Button
          icon='Filter' color='warning'
          //isLight 
          className='text-dark'
          data-tour='filter'
        >
          Filtros
        </Button>
      </DropdownToggle>
      <DropdownMenu
        isAlignmentEnd
        size='lg'
        isCloseAfterLeave={false}
        data-tour='filter-menu'>
        <div className='container py-2'>
          <form className="row g-3" onSubmit={formik.handleSubmit}>
            {accessLevel >= ACCESS_LEVEL.MANAGER &&
              <div className='col-12'>
                <FormGroup>
                  <Label htmlFor='estab'>Estabelecimento</Label>
                  <Select
                    id='estab'
                    ariaLabel='Unidade'
                    placeholder='Unidade'
                    list={optionsEstablishments}
                    onChange={formik.handleChange}
                    value={formik.values.estab}
                  />
                </FormGroup>
              </div>}
            <div className='col-12'>
              <FormGroup>
                <Label htmlFor='event'>Evento</Label>
                <Select
                  id='event'
                  ariaLabel='Unidade'
                  placeholder='Unidade'
                  list={optionsEvents}
                  onChange={formik.handleChange}
                  value={formik.values.event}
                />
              </FormGroup>
            </div>

            {/* <div className='col-12'>
              <FormGroup>
                <Label htmlFor='sector'>Setor</Label>
                <Select
                  id='sector'
                  ariaLabel='Unidade'
                  placeholder='Unidade'
                  list={optionsSectors}
                  onChange={formik.handleChange}
                  value={formik.values.sector}
                />
              </FormGroup>
            </div> */}

            <div className='col-12'>
              <FormGroup>
                <Label htmlFor='firstDate'>Data de Inicio</Label>
                <Input
                  id='firstDate'
                  type='datetime-local'
                  ariaLabel='First Date'
                  placeholder='01/2021'
                  onChange={formik.handleChange}
                  value={formik.values.firstDate}
                />
              </FormGroup>
            </div>
            <div className='col-12'>
              <FormGroup>
                <Label htmlFor='secondDate'>Data Final</Label>
                <Input
                  id='secondDate'
                  type='datetime-local'
                  ariaLabel='Second Date'
                  placeholder='01/2022'
                  onChange={formik.handleChange}
                  value={formik.values.secondDate}
                />
              </FormGroup>
            </div>
            <div className='col-6'>
              <Button
                className='w-100 text-dark bg-white'
                style={themeStatus === 'dark' ? { color: 'white' } : {}}
                color='warning'
                isOutline
                onClick={formik.resetForm}>
                Resetar
              </Button>
            </div>
            <div className='col-6'>
              <Button style={{ color: 'black' }} color='warning' className='w-100' type='submit'>
                Filtrar
              </Button>
            </div>

          </form>
        </div>

      </DropdownMenu>
    </Dropdown>
  );
}

export default memo(FiltersReports);