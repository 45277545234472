import React, { memo, useCallback, useMemo } from 'react';
import { ROLE, ROLE_PTBR } from '../../../../types/roles';
import Select from '../../../bootstrap/forms/Select';
import { Options } from '../../../bootstrap/Option';
import Checks, { ChecksGroup } from '../../../bootstrap/forms/Checks';
import FormGroup from '../../../bootstrap/forms/FormGroup';
import Input from '../../../bootstrap/forms/Input';

const rolesOptions = Object.keys(ROLE).map(key => ({ value: ROLE[key], text: ROLE_PTBR[ROLE[key]] }))

function OperatorItem({ operator, role, onChange, onChangeRoles, onChangeSector, sector, sectorsOptions, checked }) {
  const onChangeLocalRole = useCallback((e) => {
    onChangeRoles(operator.id, e.target.value)
  }, [onChangeRoles])
  const onChangeLocalSector = useCallback((e) => {
    onChangeSector(operator.id, e.target.value)
  }, [onChangeSector])
  return (
    <div className='row g-4 py-2'>
      <div className='col-md-1'>
        <div className='h-100 d-flex align-items-center justify-content-center'>
          <ChecksGroup>
            <Checks
              type='switch'
              id='operators'
              name='operators'
              value={operator.id}
              onChange={onChange}
              checked={checked}
              isInline
            />
          </ChecksGroup>
        </div>
      </div>
      <div className='col-md-5'>
        <FormGroup>
          <Input value={operator.name + ' - ' + operator.cpfFormat} disabled readOnly />
        </FormGroup>
      </div>
      <div className='col-md-3'>
        <FormGroup id='role'>
          <Select
            id="role"
            onChange={onChangeLocalRole}
            value={role}
            placeholder='Selecione uma função '
          >
            <Options
              list={rolesOptions} />
          </Select>
        </FormGroup>
      </div>
      <div className='col-md-3'>
        <FormGroup id='sector'>
          <Select
            id="sector"
            placeholder='Selecione um setor'
            list={sectorsOptions}
            onChange={onChangeLocalSector}
            value={sector}
          />
        </FormGroup>
      </div>
    </div>
  );
}

const OperatorElement = memo(OperatorItem);

function ListOperators({ hidden, operators, operatorsSelected, rolesSelected, onChange, onChangeRoles, sectors, sectorSelected, onChangeSector }) {

  const Header = useMemo(() => (
    <div className='row g-4 border-bottom'>
      <div className='col-md-1'>
        <p>#</p>
      </div>
      <div className='col-md-5'>
        <p>Nome</p>
      </div>
      <div className='col-md-3'>
        <p>Função</p>
      </div>
      <div className='col-md-3'>
        <p>Setor</p>
      </div>
    </div>
  ), [])

  const sectorsOptions = useMemo(() => sectors.map(sector => ({ value: sector.id, text: sector.name })), [sectors])

  if (!operatorsSelected) return null;
  return (
    <div style={{ maxHeight: 300 }} className='overflow-y' hidden={hidden}>
      {Header}
      {operators.map((operator, index) => {
        return (
          <OperatorElement
            key={'ch_op' + index}
            operator={operator}
            role={rolesSelected[operator.id]}
            onChange={onChange}
            onChangeRoles={onChangeRoles}
            onChangeSector={onChangeSector}
            sector={sectorSelected[operator.id]}
            sectorsOptions={sectorsOptions}
            checked={operatorsSelected.includes(operator.id)}
          />
        );
      })}
    </div>
  );
}

export default memo(ListOperators);
