import { useContext, useEffect, useMemo, useState } from "react";
import { DefaultContext } from "../../contexts/default";
import ActivationsNFC from "../../database/entities/eventData/activationsNFC.entity";
import ActivationsNFCEventData from "../../database/wrappers/eventData/activationsNFC";
import { ACTIVATION_TYPE } from "../../types/payment";


const useTotalActivationNfc = () => {
  const { estabSelected, eventSelected,sectorSelected } = useContext(DefaultContext);
  const [activationNfc, setActivationNfc] = useState<ActivationsNFC[]>([]);
 
  useEffect(() => {
    let isMounted = true;
    const loadActivationNfc = () => {
      if (estabSelected && eventSelected && sectorSelected) {
        new ActivationsNFCEventData(estabSelected, eventSelected)
         .getBySector(sectorSelected)
          .then(anfc => {
            if (isMounted) {
              setActivationNfc(anfc)
            }
          }
          );
      }
    };
    loadActivationNfc();

    return () => {
      isMounted = false;
    }
  }, [estabSelected, eventSelected, sectorSelected]);


  const ActivationNfclCalc = useMemo(() => {
    if (!activationNfc) return {
      totalActivationNfc: 0
    };

    let totalActivationRate = 0;
    let totalActivationNfc = 0;

    activationNfc.forEach(anfc => {
      // totalActivationBalance += anfc.balance;
      if (anfc.type === ACTIVATION_TYPE.INGRESSE) {
        totalActivationNfc += anfc.balance;
        totalActivationRate += anfc.activation_rate
      }
    })

    let totalEarlyRecharge = totalActivationNfc + totalActivationRate;

    return {
      totalActivationNfc,
      totalEarlyRecharge
    };
  }, [activationNfc]);

  return { ActivationNfclCalc };
};

export default useTotalActivationNfc;
