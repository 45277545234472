import React, { memo } from "react";
import useDarkMode from "../../../../hooks/useDarkMode";
import { COLORS_DARK, COLORS_LIGHT } from "../../../../types/dashColors";
import Chart from "../../../extras/Chart";

const GraphicDash = ({ labels, series, colors, height}) => {
  const { darkModeStatus } = useDarkMode()

  return (
    <Chart
      className='pb-3'
      height={height || window.innerWidth < 550 ? 250 : 380}
      options={{
        chart: {
          type: 'pie',
          width: 380
        },
        stroke: {
          width: 0,
        },
        labels,
        dataLabels: {
          enabled: true,
        },
        legend: {
          show: false,
        },
        colors: colors ? colors
          : [
            (darkModeStatus ? COLORS_DARK : COLORS_LIGHT).PRIMARY,
            (darkModeStatus ? COLORS_DARK : COLORS_LIGHT).SECONDARY,
            (darkModeStatus ? COLORS_DARK : COLORS_LIGHT).SUCCESS,
            (darkModeStatus ? COLORS_DARK : COLORS_LIGHT).INFO,
            (darkModeStatus ? COLORS_DARK : COLORS_LIGHT).WARNING,
            (darkModeStatus ? COLORS_DARK : COLORS_LIGHT).GREEN,
            (darkModeStatus ? COLORS_DARK : COLORS_LIGHT).DANGER,
            (darkModeStatus ? COLORS_DARK : COLORS_LIGHT).PURPLE,
            (darkModeStatus ? COLORS_DARK : COLORS_LIGHT).BLUE,
            (darkModeStatus ? COLORS_DARK : COLORS_LIGHT).DARK_GREEN,
            (darkModeStatus ? COLORS_DARK : COLORS_LIGHT).PINK,
            (darkModeStatus ? COLORS_DARK : COLORS_LIGHT).LIGHT_GREEN,
            (darkModeStatus ? COLORS_DARK : COLORS_LIGHT).ROSE_DARK,
            (darkModeStatus ? COLORS_DARK : COLORS_LIGHT).ORANGE,
            (darkModeStatus ? COLORS_DARK : COLORS_LIGHT).ORANGE_GREEN,
            (darkModeStatus ? COLORS_DARK : COLORS_LIGHT).GRAY,
          ]
      }}
      series={series}
      type="pie"
    />
  );
}

export default memo(GraphicDash);