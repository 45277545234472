import { QueryConstraint, Unsubscribe } from "firebase/firestore";
import Entrance from "../entities/entrance.entity";
import FirestorePipe from "../utils/pipe";
import EstablishmentDB from "./establishment";
import EventDB from "./event";
import PromotersLink from "../entities/promotersLink.entity";

class PromotersLinkDB extends FirestorePipe {

  public static readonly colName = 'PromotersLink'

  constructor(idEstab: string, idEvent: string) {
    if (!idEstab || !idEvent)
      throw new Error('Informe o id do estabelecimento e evento')
    super(`${EstablishmentDB.colName}/${idEstab}/${EventDB.colName}/${idEvent}/${PromotersLinkDB.colName}`);
  }

  public create(data: PromotersLink): Promise<any> {
    return this._create(data);
  }
  public update(id: string, data: PromotersLink): Promise<any> {
    return this._update(id, data);
  }
  public delete(id: string): Promise<any> {
    return this._delete(id);
  }
  public getAll(...params: QueryConstraint[]): Promise<PromotersLink[]> {
    return this._getAll<PromotersLink>(...params);
  }
  public get(id: string): Promise<PromotersLink> {
    return this._get(id);
  }
  public on(listener: (data: PromotersLink[]) => void, ...params: QueryConstraint[]): Unsubscribe {
    return this._on(listener, ...params);
  }
  public onDicionary(listener: (data: any) => void, ...params: QueryConstraint[]): Unsubscribe {
    return this._onDicionary(listener, ...params);
  }

}

export default PromotersLinkDB;