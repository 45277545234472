
import Card, { CardHeader, CardLabel, CardTitle, CardBody } from '../../../../../components/bootstrap/Card';
import CardValue from '../../../../../components/MyCustom/Dashboard/CardValue';
import useDarkMode from '../../../../../hooks/useDarkMode';
import Money from '../../../../../helpers/utils/money/money';

const Totais = ({qtdProducts, refunds, total }) => {
  const { darkModeStatus } = useDarkMode()


  return (
    <Card >
      <CardHeader>
        <CardLabel icon='PointOfSale' iconColor={!darkModeStatus ? 'dark' : 'light'}>
          <CardTitle>TOTAIS</CardTitle>
        </CardLabel>
      </CardHeader>
      <CardBody>
        <div className='row align-items-center' >
          <div className='col-md'>
            <CardValue
              color='primary'
              label='QTD. PRODUTO'
              value={qtdProducts}
            />
          </div>
          <div className='col-md'>
            <CardValue
              color='secondary'
              label='ESTORNO'
              value={Money.centsToMaskMoney(refunds)}
            />
          </div>
          <div className='col-md'>
            <CardValue
              color='dark'
              label='TOTAL'
              value={Money.centsToMaskMoney(total)}
            />
          </div>
        </div>
      </CardBody>
    </Card>
  )
}

export default Totais