import React, { useCallback, useMemo } from "react";
import Money from "../../../helpers/utils/money/money";
import { DEVICE } from "../../../types/device";
import { cpfFormatMask } from "../../../utils/format/cpfFormat";
import TableReport from "../TableReport";

const ClosureCashierTable = ({ closureCashiers }) => {

  const calculateTotalCashier = useCallback((row) => {
    return row.cash + row.debit + row.credit + row.pix
  }, [])

  const calculateTotalSystem = useCallback((row) => {
    return calculateTotalCashier(row) + 
            row.courtesy +
            (row.advanceRecharge || 0)
  }, [])

  const column = useMemo(() => ([
    {
      label: 'OPERADOR',
      field: 'operator',
      format: row => row.device === DEVICE.TOTEN || row.device === DEVICE.TOTEN_RECHARGE
        ? row.name : row.operator,
    },
    {
      label: 'CPF',
      field: 'cpf',
      className: 'table-not-break-line-white-space',
      format: row => cpfFormatMask(row.cpf),
    },
    {
      label: 'TERMINAL',
      field: 'terminal',
      className: 'table-not-break-line-white-space',
    },
    {
      label: 'DINHEIRO',
      field: 'cash',
      className: 'table-not-break-line-white-space',
      format: row => Money.centsToMaskMoney(row.cash),
    },
    {
      label: 'DÉBITO',
      field: 'debit',
      className: 'table-not-break-line-white-space',
      format: row => Money.centsToMaskMoney(row.debit),
    },
    {
      label: 'CRÉDITO',
      field: 'credit',
      className: 'table-not-break-line-white-space',
      format: row => Money.centsToMaskMoney(row.credit),
    },
    {
      label: 'PIX',
      field: 'pix',
      className: 'table-not-break-line-white-space',
      format: row => Money.centsToMaskMoney(row.pix),
    },
    {
      label: 'CORTESIA',
      field: 'courtesy',
      className: 'table-not-break-line-white-space',
      format: row => Money.centsToMaskMoney(row.courtesy),
    },
    {
      label: 'RG ANTECIPADA',
      field: 'advanceRecharge',
      className: 'table-not-break-line-white-space',
      format: row => row.advanceRecharge === undefined ? '' : Money.centsToMaskMoney(row.advanceRecharge),
    },
    {
      label: 'TOTAL',
      field: 'total',
      className: 'table-not-break-line-white-space',
      format: row => Money.centsToMaskMoney(calculateTotalSystem(row)),
    }
  ]), [])

  const rowFooter = useMemo(() => {
    let cash = 0;
    let credit = 0;
    let debit = 0;
    let pix = 0;
    // let mealTicket = 0;
    let courtesy = 0;
    let advanceRecharge = 0;
    closureCashiers.forEach(row => {
      cash += row.cash;
      credit += row.credit;
      debit += row.debit;
      pix += row.pix;
      // mealTicket += row.mealTicket;
      courtesy += row.courtesy;
      if(row.advanceRecharge)
        advanceRecharge += row.advanceRecharge
    })
    const cashierTotal = cash + credit + debit + pix + advanceRecharge;
    return {
      operator: 'TOTAL:',
      cash: Money.centsToMaskMoney(cash),
      credit: Money.centsToMaskMoney(credit),
      debit: Money.centsToMaskMoney(debit),
      pix: Money.centsToMaskMoney(pix),
      courtesy: Money.centsToMaskMoney(courtesy),
      advanceRecharge: Money.centsToMaskMoney(advanceRecharge),
      cashierTotal: Money.centsToMaskMoney(cashierTotal),
      total: Money.centsToMaskMoney(cashierTotal + courtesy),
    }
  }, [closureCashiers])

  return (
    <div className='mt-4'>
       <h4 className='text-center p-4 fw-bold'>BORDERO DE CAIXAS</h4>
       <h5 className='text-center p-1 mb-5'>PRESTAÇÃO DE CONTAS DO EVENTO - FECHAMENTO DE CAIXAS</h5>
      <TableReport
        rows={closureCashiers}
        columns={column}
        keyExtractor={row => row.id}
        fontSize={12}
        rowFooter={rowFooter}
      />
    </div>
  );
}

export default ClosureCashierTable;