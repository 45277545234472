import EventGeral from "../../database/entities/event/eventGeral.entity";

export const RESOURCES_LABELS = {
  pos: 'Pos',
  pos_backup: 'Pos Reserva',
  totems: 'Totems',
  totems_recharges: 'Totems Recarga',
  coordinators: 'Coordenadores',
  technical: 'Técnicos',
  cards: 'Cartões',
  cards_adm: 'Cartões Administradores',
  bracelets: 'Pulseiras',
  bobines: 'Bobinas',
  totems_cables: 'Totems Cabos',
  totems_cuffs: 'Totems Algemas',
  totems_cover: 'Totems Cobertor',
  totems_pedestal: 'Totems Pedestal',
  extension: 'Extensão',
  kit_cordao: 'kit_cordão',
  ruler: 'Réguas',
  pouch: 'Malote',
  tablet: 'Tablets',
  tablet_power: 'Tablets Carregador',
  pos_power: 'Carregador POS',
  transport_box: 'Caixa de Transporte',
  router_wifi: 'Wi-Fi',
  processor_wifi: 'Switch',
  tripod_wifi: 'Tripé Wi-Fi',
  cable_lan: 'Cabo lan',
}

export function mountEventGeralTable(eventGeral: EventGeral) {
  const rows = Object.keys(RESOURCES_LABELS).map((key: string) => ({
    key,
    number: 0,
    equipment: RESOURCES_LABELS[key as 'pos'],
    quantity: eventGeral.resources[key as 'pos'],
    value: eventGeral.resources['value_' + key as 'pos'],
    total: eventGeral.resources[key as 'pos'] * eventGeral.resources['value_' + key as 'pos'],
  }))

  rows.sort((a, b) => {
    if(a.equipment > b.equipment) return 1;
    return -1;
  })

  rows.forEach((item, index) => {
    item.number = index + 1;
  })

  return rows
}