import React from 'react';
import { BsGlobe } from 'react-icons/bs';
import { FaMapMarkerAlt } from 'react-icons/fa';
import { FaPhoneAlt } from 'react-icons/fa';

const Footer = () => {
	return (
		<div id='closureEventFooter' className='d-flex flex-row justify-content-around bg-warning '>
			<div className='d-flex col-4 justify-content-center align-items-center'>
				<div className='d-flex flex-row justify-content-center align-items-center'>
					<BsGlobe size={20} className='mx-3' />
					<p className='my-3'>www.uget.com.br</p>
				</div>
			</div>

			<div className='d-flex col-4 justify-content-center align-items-center'>
				<div className='d-flex flex-row justify-content-center align-items-center'>
					<FaMapMarkerAlt size={20} className='mx-2' />
					<div className='col text-center'>
						<p>Rua Pitangueira, 307, Loja 01, Sala 01</p>
						<p>Mirandópolis - São Paulo</p>
						<p>SP, 04052020</p>
					</div>
				</div>
			</div>

			<div className='d-flex col-4 justify-content-center align-items-center'>
				<div className='d-flex flex-row justify-content-center align-items-center gap-2'>
					<FaPhoneAlt size={20} />
					<p className='my-3'>(11) 3846-0777</p> 
					
					<p className='my-3'> (11) 98585-4948</p>
				</div>
			</div>
		</div>
	);
};

export default Footer;
