import { useContext, useEffect, useMemo, useState } from "react";
import { DefaultContext } from "../../contexts/default";
import BalanceReversalEventData from "../../database/wrappers/eventData/balanceReversal";
import BalanceReversal from "../../database/entities/eventData/balanceReversal.entity";


const useTotalBalanceReversal = () => {
  const { estabSelected, eventSelected, dataFilter } = useContext(DefaultContext);
  const [balanceReversal, setBalanceReversal] = useState<BalanceReversal[]>([]);
  const firstDate = new Date(dataFilter.firstDate);
  const secondDate = new Date(dataFilter.secondDate);

  useEffect(() => {
    let isMounted = true;
    const loadBalanceReversal = () => {
      if (estabSelected && eventSelected) {
        new BalanceReversalEventData(estabSelected, eventSelected)
          .getAllInterval(firstDate, secondDate)
          .then(balanceReversal => {
            if (isMounted) {
              setBalanceReversal(balanceReversal)

            }
          }
          );
      }
    };
    loadBalanceReversal();
    return () => {
      isMounted = false;
    }
  }, [estabSelected, eventSelected,dataFilter]);

  const BalanceReversalCalc = useMemo(() => {
    if (!balanceReversal) return {
      totalBalanceReversal: 0
    };

    const totalBalanceReversal = balanceReversal.reduce((prev, curr) => prev + curr.value, 0);

    return {
      totalBalanceReversal
    };
  }, [balanceReversal]);

  return { BalanceReversalCalc };
};

export default useTotalBalanceReversal;
