import Page from "../../../layout/Page/Page";
import Spinner from "../../bootstrap/Spinner";

const LoadingPage = () => {
  return (
    <Page container="fluid">
      <div className='h-100 d-flex justify-content-center align-items-center'>
        <div className='d-flex flex-column justify-content-center align-items-center'>
          <Spinner size={100} />
          <h4 className='mt-4'>Carregando...</h4>
        </div>
      </div>
    </Page>
  );
}

export default LoadingPage;