export const OPERATOR_HISTORY = {
  LOGIN: 1,
  CASH_RETURN: 2,
  BLEED: 3,
  SALES: 4,
  RECHARGE: 5,
  ENTRANCE_PAY: 6,
  CHARGEBACK_PRODUCT: 7,
  CHARGEBACK_SALES: 8,
  CHARGEBACK_RECHARGE: 9,
  CHARGEBACK_ENTRANCE_PAY: 10,
  ACTIVATED_NFC: 11,
  BALANCE_REVERSAL: 12,
  CLOUSER_CASHIER: 13,
} as const

export const NFC_HISTORY = {
  ACTIVE: 1,
  RECHARGE: 2,
  CONSUME: 3,
  BALANCE_REVERSAL: 4,
  CHARGEBACK_PRODUCT: 5,
  CHARGEBACK_RECHARGE: 6,
}


export const OPERATOR_HISTORY_COLOR = {
  [OPERATOR_HISTORY.LOGIN]: 'secundary',
  [OPERATOR_HISTORY.CASH_RETURN]: 'primary',
  [OPERATOR_HISTORY.BLEED]: 'warning',
  [OPERATOR_HISTORY.SALES]: 'success',
  [OPERATOR_HISTORY.RECHARGE]: 'success',
  [OPERATOR_HISTORY.ENTRANCE_PAY]: 'success',
  [OPERATOR_HISTORY.CHARGEBACK_PRODUCT]: 'danger',
  [OPERATOR_HISTORY.CHARGEBACK_SALES]: 'danger',
  [OPERATOR_HISTORY.CHARGEBACK_RECHARGE]: 'danger',
  [OPERATOR_HISTORY.CHARGEBACK_ENTRANCE_PAY]: 'danger',
  [OPERATOR_HISTORY.ACTIVATED_NFC]: 'success',
  [OPERATOR_HISTORY.BALANCE_REVERSAL]: 'danger',
  [OPERATOR_HISTORY.CLOUSER_CASHIER]: 'secundary',
} as const

export const NFC_HISTORY_COLOR = {
  [NFC_HISTORY.ACTIVE]: 'secundary',
  [NFC_HISTORY.RECHARGE]: 'success',
  [NFC_HISTORY.CONSUME]: 'danger',
  [NFC_HISTORY.BALANCE_REVERSAL]: 'warning',
  [NFC_HISTORY.CHARGEBACK_PRODUCT]: 'warning',
  [NFC_HISTORY.CHARGEBACK_RECHARGE]: 'warning',
}