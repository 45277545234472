import React, { useCallback, useContext, useMemo, useState } from 'react';
import Page from '../../layout/Page/Page';
import PageWrapper from '../../layout/PageWrapper/PageWrapper';
import ComissaoVendedor from './comissaoVendedor';
import Eventos from './eventos';
import Movimentacao from './movimentacao';
import Resumo from './resumo';
import TaxasAdquirencia from './taxasAdquirencia';
import Totais from './totais';
import AdmContabilHeader from './header';
import api from '../../services/api';
import { DefaultContext } from '../../contexts/default';
import PreAlert from '../../helpers/utils/preAlert';
import LoadingPage from '../../components/MyCustom/LoadingPage';

const TABS = [
  {
    key: 'geral',
    label: 'Geral',
  },
  {
    key: 'tecnico',
    label: 'Técnico',
  },
  {
    key: 'extrato',
    label: 'Extrato',
  },
];

const ContEvent = () => {

  const { onShowAlert } = useContext(DefaultContext)

  const [menuSelected, setMenuSelected] = useState(TABS[0].key);
  const [loading, setloading] = useState(false);
  const [resume, setresume] = useState(null);
  const [events_result, setevents_result] = useState([]);

  function resetStates() {
    setresume(null);
    setevents_result([]);
  }

  const onSearch = useCallback(({ startDate, endDate }) => {
    const data = {
      startDate: new Date(startDate).toString(),
      endDate: new Date(endDate).toString(),
    }
    console.log(data);
    resetStates();
    setloading(true)
    api.post('routes/admContabil', data)
      .then(res => {
        setresume(res.data.resume)
        setevents_result(res.data.events_result)
      })
      .catch(error => onShowAlert(PreAlert.error(error.response?.data?.message || 'Ocorreu um erro imprevisto!')))
      .finally(() => setloading(false))
  },[])

  const eventsClosureConcluded = useMemo(() => 
    events_result.filter(e => e.completeClosure)
  ,[events_result])

  const [courtesy, recargaAntecipada] = useMemo(() => [
    eventsClosureConcluded.reduce((prev, curr) => prev + curr.faturamento.courtesy, 0),
    eventsClosureConcluded.reduce((prev, curr) => prev + curr.movimentacao.recargaAntecipada, 0),
  ],[eventsClosureConcluded])

  const sellers = useMemo(() => 
    eventsClosureConcluded.map(e => e.seller).filter(s => !!s)
  ,[eventsClosureConcluded])

  const eventsRender = useMemo(() => 
    events_result.map(row => ({
      id: row.event.id,
      startDate: new Date(row.event.date._seconds * 1000),
      endDate: new Date(row.event.endDate._seconds * 1000),
      name: row.event.name,
      closured: row.completeClosure,
      seller: row.seller?.name || '',
      totalBusy: row.faturamento.cash + row.faturamento.credit + row.faturamento.debit + row.faturamento.pix + row.faturamento.mealTicket,
      totalUget: row.resume?.total_uget_gross || 0,
      totalIntermediated: row.resume?.total_intermediated || 0,
      fullTransfer: row.resume?.full_transfer || 0,
      totalLiquid: row.resume?.total_liquid || 0,
      estabId: row.estabId,
    }))
  ,[events_result])

  return (
    <PageWrapper title='ADM'>
      <AdmContabilHeader
        TABS={TABS}
        menuSelected={menuSelected}
        setMenuSelected={setMenuSelected}
        onSearch={onSearch}
        balance={resume?.final_balance_uget || 0}
      />

      {loading ?
        <LoadingPage />
        :
        <Page container="fluid">
          <Totais events_result={events_result} />
          <Movimentacao courtesy={courtesy} recargaAntecipada={recargaAntecipada} />
          <TaxasAdquirencia eventsClosureConcluded={eventsClosureConcluded} />
          <Eventos eventsRender={eventsRender} />
          <ComissaoVendedor sellers={sellers} />
          <Resumo resume={resume} />
        </Page>
      }
    </PageWrapper >
  );
};

export default ContEvent;
