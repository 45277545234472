import React, { memo, useMemo } from "react";
import classNames from "classnames";
import useDarkMode from "../../../../hooks/useDarkMode";
import Card, { CardBody, CardHeader, CardLabel, CardTitle } from "../../../bootstrap/Card";
import { COLORS_DARK, COLORS_LIGHT } from "../../../../types/dashColors";
import Popovers from '../../../bootstrap/Popovers';
import Icon from "../../../icon/Icon";



const CardValue = ({ color, label, value, description,shadow, background}) => {
  const { darkModeStatus } = useDarkMode()

  const backgroundColor = darkModeStatus ? COLORS_DARK[color.toUpperCase()]
    : COLORS_LIGHT[color.toUpperCase()];
  // const backgroundColor = color;

  return (

    <Card
      style={{ height: '90%', backgroundColor:background?? backgroundColor }}
      borderColor="dark"
      borderSize="0"
      className={classNames(
        'transition-base rounded-2 mb-4  text-dark',
        // classNameColor,
      )}
      shadow={shadow ? shadow : 'sm'}>
      <div className='rounded h-100 mt-2 d-flex flex-column justify-content-between'>
        <div>
          <CardHeader size="sm" className='bg-transparent pt-3 px-4 pb-0' >
            <CardLabel >
              <CardTitle tag='h4' className='fs-5 fw-bold' style={{ opacity: 0.7 }}>
                {label}
              </CardTitle>
            </CardLabel>
            <Popovers
              trigger='hover'
              desc={
                <>
                  <div className='h6'> {label}</div>
                  <div >
                    <b>Descrição: {description}</b>
                  </div>
                </>
              }>
              <div className='position-relative'>
                {description &&
                  <Icon icon='Info' size='2x' />
                }
              </div>
            </Popovers>
          </CardHeader>
        </div>
        <div>
          <CardBody className='p-0'>
            <div className='d-flex align-items-center p-0'>
              <div className='flex-grow-1 ms-0'>
                <div className='fs-3 mb-0 text-center text-dark'>
                  {value}
                </div>
              </div>
            </div>
          </CardBody>
        </div>
      </div>
    </Card>

  );
}

export default memo(CardValue);