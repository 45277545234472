import React, { memo } from 'react';
import Timeline, { TimelineItem } from '../../../../components/extras/Timeline';
import { EVENT_STATUS, EVENT_STATUS_COLOR } from '../../../../types/eventStatus'

const EventItemAccordian = ({ item }) => {
  return (
    <div>
      <Timeline>
        {Object.keys(EVENT_STATUS).map(key => (
          <TimelineItem
            // className='bg-dark'
            label='12/03 18:53'
            color={EVENT_STATUS_COLOR[item.steps[key].status]}
          >
            <p className='fw-bold fs-5 mb-0'>{key} - {EVENT_STATUS[key]}</p>
            <p className='text-uppercase mb-0'>{item.steps[key].label}</p>
          </TimelineItem>
        ))}
      </Timeline>
    </div>
  );
}

export default memo(EventItemAccordian)