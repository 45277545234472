import React, { memo, useEffect, useMemo } from 'react';
import Card, { CardActions, CardBody, CardHeader, CardLabel, CardTitle } from '../../../components/bootstrap/Card';
import Money from '../../../helpers/utils/money/money';
import useDarkMode from '../../../hooks/useDarkMode';
import TableCustom from '../../../components/MyCustom/TableCustom';

const TaxaAdquirencia = ({ eventsClosureConcluded }) => {
  const { darkModeStatus } = useDarkMode();

  useEffect(() => console.log('eventsClosureConcluded', eventsClosureConcluded), [eventsClosureConcluded])

  const result = useMemo(() => {
    return {
      visa: eventsClosureConcluded.reduce((prev, curr) => prev + curr.banners_card.visa, 0),
      visa_electron: eventsClosureConcluded.reduce((prev, curr) => prev + curr.banners_card.visa_electron, 0),
      master: eventsClosureConcluded.reduce((prev, curr) => prev + curr.banners_card.master, 0),
      maestro: eventsClosureConcluded.reduce((prev, curr) => prev + curr.banners_card.maestro, 0),
      elo: eventsClosureConcluded.reduce((prev, curr) => prev + curr.banners_card.elo, 0),
      dinners: eventsClosureConcluded.reduce((prev, curr) => prev + curr.banners_card.dinners, 0),
      amex: eventsClosureConcluded.reduce((prev, curr) => prev + curr.banners_card.amex, 0),
      pix: eventsClosureConcluded.reduce((prev, curr) => prev + curr.banners_card.pix, 0),
      gross_visa: eventsClosureConcluded.reduce((prev, curr) => prev + curr.banners_card.gross_visa, 0),
      gross_visa_electron: eventsClosureConcluded.reduce((prev, curr) => prev + curr.banners_card.gross_visa_electron, 0),
      gross_master: eventsClosureConcluded.reduce((prev, curr) => prev + curr.banners_card.gross_master, 0),
      gross_maestro: eventsClosureConcluded.reduce((prev, curr) => prev + curr.banners_card.gross_maestro, 0),
      gross_elo: eventsClosureConcluded.reduce((prev, curr) => prev + curr.banners_card.gross_elo, 0),
      gross_dinners: eventsClosureConcluded.reduce((prev, curr) => prev + curr.banners_card.gross_dinners, 0),
      gross_amex: eventsClosureConcluded.reduce((prev, curr) => prev + curr.banners_card.gross_amex, 0),
      gross_pix: eventsClosureConcluded.reduce((prev, curr) => prev + curr.banners_card.gross_pix, 0),
      tax_visa: (eventsClosureConcluded.reduce((prev, curr) => prev + curr.banners_card.tax_visa, 0) / eventsClosureConcluded.length) || 0,
      tax_visa_electron: (eventsClosureConcluded.reduce((prev, curr) => prev + curr.banners_card.tax_visa_electron, 0) / eventsClosureConcluded.length) || 0,
      tax_master: (eventsClosureConcluded.reduce((prev, curr) => prev + curr.banners_card.tax_master, 0) / eventsClosureConcluded.length) || 0,
      tax_maestro: (eventsClosureConcluded.reduce((prev, curr) => prev + curr.banners_card.tax_maestro, 0) / eventsClosureConcluded.length) || 0,
      tax_elo: (eventsClosureConcluded.reduce((prev, curr) => prev + curr.banners_card.tax_elo, 0) / eventsClosureConcluded.length) || 0,
      tax_dinners: (eventsClosureConcluded.reduce((prev, curr) => prev + curr.banners_card.tax_dinners, 0) / eventsClosureConcluded.length) || 0,
      tax_amex: (eventsClosureConcluded.reduce((prev, curr) => prev + curr.banners_card.tax_amex, 0) / eventsClosureConcluded.length) || 0,
      tax_pix: (eventsClosureConcluded.reduce((prev, curr) => prev + curr.banners_card.tax_pix, 0) / eventsClosureConcluded.length) || 0,
    }
  }, [eventsClosureConcluded])

  const [totalBruto, totalTaxasValor] = useMemo(() => [
    Object.keys(result).filter(key => key.includes('gross')).reduce((prev, key) => prev + result[key], 0),
    Object.keys(result).filter(key => !key.includes('tax') && !key.includes('gross')).reduce((prev, key) => prev + result[key], 0),
  ], [result])

  const rows = [
    {
      name: 'Visa',
      amountTotal: result.gross_visa,
      tax: result.tax_visa,
      amount: result.visa
    },

    {
      name: 'Visa Electron',
      amountTotal: result.gross_visa_electron,
      tax: result.tax_visa_electron,
      amount: result.visa_electron
    },
    {
      name: 'MasterCard',
      amountTotal: result.gross_master,
      tax: result.tax_master,
      amount: result.master
    },
    {
      name: 'Maestro',
      amountTotal: result.gross_maestro,
      tax: result.tax_maestro,
      amount: result.maestro
    },
    {
      name: 'Elo',
      amountTotal: result.gross_elo,
      tax: result.tax_elo,
      amount: result.elo
    },
    {
      name: 'Dinners',
      amountTotal: result.gross_dinners,
      tax: result.tax_dinners,
      amount: result.dinners
    },
    {
      name: 'Amex',
      amountTotal: result.gross_amex,
      tax: result.tax_amex,
      amount: result.amex
    },
    {
      name: 'PIX',
      amountTotal: result.gross_pix,
      tax: result.tax_pix,
      amount: result.pix
    },
    {
      name: <span style={{fontSize: 'larger', fontWeight: 'bold'}}>TOTAL</span>,
      amountTotal: totalBruto,
      tax: 0,
      amount: totalTaxasValor
    },

  ]

  const columns = useMemo(() => ([
    {
      label: 'BANDEIRA',
      field: 'name',  
      formatExport: value => (value.name)
    },
    {
      label: 'BRUTO',
      field: 'amountTotal',
      format: row => Money.centsToMaskMoney(row.amountTotal),
      formatExport: value => Money.centsToMaskMoney(value)
    },
    {
      label: 'TAXA',
      field: 'tax',
      format: row => `${row.tax.toFixed(2)}%`
    },
    {
      label: 'VALOR',
      field: 'amount',
      format: row => Money.centsToMaskMoney(row.amount),
      formatExport: value => Money.centsToMaskMoney(value)
    },
  ]))
 
  return (
    <Card>
      <CardHeader>
        <CardLabel icon='CreditCard' iconColor={!darkModeStatus ? 'dark' : 'light'}>
          <CardTitle className='d-flex flex-row align-items-center'>
            <CardActions>
              TAXAS DE ADQUIRÊNCIA
            </CardActions>
          </CardTitle>
        </CardLabel>
      </CardHeader>
      <CardBody className='table-responsive p-5'>
        <TableCustom rows={rows} columns={columns} noShowPagination fileName={'Taxas-de-adquirência'} />
        {/* <table className='table table-borderless table-hover'>
          <thead className='border-bottom'>
            <tr>
              <th>BANDEIRA</th>
              <th>BRUTO</th>
              <th>TAXA</th>
              <th>VALOR</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>Visa</td>
              <td>{Money.centsToMaskMoney(result.gross_visa)}</td>
              <td>{result.tax_visa.toFixed(2)}%</td>
              <td>{Money.centsToMaskMoney(result.visa)}</td>
            </tr>
            <tr>
              <td>Visa Electron</td>
              <td>{Money.centsToMaskMoney(result.gross_visa_electron)}</td>
              <td>{result.tax_visa_electron.toFixed(2)}%</td>
              <td>{Money.centsToMaskMoney(result.visa_electron)}</td>
            </tr>
            <tr>
              <td>MasterCard</td>
              <td>{Money.centsToMaskMoney(result.gross_master)}</td>
              <td>{result.tax_master.toFixed(2)}%</td>
              <td>{Money.centsToMaskMoney(result.master)}</td>
            </tr>
            <tr>
              <td>Maestro</td>
              <td>{Money.centsToMaskMoney(result.gross_maestro)}</td>
              <td>{result.tax_maestro.toFixed(2)}%</td>
              <td>{Money.centsToMaskMoney(result.maestro)}</td>
            </tr>
            <tr>
              <td>Elo</td>
              <td>{Money.centsToMaskMoney(result.gross_elo)}</td>
              <td>{result.tax_elo.toFixed(2)}%</td>
              <td>{Money.centsToMaskMoney(result.elo)}</td>
            </tr>
            <tr>
              <td>Dinners</td>
              <td>{Money.centsToMaskMoney(result.gross_dinners)}</td>
              <td>{result.tax_dinners.toFixed(2)}%</td>
              <td>{Money.centsToMaskMoney(result.dinners)}</td>
            </tr>
            <tr>
              <td>Amex</td>
              <td>{Money.centsToMaskMoney(result.gross_amex)}</td>
              <td>{result.tax_amex.toFixed(2)}%</td>
              <td>{Money.centsToMaskMoney(result.amex)}</td>
            </tr>
            <tr>
              <td>PIX</td>
              <td>{Money.centsToMaskMoney(result.gross_pix)}</td>
              <td>{result.tax_pix.toFixed(2)}%</td>
              <td>{Money.centsToMaskMoney(result.pix)}</td>
            </tr>
            <tr className='table-active fw-bold'>
              <td>TOTAL</td>
              <td>{Money.centsToMaskMoney(totalBruto)}</td>
              <td></td>
              <td>{Money.centsToMaskMoney(totalTaxasValor)}</td>
            </tr>
          </tbody>
        </table> */}
      </CardBody>
    </Card>
  );
}

export default memo(TaxaAdquirencia);