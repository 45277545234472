import React, { memo, useMemo, useState } from 'react';
import useFilterData from '../../../../hooks/useFilterData';
import Checks, { ChecksGroup } from '../../../bootstrap/forms/Checks';
import FormGroup from '../../../bootstrap/forms/FormGroup';
import Input from '../../../bootstrap/forms/Input';

function SectorItem({item, onChange, checked}) {
  return(
    <div className='row g-4 py-2'>
      <div className='col-md-1 d-flex align-items-center justify-content-center'>
        <ChecksGroup>
          <Checks
            type='switch'
            id='sectors'
            name='sectors'
            value={item.id}
            onChange={onChange}
            checked={checked}
            isInline
          />
        </ChecksGroup>
      </div>
      <div className='col-md-11'>
        <FormGroup>
          <Input value={item.name} disabled readOnly />
        </FormGroup>
      </div>
    </div>
  );
}

const SectorElement = memo(SectorItem);

function ListSectors({ hidden, sectors, sectorSelected, onChange }) {
  
  const [search, setsearch] = useState('');

  const dataFilter = useFilterData(sectors, search)

  const Header = useMemo(() => (
    <div className='row'>
      <div className='col-md-1'>
        <p>#</p>
      </div>
      <div className='col-md-11'>
        <p>Nome</p>
      </div>
    </div>
  ), [])

  if(!sectorSelected) return null;
	return (
    <div 
      style={{ marginTop: 20, maxHeight: 300 }}
      className='overflow-y'
      hidden={hidden}
    >
      <div className='d-flex flex-row-reverse'>
        {/* <p className='m-0'>Operadores que irão atuar no evento e suas funções</p> */}
        <FormGroup id="search" label="" className='col-md-5'>
          <Input
            onChange={e => setsearch(e.target.value)}
            value={search}
            placeholder='Buscar'
          />
        </FormGroup>
      </div>
      { Header }

      <div 
        style={{ maxHeight: 400 }} 
        className='overflow-y'
      >
        {dataFilter.map((item, index) => {
          return (
            <SectorElement
              key={'ch_sector'+index}
              item={item}
              onChange={onChange}
              checked={sectorSelected.includes(item.id)}
            />
          );
        })}
      </div>
    </div>
	);
}

export default memo(ListSectors);
