

export const TYPE_OF_CHARGE = {
    AUTOMATIC_CHARGE: 0,
    RENT_CHARGE: 1,
    PAYMENT_RATE_CHARGE: 2,
    TOTAL_CHARGE: 3,
}
    
export const TYPE_OF_CHARGE_LABEL = {
    [TYPE_OF_CHARGE.AUTOMATIC_CHARGE]: 'Cobrança automática',
    [TYPE_OF_CHARGE.RENT_CHARGE]: 'Cobrar equipamento em comodato',
    [TYPE_OF_CHARGE.PAYMENT_RATE_CHARGE]: 'Cobrar somente taxa de pagamento',
    [TYPE_OF_CHARGE.TOTAL_CHARGE]: 'Cobrar comodato + taxa de pagamento',
}