import { useContext, useEffect, useMemo, useState } from "react";
import { DefaultContext } from "../../contexts/default";
import ClouserCashierEventData from "../../database/wrappers/eventData/clouserCashier";
import ClouserCashier from "../../database/entities/eventData/clouserCashier.entity";




const useTotalCashier = () => {
  const { estabSelected, eventSelected, sectorSelected } = useContext(DefaultContext);
  const [cashiers, setcashiers] = useState<ClouserCashier[]>([]);



  useEffect(() => {
    const loadCashier = () => {
      if (estabSelected && eventSelected && sectorSelected) {
        new ClouserCashierEventData(estabSelected, eventSelected).getBySector(sectorSelected)
          .then(cashier => {
            setcashiers(cashier)
          });
      }
    }
    loadCashier();
  }, [estabSelected, eventSelected, sectorSelected]);

  const CashiersBySector = useMemo(() => {
    if (!cashiers) return [];
    return cashiers.filter((row) => row.sectorId === sectorSelected);
  }, [cashiers, sectorSelected]);


  const CashiersBalanceReversal = useMemo(() => {
    return cashiers.reduce((prev, curr) => prev + curr.balanceReversal, 0);
  }, [cashiers])

  return { CashiersBySector, cashiers, CashiersBalanceReversal };
};

export default useTotalCashier;
