import React, { useCallback, useMemo } from "react";
import Card, { CardBody, CardHeader, CardLabel, CardTitle } from "../../../../../components/bootstrap/Card";
import TableCustom from "../../../../../components/MyCustom/TableCustom";
import Money from "../../../../../helpers/utils/money/money";
import useDarkMode from "../../../../../hooks/useDarkMode";
import dateFormat from "dateformat";

const RelatorioTotemsRecharge = ({ totemsRecharge }) => {

  const { darkModeStatus } = useDarkMode()

  const calculateTotal = useCallback(row => {
    return row.credit + row.debit + row.pix + row.mealTicket
  }, [])

  const columns = useMemo(() => ([
    {
      label: 'Data',
      field: 'opening_at',
      format: row => dateFormat(row.opening_at.toDate(), 'dd/mm HH:MM'),
      formatExport: value => dateFormat(value.toDate(), 'dd/mm HH:MM'),
    },
    {
      label: 'Totem',
      field: 'name',
    },
    {
      label: 'Terminal',
      field: 'terminal',
    },
    {
      label: 'Débito',
      field: 'debit',
      format: row => Money.centsToMaskMoney(row.debit),
      formatExport: value => Money.centsToCoin(value),
    },
    {
      label: 'Crédito',
      field: 'credit',
      format: row => Money.centsToMaskMoney(row.credit),
      formatExport: value => Money.centsToCoin(value),
    },
    {
      label: 'Vale Refeição',
      field: 'mealTicket',
      format: row => Money.centsToMaskMoney(row.mealTicket),
      formatExport: value => Money.centsToCoin(value),
    },
    {
      label: 'PIX',
      field: 'pix',
      format: row => Money.centsToMaskMoney(row.pix),
      formatExport: value => Money.centsToCoin(value),
    },
    {
      label: 'Total',
      field: '{row}',
      format: row => Money.centsToMaskMoney(calculateTotal(row)),
      formatExport: row => Money.centsToCoin(calculateTotal(row)),
    },
  ]), [])

  return (
    <Card >
      <CardHeader>
        <CardLabel icon='BackupTable' iconColor={!darkModeStatus ? 'dark' : 'light'}>
          <CardTitle>RELATÓRIO DE TOTEMS RECARGA</CardTitle>
        </CardLabel>
      </CardHeader>
      <CardBody className='table-responsive'>
        <TableCustom
          rows={totemsRecharge}
          columns={columns}
          keyExtractor={row => row.id}
        />
      </CardBody>
    </Card>
  );
}

export default RelatorioTotemsRecharge;