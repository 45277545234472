export const EVENT_STATUS = {
  1: 'ENVIO DE PORPOSTA',
  2: 'RESERVA DE MATERIAL',
  3: 'PROPOSTA APROVADA',
  4: 'ENVIO FRORMULARIO DADOS DA EMPRESA',
  5: 'ABERTURA DE OS',
  6: 'ENVIO DE CONTRATO & OS PARA ASSINATURA  ',
  7: 'CONFIRMAÇÃO DE ASSINTURA   ',
  8: 'ENVIO DE BLOQUEIO DE MATERIA',
  9: 'ENVIO DE CONTRATAÇÃO DE TECNCIOS - LOGISTICA',
  10: 'OK DO BLOQUEIO DO MATERIAL',
  11: 'OK DOS TECNCIOS - LOGISTICA ',
  12: 'IMPUT DE CARDÁPIO E OPERADORES',
  13: 'GERAR OS INTERNA',
  14: 'FECHAMENTO OS INTERNA ',
  15: 'FECHAMENTO FINANCEIRO ',
  16: 'ENVIO DO FECHAMENTO FINANCEIRO PARA ASSINATURA',
  17: 'CONFIRMAÇÃO DA ASSINATURA',
  18: 'EMISSÃO DE NOTA FISCAL ',
  19: 'PAGAMENTO ',
  20: 'ENVIO DE FORMS SATISFAÇÃO',
}

export const EVENT_STATUS_SUBLABEL = {
  1: 'VENDENDOR',
  2: 'VENDEDOR / ESTOQUE',
  3: 'CLIENTE / VENDENDOR',
  4: 'VENDEDOR / CONTRATOS',
  5: 'VENDEDOR / CONTRATOS',
  6: 'CONTRATOS / CLIENTE',
  7: 'D4SIgn / CONTRATOS',
  8: 'CONTRATOS / ESTOQUE',
  9: 'CONTRATOS / COORDENADOR',
  10: 'ESTOQUE ',
  11: 'COORDENADOR',
  12: 'COORDENADOR',
  13: 'COORDENADOR / ESTOQUE',
  14: 'ESTOQUE / COOREDENADOR',
  15: 'SISTEMA / CONTRATOS ',
  16: 'CONTRATOS / D4Sign ',
  17: 'CONTRATOS / D4Sign ',
  18: 'FINANCEIRO / SISTEMA',
  19: 'FINANCEIRO / SISTEMA',
  20: 'CONTRATOS',
}

export const EVENT_STATUS_COLOR = {
  0: 'light',
  1: 'warning',
  2: 'success',
}