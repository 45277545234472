import { useContext, useEffect, useMemo, useState } from "react";
import { DefaultContext } from "../../contexts/default";
import BalanceReversalEventData from "../../database/wrappers/eventData/balanceReversal";
import ActivationsNFCEventData from "../../database/wrappers/eventData/activationsNFC";
import { ACTIVATION_TYPE } from "../../types/payment";
import ActivationsNFC from "../../database/entities/eventData/activationsNFC.entity";



const useTotalActivationNfc = () => {
  const { estabSelected, eventSelected, sectorSelected, dataFilter } = useContext(DefaultContext);
  const [activationNfc, setActivationNfc] = useState<ActivationsNFC[]>([]);
  const firstDate = new Date(dataFilter.firstDate);
  const secondDate = new Date(dataFilter.secondDate);

  useEffect(() => {
    let isMounted = true;
    const loadActivationNfc = () => {
      if (estabSelected && eventSelected) {
        new ActivationsNFCEventData(estabSelected, eventSelected)
          .getAllInterval(firstDate, secondDate)
          .then(anfc => {
            if (isMounted) {
              setActivationNfc(anfc)
            }
          }
          );
      }
    };
    loadActivationNfc();

    return () => {
      isMounted = false;
    }
  }, [estabSelected, eventSelected,dataFilter]);


  const ActivationNfclCalc = useMemo(() => {
    if (!activationNfc) return {
      totalActivationNfc: 0
    };

    let totalActivationRate = 0;
    let totalActivationNfc = 0;

    activationNfc.forEach(anfc => {
      // totalActivationBalance += anfc.balance;
      if (anfc.type === ACTIVATION_TYPE.INGRESSE) {
        totalActivationNfc += anfc.balance;
        totalActivationRate += anfc.activation_rate
      }
    })

    let totalEarlyRecharge = totalActivationNfc + totalActivationRate;

    return {
      totalActivationNfc,
      totalEarlyRecharge


    };
  }, [activationNfc]);

  return { ActivationNfclCalc };
};

export default useTotalActivationNfc;
