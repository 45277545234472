import React, { useState, useEffect, useContext, useCallback, memo, useMemo } from 'react';
import { useFormik } from 'formik';
import Modal, { ModalBody, ModalHeader, ModalTitle } from '../../bootstrap/Modal';
import PreAlert from '../../../helpers/utils/preAlert';
import { DefaultContext } from '../../../contexts/default';
import BtnLoad from '../../MyCustom/BtnLoad';
import BtnCancel from '../../MyCustom/BtnCancel';
import BtnConfirm from '../../MyCustom/BtnConfirm';
import FormGroup from '../../bootstrap/forms/FormGroup';
import Input from '../../bootstrap/forms/Input';
import { cpfFormatMask, cpfInputMask } from '../../../utils/format/cpfFormat';
import api from '../../../services/api';
import Select from '../../bootstrap/forms/Select';
import { ROLE, ROLE_PTBR } from '../../../types/roles';
import UserOperatorsDB from '../../../database/wrappers/usersOperators';
import { limit, where } from 'firebase/firestore';
import Spinner from '../../bootstrap/Spinner';
import masks from '../../../helpers/utils/masks';
import validaCpf from '../../../helpers/utils/functions/validateCpf';
import Checks, { ChecksGroup } from '../../bootstrap/forms/Checks';
import OperatorsDB from '../../../database/wrappers/operatorsEvent';

const ModalRegisterUniqueOperator = ({ open, checked, setIsOpen, onChange, setIsClose }) => {

  const { onShowAlert, estabSelected, eventSelected, sectors, event, establishment } = useContext(DefaultContext)

  const [userOperator, setuserOperator] = useState(undefined);
  const [noSearch, setnoSearch] = useState(true);
  const [loadingGetOperator, setloadingGetOperator] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (!open) {
      setnoSearch(true)
      formik.resetForm();
    }
  }, [open])

  const validate = useCallback((values) => {
    const errors = {};

    if (!values.name) {
      errors.name = 'Este campo é necessário';
    } else if (values.name.length < 3) {
      errors.name = 'O nome precisa ter 3 caracteres ou mais';
    }

    if (!values.cpf) {
      errors.cpf = 'Este campo é necessário';
    } else if (values.cpf.length < 14) {
      errors.cpf = 'Informe o cpf completo';
    } else if (!validaCpf(values.cpf.replace(/\D/g, ""))) {
      errors.cpf = 'CPF inválido';
    }

    if (!userOperator) {
      if (!values.password) {
        errors.password = 'Este campo é necessário';
      } else if (values.password.length < 6) {
        errors.password = 'A senha precisa ter 6 caracteres ou mais';
      }
    }

    if (!values.sectorId) {
      errors.sectorId = 'Este campo é necessário';
    }
    if (!values.role) {
      errors.role = 'Este campo é necessário';
    }

    return errors;
  }, [userOperator])

  const formik = useFormik({
    initialValues: {
      name: '',
      cpf: '',
      phone: '',
      active: '',
      password: '',

      role: '',
      sectorId: '',
    },
    validate,
    onSubmit: values => {
      const { name, cpf, phone, password, role, sectorId } = values;

      const data = {
        name,
        cpf: Number(cpf.replace(/\D/g, "")),
        phone: Number(phone.replace(/\D/g, "")),
        password,
        active: true,

        role,
        sectorId,
        estabId: estabSelected,
        eventId: eventSelected
      }

      const onSuccess = () => {
        onShowAlert(PreAlert.success('Registro cadastrado com sucesso'))
        setIsClose();
      }
      const onError = (error) => {
        console.error(error?.response?.status, error?.response?.data?.message);
        onShowAlert(PreAlert.error(error?.response?.data?.message || 'Falhou ao cadastrar o registro'))
      }

      setIsLoading(true);
      api.post('routes/operatorsInEvent', data)
        .then(onSuccess)
        .catch(onError)
        .finally(() => setIsLoading(false))
      console.log(data)
    },
  })

  useEffect(() => {
    if (formik.values.cpf.length === 14 && validaCpf(formik.values.cpf.replace(/\D/g, ""))) {
      setloadingGetOperator(true)
      const cpfNumeric = Number(formik.values.cpf.replace(/\D/g, ""))
      new UserOperatorsDB().getAll(where('cpf', '==', cpfNumeric), limit(10))
        .then(datas => {
          setnoSearch(false)
          setuserOperator(datas[0])
        })
        .catch(error => {
          console.error(error);
          setuserOperator(undefined)
          onShowAlert(PreAlert.error('Ocorreu um erro imprevisto'))
        })
        .finally(() => setloadingGetOperator(false))
    }
  }, [formik.values.cpf])

  useEffect(() => {
    if (userOperator) {
      const { name, phone } = userOperator
      formik.setFieldValue('name', name)
      formik.setFieldValue('phone', masks.phone(String(phone)))
    }
  }, [userOperator])

  const onChangeCpf = useCallback((e) => {
    formik.setValues({
      ...formik.values,
      cpf: cpfInputMask(e.target.value)
    })
  }, [formik.values])

  const sectorsOptions = useMemo(() =>
    sectors.map(sector => ({ value: sector.id, text: sector.name }))
    , [sectors])

  const rolesOptions = useMemo(() =>
    Object.keys(ROLE).map(key => ({ value: ROLE[key], text: ROLE_PTBR[ROLE[key]] }))
    , [])

  return (
    <Modal
      id={'modal-register-unique-operator'}
      titleId={'Cadastro de Operador'}
      isOpen={open}
      setIsOpen={setIsOpen}
      isStaticBackdrop={true}
      isScrollable={false}
      isCentered={true}
      size="lg" // 'sm' || 'lg' || 'xl' 
      isAnimation={true}
      onSubmit={formik.handleSubmit}
    >
      <ModalHeader setIsOpen={setIsOpen}>
        <ModalTitle id="register-user">Cadastro de Operador Por Evento</ModalTitle>
      </ModalHeader>
      <ModalBody>
        <form noValidate onSubmit={formik.handleSubmit}>
          {/* Inputs */}
          <div className="row g-4">
            <div className="row col-md-12">
              {/* cpf */}
              <FormGroup id='cpf' label='CPF' className='col-md-4'>
                <Input
                  onChange={onChangeCpf}
                  onBlur={formik.handleBlur}
                  value={formik.values.cpf}
                  isValid={formik.isValid}
                  isTouched={formik.touched.cpf}
                  invalidFeedback={formik.errors.cpf}
                  disabled={loadingGetOperator}
                  // validFeedback='Assim está bom!'
                  placeholder='CPF do Usuário'
                />
              </FormGroup>

              {loadingGetOperator &&
                <div className='d-flex align-items-end col-md-8 p-2'>
                  <Spinner className='ms-1' />
                </div>}
            </div>

            {!noSearch &&
              <>
                {/* Nome */}
                <FormGroup id="name" label="Nome" className='col-md-8'>
                  <Input
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.name}
                    isValid={formik.isValid}
                    isTouched={formik.touched.name}
                    invalidFeedback={formik.errors.name}
                    validFeedback='Assim está bom!'
                    placeholder='Ex: João da Silva'
                    checked={checked}
                    isInline
                  />
                </FormGroup>

                {/* phone */}
                <FormGroup id='phone' label='Telefone' className='col-md-4'>
                  <Input
                    type="phone"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={masks.phone(formik.values.phone)}
                    isValid={formik.isValid}
                    isTouched={formik.touched.phone}
                    invalidFeedback={formik.errors.phone}
                    validFeedback='Assim está bom!'
                    placeholder='Telefone do Usuário'
                  />
                </FormGroup>

                <FormGroup id="sectorId" label="Setor" className='col-md-4'>
                  <Select
                    placeholder='Selecione um setor'
                    list={sectorsOptions}
                    onChange={formik.handleChange}
                    value={formik.values.sectorId}
                    isValid={formik.isValid}
                    isTouched={formik.touched.sectorId}
                    invalidFeedback={formik.errors.sectorId}
                  />
                </FormGroup>

                <FormGroup id="role" label="Função" className='col-md-4'>
                  <Select
                    placeholder='Selecione a função'
                    list={rolesOptions}
                    onChange={formik.handleChange}
                    value={formik.values.role}
                    isValid={formik.isValid}
                    isTouched={formik.touched.role}
                    invalidFeedback={formik.errors.role}
                  />
                </FormGroup>
                {!userOperator &&
                  <FormGroup id="password" label="Senha" className='col-md-4'>
                    <Input
                      type='password'
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.password}
                      isValid={formik.isValid}
                      isTouched={formik.touched.password}
                      invalidFeedback={formik.errors.password}
                      validFeedback='Assim está bom!'
                      placeholder='Ex: 01234'
                    />
                  </FormGroup>}
              </>
            }
          </div>

          {/* Buttons */}
          <div className="row pt-4">
            <div className="col-md-4 offset-md-8">
              <div className='d-flex justify-content-evenly'>
                <BtnCancel setIsClose={setIsClose} />
                {isLoading ? (
                  <BtnLoad />
                ) : (
                  <BtnConfirm isDisable={!formik.isValid} />
                )}
              </div>
            </div>
          </div>
        </form>
      </ModalBody>
    </Modal>
  )
}

export default memo(ModalRegisterUniqueOperator);