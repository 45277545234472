import jsPDF from 'jspdf';
import 'jspdf-autotable';

export function exportPDFFile(items, filename) {
  const doc = new jsPDF({
    orientation: 'landscape',
  });

  const tableColumn = Object.keys(items[0]);
  const tableRows = [];

  const fontSize = 10; // Tamanho da fonte ajustável

  // Função para calcular a largura da célula com base no texto
  const calculateCellWidth = (text) => {
    const cellPadding = 5; // Espaçamento interno da célula
    const cellWidth = doc.getStringUnitWidth(text) * fontSize / doc.internal.scaleFactor + cellPadding * 2;
    return cellWidth;
  };

  // Função para calcular a largura da coluna com base nos textos da coluna
  const calculateColumnWidth = (columnIndex) => {
    let maxWidth = doc.getStringUnitWidth(tableColumn[columnIndex]) * fontSize / doc.internal.scaleFactor;

    items.forEach(item => {
      const text = item[tableColumn[columnIndex]].toString();
      const textWidth = calculateCellWidth(text);
      if (textWidth > maxWidth) {
        maxWidth = textWidth;
      }
    });

    return maxWidth;
  };

  // Calcular larguras das colunas
  const columnWidths = tableColumn.map((column, index) => calculateColumnWidth(index));

  // Construir tabela
  items.forEach(item => {
    const dataRow = [];

    tableColumn.forEach((column, index) => {
      const text = item[column].toString();
      dataRow.push(text);
    });

    tableRows.push(dataRow);
  });

  // Desenhar tabela
  doc.autoTable({
    head: [tableColumn],
    body: tableRows,
    startY: 20, // Posição inicial Y da tabela
    margin: { top: 20 },
    styles: { fontSize: fontSize },
    columnStyles: columnWidths.map(width => ({ cellWidth: width })),
  });

  doc.save(filename);
}


export function exportJSONFile(datas, filename) {
  filename += ".json";
  let contentType = "application/json;charset=utf-8;";
  if (window.navigator && window.navigator.msSaveOrOpenBlob) {
    var blob = new Blob([decodeURIComponent(encodeURI(JSON.stringify(datas)))], { type: contentType });
    navigator.msSaveOrOpenBlob(blob, filename);
  } else {
    var a = document.createElement('a');
    a.download = filename;
    a.href = 'data:' + contentType + ',' + encodeURIComponent(JSON.stringify(datas));
    a.target = '_blank';
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  }
}

export function exportCSVFile(items, filename) {
  const headers = []

  Object.keys(items[0]).forEach(key => headers.push(key))

  items.unshift(headers)

  var jsonObject = JSON.stringify(items)

  var csv = convertToCSV(jsonObject)

  var exportedFilename = filename + '.csv'

  var blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' })
  if (navigator.msSaveBlob) { // IE 10+
    navigator.msSaveBlob(blob, exportedFilename)
  } else {
    var link = document.createElement('a')
    if (link.download !== undefined) { // feature detection
      // Browsers that support HTML5 download attribute
      var url = URL.createObjectURL(blob)
      link.setAttribute('href', url)
      link.setAttribute('download', exportedFilename)
      link.style.visibility = 'hidden'
      document.body.appendChild(link)
      link.click()
      document.body.removeChild(link)
    }
  }
}

function convertToCSV(objArray) {
  var array = typeof objArray != 'object' ? JSON.parse(objArray) : objArray;
  var str = '';

  for (var i = 0; i < array.length; i++) {
    var line = '';
    for (var index in array[i]) {
      if (line != '') line += ';'

      line += array[i][index];
    }

    str += line + '\r\n';
  }

  return str;
}