import React, { memo } from 'react'
import Card, { CardHeader, CardLabel, CardTitle, CardBody, CardActions } from '../../../components/bootstrap/Card';
import CardValue from '../../../components/MyCustom/Dashboard/CardValue';
import useDarkMode from '../../../hooks/useDarkMode';
import Popovers from '../../../components/bootstrap/Popovers';
import Icon from '../../../components/icon/Icon';



const DevCartoesPulseira = ({ cartaoPulseira }) => {

  const { darkModeStatus } = useDarkMode();

  const naoDevolvidos = cartaoPulseira?.habilitado - cartaoPulseira?.devolvidoTaxa - cartaoPulseira?.devolvidoProduto
  const devolvidos = cartaoPulseira?.devolvidoTaxa + cartaoPulseira?.devolvidoProduto



  return (
    <Card stretch>
      <CardHeader>
        <CardLabel icon='TableRows' iconColor={!darkModeStatus ? 'dark' : 'light'}>
          <CardTitle className='d-flex flex-row align-items-center'>
            <CardActions>
             DEVOLUÇÃO DE CARTÕES-PULSEIRA
            </CardActions>
            <Popovers trigger={'hover'} desc={<b>Quantidade de cartões / pulseiras  devolvidos e não devolvidos no evento.</b>}><Icon className='mx-3' size='2x' icon='Info' /></Popovers>
          </CardTitle>
        </CardLabel>
      </CardHeader>
      <CardBody>
        <div className='col align-items-center' >
          <div className='col-md-12'>
            <CardValue
              description='Total de cartões / pulseiras devolvidos.'
              color='secondary'
              label='DEVOLVIDOS'
              value={devolvidos || 0}
            />
          </div>
          <div className='col-md-12'>
            <CardValue
              description='Total de cartões / pulseiras devolvidos com taxa para o cliente na saída do evento.'
              color='gray'
              label='DEVOLVIDOS COM TAXA'
              value={cartaoPulseira?.devolvidoTaxa || 0}
            />
          </div>
          <div className='col-md-12'>
            <CardValue
              description='Total de cartões / pulseiras trocadas por produtos no final do evento.'
              color='green'
              label='DEVOLVIDOS COM PRODUTOS'
              value={cartaoPulseira?.devolvidoProduto || 0}
            />
          </div>
          <div className='col-md-12'>
            <CardValue
              description='Total de cartões / Pulseiras não devolvidas no final do evento.'
              color='danger'
              label='NÃO DEVOLVIDOS'
              value={naoDevolvidos || 0}
            />
          </div>
        </div>
      </CardBody>
    </Card>
  )
}

export default memo(DevCartoesPulseira);



