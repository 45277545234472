import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { createContext } from "react";
import { getAuth, onAuthStateChanged } from 'firebase/auth'
import AlertCustom from '../components/MyCustom/AlertCustom';
import Establishment from '../database/entities/establishment.entity';
import EstablishmentDB from '../database/wrappers/establishment';
import AlertCustomInterface from '../interface/alertCustom';
import DefaultContextInterface from '../interface/default.interface';
import Start from '../pages/presentation/start';
import User from '../interface/user';
import { ACCESS_LEVEL, BASIC_ROLE, ROLE, ROLE_ACCESS_LEVEL } from '../types/roles';
import QuestionModalInterface from '../interface/questionModal';
import QuestionModal from '../components/MyCustom/QuestionModal';
import { documentId, limit, orderBy, where } from 'firebase/firestore';
import jwtDecode from 'jwt-decode';
import CustomClains from '../interface/customClains';
import app from '../database/config';
import Event from '../database/entities/event.entity';
import EventDB from '../database/wrappers/event';
import convertArrayToObject from '../utils/convertArrayToObject';
import Sector from '../database/entities/sector.entity';
import SectorDB from '../database/wrappers/sector';
import { defineApiUser } from '../services/api';
import { othersMenu } from '../menu';
import dateFormat from 'dateformat';


export const DefaultContext = createContext<DefaultContextInterface>({} as any)

export default function DefaultProvider({ children }: any) {

  const [isMobile, setisMobile] = useState<boolean>(false);
  const [starting, setstarting] = useState<boolean>(true);
  const [accessLevel, setaccessLevel] = useState<number>(ACCESS_LEVEL.ADMIN);
  const [user, setuser] = useState<User | null>(null);
  // ====  ESTABLISHMENT  ===
  const [establishments, setestablishments] = useState<Establishment[]>([]);
  const [establishment, setestablishment] = useState<Establishment | null>(null)
  const [estabSelected, setestabSelected] = useState<string | null>(null);
  // ====  EVENT  ===
  const [events, setevents] = useState<Event[]>([]);
  const [event, setevent] = useState<Event | null>(null)
  const [eventDicionary, seteventDicionary] = useState<any>({})
  const [eventSelected, seteventSelected] = useState<string | null>(null);
  // ====  SECTOR  ===
  const [sectors, setsectors] = useState<Sector[]>([]);
  const [sector, setsector] = useState<Sector | null>(null)
  const [sectorSelected, setsectorSelected] = useState<string | null>(null);
  // ====
  // const [usersDicionary, setusersDicionary] = useState<any>({});
  const [dataFilter, setdataFilter] = useState({
    firstDate: dateFormat(new Date(), 'yyyy-mm-dd 00:00:00'),
    secondDate: dateFormat(new Date(), 'yyyy-mm-dd 23:59:59'),
  })


  const [showAlert, setshowAlert] = useState<any>({
    visible: false,
    title: '',
    msm: '',
    icon: '',
    color: '',
    timer: 0,
  })
  const [questionModal, setquestionModal] = useState({
    visible: false,
    loading: false,
    title: '',
    message: '',
    onCancel: () => { },
    onConfirm: () => { },
  })

  useEffect(() => {
    function handleWindowSizeChange() {
      setisMobile(window.innerWidth <= 768);
    }
    window.addEventListener('resize', handleWindowSizeChange);
    return () => {
      window.removeEventListener('resize', handleWindowSizeChange);
    }
  }, []);

  useEffect(() => {
    const onSubscribe = onAuthStateChanged(getAuth(app), user => {
      console.log(!!user ? 'Logado' : 'Não logado');
      if (user) {
        defineApiUser(user)
        user.getIdToken()
          .then(token => {
            const tokenData = jwtDecode<CustomClains>(token)
            setaccessLevel(ROLE_ACCESS_LEVEL[tokenData.role as 'admin'])
            setuser({
              uid: user.uid,
              displayName: user.displayName as string,
              email: user.email as string,
              emailVerified: user.emailVerified,
              photoURL: user.photoURL,
              phoneNumber: user.phoneNumber,
              role: tokenData.role as any,
              estabs: tokenData.estabs,
            })
            setstarting(false);
          })
      } else {
        setuser(null)
        setstarting(false);
      }
    })

    return onSubscribe;
  }, [])

  useEffect(() => {
    if (!user) return;
    if (user.role === BASIC_ROLE.ADMIN || user.role === BASIC_ROLE.SUPER_ADMIN) {
      const onSubscribe = new EstablishmentDB().on(establishments => {
        setestablishments(establishments)
        if (establishments[0]) {
          setestablishment(establishments[0])
          setestabSelected(establishments[0].id)
        }
      }, orderBy('name', 'asc'))
      return onSubscribe;
    } else {
      new EstablishmentDB()
        .getAll(where(documentId(), 'in', user.estabs))
        .then(establishments => {
          setestablishments(establishments)
          if (establishments[0]) {
            setestablishment(establishments[0])
            setestabSelected(establishments[0].id)
          }
        })
        .catch(error => console.error(error))
    }
  }, [user])

  useEffect(() => {
    if (estabSelected && user) {
      setestablishment(establishments.find(estab => estab.id === estabSelected) as Establishment)
      const onEvent = (datas: Event[]) => {
        seteventDicionary(convertArrayToObject(datas, 'id'))
        seteventSelected(datas[0]?.id)
        setevents(datas)
      }
      const onSector = (datas: Sector[]) => {
        setsectorSelected(datas[0]?.id)
        setsectors(datas)
      }

      const onSubscriber = new EventDB(estabSelected).on(onEvent, orderBy('created_at', 'desc'), limit(100))
      const onSubscriber2 = new SectorDB(estabSelected).on(onSector, orderBy('name', 'asc'))
      return () => {
        onSubscriber();
        onSubscriber2();
      }
    }
  }, [estabSelected, user])

  useEffect(() => {
    if (events) {
      setevent(events.find(item => item.id === eventSelected) as Event)
    }
  }, [eventSelected, events])

  useEffect(() => {
    if (sectors) {
      setsector(sectors.find(item => item.id === sectorSelected) as Sector)
    }
  }, [sectorSelected, sectors])

  const onShowAlert = useCallback(({ icon, title, msm, color, timer }: AlertCustomInterface) => setshowAlert({
    visible: true,
    icon, title, msm, color, timer
  }), [])

  const onShowQuestion = useCallback(({ title, message, onCancel, onConfirm }: QuestionModalInterface) => setquestionModal({
    visible: true,
    loading: false,
    title, message, onCancel, onConfirm
  } as any), [])

  const showLoadStart = useMemo(() => {
    const path = window.location.pathname.replaceAll('/', '');
    if (path === '')
      return starting
    return starting && !Object.keys(othersMenu as any).find((key: string) =>
      ((othersMenu as any)[key]).path.includes(path)
    )
  }, [starting])

  if (showLoadStart) {
    return (
      <Start />
    );
  }

  return (
    <DefaultContext.Provider value={{
      accessLevel,

      establishment,
      setestablishment,
      establishments,
      estabSelected,
      setestabSelected,

      events,
      event,
      eventDicionary,
      eventSelected,
      seteventSelected,

      sectors,
      sector,
      sectorSelected,
      setsectorSelected,

      onShowAlert,
      onShowQuestion,
      user,
      isMobile,

      dataFilter,
      setdataFilter,

    }}>
      {children}

      <AlertCustom
        show={showAlert.visible}
        icon={showAlert.icon}
        title={showAlert.title}
        msm={showAlert.msm}
        color={showAlert.color}
        timer={showAlert.timer || 10000}
        onClose={() => setshowAlert({ ...showAlert, visible: false })}
      />

      <QuestionModal
        open={questionModal.visible}
        loading={questionModal.loading}
        title={questionModal.title}
        message={questionModal.message}
        onCancel={questionModal.onCancel}
        onConfirm={questionModal.onConfirm}
        onClose={() => setquestionModal({ visible: false } as any)}
      />
    </DefaultContext.Provider>
  );
}