import React from "react";
import Money from "../../../helpers/utils/money/money";
import { calculateFee, calculateTotalFee, calculateTotalMovimentSystem } from "../../../utils/closureEvent";

const SystemFeeTable = ({ resume, eventGeral, closureResourcesEvent, faturamento, movimentacao }) => {

  if(!faturamento || !movimentacao || !eventGeral || !closureResourcesEvent) return null;

  const totalMovimentacaoSistema = calculateTotalMovimentSystem(faturamento, movimentacao);
  const totalTaxaUget = calculateTotalFee(faturamento, movimentacao, eventGeral, closureResourcesEvent);
  const taxasResultados = calculateFee(faturamento, movimentacao, eventGeral, closureResourcesEvent);

  return (
    <div className=''>
      <h4 className='text-center p-4 fw-bold'>TAXAS DO SISTEMA</h4>
      <table className='table table-striped table-fs-litle'>
        <tbody>
          <tr className="fw-bold">
            <td colSpan='3'>TOTAL MOVIMENTAÇÃO SISTEMA uGet ;-)</td>
            <td className="text-tb-align-right">{Money.centsToMaskMoney(totalMovimentacaoSistema)}</td>
          </tr>
          <tr >
            <td colSpan='3'>TOTAL INTERMEDIADO PELO SISTEMA DÉBITO + CRÉDITO + PIX</td>
            <td className="text-tb-align-right">{Money.centsToMaskMoney(resume.total_intermediated)}</td>
          </tr>
          <tr>
            <td colSpan='3'>ESTORNO PAGSEGURO</td>
            <td className="text-tb-align-right">{Money.centsToMaskMoney(closureResourcesEvent.acquirerReversal)}</td>
          </tr>
          <tr>
            <td>TOTAL INTERMEDIADO EM DÉBITO</td>
            <td>{Money.centsToMaskMoney(faturamento.debit)}</td>
            <td>TAXA %: {eventGeral.rate.debit}</td>
            <td>
              <div className="d-flex justify-content-between">
                <div>
                  VALOR REFERENTE A TAXA: 
                </div>
                <div>
                  {Money.centsToMaskMoney(taxasResultados.debit)}
                </div>
              </div>
            </td>
          </tr>
          <tr>
            <td>TOTAL INTERMEDIADO EM CRÉDITO</td>
            <td>{Money.centsToMaskMoney(faturamento.credit)}</td>
            <td>TAXA %: {eventGeral.rate.credit}</td>
            <td>
              <div className="d-flex justify-content-between">
                <div>
                  VALOR REFERENTE A TAXA: 
                </div>
                <div>
                  {Money.centsToMaskMoney(taxasResultados.credit)}
                </div>
              </div>
            </td>
          </tr>
          <tr>
            <td>TOTAL INTERMEDIADO EM PIX</td>
            <td>{Money.centsToMaskMoney(faturamento.pix)}</td>
            <td>TAXA %: {eventGeral.rate.pix}</td>
            <td>
              <div className="d-flex justify-content-between">
                <div>
                  VALOR REFERENTE A TAXA: 
                </div>
                <div>
                  {Money.centsToMaskMoney(taxasResultados.pix)}
                </div>
              </div>
            </td>
          </tr>
          <tr>
            <td>TOTAL EM DINHEIRO</td>
            <td>{Money.centsToMaskMoney(faturamento.cash)}</td>
            <td>TAXA %: {eventGeral.rate.cash}</td>
            <td>
              <div className="d-flex justify-content-between">
                <div>
                  VALOR REFERENTE A TAXA: 
                </div>
                <div>
                  {Money.centsToMaskMoney(taxasResultados.cash)}
                </div>
              </div>
            </td>
          </tr>
          <tr>
            <td>TOTAL EM CORTESIA</td>
            <td>{Money.centsToMaskMoney(faturamento.courtesy)}</td>
            <td>TAXA %: {eventGeral.rate.courtesy}</td>
            <td>
              <div className="d-flex justify-content-between">
                <div>
                  VALOR REFERENTE A TAXA: 
                </div>
                <div>
                  {Money.centsToMaskMoney(taxasResultados.courtesy)}
                </div>
              </div>
            </td>
          </tr>
          <tr>
            <td>TOTAL RECARGA ANTECIPADA</td>
            <td>{Money.centsToMaskMoney(movimentacao.recargaAntecipada)}</td>
            <td>TAXA %: {eventGeral.rate.advance_recharge}</td>
            <td>
              <div className="d-flex justify-content-between">
                <div>
                  VALOR REFERENTE A TAXA: 
                </div>
                <div>
                  {Money.centsToMaskMoney(taxasResultados.advanced_recharge)}
                </div>
              </div>
            </td>
          </tr>
          <tr>
            <td>TAXA REFERENTE A ANTECIPAÇÃO D+1</td>
            <td>{Money.centsToMaskMoney(faturamento.credit)}</td>
            <td>TAXA %: {eventGeral.rate.advance_credit}</td>
            <td>
              <div className="d-flex justify-content-between">
                <div>
                  VALOR REFERENTE A TAXA: 
                </div>
                <div>
                  {Money.centsToMaskMoney(taxasResultados.advanced_credit)}
                </div>
              </div>
            </td>
          </tr>
          <tr className="fw-bold">
            <td colSpan='3'>TOTAL TAXA  REFERENTE AO SISTEMA uGet ;-)</td>
            <td className="text-tb-align-right">{Money.centsToMaskMoney(totalTaxaUget)}</td>
          </tr>
        </tbody>
      </table>
    </div>
  );
}

export default SystemFeeTable;