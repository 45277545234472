import React, { memo } from 'react';
import { SubheaderSeparator } from '../../../../layout/SubHeader/SubHeader';
import Button from '../../../bootstrap/Button';
import Dropdown, { DropdownItem, DropdownMenu, DropdownToggle } from '../../../bootstrap/Dropdown';

function HeaderLeftDesktop({ tabs, setActiveTab, setActiveSubTab, activeTab, themeStatus }) {

	function renderTab(tab) {
		if (tab.subTabs) {
			return (
				<Dropdown className='m-0 mx-2 mt-sm-0 mt-2' setIsOpen={function noRefCheck() { }}>
					<DropdownToggle>
						<Button
							className={`m-0 ${activeTab === tab.id ? 'font-weight-bold text-dark' : ''}`}
							color={activeTab === tab.id ? 'warning' : themeStatus}>
							{tab.label}
						</Button>
					</DropdownToggle>
					<DropdownMenu>
						{Object.keys(tab.subTabs).map(key => (
							<DropdownItem
								onClick={() => {
									setActiveSubTab(tab.subTabs[key].id);
									setActiveTab(tab.id);
								}}>
								{tab.subTabs[key].label}
							</DropdownItem>
						))}
					</DropdownMenu>
				</Dropdown>
			);
		} else {
			return (
				<Button
					className={`m-0 mx-2 ${activeTab === tab.id ? 'font-weight-bold text-dark' : ''}`}

					color={activeTab === tab.id ? 'warning' : themeStatus}
					onClick={() => setActiveTab(tab.id)}
					active={activeTab === tab.id}>
					{tab.label}
				</Button>
			);
		}
	}

	return (
		<>
			<span className='h4 mb-0 fw-bold'>Relatórios</span>
			<SubheaderSeparator />


			{Object.keys(tabs).map(key => renderTab(tabs[key]))}
		</>
	);
}

export default memo(HeaderLeftDesktop);