import React, { memo, useContext } from 'react';
import YoutLogo from '../../../assets/img/yourlogo.png'
import { DefaultContext } from '../../../contexts/default';
import PreAlert from '../../../helpers/utils/preAlert';
import './avatar.css';

const LIMIT_SIZE = 10000000;

function Banner({ id, value, onChange }) {
    const photo = value ? value : YoutLogo;

    const { onShowAlert } = useContext(DefaultContext)

    const onLocalChange = e => {
        const [file] = Array.from(e.target.files)
        if (file.size > LIMIT_SIZE) {
            console.error('Imagem muito grande');
            return onShowAlert(PreAlert.error('Imagem muito grande. Limite de ' + (LIMIT_SIZE / 1000) + ' KB'))
        }
        onChange(e);
    }

    return (
        <>
            <div className="personal-image">
                <label className="label">
                    <input type="file" id={id} accept="image/*" multiple onChange={onLocalChange} />
                    <figure className="personal-figure">
                        <img src={photo} className="personal-avatar rounded" alt="" />
                        <figcaption className="personal-figcaption">
                            <img src="https://raw.githubusercontent.com/ThiagoLuizNunes/angular-boilerplate/master/src/assets/imgs/camera-white.png" />
                        </figcaption>
                    </figure>
                </label>
            </div>
        </>
    );
}

export default memo(Banner)