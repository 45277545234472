import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import PageWrapper from '../../../../layout/PageWrapper/PageWrapper';
import SubHeader, { SubHeaderLeft } from '../../../../layout/SubHeader/SubHeader';
import Page from '../../../../layout/Page/Page';
import Breadcrumb from '../../../../components/bootstrap/Breadcrumb';
import { demoPages } from '../../../../menu';
import { DefaultContext } from '../../../../contexts/default';
import Card, { CardBody, CardHeader, CardLabel, CardSubTitle, CardTitle } from '../../../../components/bootstrap/Card';
import SelectEstablishment from '../../../../components/MyCustom/SelectEstablishment';
import SelectEvent from '../../../../components/MyCustom/SelectEvent';
import FormGroup from '../../../../components/bootstrap/forms/FormGroup';
import Input from '../../../../components/bootstrap/forms/Input';
import { useFormik } from 'formik';
import Button from '../../../../components/bootstrap/Button';
import Spinner from '../../../../components/bootstrap/Spinner';
import api from '../../../../services/api';
import PreAlert from '../../../../helpers/utils/preAlert';
import showNotification from '../../../../components/extras/showNotification';
import notificationBody from '../../../../helpers/utils/functions/notificationBody';
import NfcsAccessDB from '../../../../database/wrappers/nfcsAccess';
import TableCustom from '../../../../components/MyCustom/TableCustom';

const AccessPassword = () => {

  const { estabSelected, eventSelected, onShowAlert } = useContext(DefaultContext)

  const [loading, setloading] = useState(false)
  const [nfcs, setnfcs] = useState([])

  useEffect(() => {
    if (!estabSelected || !eventSelected) return;
    const onSubscriber = new NfcsAccessDB(estabSelected, eventSelected).on(setnfcs)
    return onSubscriber
  }, [estabSelected, eventSelected])

  const validate = useCallback((values) => {
    const errors = {};

    if (!values.password) {
      errors.password = 'Este campo é necessário';
    } else if (values.password.length < 6) {
      errors.password = 'Mínimo de 6 caracteres';
    }

    return errors
  }, [])

  const formik = useFormik({
    initialValues: {
      password: ''
    },
    validate,
    onSubmit: values => {
      const { password } = values;
      const data = {
        estabId: estabSelected,
        eventId: eventSelected,
        password
      }

      const onSuccess = () => {
        onShowAlert(PreAlert.success('Senha cadastrada com sucesso'))
      }
      const onError = (error) => {
        console.error(error);
        onShowAlert(PreAlert.error('Falhou ao cadastrar a senha'))
      }

      setloading(true)
      api.post('routes/createPasswordTotem', data)
        .then(onSuccess)
        .catch(onError)
        .finally(() => setloading(false))
    }
  })

  return (
    <PageWrapper title={demoPages.event.subMenu.accessPassword.text}>
      <SubHeader>
        <SubHeaderLeft>
          <Breadcrumb
            list={[
              { title: 'Evento', to: '/sector/accessPasswrod' },
              { title: 'Senha de Acesso', to: '/sector/accessPasswrod' },
            ]}
          />
        </SubHeaderLeft>
      </SubHeader>
      <Page container='fluid'>
        <div className='row d-flex align-items-center mt-4 mx-1' >
          <SelectEstablishment />
          <SelectEvent />
        </div>

        <div className="row">
          <form className="col-md-4" noValidate onSubmit={formik.handleSubmit}>
            <Card>
              <CardHeader>
                <CardLabel icon='Tv'>
                  <CardTitle>Acesso com Senha</CardTitle>
                  <CardSubTitle>Cadastre uma senha para acessar a área administrativa das pos e totems</CardSubTitle>
                </CardLabel>
              </CardHeader>
              <CardBody>
                <FormGroup id='password' label='Senha' className='col-md-8'>
                  <Input
                    type='password'
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.password}
                    isValid={formik.isValid}
                    isTouched={formik.touched.password}
                    invalidFeedback={formik.errors.password}
                    validFeedback='Assim está bom!'
                    placeholder='Informe uma senha'
                  />
                </FormGroup>

                <div className='d-flex justify-content-end'>
                  <Button
                    type='submit'
                    color={loading ? 'success' : 'primary'}
                    icon={loading ? '' : 'Save'}
                    shadow="sm"
                    hoverShadow="sm"
                    isDisable={loading}
                  >
                    {loading ? (
                      <>
                        <Spinner
                          color="light"
                          inButton
                          isGrow
                          isSmall
                          size={10}
                          tag="span"
                        />
                        Carregando...
                      </>
                    ) : (
                      'Salvar'
                    )}
                  </Button>
                </div>
              </CardBody>
            </Card>
          </form>
          {/* <form id="reg-nfc-access" className="col-md-4" noValidate onSubmit={formikNfcRegister.handleSubmit}>
            <Card>
              <CardHeader>
                <CardLabel icon='SdCard'>
                  <CardTitle>Acesso com NFC</CardTitle>
                  <CardSubTitle>Cadastre os nfcs que terão acesso a área administrativa das pos</CardSubTitle>
                </CardLabel>
              </CardHeader>
              <CardBody>
                <div className='d-flex align-items-end' style={{ marginBottom: 20 }}>
                  <FormGroup id='nfc' label='Nfc' className='col-md-8'>
                    <Input
                      onChange={formikNfcRegister.handleChange}
                      onBlur={formikNfcRegister.handleBlur}
                      value={formikNfcRegister.values.nfc}
                      isValid={formikNfcRegister.isValid}
                      isTouched={formikNfcRegister.touched.nfc}
                      invalidFeedback={formikNfcRegister.errors.nfc}
                      validFeedback='Assim está bom!'
                      placeholder='Informe uma senha'
                    />
                  </FormGroup>

                  <div className="col-md-4">
                    <div className='d-flex flex-row-reverse'>
                      <Button
                        type='submit'
                        color={loadingNfc ? 'success' : 'primary'}
                        icon={loadingNfc ? '' : 'Add'}
                        shadow="sm"
                        hoverShadow="sm"
                        isDisable={loadingNfc}
                      >
                        {loadingNfc ? (
                          <>
                            <Spinner
                              color="light"
                              inButton
                              isGrow
                              isSmall
                              size={10}
                              tag="span"
                            />
                            Carregando...
                          </>
                        ) : (
                          'Adicionar'
                        )}
                      </Button>
                    </div>
                  </div>
                </div>

                <TableCustom
                  rows={nfcs}
                  columns={columns}
                  noShowHeader
                  keyExtractor={item => item.id}
                />
              </CardBody>
            </Card>
          </form> */}
        </div>
      </Page>
    </PageWrapper>
  );
};

export default AccessPassword;
