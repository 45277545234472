import React, { useState, useCallback, useMemo, useContext, useEffect } from 'react';
import PageWrapper from '../../layout/PageWrapper/PageWrapper';
import Page from '../../layout/Page/Page';
import useDarkMode from '../../hooks/useDarkMode';
import dateFormat from 'dateformat';
import Popovers from '../../components/bootstrap/Popovers';
import Button, { ButtonGroup } from '../../components/bootstrap/Button';
import SubHeader, { SubHeaderLeft, SubHeaderRight, SubheaderSeparator, } from '../../layout/SubHeader/SubHeader';
import EstablishmentContracts from './establishmentContract';
import EventsContracts from './eventsContracts';
import { DefaultContext } from '../../contexts/default';
import ContractDB from '../../database/wrappers/contract';
import axios from 'axios';
import { use } from 'i18next';
import { topMenu } from '../../menu';


const Contracts = () => {
    const { themeStatus, darkModeStatus, setDarkModeStatus } = useDarkMode();
    const { accessLevel, estabSelected, onShowAlert, onShowQuestion } = useContext(DefaultContext)
    const [contracts, setContracts] = useState([]);
    const [hasFetchedStatus, setHasFetchedStatus] = useState(false);
    const [statusResponse, setStatusResponse] = useState({});
    const [downloadResponse, setDownloadResponse] = useState({});

    useEffect(() => {
        if (!estabSelected) return;
        const onSubscribe = new ContractDB(estabSelected).on(setContracts);
        return onSubscribe;
    }, [estabSelected])

    // console.log('contracts: ', contracts)
    // console.log('signed: ', contracts[0]?.signed)


    useEffect(() => {
        if (contracts.length !== 0 && !hasFetchedStatus && !contracts[0].signed) {
            const statusOptions = {
                method: 'GET',
                url: `/api/v1/documents/${contracts[0].contract_ref}`,
                params: {
                    tokenAPI: process.env.REACT_APP_TOKEN_API,
                    cryptKey: process.env.REACT_APP_CRYPT_KEY
                },
                headers: { accept: 'application/json', 'content-type': 'application/json' },
            };



            try {
                const statusResponse = axios.request(statusOptions)
                    .then(function (responseStatus) {
                        // console.log('responseStatus.data: ', responseStatus.data[0]);
                        setStatusResponse(responseStatus.data[0]);
                        if (responseStatus.data[0].statusName === 'Finalizado')
                            new ContractDB(estabSelected)
                                .update(contracts[0].id, { signed: true })
                    }
                    ).catch(function (error) {
                        console.log('error: ', error);
                    }
                    );
                setHasFetchedStatus(true); // atualiza o estado para indicar que a solicitação foi feita
            } catch (error) {
                console.log('error: ', error);
            }
        }
    }, [contracts, hasFetchedStatus])


    useEffect(async () => {
        if (contracts.length !== 0) {
            if (contracts[0].infoDownload) return;
            else {
                const downloadOptions = {
                    method: 'POST',
                    url: `/api/v1/documents/${contracts[0].contract_ref}/download`,
                    params: {
                        tokenAPI: process.env.REACT_APP_TOKEN_API,
                        cryptKey: process.env.REACT_APP_CRYPT_KEY
                    },
                    headers: { accept: 'application/json', 'content-type': 'application/json' },
                };
                try {
                    const downloadResponse = axios.request(downloadOptions)
                        .then(function (responseDownload) {
                            // console.log('responseDownload.data: ', responseDownload.data);
                            setDownloadResponse(responseDownload.data);
                            new ContractDB(estabSelected)
                                .update(contracts[0].id, { infoDownload: responseDownload.data })
                                .then((response) => {
                                    // console.log('atualizou contrato com download: ', response);
                                }
                                ).catch((error) => {
                                    console.log('não atualizou contract com download: ', error);
                                }
                                )
                        }
                        ).catch(function (error) {
                            console.log('error: ', error);
                        }
                        )
                } catch (error) {
                    console.log('error: ', error);
                }
            }
        }
    }, [contracts])



    const TABS = {
        ESTABLISHMENT: 'ESTABELECIMENTO',
        EVENTS: 'EVENTOS'
    };
    const [activeTab, setActiveTab] = useState(TABS.ESTABLISHMENT);



    return (
        <PageWrapper title={topMenu.contracts.text}>
            <SubHeader>
                <SubHeaderLeft>
                    <span className='h4 mb-0 fw-bold'>Meus Contratos</span>
                    <SubheaderSeparator />
                    <ButtonGroup>
                        {Object.keys(TABS).map((key) => (
                            <Button
                                key={key}
                                color={activeTab === TABS[key] ? 'success' : themeStatus}
                                onClick={() => setActiveTab(TABS[key])}>
                                {TABS[key]}
                            </Button>
                        ))}
                    </ButtonGroup>
                </SubHeaderLeft>
            </SubHeader>
            <Page container="fluid">
                <EstablishmentContracts contract={contracts[0]} hidden={activeTab !== TABS.ESTABLISHMENT} />
                <EventsContracts hidden={activeTab !== TABS.EVENTS} />
            </Page>
        </PageWrapper>
    );
};

export default Contracts;

