import { QueryConstraint, Unsubscribe } from "firebase/firestore";
import EarlyConsummation from "../entities/event/earlyConsummation.entity";
import FirestorePipe from "../utils/pipe";
import EstablishmentDB from "./establishment";
import EventDB from "./event";

class EarlyConsummationDB extends FirestorePipe {

  public static readonly colName = 'EarlyConsummation'

  constructor(idEstab: string, idEvent: string) {
    if (!idEstab || !idEvent)
      throw new Error('Informe o id do estabelecimento e evento')
    super(`${EstablishmentDB.colName}/${idEstab}/${EventDB.colName}/${idEvent}/${EarlyConsummationDB.colName}`);
  }

  public writeBatch(datas: EarlyConsummation[]): Promise<void> {
    return this._writeBatch<EarlyConsummation>(datas)
  }
  public create(data: EarlyConsummation): Promise<any> {
    return this._create(data);
  }
  public update(id: string, data: EarlyConsummation): Promise<any> {
    return this._update(id, data);
  }
  public delete(id: string): Promise<any> {
    return this._delete(id);
  }
  public getAll(...params: QueryConstraint[]): Promise<EarlyConsummation[]> {
    return this._getAll<EarlyConsummation>(...params);
  }
  public get(id: string): Promise<EarlyConsummation> {
    return this._get(id);
  }
  public on(listener: (data: EarlyConsummation[]) => void, ...params: QueryConstraint[]): Unsubscribe {
    return this._on(listener, ...params);
  }

}

export default EarlyConsummationDB;