import app from "../config";
import { 
  getFirestore, 
  collection, 
  getDocs, 
  query, 
  QueryConstraint, 
  getDoc, 
  DocumentReference, 
  DocumentData, 
  doc, 
  Unsubscribe, 
  onSnapshot, 
  CollectionReference, 
  addDoc, 
  updateDoc, 
  deleteDoc, 
  serverTimestamp,
  setDoc,
  writeBatch,
} from "firebase/firestore"
import randomString from "../../utils/random/randomString";
import StoragePipe from "./storagePipe";

abstract class FirestorePipe extends StoragePipe {

  protected readonly colRefStr: string;
  protected readonly colRef: CollectionReference<DocumentData> ;

  constructor(colRefStr: string) {
    super(colRefStr);
    this.colRefStr = colRefStr;
    this.colRef = collection(getFirestore(app), colRefStr);
  }

  private dbDoc(id: string): DocumentReference<DocumentData> {
    return doc(getFirestore(app), this.colRefStr, id)
  }

  protected _define<T>(id: string, data: T): Promise<any> {
    return setDoc(this.dbDoc(id), data as any);
  }

  protected async _create<T>(data: T): Promise<string> {
    const result = await addDoc(this.colRef, {
      ...data,
      created_at: serverTimestamp()
    } as any);
    return result.id
  }

  protected _update<T>(id: string, data: T): Promise<void> {
    return updateDoc(this.dbDoc(id), {
      ...data,
      updated_at: serverTimestamp()
    } as any);
  }

  protected _delete(id: string): Promise<void> {
    return deleteDoc(this.dbDoc(id));
  }

  protected async _getAll<T>(...params: QueryConstraint[]): Promise<T[]> {
    const snapshot = await getDocs(query(this.colRef, ...params))
    const datas: T[] = [];
    snapshot.forEach(doc => datas.push({ id: doc.id, ...doc.data() as any }))
    return datas;
  }

  protected async _get<T>(id: string): Promise<T> {
    const docSnap = await getDoc(this.dbDoc(id))
    if(!docSnap.exists())
      throw new Error("Register not found");
    return {
      id: docSnap.id,
      ...docSnap.data(),
    } as any
  }

  protected _on<T>(listener: (data: T[]) => void, ...params: QueryConstraint[]): Unsubscribe {
    return onSnapshot(query(this.colRef, ...params), snapshot => {
      const datas: T[] = [];
      snapshot.forEach(doc => datas.push({ id: doc.id, ...doc.data() as any } as T))
      listener(datas)
    })
  }

  protected _onDicionary(listener: (data: any) => void, ...params: QueryConstraint[]): Unsubscribe {
    return onSnapshot(query(this.colRef, ...params), snapshot => {
      const dicionary: any = {};
      snapshot.forEach(doc => dicionary[doc.id] = doc.data())
      listener(dicionary)
    })
  }

  protected async _writeBatch<T>(datas: T[]): Promise<void> {
    if(datas.length > 10000)
      throw new Error('Limite de 10mil registros')
    let batch = writeBatch(getFirestore(app));
    let countItems = 0;
    for(let index in datas) {
      const item = datas[index];
      const newRef = doc(getFirestore(app), this.colRefStr, randomString(30));
      batch.set(newRef, item as any);
      countItems++;
      if(countItems === 500) {
        await batch.commit();
        countItems = 0;
        batch = writeBatch(getFirestore(app));
      } else if(index as any == datas.length-1) {
        await batch.commit();
      }
    }
  }


  abstract getAll(...params: QueryConstraint[]): Promise<any[]>;
  abstract get(id: string): Promise<any>;
  abstract on(listener: (data: any[]) => void): Unsubscribe;
  abstract create(data: any): Promise<any>;
  abstract update(id: string, data: any): Promise<any>;
  abstract delete(id: string): Promise<any>;
} 

export default FirestorePipe;