import { doc, DocumentData, DocumentReference, getDoc, getFirestore, onSnapshot, serverTimestamp, setDoc, Unsubscribe, updateDoc } from "firebase/firestore";
import app from "../config";
import EstablishmentSetting from "../entities/establishment/establishmentSetting";

class EstablishmentSettingDB {

  public static readonly colName = 'EstablishmentSetting'
  public readonly docRef: DocumentReference<DocumentData>;

  constructor(idEstab: string) {
    if(!idEstab)
      throw new Error('Informe o id do estabelecimento')
    const colRefStr = `${EstablishmentSettingDB.colName}`;
    this.docRef = doc(getFirestore(app), colRefStr, idEstab);
  }

  public async define(data: EstablishmentSetting): Promise<any> {
    const snapshot = await getDoc(this.docRef);
    if(snapshot.exists())
      return updateDoc(this.docRef, {
        ...data,
        updated_at: serverTimestamp()
      });
    else
      return setDoc(this.docRef, data);
  }

  public on(listener: (data: EstablishmentSetting) => void): Unsubscribe {
    return onSnapshot(this.docRef, snapshot => {
      listener(snapshot.data() as EstablishmentSetting)
    })
  }

  public async get(): Promise<EstablishmentSetting | undefined> {
    const snapshot = await getDoc(this.docRef);
    return snapshot.data() as EstablishmentSetting;
  }

}

export default EstablishmentSettingDB;