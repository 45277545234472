// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";

// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyAzhkbrCxyz0TpE8o3JustphZ_R4kUdkuA",
  authDomain: "pdx-uget-prod.firebaseapp.com",
  databaseURL: "https://pdx-uget-prod-default-rtdb.firebaseio.com",
  projectId: "pdx-uget-prod",
  storageBucket: "pdx-uget-prod.appspot.com",
  messagingSenderId: "232352067654",
  appId: "1:232352067654:web:385aa4ed5c2dfb3b5f1a47",
  measurementId: "G-L116MY1LKS"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

export default app;