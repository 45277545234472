import React, { useState, useEffect, useContext, memo, useCallback } from 'react';
import { useFormik } from 'formik';
import Papa from 'papaparse'
import FormGroup from '../../bootstrap/forms/FormGroup';
import Input from '../../bootstrap/forms/Input';
import Modal, { ModalBody, ModalHeader, ModalTitle } from '../../bootstrap/Modal';
import PreAlert from '../../../helpers/utils/preAlert';
import { DefaultContext } from '../../../contexts/default';
import BtnLoad from '../../MyCustom/BtnLoad';
import BtnCancel from '../../MyCustom/BtnCancel';
import BtnConfirm from '../../MyCustom/BtnConfirm';
import EarlyConsummationDB from '../../../database/wrappers/earlyConsummation';
import masks from '../../../helpers/utils/masks';
import ListCourtesyDB from '../../../database/wrappers/listCourtesy';

const validate = (values) => {
  const errors = {};

  if (!values.file) {
    errors.file = 'Este campo é necessário';
  }

  return errors;
}

const ModalRegisterCourtesyList = ({ open, setIsOpen, setIsClose }) => {

  const { onShowAlert, establishment, event } = useContext(DefaultContext)

  const [isLoading, setIsLoading] = useState(false);
  const [originalFile, setoriginalFile] = useState(null);

  useEffect(() => {
    if (!open) return formik.resetForm();
  }, [open])

  const validateList = useCallback((rows) => {
    rows.forEach((row, index) => {
      if (row.cpf === null)
        throw new Error(`Linha ${index + 1}. Campo cpf não é válido`)
      if (row.phone === null)
        throw new Error(`Linha ${index + 1}. Campo Telefone não é válido`)

    })
  }, [])

  function readCSV(csv, saveData) {
    const results = Papa.parse(csv, { header: true }) // object with { data, errors, meta }
    const rows = results.data.filter(row => row.hasOwnProperty('CPF') && row['CPF'] !== '') // array of objects


    const datas = rows.map(row => ({
      name: row['Nome'] === undefined ? null : row['Nome'],
      cpf: row['CPF'] === undefined ? null : row['CPF'].toString().replace(/\D/g, ""),
      phone: row['Telefone'] === undefined ? null : row['Telefone'].toString().replace(/\D/g, ""),
      event_ingresse: event.name,
      consummation: 0,
      value: 0,
      used: false,
      approved: true,
      promoterId: null,
      link: true,
    }))

    try {
      validateList(datas)
    } catch (error) {
      onShowAlert(PreAlert.error(error.message))
      setIsLoading(false)
      return;
    }

    saveData(datas)
  }

  function sendFile(file, saveData) {
    const reader = new FileReader();
    reader.onload = function (e) {
      // Use reader.result
      readCSV(reader.result, saveData)
    }
    reader.readAsText(file);
  }

  const handleFiles = e => {
    const [file] = Array.from(e.target.files)
    setoriginalFile(file)
    formik.handleChange(e)
  }

  const formik = useFormik({
    initialValues: {
      file: '',
    },
    validate,
    onSubmit: values => {

      const onSuccess = () => {
        onShowAlert(PreAlert.success('Lista importada com sucesso'))
        setIsClose();
      }
      const onError = (error) => {
        console.error(error);
        onShowAlert(PreAlert.error('Falhou ao importar a lista'))
      }

      setIsLoading(true);
      try {
        sendFile(originalFile, datas => {
          const promises = datas.map(data => {
            const dbInstance = new ListCourtesyDB(establishment.id, event.id);
            return Promise.all([
              dbInstance.getByPhone(data.phone),
              dbInstance.getByCPF(data.cpf.replace(/\D/g, ""))
            ]).then(([phoneExists, cpfExists]) => {
              if (!phoneExists && !cpfExists) {
                return data;
              } else {
                return null;
              }
            });
          });

          Promise.all(promises)
            .then(filteredDatas => {
              const validDatas = filteredDatas.filter(data => data !== null);
              console.log(filteredDatas);
              if (validDatas.length > 0) {
                new ListCourtesyDB(establishment.id, event.id)
                  .writeBatch(validDatas)
                  .then(onSuccess)
                  .catch(onError)
                  .finally(() => setIsLoading(false));
              } else {
                console.log("Todos os dados já estão na lista de cortesia.");
                onSuccess();
                setIsLoading(false);
              }
            })
            .catch(error => {
              console.error("Erro ao verificar os dados na lista de cortesia:", error);
              onError();
              setIsLoading(false);
            });
        });
      } catch (error) {
        console.error("Erro ao enviar o arquivo:", error);
        onError();
        setIsLoading(false);
      }

    },
  })


  return (
    <Modal
      id={'modal-register-EarlyConsummation'}
      titleId={'Cadastro e Update de Entrada Antecipada'}
      isOpen={open}
      setIsOpen={setIsOpen}
      isStaticBackdrop={true}
      isScrollable={false}
      isCentered={true}
      size="lg" // 'sm' || 'lg' || 'xl' 
      isAnimation={true}
      onSubmit={formik.handleSubmit}
    >
      <ModalHeader setIsOpen={setIsOpen}>
        <ModalTitle id="register-EarlyConsummationSelected">Registrar Cortesia</ModalTitle>
      </ModalHeader>
      <ModalBody>
        <form noValidate onSubmit={formik.handleSubmit}>
          {/* Inputs */}
          <div className="row g-4">

            <FormGroup id="file" label="Nome" className='col-md-6'>
              <Input
                type='file'
                accept={'.csv'}
                onChange={handleFiles}
                onBlur={formik.handleBlur}
                value={formik.values.file}
                isValid={formik.isValid}
                isTouched={formik.touched.file}
                invalidFeedback={formik.errors.file}
                validFeedback='Assim está bom!'
                placeholder='Entrada de Bilheteria X'
              />
            </FormGroup>
          </div>
          {/* Buttons */}
          <div className="row pt-4">
            <div className="col-md-4 offset-md-8">
              <div className='d-flex justify-content-evenly'>
                <BtnCancel setIsClose={setIsClose} />
                {isLoading ? (
                  <BtnLoad />
                ) : (
                  <BtnConfirm isDisable={!formik.isValid} />
                )}
              </div>
            </div>
          </div>
        </form>
      </ModalBody>
    </Modal>
  )
}

export default memo(ModalRegisterCourtesyList);