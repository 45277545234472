import React, { useState, useEffect, useMemo, useContext, useCallback } from 'react';
import PageWrapper from '../../../../layout/PageWrapper/PageWrapper';
import SubHeader, { SubHeaderLeft, SubHeaderRight } from '../../../../layout/SubHeader/SubHeader';
import Page from '../../../../layout/Page/Page';
import Breadcrumb from '../../../../components/bootstrap/Breadcrumb';
import { demoPages } from '../../../../menu';
import Card, { CardBody, CardHeader, CardLabel, CardTitle } from '../../../../components/bootstrap/Card';
import Button from '../../../../components/bootstrap/Button';
import { DefaultContext } from '../../../../contexts/default';
import CategoryComplementDB from '../../../../database/wrappers/categoryComplement';
import SelectEstablishment from '../../../../components/MyCustom/SelectEstablishment';
import TableCustom from '../../../../components/MyCustom/TableCustom';
import ModalRegisterCategoryComplement from '../../../../components/modals/CategoryComplement/ModalRegister';
import showNotification from '../../../../components/extras/showNotification';
import notificationBody from '../../../../helpers/utils/functions/notificationBody';
import { orderBy } from 'firebase/firestore';
import { SELECT_TYPE } from '../../../../types/categoryType';
import { ACCESS_LEVEL } from '../../../../types/roles';
import useDarkMode from '../../../../hooks/useDarkMode';

const CategoryComplement = () => {
  const { darkModeStatus } = useDarkMode();

  const { accessLevel, estabSelected, onShowAlert, onShowQuestion } = useContext(DefaultContext);

  const [openModalRegister, setOpenModalRegister] = useState(false);
  const [categoryComplements, setCategoryComplements] = useState([]);
  const [categoryComplementSelected, setCategoryComplementSelected] = useState({});

  useEffect(() => {
    if (!estabSelected) return;
    const onSubscribe = new CategoryComplementDB(estabSelected).on(setCategoryComplements, orderBy('name', 'asc'));
    return onSubscribe;
  }, [estabSelected])

  const handleOpenModalRegister = useCallback(() => {
    setCategoryComplementSelected(null)
    setOpenModalRegister(true);
  }, [])

  const handleCloseModalRegister = useCallback(() => {
    setOpenModalRegister(false);
  }, [])

  const handleOpenModalEdit = useCallback((item) => {
    setCategoryComplementSelected(item);
    setOpenModalRegister(true)
  }, [])

  const handleOpenModalDelete = useCallback((id) => {
    onShowQuestion({
      title: 'Exclusão de Categoria Complemento',
      message: 'Você está excluindo uma categoria complemento. Está ação é irreversível. Deseja continuar?',
      onConfirm: () => {
        new CategoryComplementDB(estabSelected)
          .delete(id)
          .then(res => showNotification('', notificationBody('Registro deletado com sucesso'), 'success'))
          .catch(err => showNotification('', notificationBody('Falhou ao deletar o registro'), 'danger'))
      }
    })
  }, [estabSelected])

  const handleChangeStatus = useCallback((id, active) => {
    new CategoryComplementDB(estabSelected)
      .update(id, {
        active: !active
      })
      .catch(error => onShowAlert({
        title: 'ERRO!',
        msm: 'Não foi possível atualizar a categoria',
        icon: 'Error',
        color: 'danger'
      }))
  }, [estabSelected])

  const columns = useMemo(() => ([
    {
      label: 'Nome',
      field: 'name',
    },
    {
      label: 'Descrição',
      field: 'description',
    },
    {
      label: 'Tipo',
      field: 'type_select',
      format: row => (
        <span>{row.type_select === SELECT_TYPE.UNIQUE ? 'Seleção Única' : 'Seleção Múltipla'}</span>
      ),
      formatExport: value => value === SELECT_TYPE.UNIQUE ? 'Seleção Única' : 'Seleção Múltipla',
    },
    {
      label: 'Obrigatoriedade',
      field: 'type_required',
      format: row => row.type_required === 0 ? 'Obrigatório' : 'Opcional',
      formatExport: value => value === 0 ? 'Obrigatório' : 'Opcional',
    },
    {
      label: 'Mínimo de itens',
      field: 'min_item',
    },
    {
      label: 'Máximo de itens',
      field: 'max_item',
    },
    {
      label: 'Status',
      field: 'active',
      format: row => (
        <Button
          isLink
          color={row.active ? 'success' : 'danger'}
          icon='Circle'
          onClick={() => handleChangeStatus(row.id, row.active)}
          className='text-nowrap'>
          {row.active ? 'Ativo' : 'Inativo'}
        </Button>
      )
    },
    {
      label: 'Ações',
      field: 'actions',
      format: row => (
        <div className='w-75 d-flex justify-content-between'>
          <Button
            color={darkModeStatus ? 'light' : 'dark'}
            icon="edit"
            shadow="sm"
            hoverShadow="sm"
            size="sm"
            onClick={() => handleOpenModalEdit(row)}
          />
          <Button
            color="danger"
            icon="Trash"
            shadow="sm"
            hoverShadow="sm"
            size="sm"
            onClick={() => handleOpenModalDelete(row.id)}
          />
        </div>
      ),
      noExport: true
    },
  ]), [darkModeStatus, handleChangeStatus, handleOpenModalEdit, handleOpenModalDelete])
  return (
    <>
      <PageWrapper title={demoPages.pdvMenu.subMenu.categoryComplement.text}>
        <SubHeader>
          <SubHeaderLeft>
            <Breadcrumb
              list={[
                { title: 'Cadastros', to: '/pdv/categoryComplement' },
                {
                  title: 'Categoria de Complemento',
                  to: '/pdv/categoryComplement',
                },
              ]}
            />
          </SubHeaderLeft>
          <SubHeaderRight>
            {accessLevel >= ACCESS_LEVEL.MANAGER &&
              <Button
                color='primary'
                icon='plus'
                shadow="sm"
                hoverShadow="sm"
                onClick={handleOpenModalRegister}
              >
                Cadastrar
              </Button>}
          </SubHeaderRight>
        </SubHeader>
        <Page container="fluid">
          <Card>
            <CardHeader>
              <CardLabel icon='Bookmarks' iconColor={darkModeStatus ? 'light' : 'dark'}>
                <CardTitle>Categoria de Complemento</CardTitle>
              </CardLabel>
            </CardHeader>
            <CardBody className='table-responsive'>
              <SelectEstablishment />
              <TableCustom
                titlePdf='Categoria de Complemento'
                icon="Bookmarks"
                columns={columns}
                rows={categoryComplements}
                fileName="Categoria-de-complemento"
              />

            </CardBody>
          </Card>
        </Page>
      </PageWrapper>

      <ModalRegisterCategoryComplement
        open={openModalRegister}
        setIsOpen={setOpenModalRegister}
        setIsClose={handleCloseModalRegister}
        categoryComplementSelected={categoryComplementSelected}
      />
    </>
  );
};

export default CategoryComplement;
