import useProducts from "../../../hooks/useProducts"


export default function ProductsAutoFill({ searchQuery, onChosenProduct }) {
  const { products } = useProducts(searchQuery)

  const handleChosenProduct = (product) => {
    onChosenProduct(product)
    console.log(process.env.REACT_APP_API_PRODUCTS_URL)
    console.log(process.env.REACT_APP_API_PRODUCTS_TOKEN)
  }

  if (products.length <= 1 || !searchQuery) {
    return null
  }

  return (
    <div className="shadow-lg" style={{ position: "absolute", overflow: "hidden", borderRadius: "0.5rem", width: "468.79px", marginTop: "105px" }}>
      <ul className="list-group">
        {products.map((product) => (
          <li
            key={product.id}
            className="list-group-item list-group-item-action cursor-pointer fw-bold"
            style={{ opacity: 100, zIndex: 1 }}
            onClick={() => handleChosenProduct(product)}
          >
            <span>{product.name}</span>
          </li>
        ))}
      </ul>
    </div>
  );
}
