import React, { useState, useContext, useEffect, useMemo, useCallback } from 'react';
import { useFormik } from 'formik';
import FormGroup from '../../bootstrap/forms/FormGroup';
import Input from '../../bootstrap/forms/Input';
import Checks, { ChecksGroup } from '../../bootstrap/forms/Checks';
import Label from '../../bootstrap/forms/Label';
import Modal, { ModalBody, ModalHeader, ModalTitle } from '../../bootstrap/Modal';
import masks from '../../../helpers/utils/masks';
import { DefaultContext } from '../../../contexts/default';

import api from '../../../services/api';
import PreAlert from '../../../helpers/utils/preAlert';
import BtnLoad from '../../MyCustom/BtnLoad';
import BtnCancel from '../../MyCustom/BtnCancel';
import BtnConfirm from '../../MyCustom/BtnConfirm';
import { cpfFormatMask } from '../../../utils/format/cpfFormat';
import validaCpf from '../../../helpers/utils/functions/validateCpf';

const validate = (values) => {
  const errors = {};

  if (!values.name) {
    errors.name = 'Este campo é necessário';
  } else if (values.name.length < 3) {
    errors.name = 'O nome precisa ter 3 caracteres ou mais';
  }

  if (!values.password) {
    errors.password = 'Este campo é necessário';
  } else if (values.password.length < 6) {
    errors.password = 'A senha precisa ter 6 caracteres ou mais';
  }

  if (!values.cpf) {
    errors.cpf = 'Este campo é necessário';
  } else if (values.cpf.length < 14) {
    errors.cpf = 'Informe o cpf completo';
  } else if(!validaCpf(values.cpf.replace(/\D/g, ""))) {
    errors.cpf = 'CPF inválido';
  }

  if (!values.active) {
    errors.active = 'Você precisa escolher o status da categoria';
  }

  return errors;

}

const ModalRegisterUsersOperators = ({ open, setIsOpen, setIsClose, editData }) => {

  const { onShowAlert } = useContext(DefaultContext)

  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (!open) return formik.resetForm();
    if (editData) {
      const {
        phone,
        cpf,
        name,
        active,
      } = editData;
      formik.setValues({
        name,
        active: active ? 'ativo' : 'inativo',
        phone: phone.toString(),
        cpf: cpfFormatMask(cpf.toString()),
      });
    }
  }, [editData, open])

  const formik = useFormik({
    initialValues: {
      name: '',
      cpf: '',
      phone: '',
      active: '',
      role: '',
      password: '',
    },
    validate,
    onSubmit: async values => {
      const { name, active, cpf, phone, password } = values;

      const data = {
        name,
        cpf: Number(cpf.replace(/\D/g, "")),
        phone: Number(phone.replace(/\D/g, "")),
        password,
        active: active === 'ativo',
      }

      const onSuccess = () => {
        onShowAlert(PreAlert.success('Registro cadastrado com sucesso'))
        setIsClose();
      }
      const onSuccessUpdate = () => {
        onShowAlert(PreAlert.success('Registro atualizado com sucesso'))
        setIsClose();
      }
      const onError = (error) => {
        console.error(error?.response?.status, error?.response?.data?.message);
        onShowAlert(PreAlert.error(error?.response?.data?.message || 'Falhou ao cadastrar o registro'))
      }

      setIsLoading(true);
      if (editData) {
        api.put('routes/operators/' + editData.id, data)
          .then(onSuccessUpdate)
          .catch(onError)
          .finally(() => setIsLoading(false))
      } else {
        api.post('routes/operators', data)
          .then(onSuccess)
          .catch(onError)
          .finally(() => setIsLoading(false))
      }
    },
  })

  const onChangeCpf = useCallback((e) => {
    formik.setValues({
      ...formik.values,
      cpf: masks.cpf(e.target.value)
    })
  }, [formik.values])

  return (
    <Modal
      id={'modal-register-category'}
      titleId={'Cadastro de Categoria'}
      isOpen={open}
      setIsOpen={setIsOpen}
      isStaticBackdrop={true}
      isScrollable={false}
      isCentered={true}
      size="lg" // 'sm' || 'lg' || 'xl' 
      isAnimation={true}
      onSubmit={formik.handleSubmit}
    >
      <ModalHeader setIsOpen={setIsOpen}>
        <ModalTitle id="register-user">{editData ? 'Atualização de Usuário' : 'Cadastro de Usuário'}</ModalTitle>
      </ModalHeader>
      <ModalBody>
        <form noValidate onSubmit={formik.handleSubmit}>
          {/* Inputs */}
          <div className="row g-4">
            {/* Nome */}
            <FormGroup id="name" label="Nome" className='col-md-8'>
              <Input
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.name}
                isValid={formik.isValid}
                isTouched={formik.touched.name}
                invalidFeedback={formik.errors.name}
                validFeedback='Assim está bom!'
                placeholder='Ex: João da Silva'
              />
            </FormGroup>

            {/* cpf */}
            <FormGroup id='cpf' label='CPF' className='col-md-4'>
              <Input
                type="cpf"
                onChange={onChangeCpf}
                onBlur={formik.handleBlur}
                value={formik.values.cpf}
                isValid={formik.isValid}
                isTouched={formik.touched.cpf}
                invalidFeedback={formik.errors.cpf}
                validFeedback='Assim está bom!'
                placeholder='CPF do Usuário'
              />
            </FormGroup>

            {/* phone */}
            <FormGroup id='phone' label='Telefone' className='col-md-4'>
              <Input
                type="phone"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={masks.phone(formik.values.phone)}
                isValid={formik.isValid}
                isTouched={formik.touched.phone}
                invalidFeedback={formik.errors.phone}
                validFeedback='Assim está bom!'
                placeholder='Telefone do Usuário'
              />
            </FormGroup>

            {/* Password */}
            <FormGroup id="password" label="Senha" className='col-md-4'>
              <Input
                type='password'
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.password}
                isValid={formik.isValid}
                isTouched={formik.touched.password}
                invalidFeedback={formik.errors.password}
                validFeedback='Assim está bom!'
                placeholder='Ex: 01234'
              />
            </FormGroup>

            {/* Status */}
            <FormGroup className='col-md-4'>
              <Label>Status</Label>
              <ChecksGroup
                isValid={formik.isValid}
                isTouched={formik.touched.active}
                invalidFeedback={formik.errors.active}
              >
                <Checks
                  type="radio"
                  id="active"
                  label="Ativo"
                  name="active"
                  value="ativo"
                  onChange={formik.handleChange}
                  checked={formik.values.active}
                  isInline
                />
                <Checks
                  type="radio"
                  id="active2"
                  label="Inativo"
                  name="active"
                  value="inativo"
                  onChange={formik.handleChange}
                  checked={formik.values.active}
                  isInline
                />
              </ChecksGroup>
            </FormGroup>
          </div>

          {/* Buttons */}
          <div className="row pt-4">
            <div className="col-md-4 offset-md-8">
              <div className='d-flex justify-content-evenly'>
                <BtnCancel setIsClose={setIsClose} />
                {isLoading ? (
                  <BtnLoad />
                ) : (
                  <BtnConfirm isDisable={!formik.isValid} />
                )}
              </div>
            </div>
          </div>
        </form>
      </ModalBody>
    </Modal>
  )
}

export default ModalRegisterUsersOperators;