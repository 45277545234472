import { QueryConstraint, Unsubscribe } from "firebase/firestore";
import TotenKeys from "../entities/totems/totemKeys.entity";
import FireTotenPipe from "../utils/pipe";

class TotemRechargeKeysDB extends FireTotenPipe {

  public static readonly colName = 'TotemRechargeKeys'

  constructor() {
    super(`${TotemRechargeKeysDB.colName}`);
  }

  public create(data: TotenKeys): Promise<any> {
    throw new Error('Method not implemented')
  }
  public update(id: string, data: TotenKeys): Promise<any> {
    throw new Error('Method not implemented')
  }
  public delete(id: string): Promise<any> {
    throw new Error('Method not implemented')
  }
  public getAll(...params: QueryConstraint[]): Promise<TotenKeys[]> {
    return this._getAll<TotenKeys>(...params);
  }
  public get(id: string): Promise<TotenKeys> {
    return this._get(id);
  }
  public on(listener: (data: TotenKeys[]) => void, ...params: QueryConstraint[]): Unsubscribe {
    return this._on(listener, ...params);
  }
  public onDicionary(listener: (data: any) => void, ...params: QueryConstraint[]): Unsubscribe {
    return this._onDicionary(listener, ...params);
  }

}

export default TotemRechargeKeysDB;