import React, { useMemo } from "react";
import Money from "../../../helpers/utils/money/money";
import TableCustomPdf from "../TableCustomPdf";
import TableReport from "../TableReport";

const EquipamentTable = ({ equipaments, equipamentsValues }) => {


  const equipamentsFilter = useMemo(() => {
    if (!equipaments) return [];
    const filter = equipaments.filter(
      (eq) => eq.key === 'pos'
        || eq.key === 'pos_backup'
        || eq.key === 'totems'
        || eq.key === 'totems_recharges'
        || eq.key === 'cards'
        || eq.key === 'cards_adm'
        || eq.key === 'bracelets'
        || eq.key === 'bobines'
        || eq.key === 'cable_lan'
        || eq.key === 'tripod_wifi'
        || eq.key === 'processor_wifi'
        ||eq.key === 'router_wifi'
        ||eq.key === 'transport_box'
        ||eq.key === 'pos_power'
        ||eq.key === 'tablet_power'
        ||eq.key === 'tablet'
        ||eq.key === 'pouch'
        ||eq.key === 'ruler'
        ||eq.key === 'extension'
        ||eq.key === 'totems_pedestal'
        ||eq.key === 'totems_cover'
        ||eq.key === 'totems_cuffs'
        ||eq.key === 'totems_cables'
        ||eq.key === 'kit_cordao' )
    filter.push({
      equipment: <span className="fw-bold">Totais</span>,
      qtdSent: filter.reduce((acc, curr) => acc + curr?.qtdSent, 0),
      qtdReceived: filter.reduce((acc, curr) => acc + curr?.qtdReceived, 0),
      qtd_loss: filter.reduce((acc, curr) => acc + curr?.qtd_loss, 0),
    })

    return filter;

  }, [equipaments])


  const columns = useMemo(() => ([
    {
      label: 'ITENS',
      field: 'equipment',
    },
    {
      label: 'QUANTIDADE ENTREGUE',
      field: 'qtdSent',

    },
    {
      label: 'QUANTIDADE DEVOLVIDA ',
      field: 'qtdReceived',
    },
    {
      label: 'QUANTIDADE NÃO DEVOLVIDA',
      field: 'qtd_loss',
    },

  ]), [])


  const equipamentsRender = useMemo(() => {
    return equipamentsFilter?.filter(e =>
      e.qtdSent > 0 ||
      e.qtdReceived > 0 ||
      e.qtd_loss > 0
    ) || [];
  }, [equipamentsFilter])



  return (
    <div className=''>
        <h4 className='text-center p-1 mb-5 fw-bold'>VISTORIA</h4>

      <TableReport
        rows={equipamentsRender}
        columns={columns}
        keyExtractor={row => row.id}
      />

    </div>
  );
}

export default EquipamentTable;