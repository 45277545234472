import axios from 'axios';
import ContractDB from '../database/wrappers/contract';
import EstablishmentContract from '../components/modals/EstablishmentContract/contractEstablishment';
import { renderToStaticMarkup } from 'react-dom/server';
import html2pdf from 'html2pdf.js';


export default async function d4sign(estabelecimento) {

  if (estabelecimento) {
    const folderOptions = {
      method: 'POST',
      url: `/api/v1/folders/${process.env.REACT_APP_SAFE}/create`,
      params: {
        tokenAPI: process.env.REACT_APP_TOKEN_API,
        cryptKey: process.env.REACT_APP_CRYPT_KEY
      },
      headers: { accept: 'application/json', 'content-type': 'application/json' },
      data: { folder_name: estabelecimento.cnpj }
    };

    const folderResponse = await axios.request(folderOptions)
      .then(async function (responseFolder) {
        console.log('folderResponse.data: ', responseFolder.data);
        try {
          const contractComponent = <EstablishmentContract estabelecimento={estabelecimento} />;
          const contractHTML = renderToStaticMarkup(contractComponent);

          // converte o HTML em PDF com margem em cada lado
          const pdfContent = await html2pdf()
            .from(contractHTML)
            .set({
              margin: [5, 10, 10, 10],
            })
            .outputPdf('blob');

          // converte o conteúdo PDF em base64
          const base64Content = await new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsDataURL(pdfContent);
            reader.onloadend = () => {
              const base64Content = reader.result.split(',')[1];
              console.log('base64Content: ', base64Content);
              resolve(base64Content);
            };
            reader.onerror = reject;
          });

          const uploadOptions = {
            method: 'POST',
            url: `/api/v1/documents/${process.env.REACT_APP_SAFE}/uploadbinary`,
            params: {
              tokenAPI: process.env.REACT_APP_TOKEN_API,
              cryptKey: process.env.REACT_APP_CRYPT_KEY
            },
            headers: { accept: 'application/json', 'content-type': 'application/json' },
            data: {
              mime_type: 'application/pdf',
              name: 'contrato ' + estabelecimento.name,
              uuid_folder: responseFolder.data.uuid,
              base64_binary_file: base64Content
            }
          };
          try {
            const uploadResponse = await axios.request(uploadOptions)
              .then(function (responseUpload) {
                console.log('responseUpload.data: ', responseUpload.data);
                const signatoriesOptions = {
                  method: 'POST',
                  url: `/api/v1/documents/${responseUpload.data.uuid}/createlist`,
                  params: {
                    tokenAPI: process.env.REACT_APP_TOKEN_API,
                    cryptKey: process.env.REACT_APP_CRYPT_KEY
                  },
                  headers: { accept: 'application/json', 'content-type': 'application/json' },
                  data: {
                    signers: [
                      {
                        //contrato uget
                        // email: 'rafael@uget.com.br',
                        email: 'aluanbh@gmail.com',
                        act: '1',
                        foreign: '0',
                        certificadoicpbr: '0',
                        assinatura_presencial: '0'
                      },
                      {
                        email: estabelecimento.email,
                        act: '1',
                        foreign: '0',
                        assinatura_presencial: '0',
                        certificadoicpbr: '0'
                      },
                      {
                        email: estabelecimento.witnesses.witnesse1Email,
                        act: '5',
                        foreign: '0',
                        certificadoicpbr: '0',
                        assinatura_presencial: '0'
                      },
                      {
                        email: estabelecimento.witnesses.witnesse2Email,
                        act: '5',
                        foreign: '0',
                        certificadoicpbr: '0',
                        assinatura_presencial: '0'
                      }
                    ]
                  }
                };
                try {
                  const signatoriesResponse = axios.request(signatoriesOptions)
                    .then(function (responseSignatories) {
                      console.log('responseSignatories.data: ', responseSignatories.data);
                      const Sendoptions = {
                        method: 'POST',
                        url: `/api/v1/documents/${responseUpload.data.uuid}/sendtosigner`,
                        params: {
                          tokenAPI: process.env.REACT_APP_TOKEN_API,
                          cryptKey: process.env.REACT_APP_CRYPT_KEY
                        },
                        headers: { accept: 'application/json', 'content-type': 'application/json' },
                        data: { skip_email: '0', workflow: '0' }
                      };
                      try {
                        const SendResponse = axios.request(Sendoptions)
                          .then(function (responseSend) {
                            console.log('responseSend.data: ', responseSend.data);
                            const data = {
                              folder: responseFolder.data.uuid,
                              contract_ref: responseUpload.data.uuid,
                              signed: false,
                              type: 'estabelecimento',
                              signatories: responseSignatories.data
                            };
                            // console.log('data: ', data);

                            try {
                              //cadastrar a collection contract no banco
                              new ContractDB(estabelecimento.id)
                                .create(data)
                                .then(function (response) {
                                  console.log('response: ', response);
                                }
                                ).catch(function (error) {
                                  console.error('NÃO CADASTROU AS COLLECTIONS', error);
                                });
                            }
                            catch (error) {
                              console.log('não cadastrou as collections: ', error);
                            }
                            return responseSend.data;
                          }).catch(function (error) {
                            console.error('NÃO ENVIOU', error);
                            return false;
                          });
                      }
                      catch (error) {
                        console.log('não enviou os emails: ', error);
                      }
                    }).catch(function (error) {
                      console.error('NÃO CADASTROU SIGNATÁRIOS', error);
                    });
                }
                catch (error) {
                  console.log('não cadastrou signatários: ', error);
                }
                return responseUpload.data;
              }).catch(function (error) {
                console.error('NÃO FEZ UPLOAD', error);
                return false;
              })
          }
          catch (error) {
            console.error('NÃO FEZ UPLOAD', error);
          }
        } catch (error) {
          console.log('error: ', error);
          return false;
        }
      }).catch(function (error) {
        console.error('NÃO CRIOU A PASTA', error);
      });
  }


}


