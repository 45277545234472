import Setting from "../../../database/entities/setting.entity";
import { unmask } from "../../../helpers/utils/masks";

export const settingInitValues = {
  paymentDebit: false,
  paymentCredit: false,
  paymentPix: false,
  paymentCash: false,
  paymentMealTicket: false,
  paymentCourtesy: false,
  minValuePix: '',

  brandVisa: false,
  brandMasterCard: false,
  brandMaestro: false,
  brandAmericanExpress: false,
  brandElo: false,
  brandHiperCard: false,
  brandAlelo: false,
  brandSodexo: false,
  brandBanrisul: false,
  brandDinersClub: false,
  brandVr: false,
  brandCabal: false,

  observationInRequest: false,
  disableProductsInZeroStock: false,
  printConsumptionAsTicket: false,
  requestNumber: false,
  usePrinter: false,
  useQrcodeInTicket: false,
  ticketUnique: false,
  eatTake: false,
  fiscal: false,
  nameRequest: false,
  taxService: false,
  taxServiceValue: '',
  transferBalance: false,
  entrance: false,
  ingresse: false,
  listCourtesy: false,
  singleSale: false,

  cashlessValue: '',

  imageHome: null,
  imageHome_url: null,
  imageBanner: null,
  imageBanner_url: null,
  backgroundColor: '#FFFFFF',
  fontColor: '#000000',
  touchColor: '#000000',
  fontType: '',

  tokenGwp: '',
} as const

export function settingValidateForm(values: any, errors: any) {
  if (values.taxService && !values.taxServiceValue) {
    errors.taxServiceValue = 'Informe a porcentagem da taxa de serviço'
  }
}

export function loadSettingsData(settings: Setting) {
  const {
    debit,
    credit,
    pix,
    cash,
    meal_ticket,
    courtesy,
    minValuePix,

    observationInRequest,
    disableProductsInZeroStock,
    printConsumptionAsTicket,
    requestNumber,
    usePrinter,
    useQrcodeInTicket,
    ticketUnique,
    eatTake,
    fiscal,
    nameRequest,
    taxService,
    taxServiceValue,
    cashlessValue,
    transferBalance,
    entrance,
    ingresse,
    listCourtesy,
    singleSale,

    backgroundColor,
    fontColor,
    touchColor,
    fontType,
    imageHome_url,
    imageBanner_url,
    bannerCards,

    tokenGwp,
  } = settings;

  return {
    paymentDebit: debit,
    paymentCredit: credit,
    paymentPix: pix,
    paymentCash: cash,
    paymentMealTicket: meal_ticket,
    paymentCourtesy: courtesy,
    minValuePix: String(minValuePix),

    brandVisa: bannerCards?.visa || false,
    brandMasterCard: bannerCards?.masterCard || false,
    brandMaestro: bannerCards?.maestro || false,
    brandAmericanExpress: bannerCards?.american_express || false,
    brandElo: bannerCards?.elo || false,
    brandHiperCard: bannerCards?.hiperCard || false,
    brandAlelo: bannerCards?.alelo || false,
    brandSodexo: bannerCards?.sodexo || false,
    brandBanrisul: bannerCards?.banrisul || false,
    brandDinersClub: bannerCards?.diners || false,
    brandVr: bannerCards?.vr || false,
    brandCabal: bannerCards?.cabal || false,

    observationInRequest,
    printConsumptionAsTicket: printConsumptionAsTicket || false,
    disableProductsInZeroStock,
    requestNumber,
    usePrinter,
    useQrcodeInTicket,
    ticketUnique,
    eatTake,
    fiscal,
    nameRequest,
    taxService,
    taxServiceValue,
    cashlessValue: String(cashlessValue),
    transferBalance,
    entrance,
    ingresse,
    singleSale: singleSale || false,
    listCourtesy: listCourtesy || false,

    backgroundColor: backgroundColor || '#FFFFFF',
    fontColor: fontColor || '#000000',
    touchColor: touchColor || '#000000',
    fontType,
    imageHome: null,
    imageHome_url,
    imageBanner: null,
    imageBanner_url,

    tokenGwp: tokenGwp || '',
  }
}

export function settingMountSaveData(values: any) {
  const {
    paymentDebit,
    paymentCredit,
    paymentPix,
    paymentCash,
    paymentMealTicket,
    paymentCourtesy,
    minValuePix,
    observationInRequest,
    disableProductsInZeroStock,
    printConsumptionAsTicket,
    requestNumber,
    usePrinter,
    useQrcodeInTicket,
    ticketUnique,
    eatTake,
    fiscal,
    nameRequest,
    taxService,
    taxServiceValue,
    cashlessValue,
    transferBalance,
    entrance,
    ingresse,
    listCourtesy,
    singleSale,

    backgroundColor,
    fontColor,
    touchColor,
    fontType,
    imageHome,
    imageBanner,
    brandAlelo,
    brandAmericanExpress,
    brandBanrisul,
    brandCabal,
    brandDinersClub,
    brandElo,
    brandHiperCard,
    brandMaestro,
    brandMasterCard,
    brandSodexo,
    brandVr,
    brandVisa,

    tokenGwp,
  } = values;

  const valueTaxService = Number(taxServiceValue)
  const valuecashlessValue = Number(unmask(cashlessValue))
  const valueValuePix = Number(unmask(minValuePix))

  const data = {
    debit: paymentDebit,
    credit: paymentCredit,
    cash: paymentCash,
    pix: paymentPix,
    meal_ticket: paymentMealTicket,
    courtesy: paymentCourtesy,
    minValuePix: isNaN(valueValuePix) ? 0 : valueValuePix,
    bannerCards: {
      visa: brandVisa === undefined ? false : brandVisa,
      masterCard: brandMasterCard === undefined ? false : brandMasterCard,
      maestro: brandMaestro === undefined ? false : brandMaestro,
      american_express: brandAmericanExpress === undefined ? false : brandAmericanExpress,
      elo: brandElo === undefined ? false : brandElo,
      hiperCard: brandHiperCard === undefined ? false : brandHiperCard,
      alelo: brandAlelo === undefined ? false : brandAlelo,
      sodexo: brandSodexo === undefined ? false : brandSodexo,
      cabal: brandCabal === undefined ? false : brandCabal,
      banrisul: brandBanrisul === undefined ? false : brandBanrisul,
      diners: brandDinersClub === undefined ? false : brandDinersClub,
      vr: brandVr === undefined ? false : brandVr,
    },

    taxService,
    nameRequest,
    observationInRequest: observationInRequest || false,
    disableProductsInZeroStock: disableProductsInZeroStock || false,
    printConsumptionAsTicket: printConsumptionAsTicket === undefined ? false : printConsumptionAsTicket,
    requestNumber,
    usePrinter: usePrinter || null,
    useQrcodeInTicket: useQrcodeInTicket || null,
    ticketUnique,
    eatTake,
    fiscal,
    transferBalance: transferBalance || false,
    entrance: entrance || false,
    ingresse: ingresse || false,
    listCourtesy: listCourtesy === undefined ? false : listCourtesy,
    singleSale: singleSale === undefined ? false : singleSale,

    cashlessValue: isNaN(valuecashlessValue) ? 0 : valuecashlessValue,
    taxServiceValue: isNaN(valueTaxService) ? 0 : valueTaxService,

    backgroundColor,
    fontColor,
    touchColor,
    fontType,
    imageHome,
    imageBanner,

    tokenGwp: tokenGwp || '',
  }

  return data;
}