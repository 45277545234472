import React, { memo, useContext, useEffect, useMemo, useState } from "react";
import Card, { CardBody, CardHeader, CardLabel, CardTitle } from "../../../../../components/bootstrap/Card";
import TableCustom from "../../../../../components/MyCustom/TableCustom";
import { DefaultContext } from "../../../../../contexts/default";
import { ResultsContext } from "../../../../../contexts/results";
import SectorProductSoldEventData from "../../../../../database/wrappers/dashboard/sectorProductsSolds";
import useDarkMode from "../../../../../hooks/useDarkMode";
import Money from "../../../../../helpers/utils/money/money";
import SelectSector from "../../../../../components/MyCustom/SelectSector";
import dateFormat from "dateformat";
import useTotalStock from "../../../../../hooks/TotaisReports/useTotalStock";
import ProductDB from "../../../../../database/wrappers/product";
import convertArrayToObject from "../../../../../utils/convertArrayToObject";
import useDicionary from "../../../../../hooks/TotaisReports/useDicionarys.js";


const Estoque = ({ hidden}) => {
  const { darkModeStatus } = useDarkMode();
  const {productsDicionary,sectorsDicionary} = useDicionary();
  const { StockCalc } = useTotalStock();
  const columns = useMemo(() => ([
    {
      label: 'Produto',
      field: 'name',
      format: row => productsDicionary[row.id]?.name

    },
    {
      label: 'Setor',
      field: 'sectorId',
      format: row => sectorsDicionary[row.sectorId]?.name
    },
    {
      label: 'Quantidade Vendida',
      field: 'qtd',
    },
    {
      label: 'Quantidade Estornada',
      field: 'qtdReversed',
    },
    {
      label: 'Valor UNIT',
      field: 'price',
      format: row => Money.centsToMaskMoney(row.price),
      formatExport: value => Money.centsToCoin(value),
    },
    {
      label: 'Total',
      field: '{row}',
      format: row => Money.centsToMaskMoney(row.price * row.qtd),
      formatExport: row => Money.centsToCoin(row.price * row.qtd),
    },
  ]), [productsDicionary,sectorsDicionary])

  return (
    <div hidden={hidden}>
      <Card stretch>
        <CardHeader>
          <CardLabel icon='BackupTable' iconColor={!darkModeStatus ? 'dark' : 'light'}>
            <CardTitle>ESTOQUE</CardTitle>
          </CardLabel>
        </CardHeader>
        <CardBody className='table-responsive'>
          <TableCustom
            rows={StockCalc || []}
            columns={columns}
            keyExtractor={row => row.id}
          />
        </CardBody>
      </Card>
    </div>
  );
}

export default memo(Estoque);