import React, { memo, useContext, useMemo } from 'react';
import Modal, { ModalBody, ModalHeader, ModalTitle } from '../../bootstrap/Modal';
// Database
import { DefaultContext } from '../../../contexts/default';
import SelectEvent from '../../MyCustom/SelectEvent';
import { QRCodeSVG } from 'qrcode.react';
import dateFormat from 'dateformat';

const ModalShowQrcodePrinter = ({ open, setIsOpen, printersSelected }) => {

  const { establishment, event } = useContext(DefaultContext)

  const qrcode = useMemo(() => {
    if(!establishment || !event || !printersSelected) return 'Sem Dados'
    const data = {
      estabId: establishment.id,
      eventId: event.id,
      printerId: printersSelected.id,
    }
    return JSON.stringify(data)
  }, [establishment, event, printersSelected])

  return (
    <Modal
      id={'modal-login-printers'}
      titleId={'Testar Impressora'}
      isOpen={open}
      setIsOpen={setIsOpen}
      isStaticBackdrop={true}
      isScrollable={false}
      isCentered={true}
      size="lg" // 'sm' || 'lg' || 'xl' 
      isAnimation={true}
    >
      <ModalHeader setIsOpen={setIsOpen}>
        <ModalTitle id="login-printer">Login Impressora</ModalTitle>
      </ModalHeader>
      <ModalBody>
        <div className='d-flex flex-column flex-row align-items-center justify-content-between'>
          <SelectEvent />
          <h5><b style={{ marginRight: 3 }}>Estabelecimento: </b>{establishment?.name}</h5>
          <h5><b style={{ marginRight: 3 }}>Evento: </b>{event?.name}</h5>
          <h5><b style={{ marginRight: 3 }}>Data do Evento: </b>{dateFormat(event?.created_at.toDate() || new Date(), 'dd/mm/yyyy')}</h5>
          <div style={{ padding: 10, backgroundColor: 'white' }}>
            <QRCodeSVG
              value={qrcode}
              size={150}
            />
          </div>
          <span>Altere para o modo ligth ao escanear o QR Code</span>
        </div>
      </ModalBody>
    </Modal>
  )
}

export default memo(ModalShowQrcodePrinter);