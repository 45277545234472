import React from "react";
import Money from "../../../helpers/utils/money/money";
import { TYPE_OF_CHARGE, TYPE_OF_CHARGE_LABEL } from "../../../types/charge";


const ValueTable = ({ result }) => {

    const pos = result?.equipaments.find((item) => item.key === 'pos');
    const totems = result?.equipaments.find((item) => item.key === 'totems');
    const totems_recharge = result?.equipaments.find((item) => item.key === 'totems_recharges');
    const equipamentsLending = result?.equipaments.filter((item) => item.key !== 'pos' && item.key !== "totems" && item.key !== "totems_recharges");
    const totalLendingLoss = equipamentsLending && equipamentsLending?.reduce((prev, curr) => prev + curr.total_rent, 0)

    return (
        <div className=''>
            <h4 className='text-center p-4 fw-bold'>VALORES UGET</h4>

            <div className="d-flex  justify-content-between ">
                <div className="d-flex flex-column ">
                    <p className="">CRITÉRIO: {TYPE_OF_CHARGE_LABEL[result?.typeCharge]}</p>
                </div>
                <p className="">{result?.typeCharge === TYPE_OF_CHARGE.AUTOMATIC_CHARGE ? result?.typeOfChargeApplied === TYPE_OF_CHARGE.PAYMENT_RATE_CHARGE ? 'Tx de pag. acima do mínimo' : 'Tx de pag. abaixo do mínimo' : ''}</p>
            </div>
            <table className='table table-striped table-fs-litle'>
                <tbody>
                    {result?.typeOfChargeApplied !== TYPE_OF_CHARGE.PAYMENT_RATE_CHARGE &&
                        <tr>
                            <td colSpan='3'>PÓS EM COMODATO (valor mínimo) </td>
                            <td className="text-tb-align-right">{Money.centsToMaskMoney(pos?.total_rent)}</td>
                        </tr>
                    }

                    {result?.typeOfChargeApplied !== TYPE_OF_CHARGE.RENT_CHARGE &&
                        <tr>
                            <td colSpan='3'>TAXA DE PAGAMENTO </td>
                            <td className="text-tb-align-right">{Money.centsToMaskMoney(result?.resume?.tax_uget)}</td>
                        </tr>

                    }

                    {/* TOTENS SEMPRE COBRA */}
                    <tr>
                        <td colSpan='3'>TOTENS</td>
                        <td className="text-tb-align-right">{Money.centsToMaskMoney(totems?.total_rent)}</td>
                    </tr>
                    <tr>
                        <td colSpan='3'> TOTENS RECARGA </td>
                        <td className="text-tb-align-right">{Money.centsToMaskMoney(totems_recharge?.total_rent)}</td>
                    </tr>

                    {result?.typeCharge !== TYPE_OF_CHARGE.PAYMENT_RATE_CHARGE &&

                        <tr>
                            <td colSpan='3'>OUTROS EQUIPAMENTOS EM COMODATO</td>
                            <td className="text-tb-align-right">{Money.centsToMaskMoney(totalLendingLoss)}</td>
                        </tr>
                    }

                    {/* EQUIPAMENTOS PERDIDOS SEMPRE COBRA */}
                    <tr>
                        <td colSpan='3'>EQUIPAMENTOS PERDIDOS</td>
                        <td className="text-tb-align-right">{Money.centsToMaskMoney(result?.equipamentsValues?.total_loss)}</td>
                    </tr>

                    {/* SERVIÇOS SEMPRE COBRA */}
                    <tr>
                        <td colSpan='3'>SERVIÇOS ADICIONAIS</td>
                        <td className="text-tb-align-right">{Money.centsToMaskMoney(result?.resume?.services_provided)}</td>
                    </tr>
                    <tr className="fw-bold">
                        <td colSpan='3'>TOTAL VALORES uGet ;-)</td>
                        <td className="text-tb-align-right">{Money.centsToMaskMoney(result?.resume?.total_uget_gross)}</td>
                    </tr>
                </tbody>
            </table>
        </div>
    );


}

export default ValueTable;