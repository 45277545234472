import { QueryConstraint, Unsubscribe } from "firebase/firestore";
import SectorProduct from "../entities/sectors/product";
import FirestorePipe from "../utils/pipe";
import EstablishmentDB from "./establishment";
import SectorDB from "./sector";

class SectorProductDB extends FirestorePipe {

  public static readonly colName = 'Products'

  constructor(idEstab: string, idSector: string) {
    if (!idEstab)
      throw new Error('Informe o id do estabelecimento')
    super(`${EstablishmentDB.colName}/${idEstab}/${SectorDB.colName}/${idSector}/${SectorProductDB.colName}`);
  }

  public define(id: string, data: SectorProduct): Promise<any> {
    return this._define(id, data);
  }
  public create(data: SectorProduct): Promise<any> {
    return this._create(data);
  }
  public update(id: string, data: SectorProduct): Promise<any> {
    return this._update(id, data);
  }
  public delete(id: string): Promise<any> {
    return this._delete(id);
  }
  public getAll(...params: QueryConstraint[]): Promise<SectorProduct[]> {
    return this._getAll<SectorProduct>(...params);
  }
  public get(id: string): Promise<SectorProduct> {
    return this._get(id);
  }
  public on(listener: (data: SectorProduct[]) => void, ...params: QueryConstraint[]): Unsubscribe {
    return this._on(listener, ...params);
  }

}

export default SectorProductDB;