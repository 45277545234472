import React, { memo } from 'react'
import Card, { CardHeader, CardLabel, CardTitle, CardBody, CardActions } from '../../../components/bootstrap/Card';
import CardValue from '../../../components/MyCustom/Dashboard/CardValue';
import Money from '../../../helpers/utils/money/money';
import useDarkMode from '../../../hooks/useDarkMode';
import Popovers from '../../../components/bootstrap/Popovers';
import Icon from '../../../components/icon/Icon';

const TicketMedio = ({ ticketMedio, cartaoPulseira }) => {

  const { darkModeStatus } = useDarkMode();

  const qtdCards = cartaoPulseira?.habilitado || 1;
  const homem = ticketMedio?.homem / qtdCards;
  const mulher = ticketMedio?.mulher / qtdCards;
  const indefinido = ticketMedio?.indefinido / qtdCards;
  const geral = homem + mulher + indefinido;
  return (
    <Card stretch>
      <CardHeader>
        <CardLabel icon='TableRows' iconColor={!darkModeStatus ? 'dark' : 'light'}>
          <CardTitle className='d-flex flex-row align-items-center'>
            <CardActions>
              TICKET MÉDIO DE FATURAMENTO
            </CardActions>
            <Popovers trigger={'hover'} desc={<b>Faturamento de ticket médio, representado por: faturamento total, crédito, débito, pix, vale refeição e dinheiro.</b>}><Icon className='mx-3' size='2x' icon='Info' /></Popovers>
          </CardTitle>
        </CardLabel>
      </CardHeader>
      <CardBody>
        <div className='col align-items-center' >
          <div className='col-md-12' >
            <CardValue
              color='dark'
              description='Ticket médio geral do evento.'
              label='GERAL'
              value={Money.centsToMaskMoney(geral || 0)}
            />
          </div>
          <div className='col-md-12'>
            <CardValue
              color='primary'
              label='HOMEM'
             description='Ticket médio de homens no evento.'
              value={Money.centsToMaskMoney(homem || 0)}
            />
          </div>
          <div className='col-md-12'>
            <CardValue
              color='success'
              description='Ticket médio de mulheres no evento.'
              label='MULHER'
              value={Money.centsToMaskMoney(mulher || 0)}
            />
          </div>
          <div className='col-md-12'>
            <CardValue
              color='green'
              description='Ticket médio de indefinidos no evento.'
              label='INDEFINIDO'
              value={Money.centsToMaskMoney(indefinido || 0)}
            />
          </div>
        </div>
      </CardBody>
    </Card>
  )
}

export default memo(TicketMedio);



