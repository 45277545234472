import React, { useContext, useState } from 'react';
import PageWrapper from '../../../layout/PageWrapper/PageWrapper';
import Page from '../../../layout/Page/Page';
import { demoPages } from '../../../menu';
import { DefaultContext } from '../../../contexts/default';
import useDarkMode from '../../../hooks/useDarkMode';
import Card, { CardBody, CardHeader, CardLabel, CardTitle } from '../../../components/bootstrap/Card';
import Button from '../../../components/bootstrap/Button';
import Accordion, { AccordionItem } from '../../../components/bootstrap/Accordion';
import EventItemAccordian from './eventItem'
import { EVENT_STATUS, EVENT_STATUS_SUBLABEL } from '../../../types/eventStatus';

const EventStatus = () => {
  const { darkModeStatus } = useDarkMode();

  const { } = useContext(DefaultContext)

  const [showEvents, setshowEvents] = useState(0)
  const [eventStatus, seteventStatus] = useState([
    {
      id: '1',
      name: 'Evento teste 1',
      number: 3,
      status: 1,
      establishment: 'Pousada Tal Tal',
      steps: {
        1:{ status: 2, label: 'Vendedor: Gabriel gleydstone' },
        2:{ status: 2, label: 'Vendedor: Gabriel / Estoque: Adonis' },
        3:{ status: 2, label: 'Vendedor: Gabriel / Estoque: Adonis' },
        4:{ status: 1, label: 'Vendedor: Gabriel / Estoque: Adonis' },
        5:{ status: 0, label: 'Vendedor: Gabriel / Estoque: Adonis' },
        6:{ status: 0, label: 'Vendedor: Gabriel gleydstone' },
        7:{ status: 0, label: 'Vendedor: Gabriel gleydstone' },
        8:{ status: 0, label: 'Vendedor: Gabriel gleydstone' },
        9:{ status: 0, label: 'Vendedor: Gabriel gleydstone' },
        10:{ status: 0, label: 'Vendedor: Gabriel gleydstone' },
        11:{ status: 0, label: 'Vendedor: Gabriel gleydstone' },
        12:{ status: 0, label: 'Vendedor: Gabriel gleydstone' },
        13:{ status: 0, label: 'Vendedor: Gabriel gleydstone' },
        14:{ status: 0, label: 'Vendedor: Gabriel gleydstone' },
        15:{ status: 0, label: 'Vendedor: Gabriel gleydstone' },
        16:{ status: 0, label: 'Vendedor: Gabriel gleydstone' },
        17:{ status: 0, label: 'Vendedor: Gabriel gleydstone' },
        18:{ status: 0, label: 'Vendedor: Gabriel gleydstone' },
        19:{ status: 0, label: 'Vendedor: Gabriel gleydstone' },
        20:{ status: 0, label: 'Vendedor: Gabriel gleydstone' },
      }
    },
    {
      id: '2',
      name: 'Evento teste 2',
      number: 4,
      status: 4,
      establishment: 'Raparigada',
      steps: {
        1:{ status: 2, label: 'Gabriel gleydstone' },
        2:{ status: 2, label: 'Gabriel / Adonis' },
        3:{ status: 2, label: 'Gabriel / Adonis' },
        4:{ status: 1, label: 'Gabriel / Adonis' },
        5:{ status: 0, label: 'Gabriel / Adonis' },
        6:{ status: 0, label: 'Gabriel gleydstone' },
        7:{ status: 0, label: 'Gabriel gleydstone' },
        8:{ status: 0, label: 'Gabriel gleydstone' },
        9:{ status: 0, label: 'Gabriel gleydstone' },
        10:{ status: 0, label: 'Gabriel gleydstone' },
        11:{ status: 0, label: 'Gabriel gleydstone' },
        12:{ status: 0, label: 'Gabriel gleydstone' },
        13:{ status: 0, label: 'Gabriel gleydstone' },
        14:{ status: 0, label: 'Gabriel gleydstone' },
        15:{ status: 0, label: 'Gabriel gleydstone' },
        16:{ status: 0, label: 'Gabriel gleydstone' },
        17:{ status: 0, label: 'Gabriel gleydstone' },
        18:{ status: 0, label: 'Gabriel gleydstone' },
        19:{ status: 0, label: 'Gabriel gleydstone' },
        20:{ status: 0, label: 'Gabriel gleydstone' },
      }
    }
  ])

  const TitleAccordian = ({ item }) => {
    return (
      <div className='row col-md-12'>
        <div className='col-md-2'>
          <h3>{item.number}</h3>
        </div>
        <div className='col-md-5'>
          <h4>{item.number} - {item.name}</h4>
          <p className='fw-light'>{item.establishment}</p>
        </div>
        <div className='col-md-5'>
          <h4>{item.status} - {EVENT_STATUS[item.status]}</h4>
          <p className='fw-light'>{EVENT_STATUS_SUBLABEL[item.status]}</p>
        </div>
      </div>
    );
  }

  return (
    <PageWrapper title={demoPages.eventStatus.text}>
      <Page container='fluid'>
        <Card>
          <CardHeader>
            <CardLabel icon='EventNote' iconColor={darkModeStatus ? 'light' : 'dark'}>
              <CardTitle>Status Eventos</CardTitle>
            </CardLabel>
            <div className='w-100 d-flex flex-row justify-content-end'>
              <Button
                className='text-dark'
                color={showEvents === 0 ? 'warning' : 'light'}
                shadow="sm"
                hoverShadow="sm"
                size="sm"
                onClick={() => setshowEvents(0)}
              >
                Ativos
              </Button>
              <Button
                className='mx-4 text-dark'
                color={showEvents === 1 ? 'warning' : 'light'}
                shadow="sm"
                hoverShadow="sm"
                size="sm"
                onClick={() => setshowEvents(1)}
              >
                Finalizados
              </Button>
            </div>
          </CardHeader>
          <CardBody>
            <Accordion
              tag='div'
              isFlush
              color='success'
            >
              {eventStatus.map(item => (
                <AccordionItem 
                  id={item.id} 
                  title={<TitleAccordian item={item} />}
                >
                  <EventItemAccordian item={item} />
                </AccordionItem>
              ))}
            </Accordion>
          </CardBody>
        </Card>
      </Page>
    </PageWrapper>
  );
};

export default EventStatus;
