import React, { useState, useContext, memo, useRef, useEffect, useMemo } from 'react';
import Modal, { ModalBody, ModalFooter } from '../../bootstrap/Modal';
import { DefaultContext } from '../../../contexts/default';
import Button from '../../bootstrap/Button';
import { useReactToPrint } from 'react-to-print';
import Spinner from '../../bootstrap/Spinner';
import useDarkMode from '../../../hooks/useDarkMode';
import BtnCancel from '../../MyCustom/BtnCancel';
import LastPageContract from './lastPageContract';
import HeaderContrato from './header';
import BodyContract from './bodyContract';



const EstablishmentContract = ({ open, setIsOpen = () => { }, setIsClose, eventSelected, estabSelecionado }) => {
  const { darkModeStatus } = useDarkMode();
  // console.log('estabSelecionado', estabSelecionado)

  const { establishment, estabSelected, onShowAlert } = useContext(DefaultContext);

  const [loading, setloading] = useState(false)
  const [eventGeral, seteventGeral] = useState(null)
  const [faturamento, setfaturamento] = useState(null)
  const [movimentacao, setmovimentacao] = useState(null)
  const [closureResourcesEvent, setclosureResourcesEvent] = useState(null)
  const [closureCashiers, setclosureCashiers] = useState([])
  const [openSignature, setOpenSignature] = useState(false)


  const componentRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    documentTitle: 'Fechamento - ' + eventSelected?.name,
    // onAfterPrint: () => setIsOpen(false),
  });

  const LoadSpinner = () => (
    <div className='d-flex align-items-center justify-content-center h-100'>
      <Spinner
        size={80}
        color={darkModeStatus ? 'light' : 'dark'}
      />
    </div>
  )



  return (
    <>
      <Modal
        id={'modal-register-stores'}
        titleId={'Cadastro de Estabelecimentos'}
        isOpen={open}
        setIsOpen={setIsOpen}
        isStaticBackdrop={true}
        isScrollable={false}
        isCentered={true}
        size="xl" // 'sm' || 'lg' || 'xl' 
        isAnimation={true}
        fullScreen
      // onSubmit={formik.handleSubmit}
      >
        <ModalBody className='px-5 pt-5' ref={componentRef} >
          <HeaderContrato eventSelected={eventSelected} establishment={estabSelecionado} />
          {loading ?
            <LoadSpinner />
            :
            <>
              <BodyContract establishment={estabSelecionado} />

              <LastPageContract />
            </>
          }
        </ModalBody>

        <ModalFooter>
          <div className='row col-md-12 justify-content-around'>
            <BtnCancel
              className='col-md-3'
              setIsClose={setIsClose}
            />
            <Button onClick={() => handlePrint()}
              className='col-md-3' type='button' color='primary' icon="PictureAsPdf" rounded={1} hoverShadow="sm" shadow="sm" size="sm">
              {establishment?.contracts?.signed ? 'Imprimir Contrato' : 'Assinar'}
            </Button>
          </div>
        </ModalFooter>
      </Modal >
    </>

  )
}


export default memo(EstablishmentContract);