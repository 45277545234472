import { useContext, useEffect, useMemo, useState } from "react";
import { DefaultContext } from "../../contexts/default";
import RechargeEventData from "../../database/wrappers/eventData/recharge";
import { PAYMENT_TYPE } from "../../types/payment";
import Recharge from "../../database/entities/eventData/recharge.entity";

const useTotalRecharge = () => {
  const { estabSelected, eventSelected, sectorSelected } = useContext(DefaultContext);
  const [recharges, setRecharges] = useState<Recharge[]>([]);



  useEffect(() => {
    let isMounted = true;
    const loadRecharge = () => {
      if (estabSelected && eventSelected && sectorSelected) {
        new RechargeEventData(estabSelected, eventSelected)
          .getBySector(sectorSelected)
          .then(recharge => {
            if (isMounted) {
              setRecharges(recharge)
            }
          }
          );
      }
    };
    loadRecharge();

    return () => {
      isMounted = false;
    }
  }, [estabSelected, eventSelected,sectorSelected]);


  const RechargesCalc = useMemo(() => {
    let totalCredit = 0;
    let totalDebit = 0;
    let totalPix = 0;
    let totalMealTicket = 0;
    let totalCash = 0;
    let totalCourtesy = 0;
    if (!recharges) return {
      totalCash: 0,
      totalDebit: 0,
      totalCredit: 0,
      totalPix: 0,
      totalMealTicket: 0,
      totalCourtesy: 0,
    };
    const sumTypeValue = (paymentType: number, value: number) => {
      if (paymentType === PAYMENT_TYPE.CASH)
        totalCash += value
      else if (paymentType === PAYMENT_TYPE.CARD_CREDIT)
        totalCredit += value
      else if (paymentType === PAYMENT_TYPE.CARD_DEBIT)
        totalDebit += value
      else if (paymentType === PAYMENT_TYPE.PIX)
        totalPix += value
      else if (paymentType === PAYMENT_TYPE.MEAL_TICKET)
        totalMealTicket += value
      else if (paymentType === PAYMENT_TYPE.COURTESY)
        totalCourtesy += value
    }


    const rechargesWithoutRefunds = recharges.filter(recharge => !recharge?.reversed);
    const rechargesWithRefunds = recharges.filter(recharge => recharge?.reversed);
    const rechargesTotalWithRefunds = rechargesWithRefunds.reduce((prev, curr) => prev + curr.value, 0);
    rechargesWithoutRefunds.forEach((recharge: Recharge) => {
      if (recharge.payment_type === PAYMENT_TYPE.DIVIDED) {
        recharge.splitPayment.forEach(item => {
          if (item.reversed) {
            return;
          } else {
            sumTypeValue(item.payment_type, item.value);
          }
        });
      } else {
        sumTypeValue(recharge.payment_type, recharge.value);
      }
    });

    const totalRecharge = totalCash + totalDebit + totalCredit + totalPix + totalMealTicket + totalCourtesy;



    return {
      cash: totalCash,
      debit: totalDebit,
      credit: totalCredit,
      pix: totalPix,
      mealTicket: totalMealTicket,
      courtesy: totalCourtesy,
      totalRecharge,
      rechargesTotalWithRefunds,
      rechargesWithoutRefunds,
      recharges,

    };
  }, [recharges]);

  const RechargesSector = useMemo(() => {
    let totalCredit = 0;
    let totalDebit = 0;
    let totalPix = 0;
    let totalMealTicket = 0;
    let totalCash = 0;
    let totalCourtesy = 0;
    if (!recharges) return {
      totalCash: 0,
      totalDebit: 0,
      totalCredit: 0,
      totalPix: 0,
      totalMealTicket: 0,
      totalCourtesy: 0,
    };

    const sumTypeValue = (paymentType: number, value: number) => {
      if (paymentType === PAYMENT_TYPE.CASH)
        totalCash += value
      else if (paymentType === PAYMENT_TYPE.CARD_CREDIT)
        totalCredit += value
      else if (paymentType === PAYMENT_TYPE.CARD_DEBIT)
        totalDebit += value
      else if (paymentType === PAYMENT_TYPE.PIX)
        totalPix += value
      else if (paymentType === PAYMENT_TYPE.MEAL_TICKET)
        totalMealTicket += value
      else if (paymentType === PAYMENT_TYPE.COURTESY)
        totalCourtesy += value
    }

    const rechargesSector = recharges.filter((row) => row.sectorId === sectorSelected);
    const rechargesSectorWithoutRefunds = rechargesSector.filter(recharge => !recharge?.reversed);
    const rechargesSectorWithRefunds = rechargesSector.filter(recharge => recharge?.reversed);
    const rechargesSectorTotalWithRefunds = rechargesSectorWithRefunds.reduce((prev, curr) => prev + curr.value, 0);

    rechargesSectorWithoutRefunds.forEach(recharge => {
      if (recharge.payment_type === PAYMENT_TYPE.DIVIDED) {
        recharge.splitPayment.forEach(item => {
          if (item.reversed) {
            return;
          } else {
            sumTypeValue(item.payment_type, item.value);
          }
        });
      } else {
        sumTypeValue(recharge.payment_type, recharge.value);
      }
    });

    const totalRecharge = totalCash + totalDebit + totalCredit + totalPix + totalMealTicket + totalCourtesy;


    return {
      cash: totalCash,
      debit: totalDebit,
      credit: totalCredit,
      pix: totalPix,
      mealTicket: totalMealTicket,
      courtesy: totalCourtesy,
      rechargesSector,
      totalRecharge,
      rechargesSectorTotalWithRefunds

    };

  }, [recharges, sectorSelected])

  return { RechargesCalc, RechargesSector };
};

export default useTotalRecharge;
