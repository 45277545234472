import React, { useState, useEffect, useContext, memo, useCallback, useMemo } from 'react';
import { useFormik } from 'formik';
import { Timestamp } from 'firebase/firestore'
import { DefaultContext } from '../../../contexts/default';
import Modal, { ModalBody, ModalHeader, ModalTitle } from '../../bootstrap/Modal';
import Checks, { ChecksGroup } from '../../bootstrap/forms/Checks';
import Wizard, { WizardItem } from '../../Wizard';
import FormGroup from '../../bootstrap/forms/FormGroup';
import Input from '../../bootstrap/forms/Input';
import EventDB from '../../../database/wrappers/event';
import PreAlert from '../../../helpers/utils/preAlert';
import randomString from '../../../utils/random/randomString';
import SettingsEvent from '../../MyCustom/SettingsEvent';
import SettingDB from '../../../database/wrappers/settings';
import masks from '../../../helpers/utils/masks';
import UserAdd from '../../MyCustom/Events/UserAdd';
import { loadSettingsData, settingInitValues, settingMountSaveData, settingValidateForm } from '../../MyCustom/SettingsEvent/settingsUtils';
import Select from '../../bootstrap/forms/Select';
import apiViaCep from '../../../services/viaCep';
import showNotification from '../../extras/showNotification';
import InputGroup, { InputGroupText } from '../../bootstrap/forms/InputGroup';
import Textarea from '../../bootstrap/forms/Textarea';
import Banner from '../../MyCustom/Banner';
import Spinner from '../../bootstrap/Spinner';
import Avatar from '../../Avatar';
import Card, { CardBody, CardHeader, CardLabel, CardTitle, CardSubTitle } from "../../bootstrap/Card";
import Alelo from '../../../assets/img/brands/alelo.svg'
import AmericanExpress from '../../../assets/img/brands/american-express.svg'
import Banrisul from '../../../assets/img/brands/banrisul.svg'
import Cabal from '../../../assets/img/brands/cabal.svg'
import DinersClub from '../../../assets/img/brands/diners-club.svg'
import Elo from '../../../assets/img/brands/elo.svg'
import HiperCard from '../../../assets/img/brands/hipercard.svg'
import Maestro from '../../../assets/img/brands/maestro.svg'
import MasterCard from '../../../assets/img/brands/masterCard.svg'
import Sodexo from '../../../assets/img/brands/sodexo.svg'
import ValeRF from '../../../assets/img/brands/vale-refeicao.svg'
import Visa from '../../../assets/img/brands/visa.svg'
import AvatarVideo from '../../MyCustom/AvatarVideo';
import useDarkMode from '../../../hooks/useDarkMode';


const ModalRegisterEvents = ({ open, setIsOpen, setIsClose, eventSelected, usersCoor, usersSeller }) => {

  const { estabSelected, establishment, onShowAlert } = useContext(DefaultContext);

  const [isLoading, setIsLoading] = useState(false);
  const [loadingCep, setloadingCep] = useState(false);

  function convertDateToString(date) {
    if (!date)
      return '';
    if (typeof date === 'string')
      return date
    else {
      const eventDate = date.toDate()
      const month = eventDate.getMonth() + 1 > 9 ? eventDate.getMonth() + 1 : '0' + (eventDate.getMonth() + 1)
      const day = eventDate.getDate() > 9 ? eventDate.getDate() : '0' + eventDate.getDate()
      const hours = eventDate.getHours() > 9 ? eventDate.getHours() : '0' + eventDate.getHours()
      const minutes = eventDate.getMinutes() > 9 ? eventDate.getMinutes() : '0' + eventDate.getMinutes()
      return `${eventDate.getFullYear()}-${month}-${day}T${hours}:${minutes}`
    }
  }

  const { darkModeStatus } = useDarkMode();

  useEffect(() => {
    if (!open) return formik.resetForm();
    if (eventSelected) {
      const {
        name,
        date,
        endDate,
        isConfirmed,
        customLink,
        types,
        witnesses,
        responsibles,
        technical,
        sellerId,
        place,
        description,
        title,
        email_description,
        email_title,
        banner_email_url,
        banner_url,
        codeNumber,
        logo_url,
        customMessage
      } = eventSelected;

      const dateStr = convertDateToString(date)
      const endDateStr = convertDateToString(endDate)

      const eventValues = {
        name,
        date: dateStr,
        endDate: endDateStr,
        isConfirmed: isConfirmed || false,
        customLink: customLink || false,

        cashless: types.cashless,
        command: types.command,
        ticket: types.ticket,
        totem: types.totem,
        hawker: types.hawker,

        ugetWitnessName: witnesses?.ugetWitnessName,
        ugetWitnessCpf: witnesses?.ugetWitnessCpf,
        ugetWitnessEmail: witnesses?.ugetWitnessEmail,
        eventWitnessName: witnesses?.eventWitnessName,
        eventWitnessCpf: witnesses?.eventWitnessCpf,
        eventWitnessEmail: witnesses?.eventWitnessEmail,

        placeName: place?.placeName,
        street: place?.street,
        number: place?.number,
        neighborhood: place?.neighborhood,
        city: place?.city,
        state: place?.state,
        zip_code: place?.zip_code,
        complement: place?.complement,

        responsibles: responsibles || [],
        technical: technical || [],
        sellerId: sellerId || '',

        description: description || '',
        customMessage: customMessage || '',
        title: title || '',
        email_description: email_description,
        email_title: email_title,
        banner_email_url: banner_email_url,

        banner: null,
        banner_email: null,

        banner_url: banner_url || '',

        codeNumber: codeNumber || '',
        logo: null,
        logo_url: logo_url || '',

        cepSearched: true,
      }
      formik.setValues(eventValues);

      new SettingDB(estabSelected, eventSelected.id)
        .get()
        .then(setting => {
          if (setting) {
            formik.setValues({
              ...eventValues,
              ...loadSettingsData(setting),
            })
          }
        })
        .catch(error => console.error(error))
    }
  }, [eventSelected, open])

  const validate = useCallback((values) => {
    const errors = {};

    settingValidateForm(values, errors)

    return errors;
  }, [])

  const formik = useFormik({
    initialValues: {
      name: '',
      date: '',
      endDate: '',
      isConfirmed: false,
      customLink: false,
      cashless: false,
      command: false,
      ticket: false,
      totem: false,
      hawker: false,

      placeName: '',
      street: '',
      number: '',
      neighborhood: '',
      city: '',
      state: '',
      zip_code: '',
      complement: '',


      ugetWitnessName: '',
      ugetWitnessCpf: '',
      ugetWitnessEmail: '',
      eventWitnessName: '',
      eventWitnessCpf: '',
      eventWitnessEmail: '',

      responsibles: [],
      technical: [],
      sellerId: '',

      description: '',
      customMessage: '',
      title: '',
      email_description: '',
      email_title: '',
      banner_email_url: '',
      banner: null,
      banner_email: null,
      banner_url: null,

      logo: null,
      logo_url: null,

      cepSearched: false,

      codeNumber: '',

      ...settingInitValues,
    },
    validate,
    onSubmit: values => {
      const {
        name,
        date,
        endDate,
        isConfirmed,
        customLink,

        cashless,
        command,
        ticket,
        totem,
        hawker,

        placeName,
        street,
        number,
        neighborhood,
        city,
        state,
        zip_code,
        complement,

        ugetWitnessName,
        ugetWitnessCpf,
        ugetWitnessEmail,
        eventWitnessName,
        eventWitnessCpf,
        eventWitnessEmail,

        responsibles,
        technical,
        sellerId,
        description,
        customMessage,
        title,
        codeNumber,
        email_description,
        email_title,
        banner_email_url,
        banner_email,

        banner,
        logo,
      } = values;

      const data = {
        closed: false,
        name,
        date: Timestamp.fromDate(new Date(date)),
        endDate: Timestamp.fromDate(new Date(endDate)),
        isConfirmed,
        customLink,

        types: {
          cashless,
          command,
          ticket,
          totem,
          hawker,
        },

        place: {
          placeName: placeName || null,
          street: street || null,
          number: number || null,
          neighborhood: neighborhood || null,
          city: city || null,
          state: state || null,
          zip_code: zip_code || null,
          complement: complement || null,
        },

        witnesses: {
          ugetWitnessName: ugetWitnessName || null,
          ugetWitnessCpf: ugetWitnessCpf || null,
          ugetWitnessEmail: ugetWitnessEmail || null,
          eventWitnessName: eventWitnessName || null,
          eventWitnessCpf: eventWitnessCpf || null,
          eventWitnessEmail: eventWitnessEmail || null,
        },
        crypt_key: randomString(10),

        contracts: {
          openSigned: false,
          openContract_url: null,
          closeSigned: false,
          closeContract_url: null,
        },

        responsibles,
        technical,
        sellerId,

        description: description || null,
        customMessage: customMessage || null,
        title: title || null,
        email_description: email_description || null,
        email_title: email_title || null,
        banner_email_url: banner_email_url || null,


        codeNumber: codeNumber || '',

        banner,
        banner_email,
        logo
      }

      if (eventSelected) {
        delete data.crypt_key
      }

      // console.log('data', data);

      const settings = settingMountSaveData(values)



      const onSuccess = () => {
        onShowAlert(PreAlert.success('Registro cadastrado com sucesso'))
        setIsClose();
      }
      const onSuccessUpdate = () => {
        onShowAlert(PreAlert.success('Registro atualizado com sucesso'))
        setIsClose();
      }
      const onError = (error) => {
        console.error(error);
        onShowAlert(PreAlert.error('Falhou ao cadastrar o registro'))
      }

      setIsLoading(true);
      if (eventSelected) {
        new EventDB(establishment.id)
          .update(eventSelected.id, data, settings)
          .then(onSuccessUpdate)
          .catch(onError)
          .finally(() => setIsLoading(false))
      } else {
        new EventDB(establishment.id)
          .create(data, settings)
          .then(onSuccess)
          .catch(onError)
          .finally(() => setIsLoading(false))
      }
    }
  });
  
  const handleImageHome = useCallback((e) => {
    const [file] = Array.from(e.target.files)
    formik.setValues({
      ...formik.values,
      imageHome: file,
      imageHome_url: URL.createObjectURL(file)
    })
  }, [formik.values])

  const handleImageBanner = useCallback((e) => {
    const [file] = Array.from(e.target.files)
    formik.setValues({
      ...formik.values,
      imageBanner: file,
      imageBanner_url: URL.createObjectURL(file)
    })
  }, [formik.values])

  const checkStepIsValid = (step) => {
    if (step === 'step1') {
      if (formik.values.name !== '' && formik.values.date !== '' && formik.values.endDate !== '') {
        return false;
      } else {
        return true;
      }
    }
    if (step === 'step2') {
      if (formik.values.placeName !== '' && formik.values.zip_code !== '' && formik.values.number !== '') {
        return false;
      } else {
        return true;
      }
    }
    if (step === 'step3') {

    }
    if (step === 'step4') {

    }
    if (step === 'step6') {

    }

    if (step === 'step7') {

    }
    if (step === 'step8') {

    }
    return false;
  }

  const handleImage = useCallback((e) => {
    const [file] = Array.from(e.target.files)
    formik.setFieldValue('banner', file)
    formik.setFieldValue('banner_url', URL.createObjectURL(file))
  }, [formik])

  const handleImageEmail = useCallback((e) => {
    const [file] = Array.from(e.target.files)
    formik.setValues({
      ...formik.values,
      banner_email: file,
      banner_email_url: URL.createObjectURL(file)
    })
  }, [formik])

  const [usersResponsiblesInit, usersTechnicalInit] = useMemo(() =>
    [
      eventSelected?.responsibles || [],
      eventSelected?.technical || [],
    ]
    , [eventSelected])

  const onChangeUsersCoor = useCallback((newUser, lastUser, index) => {
    formik.values.responsibles[index] = newUser
    formik.setFieldValue('responsibles', formik.values.responsibles.slice())
  }, [formik.values.responsibles])

  const onRemoveUsersCoor = useCallback((index) => {
    formik.values.responsibles.splice(index, 1);
    formik.setFieldValue('responsibles', formik.values.responsibles.slice())
  }, [formik.values.responsibles])

  const onChangeUsersTechnical = useCallback((newUser, lastUser, index) => {
    formik.values.technical[index] = newUser
    formik.setFieldValue('technical', formik.values.technical.slice())
  }, [formik.values.technical])

  const onRemoveUsersTechnical = useCallback((index) => {
    formik.values.technical.splice(index, 1);
    formik.setFieldValue('technical', formik.values.technical.slice())
  }, [formik.values.technical])

  const optionsUsersCoor = useMemo(() =>
    usersCoor.map(u => ({ value: u.id, label: u.name }))
    , [usersCoor])

  const optionsUsersSeller = useMemo(() =>
    usersSeller.map(u => ({ value: u.id, label: u.name }))
    , [usersSeller])

  useEffect(() => {
    if (formik.values.zip_code?.length === 9 && formik.values.zip_code !== '') {
      const handleCallbackCep = (cep) => {
        setloadingCep(true)
        apiViaCep(`${cep}/json`)
          .then(res => {
            if (res.data.erro) {
              showNotification('', 'CEP não encontrado', 'danger');
              // formik.setFieldValue('neighborhood', '');
              // formik.setFieldValue('street', '');
              // formik.setFieldValue('zip_code', '');
              // formik.setFieldValue('state', '');
              // formik.setFieldValue('city', '');
              // formik.setFieldValue('state_code', '');
            } else {
              showNotification('', 'CEP localizado', 'success');
              if (res.data.bairro) formik.setFieldValue('neighborhood', res.data.bairro);
              if (res.data.logradouro) formik.setFieldValue('street', res.data.logradouro);
              formik.setFieldValue('zip_code', res.data.cep);
              formik.setFieldValue('state', res.data.uf);
              formik.setFieldValue('city', res.data.localidade);
              formik.setFieldValue('state_code', res.data.ibge.substring(0, 2));
            }
          }).catch(err => {
            showNotification('', 'CEP não encontrado', 'danger');
          }).finally(res => {
            formik.setFieldValue('cepSearched', true);
            setloadingCep(false)
          });
      }

      let cep = formik.values.zip_code.replace('-', '');
      handleCallbackCep(cep);
    }
  }, [formik.values.zip_code]);

  useEffect(() => {
    console.log('responsibles', formik.values.responsibles);
  }, [formik.values.responsibles])

  return (
    <Modal
      id={'modal-register-stores'}
      titleId={'Cadastro de Evento'}
      isOpen={open}
      setIsOpen={setIsOpen}
      isStaticBackdrop={true}
      isScrollable={false}
      isCentered={true}
      size="lg" // 'sm' || 'lg' || 'xl' 
      isAnimation={true}
    // onSubmit={formik.handleSubmit}
    >
      <ModalHeader setIsOpen={setIsOpen}>
        <ModalTitle id="register-stores">Cadastro de Eventos</ModalTitle>
      </ModalHeader>
      <ModalBody>
        {/* <form noValidate onSubmit={formik.handleSubmit}> */}
        {/* Inputs */}
        <Wizard
          isHeader
          color='info'
          isLoading={isLoading}
          onSubmit={formik.handleSubmit}
          className='shadow-3d-info'>
          <WizardItem id='step1' title='Rótulo do Evento' required={checkStepIsValid('step1')} >
            <div className='row align-items-center justify-content-center'>
              <div className='row p-2'>
                <FormGroup id="name" label="Nome do Evento" className='col-md-6'>
                  <Input
                    ariaDescribedby='inputGroupPrepend'
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.name}
                    isValid={formik.isValid}
                    isTouched={formik.touched.name}
                    invalidFeedback={formik.errors.name}
                    validFeedback='Assim está bom!'
                    placeholder="Ex: Show do Pink Floyd"
                  />
                </FormGroup>
                <FormGroup id="sellerId" label="Vendedor" className='col-md-6'>
                  <Select
                    value={formik.values.sellerId}
                    list={optionsUsersSeller}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    isValid={formik.isValid}
                    invalidFeedback={formik.errors.sellerId}
                    isTouched={formik.touched.sellerId}
                    placeholder="Selecione o vendedor"
                  />
                </FormGroup>
              </div>

              <div className='row p-2'>
                <FormGroup id="date" label="Data Inicial" className='col-md-6'>
                  <Input
                    ariaDescribedby='inputGroupPrepend'
                    type='datetime-local'
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.date}
                    isValid={formik.isValid}
                    isTouched={formik.touched.date}
                    invalidFeedback={formik.errors.date}
                    validFeedback='Assim está bom!'
                    placeholder="Ex: 22/05/2023"
                  />
                </FormGroup>
                <FormGroup id="endDate" label="Data de Término" className='col-md-6'>
                  <Input
                    ariaDescribedby='inputGroupPrepend'
                    type='datetime-local'
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.endDate}
                    isValid={formik.isValid}
                    isTouched={formik.touched.endDate}
                    invalidFeedback={formik.errors.endDate}
                    validFeedback='Assim está bom!'
                    placeholder="Ex: 22/05/2023"
                  />
                </FormGroup>
              </div>

              <div className='row p-2'>
                <FormGroup id="isConfirmed" label="Confirmação" className='col-md-4'>
                  <Checks
                    type='switch'
                    label={formik.values.isConfirmed ? 'Confirmado' : 'Não Confirmado'}
                    name='isConfirmed'
                    onChange={formik.handleChange}
                    checked={formik.values.isConfirmed}
                  />
                </FormGroup>
                <FormGroup id="customLink" label="Link Personalizado" className='col-md-4'>
                  <Checks
                    type='switch'
                    label={formik.values.customLink ? 'Sim' : 'Não'}
                    name='customLink'
                    onChange={formik.handleChange}
                    checked={formik.values.customLink}
                  />
                </FormGroup> 


              </div>
              <div className='row p-2'>
                <FormGroup label="Aplicação" className='col-md-12'>
                  <div className='row p-2'>
                    <ChecksGroup
                      className='d-flex flex-row gap-4 justify-content-between'
                      isValid={formik.isValid}
                      isTouched={formik.touched.eventType}
                      invalidFeedback={formik.errors.eventType}
                    >
                      <Checks
                        type="switch"
                        id="cashless"
                        label='Cashless'
                        name="cashless"
                        onChange={formik.handleChange}
                        checked={formik.values.cashless}
                      />
                      <Checks
                        className=''
                        type="switch"
                        id="command"
                        label='Comanda'
                        name="command"
                        onChange={formik.handleChange}
                        checked={formik.values.command}
                      />
                      <Checks
                        type="switch"
                        id="ticket"
                        label='Ticket'
                        name="ticket"
                        onChange={formik.handleChange}
                        checked={formik.values.ticket}
                      />

                      <Checks
                        type="switch"
                        id="totem"
                        label='Totem'
                        name="totem"
                        onChange={formik.handleChange}
                        checked={formik.values.totem}
                      />
                      <Checks
                        type="switch"
                        id="hawker"
                        label='Hawker'
                        name="hawker"
                        onChange={formik.handleChange}
                        checked={formik.values.hawker}
                      />
                    </ChecksGroup>
                  </div>
                </FormGroup>
              </div>
              <div className='row mt-4'>
                <div className='col-md-6 gap-4'>
                  <FormGroup id="ugetWitnessName" label="Testemunhas da Uget" className='col-md-12'>
                    <Input
                      ariaDescribedby='inputGroupPrepend'
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.ugetWitnessName}
                      isValid={formik.isValid}
                      isTouched={formik.touched.ugetWitnessName}
                      invalidFeedback={formik.errors.ugetWitnessName}
                      validFeedback='Assim está bom!'
                      placeholder="Nome da Testemunha Uget"
                    />
                  </FormGroup>
                  <FormGroup id="ugetWitnessCpf" className='col-md-12  my-2'>
                    <Input
                      ariaDescribedby='inputGroupPrepend'
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={masks.cpf(formik.values.ugetWitnessCpf)}
                      isValid={formik.isValid}
                      isTouched={formik.touched.ugetWitnessCpf}
                      invalidFeedback={formik.errors.ugetWitnessCpf}
                      validFeedback='Assim está bom!'
                      placeholder="CPF da Testemunha Uget"
                    />
                  </FormGroup>
                  <FormGroup id="ugetWitnessEmail" className='col-md-12 '>
                    <InputGroup>
                      <InputGroupText id="inputGroupPrepend">
                        @
                      </InputGroupText>
                      <Input
                        id="ugetWitnessEmail"
                        ariaDescribedby='inputGroupPrepend'
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.ugetWitnessEmail}
                        isValid={formik.isValid}
                        isTouched={formik.touched.ugetWitnessEmail}
                        invalidFeedback={formik.errors.ugetWitnessEmail}
                        validFeedback='Assim está bom!'
                        placeholder="mariadasilva@email.com"
                      />
                    </InputGroup>
                  </FormGroup>
                </div>
                <div className='col-md-6'>
                  <FormGroup id="eventWitnessName" label="Testemunhas do Evento" className='col-md-12  '>
                    <Input
                      ariaDescribedby='inputGroupPrepend'
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.eventWitnessName}
                      isValid={formik.isValid}
                      isTouched={formik.touched.eventWitnessName}
                      invalidFeedback={formik.errors.eventWitnessName}
                      validFeedback='Assim está bom!'
                      placeholder="Nome da Testemunha do evento"
                    />
                  </FormGroup>
                  <FormGroup id="eventWitnessCpf" className='col-md-12  my-2'>
                    <Input
                      ariaDescribedby='inputGroupPrepend'
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={masks.cpf(formik.values.eventWitnessCpf)}
                      isValid={formik.isValid}
                      isTouched={formik.touched.eventWitnessCpf}
                      invalidFeedback={formik.errors.eventWitnessCpf}
                      validFeedback='Assim está bom!'
                      placeholder="CPF da Testemunha do evento"
                    />
                  </FormGroup>
                  <FormGroup id="eventWitnessEmail" className='col-md-12  '>
                    <InputGroup>
                      <InputGroupText id="inputGroupPrepend">
                        @
                      </InputGroupText>
                      <Input
                        id="eventWitnessEmail"
                        ariaDescribedby='inputGroupPrepend'
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.eventWitnessEmail}
                        isValid={formik.isValid}
                        isTouched={formik.touched.eventWitnessEmail}
                        invalidFeedback={formik.errors.eventWitnessEmail}
                        validFeedback='Assim está bom!'
                        placeholder="joaodasilva@email.com"
                      />
                    </InputGroup>
                  </FormGroup>
                </div>
                <div className='col-md-6 mt-4'>
                  <FormGroup id="codeNumber" label="Ordem de Serviço (Opcional)" className='col-md-12  '>
                    <Input
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.codeNumber}
                      isValid={formik.isValid}
                      isTouched={formik.touched.codeNumber}
                      invalidFeedback={formik.errors.codeNumber}
                      validFeedback='Assim está bom!'
                      placeholder="Ordem de serviço"
                    />
                  </FormGroup>
                </div>
              </div>
            </div>
          </WizardItem>

          <WizardItem id='step2' title='Local do Evento' required={checkStepIsValid('step2')} >
            <div className="row g-4">
              <FormGroup id="placeName" label="Nome do Local" className='col-md-8'>
                <Input
                  ariaDescribedby='inputGroupPrepend'
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.placeName}
                  isValid={formik.isValid}
                  isTouched={formik.touched.placeName}
                  invalidFeedback={formik.errors.placeName}
                  validFeedback='Assim está bom!'
                  placeholder="Ex: Show do Pink Floyd"
                />
              </FormGroup>
              <FormGroup
                id='zip_code'
                label='CEP'
                className='col-md-3'
              >
                <Input
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={masks.cep(formik.values.zip_code)}
                  isValid={formik.isValid}
                  isTouched={formik.touched.zip_code}
                  invalidFeedback={formik.errors.zip_code}
                  validFeedback='Assim está bom!'
                  placeholder='39100-000'
                />
              </FormGroup>

              <div className='col-md-1'>
                {loadingCep && (
                  <Spinner className='mt-4' />
                )}
              </div>

              {(formik.values.cepSearched) && (
                <FormGroup
                  id='state'
                  label='Estado'
                  className='col-md-2'
                >
                  <Input
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.state}
                    isTouched={formik.touched.state}
                    invalidFeedback={formik.errors.state}
                    disabled
                  />
                </FormGroup>
              )}

              {(formik.values.cepSearched) && (
                <FormGroup
                  id='city'
                  label='Cidade'
                  className='col-md-6'
                >
                  <Input
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.city}
                    isTouched={formik.touched.city}
                    invalidFeedback={formik.errors.city}
                    disabled
                  />
                </FormGroup>
              )}

              {(formik.values.cepSearched) && (
                <FormGroup
                  id='neighborhood'
                  label='Bairro'
                  className='col-md-4'
                >
                  <Input
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.neighborhood}
                    isTouched={formik.touched.neighborhood}
                    invalidFeedback={formik.errors.neighborhood}
                    placeholder='Ex: Centro'
                  />
                </FormGroup>
              )}

              {(formik.values.cepSearched) && (
                <FormGroup
                  id='street'
                  label='Logradouro'
                  className='col-md-6'
                >
                  <Input
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.street}
                    isTouched={formik.touched.street}
                    invalidFeedback={formik.errors.street}
                    placeholder='Ex: Rua Principal'
                  />
                </FormGroup>
              )}

              {(formik.values.cepSearched) && (
                <FormGroup
                  id='number'
                  label='Número'
                  className='col-md-2'
                >
                  <Input
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.number}
                    isTouched={formik.touched.number}
                    invalidFeedback={formik.errors.number}
                    placeholder='0'
                  />
                </FormGroup>
              )}

              {(formik.values.cepSearched) && (
                <FormGroup
                  id='complement'
                  label='Complemento'
                  className='col-md-4'
                >
                  <Input
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.complement}
                    isTouched={formik.touched.complement}
                    invalidFeedback={formik.errors.complement}
                    placeholder='Ex: Casa'
                  />
                </FormGroup>
              )}
            </div>
          </WizardItem>

          <WizardItem id='step3' title='Lider(s) Responsável(s)' required={checkStepIsValid('step3')} >
            <div className='col-md-12'>
              <UserAdd
                usersInit={usersResponsiblesInit}
                usersOptions={optionsUsersCoor}
                usersSelected={formik.values.responsibles}
                onChange={onChangeUsersCoor}
                onRemove={onRemoveUsersCoor}
              />
            </div>
          </WizardItem>

          <WizardItem id='step4' title='Técnicos' required={checkStepIsValid('step4')} >
            <div className='col-md-12'>
              <UserAdd
                usersInit={usersTechnicalInit}
                usersOptions={optionsUsersCoor}
                usersSelected={formik.values.technical}
                onChange={onChangeUsersTechnical}
                onRemove={onRemoveUsersTechnical}
              />
            </div>
          </WizardItem>

          <WizardItem id='step5' title='Configuração' required={checkStepIsValid('step5')} >
            <div className='col-md-12 d-grid gap-4'>
              <SettingsEvent formik={formik} />
            </div>
          </WizardItem>

          <WizardItem id='step6' title='Configuração 2' required={checkStepIsValid('step6')} >
            <div className="row p-2">
              <Card>
                <CardHeader>
                  <CardLabel icon='AppSettingsAlt' iconColor={darkModeStatus ? 'light' : 'dark'}>
                    <CardTitle>Configurações dos Totems Ticket</CardTitle>
                    <CardSubTitle>Preencha apenas para os casos de utilização dos totems ticket</CardSubTitle>
                  </CardLabel>
                </CardHeader>
                <CardBody>
                  <div className='row p-2'>
                    <div className='col-md-6'>
                      <div className='d-flex flex-row justify-content-center'>
                        <label>Image Home</label>
                      </div>
                      <AvatarVideo
                        id='imageHome_url'
                        value={formik.values.imageHome_url}
                        onChange={handleImageHome} />
                    </div>
                    <div className='col-md-6'>
                      <div className='d-flex flex-row justify-content-center'>
                        <label>Image Banner</label>
                      </div>
                      <AvatarVideo
                        id='imageBanner_url'
                        value={formik.values.imageBanner_url}
                        onChange={handleImageBanner} />
                    </div>
                  </div>

                  <div className="row mt-4  ">
                    <div className="col-md-6" >
                      <CardHeader className="p-0 py-4">
                        <CardLabel icon='ColorLens' iconColor='#000000'>
                          <CardTitle>Cores e tipografia</CardTitle>
                        </CardLabel>
                      </CardHeader>
                      <div className='row d-flex pt-4'>
                        <div className='row col-md-12 '>
                          <FormGroup id="backgroundColor" label="Cor Background" className='col-md-3' >
                            <Input
                              type='color'
                              ariaDescribedby='inputGroupPrepend'
                              onChange={formik.handleChange}
                              onBlur={formik.handleBlur}
                              value={formik.values.backgroundColor}
                              isValid={formik.isValid}
                              isTouched={formik.touched.backgroundColor}
                              invalidFeedback={formik.errors.backgroundColor}
                              validFeedback='Assim está bom!'
                              placeholder="Ex: #000000"
                            />
                          </FormGroup>
                          <FormGroup id="fontColor" label="Cor da Fonte" className='col-md-3' >
                            <Input
                              type='color'
                              ariaDescribedby='inputGroupPrepend'
                              onChange={formik.handleChange}
                              onBlur={formik.handleBlur}
                              value={formik.values.fontColor}
                              isValid={formik.isValid}
                              isTouched={formik.touched.fontColor}
                              invalidFeedback={formik.errors.fontColor}
                              validFeedback='Assim está bom!'
                              placeholder="Ex: #000000"
                            />
                          </FormGroup>

                          <FormGroup id="touchColor" label="Cor do toque" className='col-md-3'>
                            <Input
                              type='color'
                              ariaDescribedby='inputGroupPrepend'
                              onChange={formik.handleChange}
                              onBlur={formik.handleBlur}
                              value={formik.values.touchColor}
                              isValid={formik.isValid}
                              isTouched={formik.touched.touchColor}
                              invalidFeedback={formik.errors.touchColor}
                              validFeedback='Assim está bom!'
                              placeholder="Ex: #000000"
                            />
                          </FormGroup>
                        </div>
                        <FormGroup label="Tipo de fonte" className='p-3 '>
                          <ChecksGroup
                            isValid={formik.isValid}
                            isTouched={formik.touched.fontType}
                            invalidFeedback={formik.errors.fontType}
                            className='d-flex justify-content-lg-start '
                          >
                            <Checks
                              type="radio"
                              id="fontType"
                              label="Maúscula"
                              name="fontType"
                              value="UPPERCASE"
                              onChange={formik.handleChange}
                              checked={formik.values.fontType}
                              isInline

                            />
                            <Checks
                              type="radio"
                              id="fontType2"
                              label="Minúscula"
                              name="fontType"
                              value="LOWERCASE"
                              onChange={formik.handleChange}
                              checked={formik.values.fontType}
                              isInline

                            />
                            <Checks
                              type="radio"
                              id="fontType3"
                              label="Normal"
                              name="fontType"
                              value="NORMAL"
                              onChange={formik.handleChange}
                              checked={formik.values.fontType}
                              isInline


                            />
                          </ChecksGroup>
                        </FormGroup>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <CardHeader>
                        <CardLabel icon='CreditCard' iconColor='#000000'>
                          <CardTitle>Pagamento</CardTitle>
                        </CardLabel>
                      </CardHeader>
                      <div className='col-md-12 d-flex g-4 justify-content-lg-between p-4'>
                        <div className='row g-2'>

                          <div className='d-flex col-md-4 align-items-center justify-content-start  gap-3'>
                            <Checks
                              type="switch"
                              id="brandVisa"
                              name="brandVisa"
                              onChange={formik.handleChange}
                              checked={formik.values.brandVisa}
                            />
                            <img src={Visa} height={12} />
                          </div>

                          <div className='d-flex col-md-4 align-items-center justify-content-start gap-3'>
                            <Checks
                              type="switch"
                              id="brandMasterCard"
                              name="brandMasterCard"
                              onChange={formik.handleChange}
                              checked={formik.values.brandMasterCard}
                            />
                            <img src={MasterCard} height={25} />
                          </div>

                          <div className='d-flex col-md-4 align-items-center justify-content-start gap-3'>
                            <Checks
                              type="switch"
                              id="brandMaestro"
                              name="brandMaestro"
                              onChange={formik.handleChange}
                              checked={formik.values.brandMaestro}
                            />
                            <img src={Maestro} height={25} />
                          </div>

                          <div className='d-flex col-md-4 align-items-center justify-content-start gap-3'>
                            <Checks
                              type="switch"
                              id="brandElo"
                              name="brandElo"
                              onChange={formik.handleChange}
                              checked={formik.values.brandElo}
                            />
                            <img src={Elo} height={15} />
                          </div>

                          <div className='d-flex col-md-4 align-items-center justify-content-start gap-3'>
                            <Checks
                              type="switch"
                              id="brandHiperCard"
                              name="brandHiperCard"
                              onChange={formik.handleChange}
                              checked={formik.values.brandHiperCard}
                            />
                            <img src={HiperCard} height={15} />
                          </div>

                          <div className='d-flex col-md-4 align-items-center justify-content-start gap-3'>
                            <Checks
                              type="switch"
                              id="brandAlelo"
                              name="brandAlelo"
                              onChange={formik.handleChange}
                              checked={formik.values.brandAlelo}
                            />
                            <img src={Alelo} height={25} />
                          </div>

                          <div className='d-flex col-md-4 align-items-center justify-content-start gap-3'>
                            <Checks
                              type="switch"
                              id="brandSodexo"
                              name="brandSodexo"
                              onChange={formik.handleChange}
                              checked={formik.values.brandSodexo}
                            />
                            <img src={Sodexo} height={15} />
                          </div>

                          <div className='d-flex col-md-4 align-items-center justify-content-start gap-3'>
                            <Checks
                              type="switch"
                              id="brandCabal"
                              name="brandCabal"
                              onChange={formik.handleChange}
                              checked={formik.values.brandCabal}
                            />
                            <img src={Cabal} height={15} />
                          </div>
                          <div className='d-flex col-md-4 align-items-center justify-content-start gap-3'>
                            <Checks
                              type="switch"
                              id="brandDinersClub"
                              name="brandDinersClub"
                              onChange={formik.handleChange}
                              checked={formik.values.brandDinersClub}
                            />
                            <img src={DinersClub} height={20} />
                          </div>

                          <div className='d-flex col-md-4 align-items-center justify-content-start gap-3'>
                            <Checks
                              type="switch"
                              id="brandVr"
                              name="brandVr"
                              onChange={formik.handleChange}
                              checked={formik.values.brandVr}
                            />
                            <img src={ValeRF} height={35} />
                          </div>

                          <div className='d-flex col-md-4 align-items-center justify-content-start gap-3'>
                            <Checks
                              type="switch"
                              id="brandBanrisul"
                              name="brandBanrisul"
                              onChange={formik.handleChange}
                              checked={formik.values.brandBanrisul}
                            />
                            <img src={Banrisul} height={12} />
                          </div>

                          <div className='d-flex col-md-4 align-items-center justify-content-start gap-3'>
                            <Checks
                              type="switch"
                              id="brandAmericanExpress"
                              name="brandAmericanExpress"
                              onChange={formik.handleChange}
                              checked={formik.values.brandAmericanExpress}
                            />
                            <img src={AmericanExpress} height={50} />
                          </div>

                        </div>
                      </div>
                    </div>


                  </div>

                </CardBody>
              </Card>
            </div>
          </WizardItem>

          <WizardItem id='step7' title='Link Personalizado' required={checkStepIsValid('step7')}>

            {formik.values.customLink ? (
              <div className="row g-4">
                <FormGroup id="title" label="Título do anúncio" className='col-md-6'>
                  <Input
                    ariaDescribedby='inputGroupPrepend'
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.title}
                    isValid={formik.isValid}
                    isTouched={formik.touched.title}
                    invalidFeedback={formik.errors.title}
                    validFeedback='Assim está bom!'
                    placeholder="Show do Pink Floyd - 2021"
                  />
                </FormGroup>
                <div className='row my-3  '>

                  <div className='d-flex flex-row justify-content-center'>
                    <label>Banner do evento</label>
                  </div>
                  <Banner
                    id='banner'
                    value={formik.values.banner_url}
                    onChange={handleImage} />
                </div>

                <FormGroup id="description" label="Descrição do anúncio" className='col-md-12'>
                  <Textarea
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.description}
                    isValid={formik.isValid}
                    isTouched={formik.touched.description}
                    invalidFeedback={formik.errors.description}
                    validFeedback='Assim está bom!'
                    placeholder='Digite a descrição do evento aqui'
                    rows={5}
                  />
                </FormGroup>

                <FormGroup id="customMessage" label="Mensagem personalizada" className='col-md-12'>
                  <Textarea
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.customMessage}
                    isValid={formik.isValid}
                    isTouched={formik.touched.customMessage}
                    invalidFeedback={formik.errors.customMessage}
                    validFeedback='Assim está bom!'
                    placeholder='Digite a mensagem personalizada'
                    rows={3}
                  />
                </FormGroup>
              </div>
            ) : (
              <div className='row g-4 mt-1'>
                <h6 className='text-center'>Você deve marcar a opção <b className='text-primary'>"Publicar Uget Pass"</b> em <b className='text-primary'>"Rótulo do Evento"</b> para visualizar esta seção.</h6>
              </div>
            )}
          </WizardItem>

          <WizardItem id='step8' title='Link Personalizado' required={checkStepIsValid('step8')}>
            {formik.values.customLink ? (
              <div className="row my-1 ">
                <FormGroup id="email_title" label="Título do e-mail" className='row my-1 '>
                <Input
                    ariaDescribedby='inputGroupPrepend'
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.email_title}
                    isValid={formik.isValid}
                    isTouched={formik.touched.email_title}
                    invalidFeedback={formik.errors.email_title}
                    validFeedback='Assim está bom!'
                    placeholder="evento@evento.com"
                  />
                </FormGroup>

                <div className='row my-3  '>
                  <div className='d-flex flex-row justify-content-center'>
                    <label>Banner do e-mail</label>
                  </div>
                  <Banner
                    id='banner_email'
                    value={formik.values.banner_email_url}
                    onChange={handleImageEmail} />
                </div>

                <FormGroup id="email_description" label="Descrição do e-mail" className='col-md-12'>
                  <Textarea
                    ariaDescribedby='inputGroupPrepend'
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.email_description}
                    isValid={formik.isValid}
                    isTouched={formik.touched.email_description}
                    invalidFeedback={formik.errors.email_description}
                    validFeedback='Assim está bom!'
                    placeholder='Descrição do e-mail'
                  />
                </FormGroup>
              </div>
            ) : (
              <div className='row g-4 mt-1'>
                <h6 className='text-center'>Você deve marcar a opção <b className='text-primary'>"Publicar Uget Pass"</b> em <b className='text-primary'>"Rótulo do Evento"</b> para visualizar esta seção.</h6>
              </div>
            )}
          </WizardItem>
        </Wizard>

        {/* </form> */}
      </ModalBody>
    </Modal >
  )
}

export default memo(ModalRegisterEvents);