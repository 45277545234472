import { useFormik } from "formik";
import { memo, useCallback, useContext, useEffect, useState } from "react";
import Card, { CardBody, CardFooter, CardHeader, CardLabel, CardTitle } from "../../../../components/bootstrap/Card";
import FormGroup from "../../../../components/bootstrap/forms/FormGroup";
import Input from "../../../../components/bootstrap/forms/Input";
import BtnCancel from "../../../../components/MyCustom/BtnCancel";
import BtnConfirm from "../../../../components/MyCustom/BtnConfirm";
import BtnLoad from "../../../../components/MyCustom/BtnLoad";
import { DefaultContext } from "../../../../contexts/default";
import ConfigUgetDB from "../../../../database/wrappers/configUget";
import { unmask } from "../../../../helpers/utils/masks";
import Money from "../../../../helpers/utils/money/money";
import PreAlert from "../../../../helpers/utils/preAlert";
import { inputMoneyMask } from "../../../../utils/input";

const CustomOperacional = ({ darkModeStatus }) => {

  const { onShowAlert } = useContext(DefaultContext);

  const [loading, setloading] = useState(false)
  const [loadingGetData, setloadingGetData] = useState(false)

  const validate = useCallback((values) => {
    const errors = {};

    if(!values.daily_coordinator) {
      errors.daily_coordinator = 'Valor inválido'
    }
    if(!values.hours_coordinator) {
      errors.hours_coordinator = 'Valor inválido'
    }
    if(!values.daily_technical) {
      errors.daily_technical = 'Valor inválido'
    }
    if(!values.hours_technical) {
      errors.hours_technical = 'Valor inválido'
    }

    return errors;
  }, [])

  const formik = useFormik({
    initialValues: {
      daily_coordinator: '',
      hours_coordinator: '',
      daily_technical: '',
      hours_technical: '',
    },
    validate,
    onSubmit: values => {
      const {
        daily_coordinator,
        hours_coordinator,
        daily_technical,
        hours_technical,
      } = values;

      const data = {
        daily: {
          coordinator: Number(unmask(daily_coordinator)),
          technical: Number(unmask(daily_technical)),
        },
        hours: {
          coordinator: Number(unmask(hours_coordinator)),
          technical: Number(unmask(hours_technical)),
        },
      }

      const onSuccessUpdate = () => {
        onShowAlert(PreAlert.success('Registro atualizado com sucesso'))
      }
      const onError = (error) => {
        console.error(error);
        onShowAlert(PreAlert.error('Falhou ao cadastrar o registro'))
      }

      setloading(true);
      new ConfigUgetDB()
        .define('custoOperacional', data)
        .then(onSuccessUpdate)
        .catch(onError)
        .finally(() => setloading(false))
    }
  });

  const loadData = useCallback(() => {
    setloadingGetData(true)
    new ConfigUgetDB()
      .get('custoOperacional')
      .then(data => {
        if(!data) 
          return formik.resetForm();
        formik.setValues({
          daily_coordinator: Money.centsToCoin(data.daily.coordinator).toFixed(2),
          hours_coordinator: Money.centsToCoin(data.hours.coordinator).toFixed(2),
          daily_technical: Money.centsToCoin(data.daily.technical).toFixed(2),
          hours_technical: Money.centsToCoin(data.hours.technical).toFixed(2),
        })
      })
      .catch(error => {
        console.error(error)
        formik.resetForm();
      })
      .finally(() => setloadingGetData(false))
  },[])

  useEffect(loadData, [])

  const onChangeMoney = useCallback((e) => inputMoneyMask(formik, e), [])

  return (
    <Card>
      <CardHeader>
        <CardLabel icon='People' iconColor={darkModeStatus ? 'light' : 'dark'}>
          <CardTitle>Custo Operacional</CardTitle>
        </CardLabel>
      </CardHeader>
      <form onSubmit={formik.handleSubmit}>
      <CardBody>
        <div className="row ms-2">
          <div className="col-md-6">
            <h4>Coordenadores</h4>
            <FormGroup className='row' label="Diaria (10H)" labelClassName='col-md-2' isColForLabel>
              <div className="col-md-3">
              <Input
                id="daily_coordinator"
                onChange={onChangeMoney}
                onBlur={formik.handleBlur}
                value={formik.values.daily_coordinator}
                isValid={formik.isValid}
                isTouched={formik.touched.daily_coordinator}
                invalidFeedback={formik.errors.daily_coordinator}
                validFeedback='Assim está bom!'
                placeholder='R$ 0.00'
              />
              </div>
            </FormGroup>
            <FormGroup className='row mt-2' label="Hora Extra" labelClassName='col-md-2' isColForLabel>
              <div className="col-md-3">
              <Input
                id="hours_coordinator"
                onChange={onChangeMoney}
                onBlur={formik.handleBlur}
                value={formik.values.hours_coordinator}
                isValid={formik.isValid}
                isTouched={formik.touched.hours_coordinator}
                invalidFeedback={formik.errors.hours_coordinator}
                validFeedback='Assim está bom!'
                placeholder='R$ 0.00'
              />
              </div>
            </FormGroup>
          </div>

          <div className="col-md-6">
            <h4>Técnicos</h4>
            <FormGroup className='row' label="Diaria (10H)" labelClassName='col-md-2' isColForLabel>
              <div className="col-md-3">
              <Input
                id="daily_technical"
                onChange={onChangeMoney}
                onBlur={formik.handleBlur}
                value={formik.values.daily_technical}
                isValid={formik.isValid}
                isTouched={formik.touched.daily_technical}
                invalidFeedback={formik.errors.daily_technical}
                validFeedback='Assim está bom!'
                placeholder='R$ 0.00'
              />
              </div>
            </FormGroup>
            <FormGroup className='row mt-2' label="Hora Extra" labelClassName='col-md-2' isColForLabel>
              <div className="col-md-3">
              <Input
                id="hours_technical"
                onChange={onChangeMoney}
                onBlur={formik.handleBlur}
                value={formik.values.hours_technical}
                isValid={formik.isValid}
                isTouched={formik.touched.hours_technical}
                invalidFeedback={formik.errors.hours_technical}
                validFeedback='Assim está bom!'
                placeholder='R$ 0.00'
              />
              </div>
            </FormGroup>
          </div>
        </div>
      </CardBody>
      <CardFooter>
        <div>
          {!loadingGetData && <BtnCancel setIsClose={loadData} className='mx-4' />}
          {loading ? (
            <BtnLoad className='mx-4' />
          ) : (
            <BtnConfirm className='mx-4' isDisable={!formik.isValid} />
          )}
        </div>
      </CardFooter>
      </form>
    </Card>
  );
}

export default memo(CustomOperacional)