import axios from 'axios'
import { useEffect, useState } from 'react'


export default function useProducts(query) {
    const [products, setProducts] = useState([])
    const [isLoading, setIsLoading] = useState(false)
    const [error, setError] = useState('')
    const [searchQuery, setSearchQuery] = useState()

    useEffect(() => {
        const id = setTimeout(() => {
            setSearchQuery(query)
        }, 500)

        return () => {
            clearTimeout(id)
        }
    }, [query])


    useEffect(function () {
        if(!process.env.REACT_APP_API_PRODUCTS_URL || !process.env.REACT_APP_API_PRODUCTS_TOKEN) return

        
        const source = axios.CancelToken.source()

        async function fetchProducts() {
            try {
                setIsLoading(true)
                setError('')
                if (!searchQuery) return setProducts([])
                const URL = `${process.env.REACT_APP_API_PRODUCTS_URL}${searchQuery}`

                const headers = {
                    Authorization:
                        `Bearer ${process.env.REACT_APP_API_PRODUCTS_TOKEN}`,
                        'Content-Type': 'application/json',
                }

                const response = await axios.get(URL, {
                    headers,
                })

                if (response.status !== 200) {
                    throw new Error('Something went wrong')
                }

                const data = response.data

                
                setProducts(data)
                setError('')
            } catch (error) {
                if (axios.isCancel(error)) {
                    console.log(error)
                } else {
                    if (error.name !== 'AbortError') {
                        setError(error.message)
                    }
                    console.log(error.message)
                }
            } finally {
                setIsLoading(false)
                
            }
        }
        
        fetchProducts()

        return function () {
          source.cancel()
        }

    }, [searchQuery])

    return { products, isLoading, error }
}
