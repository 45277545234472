import React from 'react';
import { authPages, financialMenu, demoPages, calendarMenu, othersMenu, topMenu } from '../menu';
import DashboardHeader from '../pages/common/Headers/DashboardHeader';

const headers = [
  // { path: layoutMenu.pageLayout.subMenu.onlySubheader.path, element: null, exact: true },
  // { path: layoutMenu.pageLayout.subMenu.onlyContent.path, element: null, exact: true },
  // { path: layoutMenu.blank.path, element: null, exact: true },
  { path: authPages.login.path, element: null, exact: true },
  { path: authPages.signUp.path, element: null, exact: true },
  { path: authPages.page404.path, element: null, exact: true },
  { path: othersMenu.operatorAutoRegister.path, element: null, exact: true },
  { path: demoPages.reports.subMenu.filtroPorData.path, element: <DashboardHeader/>, exact: true },
  { path: calendarMenu.calendar.path, element: <DashboardHeader />, exact: true },
  { path: financialMenu.financial.path, element: <DashboardHeader />, exact: true },
  ...Object.keys(topMenu).map(key => ({
    path: topMenu[key].path, element: <DashboardHeader />, exact: true
  })),
  ...Object.keys(demoPages.pageLayout.subMenu).map(key => ({
    path: demoPages.pageLayout.subMenu[key].path, element: <DashboardHeader />, exact: true
  })),
  ...Object.keys(demoPages.establishment.subMenu).map(key => ({
    path: demoPages.establishment.subMenu[key].path, element: <DashboardHeader />, exact: true
  })),
  ...Object.keys(demoPages.event.subMenu).map(key => ({
    path: demoPages.event.subMenu[key].path, element: <DashboardHeader />, exact: true
  })),
  ...Object.keys(demoPages.pdvMenu.subMenu).map(key => ({
    path: demoPages.pdvMenu.subMenu[key].path, element: <DashboardHeader />, exact: true
  })),
  ...Object.keys(demoPages.sector.subMenu).map(key => ({
    path: demoPages.sector.subMenu[key].path, element: <DashboardHeader />, exact: true
  })),
  // { path: demoPages.notifications.path, element: <DashboardHeader />, exact: true },
  // { path: demoPages.contactUs.path, element: <DashboardHeader />, exact: true },
  { path: demoPages.defect.path, element: <DashboardHeader />, exact: true },
  // { path: financialMenu.cashier.path, element: <DashboardHeader />, exact: true },
  // { path: demoPages.profile.path, element: <DashboardHeader />, exact: true },
  // { path: demoPages.settings.path, element: <DashboardHeader />, exact: true },
  // { path: demoPages.reports.path, element: <DashboardHeader />, exact: true },
  // { path: demoPages.documentations.path, element: <DashboardHeader />, exact: true },
  { path: demoPages.configUget.path, element: <DashboardHeader />, exact: true },
  { path: demoPages.eventStatus.path, element: <DashboardHeader />, exact: true },
  
  /*{
    path: `*`,
    element: <DefaultHeader />,
  },*/
];

export default headers;
