import React, { useMemo } from "react";
import Money from "../../../helpers/utils/money/money";
import TableReport from "../TableReport";

const EquipamentTable = ({ equipaments, equipamentsValues }) => {

  const bobines = equipaments?.find((item) => item.key === 'bobines')




  const columns = useMemo(() => ([
    {
      label: 'Nome',
      field: 'equipment',
    },
    {
      label: 'Diária',
      field: 'value',
      className: 'table-not-break-line-white-space',
      format: row => Money.centsToMaskMoney(row.value),
    },
    {
      label: 'Enviados',
      field: 'qtdSent',
    },
    {
      label: 'Recebidos',
      field: 'qtdReceived',
    },
    {
      label: 'Perdidos',
      field: 'qtd_loss',
      // format: row => row.key === "bobines" ? '0' : row.qtd_loss,
    },
    {
      label: 'VL Perda',
      field: 'value_loss',
      className: 'table-not-break-line-white-space',
      format: row => row.value_loss === undefined ? '' : Money.centsToMaskMoney(row.value_loss),
    },
    {
      label: 'Total Diária',
      field: '{row}',
      format: row => Money.centsToMaskMoney(row.value * row.qtdSent),

    },
    {
      label: 'Total Perdidos',
      field: '{row}',
      format: row => Money.centsToMaskMoney(row.value_loss * row.qtd_loss),
    },

    {
      label: 'Total',
      field: 'total',
      className: 'table-not-break-line-white-space',
      format: row => Money.centsToMaskMoney(row.total),
    },
  ]), [])

  const equipamentsRender = useMemo(() => {
    return equipaments?.filter(e =>
      e.qtdSent > 0 ||
      e.qtdReceived > 0 ||
      e.qtd_loss > 0
    ) || [];
  }, [equipaments])

  if (!equipamentsValues) return null;

  const { qtdSent, qtdReceived, total_rent, total_loss } = equipamentsValues;

  console.log()
  return (
    <div className='mt-4 pagebreak'>
      <TableReport
        title='EQUIPAMENTOS'
        rows={equipamentsRender}
        columns={columns}
        keyExtractor={row => row.id}
      />

      <table className='table table-striped table-fs-litle'>
        <tbody>
          <tr>
            <td>EQUIPAMENTOS E UTENSÍLIOS</td>
            <td>QUANTIDADE: {qtdSent}</td>
            <td className="text-tb-align-right">{Money.centsToMaskMoney(total_rent)}</td>
          </tr>

          <tr>
            <td>EQUIPAMENTOS PERDIDOS (Exceto bobinas)</td>
            <td>QUANTIDADE: {qtdSent - qtdReceived - bobines?.qtd_loss}</td>
            <td className="text-tb-align-right">{Money.centsToMaskMoney(total_loss)}</td>
          </tr>
          <tr className="fw-bold">
            <td >TOTAL</td>
            <td></td>
            <td className="text-tb-align-right ">{Money.centsToMaskMoney(total_loss + total_rent)}</td>

          </tr>

          {/* <tr style={{ backgroundColor: '#fbd4e5' }}>
            <td>COORDENADORES</td>
            <td>QUANTIDADE: {coordinators.qtdSent}</td>
            <td className="text-tb-align-right">{Money.centsToMaskMoney(coordinators.total_rent)}</td>
          </tr>
          <tr style={{ backgroundColor: '#fbd4e5' }}>
            <td>TÉCNICOS</td>
            <td>QUANTIDADE: {technicals.qtdSent}</td>
            <td className="text-tb-align-right">{Money.centsToMaskMoney(technicals.total_rent)}</td>
          </tr> */}
        </tbody>
      </table>
    </div>
  );
}

export default EquipamentTable;