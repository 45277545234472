import React, { useContext, memo } from 'react';
import Modal, { ModalBody, ModalHeader, ModalTitle } from '../../bootstrap/Modal';
import { QRCodeSVG } from 'qrcode.react';
import { DefaultContext } from '../../../contexts/default';
import dateFormat from 'dateformat';

const ModalQrcodeOperators = ({ open, setIsOpen, qrcodeData, operatorSelected, usersOperatorsDicionary }) => {

  const { establishment, event } = useContext(DefaultContext)

  return (
    <Modal
      id={'modal-qrcode-operators'}
      titleId={'Login com Qrcode'}
      isOpen={open}
      setIsOpen={setIsOpen}
      isStaticBackdrop={true}
      isScrollable={false}
      isCentered={true}
      size="lg" // 'sm' || 'lg' || 'xl' 
      isAnimation={true}
    >
      <ModalHeader setIsOpen={setIsOpen}>
        <ModalTitle id="qrcode-operators">Login com Qrcode</ModalTitle>
      </ModalHeader>
      <ModalBody>
        <div className='d-flex flex-column flex-row align-items-center justify-content-between'>
          <h5><b style={{ marginRight: 3 }}>Estabelecimento: </b>{establishment?.name}</h5>
          <h5><b style={{ marginRight: 3 }}>Evento: </b>{event?.name}</h5>
          <h5><b style={{ marginRight: 3 }}>Data do Evento: </b>{dateFormat(event?.created_at.toDate() || new Date(), 'dd/mm/yyyy')}</h5>
          <h5><b style={{ marginRight: 3 }}>Operador: </b>{usersOperatorsDicionary[operatorSelected?.id]?.name}</h5>
          <div style={{ padding: 10, backgroundColor: 'white' }}>
            <QRCodeSVG
              value={qrcodeData?.qrcode || 'Sem dados'}
              size={150}
            />
          </div>
          <span>Altere para o modo ligth ao escanear o QR Code</span>
        </div>
      </ModalBody>
    </Modal>
  )
}

export default memo(ModalQrcodeOperators);