import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import PageWrapper from '../../../../layout/PageWrapper/PageWrapper';
import SubHeader, { SubHeaderLeft, SubHeaderRight } from '../../../../layout/SubHeader/SubHeader';
import Page from '../../../../layout/Page/Page';
import Breadcrumb from '../../../../components/bootstrap/Breadcrumb';
import Button from '../../../../components/bootstrap/Button';
import Card, { CardBody, CardHeader, CardLabel, CardTitle } from '../../../../components/bootstrap/Card';
import { demoPages } from '../../../../menu';
import ModalRegisterEvents from '../../../../components/modals/Events/ModalRegister';
import ModalGeral from '../../../../components/modals/Events/ModalGeral';
import ModalOs from '../../../../components/modals/Events/ModalOs';
import TableCustom from '../../../../components/MyCustom/TableCustom';
import dateFormat from 'dateformat';
import showNotification from '../../../../components/extras/showNotification';
import EventDB from '../../../../database/wrappers/event';
import { DefaultContext } from '../../../../contexts/default';
import SelectEstablishment from '../../../../components/MyCustom/SelectEstablishment';
import { orderBy, where } from 'firebase/firestore';
import notificationBody from '../../../../helpers/utils/functions/notificationBody';
import EventsType from '../../../../components/MyCustom/EventsType';
import EventNumberDB from '../../../../database/wrappers/eventNumber';
import useDarkMode from '../../../../hooks/useDarkMode';
import ModalCloneEvent from '../../../../components/modals/Events/ModalCloneEvent';
import ModalClosure from '../../../../components/modals/Events/ModalClosure';
import UserDB from '../../../../database/wrappers/user';
import { BASIC_ROLE } from '../../../../types/roles';
import convertArrayToObject from '../../../../utils/convertArrayToObject';
import ModalQrcode from '../../../../components/modals/Events/modalQrcode';
import { returnTrueType } from '../../../../utils/format/exportFormat';

const Event = () => {
  const { darkModeStatus } = useDarkMode();

  const { onShowQuestion, estabSelected } = useContext(DefaultContext)

  const [openModalClosure, setOpenModalClosure] = useState(false);
  const [openModalRegister, setOpenModalRegister] = useState(false);
  const [openModalClonar, setOpenModalClonar] = useState(false);
  const [openModalGeral, setOpenModalGeral] = useState(false);
  const [openModalOs, setOpenModalOs] = useState(false);
  const [usersSeller, setusersSeller] = useState([])
  const [usersCoor, setusersCoor] = useState([])
  const [usersCoorDicionary, setusersCoorDicionary] = useState({})

  const [events, setEvents] = useState([])
  const [eventNumbers, seteventNumbers] = useState({})
  const [eventSelected, setEventSelected] = useState(null)
  const [openModalQrcode, setOpenModalQrcode] = useState(false);
  const [qrcodeSelected, setqrcodeSelected] = useState(null)

  // console.log('eventSelected', eventSelected);

  

  useEffect(() => {
    const sort = (a, b) => {
      if (a.name.toLowerCase() > b.name.toLowerCase())
        return 1;
      return -1;
    }
    return new UserDB().on(datas => {
      datas.sort(sort)
      const usersCoor = datas.filter(u => u.role === BASIC_ROLE.COORDINATOR || u.role === BASIC_ROLE.ADMIN)
      const usersSeller = datas.filter(u => u.role === BASIC_ROLE.SELLER)
      setusersSeller(usersSeller)
      setusersCoor(usersCoor)
      setusersCoorDicionary(convertArrayToObject(usersCoor, 'id'))
    }, where('role', 'in', [BASIC_ROLE.COORDINATOR, BASIC_ROLE.ADMIN, BASIC_ROLE.SELLER]))
  }, [])

  useEffect(() => {
    if (!estabSelected) return;
    const onSubscribe1 = new EventDB(estabSelected).on(setEvents, orderBy('created_at', 'desc'))
    const onSubscribe2 = new EventNumberDB().on(datas => {
      const dicionary = {};
      datas.forEach(item => dicionary[item.eventId] = item)
      seteventNumbers(dicionary)
    }, where('estabId', '==', estabSelected))

    return () => {
      onSubscribe1();
      onSubscribe2();
    }
  }, [estabSelected])

  const onClickCloneEvent = useCallback(() => {
    setOpenModalClonar(true)
  }, [])

  const onCloseCloneEventModal = useCallback(() => {
    setOpenModalClonar(false)
  }, [])

  const handleOpenModalRegister = useCallback(() => {
    setEventSelected(null);
    setOpenModalRegister(true);
  }, [])

  const handleCloseModalRegister = useCallback(() => {
    setOpenModalRegister(false);
  }, [])

  const handleOpenModalEdit = useCallback((item) => {
    setEventSelected(item);
    setOpenModalRegister(true)
  }, [])

  const handleOpenModalGeral = useCallback((item) => {
    setEventSelected(item);
    setOpenModalGeral(true)
  }, [])

  const handleCloseModalGeral = useCallback(() => {
    setOpenModalGeral(false);
  }, [])

  const handleOpenModalOs = useCallback((item) => {
    setEventSelected(item);
    setOpenModalOs(true)
  }, [])

  const handleCloseModalOs = useCallback(() => {
    setOpenModalOs(false);
  }, [])


  const handleOpenModalDelete = useCallback((item) => {
    onShowQuestion({
      title: 'Excluir Evento',
      message: `Deseja realmente excluir o evento  ${item.name} ?`,
      onConfirm: () => {
        new EventDB(estabSelected)
          .delete(item.id)
          .then(() => showNotification('', notificationBody('Registro deletado com sucesso'), 'success'))
          .catch(() => showNotification('', notificationBody('Falhou ao deletar o registro'), 'danger'))
      }
    })
  }, [estabSelected])

  const handleOpenModalClosure = useCallback((item) => {
    setEventSelected(item);
    setOpenModalClosure(true);
  }, [])

  const handleCloseModalClosure = useCallback(() => {
    setOpenModalClosure(false);
  }, [])

  const handleChangeItem = useCallback((id, key, value) => {
    new EventDB(estabSelected)
      .update(id, {
        [key]: value
      })
      .then(() => showNotification('', notificationBody('Registro alterado com sucesso'), 'success'))
      .catch(() => showNotification('', notificationBody('Falhou ao atualizar o registro'), 'danger'))
  }, [estabSelected])

  const onCloseEvent = useCallback((row) => {
    onShowQuestion({
      title: 'Fechar Evento',
      message: `Tem certeza que deseja fechar o evento  ${row.name} ?`,
      onConfirm: () => {
        new EventDB(estabSelected)
          .update(row.id, {
            closed: true
          })
          .then(() => showNotification('', notificationBody('Evento fechado com sucesso'), 'success'))
          .catch(() => showNotification('', notificationBody('Falhou ao fechar o evento'), 'danger'))
      }
    })
  }, [estabSelected])

  const onChangeConfirmation = useCallback((row) => {
    onShowQuestion({
      title: 'Alterar Confirmação',
      message: `Alterar o evento  ${row.name}, para o status '${!row.isConfirmed ? 'Confirmado' : 'Não Confirmado'}'?`,
      onConfirm: () => {
        new EventDB(estabSelected)
          .update(row.id, {
            isConfirmed: !row.isConfirmed
          })
          .then(() => showNotification('', notificationBody('Evento fechado com sucesso'), 'success'))
          .catch(() => showNotification('', notificationBody('Falhou ao fechar o evento'), 'danger'))
      }
    })
  }, [estabSelected])

  const handleChangeRequestRegister = useCallback((eventNumber) => {
    console.log(eventNumber)
    const { id, key } = eventNumber;
    navigator.clipboard.writeText(`${window.location.host}/cadastro-operador/?eventNumber=${id}&key=${key}`)
    showNotification('LINK COPIADO', null, 'default', {
      container: 'bottom-center',
    })
  }, [])

  const handleShowQrcode = useCallback((eventNumber, item) => {
    const { id, key } = eventNumber;
    navigator.clipboard.writeText(`${window.location.host}/cadastro-operador/?eventNumber=${id}&key=${key}`)
    setEventSelected(item);
    setqrcodeSelected(eventNumber);
    setOpenModalQrcode(true);
  }, [])


  const columns = useMemo(() => ([
    {
      label: 'Link',
      field: 'link',
      format: row => (
        <div>
          {eventNumbers[row.id]?.key &&
            <Button
              className='btn-tablecustom-row'
              color={darkModeStatus ? 'light' : 'dark'}
              icon="InsertLink"
              shadow="sm"
              hoverShadow="sm"
              size="sm"
              onClick={() => handleChangeRequestRegister(eventNumbers[row.id])}
            />}
        </div>
      ),
      noExport:true,
    },
    {
      label: 'QRcode',
      field: 'qrcode',
      format: row => (
        <div>
          {eventNumbers[row.id]?.key &&
            <Button
              className='btn-tablecustom-row'
              color={darkModeStatus ? 'light' : 'dark'}
              icon="QrCodeScanner"
              shadow="sm"
              hoverShadow="sm"
              size="sm"
              onClick={() => handleShowQrcode(eventNumbers[row.id], row)}
            />}
        </div>
      ),
      noExport: true,
    },
    {
      label: 'Data',
      field: 'date',
      format: row => typeof row.date === 'string' ? `${row.date.substring(8, 10)}/${row.date.substring(5, 7)}/${row.date.substring(0, 4)}`
        : dateFormat(row.date.toDate(), 'dd/mm/yyyy'),
      formatExport: value => typeof value === 'string' ? `${value.substring(8, 10)}/${value.substring(5, 7)}/${value.substring(0, 4)}`
        : dateFormat(value.toDate(), 'dd/mm/yyyy'),
    },
    {
      label: 'Nome',
      field: 'name',
    },
    {
      label: 'Cod.',
      field: 'codeNumber',
    },
    {
      label: 'Tipo',
      field: 'types',
      format: row => <EventsType types={row.types}/>,
      formatExport: value => returnTrueType(value)
    },
    {
      label: 'Chave',
      field: 'id',
      format: row => eventNumbers[row.id]?.id || 'Processando',
      formatExport: value => eventNumbers[value]?.id || 'Processando',
    },
    {
      label: 'Confirmado',
      field: 'isConfirmed',
      format: row => (
        <Button
          isLink
          color={row.isConfirmed ? 'success' : 'danger'}
          icon={row.isConfirmed ? 'Check' : 'Error'}
          className='text-nowrap'
          onClick={() => onChangeConfirmation(row)}
        >
          {row.isConfirmed ? 'Sim' : 'Não'}
        </Button>
      ),
      formatExport: value => !value
    },
    {
      label: 'Aberto',
      field: 'closed',
      format: row => (
        <Button
          isLink
          color={!row.closed ? 'success' : 'danger'}
          icon={row.closed ? 'Lock' : 'Check'}
          className='text-nowrap'
          onClick={() => onCloseEvent(row)}
        >
          {!row.closed ? 'Aberto' : 'Fechado'}
        </Button>
      ),
      formatExport: value => !value
    },
    {
      label: 'Ações',
      field: 'actions',
      format: row => (
        <div className='w-75 d-flex justify-content-between'>
          <Button
            color={darkModeStatus ? 'light' : 'dark'}
            icon="edit"
            shadow="sm"
            hoverShadow="sm"
            size="sm"
            onClick={() => handleOpenModalEdit(row)}
          />
          <Button
            color={darkModeStatus ? 'light' : 'dark'}
            icon="SettingsEthernet"
            shadow="sm"
            hoverShadow="sm"
            size="sm"
            onClick={() => handleOpenModalGeral(row)}
          />
          <Button
            color={darkModeStatus ? 'light' : 'dark'}
            icon="AdminPanelSettings"
            shadow="sm"
            hoverShadow="sm"
            size="sm"
            onClick={() => handleOpenModalOs(row)}
          />
          <Button
            color="danger"
            icon="Close"
            shadow="sm"
            hoverShadow="sm"
            size="sm"
            // onClick={() => handleOpenModalDelete(row)}
            onClick={() => handleOpenModalClosure(row)}
          />
        </div>
      ),
      noExport: true
    },
  ]), [darkModeStatus, handleOpenModalDelete, handleChangeItem, eventNumbers])


  return (
    <>
      <PageWrapper title={demoPages.establishment.subMenu.event.text}>
        <SubHeader>
          <SubHeaderLeft>
            <Breadcrumb
              list={[
                { title: 'Cadastros', to: '/estab/event' },
                { title: 'Estabelecimento', to: '/estab/event' },
              ]}
            />
          </SubHeaderLeft>
          <SubHeaderRight>
            <Button
              className='text-dark'
              type='file'
              color='warning'
              icon='PublishedWithChanges'
              shadow="sm"
              hoverShadow="sm"
              onClick={onClickCloneEvent}
            >
              Clonar Evento
            </Button>
            <Button
              color='primary'
              icon='plus'
              shadow="sm"
              hoverShadow="sm"
              onClick={handleOpenModalRegister}
            >
              Cadastrar
            </Button>
          </SubHeaderRight>
        </SubHeader>
        <Page container='fluid'>
          <Card>
            <CardHeader>
              <CardLabel icon='Festival' iconColor={darkModeStatus ? 'light' : 'dark'}>
                <CardTitle>Eventos</CardTitle>
              </CardLabel>
            </CardHeader>
            <CardBody className='table-responsive'>
              <SelectEstablishment />

              <TableCustom
                columns={columns}
                rows={events}
                keyExtractor={item => item.id}
                fileName="eventos"
              />
            </CardBody>
          </Card>
        </Page>
      </PageWrapper>

      <ModalRegisterEvents
        open={openModalRegister}
        setIsOpen={setOpenModalRegister}
        setIsClose={handleCloseModalRegister}
        eventSelected={eventSelected}
        usersCoor={usersCoor}
        usersSeller={usersSeller}
      />
      <ModalGeral
        open={openModalGeral}
        setIsOpen={setOpenModalGeral}
        setIsClose={handleCloseModalGeral}
        eventSelected={eventSelected}
      />
      <ModalOs
        open={openModalOs}
        setIsOpen={setOpenModalOs}
        setIsClose={handleCloseModalOs}
        eventSelected={eventSelected}
      />
      <ModalClosure
        open={openModalClosure}
        setIsOpen={setOpenModalClosure}
        setIsClose={handleCloseModalClosure}
        eventSelected={eventSelected}
      />
      <ModalCloneEvent
        open={openModalClonar}
        setIsOpen={onCloseCloneEventModal}
        setIsClose={onCloseCloneEventModal}
        eventSelected={eventSelected}
        usersCoor={usersCoor}
        usersSeller={usersSeller}
      />
      <ModalQrcode
        open={openModalQrcode}
        setIsOpen={setOpenModalQrcode}
        eventselect={eventSelected}
        qrcodeData={qrcodeSelected}
      />
    </>
  );
};

export default Event;
