import React, { useState, useEffect, useContext, memo, useCallback, useMemo } from 'react';
import { useFormik } from 'formik';
import FormGroup from '../../bootstrap/forms/FormGroup';
import Input from '../../bootstrap/forms/Input';
import Label from '../../bootstrap/forms/Label';
import Modal, { ModalBody, ModalHeader, ModalTitle } from '../../bootstrap/Modal';
import masks, { unmask } from '../../../helpers/utils/masks';
import { DefaultContext } from '../../../contexts/default';
import PreAlert from '../../../helpers/utils/preAlert';
import BtnCancel from '../../MyCustom/BtnCancel';
import BtnLoad from '../../MyCustom/BtnLoad';
import BtnConfirm from '../../MyCustom/BtnConfirm';
import { inputMoneyMask, inputOnlyNumbers, inputOnlyNumbersNoStitch } from '../../../utils/input';
import EventGeralDB from '../../../database/wrappers/eventGeral';
import Button from '../../bootstrap/Button';
import RelatorioOs from './RelatorioOs';
import d4signOpen from '../../../services/d4signEvent';
import ModalEventoContrato from '../EventContract/modalEvent';
import ConfigUgetDB from '../../../database/wrappers/configUget';

const ModalOs = ({ open, setIsOpen, setIsClose, eventSelected }) => {

  const { estabSelected, onShowAlert, establishment, event } = useContext(DefaultContext);

  const [docExists, setdocExists] = useState(false);
  const [resourcesExists, setresourcesExists] = useState(false);
  const [openRelatorio, setOpenRelatorio] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [osData, setOsData] = useState(null);
  const [openModalEvent, setOpenModalEvent] = useState(false);
  const [configUget, setConfigUget] = useState(null)




  useEffect(() => {
    if (!open) return resetForm();
    if (eventSelected) {
  
      new ConfigUgetDB()
        .getAll()
        .then((data)=>{
          setConfigUget(data[1].daily)
      })
      new EventGeralDB(estabSelected)
        .get(eventSelected.id)
        .then(data => {
          console.log(data);
          setOsData(data);
          setdocExists(!!data)
          if (!data) return resetForm();
          const { resources } = data;
          setresourcesExists(!!resources)
          if (!resources) return;
          formik.setValues({
            pos: resources.pos.toString(),
            pos_backup: resources.pos_backup?.toString() || '',
            totems: resources.totems.toString(),
            totems_recharges: resources.totems_recharges.toString(),
            coordinators: resources.coordinators.toString(),
            technical: resources.technical.toString(),
            cards: resources.cards.toString(),
            cards_adm: resources.cards_adm.toString(),
            bracelets: resources.bracelets.toString(),
            bobines: resources.bobines.toString(),
            totems_cables: resources.totems_cables.toString(),
            totems_cuffs: resources.totems_cuffs.toString(),
            totems_pedestal: resources.totems_pedestal.toString(),
            totems_cover: resources.totems_cover.toString(),
            extension: resources.extension.toString(),
            ruler: resources.ruler.toString(),
            kit_cordao: resources.kit_cordao.toString(),
            pouch: resources.pouch.toString(),
            tablet: resources.tablet.toString(),
            tablet_power: resources.tablet_power.toString(),
            pos_power: resources.pos_power.toString(),
            transport_box: resources.transport_box.toString(),
            router_wifi: resources.router_wifi.toString(),
            processor_wifi: resources.processor_wifi.toString(),
            tripod_wifi: resources.tripod_wifi.toString(),
            cable_lan: resources.cable_lan.toString(),

            value_pos: masks.money(resources.value_pos.toString()),
            value_pos_backup: masks.money(resources.value_pos_backup?.toString() || ''),
            value_totems: masks.money(resources.value_totems.toString()),
            value_totems_recharges: masks.money(resources.value_totems_recharges.toString()),
            value_coordinators: masks.money(resources.value_coordinators.toString()),
            value_technical: masks.money(resources.value_technical.toString()),
            value_cards: masks.money(resources.value_cards.toString()),
            value_cards_adm: masks.money(resources.value_cards_adm.toString()),
            value_bracelets: masks.money(resources.value_bracelets.toString()),
            value_bobines: masks.money(resources.value_bobines.toString()),
            value_totems_cables: masks.money(resources.value_totems_cables.toString()),
            value_totems_cuffs: masks.money(resources.value_totems_cuffs.toString()),
            value_totems_pedestal: masks.money(resources.value_totems_pedestal.toString()),
            value_totems_cover: masks.money(resources.value_totems_cover.toString()),
            value_extension: masks.money(resources.value_extension.toString()),
            value_ruler: masks.money(resources.value_ruler.toString()),
            value_kit_cordao: masks.money(resources.value_kit_cordao.toString()),
            value_pouch: masks.money(resources.value_pouch.toString()),
            value_tablet: masks.money(resources.value_tablet.toString()),
            value_tablet_power: masks.money(resources.value_tablet_power.toString()),
            value_pos_power: masks.money(resources.value_pos_power.toString()),
            value_transport_box: masks.money(resources.value_transport_box.toString()),
            value_router_wifi: masks.money(resources.value_router_wifi.toString()),
            value_processor_wifi: masks.money(resources.value_processor_wifi.toString()),
            value_tripod_wifi: masks.money(resources.value_tripod_wifi.toString()),
            value_cable_lan: masks.money(resources.value_cable_lan.toString()),
          })
        })
        .catch(error => console.error(error))
    }
  }, [eventSelected, open])

  const validate = useCallback((values) => {
    const errors = {};

    if (!values.pos) {
      errors.pos = 'Valor inválido'
    }
    if (!values.value_pos) {
      errors.value_pos = 'Valor inválido'
    }
    if (!values.pos_backup) {
      errors.pos_backup = 'Valor inválido'
    }
    if (!values.totems) {
      errors.totems = 'Valor inválido'
    }
    if (!values.value_totems) {
      errors.value_totems = 'Valor inválido'
    }
    if (!values.totems_recharges) {
      errors.totems_recharges = 'Valor inválido'
    }
    if (!values.value_totems_recharges) {
      errors.value_totems_recharges = 'Valor inválido'
    }
    if(!values.cards_adm){
      errors.cards_adm = "Valor inválido" 
    }
    if(!values.cards){
      errors.cards = "Valor inválido"
    }
    if(!values.bracelets){
      errors.bracelets = "Valor inválido"
    }
    if(!values.totems_cables){
      errors.totems_cables = "Valor inválido"
    }
    if(!values.totems_cuffs){
      errors.totems_cuffs = "Valor inválido"
    }
    if(!values.totems_pedestal){
      errors.totems_pedestal = "Valor inválido"
    }
    if(!values.totems_cover){
      errors.totems_cover = "Valor inválido"
    }
    if (!values.coordinators) {
      errors.coordinators = 'Valor inválido'
    }
    if (!values.technical) {
      errors.technical = 'Valor inválido'
    }
    if(!values.pos_power){
      errors.pos_power = "Valor inválido"
    }
    if(!values.bobines){
      errors.bobines = "Valor inválido"
    }
    if(!values.extension){
      errors.extension = "Valor inválido"
    }
    if(!values.ruler){
      errors.ruler = "Valor inválido"
    }
    if(!values.kit_cordao){
      errors.kit_cordao = "Valor inválido"
    }
    if(!values.pouch){
      errors.pouch = "Valor inválido"
    }
    if(!values.tablet){
      errors.tablet = "Valor inválido"
    }
    if(!values.tablet_power){
      errors.tablet_power = "Valor inválido"
    }
    if(!values.transport_box){
      errors.transport_box = "Valor inválido"
    }
    if(!values.router_wifi){
      errors.router_wifi = "Valor inválido"
    }
    if(!values.processor_wifi){
      errors.processor_wifi = "Valor inválido"
    }
    if(!values.tripod_wifi){
      errors.tripod_wifi = "Valor inválido"
    }
    if(!values.cable_lan){
      errors.cable_lan = "Valor inválido"
    }

    return errors;
  }, [])

  const formik = useFormik({
    initialValues: {
      pos: '',
      value_pos: '',
      pos_backup: '',
      value_pos_backup: '',
      totems: '',
      value_totems: '',
      totems_recharges: '',
      value_totems_recharges: '',
      cards: '',
      value_cards: '',
      cards_adm: '',
      value_cards_adm: '',
      bracelets: '',
      value_bracelets: '',
      kit_cordao: '',
      value_kit_cordao: '',
      totems_cuffs: '',
      value_totems_cuffs: '',
      totems_pedestal: '',
      value_totems_pedestal: '',
      totems_cover: '',
      value_totems_cover: '',
      bobines: '',
      value_bobines: '',
      totems_cables: '',
      value_totems_cables: '',
      totems_cuffs: '',
      value_totems_cuffs: '',
      totems_pedestal: '',
      value_totems_pedestal: '',
      totems_cover: '',
      value_totems_cover: '',
      extension: '',
      value_extension: '',
      ruler: '',
      value_ruler: '',
      pouch: '',
      value_pouch: '',
      tablet_power: '',
      pos_power: '',
      tablet: '',
      value_tablet: '',
      value_pos_power: '',
      value_tablet_power: '',
      transport_box: '',
      value_transport_box: '',
      router_wifi: '',
      value_router_wifi: '',
      processor_wifi: '',
      value_processor_wifi: '',
      tripod_wifi: '',
      value_tripod_wifi: '',
      cable_lan: '',
      value_cable_lan: '',
      coordinators: '',
      value_coordinators: '',
      technical: '',
      value_technical: '',
    },
    validate,
    onSubmit: values => {
      const {
        pos,
        value_pos,
        pos_backup,
        value_pos_backup,
        totems,
        value_totems,
        totems_recharges,
        value_totems_recharges,
        cards,
        value_cards,
        cards_adm,
        value_cards_adm,
        bracelets,
        value_bracelets,
        bobines,
        value_bobines,
        totems_cables,
        value_totems_cables,
        totems_cuffs,
        value_totems_cuffs,
        totems_pedestal,
        value_totems_pedestal,
        totems_cover,
        value_totems_cover,
        extension,
        value_extension,
        ruler,
        value_ruler,
        kit_cordao,
        value_kit_cordao,
        pouch,
        value_pouch,
        tablet_power,
        pos_power,
        tablet,
        value_tablet,
        value_pos_power,
        value_tablet_power,
        transport_box,
        value_transport_box,
        router_wifi,
        value_router_wifi,
        processor_wifi,
        value_processor_wifi,
        tripod_wifi,
        value_tripod_wifi,
        cable_lan,
        value_cable_lan,


        coordinators,
        value_coordinators,
        technical,
        value_technical,
      } = values;

      const data = {
        resources: {
          pos: Number(pos),
          pos_backup: Number(pos_backup),
          totems: Number(totems),
          totems_recharges: Number(totems_recharges),
          coordinators: Number(coordinators),
          technical: Number(technical),
          cards: Number(cards),
          cards_adm: Number(cards_adm),
          bracelets: Number(bracelets),
          bobines: Number(bobines),
          totems_cables: Number(totems_cables),
          totems_cuffs: Number(totems_cuffs),
          totems_cover: Number(totems_cover),
          totems_pedestal: Number(totems_pedestal),
          extension: Number(extension),
          ruler: Number(ruler),
          kit_cordao: Number(kit_cordao),
          pouch: Number(pouch),
          tablet: Number(tablet),
          tablet_power: Number(tablet_power),
          pos_power: Number(pos_power),
          transport_box: Number(transport_box),
          router_wifi: Number(router_wifi),
          processor_wifi: Number(processor_wifi),
          tripod_wifi: Number(tripod_wifi),
          cable_lan: Number(cable_lan),
          value_pos: Number(unmask(value_pos)),
          value_pos_backup: Number(unmask(value_pos_backup)),
          value_totems: Number(unmask(value_totems)),
          value_totems_recharges: Number(unmask(value_totems_recharges)),
          value_cards: Number(unmask(value_cards)),
          value_cards_adm: Number(unmask(value_cards_adm)),
          value_bracelets: Number(unmask(value_bracelets)),
          value_bobines: Number(unmask(value_bobines)),
          value_totems_cables: Number(unmask(value_totems_cables)),
          value_totems_cuffs: Number(unmask(value_totems_cuffs)),
          value_totems_pedestal: Number(unmask(value_totems_pedestal)),
          value_totems_cover: Number(unmask(value_totems_cover)),
          value_extension: Number(unmask(value_extension)),
          value_ruler: Number(unmask(value_ruler)),
          value_kit_cordao: Number(unmask(value_kit_cordao)),
          value_pouch: Number(unmask(value_pouch)),
          value_tablet: Number(unmask(value_tablet)),
          value_tablet_power: Number(unmask(value_tablet_power)),
          value_pos_power: Number(unmask(value_pos_power)),
          value_transport_box: Number(unmask(value_transport_box)),
          value_router_wifi: Number(unmask(value_router_wifi)),
          value_processor_wifi: Number(unmask(value_processor_wifi)),
          value_tripod_wifi: Number(unmask(value_tripod_wifi)),
          value_cable_lan: Number(unmask(value_cable_lan)),
          value_coordinators: Number(unmask(value_coordinators)),
          value_technical: Number(unmask(value_technical)),
        },
      }

      setOsData({
        ...osData,
        resources: data.resources
      });

      const onSuccessUpdate = () => {
        onShowAlert(PreAlert.success('Registro atualizada com sucesso'))
        setresourcesExists(true)
      }
      const onError = (error) => {
        console.error(error);
        onShowAlert(PreAlert.error('Falhou ao cadastrar a registro'))
      }



      setIsLoading(true);
      if (docExists) {
        new EventGeralDB(estabSelected)
          .update(eventSelected.id, data)
          .then(onSuccessUpdate)
          .catch(onError)
          .finally(() => setIsLoading(false))
      } else {
        new EventGeralDB(estabSelected)
          .define(eventSelected.id, data)
          .then(onSuccessUpdate)
          .catch(onError)
          .finally(() => setIsLoading(false))
      }
    }
  });

  const resetForm = useCallback(() => {
    formik.resetForm()
    setresourcesExists(false)
  }, [formik])

  const onChangeNumbers = useCallback((e) => inputOnlyNumbers(formik, e), [formik.values])
  const onChangeMoney = useCallback((e) => inputMoneyMask(formik, e), [formik.values])

  const handleOpenRelatorio = useCallback((event) => {
    setOpenRelatorio(true);
  }, [])

  const handleCloseRelatorio = useCallback(() => {
    setOpenRelatorio(false);
  }, [])


  const handleSubmit = () => {
    d4signOpen(establishment, event || {})
  }

  const handleSendContract = () => {
    d4signOpen(establishment, eventSelected, formik.values)
    // console.log('eventSelected', eventSelected)
    // console.log('establishment', establishment)
    // console.log('formik.values', formik.values)
  }

  const handleOpenModalEvent = () => {
    setIsOpen(false)
    setOpenModalEvent(true)
  }

  const handleCloseModalEvent = () => {
    setIsOpen(true)
    setOpenModalEvent(false)
  }


  return (
    <>
      <Modal
        id={'modal-register-stores'}
        titleId={'Cadastro de Estabelecimentos'}
        isOpen={open}
        setIsOpen={setIsOpen}
        isStaticBackdrop={true}
        isScrollable={false}
        isCentered={true}
        size="xl" // 'sm' || 'lg' || 'xl' 
        isAnimation={true}
      // onSubmit={formik.handleSubmit}
      >
        <ModalHeader setIsOpen={setIsOpen}>
          <ModalTitle id="register-stores">Ordem de Serviço</ModalTitle>
        </ModalHeader>
        <ModalBody className='p-0 pb-4 px-4'>
          <form className='row' noValidate onSubmit={formik.handleSubmit}>


            <div className='col-12 col-lg-6'>
              <div className='row col-md-12 align-items-center'>
                <div className='col-md-3'></div>
                <div className='d-flex col-md-3 justify-content-center'>
                  <p>QTD</p>
                </div>
                <div className='d-flex col-md-3 justify-content-center'>
                  <p>VALOR</p>
                </div>
              </div>
              <div className='row col-md-12 align-items-center'>
                <div className='col-md-3'>
                  <Label>POS</Label>
                </div>
                <div className='col-md-3'>
                  <Input
                    id="pos"
                    onChange={onChangeNumbers}
                    onBlur={formik.handleBlur}
                    value={formik.values.pos}
                    isValid={formik.isValid}
                    isTouched={formik.touched.pos}
                    invalidFeedback={formik.errors.pos}
                    placeholder="Ex: 100"
                  />
                </div>
                <div className='col-md-3'>
                  <Input
                    id="value_pos"
                    onChange={onChangeMoney}
                    onBlur={formik.handleBlur}
                    value={formik.values.value_pos}
                    isValid={formik.isValid}
                    isTouched={formik.touched.value_pos}
                    invalidFeedback={formik.errors.value_pos}
                    placeholder="Ex: R$ 0,00"
                  />
                </div>
              </div>
              <div className='row col-md-12 align-items-center'>
                <div className='col-md-3'>
                  <Label>POS Reserva</Label>
                </div>
                <div className='col-md-3'>
                  <Input
                    id="pos_backup"
                    onChange={onChangeNumbers}
                    onBlur={formik.handleBlur}
                    value={formik.values.pos_backup}
                    isValid={formik.isValid}
                    isTouched={formik.touched.pos_backup}
                    invalidFeedback={formik.errors.pos_backup}
                    placeholder="Ex: 100"
                  />
                </div>
                <div className='col-md-3'>
                  <Input
                    id="value_pos_backup"
                    onChange={onChangeMoney}
                    onBlur={formik.handleBlur}
                    value={formik.values.value_pos_backup}
                    isValid={formik.isValid}
                    isTouched={formik.touched.value_pos_backup}
                    invalidFeedback={formik.errors.value_pos_backup}
                    placeholder="Ex: R$ 0,00"
                    disabled
                  />
                </div>
              </div>
              <div className='row col-md-12 align-items-center'>
                <div className='col-md-3'>
                  <Label>Totems Ticket</Label>
                </div>
                <div className='col-md-3'>
                  <Input
                    id="totems"
                    onChange={onChangeNumbers}
                    onBlur={formik.handleBlur}
                    value={formik.values.totems}
                    isValid={formik.isValid}
                    isTouched={formik.touched.totems}
                    invalidFeedback={formik.errors.totems}
                    placeholder="Ex: 100"
                  />
                </div>
                <div className='col-md-3'>
                  <Input
                    id="value_totems"
                    onChange={onChangeMoney}
                    onBlur={formik.handleBlur}
                    value={formik.values.value_totems}
                    isValid={formik.isValid}
                    isTouched={formik.touched.value_totems}
                    invalidFeedback={formik.errors.value_totems}
                    placeholder="Ex: R$ 0,00"
                  />
                </div>
              </div>
              <div className='row col-md-12 align-items-center'>
                <div className='col-md-3'>
                  <Label>Totems Recarga</Label>
                </div>
                <div className='col-md-3'>
                  <Input
                    id="totems_recharges"
                    onChange={onChangeNumbers}
                    onBlur={formik.handleBlur}
                    value={formik.values.totems_recharges}
                    isValid={formik.isValid}
                    isTouched={formik.touched.totems_recharges}
                    invalidFeedback={formik.errors.totems_recharges}
                    placeholder="Ex: 100"
                  />
                </div>
                <div className='col-md-3'>
                  <Input
                    id="value_totems_recharges"
                    onChange={onChangeMoney}
                    onBlur={formik.handleBlur}
                    value={formik.values.value_totems_recharges}
                    isValid={formik.isValid}
                    isTouched={formik.touched.value_totems_recharges}
                    invalidFeedback={formik.errors.value_totems_recharges}
                    placeholder="Ex: R$ 0,00"
                  />
                </div>
              </div>
              <div className='row col-md-12 align-items-center'>
                <div className='col-md-3'>
                  <Label>Cartões Administrador</Label>
                </div>
                <div className='col-md-3'>
                  <Input
                    id="cards_adm"
                    onChange={onChangeNumbers}
                    onBlur={formik.handleBlur}
                    value={formik.values.cards_adm}
                    isValid={formik.isValid}
                    isTouched={formik.touched.cards_adm}
                    invalidFeedback={formik.errors.cards_adm}
                    placeholder="Ex: 100"
                  />
                </div>
                <div className='col-md-3'>
                  <Input
                    id="value_cards_adm"
                    onChange={onChangeMoney}
                    onBlur={formik.handleBlur}
                    value={formik.values.value_cards_adm}
                    isValid={formik.isValid}
                    isTouched={formik.touched.value_cards_adm}
                    invalidFeedback={formik.errors.value_cards_adm}
                    placeholder="Ex: R$ 0,00"
                    disabled

                  />
                </div>
              </div>
              <div className='row col-md-12 align-items-center'>
                <div className='col-md-3'>
                  <Label>Cartões</Label>
                </div>
                <div className='col-md-3'>
                  <Input
                    id="cards"
                    onChange={onChangeNumbers}
                    onBlur={formik.handleBlur}
                    value={formik.values.cards}
                    isValid={formik.isValid}
                    isTouched={formik.touched.cards}
                    invalidFeedback={formik.errors.cards}
                    placeholder="Ex: 100"
                  />
                </div>
                <div className='col-md-3'>
                  <Input
                    id="value_cards"
                    onChange={onChangeMoney}
                    onBlur={formik.handleBlur}
                    value={formik.values.value_cards}
                    isValid={formik.isValid}
                    isTouched={formik.touched.value_cards}
                    invalidFeedback={formik.errors.value_cards}
                    placeholder="Ex: R$ 0,00"
                    disabled

                  />
                </div>
              </div>

              <div className='row col-md-12 align-items-center'>
                <div className='col-md-3'>
                  <Label>Pulseiras</Label>
                </div>
                <div className='col-md-3'>
                  <Input
                    id="bracelets"
                    onChange={onChangeNumbers}
                    onBlur={formik.handleBlur}
                    value={formik.values.bracelets}
                    isValid={formik.isValid}
                    isTouched={formik.touched.bracelets}
                    invalidFeedback={formik.errors.bracelets}
                    placeholder="Ex: 100"
                  />
                </div>
                <div className='col-md-3'>
                  <Input
                    id="value_bracelets"
                    onChange={onChangeMoney}
                    onBlur={formik.handleBlur}
                    value={formik.values.value_bracelets}
                    isValid={formik.isValid}
                    isTouched={formik.touched.value_bracelets}
                    invalidFeedback={formik.errors.value_bracelets}
                    placeholder="Ex: R$ 0,00"
                    disabled
                  />
                </div>
              </div>
              <div className='row col-md-12 align-items-center'>
                <div className='col-md-3'>
                  <Label>Cabo de Totems</Label>
                </div>
                <div className='col-md-3'>
                  <Input
                    id="totems_cables"
                    onChange={onChangeNumbers}
                    onBlur={formik.handleBlur}
                    value={formik.values.totems_cables}
                    isValid={formik.isValid}
                    isTouched={formik.touched.totems_cables}
                    invalidFeedback={formik.errors.totems_cables}
                    placeholder="Ex: 100"
                  />
                </div>
                <div className='col-md-3'>
                  <Input
                    id="value_totems_cables"
                    onChange={onChangeMoney}
                    onBlur={formik.handleBlur}
                    value={formik.values.value_totems_cables}
                    isValid={formik.isValid}
                    isTouched={formik.touched.value_totems_cables}
                    invalidFeedback={formik.errors.value_totems_cables}
                    placeholder="Ex: R$ 0,00"
                    disabled
                  />
                </div>
              </div>
              <div className='row col-md-12 align-items-center'>
                <div className='col-md-3'>
                  <Label>Algemas Totems</Label>
                </div>
                <div className='col-md-3'>
                  <Input
                    id="totems_cuffs"
                    onChange={onChangeNumbers}
                    onBlur={formik.handleBlur}
                    value={formik.values.totems_cuffs}
                    isValid={formik.isValid}
                    isTouched={formik.touched.totems_cuffs}
                    invalidFeedback={formik.errors.totems_cuffs}
                    placeholder="Ex: 100"

                  />
                </div>
                <div className='col-md-3'>
                  <Input
                    id="value_totems_cuffs"
                    onChange={onChangeMoney}
                    onBlur={formik.handleBlur}
                    value={formik.values.value_totems_cuffs}
                    isValid={formik.isValid}
                    isTouched={formik.touched.value_totems_cuffs}
                    invalidFeedback={formik.errors.value_totems_cuffs}
                    placeholder="Ex: R$ 0,00"
                    disabled
                  />
                </div>
              </div>
              <div className='row col-md-12 align-items-center'>
                <div className='col-md-3'>
                  <Label>Pedestal Totems</Label>
                </div>
                <div className='col-md-3'>
                  <Input
                    id="totems_pedestal"
                    onChange={onChangeNumbers}
                    onBlur={formik.handleBlur}
                    value={formik.values.totems_pedestal}
                    isValid={formik.isValid}
                    isTouched={formik.touched.totems_pedestal}
                    invalidFeedback={formik.errors.totems_pedestal}
                    placeholder="Ex: 100"
                  />
                </div>
                <div className='col-md-3'>
                  <Input
                    id="value_totems_pedestal"
                    onChange={onChangeMoney}
                    onBlur={formik.handleBlur}
                    value={formik.values.value_totems_pedestal}
                    isValid={formik.isValid}
                    isTouched={formik.touched.value_totems_pedestal}
                    invalidFeedback={formik.errors.value_totems_pedestal}
                    placeholder="Ex: R$ 0,00"
                    disabled
                  />
                </div>
              </div>
              <div className='row col-md-12 align-items-center'>
                <div className='col-md-3'>
                  <Label>Carcaça Totems</Label>
                </div>
                <div className='col-md-3'>
                  <Input
                    id="totems_cover"
                    onChange={onChangeNumbers}
                    onBlur={formik.handleBlur}
                    value={formik.values.totems_cover}
                    isValid={formik.isValid}
                    isTouched={formik.touched.totems_cover}
                    invalidFeedback={formik.errors.totems_cover}
                    placeholder="Ex: 100"
                  />
                </div>
                <div className='col-md-3'>
                  <Input
                    id="value_totems_cover"
                    onChange={onChangeMoney}
                    onBlur={formik.handleBlur}
                    value={formik.values.value_totems_cover}
                    isValid={formik.isValid}
                    isTouched={formik.touched.value_totems_cover}
                    invalidFeedback={formik.errors.value_totems_cover}
                    placeholder="Ex: R$ 0,00"
                    disabled
                  />
                </div>
              </div>
              <div className='row col-md-12 align-items-center'>
                <div className='col-md-3'>
                  <Label>Coordenadores</Label>
                </div>
                <div className='col-md-3'>
                  <Input
                    id="coordinators"
                    onChange={onChangeNumbers}
                    onBlur={formik.handleBlur}
                    value={formik.values.coordinators}
                    isValid={formik.isValid}
                    isTouched={formik.touched.coordinators}
                    invalidFeedback={formik.errors.coordinators}
                    placeholder="Ex: 100"
                  />
                </div>
                <div className='col-md-3'>
                  <Input
                    id="value_coordinators"
                    onBlur={formik.handleBlur}
                    value={masks.money(configUget?.coordinator.toString()) || "0,00"}
                    placeholder="Ex: R$ 0,00"
                    disabled
                  />
                </div>
              </div>
              <div className='row col-md-12 align-items-center'>
                <div className='col-md-3'>
                  <Label>Técnicos</Label>
                </div>
                <div className='col-md-3'>
                  <Input
                    id="technical"
                    onChange={onChangeNumbers}
                    onBlur={formik.handleBlur}
                    value={formik.values.technical}
                    isValid={formik.isValid}
                    isTouched={formik.touched.technical}
                    invalidFeedback={formik.errors.technical}
                    placeholder="Ex: 100"
                  />
                </div>
                <div className='col-md-3'>
                  <Input
                    id="value_technical"
                    onBlur={formik.handleBlur}
                    value={masks.money(configUget?.technical.toString()) || "0,00"}
                    placeholder="Ex: R$ 0,00"
                    disabled
                  />
                </div>
              </div>
              <div className='row col-md-12 align-items-center'>
                <div className='col-md-3'>
                  <Label>Carregador POS</Label>
                </div>
                <div className='col-md-3'>
                  <Input
                    id="pos_power"
                    onChange={onChangeNumbers}
                    onBlur={formik.handleBlur}
                    value={formik.values.pos_power}
                    isValid={formik.isValid}
                    isTouched={formik.touched.pos_power}
                    invalidFeedback={formik.errors.pos_power}
                    placeholder="Ex: 100"
                  />
                </div>
                <div className='col-md-3'>
                  <Input
                    id="value_pos_power"
                    onChange={onChangeMoney}
                    onBlur={formik.handleBlur}
                    value={formik.values.value_pos_power}
                    isValid={formik.isValid}
                    isTouched={formik.touched.value_pos_power}
                    invalidFeedback={formik.errors.value_pos_power}
                    placeholder="Ex: R$ 0,00"
                    disabled
                  />
                </div>
              </div>
            </div>

            <div className='col-12 col-lg-6'>
              <div className='row col-md-12 align-items-center'>
                <div className='col-md-3'></div>
                <div className='d-flex col-md-3 justify-content-center'>
                  <p>QTD</p>
                </div>
                <div className='d-flex col-md-3 justify-content-center'>
                  <p>VALOR</p>
                </div>
              </div>
              <div className='row col-md-12 align-items-center'>
                <div className='col-md-3'>
                  <Label>Bobinas</Label>
                </div>
                <div className='col-md-3'>
                  <Input
                    id="bobines"
                    onChange={onChangeNumbers}
                    onBlur={formik.handleBlur}
                    value={formik.values.bobines}
                    isValid={formik.isValid}
                    isTouched={formik.touched.bobines}
                    invalidFeedback={formik.errors.bobines}
                    placeholder="Ex: 100"
                  />
                </div>
                <div className='col-md-3'>
                  <Input
                    id="value_bobines"
                    onChange={onChangeMoney}
                    onBlur={formik.handleBlur}
                    value={formik.values.value_bobines}
                    isValid={formik.isValid}
                    isTouched={formik.touched.value_bobines}
                    invalidFeedback={formik.errors.value_bobines}
                    placeholder="Ex: R$ 0,00"
                    disabled
                  />
                </div>
              </div>
              <div className='row col-md-12 align-items-center'>
                <div className='col-md-3'>
                  <Label>Extensão</Label>
                </div>
                <div className='col-md-3'>
                  <Input
                    id="extension"
                    onChange={onChangeNumbers}
                    onBlur={formik.handleBlur}
                    value={formik.values.extension}
                    isValid={formik.isValid}
                    isTouched={formik.touched.extension}
                    invalidFeedback={formik.errors.extension}
                    placeholder="Ex: 100"
                  />
                </div>
                <div className='col-md-3'>
                  <Input
                    id="value_extension"
                    onChange={onChangeMoney}
                    onBlur={formik.handleBlur}
                    value={formik.values.value_extension}
                    isValid={formik.isValid}
                    isTouched={formik.touched.value_extension}
                    invalidFeedback={formik.errors.value_extension}
                    placeholder="Ex: R$ 0,00"
                    disabled
                  />
                </div>
              </div>
              <div className='row col-md-12 align-items-center'>
                <div className='col-md-3'>
                  <Label>Régua</Label>
                </div>
                <div className='col-md-3'>
                  <Input
                    id="ruler"
                    onChange={onChangeNumbers}
                    onBlur={formik.handleBlur}
                    value={formik.values.ruler}
                    isValid={formik.isValid}
                    isTouched={formik.touched.ruler}
                    invalidFeedback={formik.errors.ruler}
                    placeholder="Ex: 100"
                  />
                </div>
                <div className='col-md-3'>
                  <Input
                    id="value_ruler"
                    onChange={onChangeMoney}
                    onBlur={formik.handleBlur}
                    value={formik.values.value_ruler}
                    isValid={formik.isValid}
                    isTouched={formik.touched.value_ruler}
                    invalidFeedback={formik.errors.value_ruler}
                    placeholder="Ex: R$ 0,00"
                    disabled
                  />
                </div>
              </div>
              <div className='row col-md-12 align-items-center'>
                <div className='col-md-3'>
                  <Label>Kit Cordão</Label>
                </div>
                <div className='col-md-3'>
                  <Input
                    id="kit_cordao"
                    onChange={onChangeNumbers}
                    onBlur={formik.handleBlur}
                    value={formik.values.kit_cordao}
                    isValid={formik.isValid}
                    isTouched={formik.touched.kit_cordao}
                    invalidFeedback={formik.errors.kit_cordao}
                    placeholder="Ex: 100"
                  />
                </div>
                <div className='col-md-3'>
                  <Input
                    id="kit_cordao"
                    onChange={onChangeMoney}
                    onBlur={formik.handleBlur}
                    value={formik.values.value_kit_cordao}
                    isValid={formik.isValid}
                    isTouched={formik.touched.value_kit_cordao}
                    invalidFeedback={formik.errors.value_kit_cordao}
                    placeholder="Ex: R$ 0,00"
                    disabled
                  />
                </div>
              </div>

              <div className='row col-md-12 align-items-center'>
                <div className='col-md-3'>
                  <Label>Malote</Label>
                </div>
                <div className='col-md-3'>
                  <Input
                    id="pouch"
                    onChange={onChangeNumbers}
                    onBlur={formik.handleBlur}
                    value={formik.values.pouch}
                    isValid={formik.isValid}
                    isTouched={formik.touched.pouch}
                    invalidFeedback={formik.errors.pouch}
                    placeholder="Ex: 100"
                  />
                </  div>
                <div className='col-md-3'>
                  <Input
                    id="value_pouch"
                    onChange={onChangeMoney}
                    onBlur={formik.handleBlur}
                    value={formik.values.value_pouch}
                    isValid={formik.isValid}
                    isTouched={formik.touched.value_pouch}
                    invalidFeedback={formik.errors.value_pouch}
                    placeholder="Ex: R$ 0,00"
                    disabled
                  />
                </div>
              </div>
              <div className='row col-md-12 align-items-center'>
                <div className='col-md-3'>
                  <Label>Tablet</Label>
                </div>
                <div className='col-md-3'>
                  <Input
                    id="tablet"
                    onChange={onChangeNumbers}
                    onBlur={formik.handleBlur}
                    value={formik.values.tablet}
                    isValid={formik.isValid}
                    isTouched={formik.touched.tablet}
                    invalidFeedback={formik.errors.tablet}
                    placeholder="Ex: 100"
                  />
                </div>
                <div className='col-md-3'>
                  <Input
                    id="value_tablet"
                    onChange={onChangeMoney}
                    onBlur={formik.handleBlur}
                    value={formik.values.value_tablet}
                    isValid={formik.isValid}
                    isTouched={formik.touched.value_tablet}
                    invalidFeedback={formik.errors.value_tablet}
                    placeholder="Ex: R$ 0,00"
                    disabled
                  />
                </div>
              </div>
              <div className='row col-md-12 align-items-center'>
                <div className='col-md-3'>
                  <Label>Carregador Tablet</Label>
                </div>
                <div className='col-md-3'>
                  <Input
                    id="tablet_power"
                    onChange={onChangeNumbers}
                    onBlur={formik.handleBlur}
                    value={formik.values.tablet_power}
                    isValid={formik.isValid}
                    isTouched={formik.touched.tablet_power}
                    invalidFeedback={formik.errors.tablet_power}
                    placeholder="Ex: 100"
                  />
                </div>
                <div className='col-md-3'>
                  <Input
                    id="value_tablet_power"
                    onChange={onChangeMoney}
                    onBlur={formik.handleBlur}
                    value={formik.values.value_tablet_power}
                    isValid={formik.isValid}
                    isTouched={formik.touched.value_tablet_power}
                    invalidFeedback={formik.errors.value_tablet_power}
                    placeholder="Ex: R$ 0,00"
                    disabled
                  />
                </div>
              </div>
              <div className='row col-md-12 align-items-center'>
                <div className='col-md-3'>
                  <Label>Caixa Transporte</Label>
                </div>
                <div className='col-md-3'>
                  <Input
                    id="transport_box"
                    onChange={onChangeNumbers}
                    onBlur={formik.handleBlur}
                    value={formik.values.transport_box}
                    isValid={formik.isValid}
                    isTouched={formik.touched.transport_box}
                    invalidFeedback={formik.errors.transport_box}
                    placeholder="Ex: 100"
                  />
                </div>
                <div className='col-md-3'>
                  <Input
                    id="value_transport_box"
                    onChange={onChangeMoney}
                    onBlur={formik.handleBlur}
                    value={formik.values.value_transport_box}
                    isValid={formik.isValid}
                    isTouched={formik.touched.value_transport_box}
                    invalidFeedback={formik.errors.value_transport_box}
                    placeholder="Ex: R$ 0,00"
                    disabled
                  />
                </div>
              </div>
              <div className='row col-md-12 align-items-center'>
                <div className='col-md-3'>
                  <Label>Roteador Wi-Fi</Label>
                </div>
                <div className='col-md-3'>
                  <Input
                    id="router_wifi"
                    onChange={onChangeNumbers}
                    onBlur={formik.handleBlur}
                    value={formik.values.router_wifi}
                    isValid={formik.isValid}
                    isTouched={formik.touched.router_wifi}
                    invalidFeedback={formik.errors.router_wifi}
                    placeholder="Ex: 100"
                  />
                </div>
                <div className='col-md-3'>
                  <Input
                    id="value_router_wifi"
                    onChange={onChangeMoney}
                    onBlur={formik.handleBlur}
                    value={formik.values.value_router_wifi}
                    isValid={formik.isValid}
                    isTouched={formik.touched.value_router_wifi}
                    invalidFeedback={formik.errors.value_router_wifi}
                    placeholder="Ex: R$ 0,00"
                    disabled
                  />
                </div>
              </div>
              <div className='row col-md-12 align-items-center'>
                <div className='col-md-3'>
                  <Label>Switch</Label>
                </div>
                <div className='col-md-3'>
                  <Input
                    id="processor_wifi"
                    onChange={onChangeNumbers}
                    onBlur={formik.handleBlur}
                    value={formik.values.processor_wifi}
                    isValid={formik.isValid}
                    isTouched={formik.touched.processor_wifi}
                    invalidFeedback={formik.errors.processor_wifi}
                    placeholder="Ex: 100"
                  />
                </div>
                <div className='col-md-3'>
                  <Input
                    id="value_processor_wifi"
                    onChange={onChangeMoney}
                    onBlur={formik.handleBlur}
                    value={formik.values.value_processor_wifi}
                    isValid={formik.isValid}
                    isTouched={formik.touched.value_processor_wifi}
                    invalidFeedback={formik.errors.value_processor_wifi}
                    placeholder="Ex: R$ 0,00"
                    disabled
                  />
                </div>
              </div>
              <div className='row col-md-12 align-items-center'>
                <div className='col-md-3'>
                  <Label>Tripé Wi-Fi</Label>
                </div>
                <div className='col-md-3'>
                  <Input
                    id="tripod_wifi"
                    onChange={onChangeNumbers}
                    onBlur={formik.handleBlur}
                    value={formik.values.tripod_wifi}
                    isValid={formik.isValid}
                    isTouched={formik.touched.tripod_wifi}
                    invalidFeedback={formik.errors.tripod_wifi}
                    placeholder="Ex: 100"
                  />
                </div>
                <div className='col-md-3'>
                  <Input
                    id="value_tripod_wifi"
                    onChange={onChangeMoney}
                    onBlur={formik.handleBlur}
                    value={formik.values.value_tripod_wifi}
                    isValid={formik.isValid}
                    isTouched={formik.touched.value_tripod_wifi}
                    invalidFeedback={formik.errors.value_tripod_wifi}
                    placeholder="Ex: R$ 0,00"
                    disabled
                  />
                </div>
              </div>
              <div className='row col-md-12 align-items-center'>
                <div className='col-md-3'>
                  <Label>Cabo LAN</Label>
                </div>
                <div className='col-md-3'>
                  <Input
                    id="cable_lan"
                    onChange={onChangeNumbers}
                    onBlur={formik.handleBlur}
                    value={formik.values.cable_lan}
                    isValid={formik.isValid}
                    isTouched={formik.touched.cable_lan}
                    invalidFeedback={formik.errors.cable_lan}
                    placeholder="Ex: 100"
                  />
                </div>
                <div className='col-md-3'>
                  <Input
                    id="value_cable_lan"
                    onChange={onChangeMoney}
                    onBlur={formik.handleBlur}
                    value={formik.values.value_cable_lan}
                    isValid={formik.isValid}
                    isTouched={formik.touched.value_cable_lan}
                    invalidFeedback={formik.errors.value_cable_lan}
                    placeholder="Ex: R$ 0,00"
                    disabled
                  />
                </div>
              </div>
            </div>

            <div className="d-flex justify-content-end gap-2 flex-row pt-4 px-4">
              <Button
                className=' text-dark '
                // disabled={!eventGeral || !resourceRegistered}
                type='button'
                color='success'
                icon="FilePdf"
                rounded={1}
                hoverShadow="sm"
                shadow="sm"
                size="sm"
                isDisable={!resourcesExists}
                onClick={handleOpenModalEvent}
              >
                Exibir Contrato
              </Button>
              <Button
                className=' text-dark '
                // disabled={!eventGeral || !resourceRegistered}
                type='button'
                color='secondary'
                icon="FilePdf"
                rounded={1}
                hoverShadow="sm"
                shadow="sm"
                size="sm"
                isDisable={!resourcesExists}
                onClick={handleSendContract}
              >
                Enviar Contrato
              </Button>
              <Button
                className=' text-dark'
                // disabled={!eventGeral || !resourceRegistered}
                type='button'
                color='warning'
                icon="PictureAsPdf"
                rounded={1}
                hoverShadow="sm"
                shadow="sm"
                size="sm"
                isDisable={!resourcesExists}
                onClick={handleOpenRelatorio}
              >
                Relatório
              </Button>
              <BtnCancel setIsClose={setIsClose} />
              {isLoading ? (
                <BtnLoad />
              ) : (
                <BtnConfirm isDisable={!formik.isValid} />
              )}
            </div>

          </form>
        </ModalBody>
      </Modal >
      <RelatorioOs
        open={openRelatorio}
        setIsOpen={setOpenRelatorio}
        setIsClose={handleCloseRelatorio}
        eventSelected={eventSelected}
      />
      <ModalEventoContrato
        open={openModalEvent}
        setIsOpen={setOpenModalEvent}
        setIsClose={handleCloseModalEvent}
        osData={osData}
        estabelecimento={establishment}
        evento={eventSelected}
      />
    </>
  )
}

export default memo(ModalOs);