import React, { useState, useEffect, useContext, memo, useCallback } from 'react';
import { useFormik } from 'formik';
import FormGroup from '../../bootstrap/forms/FormGroup';
import Input from '../../bootstrap/forms/Input';
import Label from '../../bootstrap/forms/Label';
import Modal, { ModalBody, ModalHeader, ModalTitle } from '../../bootstrap/Modal';
import { DefaultContext } from '../../../contexts/default';
import PreAlert from '../../../helpers/utils/preAlert';
import BtnCancel from '../../MyCustom/BtnCancel';
import BtnLoad from '../../MyCustom/BtnLoad';
import BtnConfirm from '../../MyCustom/BtnConfirm';
import { inputOnlyNumbers, inputOnlyNumbersNoStitch } from '../../../utils/input';
import EventGeralDB from '../../../database/wrappers/eventGeral';

const ModalGeral = ({ open, setIsOpen, setIsClose, eventSelected }) => {

  const { estabSelected, onShowAlert } = useContext(DefaultContext);

  const [docExists, setdocExists] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (!open) return formik.resetForm();
    if (eventSelected) {
      new EventGeralDB(estabSelected)
        .get(eventSelected.id)
        .then(data => {
          setdocExists(!!data)
          if (!data || !data.rate) return;
          //console.log(data);
          const {
            rate,
            publicNumber,
          } = data;

          formik.setValues({
            taxAdvanceCredit: rate.advance_credit.toString(),
            taxAdvanceRecharge: rate.advance_recharge.toString(),
            taxDebit: rate.debit.toString(),
            taxCredit: rate.credit.toString(),
            taxPix: rate.pix.toString(),
            taxCash: rate.cash.toString(),
            taxMealTicket: rate.mealTicket.toString(),
            taxCourtesy: rate.courtesy.toString(),

            publicNumber: publicNumber.toString(),
          })
        })
        .catch(error => console.error(error))
    }
  }, [eventSelected, open])

  const validate = useCallback((values) => {
    const errors = {};

    if (!values.taxAdvanceCredit) {
      errors.taxAdvanceCredit = 'Valor inválido'
    }
    if (!values.taxAdvanceRecharge) {
      errors.taxAdvanceRecharge = 'Valor inválido'
    }
    if (!values.taxDebit) {
      errors.taxDebit = 'Valor inválido'
    }
    if (!values.taxCredit) {
      errors.taxCredit = 'Valor inválido'
    }
    if (!values.taxPix) {
      errors.taxPix = 'Valor inválido'
    }
    if (!values.taxCash) {
      errors.taxCash = 'Valor inválido'
    }
    if (!values.taxMealTicket) {
      errors.taxMealTicket = 'Valor inválido'
    }
    if (!values.taxCourtesy) {
      errors.taxCourtesy = 'Valor inválido'
    }

    if (!values.taxMealTicket) {
      errors.taxMealTicket = 'Valor inválido'
    }
    if (!values.taxCourtesy) {
      errors.taxCourtesy = 'Valor inválido'
    }

    if (!values.publicNumber) {
      errors.publicNumber = 'Valor inválido'
    }

    return errors;
  }, [])

  const formik = useFormik({
    initialValues: {
      taxAdvanceCredit: '',
      taxAdvanceRecharge: '',
      taxDebit: '',
      taxCredit: '',
      taxPix: '',
      taxCash: '',
      taxMealTicket: '',
      taxCourtesy: '',

      publicNumber: '',
    },
    validate,
    onSubmit: values => {
      const {
        taxAdvanceCredit,
        taxAdvanceRecharge,
        taxDebit,
        taxCredit,
        taxPix,
        taxCash,
        taxMealTicket,
        taxCourtesy,

        publicNumber,
      } = values;

      const data = {
        rate: {
          debit: Number(taxDebit),
          credit: Number(taxCredit),
          pix: Number(taxPix),
          cash: Number(taxCash),
          mealTicket: Number(taxMealTicket),
          courtesy: Number(taxCourtesy),
          advance_credit: Number(taxAdvanceCredit),
          advance_recharge: Number(taxAdvanceRecharge),
        },

        publicNumber: Number(publicNumber),
      }

      const onSuccessUpdate = () => {
        onShowAlert(PreAlert.success('Registro atualizada com sucesso'))
        setIsClose();
      }
      const onError = (error) => {
        console.error(error);
        onShowAlert(PreAlert.error('Falhou ao cadastrar a registro'))
      }

      setIsLoading(true);
      if (docExists) {
        new EventGeralDB(estabSelected)
          .update(eventSelected.id, data)
          .then(onSuccessUpdate)
          .catch(onError)
          .finally(() => setIsLoading(false))
      } else {
        new EventGeralDB(estabSelected)
          .define(eventSelected.id, data)
          .then(onSuccessUpdate)
          .catch(onError)
          .finally(() => setIsLoading(false))
      }
    }
  });

  const onChangeNumbers = useCallback((e) => inputOnlyNumbers(formik, e), [formik.values])
  const onChangeNumbersNoStitch = useCallback((e) => inputOnlyNumbersNoStitch(formik, e), [formik.values])

  return (
    <Modal
      id={'modal-register-stores'}
      titleId={'Cadastro de Estabelecimentos'}
      isOpen={open}
      setIsOpen={setIsOpen}
      isStaticBackdrop={true}
      isScrollable={false}
      isCentered={true}
      size="lg" // 'sm' || 'lg' || 'xl' 
      isAnimation={true}
    // onSubmit={formik.handleSubmit}
    >
      <ModalHeader setIsOpen={setIsOpen}>
        <ModalTitle id="register-stores">Ajustes Gerais</ModalTitle>
      </ModalHeader>
      <ModalBody>
        <form className='row p-4' noValidate onSubmit={formik.handleSubmit}>
          <div className='col-md-6'>
            <h5>Taxa por Modalidade</h5>
            <div className='row col-md-12 align-items-center'>
              <div className='col-md-4'>
                <Label>Antecipação Adquirência</Label>
              </div>
              <div className='col-md-6'>
                <Input
                  id="taxAdvanceCredit"
                  onChange={onChangeNumbers}
                  onBlur={formik.handleBlur}
                  value={formik.values.taxAdvanceCredit}
                  isValid={formik.isValid}
                  isTouched={formik.touched.taxAdvanceCredit}
                  invalidFeedback={formik.errors.taxAdvanceCredit}
                  // validFeedback='Assim está bom!'
                  placeholder="Ex: 10%"
                />
              </div>
            </div>
            <div className='row col-md-12 align-items-center'>
              <div className='col-md-4'>
                <Label>Recarga Antecipada</Label>
              </div>
              <div className='col-md-6'>
                <Input
                  id="taxAdvanceRecharge"
                  onChange={onChangeNumbers}
                  onBlur={formik.handleBlur}
                  value={formik.values.taxAdvanceRecharge}
                  isValid={formik.isValid}
                  isTouched={formik.touched.taxAdvanceRecharge}
                  invalidFeedback={formik.errors.taxAdvanceRecharge}
                  // validFeedback='Assim está bom!'
                  placeholder="Ex: 10%"
                />
              </div>
            </div>
            <div className='row col-md-12 align-items-center'>
              <div className='col-md-4'>
                <Label>Débito</Label>
              </div>
              <div className='col-md-6'>
                <Input
                  id="taxDebit"
                  onChange={onChangeNumbers}
                  onBlur={formik.handleBlur}
                  value={formik.values.taxDebit}
                  isValid={formik.isValid}
                  isTouched={formik.touched.taxDebit}
                  invalidFeedback={formik.errors.taxDebit}
                  // validFeedback='Assim está bom!'
                  placeholder="Ex: 10%"
                />
              </div>
            </div>
            <div className='row col-md-12 align-items-center'>
              <div className='col-md-4'>
                <Label>Crédito</Label>
              </div>
              <div className='col-md-6'>
                <Input
                  id="taxCredit"
                  onChange={onChangeNumbers}
                  onBlur={formik.handleBlur}
                  value={formik.values.taxCredit}
                  isValid={formik.isValid}
                  isTouched={formik.touched.taxCredit}
                  invalidFeedback={formik.errors.taxCredit}
                  // validFeedback='Assim está bom!'
                  placeholder="Ex: 10%"
                />
              </div>
            </div>
            <div className='row col-md-12 align-items-center'>
              <div className='col-md-4'>
                <Label>Pix</Label>
              </div>
              <div className='col-md-6'>
                <Input
                  id="taxPix"
                  onChange={onChangeNumbers}
                  onBlur={formik.handleBlur}
                  value={formik.values.taxPix}
                  isValid={formik.isValid}
                  isTouched={formik.touched.taxPix}
                  invalidFeedback={formik.errors.taxPix}
                  // validFeedback='Assim está bom!'
                  placeholder="Ex: 10%"
                />
              </div>
            </div>
            <div className='row col-md-12 align-items-center'>
              <div className='col-md-4'>
                <Label>Dinheiro</Label>
              </div>
              <div className='col-md-6'>
                <Input
                  id="taxCash"
                  onChange={onChangeNumbers}
                  onBlur={formik.handleBlur}
                  value={formik.values.taxCash}
                  isValid={formik.isValid}
                  isTouched={formik.touched.taxCash}
                  invalidFeedback={formik.errors.taxCash}
                  // validFeedback='Assim está bom!'
                  placeholder="Ex: 10%"
                />
              </div>
            </div>
            <div className='row col-md-12 align-items-center'>
              <div className='col-md-4'>
                <Label>Vale Refeição</Label>
              </div>
              <div className='col-md-6'>
                <Input
                  id="taxMealTicket"
                  onChange={onChangeNumbers}
                  onBlur={formik.handleBlur}
                  value={formik.values.taxMealTicket}
                  isValid={formik.isValid}
                  isTouched={formik.touched.taxMealTicket}
                  invalidFeedback={formik.errors.taxMealTicket}
                  // validFeedback='Assim está bom!'
                  placeholder="Ex: 10%"
                />
              </div>
            </div>
            <div className='row col-md-12 align-items-center'>
              <div className='col-md-4'>
                <Label>Cortesia</Label>
              </div>
              <div className='col-md-6'>
                <Input
                  id="taxCourtesy"
                  onChange={onChangeNumbers}
                  onBlur={formik.handleBlur}
                  value={formik.values.taxCourtesy}
                  isValid={formik.isValid}
                  isTouched={formik.touched.taxCourtesy}
                  invalidFeedback={formik.errors.taxCourtesy}
                  // validFeedback='Assim está bom!'
                  placeholder="Ex: 10%"
                />
              </div>
            </div>
          </div>

          <div className='col-md-6'>
            <div className='col-md-12 mt-4'>
              <div className='d-flex flex-column justify-content-between h-100'>
                <div className='col-md-12 mb-12'>
                  <h5>Público</h5>
                  <FormGroup id="publicNumber" label="Estimativa de Público" className='col-md-9'>
                    <Input
                      ariaDescribedby='inputGroupPrepend'
                      onChange={onChangeNumbersNoStitch}
                      onBlur={formik.handleBlur}
                      value={formik.values.publicNumber}
                      isValid={formik.isValid}
                      isTouched={formik.touched.publicNumber}
                      invalidFeedback={formik.errors.publicNumber}
                      validFeedback='Assim está bom!'
                      placeholder="Ex: 1000"
                    />
                  </FormGroup>
                </div>
              </div>
            </div>
          </div>

          <div className="row pt-4">
            <div className="col-md-4 offset-md-8">
              <div className='d-flex justify-content-evenly'>
                <BtnCancel setIsClose={setIsClose} />
                {isLoading ? (
                  <BtnLoad />
                ) : (
                  <BtnConfirm isDisable={!formik.isValid} />
                )}
              </div>
            </div>
          </div>
        </form>
      </ModalBody>
    </Modal >
  )
}

export default memo(ModalGeral);