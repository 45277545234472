import { useContext, useEffect, useState } from "react"
import { DefaultContext } from "../../../../../contexts/default"
import ClouserCashierEventData from "../../../../../database/wrappers/eventData/clouserCashier"
import ClouserTotemEventData from "../../../../../database/wrappers/eventData/clouserTotem"
import MovementReports from "./relatorioMovimentacao"
import Totais from "./totais"
import useTotalSales from "../../../../../hooks/TotaisReports/useTotalSales"
import { TABS } from "../tabs"
import ReportsBySector from "./reportsBySector"
import TotaisBySector from "./totaisBySector"
import { PAYMENT_TYPE } from "../../../../../types/payment"



const TabConsumo = ({ hidden, SubTabs }) => {
    const { sectorSelected } = useContext(DefaultContext);
    const [salesfilter, setsalesfilter] = useState([]);
    const { SalesCalc } = useTotalSales();
    const [totaisSector, setTotaisSector] = useState({
        qtdProducts: 0,
        refunds: 0,
        total: 0,

    })


    useEffect(() => {
        const rows = SalesCalc.sales.filter((row) => row.sectorId === sectorSelected);
        setsalesfilter(rows);
        let totalQtdProducts = 0;
        let totalSplitPaymentReversed = 0;
        rows.forEach((row) => {
            row.products.forEach((product) => {
                totalQtdProducts += product.qtd;
            });
            if (row.payment_type === PAYMENT_TYPE.DIVIDED) {
                row.splitPayment.forEach(item => {
                    if (item.reversed) {
                        totalSplitPaymentReversed += item.value;
                    } 
                });
            }
        });

        const salesBySectorRefunds = rows.filter((row) => row.reversed);
        const salesBySector = rows.filter((row) => !row.reversed);
        let totalRefunds = salesBySectorRefunds.reduce((prev, curr) => prev + curr.value, 0);
        let total = salesBySector.reduce((prev, curr) => prev + curr.value, 0);


        setTotaisSector({
            qtdProducts: totalQtdProducts,
            refunds: totalRefunds,
            total: total - totalSplitPaymentReversed,
        });
    }, [sectorSelected, SalesCalc]);


    return (
        <div className='row' hidden={hidden} >
            <div hidden={hidden || SubTabs !== TABS.CONSUMO.subTabs.GERAL.id}>
                <Totais
                    qtdProducts={SalesCalc.totalProducts}
                    refunds={SalesCalc.TotalSalesWithRefunds}
                    total={SalesCalc.TotalSales}
                />
                <MovementReports moviments={SalesCalc.sales} />
            </div>
            <div hidden={hidden || SubTabs !== TABS.CONSUMO.subTabs.POR_SECTOR.id}>
                <TotaisBySector qtdProducts={totaisSector.qtdProducts} refunds={totaisSector.refunds} total={totaisSector.total} />
                <ReportsBySector moviments={salesfilter} />


            </div>
        </div>
    )
}
export default TabConsumo