import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";

// ============================== //
// CREDENCIAIS DE PRODUÇÃO
// ============================== //

const firebaseConfigGlobal = {
  apiKey: "AIzaSyD8qpDRfJuX_JdstgIIatoTjCjnwUQXMnA",
  authDomain: "clients-uget-prod.firebaseapp.com",
  projectId: "clients-uget-prod",
  storageBucket: "clients-uget-prod.appspot.com",
  messagingSenderId: "133280061756",
  appId: "1:133280061756:web:c4afe6b454cd09d866e639",
  measurementId: "G-W74VXDT250"
};

// ============================== //
// CREDENCIAIS DE DESENVOLVIMENTO //
// ============================== //

// const firebaseConfigGlobal = {
//   apiKey: "AIzaSyD0nmwFOc0Uxxa8lBDtIL1jsiLVvnplSgo",
//   authDomain: "clients-uget-dev.firebaseapp.com",
//   projectId: "clients-uget-dev",
//   storageBucket: "clients-uget-dev.appspot.com",
//   messagingSenderId: "769416394370",
//   appId: "1:769416394370:web:212500af7ab53d6a84a69b",
//   measurementId: "G-NMEPCNFH9W"
// };

const appGlobal = initializeApp(firebaseConfigGlobal, "appGlobal");


export {appGlobal}

