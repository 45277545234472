import React, { useState, useCallback, useMemo, useContext, useEffect } from 'react';
import Card, { CardBody, CardHeader, CardLabel, CardTitle } from '../../components/bootstrap/Card';
import useDarkMode from '../../hooks/useDarkMode';
import TableSimples from '../../components/MyCustom/TableSimples';
import Popovers from '../../components/bootstrap/Popovers';
import Button, { ButtonGroup } from '../../components/bootstrap/Button';
import { DefaultContext } from '../../contexts/default';
import PreAlert from '../../helpers/utils/preAlert';
import EstablishmentContract from '../../components/modals/EstablishmentContract/establishmentContract';
import axios from 'axios';
import EstablishmentDB from '../../database/wrappers/establishment';

const EstablishmentContracts = ({ contract, hidden }) => {
    const { themeStatus, darkModeStatus, setDarkModeStatus } = useDarkMode();
    const { establishment, estabSelected, onShowAlert } = useContext(DefaultContext)
    const [rows, setrows] = useState([])
    const [openModalContrato, setOpenModalContrato] = useState(false);
    const [folderData, setFolderData] = useState({})
    const [contractData, setContractData] = useState({})
    const [base64Contract, setBase64Contract] = useState('')
    const [isLoad, setIsLoad] = useState(false);

    console.log('contract: ', contract)
    console.log('estabelishment: ', establishment)

    const columnEstablishment = useMemo(() => ([
        {
            label: 'ESTABELECIMENTO',
            field: 'establishment',
        },
        {
            label: 'E-MAIL',
            field: 'email',
        },
        {
            label: 'DATA ASSINATURA',
            field: 'date',
        },
        {
            label: 'STATUS',
            field: 'signed',
            format: row => (
                <Button
                    isLink
                    color={row.signed ? 'success' : 'danger'}
                    icon='Circle'
                    className='text-nowrap m-0 p-0 py-2'
                >
                    {row.signed ? 'Assinado' : 'Pendente'}
                </Button>
            )
        },
        {
            label: 'AÇÕES',
            field: 'actions',
            format: row => (
                <div className='w-75 d-flex justify-content-center'>
                    {/* <Popovers trigger='hover' desc='Upload de Contrato'>
                        <Button
                            color={darkModeStatus ? 'light' : 'dark'}
                            icon="EditNote"
                            shadow="sm"
                            hoverShadow="sm"
                            size="sm"
                            onClick={() => handleOpenModalContrato(row)}
                        // onClick={establishment?.contracts?.signed ? () => { onError() } : () => { console.log('abrir modal contrato') }}
                        />
                    </Popovers>
                    <Popovers trigger='hover' desc='Enviar Contrato'>
                        <Button
                            color={darkModeStatus ? 'light' : 'dark'}
                            icon="EditNote"
                            shadow="sm"
                            hoverShadow="sm"
                            size="sm"
                        // onClick={establishment?.contracts?.signed ? () => { onError() } : () => { console.log('abrir modal contrato') }}
                        />
                    </Popovers> */}
                    <Popovers trigger='hover' desc='Download'>
                        <Button
                            color={darkModeStatus ? 'light' : 'dark'}
                            icon="Download"
                            shadow="sm"
                            hoverShadow="sm"
                            size="sm"
                            onClick={() => window.open(row.link, '_blank')} />
                    </Popovers>
                </div>
            ),
        }
    ]), [])

    const rowContrato = [
        {
            id: 1,
            establishment: establishment?.name,
            email: establishment?.email,
            signed: contract?.signed,
            link: contract?.infoDownload?.url,
        }
    ];

    const handleOpenModalContrato = useCallback(() => {
        setOpenModalContrato(true)
    }, [])

    const handleCloseModalContrato = useCallback(() => {
        setOpenModalContrato(false);
    }, [])




    return (
        <>
            <Card hidden={hidden}>
                <CardBody>
                    <TableSimples
                        rows={rowContrato}
                        columns={columnEstablishment}
                        keyExtractor={row => row.id}
                    />
                </CardBody>
            </Card>
            <EstablishmentContract
                estabSelecionado={establishment}
                open={openModalContrato}
                setIsClose={handleCloseModalContrato}
            />
        </>
    )
}

export default EstablishmentContracts;