import { QueryConstraint, Unsubscribe } from "firebase/firestore";
import Reason from "../entities/reason.entity";
import FirestorePipe from "../utils/pipe";
import EstablishmentDB from "./establishment";
import EventDB from "./event";

class ReasonDB extends FirestorePipe {

    public static readonly colName = 'Reasons'

    constructor(idEstab: string) {
        if (!idEstab)
            throw new Error('Informe o id do estabelecimento e estabelecimento')
        super(`${EstablishmentDB.colName}/${idEstab}/${ReasonDB.colName}`);
    }

    public create(data: Reason): Promise<any> {
        return this._create(data);
    }
    public update(id: string, data: Reason): Promise<any> {
        return this._update(id, data);
    }
    public delete(id: string): Promise<any> {
        return this._delete(id);
    }
    public getAll(...params: QueryConstraint[]): Promise<Reason[]> {
        return this._getAll<Reason>(...params);
    }
    public get(id: string): Promise<Reason> {
        return this._get(id);
    }
    public on(listener: (data: Reason[]) => void, ...params: QueryConstraint[]): Unsubscribe {
        return this._on(listener, ...params);
    }
    public onDicionary(listener: (data: any) => void, ...params: QueryConstraint[]): Unsubscribe {
        return this._onDicionary(listener, ...params);
    }

}

export default ReasonDB;