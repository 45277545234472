import React, { useRef } from 'react';
import Modal, { ModalBody, ModalFooter, ModalHeader, ModalTitle } from '../../bootstrap/Modal';
import { useReactToPrint } from 'react-to-print';
import BtnCancel from '../../MyCustom/BtnCancel';
import Button from '../../bootstrap/Button';
import SectorPdf from './index';

const ModalSectorPdf = ({ open, setIsOpen, setIsClose, data, title, graphics, cartoesPulseira, estorno, products,rankingBilheteria,rankingTerminalConsumo, fileName}) => {
    const contractRef = useRef();
    const handlePrint = useReactToPrint({
        content: () => contractRef.current,
        removeAfterPrint: true,
        documentTitle: fileName ||'relatorio-ugetpdx',

    });

    return (
        <Modal
            id={'modal-contrato-evento'}
            titleId={'contrato evento'}
            isOpen={open}
            setIsOpen={setIsOpen}
            isStaticBackdrop={true}
            isScrollable={false}
            isCentered={true}
            size="lg" // 'sm' || 'lg' || 'xl' 
            isAnimation={true}

        >
            <ModalBody className='overflow-auto px-0' style={{ height: 800 }}>
                <div ref={contractRef}  >
                    <SectorPdf data={data} graphics={graphics} title={title} cartoesPulseira={cartoesPulseira} estorno={estorno} products={products} rankingBilheteria={rankingBilheteria} rankingTerminalConsumo={rankingTerminalConsumo} />
                </div>
            </ModalBody>
            <ModalFooter>
                <div className='row col-md-12 justify-content-around'>
                    <BtnCancel
                        className='col-md-3'
                        setIsClose={setIsClose}
                    />
                    <Button onClick={handlePrint} className='col-md-3' type='button' color='primary' icon="PictureAsPdf" rounded={1} hoverShadow="sm" shadow="sm" size="sm">
                        Imprimir ou Baixar
                    </Button>
                </div>
            </ModalFooter>
        </Modal>
    )
}

export default ModalSectorPdf;
