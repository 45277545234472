import React, { memo, useState } from 'react'
import Modal, { ModalBody, ModalHeader, ModalTitle, ModalFooter } from '../../bootstrap/Modal';
import Button from '../../bootstrap/Button';
import BtnCancel from '../BtnCancel';
import FormGroup from '../../bootstrap/forms/FormGroup';
import Select from '../../bootstrap/forms/Select';

const ExportSelectType = ({ open, onClose, onConfirm, onCancel, title, message }) => {
  const [typeFile, settypeFile] = useState('csv')

  const options = [
    {value: 'csv', label: 'CSV'},
    {value: 'json', label: 'JSON'},
  ]

  return (
    <Modal
      id={'modal-delete-products'}
      titleId={'Exclusão de Produtos'}
      isOpen={open}
      setIsOpen={onClose}
      isStaticBackdrop={true}
      isScrollable={false}
      isCentered={true}
      size="lg"
      isAnimation={true}
    >
      <ModalHeader>
        <ModalTitle id="delete-products">{title}</ModalTitle>
      </ModalHeader>
      <ModalBody>
        <FormGroup id="typeFile" label="Tipo de Arquivo" className='col-md-4'>
          <Select
            value={typeFile}
            list={options}
            onChange={e => settypeFile(e.target.value)}
          />
        </FormGroup>
        <p className='mt-4'>{message}</p>
      </ModalBody>
      <ModalFooter>
        <BtnCancel
          setIsClose={() => {
            if(onCancel) onCancel();
            onClose(false);
          }}
        />
        
        <Button
          type='submit'
          color='primary'
          icon="check"
          rounded={1}
          hoverShadow="sm"
          shadow="sm"
          size="sm"
          onClick={() => {
            if(onConfirm) onConfirm(typeFile);
            onClose(false);
          }}
        >
          Confirmar
        </Button>
      </ModalFooter>
    </Modal>
  )
}

export default memo(ExportSelectType);
