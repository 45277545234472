import React, { memo, useContext, useEffect, useState } from 'react';
import Faturamento from './faturamento';
import Movimentacao from './movimentacao';
import useTotalGeral from '../../../../../hooks/TotaisReports/useTotalGeral';
import FaturamentoSetor from '../../../../financial/Setor/faturamentoSetor';
import RankingTerminal from '../../../../financial/Geral/rankingTerminal';
import RankingTerminalConsumo from '../../../../financial/Geral/rankingTerminalConsumo';
import { DefaultContext } from '../../../../../contexts/default';
import ClouserCashierEventData from '../../../../../database/wrappers/eventData/clouserCashier';
import ClouserTotemRechargeEventData from '../../../../../database/wrappers/eventData/clouserTotemRecharge';
import ClouserTotemEventData from '../../../../../database/wrappers/eventData/clouserTotem';
import { where } from 'firebase/firestore';
import { Timestamp } from 'firebase/firestore';
const TabSales = ({ hidden }) => {
  const {TotalCalc} = useTotalGeral();
  const {dataFilter,estabSelected,eventSelected} = useContext(DefaultContext);
  const [fatPos, setFatPos] = useState([]);
  const [fatTotems, setFatTotems] = useState([]);
  const [fatTotemsRecharges, setFatTotemsRecharges] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      
      const {firstDate, secondDate} = dataFilter;
      const startDate = Timestamp.fromDate(new Date(firstDate))
      const endDate = Timestamp.fromDate(new Date(secondDate))
      const [pos, totem, totemsRecharge] = await Promise.all([
        new ClouserCashierEventData(estabSelected, eventSelected)
          .getAll(
            where('opening_at', '>=',  startDate),
            where('opening_at', '<=', endDate)
          ),
  
        new ClouserTotemEventData(estabSelected, eventSelected)
          .getAll(
            where('opening_at', '>=', startDate),
            where('opening_at', '<=', endDate)
          ),
          
        new ClouserTotemRechargeEventData(estabSelected, eventSelected)
          .getAll(
            where('opening_at', '>=', startDate),
            where('opening_at', '<=', endDate)
          )
      ]);
  
      console.log(pos, totem, totemsRecharge);
      setFatPos(pos);
      setFatTotems(totem);
      setFatTotemsRecharges(totemsRecharge);
    };
  
    if (!eventSelected || !estabSelected) return;
    fetchData();
  }, [dataFilter, estabSelected, eventSelected]);

  return (
    <div hidden={hidden} className='col-xxl'>

      <Faturamento faturamento={TotalCalc} />

      <Movimentacao movimentacao={TotalCalc}/>

      <div>
        <RankingTerminal fatPos={fatPos} fatTotems={fatTotems} fatTotemsRecharge={fatTotemsRecharges} />
        <RankingTerminalConsumo fatPos={fatPos} />
      </div>
      
    
      


    </div>
  )
}

export default memo(TabSales);
