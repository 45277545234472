import {
	collection,
	CollectionReference,
	DocumentData,
	getDocs,
	getFirestore,
	onSnapshot,
	query,
	QueryConstraint,
	Timestamp,
	Unsubscribe,
	where,
} from 'firebase/firestore';
import app from '../../config';
import ActivationsNFC from '../../entities/eventData/activationsNFC.entity';



class ActivationsNFCEventData {
	protected readonly colRefStr: string;
	protected readonly colRef: CollectionReference<DocumentData>;

	constructor(estabId: string, eventId: string) {
		this.colRefStr = `EstablishmentsData/${estabId}/EventsData/${eventId}/ActivationsNFC`;
		this.colRef = collection(getFirestore(app), this.colRefStr);
	}

	on(listener: (data: ActivationsNFC[]) => void, ...params: QueryConstraint[]): Unsubscribe {
		return onSnapshot(query(this.colRef, ...params), snapshot => {
			const datas: ActivationsNFC[] = [];
			snapshot.forEach(doc => datas.push({ id: doc.id, ...doc.data() as any } as ActivationsNFC))
			listener(datas)
		})
	}

	async getAll(): Promise<ActivationsNFC[]> {
		const snapshot = await getDocs(query(this.colRef))
		
		const array: ActivationsNFC[] = [];
		snapshot.forEach(item => array.push({
			id: item.id,
			...item.data()
		} as any))
		
		return array
	}

	async getAllInterval(startDate: Date, endDate: Date): Promise<ActivationsNFC[]> {
		const snapshot = await getDocs(query(this.colRef,
			where('created_at', '>=', Timestamp.fromDate(startDate)),
			where('created_at', '<=', Timestamp.fromDate(endDate))
		))
		return snapshot.docs.map(doc => ({
			id: doc.id,
			...doc.data(),
		})) as any
	}
	async getBySector(sectorId: string): Promise<ActivationsNFC[]> {
		const snapshot = await getDocs(query(this.colRef,
			where('sectorId', '==', sectorId)

		))
		return snapshot.docs.map(doc => ({
			id: doc.id,
			...doc.data(),
		})) as any
	}

	async getByIdCard(idCard: string) {
		const snapshot = await getDocs(query(this.colRef,
			where('idCard', '==', idCard)
		))
		return snapshot.docs.map(doc => ({
			id: doc.id,
			...doc.data(),
		})) as any
	}

	async getAllDicionary() {
		const snapshot = await getDocs(query(this.colRef,))

		const nfcsDicionary: any = {};
		snapshot.forEach(item => {
			const data = item.data();
			nfcsDicionary[data.idCard] = {
				id: item.id,
				...item.data()
			}
		})

		return nfcsDicionary;
	}

}

export default ActivationsNFCEventData;
