
import RechargeMovement from "./movementRecharge"
import TotaisRecharge from "./totaisRecharge"
import useTotalRecharge from "../../../../../hooks/TotaisReports/useTotalRecharge"
import { TABS } from "../tabs"
import ReportsBySector from "./reportsBySector"
import TotaisBySector from "./totaisBySector"
import { useContext, useState,useEffect } from "react"
import { DefaultContext } from "../../../../../contexts/default"


const RechargeMovementTab = ({ hidden, SubTabs}) => {
    const { RechargesCalc,RechargesSector } = useTotalRecharge();

    return (
        <div className='row' hidden={hidden} >
            <div hidden={hidden || SubTabs !== TABS.RECARGA.subTabs.GERAL.id}>
                <TotaisRecharge
                    recharge={RechargesCalc.totalRecharge}
                    rechargeRefund={RechargesCalc.rechargesTotalWithRefunds}
                />
                <RechargeMovement recharge={RechargesCalc.recharges} />
            </div>
            <div hidden={hidden || SubTabs !== TABS.RECARGA.subTabs.POR_SECTOR.id}>
                <TotaisBySector recharge={RechargesSector.totalRecharge} rechargeRefund={RechargesSector.rechargesSectorTotalWithRefunds}/>
                <ReportsBySector recharge={RechargesSector.rechargesSector}/>
            </div>
        </div>
    )
}
export default RechargeMovementTab