import { memo, useContext, useMemo } from "react";
import { DefaultContext } from "../../../../../contexts/default";
import useDarkMode from "../../../../../hooks/useDarkMode";
import Card, { CardActions, CardBody, CardHeader, CardLabel, CardTitle } from "../../../../../components/bootstrap/Card";
import CardValue from "../../../../../components/MyCustom/Dashboard/CardValue";
import Money from "../../../../../helpers/utils/money/money";
import useTotalCashier from "../../../../../hooks/TotaisReports/useTotalCashier";
import useTotalSales from "../../../../../hooks/TotaisReports/useTotalSales";


const Movimentacao = ({ movimentacao }) => {
  const { event } = useContext(DefaultContext)
  const { darkModeStatus } = useDarkMode();
  const {CashiersBalanceReversal} = useTotalCashier();

  return (
    <Card className="mt-5" >
      <CardHeader>
        <CardLabel icon='PieChart' iconColor={!darkModeStatus ? 'dark' : 'light'}>
          <CardTitle className='d-flex flex-row align-items-center'>
            <CardActions>
              MOVIMENTAÇÃO
            </CardActions>
            {/* <Popovers desc={<b>Valor movimentado no evento, representado por: Valor Carregado, Valor Antecipado (ticketeira), Consumação e Ingresso da Bilheteria e Cortesia. OBS: Geralmente valor maior que faturamento.</b>}><Icon className='mx-3' size='2x' icon='Info' /></Popovers> */}
          </CardTitle>
        </CardLabel>
      </CardHeader>
      <CardBody >

        <div className='row'>
          <div className='col-md'>
            <CardValue
              color='dark'
              label='TOTAL'
              value={Money.centsToMaskMoney(movimentacao.totalMovimentacao)}
            />
          </div>
          <div className='col-md'>
            <CardValue
              color='warning'
              description='Carga disponibilizada por um responsável pra consumo no evento.'
              label='CORTESIA'
              value={Money.centsToMaskMoney(movimentacao.totalCourtesy)}
            />
          </div>
          <div className='col-md'>
            <CardValue
              color='danger'
              description='Faltou descrição Gabriel XD'
              label='ESTORNO MOVIMENTAÇÃO (-)'
              value={Money.centsToMaskMoney(CashiersBalanceReversal)}
            />
          </div>
          <div className='col-md' hidden={!event?.types.ticket}>
            <CardValue
              color='green'
              description='Total faturado em evento ticket. OBS: Geralmente valor menor que movimentação.'
              label='FATURAMENTO TICKET'
              value={Money.centsToMaskMoney(movimentacao.totalFaturamentoTicket)}
            />
          </div>
        </div>

        <div className="row">
          <div className='col-md' hidden={!event?.types.cashless}>
            <CardValue
              color='primary'
              description='Valor recarregado durante o evento'
              label='RECARGA EVENTO'
              value={Money.centsToMaskMoney(movimentacao.totalRecharge)}
            />
          </div>
          <div className='col-md' hidden={!event?.types.cashless}>
            <CardValue
              color='secondary'
              description='Valor recarregado anterior ao evento. OBS: Geralmente por uma Ticketeira.'
              label='RECARGA ANTECIPADA'
              value={Money.centsToMaskMoney(movimentacao.totalEarlyRecharge)}
            />
          </div>
          <div className='col-md' hidden={!event?.types.cashless}>
            <CardValue
              color='success'
              description='Consumação disponibilizada na compra do ingresso vendido na bilheteria do evento.'
              label='CONSUMAÇÃO BILHETERIA'
              value={Money.centsToMaskMoney(movimentacao.totalConsummation)}
            />
          </div>
          <div className='col-md' hidden={!event?.types.cashless}>
            <CardValue
              color='info'
              description='Valor de custo do ingresso.'
              label='INGRESSO BILHETERIA'
              value={Money.centsToMaskMoney(movimentacao.totalIngresso)}
            />
          </div>


        </div>

      </CardBody>
    </Card >
  )
}

export default memo(Movimentacao)
