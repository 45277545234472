import { QueryConstraint, Unsubscribe } from "firebase/firestore";
import CategoryComplement from "../entities/pdv/categoryComplement.entity";
import FirestorePipe from "../utils/pipe";
import EstablishmentDB from "./establishment";

class CategoryComplementDB extends FirestorePipe {

  public static readonly colName = 'CategoriesComplement'

  constructor(idEstab: string) {
    if(!idEstab)
      throw new Error('Informe o id do estabelecimento')
    super(`${EstablishmentDB.colName}/${idEstab}/${CategoryComplementDB.colName}`);
  }

  public create(data: CategoryComplement): Promise<any> {
    return this._create(data);
  }
  public update(id: string, data: CategoryComplement): Promise<any> {
    return this._update(id, data);
  }
  public delete(id: string): Promise<any> {
    return this._delete(id);
  }
  public getAll(...params: QueryConstraint[]): Promise<CategoryComplement[]> {
    return this._getAll<CategoryComplement>(...params);
  }
  public get(id: string): Promise<CategoryComplement> {
    return this._get(id);
  }
  public on(listener: (data: CategoryComplement[]) => void, ...params: QueryConstraint[]): Unsubscribe {
    return this._on(listener, ...params);
  }

}

export default CategoryComplementDB;