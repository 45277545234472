import React, { memo, useContext, useMemo, useState } from "react";
import { DefaultContext } from "../../../../contexts/default";
import { ACCESS_LEVEL } from "../../../../types/roles";
import Button from "../../../bootstrap/Button";
import Dropdown, { DropdownMenu, DropdownToggle } from "../../../bootstrap/Dropdown";
import FormGroup from "../../../bootstrap/forms/FormGroup";
import Label from "../../../bootstrap/forms/Label";
import Select from "../../../bootstrap/forms/Select";

function FiltersDash({ }) {

  const { accessLevel, establishments, estabSelected, setestabSelected, events, eventSelected, seteventSelected } = useContext(DefaultContext)

  const [filterMenu, setFilterMenu] = useState(false);

  const optionsEstablishments = useMemo(() => establishments.map(item => ({ value: item.id, text: item.name })), [establishments])
  const optionsEvents = useMemo(() => events.map(item => ({ value: item.id, text: item.name })), [events])

  return (
    <Dropdown isOpen={filterMenu} setIsOpen={setFilterMenu}>
      <DropdownToggle hasIcon={false}>
        <Button
          icon='Filter' color='warning'
          //isLight 
          className='text-dark'
          data-tour='filter'
        >
          Filtros
        </Button>
      </DropdownToggle>
      <DropdownMenu
        isAlignmentEnd
        size='lg'
        isCloseAfterLeave={false}
        data-tour='filter-menu'>
        <div className='container py-2'>
          {accessLevel >= ACCESS_LEVEL.MANAGER &&
            <div className='col-12'>
              <FormGroup>
                <Label htmlFor='unity'>Estabelecimento</Label>
                <Select
                  id='unity'
                  ariaLabel='Unidade'
                  placeholder='Unidade'
                  list={optionsEstablishments}
                  onChange={e => {
                    seteventSelected('')
                    setestabSelected(e.target.value)
                  }}
                  value={estabSelected}
                />
              </FormGroup>
            </div>}
          <div className='col-12'>
            <FormGroup>
              <Label htmlFor='unity'>Evento</Label>
              <Select
                id='unity'
                ariaLabel='Unidade'
                placeholder='Unidade'
                list={optionsEvents}
                onChange={e => seteventSelected(e.target.value)}
                value={eventSelected}
              />
            </FormGroup>
          </div>
        </div>
      </DropdownMenu>
    </Dropdown>
  );
}

export default memo(FiltersDash);