import React, { memo } from "react";
import { TABS } from "../tabs";
import EntranceReports from "./entranceReports";
import ReportsBySector from "./reportsBySector";
import TotaisBySector from "./totaisBySector";


const TabBilheteria = ({ hidden, SubTabs }) => {


  return (
    <div hidden={hidden} className='col-xxl'>
      <EntranceReports
        hidden={hidden || SubTabs !== TABS.ENTRADA.subTabs.GERAL.id}

      />


      <div hidden={hidden || SubTabs !== TABS.ENTRADA.subTabs.POR_SECTOR.id}>

        <TotaisBySector />
        <ReportsBySector />
      </div>



    </div>
  );
}

export default memo(TabBilheteria)